/**
 * Created by r4zi4l on 21.04.2022
 */

var ClanPlayer = function (data) {
    this.id = data.id;
    this.level = data.level;
    this.name = data.name;
    this.avatar = data.avatar;
    this.role = data.role;
};

ClanPlayer.prototype.isUser = function () {
    return this.id === connector.platform.getUserID();
};

ClanPlayer.prototype.isOwner = function () {
    return this.role === ClansConfig.ROLE_OWNER;
};

ClanPlayer.prototype.getInfo = function () {
    return {
        id: this.id,
        level: this.level,
        name: this.name,
        avatar: this.avatar,
        role: this.role
    };
};

ClanPlayer.prototype.convertToRowData = function () {
    return {
        id: this.id,
        player: this.isUser(),
        text: this.name,
        avatar: {
            id: this.id,
            avatar: this.avatar
        },
        leader: this.isOwner(),
        level: this.level + 1
    };
};
