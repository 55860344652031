/**
 * Created by mac on 10/5/20
 */

var AdminLevel = function (options) {
    cleverapps.EventEmitter.call(this);

    Object.assign(this, options);

    var tag = options.tag;

    // migration
    if (!tag) {
        if (options.hard) {
            tag = Level.TAGS.HARD;
        } else if (options.tricky) {
            tag = Level.TAGS.TRICKY;
        } else if (options.rules) {
            tag = Level.TAGS.RULES;
        }
    }

    this.tag = tag;

    if (options.hashes) {
        this.hashes = this.getLevelHashes();
    }
    if (options.version) {
        this.version = this.getMaxVersion(this.getLevelVersions());
    }
};

AdminLevel.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminLevel.prototype.constructor = AdminLevel;

AdminLevel.createFromData = function (levelNumber, extra) {
    if (Array.isArray(extra)) {
        extra = extra[extra.length - 1];
    }
    var content = JSON.parse(JSON.stringify(extra));
    return new AdminLevel(Object.assign({}, content, levelNumber, {
        content: content
    }));
};

AdminLevel.getAddMoreLevel = function () {
    return new AdminLevel({
        addMoreLevel: true
    });
};

AdminLevel.prototype.setDragEpisode = function (episode) {
    var newEpisodeNo = this.originalEpisodeNo;
    if (episode) {
        newEpisodeNo = episode.episodeNo;
    }

    if (this.episodeNo !== newEpisodeNo) {
        this.episodeNo = newEpisodeNo;
        this.trigger("updateEpisode");
    }
};

AdminLevel.prototype.setDifficulty = function (difficulty) {
    this.difficulty = difficulty;

    this.trigger("changeDifficulty");
};

AdminLevel.prototype.setLevelNo = function (levelNo) {
    if (this.levelNo !== levelNo) {
        this.levelNo = levelNo;
        this.onUpdate();
    }
};

AdminLevel.prototype.getValidateError = function () {
    if (cleverapps.config.type === "tile3") {
        var isCorrectAmount = this.screens.every(function (screen) {
            var amount;
            if (cleverapps.config.orientation === "horizontal") {
                amount = EditorCardTable.CountCards(screen.cards.concat(screen.magicH));
            } else {
                amount = EditorCardTable.CountCards(screen.cards.concat(screen.magicV));
            }
            return amount % 3 === 0;
        }, this);

        if (!isCorrectAmount) {
            return "Error: The number of tiles must be divisible by 3";
        }

        var hasNeighborStacks = false;

        this.screens.forEach(function (screen) {
            var cards = screen.cards.concat(screen.magicH.concat(screen.magicV));
            hasNeighborStacks = hasNeighborStacks || EditorCardTable.findAdjacentStacks(cards);
        });

        if (hasNeighborStacks) {
            return "Error: Should not be neighbor stacks";
        }
    }
};

AdminLevel.prototype.getHint = function () {
    if (this.hint) { // scramble
        var letters = this.words.reduce(function (a, c) {
            return a + c.length; 
        }, 0);
        return letters + " - " + this.words.length;
    }

    if (["solitaire", "tile3"].indexOf(cleverapps.config.type) !== -1) {
        if (this.screens) {
            return this.screens.map(function (screen) {
                var cards = screen.cards || screen.horizontalCards;
                return EditorCardTable.CountCards(cards.concat(TileTable.ListMagicCards(screen)));
            }).join("-");
        }
    }

    if (cleverapps.config.type === "differences") {
        return "x " + this.differences.length + " - " + this.rate;
    }

    return this.levelNo;
};

AdminLevel.prototype.getFeatures = function () {
    var features = [];
    if ((cleverapps.config.type === "solitaire" || cleverapps.config.type === "tile3") && this.screens) {
        var extractFeature = function (card) {
            var feature;
            if (card.feature && card.feature !== "mouse") {
                feature = card.feature;
            }

            if (card.marks && card.marks.indexOf("magnet") !== -1) {
                feature = "magnet";
            }
            return feature && features.indexOf(feature) === -1 ? feature : undefined;
        };

        this.screens.forEach(function (screen) {
            var cards = screen.cards || screen.horizontalCards;
            cards.concat(TileTable.ListMagicCards(screen)).forEach(function (card) {
                var cardFeature = extractFeature(card);
                if (cardFeature) {
                    features.push(cardFeature);
                }
            });
        });
    }
    if (cleverapps.config.type === "match3") {
        var gather = function (data) {
            var featuresBundles = {};
            data.forEach(function (row) {
                row.forEach(function (cell) {
                    if (cell && cell.bundleId) {
                        var features = cell.bundleId();
                        if (!Array.isArray(features)) {
                            features = [features];
                        }
                        features.forEach(function (feature) {
                            featuresBundles[feature] = true;
                        });
                    }
                });
            });
            return Object.keys(featuresBundles);
        };

        features = features.concat(gather(this.gameField.cells), gather(this.gameField.floor));
        features = features.filter(function (bundle) {
            return bundles.admin.frames["feature_" + bundle];
        });
        features = cleverapps.unique(features);

        features.sort(function (a, b) {
            var first = AdminLevel.MATCH3_FEATUREBUNDLES.indexOf(a);
            var second = AdminLevel.MATCH3_FEATUREBUNDLES.indexOf(b);
            if (first < 0) {
                console.error("Unknown priority for " + a);
                return 1;
            }
            if (second < 0) {
                console.error("Unknown priority for " + b);
                return -1;
            }
            return first - second;
        });
    }
    return features.slice(0, 3);
};

AdminLevel.prototype.isAddMoreLevel = function () {
    return this.addMoreLevel !== undefined;
};

AdminLevel.prototype.handleDragStart = function () {
    this.dragging = true;
    this.originalLevelNo = this.levelNo;

    cleverapps.administrator.adminLevels.draggingLevel = this;

    this.trigger("startDrag");
    this.onUpdate();
};

AdminLevel.prototype.handleDragMove = function (levelNo) {
    if (!this.dragging) {
        return;
    }

    if (this.levelNo !== levelNo) {
        cleverapps.administrator.adminLevels.dragLevel(this, levelNo);
    }
};

AdminLevel.prototype.handleDragEnd = function () {
    if (!this.dragging) {
        return;
    }

    delete cleverapps.administrator.adminLevels.draggingLevel;
    this.dragging = false;

    cleverapps.focusManager.display({
        focus: "MoveLevel",
        action: function (f) {
            var waitWindow = new WaitWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
            cleverapps.administrator.adminLevels.moveLevel({
                fromLevelNo: this.originalLevelNo,
                toLevelNo: this.levelNo,
                episodeNo: this.episodeNo,
                callback: function () {
                    waitWindow.close();
                }
            });
        }.bind(this)
    });

    this.trigger("stopDrag");

    delete this.originalEpisodeNo;
    delete this.originalLevelNo;
    this.onUpdate();
};

AdminLevel.prototype.moveToSlot = function (slot) {
    if (this.dragging) {
        return;
    }

    this.trigger("moveToSlot", slot);
};

AdminLevel.prototype.isCurrent = function () {
    return +this.episodeNo === cleverapps.user.episode && this.levelNo === cleverapps.user.level;
};

AdminLevel.prototype.getTag = function () {
    return this.tag;
};

AdminLevel.prototype.toggleTag = function (tag) {
    var previousTag = this.tag;

    if (this.tag === tag) {
        this.tag = undefined;
    } else {
        this.tag = tag;
    }

    var currentTag = this.tag;

    this.updateContent({
        tag: currentTag,
        hard: undefined,
        tricky: undefined
    }, function () {
        var level = LevelManager.getInstance().level;
        level.meta.tag = currentTag;

        this.trigger("change:tag");

        cleverapps.administrator.adminLevels.updateLevels();

        cleverapps.administrator.log.createEntry({
            type: AdminLog.TOGGLE_TAG,
            levelNo: this.levelNo,
            episodeNo: this.episodeNo,
            removedTag: previousTag || "",
            addedTag: currentTag || ""
        });
    }.bind(this));
};

AdminLevel.prototype.onClick = function (f) {
    if (this.isAddMoreLevel()) {
        if (["board", "differences"].indexOf(cleverapps.config.type) >= 0) {
            f();
            return;
        }

        if (cleverapps.isNumber(cleverapps.administrator.adminEpisodes.active.episodeNo)
            && cleverapps.administrator.adminLevels.levels.length > AdminEpisode.LEVELS_PER_EPISODE) {
            var message = "Episode already has " + cleverapps.administrator.adminLevels.levels.length + " levels bigger than " + AdminEpisode.LEVELS_PER_EPISODE;
            cleverapps.notification.create(message);
            f();
            return;
        }

        var blankContent = this.getBlankContent();
        cleverapps.administrator.adminLevels.createNew(blankContent, f);
    } else {
        cleverapps.administrator.adminLevels.setActive(this);
        f();
    }
};

AdminLevel.prototype.onUpdate = function () {
    this.trigger("update");
};

AdminLevel.prototype.select = function () {
    this.selected = true;
    LevelManager.getInstance().setLevel({
        episodeNo: this.episodeNo,
        levelNo: this.levelNo
    });

    this.trigger("select");
    this.trigger("change:tag");
};

AdminLevel.prototype.unselect = function () {
    this.selected = false;
    this.trigger("unselect");
    this.trigger("change:tag");
};

AdminLevel.prototype.getContent = function () {
    return this.content;
};

AdminLevel.prototype.updateContent = function (data, onSuccess) {
    Object.assign(this.content, data);

    LevelManager.getInstance().update(this.content);
    LevelManager.getInstance().save(onSuccess);
};

AdminLevel.prototype.saveComments = function (txt) {
    this.comments = txt;
    this.updateContent({ comments: txt }, function () {
        this.trigger("change:comment", txt);

        cleverapps.administrator.log.createEntry({
            type: AdminLog.ANNOTAION_CHAGE,
            levelNo: this.levelNo,
            episodeNo: this.episodeNo
        });
    }.bind(this));
};

AdminLevel.prototype.editLevel = function () {
    if (cleverapps.config.name === "scramble") {
        this.findRowInGoogleSheets(function (row) {
            cleverapps.administrator.scrambleGotoRow(row);
        });
        return;
    }

    cleverapps.config.editorMode = true;
    LevelManager.getInstance().editLevel();
};

AdminLevel.prototype.getBlankContent = function () {
    if (["solitaire", "tile3"].indexOf(cleverapps.config.type) !== -1) {
        return {
            hard: false,
            hand: {
                num: 1,
                difficulty: 1
            },
            screens: [
                {
                    cards: [
                        {
                            x: 0,
                            y: 0
                        }
                    ],
                    magicV: [],
                    magicH: [],
                    maxValue: 3
                }
            ]
        };
    }

    if (cleverapps.config.type === "match3") {
        return {
            hard: false,
            moves: 1,
            score: 1,
            stars: 10,
            colors: [
                "A",
                "b",
                "c",
                "d",
                "e"
            ],
            bundles: [],
            field: [
                "...",
                "...",
                "..."
            ],
            floor: [
                "   ",
                "   ",
                "   "
            ],
            goals: [
                {
                    type: "a",
                    target: 0
                }
            ]
        };
    }

    if (cleverapps.config.type === "merge") {
        return {
            visibleBox: {
                "width": 0.38,
                "height": 0.48
            },
            map: [
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000001000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000",
                "00000000000000000000000000000000000000000000000000000000000000000000000000000000"
            ],
            field: [],
            tiles: "medieval",
            units: "main",
            expedition: "main"
        };
    }

    if (cleverapps.config.type === "blocks") {
        return {
            "hard": false
        };
    }
};

AdminLevel.prototype.getHumanNumber = function () {
    if (this.isAddMoreLevel()) {
        return "";
    }
    return cleverapps.humanReadableNumber(this.episodeNo, this.levelNo);
};

AdminLevel.prototype.findRowInGoogleSheets = function (callback) {
    cleverapps.RestClient.post("/admin/findrowingooglesheets", {
        language: cleverapps.settings.language,
        hint: this.hint,
        words: this.words
    }, function (response) {
        if (response.error) {
            console.log("Error response: ", response);
            cleverapps.notification.create(response.error);
            callback();
        } else {
            callback(response.row);
        }
    });
};

AdminLevel.MATCH3_FEATUREBUNDLES = [
    "shark",
    "wall",
    "dirt_maker",
    "moves_cell",
    "colorcookieman",
    "candle",
    "cookieman",
    "ice_block_maker",
    "gingerbread_house",
    "petards",
    "clock",
    "crab_maker",
    "syrup",
    "waffle",
    "ice_maker",
    "color_bomb",
    "bomb",
    "color_container",
    "dog",
    "lollipop",
    "cake",
    "fish_cell",
    "truffle",
    "firework_cell",
    "firefly_jar",
    "donut_box",
    "mouse",
    "burst",
    "small_petard",
    "cannon",
    "ice_cream_maker",
    "muffin",
    "dragon",
    "rug",
    "dirty_honey",
    "crab_decorator",
    "rabbit",
    "muffin",
    "ice_block",
    "flower_cell",
    "dirt_decorator",
    "blanket",
    "nut",
    "bag",
    "ice",
    "gum_decorator",
    "chain_decorator",
    "transporter",
    "grass_tile",
    "box"
].reduce(function (names, name) {
    names.push(name, name + "_reskin");
    return names;
}, []);

AdminLevel.prototype.getLevelVersions = function () {
    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();
    var episodeLevels = adminEpisode.getEpisodeLevels();

    if (!episodeLevels || !episodeLevels[this.levelNo]) {
        cleverapps.throwAsync("No levels for episode " + adminEpisode.episodeNo + " level " + this.levelNo);
    }

    var content = episodeLevels[this.levelNo];
    if (!Array.isArray(content)) {
        content = [content];
    }

    var versions = {};
    content.forEach(function (level, index) {
        var version = level.version || index;
        versions[version] = level;
    });
    return versions;
};

AdminLevel.prototype.getLevelHashes = function () {
    var hashes = {};

    var versions = this.getLevelVersions();
    Object.keys(versions).map(cleverapps.castType).sort().forEach(function (version) {
        var content = versions[version];
        var hash = Level.CalcHash(content);
        hashes[hash] = {
            levelNo: this.levelNo,
            version: version,
            hash: hash
        };
    }.bind(this));

    return hashes;
};

AdminLevel.prototype.getMaxVersion = function (versions) {
    var maxVersion = 0;
    for (var version in versions) {
        maxVersion = Math.max(maxVersion, parseInt(version));
    }
    return maxVersion;
};

AdminLevel.prototype.getLevelContent = function () {
    var adminEpisode = cleverapps.administrator.adminEpisodes.getActive();
    var bundle = bundles[adminEpisode.bundleId()];
    var content;
    if (bundle.meta.location) {
        content = cc.loader.getRes(bundle.jsons.map_json);
    } else {
        var versions = this.getLevelVersions();
        var activeVersion = this.getMaxVersion(versions);

        if (cleverapps.config.editorMode && LevelManager.getInstance().version !== undefined
            && !(cleverapps.environment.isMainScene() && !cleverapps.travelBook.isExpedition())) {
            activeVersion = LevelManager.getInstance().version;
        }

        content = versions[activeVersion];
        content.version = activeVersion;
    }

    content.episodeNo = this.episodeNo;
    content.levelNo = this.levelNo;

    return content;
};
