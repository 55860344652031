/**
 * Created by vladislav on 20/10/2022
 */

var Customer = function (unit, options) {
    UnitComponent.call(this, unit);

    this.seed = options.seed;
    this.appearTime = options.appearTime || options.seed || Date.now();
    if (cleverapps.gameModes.silentCustomers) {
        this.appearTime -= Customer.COOLDOWN;
    }

    this.cookingTime = options.ct;

    if (cleverapps.environment.isAdministratorScene()) {
        return;
    }

    this.onAmountChanged = function () {};

    this.timeout = new cleverapps.LongTimeout(this.generateNextRecipe.bind(this), this.unit.prizesCount
        || this.cookingTime ? 0 : this.getTimeLeft());

    this.addTimeout = Game.currentGame.setTimeout(function () {
        Map2d.currentMap.customers.addCustomer(this);
        if (this.recipe) {
            Map2d.currentMap.customers.updateMarks();
        }
    }.bind(this), 0);
};

Customer.prototype = Object.create(UnitComponent.prototype);
Customer.prototype.constructor = Customer;

Customer.prototype.onPrizeHarvested = function () {
    this.afterExchange();
};

Customer.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

Customer.prototype.handleClick = function () {
    if (this.getCookingTimeLeft()) {
        cleverapps.focusManager.display({
            focus: "ConfirmSpeedUpWindowCustomer",
            action: function (f) {
                new ConfirmSpeedUpWindow({
                    timeLeft: this.getCookingTimeLeft(),
                    totalDuration: this.cookingDuration,
                    eventName: cleverapps.EVENTS.SPENT.CUSTOMER,
                    callback: this.speedUp.bind(this),
                    canCoins: false
                });
                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this)
        });

        return;
    }

    if (!this.recipe) {
        if (!this.hasSomeRecipes()) {
            cleverapps.centerHint.createTextHint("Customer.NoMore");
        } else if (this._getRecipeRequiredUnit()) {
            CustomerHintContent.pointToCustomer(this.unit.code, this._getRecipeRequiredUnit());
        }
        return false;
    }

    this.unit.squeeze();

    Map2d.currentMap.customers.showWindow(this);

    return true;
};

Customer.prototype.speedUp = function () {
    clearTimeout(this.cookingTimeout);
    this.finishCooking();
};

Customer.prototype.handlePrizes = function () {
    return this.unit.prizes.waiting;
};

Customer.prototype.showWaiting = function () {
    if (!this.unit.prizes && this.getTimeLeft() > 0 && Boolean(CustomerRecipe.selectTemplate(this.unit, this.seed))) {
        this.unit.setPrizes({ waiting: true });
    }
};

Customer.prototype.handleRemove = function () {
    Map2d.currentMap.customers.removeCustomer(this);
};

Customer.prototype.onUnitRemoved = function (unit) {
    this.recipe.removeUnitFromIngredients(unit);
    this.onAmountChanged();
};

Customer.prototype.processUnits = function (units) {
    var isReady = this.recipe.addUnits(units);
    this.onAmountChanged();
    if (isReady) {
        this.wantsWindow = true;
        Game.currentGame.counter.trigger();
    }
};

Customer.prototype.updateIngredients = function () {
    if (this.recipe.needsIngredients()) {
        var isReady = this.recipe.updateIngredients();
        this.onAmountChanged();
        if (isReady) {
            this.wantsWindow = true;
            Game.currentGame.counter.trigger();
        }
    }
};

Customer.prototype.exchange = function (callback) {
    Map2d.mapEvent(Map2d.BEFORE_EXCHANGE, { affected: this.unit });

    var recipe = this.recipe;
    this.recipe = undefined;

    recipe.cook(this.unit, function () {
        this.startCooking(recipe);

        var timeLeft = this.getCookingTimeLeft();
        var climbable = this.unit.findComponent(Climbable);
        if (climbable && !timeLeft && !this.unit.isLast()) {
            climbable.gotoNextStage(callback);
            return;
        }

        callback();
    }.bind(this));

    this.onUpdateState();
    Map2d.currentMap.customers.updateMarks();

    this.cookingTime = Date.now() + recipe.getCookingDuration();
    this.unit.save();

    this.unit.squeeze();
};

Customer.prototype.startCooking = function (recipe) {
    clearTimeout(this.cookingTimeout);
    this.cookingRecipe = recipe;
    this.onUpdateState();

    var timeLeft = this.getCookingTimeLeft();
    if (timeLeft) {
        this.cookingDuration = recipe.getCookingDuration();
        this.cookingTimeout = new cleverapps.LongTimeout(this.finishCooking.bind(this), timeLeft);
    } else {
        this.finishCooking();
    }
};

Customer.prototype.finishCooking = function () {
    var prize = this.cookingRecipe.listPrize();
    this.cookingDuration = undefined;
    this.cookingRecipe = undefined;
    this.unit.setPrizes(prize);
    this.onUpdateState();
};

Customer.prototype.afterExchange = function () {
    this.cookingTime = undefined;
    this.seed = Date.now();
    this.appearTime = Date.now();
    this.unit.save();

    Map2d.mapEvent(Map2d.EXCHANGE, { affected: this.unit });

    var climbable = this.unit.findComponent(Climbable);
    if (climbable && !this.unit.isLast()) {
        climbable.gotoNextStage();
        return;
    }

    clearTimeout(this.timeout);
    this.timeout = new cleverapps.LongTimeout(this.generateNextRecipe.bind(this), this.getTimeLeft());

    this.showWaiting();
};

Customer.prototype.generateNextRecipe = function () {
    clearTimeout(this.timeout);
    delete this.timeout;

    if (!Game.currentGame || this.unit.x === undefined || this.unit.y === undefined || Map2d.currentMap.getUnit(this.unit.x, this.unit.y) !== this.unit) {
        cleverapps.throwAsync("generate recipe after unit removed " + this.unit.code + " " + this.unit.stage);
        return;
    }

    var recipeTemplate = CustomerRecipe.selectTemplate(this.unit, this.seed);
    if (!recipeTemplate) {
        this.onUpdateState();
        return;
    }

    var recipe = new CustomerRecipe(recipeTemplate, this.seed);

    if (this.unit.prizesCount) {
        this.unit.setPrizes(recipe.listPrize());
        return;
    }
    if (this.cookingTime) {
        this.startCooking(recipe);
        return;
    }

    this.recipe = recipe;

    this.calcCurrentAmount();
    this.onUpdateState();
    Map2d.currentMap.customers.updateMarks();

    this.unit.setPrizes();
    this.unit.save();
};

Customer.prototype.calcCurrentAmount = function () {
    var units = [], items = [];
    this.recipe.getIngredients().forEach(function (ingredient) {
        ingredient.unit && units.push(ingredient.unit);
        ingredient.ingredient && items.push(ingredient.ingredient);
    });

    // Map2d.currentMap.listAvailableUnits(units).forEach(function (unit) {
    //     this.processUnit(unit);
    // }, this);

    if (units.length) {
        this.processUnits(Map2d.currentMap.listAvailableUnits(units));
    }

    if (items.length) {
        this.updateIngredients();
    }
};

Customer.prototype.isRecipeReady = function () {
    return Boolean(this.recipe && this.recipe.isReady());
};

Customer.prototype.getCurrentRecipe = function () {
    return this.recipe;
};

Customer.prototype.getCookingRecipe = function () {
    return this.cookingRecipe;
};

Customer.prototype.hasSomeRecipes = function (useFilter) {
    return Boolean(CustomerRecipe.selectTemplate(this.unit, this.seed, !useFilter));
};

Customer.prototype._getRecipeRequiredUnit = function () {
    var recipe = CustomerRecipe.selectTemplate(this.unit, this.seed, true);
    if (recipe && recipe.requiredUnits) {
        return recipe.requiredUnits.filter(function (unit) {
            return !cleverapps.unitsLibrary.isOpened(unit);
        })[0];
    }
};

Customer.prototype.isStandby = function () {
    return !this.unit.prizesCount && !this.getCurrentRecipe() && !this.timeout && !this.getCookingTimeLeft() && this.hasSomeRecipes();
};

Customer.prototype.save = function (data) {
    data.seed = this.seed;
    data.appearTime = this.appearTime;
    data.ct = this.cookingTime;
};

Customer.prototype.destructor = function () {
    Map2d.currentMap.customers.removeCustomer(this);
    clearTimeout(this.addTimeout);
    clearTimeout(this.timeout);
    clearTimeout(this.cookingTimeout);
};

Customer.prototype.getTimeLeft = function () {
    return Math.max(0, this.appearTime + Customer.COOLDOWN - Date.now());
};

Customer.prototype.getCookingTimeLeft = function () {
    if (this.cookingTime) {
        return Math.max(0, this.cookingTime - Date.now());
    }
    return 0;
};

Customer.prototype.isResting = function () {
    return !this.unit.prizesCount && !this.cookingTime && !this.recipe;
};

Customer.prototype.getGuideOptions = function () {
    return this.unit.getData().customer.guide;
};

Customer.COOLDOWN = cleverapps.parseInterval("10 seconds");
