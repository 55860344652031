/**
 * Created by razial on 12.10.2021
 */

var TankView = cc.Node.extend({
    ctor: function (tank, unitView) {
        this._super();

        this.tank = tank;
        this.unitView = unitView;

        this.restoreState();
    },

    restoreState: function (animated) {
        if (cleverapps.gameModes.hideTankProgress || !this.tank.amount) {
            this.removeProgress(animated);
        } else {
            this.createProgress(animated);
        }

        if (this.tank.amount || !this.tank.getTimeLeft()) {
            this.removeTimer(animated);
        } else {
            this.createTimer(animated);
        }
    },

    createProgress: function (animated) {
        if (!this.progress) {
            var styles = cleverapps.styles.TankView.progress;

            this.progress = new ScaledProgressBar({
                type: ScaledProgressBar.Types.blue_small
            });
            this.progress.setAnchorPoint(0.5, 0.5);
            this.progress.setPositionRound(styles);
            this.progress.setLength(styles.width);
            this.unitView.addChild(this.progress);

            if (animated) {
                this.progress.setScale(0.5);
                this.progress.runAction(new cc.ScaleTo(0.2, 1, 1).easing(cc.easeIn(1)));
            }
        }

        this.progress.setGoal(this.tank.data.maxGrow);
        this.progress.setPercentage(this.tank.amount, {
            animated: animated
        });
    },

    removeProgress: function (animated) {
        if (!this.progress) {
            return;
        }

        if (!animated) {
            this.progress.removeFromParent();
            return;
        }

        this.progress.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 0.5, 0.5).easing(cc.easeIn(1)),
            new cc.RemoveSelf()
        ));
        this.progress = undefined;
    },

    createTimer: function (animated) {
        if (this.unitView.getAdditionalView("timer")) {
            return;
        }

        var styles = cleverapps.styles.TankView.timer;

        var timer = new ComponentTimer(
            new cleverapps.CountDown(this.tank.getTimeLeft()),
            this.tank.getGrowTime(),
            {
                barType: ScaledProgressBar.Types.blue,
                reversed: false
            }
        );
        timer.setPositionRound(styles);

        if (animated) {
            this.unitView.animateAppearAdditionalView("timer", timer);
        } else {
            this.unitView.createAdditionalView("timer", timer);
        }
    },

    removeTimer: function (animated) {
        this.unitView.removeAdditionalView("timer", !animated);
    }
});

cleverapps.styles.TankView = {
    progress: {
        width: 84,
        x: { align: "center" },
        y: { align: "bottom", dy: -15 }
    },

    timer: {
        x: { align: "center" },
        y: { align: "bottom" }
    }
};
