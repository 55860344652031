/**
 * Created by andrey on 21.07.2023
 */

var Map2dBorderView = cc.Node.extend({
    ctor: function (borderTypes) {
        this._super();

        for (var i = 0; i < borderTypes.length; i++) {
            var border = ViewReader.createTopYAnchorElement(borderTypes[i]);
            this.addChild(border);
        }
    }
});