/**
 * Created by Andrey Popov on 16.08.2022
 */

var Feedable = function (unit) {
    UnitComponent.call(this, unit);

    var data = this.unit.getData().feedable;

    this.food = data.food;
    this.useFoodIcon = data.useFoodIcon;
    this.canStarve = data.canStarve;

    this.eating = false;
    this.setupPrize();
};

Feedable.prototype = Object.create(UnitComponent.prototype);
Feedable.prototype.constructor = Feedable;

Feedable.prototype.getImageForPrizeView = function () {
    return this.useFoodIcon
        ? { sprite: UnitView.UnitIconFrame(this.food) }
        : { sprite: cleverapps.skins.getSlot("feed_icon_png") };
};

Feedable.prototype.onInfoClosed = function (withAction) {
    if (!withAction && this.isHungry()) {
        this.unit.setPrizes(this.getImageForPrizeView());
    }
};

Feedable.prototype.canAcceptDraggable = function (unit) {
    return this.isHungry() && Unit.IsApplicable(this.food, unit);
};

Feedable.prototype.acceptDraggable = function (unit) {
    this.feed();
    unit.findComponent(Collectible).onFeed(this.unit);
    unit.remove(true);
};

Feedable.prototype.onFeed = function () {
    if (this.view) {
        this.view.feed();
    }
};

Feedable.prototype.handlePrizes = function () {
    if (this.state === Feedable.STATE_STARVE) {
        var foodSource = UnitLocator.getFoodSource(this);

        if (foodSource) {
            Map2d.currentMap.getScrollView().runAction(new cc.CellScrollAction(foodSource, {
                allowScrollWithFocus: true
            }).easing(cc.easeInOut(2)));

            Map2d.currentMap.showLayerTile(Map2d.LAYER_UNITS, foodSource.x, foodSource.y);
            FingerView.hintClick(foodSource, {
                runOnce: true
            });

            cleverapps.centerHint.create({
                food: this.food,
                unit: foodSource
            });
        }
        return true;
    }
};

Feedable.prototype.setupPrize = function () {
    var prize = this.unit.getData().prize;
    var resource = prize.resource;
    var amount = prize.amount;

    this.prize = {
        resource: resource,
        amount: amount
    };
};

Feedable.prototype.feed = function () {
    this.eating = true;
    this.state = Feedable.STATE_FULL;
    this.unit.setPrizes();

    this.feedTime = Date.now();

    var game = Game.currentGame;

    var reward = this.prize.resource;
    var rewardAmount = this.prize.amount;
    if (reward === "pawsPoints" && Game.currentGame.tutorial.checkTypes([Map2d.FEED_HERO])) {
        rewardAmount = Game.currentGame.pawBox.getPointsLeft();
    }

    game.counter.setTimeout(function () {
        this.eating = false;
        game.addReward(reward, rewardAmount, this.unit, { withoutDelta: true });
        Map2d.mapEvent(Map2d.FEED_HERO, { affected: this.unit });

        cleverapps.audio.playSound(bundles.merge.urls.feeding_effect);
        Feedable.processFeedable();
    }.bind(this), Feedable.FOOD_FLY_TIME);
};

Feedable.prototype.isAcceptable = function (unit) {
    return Unit.IsApplicable(this.food, unit);
};

Feedable.prototype.canCollect = function (unit) {
    return this.isHungry() && this.isAcceptable(unit);
};

Feedable.prototype.isReady = function () {
    return this.getTimeLeft() <= 0;
};

Feedable.prototype.isHungry = function () {
    return this.state === Feedable.STATE_HUNGRY;
};

Feedable.prototype.getTimeLeft = function () {
    var feedTime = this.feedTime || 0;
    return Math.max(0, feedTime + Feedable.FEED_COOLDOWN - Date.now());
};

Feedable.prototype.getActionInfo = function () {
    var foods = Map2d.currentMap.listAvailableUnits(this.food);
    if (this.isHungry() && foods.length > 0) {
        var collectible = foods[0].findComponent(Collectible);
        return {
            title: this.unit,
            buttons: {
                feed: collectible.collect.bind(collectible, this.unit)
            }
        };
    }
};

Feedable.prototype.clearTimer = function () {
    if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    }
};

Feedable.prototype.updateTimer = function () {
    this.clearTimer();

    this.timeout = new cleverapps.LongTimeout(function () {
        Feedable.processFeedable();
    }, this.getTimeLeft());
};

Feedable.prototype.setState = function (state) {
    if (this.state === state) {
        return;
    }

    this.state = state;

    this.clearTimer();

    if (this.state === Feedable.STATE_HUNGRY) {
        this.unit.setPrizes(this.getImageForPrizeView());
    } else if (this.state === Feedable.STATE_FULL) {
        this.unit.setPrizes();
    } else if (this.state === Feedable.STATE_STARVE) {
        this.unit.setPrizes({ sprite: bundles.merge.frames.prize_exclamation_off });
    }
};

Feedable.prototype.destructor = function () {
    clearTimeout(this.timeout);
    this.timeout = undefined;
};

Feedable.prototype.handleRemove = function () {
    Feedable.processFeedable();
};

Feedable.Clear = function () {
    clearTimeout(this.processTimeout);
    this.processTimeout = undefined;
};

Feedable.processFeedable = function () {
    if (this.processTimeout) {
        return;
    }

    var game = Game.currentGame;
    if (!game) {
        cleverapps.throwAsync("feed process after game stop");
        return;
    }

    this.processTimeout = setTimeout(function () {
        delete this.processTimeout;
        var foods = [];
        var feedables = [];
        var foodCodeCounter = {};

        game.map.listAvailableUnits(function (unit) {
            if (unit.getData().feedableTarget) {
                foods.push(unit);
                foodCodeCounter[unit.code] = (foodCodeCounter[unit.code] || 0) + 1;
            }

            var feedable = unit.findComponent(Feedable);
            if (feedable) {
                feedables.push(feedable);
            }
        });

        var findMinDist = function (feedable) {
            var minDist = 99999;
            foods.forEach(function (food) {
                if (Unit.Equals(feedable.food, food)) {
                    var dist = cc.pDistanceSQ(feedable.unit, food);
                    minDist = dist < minDist ? dist : minDist;
                }
            });
            return minDist;
        };

        feedables = feedables.sort(function (a, b) {
            var timeDiff = (a.feedTime || 0) - (b.feedTime || 0);
            return timeDiff || (findMinDist(a) - findMinDist(b));
        });

        var isRequiredFood = {};
        for (var i = 0; i < feedables.length; i++) {
            var feedable = feedables[i];
            if (foodCodeCounter[feedable.food.code] && feedable.isAcceptable(foods[0]) && !feedable.eating) {
                if (feedable.isReady()) {
                    foodCodeCounter[feedable.food.code]--;
                    if (!isRequiredFood[feedable.food.code]) {
                        isRequiredFood[feedable.food.code] = true;
                    }
                    feedable.setState(Feedable.STATE_HUNGRY);
                } else {
                    feedable.updateTimer();
                }
            } else if (foods.length === 0 && feedable.canStarve) {
                feedable.setState(Feedable.STATE_BEFORE_STARVE);
                game.counter.setTimeout(function () {
                    if (feedable.state === Feedable.STATE_BEFORE_STARVE) {
                        feedable.setState(Feedable.STATE_STARVE);
                    }
                }, Feedable.BEFORE_STARVE_TIME);
            } else {
                feedable.setState(Feedable.STATE_FULL);
            }
        }

        foods.forEach(function (food) {
            var collectible = food.findComponent(Collectible);
            if (collectible) {
                collectible.setFeedables(feedables);
            }

            var pulsing = food.findComponent(Pulsing);
            if (pulsing) {
                pulsing.setActive(isRequiredFood[food.code]);
            }
        });
    }.bind(this), 100);
};

Feedable.FOOD_FLY_TIME = 650;
Feedable.BEFORE_STARVE_TIME = 1200;
Feedable.FEED_COOLDOWN = cleverapps.parseInterval("10 seconds");

Feedable.STATE_HUNGRY = 0;
Feedable.STATE_FULL = 1;
Feedable.STATE_STARVE = 2;
Feedable.STATE_BEFORE_STARVE = 3;