/**
 * Created by mac on 12/22/20
 */

var InfoView = cc.Scale9Sprite.extend({
    ctor: function (info) {
        var styles = cleverapps.styles.InfoView;
        var frame = bundles.merge.frames.background;

        this._super(frame, cleverapps.UI.getScale9Rect(frame, cleverapps.UI.Scale9Rect.TwoPixelXY));

        this.debugId = "InfoView";

        if (cleverapps.gameModes.silentInfoView) {
            this.setVisible(false);
        }

        var children = [];
        children.push(info.rarity && this.createRarity(info.rarity));
        children.push(info.title && this.createTitle(info.title));
        children.push(info.progress && this.createProgressBar(info.progress));
        children.push(info.description && this.createDescription(info.description));
        children.push(info.generator && this.createGenerator(info.generator));
        children.push(info.duration && this.createDuration(info.duration));
        children.push(info.countdown && this.createCountDown(info.countdown));
        children.push(info.remains && this.createRemainsBar(info.remains));
        children.push(info.ingredients && this.createIngredientsList(info.ingredients));

        children = children.concat(info.buttons && this.createButtons(info.buttons));
        var content = new cleverapps.Layout(children.filter(Boolean), {
            direction: cleverapps.UI.VERTICAL,
            padding: styles.padding
        });

        this.setContentSize2(Math.max(content.width, styles.minWidth), Math.max(content.height, styles.minHeight));

        var pointer = new cc.Sprite(bundles.merge.frames.pointer);
        pointer.setPositionRound(styles.pointer.x, styles.pointer.y);
        this.addChild(pointer);

        content.setAnchorPoint(0.5, 0.5);
        content.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(content);

        if (styles.decorators) {
            styles.decorators.forEach(function (style) {
                var decorator = new cc.Sprite(style.image);
                decorator.setAnchorPoint(0.5, 0.5);
                decorator.setPositionRound(style);
                this.addChild(decorator);
            }, this);
        }

        if (info.windowParameters) {
            this.addChild(this.createWindow(info.windowParameters));
        }

        if (info.upgradableLevel) {
            var upgradableLevel = this.createUpgradableLevel(info.upgradableLevel);
            if (upgradableLevel) {
                this.addChild(upgradableLevel);
            }
        }

        this.baseScale = 1;

        if (info.onClicked) {
            cleverapps.UI.onClick(this, info.onClicked);
        } else {
            cleverapps.UI.onClick(this, this.onClicked.bind(this, undefined), {
                interactiveScale: false
            });
        }
    },

    createRarity: function (rarity) {
        var styles = cleverapps.styles.InfoView.rarity;

        var icon = new cc.Sprite(bundles.merge.frames["icon_" + rarity]);
        icon.setScale(0.7);

        return cleverapps.UI.wrapWithPadding(icon, styles.padding);
    },

    createIngredientsList: function (ingredients) {
        var styles = cleverapps.styles.InfoView;

        return new cleverapps.Layout(ingredients.map(function (ingredient) {
            return new CustomerIngredientView(ingredient, CustomerIngredientView.TYPE_INFOVIEW);
        }), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.ingredients.margin,
            padding: styles.ingredients.padding
        });
    },

    createTitle: function (unit) {
        var styles = cleverapps.styles.InfoView.title;

        var text = unit;
        if (typeof unit !== "string") {
            text = unit.title ? unit.title : cleverapps.unitsLibrary.getUnitName(unit);
        }
        var textWidth = styles.width;

        var icon;
        if (unit.image) {
            icon = new cc.Sprite(unit.image);
        } else if (unit.icon) {
            icon = UnitView.getUnitImage(unit);
        }

        var title;
        if (TextWithIcon.IsApplicable(text)) {
            title = new TextWithIcon(text, {
                font: cleverapps.styles.FONTS.INFO_BIG_TEXT
            });
        } else {
            title = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.INFO_TEXT);
            title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        }

        if (icon) {
            cleverapps.UI.fitToBox(icon, {
                width: styles.width * 0.4,
                height: title.height * 1.8
            });
            textWidth -= icon.width * icon.scaleX;
        }

        if (title.width > textWidth && text.indexOf(" ") >= 0 && text.lastIndexOf(" ") === text.indexOf(" ")) {
            text = text.replace(" ", "\n");
            title.setString(text);
        }

        title.fitTo(textWidth);

        return new cleverapps.Layout([icon, title].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            padding: icon ? styles.iconPadding : styles.padding
        });
    },

    createGenerator: function (generator) {
        var styles = cleverapps.styles.InfoView.generator;

        var text = cleverapps.UI.generateOnlyText("InfoView.generator", cleverapps.styles.FONTS.INFO_TEXT, generator);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.fitTo(styles.width);
        return cleverapps.UI.wrapWithPadding(text, styles.padding);
    },

    createDescription: function (description) {
        var styles = cleverapps.styles.InfoView.description;

        var text = cleverapps.UI.generateOnlyText(description, cleverapps.styles.FONTS.INFO_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.width, 0);
        text.fitTo(undefined, styles.height);
        return cleverapps.UI.wrapWithPadding(text, styles.padding);
    },

    createProgressBar: function (progress) {
        var styles = cleverapps.styles.InfoView.bar;

        var val = progress.total - progress.value;

        var bar = new ScaledProgressBar({
            type: ScaledProgressBar.Types.blue_with_frames
        });
        bar.setAnchorPoint(0.5, 0.5);
        bar.setLength(styles.width);
        bar.setGoal(progress.total);
        bar.setPercentage(val);

        if (cleverapps.config.ui === "heroes") {
            for (var i = 1; i <= Math.min(val, progress.total - 1); i++) {
                var delimiter = bar.getDelimiter();
                delimiter.setAnchorPoint(0, 0);
                delimiter.setPositionRound(i * bar.width / progress.total - delimiter.width, 0);
                bar.addChild(delimiter);
            }
        } else {
            var amount = cleverapps.UI.generateImageText(val + "/" + progress.total, cleverapps.styles.FONTS.INFO_BAR_TEXT);
            amount.setPositionRound(styles.amount);
            bar.addChild(amount);
        }

        return cleverapps.UI.wrapWithPadding(bar, styles.padding);
    },

    createRemainsBar: function (remains) {
        var styles = cleverapps.styles.InfoView.bar;

        var progress = new ScaledProgressBar({
            type: styles.type
        });
        progress.setGoal(remains.total);
        progress.setPercentage(remains.value);
        progress.setLength(styles.width);
        return cleverapps.UI.wrapWithPadding(progress, styles.padding);
    },

    createDuration: function (duration) {
        var styles = cleverapps.styles.InfoView.timer;

        var timer = new cleverapps.CountDownView(duration, {
            font: cleverapps.styles.FONTS.INFO_TIMER_TEXT,
            icon: {
                frame: bundles.timer_merge.frames.timer_png,
                json: bundles.timer_merge.jsons.timer_icon_json,
                animation: "animation",
                stopped: true
            }
        });
        return cleverapps.UI.wrapWithPadding(timer, styles.padding);
    },

    createCountDown: function (duration) {
        var styles = cleverapps.styles.InfoView.timer;

        var countdown = this.countdown = new cleverapps.CountDown(duration, {
            onFinish: InfoView.CloseInfo
        });

        var timer = new cleverapps.CountDownView(countdown, {
            font: cleverapps.styles.FONTS.INFO_TIMER_TEXT,
            icon: {
                frame: bundles.timer_merge.frames.timer_png,
                json: bundles.timer_merge.jsons.timer_icon_json,
                animation: "animation"
            }
        });
        return cleverapps.UI.wrapWithPadding(timer, styles.padding);
    },

    createButtons: function (actions) {
        var buttons = [];

        buttons.push(actions.build && this.createBuildButton(actions.build));
        buttons.push(actions.instant && this.createInstantButton(actions.instant));
        buttons.push(actions.energy && this.createEnergyButton(actions.energy));
        buttons.push(actions.wands && actions.wands.label && this.createAttackLabel(actions.wands.label));
        buttons.push(actions.wands && this.createAttackButton(actions.wands));
        buttons.push(actions.extraWands && actions.extraWands.label && this.createAttackLabel(actions.extraWands.label));
        buttons.push(actions.extraWands && this.createAttackButton(actions.extraWands));
        buttons.push(actions.speedup && this.createSpeedUpButton(actions.speedup));
        buttons.push(actions.sell && this.createSellButton(actions.sell));
        buttons.push(actions.unlock && this.createUnlockButton(actions.unlock));
        buttons.push(actions.valuable && this.createValuableButton(actions.valuable));
        buttons.push(actions.feed && this.createFeedButton(actions.feed));
        buttons.push(actions.create && this.createCreateButton(actions.create));
        buttons.push(actions.locate && this.createLocateButton(actions.locate));
        buttons.push(actions.explode && this.createExplodeButton(actions.explode));

        return buttons;
    },

    createLocateButton: function (options) {
        var styles = cleverapps.styles.InfoView.button;
        var button = new cleverapps.UI.Button({
            type: styles.type.default,
            width: styles.width,
            height: styles.height,
            text: "Find",
            onClicked: this.onClicked.bind(this, options.action, true)
        });
        return cleverapps.UI.wrapWithPadding(button, styles.padding);
    },

    createExplodeButton: function (options) {
        var styles = cleverapps.styles.InfoView.button;
        var button = new cleverapps.UI.Button({
            type: styles.type.default,
            width: styles.width,
            height: styles.height,
            text: "<<Explode",
            icons: {
                "<<": bundles.merge.frames.icon_explode
            },
            onClicked: this.onClicked.bind(this, options.action, true)
        });
        return cleverapps.UI.wrapWithPadding(button, styles.padding);
    },

    createFeedButton: function (options) {
        return this.createBuildButton({
            type: cleverapps.styles.InfoView.button.type.secondary,
            text: "InfoView.button.feed<<",
            action: options
        });
    },

    createValuableButton: function (options) {
        var styles = cleverapps.styles.InfoView.button;

        var button = new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            type: styles.type.secondary,
            text: TextWithIcon.ICONS_BY_NAME[options.unit.code] + options.unit.getData().value,
            onClicked: this.onClicked.bind(this, options.action, true)
        });
        return cleverapps.UI.wrapWithPadding(button, styles.padding);
    },

    createBuildButton: function (options) {
        var styles = cleverapps.styles.InfoView.button;

        options = {
            type: options.type || styles.type.default,
            text: options.text || "InfoView.button.build--",
            action: options instanceof Function ? options : options.action,
            adviceTarget: options.adviceTarget || "build"
        };

        var button = new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            text: options.text,
            onClicked: this.onClicked.bind(this, options.action, true),
            type: options.type
        });
        button.adviceTarget = options.adviceTarget;
        return cleverapps.UI.wrapWithPadding(button, styles.padding);
    },

    createCreateButton: function (options) {
        var styles = cleverapps.styles.InfoView.button;

        var button = new UseEnergyButton({
            energy: options.energy,
            onClicked: this.onClicked.bind(this, options.action, true),
            width: styles.width,
            height: styles.height,
            type: styles.type.default
        });

        return cleverapps.UI.wrapWithPadding(button, styles.padding);
    },

    createInstantButton: function (options) {
        return this.createBuildButton({
            type: cleverapps.styles.InfoView.button.type.secondary,
            text: "InfoView.button.instant^^",
            action: options,
            adviceTarget: "instant"
        });
    },

    createSellButton: function (options) {
        var styles = cleverapps.styles.InfoView.button;

        var button = new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            text: Unit.CalcSellPrice(options.unit) > 0 ? "Sell" : "Remove",
            onClicked: this.onClicked.bind(this, function () {
                cleverapps.focusManager.display({
                    focus: "SellUnitWindow",
                    action: function (f) {
                        new SellUnitWindow(options.unit);
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }
                });
            }),
            type: styles.type.secondary
        });
        return cleverapps.UI.wrapWithPadding(button, styles.padding);
    },

    createUnlockButton: function (options) {
        var styles = cleverapps.styles.InfoView.button;

        var button = new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            text: "Unlock",
            type: styles.type.default,
            onClicked: this.onClicked.bind(this, options.action, true)
        });
        return cleverapps.UI.wrapWithPadding(button, styles.padding);
    },

    createEnergyButton: function (options) {
        var styles = cleverapps.styles.InfoView.button;

        if (options.energy) {
            var button = new UseEnergyButton({
                energy: options.energy,
                onClicked: this.onClicked.bind(this, options.action, true),
                width: styles.width,
                height: styles.height,
                type: styles.type.default
            });
            button.adviceTarget = "energy";
            return cleverapps.UI.wrapWithPadding(button, styles.padding);
        }

        return this.createBuildButton({
            text: "InfoView.button.energy" + (options.isQuick ? "" : "--"),
            action: options.action,
            adviceTarget: "energy"
        });
    },

    createAttackButton: function (options) {
        var styles = cleverapps.styles.InfoView.button;

        var button = new WandsAttackButton({
            wands: options.wands,
            onClicked: this.onClicked.bind(this, options.action, true),
            width: styles.width,
            height: styles.height,
            type: styles.type.default
        });
        button.adviceTarget = "wandsAttack";
        return cleverapps.UI.wrapWithPadding(button, styles.padding);
    },

    createAttackLabel: function (options) {
        var styles = cleverapps.styles.InfoView.label;

        var label = cleverapps.UI.generateOnlyText(options, cleverapps.styles.FONTS.BUTTON_LABEL);
        label.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        label.setDimensions(styles.width, 0);
        label.fitTo(undefined, styles.height);

        return cleverapps.UI.wrapWithPadding(label, styles.padding);
    },

    createSpeedUpButton: function (options) {
        var styles = cleverapps.styles.InfoView.button;

        var eventName = cleverapps.EVENTS.SPENT.RESET;
        if (options.unit && options.unit.getType() === "fruit") {
            eventName = cleverapps.EVENTS.SPENT.FRUIT;
        }
        var buttonOptions = {
            price: options.price || SpeedUpWindow.CalcPrice(options.duration),
            canCoins: options.canCoins !== undefined ? options.canCoins : true,
            onClicked: this.onClicked.bind(this, options.action),
            eventName: eventName,
            speedUp: true,
            source: options.unit,
            type: styles.type.secondary,
            width: styles.width,
            height: styles.height
        };

        var component;
        if (options.unit) {
            component = options.unit.findComponent(Fruit) || options.unit.findComponent(MiniGame);
        }

        if (component && ConfirmSpeedUpWindow.isAvailable(buttonOptions.price)) {
            buttonOptions.confirmWindowOptions = {
                price: options.price,
                timeLeft: this.countdown.getTimeLeft(),
                totalDuration: component.harvestDuration
            };
        }

        var button = new UseGoldButton(buttonOptions);
        button.adviceTarget = "speedup";
        return cleverapps.UI.wrapWithPadding(button, styles.padding);
    },

    createWindow: function (options) {
        var styles = cleverapps.styles.InfoView.guide;

        var button = new cleverapps.UI.HelpButton(function () {
            cleverapps.focusManager.display({
                stack: Game.currentGame.tutorial.checkTargets(options.unit),
                focus: "InfoViewGuide",
                action: function (f) {
                    if (options.season) {
                        new SeasonWindow(options.season);
                    } else if (options.unitsLibrary) {
                        new UnitsLibraryWindow(options.unit);
                    } else if (options.landmark) {
                        new LandMarkDonorWindow(options.landmark);
                    } else {
                        new GuideWindow(options.guideOptions, options.unit);
                    }

                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
            InfoView.CloseInfo();
        });
        button.setAnchorPoint(0.5, 0.5);
        button.setPositionRound(styles);
        return button;
    },

    createUpgradableLevel: function (level) {
        var styles = cleverapps.styles.InfoView.upgradableLevel;

        if (!styles) {
            return;
        }

        var bg = new cc.Sprite(bundles.merge.frames.upgradable_level_png);
        bg.setPositionRound(styles);

        var text = cleverapps.UI.generateImageText(level, cleverapps.styles.FONTS.UPGRADABLE_LEVEL_TEXT);
        text.setAnchorPoint(0.5, 0.5);
        text.setPositionRound(styles.text);
        bg.addChild(text);

        return bg;
    },

    onClicked: function (callback, needInterstitial) {
        if (this.clicked) {
            return;
        }
        this.clicked = true;

        if (callback && typeof callback !== "function") {
            callback = undefined;
            cleverapps.throwAsync("callback is not a function");
        }

        if (needInterstitial && !Game.currentGame.tutorial.isActive()) {
            cleverapps.rewardedAdsManager.showInterstitial(this.createListener(function () {
                InfoView.CloseInfo(callback);
            }));
        } else {
            InfoView.CloseInfo(callback);
        }
    },

    beforeRemove: function () {
        this.performRecursive(function (node) {
            if (node instanceof cleverapps.UI.Button) {
                node.disable();
            }
        });
    }
});

InfoView.SqueezeAndDisplay = function (unit) {
    if (InfoView.blocked) {
        return;
    }

    var wasOpened = InfoView.IsDisplayedFor(unit);
    if (!wasOpened) {
        InfoView.CloseInfo();
    }

    InfoView.blocked = unit;
    unit.squeeze(function () {
        InfoView.blocked = false;
        if (wasOpened) {
            InfoView.CloseInfo();
        } else {
            InfoView.DisplayInfo(unit);
        }
    });
};

InfoView.DisplayInfo = function (unit) {
    if (cleverapps.config.demoMode) {
        InfoView.CreateInfo({
            title: cleverapps.unitsLibrary.getUnitName(unit) + " (" + unit.code + unit.stage + ")"
        }, unit);

        return;
    }

    var info = unit.getActionInfo();
    if (info && unit.onGetView() && !unit.isBlocked() && !Map2d.currentMap.dragging && Game.currentGame.tutorial.isInfoAvailable(unit)) {
        if (InfoView.IsOpened()) {
            InfoView.CloseInfo();
        }

        InfoView.CreateInfo(info, unit);

        unit.playClickSound();
        cleverapps.eventBus.trigger("openInfoView");
    }
};

InfoView.IsDisplayedFor = function (unit) {
    return InfoView.latestParent === unit || InfoView.blocked === unit;
};

InfoView.IsOpened = function () {
    return InfoView.latestParent !== undefined || InfoView.blocked !== undefined;
};

InfoView.Clear = function (unit) {
    if (!unit || InfoView.IsDisplayedFor(unit)) {
        InfoView.latestParent = undefined;
    }
    if (!unit || InfoView.blocked === unit) {
        InfoView.blocked = false;
    }
};

InfoView.CloseInfo = function (action, silent, delay) {
    var latestParent = InfoView.latestParent;
    var duration = delay || 0;

    if (latestParent) {
        InfoView.latestParent = undefined;
        var view = latestParent.onGetView();
        if (!view) {
            return;
        }
        view.outline(true);

        if (!view.getAdditionalView("info")) {
            cleverapps.throwAsync("No additional view of type info!");
        }

        var withAction = Boolean(action);

        if (duration) {
            Game.currentGame.setTimeout(function () {
                view.removeAdditionalView("info", silent);
                latestParent.onInfoClosed(withAction);
            }, duration);
        } else {
            view.removeAdditionalView("info", silent);
            latestParent.onInfoClosed(withAction);
        }

        duration += 100;

        Game.currentGame.setTimeout(function () {
            cleverapps.eventBus.trigger("closeInfoView");
        }, 500);

        cleverapps.userStatus.reportUserAction();
    }

    if (typeof action === "function") {
        Game.currentGame.setTimeout(action, duration);
    }

    if (duration && latestParent) {
        latestParent.block(duration);
    }

    return latestParent;
};

InfoView.CreateInfo = function (info, unit) {
    var styles = cleverapps.styles.InfoView;
    var infoView;

    if (info.actions) {
        infoView = new ResourceView(info, unit);
    } else if (info.simpleInfo) {
        infoView = new SimpleInfoView(info, unit);
    } else {
        infoView = new InfoView(info);
    }

    var parent = unit.onGetView();
    infoView.setPositionRound(parent.width / 2, parent.height + styles.dy);
    infoView.setAnchorPoint(0.5, 0);
    infoView.setLocalZOrder(2);

    parent.animateAppearAdditionalView("info", infoView);
    parent.outline();

    if (!cleverapps.config.wysiwygMode) {
        var scene = cleverapps.scenes.getRunningScene(unit.onGetView());
        scene.makeNodeVisible(infoView);
    }

    cleverapps.windows.on("openWindow", function () {
        InfoView.CloseInfo();
    }, infoView);

    InfoView.latestParent = unit;

    unit.onInfoOpened();
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    INFO_TEXT: {
        name: "nostroke",
        size: 23,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    INFO_BIG_TEXT: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    BUTTON_LABEL: {
        size: 18,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    INFO_TIMER_TEXT: {
        name: "nostroke",
        size: 24,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    INFO_BAR_TEXT: {
        size: 25,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.InfoView = {
    minHeight: 0,
    minWidth: 0,
    dy: 10,

    padding: {
        x: 2,
        top: 12,
        bottom: 16
    },

    pointer: {
        x: { align: "center" },
        y: -5
    },

    rarity: {
        padding: {
            top: 7,
            bottom: 7
        }
    },

    title: {
        width: 185,
        padding: {
            x: 20,
            top: 5,
            bottom: 4
        },
        iconPadding: {
            left: 10,
            right: 20,
            top: 0,
            bottom: 0
        }
    },

    generator: {
        width: 185,
        padding: {
            x: 20,
            y: 5
        }
    },

    description: {
        width: 185,
        height: 60,
        padding: {
            x: 20,
            y: 5
        }
    },

    bar: {
        width: 170,
        padding: {
            x: 20,
            y: 8
        },
        type: ScaledProgressBar.Types.red,

        amount: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    timer: {
        padding: {
            x: 20,
            top: 3,
            bottom: 5
        }
    },

    button: {
        width: 170,
        height: 70,
        padding: {
            x: 20,
            y: 6
        },
        type: {
            default: cleverapps.styles.UI.Button.Images.button_green,
            secondary: cleverapps.styles.UI.Button.Images.button_blue
        }
    },

    label: {
        width: 185,
        height: 55,
        padding: {
            x: 20,
            top: 4,
            bottom: -3
        }
    },

    ingredients: {
        margin: 10,

        padding: {
            x: 10,
            y: 0
        }
    },

    guide: {
        x: { align: "right", dx: 35 },
        y: { align: "top", dy: 35 }
    }
};
