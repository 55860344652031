/**
 * Created by iamso on 02.09.2021
 */

var BattlePassPointsPlanner = function () {
    this.removeWrongPoints();
};

BattlePassPointsPlanner.prototype.getCurrentPoints = function () {
    var mission = cleverapps.missionManager.findLocalPass();

    if (mission) {
        var data = BattlePassPointsPlanner.UNITS[Mission.GetChildType(mission.type)];
        if (data && Families[data.code]) {
            return data;
        }
    }
};

BattlePassPointsPlanner.prototype.removeWrongPoints = function () {
    var currentPoints = this.getCurrentPoints();
    var code = currentPoints && currentPoints.code;

    Map2d.currentMap.listAvailableUnits({ type: "battlepasspoints" }).forEach(function (unit) {
        if (unit.code !== code) {
            unit.onDestruction();
            unit.remove(true);
        }
    });
};

BattlePassPointsPlanner.prototype.spawnOfferUnit = function () {
    var current = this.getCurrentPoints();
    if (!current) {
        return;
    }
    if (Game.currentGame.thirdElementPlanner.hasOffer(ThirdElement.TYPE_PASS_POINTS)) {
        return;
    }

    if (ThirdElement.IsAvailable(ThirdElement.TYPE_PASS_POINTS, current) && Math.random() <= current.prob) {
        this.performSpawn(current);
    }
};

BattlePassPointsPlanner.prototype.performSpawn = function (pointsUnitData) {
    var centerCell = Map2d.currentMap.getScreenCenterCell();
    var offerCell = Map2d.currentMap.findEmptySlot(centerCell.x, centerCell.y);

    if (offerCell) {
        Game.currentGame.thirdElementPlanner.planNext({
            type: ThirdElement.TYPE_PASS_POINTS,
            delay: 1000,
            target: {
                code: pointsUnitData.code,
                stage: pointsUnitData.stage,
                x: offerCell.x,
                y: offerCell.y
            }
        });
    }
};

BattlePassPointsPlanner.prototype.start = function () {
    Game.currentGame.setTimeout(function () {
        Map2d.currentMap.listAvailableUnits().forEach(function (unit) {
            var unitsShopComponent = unit.findComponent(UnitsShopComponent);
            if (unitsShopComponent) {
                unitsShopComponent.checkPrizes();
                unitsShopComponent.checkCrystals();
            }
        });
    }, 500);
};

BattlePassPointsPlanner.UNITS = {};
BattlePassPointsPlanner.UNITS[Mission.TYPE_BUILDPASS] = { code: "bppointsstar", stage: 0, prob: 0.5 };
BattlePassPointsPlanner.UNITS[Mission.TYPE_EXPEDITION_PASS] = { code: "bppointsstar", stage: 0, prob: 0.5 };
BattlePassPointsPlanner.UNITS[Mission.TYPE_COLLECTIONS_PASS] = { code: "bppointsstar", stage: 0, prob: 0.5 };
BattlePassPointsPlanner.UNITS[Mission.TYPE_SALEPASS] = { code: "bppointscrystal", stage: 0, prob: 0.5 };