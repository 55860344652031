/**
 * Created by vladislav on 31/10/2022
 */

var Customers = function () {
    this.list = [];
};

Customers.prototype.onUnitRemoved = function (unit) {
    this.listActive().forEach(function (customer) {
        customer.onUnitRemoved(unit);
    });
};

Customers.prototype.onUnitAvailable = function (unit) {
    this.list.forEach(function (customer) {
        if (customer.getCurrentRecipe()) {
            customer.getCurrentRecipe().replaceWithCoins();
            customer.processUnits(unit);
        }
        if (customer.isStandby()) {
            customer.generateNextRecipe();
        }
    });
};

Customers.prototype.onResourcesChanged = function () {
    this.listActive().forEach(function (customer) {
        customer.updateIngredients();
    });
};

Customers.prototype.updateMarks = function () {
    Map2d.currentMap.listAvailableUnits().forEach(function (unit) {
        unit.setCustomerMark(this.listActive().some(function (customer) {
            return customer.getCurrentRecipe().needsUnit(unit);
        }));
    }.bind(this));
};

Customers.prototype.onFogOpened = function () {
    this.list.forEach(function (customer) {
        if (customer.isStandby()) {
            customer.generateNextRecipe();
        }
    });
};

Customers.prototype.clearWantsWindow = function () {
    this.list.forEach(function (customer) {
        customer.wantsWindow = undefined;
    });
};

Customers.prototype.process = function () {
    if (cleverapps.focusManager.isFocused() || Map2d.currentMap.dragging || cleverapps.gameModes.silentCustomers) {
        return;
    }

    var toOpen;
    this.list.forEach(function (customer) {
        if (customer.getCurrentRecipe()) {
            if (!toOpen && customer.wantsWindow && Map2d.currentMap.getUnit(customer.unit.x, customer.unit.y)) {
                toOpen = customer;
            }
        } else {
            customer.showWaiting();
        }
    });

    if (toOpen) {
        this.showWindow(toOpen);
    }
};

Customers.prototype.showWindow = function (customer) {
    if (!customer) {
        var customers = this.listActive();
        customer = customers.find(function (customer) {
            return customer.isRecipeReady();
        }) || customers[0];
    }

    if (!this._openStack) {
        this._openStack = [];
    }
    this._openStack.push(Date.now() + " " + new Error().stack);
    if (this._openStack.length > 2) {
        this._openStack.shift();
    }

    var isExchange = customer.getCurrentRecipe().getOrder().length > 0;

    cleverapps.focusManager.display({
        stack: Game.currentGame.tutorial.checkTargets(customer && customer.unit) && !cleverapps.windows.isActive(),
        focus: "CustomersWindow",
        actions: [
            function (f) {
                Map2d.currentMap.zoomIn(customer.unit, {
                    position: cleverapps.UI.DOCK_LEFT | cleverapps.UI.DOCK_RIGHT,
                    zoom: 2,
                    centerOffset: {
                        x: 0,
                        y: -cleverapps.resolution.getSceneSize().height / (isExchange ? 6 : 12)
                    },
                    callback: f
                });
            },

            function (f) {
                Map2d.currentMap.highlightUnit(customer.unit, f);
            },

            function (f) {
                var shoppingList = customer.getCurrentRecipe().getShoppingList();
                shoppingList.totalHard && cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");
                shoppingList.totalSoft && cleverapps.focusManager.showControlsWhileFocused("MenuBarCoinsItem");

                if (isExchange) {
                    new ExchangeWindow(customer);
                } else {
                    new UpgradeWindow(customer);
                }

                cleverapps.focusManager.onceNoWindowsListener = f;
            },

            function (f) {
                Map2d.currentMap.customers.listActive().forEach(function (customer) {
                    customer.onUpdateState();
                }, this);
                f();
                Map2d.currentMap.zoomOut();
                Map2d.currentMap.unhighlightUnit();

                Map2d.currentMap.unitGreeters.processFresh();
            }
        ]
    });
};

Customers.prototype.addCustomer = function (customer) {
    if (customer.unit.isLifted()) {
        return;
    }

    this.list.push(customer);
};

Customers.prototype.removeCustomer = function (customer) {
    var index = this.list.indexOf(customer);
    if (index !== -1) {
        this.list.splice(index, 1);
    }
};

Customers.prototype.listReady = function () {
    return this.list.filter(function (customer) {
        return customer.isRecipeReady();
    });
};

Customers.prototype.listActive = function () {
    return this.list.filter(function (customer) {
        return customer.getCurrentRecipe();
    });
};

Customers.prototype.findCustomerForHint = function (options) {
    var codes = options.type ? cleverapps.unitsLibrary.listCodesByType(options.type) : [options.code];
    var stage = options.stage;

    var producesType = function (customerCode) {
        var recipes = CustomerRecipes[cleverapps.travelBook.getCurrentPage().id];
        return recipes[customerCode] && recipes[customerCode].some(function (template) {
            return template.order.some(function (order) {
                var orderCodes = order.type ? cleverapps.unitsLibrary.listCodesByType(order.type) : [order.code];
                return orderCodes.some(function (orderCode) {
                    return codes.some(function (code) {
                        return code === orderCode && (stage === undefined || order.stage === stage || (!Families[code].units[stage].unmergeable && stage > order.stage));
                    });
                });
            });
        });
    };

    var priority = Object.keys(Map2d.currentMap.fogs.config);
    var fakeUnits = Map2d.currentMap.fogs.listFakeUnits({ type: cleverapps.unitsLibrary.getExpeditionUnitType("customer") });

    fakeUnits.sort(function (a, b) {
        var fogA = Map2d.currentMap.getFog(a.x, a.y);
        var fogB = Map2d.currentMap.getFog(b.x, b.y);
        return priority.indexOf(fogA && fogA.fogBlock && fogA.fogBlock.id) - priority.indexOf(fogB && fogB.fogBlock && fogB.fogBlock.id);
    });

    for (var i = 0; i < fakeUnits.length; i++) {
        var fakeUnit = fakeUnits[i];
        for (var unitStage = 0; unitStage < Families[fakeUnit.code].units.length; unitStage++) {
            if (producesType(Unit.GetKey({ code: fakeUnit.code, stage: unitStage }))) {
                var real = this.list.find(function (customer) {
                    return customer.unit.code === fakeUnit.code;
                });
                return real && real.unit || fakeUnit;
            }
        }
    }
};
