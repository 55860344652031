/**
 * Created by iamso on 28.05.21.
 */

var MineContinueWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.reward = Object.assign({
            hard: 0,
            soft: 0,
            energy: 0
        }, options.reward);
        this.callback = options.callback;
        this._super({
            name: "minecontinuewindow",
            title: "MineContinueWindow.title",
            content: this.createContent()
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.MineContinueWindow;

        var text = cleverapps.UI.generateOnlyText("MineContinueWindow.description", cleverapps.styles.FONTS.MINESWEEPERCONTINUE_TEXT);
        text.setAnchorPoint(0.5, 0.5);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.details.width, 0);

        var rewards = this.createRewards();

        var details = new cleverapps.Layout([text, rewards], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.details.margin,
            padding: styles.details.padding
        });
        details.setAnchorPoint(0.5, 0.5);
        details.setPositionRound(details.width / 2, details.height / 2);

        var background = cleverapps.UI.createScale9Sprite(bundles.minesweeper.frames.details_background, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize(details.width, details.height);
        background.setAnchorPoint(0.5, 0.5);
        background.addChild(details);

        var buttons = this.createButtons();

        return new cleverapps.Layout([background, buttons], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createRewards: function () {
        var styles = cleverapps.styles.MineContinueWindow.rewards;

        var goldIcon = new cc.Sprite(bundles.reward_icons.frames.reward_gold_png);
        var coinsIcon = new cc.Sprite(bundles.reward_icons.frames.reward_coin_png);
        var energyIcon = new cc.Sprite(cleverapps.skins.getSlot("reward_energy_png") || bundles.reward_icons.frames.reward_energy_png);

        var goldAmount = cleverapps.UI.generateImageText("x" + this.reward.hard, cleverapps.styles.FONTS.MINESWEEPERCONTINUE_REWARDS_TEXT);
        var coinsAmount = cleverapps.UI.generateImageText("x" + this.reward.soft, cleverapps.styles.FONTS.MINESWEEPERCONTINUE_REWARDS_TEXT);
        var energyAmount = cleverapps.UI.generateImageText("x" + this.reward.energy, cleverapps.styles.FONTS.MINESWEEPERCONTINUE_REWARDS_TEXT);

        return new cleverapps.GridLayout([goldIcon, coinsIcon, energyIcon, goldAmount, coinsAmount, energyAmount], {
            columns: 3,
            margin: styles.margin,
            padding: styles.padding
        });
    },

    createButtons: function () {
        var styles = cleverapps.styles.MineContinueWindow.buttons;

        var continueText = cleverapps.UI.generateOnlyText("MineContinueWindow.continue.caption", cleverapps.styles.FONTS.DARK_TEXT);
        continueText.setDimensions(styles.width, 0);
        continueText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var giveupText = cleverapps.UI.generateOnlyText("MineContinueWindow.giveup.caption", cleverapps.styles.FONTS.DARK_TEXT);
        giveupText.setDimensions(styles.width, 0);
        giveupText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var continueButton = new UseGoldButton({
            price: MineSweeper.REPLAY_PRICE,
            eventName: cleverapps.EVENTS.SPENT.MINESWEEPER,
            width: styles.width,
            height: styles.height,
            onClicked: function () {
                this.callback();
                this.close();
            }.bind(this)
        });

        var giveupButton = new cleverapps.UI.Button({
            text: "GiveUp",
            width: styles.width,
            height: styles.height,
            onClicked: this.close.bind(this),
            type: cleverapps.styles.UI.Button.Images.button_red
        });

        return new cleverapps.GridLayout([continueText, giveupText, continueButton, giveupButton], {
            columns: 2,
            margin: styles.margin,
            padding: styles.padding
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MINESWEEPERCONTINUE_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    MINESWEEPERCONTINUE_REWARDS_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.MineContinueWindow = {
    margin: 25,

    details: {
        width: 760,
        margin: 30,
        padding: {
            x: 50,
            top: 30,
            bottom: 40
        }
    },

    rewards: {
        margin: {
            x: 130,
            y: 10
        }
    },

    buttons: {
        width: 400,
        height: 136,
        margin: {
            x: 50,
            y: 15
        },
        padding: {
            x: 0,
            y: 0
        }
    }
};
