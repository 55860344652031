/**
 * Created by slava on 25/12/19
 */

var Meta = function () {

};

Meta.prototype.getType = function () {
    if (connector.info.source === "playable") {
        return Meta.NONE;
    }

    return cleverapps.config.meta;
};

Meta.prototype.getMainScene = function () {
    if (connector.info.source === "playable") {
        return PlayableAdsScene;
    }

    switch (this.getType()) {
        case Meta.FARM:
            return FarmScene;

        case Meta.HOMEFIX:
            return HomefixScene;

        case Meta.FISHDOM:
            return FishdomScene;

        case Meta.SHORTMETA:
            return ShortMetaScene;

        case Meta.SIMPLE:
            return SimpleMainScene;

        case Meta.NONE:
            return GameScene;

        default:
            return HoseScene;
    }
};

Meta.prototype.getRumble = function () {
    return this.knockoutGame && this.knockoutGame.rumble;
};

Meta.prototype.stopRumble = function () {
    return this.knockoutGame.stopRumble();
};

Meta.prototype.getMainObject = function () {
    switch (this.getType()) {
        case Meta.FARM:
            return cleverapps.farm;

        case Meta.FISHDOM:
            return cleverapps.fishdom;

        case Meta.HOMEFIX:
            return cleverapps.home;

        case Meta.SHORTMETA:
            return cleverapps.shortmeta;

        case Meta.SIMPLE:
            return cleverapps.simple;

        case Meta.HOSE:
            return cleverapps.hose;

        case Meta.NONE:
            return NoMetha;
    }
};

Meta.prototype.wantsToPlay = function (f, level) {
    if (cleverapps.lives && cleverapps.lives.isEmpty()) {
        if (cleverapps.unlimitedLives && cleverapps.unlimitedLives.freeOfferIsAvailable()) {
            cleverapps.unlimitedLives.showFreeOffer(f);
        } else {
            new LivesShopWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }

        return;
    }

    var mainObject = this.getMainObject();

    if (this.getType() === Meta.FARM) {
        var building = level && level.building && level.building.canStillProvideQuestItems()
            ? level.building
            : mainObject.findBuildingForActiveItem() || cleverapps.Random.mathChoose(mainObject.listAvailableProvidesBuildings());

        level = MethaHelper.getCurrentLevel(building);
    } else {
        level = level || MethaHelper.getCurrentLevel();
    }

    if (this.getType() === Meta.HOSE) {
        cleverapps.Plot.isPassedAllEpisodes = levels.user.isPassedAll();
    }

    if (this.knockoutGame) {
        this.knockoutGame.wantsToPlay(f, level);
    } else {
        MethaHelper.start(f, level);
    }
};

Meta.prototype.wantsToReplay = function (f, level) {
    MethaHelper.start(f, level);
};

Meta.HOSE = "hose";
Meta.FARM = "farm";
Meta.HOMEFIX = "homefix";
Meta.SHORTMETA = "shortmeta";
Meta.SIMPLE = "simple";
Meta.NONE = "none";
Meta.FISHDOM = "fishdom";

Meta.SLOT_MAIN = "";
Meta.SLOTS = [Meta.SLOT_MAIN];

if (cleverapps.config.type === "merge") {
    Meta.EXPEDITION_SLOT1 = "1";
    Meta.EXPEDITION_SLOT2 = "2";
    Meta.EXPEDITION_SLOT3 = "3";
    Meta.EXPEDITION_SLOT4 = "4";

    Meta.SLOTS = [Meta.SLOT_MAIN, Meta.EXPEDITION_SLOT1, Meta.EXPEDITION_SLOT2, Meta.EXPEDITION_SLOT3, Meta.EXPEDITION_SLOT4];
}

if (cleverapps.config.meta === "homefix") {
    Meta.LOCATION_SLOT0 = "0";
    Meta.LOCATION_SLOT1 = "1";
    Meta.LOCATION_SLOT2 = "2";

    Meta.SLOTS = [Meta.SLOT_MAIN, Meta.LOCATION_SLOT0, Meta.LOCATION_SLOT1, Meta.LOCATION_SLOT2];
}