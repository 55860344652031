/**
 * Created by spepa on 08.11.2022
 */

var LandmarkView = function (landmark, unitView) {
    this.unitView = unitView;
};

LandmarkView.prototype.clearAnimations = function () {
    if (this._createRemove && !this._createRemove.isDone()) {
        this.unitView.stopAction(this._createRemove);
    }
};

LandmarkView.prototype.onRemove = function () {
    if (this._createRemove && !this._createRemove.isDone()) {
        this.unitView.stopAction(this._createRemove);
    }

    this._createRemove = this.unitView.runAction(new cc.Sequence(
        new cc.ScaleTo(0.2, 0.4).easing(cc.easeBackIn()),
        new cc.RemoveSelf()
    ));
};

LandmarkView.prototype.onCreate = function () {
    this.unitView.setVisible(false);
    this.unitView.setScale(0.4);

    this._createRemove = this.unitView.runAction(new cc.Sequence(
        new cc.DelayTime(0.2),
        new cc.Show(),
        new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut())
    ));
};