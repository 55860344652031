/**
 * Created by Vladislav on 17.10.2024.
 */

var Map2dEdges = function (map) {
    this.map = map;

    this.edges = {};
};

Map2dEdges.prototype.getEdges = function (x, y) {
    var edges = this.edges[x] && this.edges[x][y];
    if (edges === undefined) {
        edges = this.calculateEdges(x, y);

        if (!this.edges[x]) {
            this.edges[x] = {};
        }

        this.edges[x][y] = edges;
    }

    return edges;
};

Map2dEdges.prototype.updateEdges = function (x, y) {
    if (this.edges[x]) {
        delete this.edges[x][y];
    }

    this.getEdges(x, y);
};

Map2dEdges.prototype.addViews = function (x, y) {
    var edges = this.getEdges(x, y);

    if (!edges) {
        return;
    }

    edges.forEach(function (edge) {
        var view = edge.getView && edge.getView();

        if (!view) {
            view = ViewReader.createTopYAnchorElement(edge);
            edge.getView = view.createListener(function () {
                return view;
            });

            this.map.getMapView().addTile(Map2d.LAYER_GROUND, x, y, view);
        } else {
            view.invisible = false;
            cc.renderer.childrenOrderDirty = true;
        }
    }, this);
};

Map2dEdges.prototype.calculateEdges = function (x, y) {
    if (!this.map.isLand(x, y)) {
        return false;
    }
    
    this.map.setPointer(x, y);

    this.directionLocks = {};

    var downInner = this.calculateEdge("edge_down_inner", [Iso.UP_LEFT, Iso.UP_RIGHT]);
    var leftInner = this.calculateEdge("edge_left_inner", [Iso.UP_RIGHT, Iso.DOWN_RIGHT]);
    var rightInner = this.calculateEdge("edge_right_inner", [Iso.UP_LEFT, Iso.DOWN_LEFT]);
    var upInner = this.calculateEdge("edge_up_inner", [Iso.DOWN_LEFT, Iso.DOWN_RIGHT]);

    var downRight = this.calculateEdge("edge_down_right", [Iso.UP_LEFT]);
    var downLeft = this.calculateEdge("edge_down_left", [Iso.UP_RIGHT]);
    var upRight = this.calculateEdge("edge_up_right", [Iso.DOWN_LEFT]);
    var upLeft = this.calculateEdge("edge_up_left", [Iso.DOWN_RIGHT]);

    var downOuter = this.calculateEdge("edge_down_outer", [Iso.UP_ABOVE], [Iso.UP_LEFT, Iso.UP_RIGHT]);
    var upOuter = this.calculateEdge("edge_up_outer", [Iso.DOWN_BELOW], [Iso.DOWN_LEFT, Iso.DOWN_RIGHT]);
    var leftOuter = this.calculateEdge("edge_left_outer", [Iso.HORIZONTAL_RIGHT], [Iso.UP_RIGHT, Iso.DOWN_RIGHT]);
    var rightOuter = this.calculateEdge("edge_right_outer", [Iso.HORIZONTAL_LEFT], [Iso.UP_LEFT, Iso.DOWN_LEFT]);

    var edges = [downInner, leftInner, rightInner, upInner,
        downRight, downLeft, upRight, upLeft,
        downOuter, upOuter, leftOuter, rightOuter].filter(Boolean);

    return edges.length ? edges : false;
};

Map2dEdges.prototype.calculateEdge = function (name, directions, excludeDirections) {
    var terrain = this.map.getGroundTerrainCode(Iso.SAME);
    var directionTerrain = this.map.getGroundTerrainCode(directions[0]);
    var i;

    if (!directionTerrain || terrain === directionTerrain || !this.canLock(directions)) {
        return;
    }

    for (i = 1; i < directions.length; i++) {
        if (directionTerrain !== this.map.getGroundTerrainCode(directions[i])) {
            return;
        }
    }
    if (excludeDirections) {
        for (i = 0; i < excludeDirections.length; i++) {
            if (directionTerrain === this.map.getGroundTerrainCode(excludeDirections[i]) || !this.map.isLand(excludeDirections[i])) {
                return;
            }
        }
    }

    var directionTerrainPriority = Map2dEdges.TERRAIN_PRIORITY[directionTerrain] || 0;
    var terrainPriority = Map2dEdges.TERRAIN_PRIORITY[terrain] || 0;

    if (directionTerrainPriority < terrainPriority && terrain) {
        return;
    }

    var amount = 0;
    while (this.map.getTerrainFrames(directions[0])[name + "_" + amount]) {
        amount++;
    }

    var result;

    if (amount) {
        var index = (this.map.pointer.x + this.map.pointer.y) % amount;
        result = this.map.getTerrainFrameData(name + "_" + index, directions[0]);
    }

    if (!result && this.map.getTerrainFrames(directions[0])[name]) {
        result = this.map.getTerrainFrameData(name, directions[0]);
    }

    if (result) {
        this.lock(directions);
    }

    return result;
};

Map2dEdges.prototype.canLock = function (directions) {
    return directions.every(function (direction) {
        return !this.directionLocks[direction.key];
    }, this);
};

Map2dEdges.prototype.lock = function (directions) {
    directions.forEach(function (direction) {
        this.directionLocks[direction.key] = true;
    }, this);
};

Map2dEdges.TERRAIN_PRIORITY = {
    g: -1
};
