/**
 * Created by andrey on 09.02.2021.
 */

Map2dDecorator.prototype.getSaveInfo = function () {
    return {
        code: this.code,
        x: this.x,
        y: this.y,
        dx: this.dx || 0,
        dy: this.dy || 0,
        rotation: this.rotation || 0,
        scaleX: this.scaleX || 1,
        scaleY: this.scaleY || 1,
        largeBox: this.largeBox
    };
};

Map2dDecorator.prototype.setLargeBox = function (largeBox) {
    var oldLarge = this.isLarge();

    this.largeBox = largeBox;
    delete this.largeBoxScaled;

    if (oldLarge === this.isLarge()) {
        return;
    }

    var largeDecorators = Map2d.currentMap.decorators.largeDecorators;

    if (this.isLarge()) {
        largeDecorators.push(this);
    } else {
        cc.arrayRemoveObject(largeDecorators, this);
    }
};

Map2dDecorator.prototype.updateCell = function (x, y, dx, dy) {
    Map2d.currentMap.decorators.updateCell(this, x, y);
    this.x = x;
    this.y = y;
    this.dx = dx;
    this.dy = dy;
    this.updateLargeBox();
    Map2d.currentMap.saveLevel();
};

Map2dDecorator.prototype.setupChildren = function (dx, dy, rotation) {
    this.dx = dx;
    this.dy = dy;
    this.rotation = rotation;
    this.updateLargeBox();
    Map2d.currentMap.saveLevel();
};

Map2dDecorator.prototype.updateScale = function (scaleX, scaleY) {
    this.scaleX = Math.round(scaleX * 100) / 100;
    this.scaleY = Math.round(scaleY * 100) / 100;
    this.updateLargeBox();
    Map2d.currentMap.saveLevel();
};

Map2dDecorator.prototype.getViewClass = cleverapps.extendFunc(Map2dDecorator.prototype.getViewClass, function () {
    if (cleverapps.config.editorMode) {
        return EditorMap2dDecoratorView;
    }
    return this._super.apply(this, arguments);
});
