/**
 * Created by andrey on 01.03.2021.
 */

var IntactFamilySyncer = function (code, slot) {
    FamilySyncer.call(this, code, slot);
};

IntactFamilySyncer.prototype = Object.create(FamilySyncer.prototype);
IntactFamilySyncer.prototype.constructor = IntactFamilySyncer;

IntactFamilySyncer.prototype.getKey = function (x, y) {
    return UnitPacker.compactCoordinates(x) + UnitPacker.compactCoordinates(y);
};

IntactFamilySyncer.prototype.parseKey = function (key) {
    var coordinates = UnitPacker.splitCoordinates(key, 0);
    return {
        x: UnitPacker.expandCoordinates(coordinates.x),
        y: UnitPacker.expandCoordinates(coordinates.y)
    };
};

IntactFamilySyncer.prototype.encode = function () {
    return true;
};

IntactFamilySyncer.prototype.decode = function () {
    return {
        code: "intact",
        stage: 0
    };
};

IntactFamilySyncer.prototype.getInfo = function () {
    return Object.keys(this.data).join("");
};

IntactFamilySyncer.prototype.updateInfo = function (data) {
    this.data = {};
    data = data || "";

    for (var index = 0; index < data.length;) {
        var coordinates = UnitPacker.splitCoordinates(data, index);
        this.data[coordinates.x + coordinates.y] = true;
        index += coordinates.x.length + coordinates.y.length;
    }
};
