/**
 * Created by spepa on 01.11.2022
 */

var UnitLibraryIconBg = cc.Scale9Sprite.extend({
    ctor: function (unit) {
        this._super(bundles.merge.frames.icon_background_light);
        this.setContentSize2(cleverapps.styles.UnitLibraryIconBg);

        this.unit = unit;
        this.closed = !cleverapps.unitsLibrary.isOpened(this.unit);

        this.createIcon();
        this.createPassIcon();
        this.createFeedIcon();
        this.createTitle();
    },

    createIcon: function () {
        var styles = cleverapps.styles.UnitLibraryIconBg;
        var icon = this.icon = UnitView.getUnitImage(this.unit, { useLibraryAnchors: true, preferStatic: true });
        cleverapps.UI.fitToBox(icon, styles.icon);
        icon.setPositionRound(this.width / 2 + styles.icon.x, this.height / 2 + styles.icon.y);
        this.addChild(icon);

        if (this.closed) {
            cleverapps.UI.convertToGrayShape(icon);

            if (this.unit.unknown) {
                cleverapps.UI.fitToBox(icon, styles.iconUnknown);
                icon.setPositionRound(styles.iconUnknown);
                icon.setColor(cleverapps.styles.COLORS.UNITICON_COLOR);
            }
        }
    },

    createTitle: function () {
        var styles = cleverapps.styles.UnitLibraryIconBg.title;
        var text = cleverapps.unitsLibrary.getUnitName(this.unit);
        text = cleverapps.splitHalfByWord(text).join("\n");

        var title = this.title = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.UNITICON_TITLE);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        title.fitTo(styles.width, styles.height);
        title.setPositionRound(styles);
        this.addChild(title);

        if (this.closed) {
            title.setCascadeOpacityEnabled(true);
            title.setCascadeColorEnabled(true);
            title.setOpacity(120);
        }
    },

    createPassIcon: function () {
        if (this.unit.unknown) {
            return;
        }

        if (!Families[this.unit.code].units[this.unit.stage].bpPoints) {
            return;
        }

        var styles = cleverapps.styles.UnitLibraryIconBg;

        var mission = cleverapps.missionManager.findLocalPass();
        if (mission) {
            var icon = new cc.Sprite(bundles.reward_icons_pass.frames.pass_star_png);
            icon.setPositionRound(styles.passIcon);
            this.addChild(icon);
        }
    },

    createFeedIcon: function () {
        if (this.unit.unknown) {
            return;
        }
        var unitData = Families[this.unit.code].units[this.unit.stage];
        if (!unitData.feedable || !["clpet", "clpetrare", "clpetlegend"].includes(Families[this.unit.code].type)) {
            return;
        }

        var icon = new cc.Sprite(bundles.merge.frames.feedable_icon);
        icon.setPositionRound(cleverapps.styles.UnitLibraryIconBg.feedIcon);
        this.addChild(icon);
    },

    updateStatus: function () {
        if (cleverapps.unitsLibrary.isFresh(this.unit)) {
            this.createPrize();
            if (cleverapps.config.name !== "wondermerge") {
                this.addRays();
            }
            this.icon.setVisible(false);
        } else {
            this.removeRays();

            if (this.prize) {
                this.prize.removeFromParent();
                delete this.prize;
                this.icon.setVisible(true);
            }
        }

        if (this.closed && cleverapps.unitsLibrary.isOpened(this.unit)) {
            this.closed = false;
            this.icon.setOpacity(255);
            this.icon.setColor(cleverapps.styles.COLORS.UNITICON_COLOR);
            this.title.setOpacity(255);
            this.title.setColor(cleverapps.styles.COLORS.UNITICON_COLOR);
        }
    },

    createPrize: function () {
        if (this.prize) {
            return;
        }

        var prizeIcon = new cc.Sprite(bundles.windows.frames.big_prize_png);

        this.prize = new cc.Node();
        this.prize.setAnchorPoint(0.5, 0.5);
        this.addChild(this.prize);
        this.prize.setContentSize2(prizeIcon.getContentSize());
        this.prize.addChild(prizeIcon);
        prizeIcon.setPositionRound(this.prize.width / 2, this.prize.height / 2);
        this.prize.setPositionRound(this.icon.getPosition());
    },

    addRays: function () {
        if (this.rays) {
            return;
        }
        this.rays = AnimationsLibrary.rays(this.prize, { skin: "white" });
    },

    removeRays: function () {
        if (this.rays) {
            this.rays.runAction(new cc.Sequence(
                new cc.ScaleTo(0.5, 0),
                new cc.RemoveSelf()
            ));
            delete this.rays;
        }
    }
});

cleverapps.styles.UnitLibraryIconBg = {
    width: 260,
    height: 340,

    icon: {
        x: 0,
        y: -40,
        width: 200,
        height: 190,
        maxScale: 1.3
    },

    iconUnknown: {
        x: { align: "center" },
        y: { align: "center", dy: -25 }
    },

    rays: {
        x: { align: "center" },
        y: { align: "center", dy: -40 }
    },

    title: {
        x: { align: "center" },
        y: { align: "center", dy: 110 },
        width: 200,
        height: 100
    },

    passIcon: {
        x: { align: "left", dx: 20 },
        y: { align: "bottom", dy: 45 }
    },

    feedIcon: {
        x: { align: "right", dx: -13 },
        y: { align: "bottom", dy: 17 }
    }
};