/**
 * https://firebase.google.com/docs/remote-config/get-started?platform=web
 *
 * Created by andrey on 12.08.2024
 */

var RemoteJson = function (json) {
    cleverapps.EventEmitter.call(this);

    this.json = json;

    if (!json.remote) {
        return;
    }

    var remote = json.remote.replace("firebase://", "").split("/");
    var project = this.name = remote[0];
    var key = this.key = remote[1];

    if (!RemoteProject.isAvailable(project)) {
        return;
    }

    var remoteProject = RemoteProject.GetProject(project);

    remoteProject.setDefaultValue(key, json);
    remoteProject.initializeThrottle();
    remoteProject.fetchAndActivateThrottle();

    this.project = remoteProject;

    remoteProject.on("update", this.update.bind(this));
    this.update();
};

RemoteJson.prototype = Object.create(cleverapps.EventEmitter.prototype);
RemoteJson.prototype.constructor = RemoteJson;

RemoteJson.prototype.getJson = function () {
    return this.json;
};

RemoteJson.prototype.update = function () {
    var json = this.project.getValue(this.key);

    if (JSON.stringify(this.json) !== JSON.stringify(json)) {
        console.log("update " + this.key, json);

        this.json = json;

        this.trigger("update");
    }
};

RemoteJson.prototype.initialize = function (callback) {
    if (!this.project || this.project.initialized) {
        callback();
    } else {
        this.project.initializeThrottle();
        this.project.once("initialize", callback);
    }
};