/**
 * Created by r4zi4l on 17.06.2021
 */

var SoftFeastMissionWindow = FeastMissionWindow.extend({
    onWindowLoaded: function (mission) {
        this._super({
            mission: mission,
            pack: {
                type: "soft",
                price: 250,
                prizes: RewardsConfig.SoftFeast.offer
            },
            name: "SoftFeastMissionWindow",
            controls: ["MenuBarGoldItem", "MenuBarLivesItem", "MenuBarCoinsItem"],
            notCloseByTouchInShadow: true
        });
    },

    getPerson: function () {
        var person = {
            role: "elf",
            emotion: "happy"
        };
        if (cleverapps.config.name === "wondermerge") {
            person = "worker";
        }
        return person;
    },

    getGuideOptions: function () {
        return {
            name: "SoftFeastGuideWindow",
            bundle: bundles.softfeastguidewindow
        };
    },

    createTable: function () {
        var table = this._super();

        if (bundles.softfeast_window.frames.coin1) {
            var styles = cleverapps.styles.SoftFeastMissionWindow;

            var coin1 = new cc.Sprite(bundles.softfeast_window.frames.coin1);
            coin1.setPositionRound(styles.coin1);
            table.addChild(coin1);

            var coin2 = new cc.Sprite(bundles.softfeast_window.frames.coin2);
            coin2.setPositionRound(styles.coin2);
            table.addChild(coin2);
        }

        return table;
    }
});

cleverapps.styles.SoftFeastMissionWindow = {
    pack: {
        x: { align: "center", dx: -78 },
        y: { align: "center", dy: -487 }
    }
};
