/**
 * Created by mac on 12/28/20
 */

var MonsterMine = {
    prize: function (level, unit, bonus) {
        var key = "" + level;

        if (level === unit.getData().mineable.length - 1 && unit.stage > 0) {
            key = "treasure" + level;
        }

        return bonus ? MonsterMine.bonusPrize[key] : MonsterMine.basicPrize[key];
    }
};

MonsterMine.basicPrize = {
    0: [{
        units: [{ code: 'coins', stage: 0 }]
    }],
    1: [{
        amount: 3,
        units: [{code: 'coins', stage: 0}]
    }],
    2: [{
        units: [{currentType: "hero", stage: 0 }]
    }, {
        units: [{code: 'coins', stage: 1}]
    }],
    3: [{
        amount: 3,
        units: [{code: 'coins', stage: 1}]
    }, {
        amount: 2,
        units: [{currentType: "hero", stage: 0 }]
    }],
    4: [{
        amount: 2,
        units: [{code: 'coins', stage: 2}]
    }, {
        amount: 2,
        units: [{currentType: "hero", stage: 1 }]
    }],

    treasure3: [{
        units: [{code: 'seasonchest', stage: 0}]
    }],
    treasure5: [{
        units: [{code: 'seasonchest', stage: 1}]
    }]
};

MonsterMine.bonusPrize = {
    0: [{
        units: [{code: 'coins', stage: 1}]
    }],
    1: [{
        amount: 3,
        units: [{code: 'coins', stage: 1}]
    }],
    2: [{
        units: [{currentType: "hero", stage: 1 }]
    }, {
        amount: 2,
        units: [{code: 'coins', stage: 2}]
    }],
    3: [{
        amount: 3,
        units: [{code: 'coins', stage: 2}]
    }, {
        amount: 2,
        units: [{currentType: "hero", stage: 1 }]
    }],
    4: [{
        amount: 2,
        units: [{code: 'coins', stage: 3}]
    }, {
        amount: 2,
        units: [{currentType: "hero", stage: 2 }]
    }],

    treasure3: [{
        amount: 2,
        units: [{code: 'seasonchest', stage: 0}]
    }],
    treasure5: [{
        units: [{code: 'seasonchest', stage: 2}]
    }]
};

