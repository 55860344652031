/**
 * Created by andrey on 01.02.2021.
 */

var FamilyLayout = cc.Node.extend({
    ctor: function (code, icons, options) {
        var styles = cleverapps.styles.FamilyLayout;

        options = options || {};
        this.options = options;

        this._super();

        this.code = code;
        this.icons = icons;

        this.grid = this.createGrid(icons, options.columns || 6);

        var background = this.background = cleverapps.UI.createScale9Sprite(bundles.windows.frames.windows_details_bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(options.width || this.grid.width, options.height || this.grid.height);
        background.addChild(this.grid);

        this.setContentSize2(background.getContentSize());
        this.setAnchorPoint(0.5, 0.5);

        background.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(background);

        if (bundles.windows.frames.details_rings) {
            styles.rings.forEach(function (style) {
                var ring = new cc.Sprite(bundles.windows.frames.details_rings);
                ring.setPositionRound(style);
                background.addChild(ring);
            });
        }

        if (options.title !== false) {
            this.title = this.createTitle(options.title || cleverapps.unitsLibrary.getFamilyName(code));
            this.addChild(this.title);
        }
    },

    gridSeparator: function () {
        if (cleverapps.config.type !== "merge") {
            return;
        }

        return {
            horizontal: cleverapps.styles.FamilyLayout.grid.separator
        };
    },

    createGrid: function (icons, columns) {
        var styles = cleverapps.styles.FamilyLayout;

        var grid = new cleverapps.GridLayout(icons, {
            columns: columns,
            align: {
                y: cleverapps.GridLayout.ALIGN_END
            },
            padding: styles.grid.padding,
            margin: this.options.margin || styles.grid.margin,
            separator: this.gridSeparator()
        });
        grid.setPositionRound(this.options.gridPosition || styles.grid);
        return grid;
    },

    createTitle: function (title) {
        var styles = cleverapps.styles.FamilyLayout.title;

        var text = cleverapps.UI.generateOnlyText(title, cleverapps.styles.FONTS.LIGHT_TITLE_TEXT);
        var rect = cc.rectAddPadding(cc.rect(0, 0, styles.fitToLayoutWidth ? this.width : text.width, text.height), cc.padding(styles.padding));

        var background = cleverapps.UI.createScale9Sprite(bundles.windows.frames.family_header, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(rect.width, background.height);
        background.setPositionRound(styles);

        background.addChild(text);
        text.setPositionRound(-rect.x + (styles.fitToLayoutWidth ? this.width / 2 : text.width / 2), -rect.y + background.height / 2);

        return background;
    },

    getStyles: function () {
        return cleverapps.styles.FamilyLayout;
    }
});

cleverapps.styles.FamilyLayout = {
    title: {
        x: { align: "center" },
        y: { align: "top", dy: 30 },

        padding: {
            x: 55,
            y: 6
        }
    },

    grid: {
        x: { align: "left" },
        y: { align: "center", dy: -40 },

        padding: {
            x: 50,
            y: 85
        },

        margin: {
            x: 44,
            y: 20
        },

        separator: {
            x: { align: "center" },
            y: { align: "top", dy: -140 }
        }
    }
};