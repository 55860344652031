/**
 * Created by r4zi4l on 22.06.2022
 */

var RewardsList = function (reward, options) {
    this.options = options || {};
    this.rewards = [];

    Object.keys(reward).forEach(function (type) {
        var value = reward[type];

        if (!RewardTypes[type]) {
            if (cleverapps.config.debugMode) {
                throw "unknown reward type '" + type + "'";
            }
            cleverapps.throwAsync("unknown reward type '" + type + "'");
            return;
        }

        if (!value) {
            return;
        }

        if (RewardTypes[type].parse) {
            this.rewards = this.rewards.concat(RewardTypes[type].parse(value, this.options));
        } else {
            this.rewards.push(new Reward(type, value, this.options));
        }
    }, this);
    this.rewards = this.sortRewards();

    this.commonRewards = this.rewards.filter(function (reward) {
        return !Reward.isCustom(RewardTypes[reward.type].flyingAnimation);
    });
    this.customRewards = this.rewards.filter(function (reward) {
        return RewardTypes[reward.type].flyingAnimation === Reward.CUSTOM_COLLECT;
    });
    this.fullScreenRewards = this.rewards.filter(function (reward) {
        return RewardTypes[reward.type].flyingAnimation === Reward.FULL_SCREEN_CUSTOM_ANIMATION;
    });
};

RewardsList.prototype.sortRewards = function () {
    return this.rewards.filter(function (reward) {
        return !reward.isFlyingUnderShadow();
    }).concat(this.rewards.filter(function (reward) {
        return reward.isFlyingUnderShadow();
    }));
};

RewardsList.prototype.updateViewAmount = function () {
    this.rewards.forEach(function (reward) {
        return reward.updateViewAmount();
    });
};

RewardsList.prototype.receiveRewards = function () {
    if (this.received) {
        return;
    }
    this.received = true;

    this.rewards.forEach(function (reward) {
        reward.receiveReward();
    });
};

RewardsList.prototype.onAnimationFinished = function () {
    this.rewards.forEach(function (reward) {
        reward.onAnimationFinished();
    });
};

RewardsList.prototype.listRewards = function () {
    return this.rewards;
};

RewardsList.prototype.listControls = function () {
    return this.rewards.reduce(function (controls, reward) {
        return controls.concat(reward.listControls());
    }, []);
};

RewardsList.prototype.listBundles = function () {
    return this.rewards.reduce(function (controls, reward) {
        return controls.concat(reward.listBundles());
    }, []);
};

RewardsList.prototype.isBigReward = function () {
    return this.rewards.some(function (reward) {
        return reward.type === "units" && (reward.value.code === "coinsplant" || reward.value.code === "rubiesplant" || reward.value.code === "magicplant" && reward.value.stage === 5);
    });
};

RewardsList.prototype.collectRewardsAnimation = function (source, options) {
    options = options || {};

    var jumpSides = ["left", "right"];
    var delay = options.delay || 0;
    var rewardsInterval = options.rewardsInterval;
    var side = 0;
    var deltaOffset = 0;
    var customRewardIndex = 0;

    this.commonRewards.forEach(function (reward) {
        var rewardOptions = Object.assign({}, options, {
            jumpSide: jumpSides[side % jumpSides.length],
            delay: delay,
            deltaOffset: deltaOffset,
            customRewardIndex: customRewardIndex
        });

        reward.collectRewardsAnimation(source, rewardOptions);

        if (RewardTypes[reward.type] && RewardTypes[reward.type].flyingAnimation === Reward.CUSTOM_COLLECT) {
            customRewardIndex++;
        }

        if (!options.allAtOnce) {
            side += 1;
            delay += rewardsInterval === undefined ? 500 + reward.getFlyingAmount() * 250 : rewardsInterval;
            deltaOffset += cleverapps.styles.RewardsList.deltaOffset;
        }
    });
    return delay || 0;
};

RewardsList.prototype.underShadowRewardIndex = function () {
    for (var index = 0; index < this.rewards.length; ++index) {
        if (this.rewards[index].isFlyingUnderShadow()) {
            return index;
        }
    }

    return -1;
};

RewardsList.sumRewards = function (reward1, reward2) {
    var result = {};
    var copySumFunction = function (from, to, property) {
        if (typeof from[property] === "number") {
            to[property] = from[property] + (to[property] ? to[property] : 0);
        } else if (typeof from[property] === "string") {
            var time = cleverapps.parseInterval(from[property]) + (to[property] ? cleverapps.parseInterval(to[property]) : 0);
            var seconds = time / 1000;
            var minutes = seconds / 60;

            to[property] = minutes + " minutes";
        } else {
            if (to[property] === undefined) {
                to[property] = {};
            }
            var keys = Object.keys(from[property]);
            for (var i = 0; i < keys.length; i++) {
                copySumFunction(from[property], to[property], keys[i]);
            }
        }
    };
    Object.keys(reward1).forEach(function (type) {
        copySumFunction(reward1, result, type);
    });
    Object.keys(reward2).forEach(function (type) {
        copySumFunction(reward2, result, type);
    });
    return result;
};

RewardsList.calcCost = function (rewards) {
    var rewardList;
    if (rewards instanceof RewardsList) {
        rewardList = rewards;
    } else {
        rewardList = new RewardsList(rewards);
    }

    return rewardList.rewards.reduce(function (sum, reward) {
        return sum + reward.calcPrice();
    }, 0);
};

cleverapps.styles.RewardsList = {
    deltaOffset: -28
};