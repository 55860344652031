/**
 * Created by Ivan on 18.01.2023
 */
// eslint-disable-next-line no-unused-vars
var EditorFogBalloon = function (tile, block) {
    FogBalloon.apply(this, arguments);

    this.moveTo = function () {};
    this.onDragMove = function () {};
};

EditorFogBalloon.prototype = Object.create(FogBalloon.prototype);
EditorFogBalloon.prototype.constructor = EditorFogBalloon;

EditorFogBalloon.prototype.hide = function () {
};

EditorFogBalloon.prototype.canMoveTo = function (x, y) {
    var minDistanceToFogBlock = Infinity;
    var MAX_CELL_FOGBLOCK_DEVIATION = 5;

    if (cc.pointEqualToPoint(this.block.head, cc.p(x, y))) {
        return false;
    }

    this.block.tiles.forEach(function (tile) {
        var tileCell = cc.p(tile.x, tile.y);
        var cell = cc.p(x, y);
        minDistanceToFogBlock = Math.min(minDistanceToFogBlock, cc.getDistanceChebyshev(cell, tileCell));
    });

    return minDistanceToFogBlock <= MAX_CELL_FOGBLOCK_DEVIATION;
};

EditorFogBalloon.prototype.setPosition = function (x, y) {
    this.moveTo(x, y);

    this.block.moveBalloon(x, y);
};

EditorFogBalloon.prototype.getShape = function () {
    return FamiliesHelper.ShapeCell;
};
