/**
 * Created by vladislav on 30.12.2021
 */

cleverapps.Boosters.CONFIG = {};

cleverapps.Boosters.TYPES = {
    CHEAP: "cheap",
    MEDIUM: "medium",
    EXPENSIVE: "expensive"
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_CELL] = {
    model: CellBooster,
    type: cleverapps.Boosters.TYPES.CHEAP,
    limit: AdsLimits.TYPES.BOOSTER_1,
    icon_moving: bundles.game.frames.cellbooster_moving_png,
    icon: bundles.game.frames.cellbooster_png,
    icon_active: bundles.game.frames.cellbooster_active_png,
    available: {
        level: 1.6
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_LINE] = {
    model: LineBooster,
    type: cleverapps.Boosters.TYPES.MEDIUM,
    limit: AdsLimits.TYPES.BOOSTER_2,
    icon_moving: bundles.game.frames.linebooster_moving_png,
    icon: bundles.game.frames.linebooster_png,
    icon_active: bundles.game.frames.linebooster_active_png,
    available: {
        level: 2.53
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_COLOR] = {
    model: ColorBooster,
    type: cleverapps.Boosters.TYPES.EXPENSIVE,
    limit: AdsLimits.TYPES.BOOSTER_3,
    icon_moving: bundles.game.frames.colorbooster_moving_png,
    icon: bundles.game.frames.colorbooster_png,
    icon_active: bundles.game.frames.colorbooster_active_png,
    available: {
        level: 3.33
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_HINT] = {
    model: HintBooster,
    type: cleverapps.config.name === "scramble" ? cleverapps.Boosters.TYPES.EXPENSIVE : cleverapps.Boosters.TYPES.CHEAP,
    force: Forces.FREE_HINT_FORCE,
    limit: AdsLimits.TYPES.BOOSTER_1,
    available: {
        level: 0.07,
        olympics: {
            level: 0.25
        }
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_OPEN_LETTER] = {
    model: OpenLetterBooster,
    type: cleverapps.Boosters.TYPES.EXPENSIVE,
    force: Forces.FREE_HINT_FORCE,
    limit: AdsLimits.TYPES.BOOSTER_1,
    available: {
        level: 0.07,
        debugMode: true
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_WILDCARD] = {
    model: WildcardBooster,
    type: cleverapps.Boosters.TYPES.EXPENSIVE,
    limit: AdsLimits.TYPES.BOOSTER_3,
    available: {
        level: 0.65
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_SHUFFLE] = {
    model: ShuffleBooster,
    force: Forces.FREE_SHUFFLE_FORCE,
    type: cleverapps.Boosters.TYPES.CHEAP,
    limit: AdsLimits.TYPES.BOOSTER_2,
    available: {
        level: 0.25,
        projectName: ["scramble"]
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_BRUSH] = {
    model: BrushBooster,
    force: Forces.FREE_PAINT_FORCE,
    type: cleverapps.Boosters.TYPES.EXPENSIVE,
    limit: AdsLimits.TYPES.BOOSTER_3,
    available: {
        level: 0.8
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_DISCOVER] = {
    model: DiscoverBooster,
    force: Forces.FREE_HINT_FORCE,
    limit: AdsLimits.TYPES.BOOSTER_1,
    type: cleverapps.Boosters.TYPES.CHEAP,
    available: {
        level: 0.8
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_TILE_SHUFFLE] = {
    model: TileShuffleBooster,
    force: Forces.FREE_SHUFFLE_FORCE,
    type: cleverapps.Boosters.TYPES.CHEAP,
    executeEffect: bundles.game.urls.shuffle_booster_effect,
    limit: AdsLimits.TYPES.BOOSTER_2,
    available: {
        level: 0.91,
        types: ["tile3"]
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_UNDO] = {
    model: UndoBooster,
    force: Forces.FREE_UNDO_FORCE,
    type: cleverapps.config.type === "tile3" ? cleverapps.Boosters.TYPES.MEDIUM : cleverapps.Boosters.TYPES.CHEAP,
    disableForceOnInactive: true,
    executeEffect: bundles.game.urls.undo_booster_effect,
    limit: AdsLimits.TYPES.BOOSTER_1,
    available: {
        level: 0.4
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_VACUUM] = {
    model: VacuumBooster,
    force: Forces.FREE_VACUUM_FORCE,
    disableForceOnInactive: true,
    type: cleverapps.Boosters.TYPES.EXPENSIVE,
    executeEffect: bundles.game.urls.return_booster_effect,
    limit: AdsLimits.TYPES.BOOSTER_3,
    available: {
        level: 1.6,
        types: ["tile3"]
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_REPLACE_ALL] = {
    model: ReplaceAllBooster,
    force: Forces.FREE_REPLACE_ALL_FORCE,
    disableForceOnInactive: true,
    type: cleverapps.Boosters.TYPES.MEDIUM,
    executeEffect: bundles.game.urls.booster_replace_effect,
    limit: AdsLimits.TYPES.BOOSTER_2,
    available: {
        level: 0.53,
        types: ["blocks"]
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_CLEAR_PIECES] = {
    model: ClearPiecesBooster,
    force: Forces.FREE_NEXT_PIECES_FORCE,
    disableForceOnInactive: true,
    type: cleverapps.Boosters.TYPES.EXPENSIVE,
    limit: AdsLimits.TYPES.BOOSTER_2,
    available: {
        level: 0.66,
        types: ["blocks"]
    }
};

cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_UNDO_PIECE] = {
    model: UndoPieceBooster,
    force: Forces.FREE_UNDO_PIECE,
    disableForceOnInactive: true,
    type: cleverapps.Boosters.TYPES.CHEAP,
    executeEffect: bundles.game.urls.booster_undo_effect,
    limit: AdsLimits.TYPES.BOOSTER_1,
    available: {
        level: 0.4,
        types: ["blocks"]
    }
};

if (cleverapps.config.type === "klondike") {
    cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_UNDO].price = { type: cleverapps.Boosters.TYPES.CHEAP };
    cleverapps.Boosters.CONFIG[cleverapps.Boosters.TYPE_HINT].price = { type: cleverapps.Boosters.TYPES.MEDIUM };
}
