/**
 * Created by slava on 23.03.17.
 */

cleverapps.FixedWidthScene = cc.Scene.extend({
    listBundles: function () {
        return [];
    },

    cachedBundles: function () {
        return [];
    },

    lazyBundles: function () {
        return [];
    },

    destructor: function () {
        if (Game.currentGame) {
            Game.currentGame.stop();
            Game.currentGame = undefined;
        }

        cleverapps.sideBar.clearListeners();
        cleverapps.centerHint.destructor();

        cleverapps.menuBar.clean();
        cleverapps.aims.clear();
        cleverapps.focusManager.clearTempControls();

        this.updateDynamicNodes = cleverapps.EMPTY_FUNCTION;
        this.updateDynamicNodesThrottled = cleverapps.EMPTY_FUNCTION;

        this.performRecursive(function (node) {
            cc.eventManager.removeListeners(node);
            node.stopAllActions();
            if (node instanceof cleverapps.Spine) {
                node.setCompleteListener();
            }
        });
    },

    listBaseBundles: function () {
        cleverapps.eventManager.setCurrentFeatures();
        cleverapps.skins.update(this);

        var result = ["main"];

        if (cleverapps.config.debugMode) {
            result.push("dev_resources");
            result.push("orangery");
        }
        if (cleverapps.skins.getBundles()) {
            result = result.concat(cleverapps.skins.getBundles());
        }

        return result;
    },

    ctor: function (options) {
        this._super();

        this.avoidNodes = {};
        this.dynamicNodes = {};

        this.level = options && options.level;

        var screen = this.screen = new cc.Node();
        screen.setLocalZOrder(-10);
        this.addChild(screen);

        this.updateDynamicNodesThrottled = cleverapps.accumulate(0, this.createListener(this.updateDynamicNodes.bind(this)));
    },

    closeAction: function () {
        if (cleverapps.focusManager.isFocused()) {
            return;
        }

        if (cleverapps.git && !cleverapps.git.handleSceneClose()) {
            return;
        }

        this._closeAction();
    },

    _closeAction: function () {
        cleverapps.focusManager.display({
            focus: "closeScene",
            action: function (f) {
                cleverapps.travelBook.gotoMainScene(f);
            }
        });
    },

    prepareBundles: function () {
        this.bundles = this.listBundles().concat(this.listBaseBundles());
        this.bundles = cleverapps.unique(this.bundles.filter(function (name) {
            return name && bundles[name];
        }));
    },

    load: function (f) {
        var callback = cleverapps.wait(2, f);

        if (this.level) {
            this.level.load(callback);
        } else {
            callback();
        }

        cleverapps.lazyAssets.setPreferredBundles(this.lazyBundles());

        var toLoad = cleverapps.substract(this.bundles, this.prevSceneBundles);

        cleverapps.bundleLoader.loadBundles(toLoad, {
            onSuccess: callback,
            onFailure: function () {
                var options = {
                    focus: "loadSceneBundlesFailure",
                    action: function () {
                        new RestartWindow();
                    }
                };

                if (cleverapps.focusManager.isFocused()) {
                    cleverapps.focusManager.distract(options);
                } else {
                    cleverapps.focusManager.display(options);
                }
            }
        });
    },

    unload: function (nextScene) {
        if (this.level) {
            this.level.unload();
        }

        nextScene.prevSceneBundles = this.bundles;
        var toDelete = cleverapps.substract(this.bundles, nextScene.bundles);

        cleverapps.bundleLoader.deleteBundles(toDelete);
        cleverapps.bundleLoader.clearUnusedResources();
        cc.pool.drainAllPools();
    },

    onSceneLoaded: function (sceneName) {
        cleverapps.environment.setScene(sceneName);

        if (typeof Map2d !== "undefined" && Map2d.resetChessLayerVisible) {
            Map2d.resetChessLayerVisible();
        }

        this.movingNode = new cc.Node();
        this.movingNode.setLocalZOrder(BaseWindow.WINDOWS_ZORDER + 3);
        this.addChild(this.movingNode);

        var shadow = new ShadowLayer();
        shadow.setLocalZOrder(BaseWindow.WINDOWS_ZORDER - 3);
        shadow.subscribeToWindows();

        var loading = new LoadingAnimation();
        loading.visible = false;
        loading.setLocalZOrder(1000);
        loading.subscribeToWindows();
        loading.setPositionRound(cleverapps.styles.FixedWidthScene.loading);
        this.addChild(loading);

        this.backgroundStyles = this.getBackgroundStyles();
        this.setBackground();

        this.addTreasureBag();

        this.upMenuContainer = new UpMenuContainer();
        this.addChild(this.upMenuContainer);

        this.upMenuContainer.addMenuBar();
        this.upMenuContainer.addControlButtons();

        this.addSideBar();
        this.addToolbar();
        this.addDebugToolsBar();

        this.addChild(new CenterHintView(cleverapps.centerHint));

        var styles = this.getAudioStyles();

        if (connector.info.source !== "playable") {
            this.startOrRestartMusic(styles);
        }

        if (cleverapps.flyingAd) {
            cleverapps.flyingAd.reset();
        }

        cleverapps.userStatus.initTimeouts();

        cc.director.setClearColor(cleverapps.styles.FixedWidthScene.clearColor || cc.color.BLACK);

        this.debugId = "Scene";

        if (cleverapps.restoreProgress.isAvailable()) {
            var restoreProgress = new RestoreProgressButton();
            this.addChild(restoreProgress);
        }

        cleverapps.scenes.onAvoidNodeVisibilityChanged = this.updateDynamicNodesThrottled.bind(this);
    },

    startOrRestartMusic: function (styles) {
        this.startMusicAction = this.runAction(new cc.Sequence(
            new cc.DelayTime(styles.delay || 0),
            new cc.CallFunc(function () {
                cleverapps.audio.insertDisk(styles.res);
                cleverapps.audio.fadeIn(styles.fadeIn || 0);
            })
        ));
    },

    getAudioStyles: function () {
        return cleverapps.styles.FixedWidthScene.sound;
    },

    introControls: function () {

    },

    playIntro: function (f) {
        var silent = cleverapps.silentIntro;

        new ActionPlayer([
            function (f) {
                this.playIntroActions(f, silent);
            }.bind(this),

            function (f) {
                cleverapps.restoreProgress.update();
                cleverapps.silentIntro = false;

                f();
            }
        ]).play(f);
    },

    ensureSoundsLoaded: function () {
        var soundUrls = [];
        this.bundles.forEach(function (name) {
            soundUrls.push.apply(soundUrls, bundles[name].listSoundUrls());
        });
        cleverapps.bundleLoader.ensureSoundsLoaded(soundUrls);
    },

    playIntroActions: function (f) {
        f();
    },

    addSideBar: function () {
        if (this.sideBar) {
            this.sideBar.removeFromParent();
            delete this.sideBar;
        }

        if (cleverapps.gameModes.noSidebar) {
            return;
        }

        var sideBarView = this.sideBar = new SideBarView();
        this.addChild(sideBarView);
    },

    addToolbar: function () {
        if (cleverapps.environment.isMainScene() && !cleverapps.gameModes.noControls && !cleverapps.gameModes.noToolbar) {
            var toolbarView = new ToolbarView();
            toolbarView.setLocalZOrder(1);
            this.addChild(toolbarView);

            this.downToolBarControl = new HidingNode(toolbarView, cleverapps.UI.VERTICAL);
            cleverapps.focusManager.registerControl("toolbar", this.downToolBarControl);
        }
    },

    addTreasureBag: function () {
        if (this.treasureBag) {
            this.treasureBag.removeFromParent();
            this.treasureBag = undefined;
        }

        var treasureBag = new TreasureBagIcon();
        this.addChild(treasureBag);

        this.treasureBag = new HidingNode(treasureBag, cleverapps.styles.TreasureBag.hideVertical ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL);
        cleverapps.focusManager.registerControl("treasureBag", this.treasureBag, Object.values(cleverapps.Environment));
    },

    addDebugToolsBar: function () {
        if (!cleverapps.config.debugMode || cleverapps.config.wysiwygMode || !cleverapps.environment.isGameScene() && !cleverapps.environment.isMainScene()) {
            return;
        }

        var styles = cleverapps.styles.FixedWidthScene;

        this.snapshotBar = new SnapshotBarView();
        this.screen.addChild(this.snapshotBar);
        this.gitButtons = new GitButtonsView({
            hiddenByDefault: true
        });
        this.gitButtons.setLocalZOrder(1000);
        this.gitButtons.setPositionRound(styles.gitButtons);
        this.addChild(this.gitButtons);
    },

    getBackgroundStyles: function () {
        return cleverapps.skins.getSlot("sceneBg") || cleverapps.styles.FixedWidthScene.background;
    },

    setBackground: function () {
        var options = this.backgroundStyles;

        if (!options || cleverapps.gameModes.noBg) {
            return;
        }

        if (options.patternId) {
            var winSize = cleverapps.resolution.getSceneSize();
            winSize.height += 2;

            this.background = new cc.Node();
            this.background.setAnchorPoint(0.5, 0.5);
            this.background.setContentSize(winSize);

            var pattern = cleverapps.UI.createPatternSprite(bundles[options.bundle].urls[options.patternId], winSize);
            this.background.addChild(pattern);
        } else if (options.horizontalPattern) {
            var layoutWidth = 0;
            var layoutItems = [];

            do {
                var bgSprite = new cc.Sprite(options.horizontalPattern);
                layoutItems.push(bgSprite);
                layoutWidth += bgSprite.width;
            } while (layoutWidth < this.width || layoutItems.length < 2);

            this.background = new cleverapps.Layout(layoutItems, {
                direction: cleverapps.UI.HORIZONTAL,
                margin: 0
            });
        } else if (options.scale9) {
            this.background = cleverapps.UI.createScale9Sprite(options.scale9, cleverapps.UI.Scale9Rect.TwoPixelXY);
        } else if (options.movableAnimation) {
            this.background = new cleverapps.Spine(options.movableAnimation);
            this.background.setAnimation(0, "animation", true);
        } else if (options.backgroundId) {
            this.background = new cc.Sprite(bundles[options.bundle].urls[options.backgroundId]);
        } else {
            return;
        }

        if (options.animation) {
            var animation = this.background.animation = new cleverapps.Spine(options.animation);
            animation.setAnimation(0, "animation", true);
            if (options.skin) {
                animation.setSkin(options.skin);
            }
            this.background.addChild(animation);
        }

        this.background.transitionBundles = cleverapps.toArray(options.bundle);

        this.updateBackgroundScale();
        this.updateBackgroundPosition();

        this.background.setLocalZOrder(-10);
        this.addChild(this.background);
    },

    completeAnimationOnResize: function () {
        if (this.background) {
            this.updateBackgroundScale();
        }
        this.removeFilmEffect();
    },

    updateSize: function () {
        var safePadding = cleverapps.resolution.getSafePadding();

        this.setContentSize(cleverapps.resolution.getSceneSize());
        this.setPosition(safePadding.left, safePadding.bottom);

        this.screen.setContentSize(cleverapps.resolution.getBgSize());
        this.screen.setPosition(-safePadding.left, -safePadding.bottom);
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }

        this.updateBackgroundScale();
        this.updateBackgroundPosition();
    },

    onResize: function () {
        this._super();

        if (!this.isAllLoaded) {
            return;
        }

        this.afterResize();
    },

    afterResize: function () {
        if (this.scaleGameField) {
            this.scaleGameField();
        }

        var window = cleverapps.windows.currentWindow();
        if (window && window.initializedSuccess) {
            window.inflateWindow();
        }

        var movedUpNodes = [
            PointerView.currentPointer, FingerView.currentFinger,
            cleverapps.forces.getRunningForce(),
            Game.currentGame && Game.currentGame.tutorial
        ];

        movedUpNodes.forEach(function (node) {
            var isRunning = (node instanceof cc.Node) ? node.isRunning() : true;
            if (node && node.afterResize && isRunning) {
                node.afterResize();
            }
        });

        this.updateDynamicNodesThrottled(true);
    },

    updateBackgroundPosition: function () {
        if (this.background && !(this.backgroundStyles && this.backgroundStyles.movableAnimation)) {
            var bgSize = cleverapps.resolution.getBgSize();

            if (this.backgroundStyles && this.backgroundStyles.position) {
                this.background.setPositionRound(this.backgroundStyles.position);
                this.background.originalPosition = this.backgroundStyles.position;
            } else {
                this.background.setPositionRound(this.convertToNodeSpace(cc.p(bgSize.width / 2, bgSize.height / 2)));
            }
        }
    },

    updateBackgroundScale: function () {
        if (this.background && !(this.backgroundStyles && this.backgroundStyles.movableAnimation)) {
            var style = this.backgroundStyles;

            if (this.background instanceof cc.Scale9Sprite) {
                var size = cleverapps.resolution.getSceneSize();
                this.background.setContentSize2(size.width, size.height);
            } else if (typeof style.scale === "number") {
                this.background.setScale(style.scale);
            } else {
                var backgroundSize = style.size || this.background.getContentSize();
                var scale = Math.max(
                    this.width / backgroundSize.width,
                    cleverapps.resolution.getBgSize().height / backgroundSize.height
                );
                this.background.setScale(scale);
                this.background.stopAllActions();
            }

            if (this.background.animation) {
                this.background.animation.setPositionRound(this.background.width / 2, this.background.height / 2);
            }
        }
    },

    onTransitionAnimationDidStart: function () {
        cleverapps.audio.fadeOut(0.1);
    },

    addTransitionBundles: function (bundles) {
        if (bundles) {
            this.bundles = this.bundles.concat(bundles);
        }
    },

    getPreferredBundles: function () {
        var bundles = this._super();
        bundles = cleverapps.substract(bundles, ["dev_resources"]);
        return bundles;
    },

    addDynamicNode: function (node) {
        if (!this.dynamicNodes[node.__instanceId]) {
            this.dynamicNodes[node.__instanceId] = node;
        }
    },

    removeDynamicNode: function (node) {
        if (this.dynamicNodes[node.__instanceId]) {
            delete this.dynamicNodes[node.__instanceId];
        }
    },

    addAvoidNode: function (node) {
        if (!this.avoidNodes[node.__instanceId]) {
            this.avoidNodes[node.__instanceId] = node;
            this.updateDynamicNodesThrottled();
        }
    },

    removeAvoidNode: function (node) {
        if (this.avoidNodes[node.__instanceId]) {
            delete this.avoidNodes[node.__instanceId];
            this.updateDynamicNodesThrottled();
        }
    },

    updateDynamicNodes: function (silent) {
        Object.values(this.dynamicNodes).forEach(function (node) {
            node.updateDynamicPosition(silent);
        });
    },

    getAvoidNodes: function (types) {
        types = cleverapps.createSet(types);

        return Object.values(this.avoidNodes).filter(function (node) {
            return types[node.avoidNode];
        });
    },

    createFilmEffect: function (options) {
        this.removeFilmEffect();

        this.filmEffect = new SceneFilmEffect(options);
        this.addChild(this.filmEffect);
    },

    removeFilmEffect: function (timeout, callback) {
        if (!this.filmEffect) {
            callback && callback();
            return;
        }

        this.filmEffect.hideAnimation(timeout, function () {
            delete this.filmEffect;
            callback && callback();
        }.bind(this));
    }
});

cleverapps.styles.FixedWidthScene = {
    loading: {
        x: { align: "center" },
        y: { align: "center" }
    },

    sound: {
        res: bundles.main.urls.background_music_map,
        delay: 0.5
    },

    gitButtons: {
        x: { align: "right", dx: -4 },
        y: { align: "bottom", dy: 90 }
    }
};
