/**
 * Created by iamso on 28.05.21.
 */

var MineGuideOptions = {
    name: "MineGuideWindow",
    title: "Units.dragonmine.stage1.name",

    getGuideBundle: function () {
        return bundles.mineguidewindow;
    },

    stagesContent: function () {
        var styles = cleverapps.styles.MineGuideWindow;

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.guide_stage_1, styles.image);
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.guide_stage_2, styles.image);
        }.bind(this));

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.yellow_light, styles.light);
            this.addSprite(container, this.bundle.frames.guide_prize, styles.image);
        }.bind(this));

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.MineGuideWindow = {
    upperArrow: {
        x: { align: "center" },
        y: { align: "center", dy: 260 }
    },

    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        image: {
            x: 0,
            y: 0,
            scale: 0.9
        }
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        image: {
            x: 6,
            y: 0,
            scale: 0.9
        }
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        image: {
            x: 15,
            y: 0
        },

        light: {
            x: 0,
            y: 50
        }
    }
};
