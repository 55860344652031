/**
 * Created by olga on 21.10.2024
 */

var FocusManager = function () {
    this.stack = [];

    this.pushState({
        focus: false,
        controls: [],
        eventNodes: []
    });
    this.pushState({
        focus: "initialize",
        controls: [],
        eventNodes: []
    });

    this.focusHistory = [];
    this.tempControls = {};
    this.lastFocus = undefined;

    // for cleverapps.windows callback
    this.onceNoWindowsListener = undefined;

    this.lastControlsVisible = {};
    this.onControlsListeners = {};
    this.controlsOptions = {};

    this.pendingListeners = [];
};

FocusManager.prototype.showControlsTemporary = function (controls) {
    cleverapps.toArray(controls).forEach(function (control) {
        this.tempControls[control] = true;
        if (!this.lastControlsVisible[control] && this.onControlsListeners[control]) {
            this.onControlsListeners[control](true);
            this.lastControlsVisible[control] = true;
        }
    }.bind(this));
};

FocusManager.prototype.hideControlsTemporary = function (controls) {
    cleverapps.toArray(controls).forEach(function (control) {
        delete this.tempControls[control];
        if (this.lastControlsVisible[control] && this.onControlsListeners[control] && this.controls.indexOf(control) === -1) {
            this.onControlsListeners[control](false);
            this.lastControlsVisible[control] = false;
        }
    }.bind(this));
};

FocusManager.prototype.clearTempControls = function () {
    this.tempControls = {};
};

FocusManager.prototype.registerControl = function (name, listener, hiddenByDefault) {
    if (typeof listener === "object") {
        listener = listener.createListener(listener.toggle.bind(listener));
    }

    if (hiddenByDefault) {
        this.controlsOptions[name] = {
            hiddenByDefault: hiddenByDefault
        };
    }

    this.onControlsListeners[name] = listener;
    this.lastControlsVisible[name] = false;

    this.setControlsVisible(true);
};

FocusManager.prototype.removeControl = function (name) {
    delete this.onControlsListeners[name];
    delete this.lastControlsVisible[name];
};

FocusManager.prototype.updateControlsVisibility = function (silent) {
    for (var key in this.onControlsListeners) {
        var visible = this.controls.indexOf(key) !== -1 || this.tempControls[key] !== undefined;

        if (this.lastControlsVisible[key] !== visible) {
            this.onControlsListeners[key](visible, silent);
            this.lastControlsVisible[key] = visible;
        }
    }
};

FocusManager.prototype.setControlsVisible = function (silent) {
    this.clearUpdateTimeout();

    if (!this.isFocused() && !cleverapps.gameModes.noControls) {
        this.setControls(Object.keys(this.onControlsListeners).filter(function (control) {
            return this.shouldBeVisible(control);
        }.bind(this)));
    }

    if (silent) {
        this.updateControlsVisibility(true);
    } else {
        this.updateTimeout = setTimeout(this.updateControlsVisibility.bind(this), 100);
    }
};

FocusManager.prototype.shouldBeVisible = function (control) {
    return !this.isFocused() && (!this.controlsOptions[control] || !this.controlsOptions[control].hiddenByDefault || !cleverapps.environment.hasScene(this.controlsOptions[control].hiddenByDefault));
};

FocusManager.prototype.clearUpdateTimeout = function () {
    if (this.updateTimeout) {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = undefined;
    }
};

FocusManager.prototype.debugMessage = function (message) {
    if (cleverapps.config.debugMode && !connector.info.isNative) {
        throw message;
    } else {
        cleverapps.throwAsync(message);
    }
};

FocusManager.prototype.displayWhenFreeFocus = function (options) {
    if (!this.isFocused()) {
        this.display(options);
    } else {
        this.whenFreeFocus(this.display.bind(this, options));
    }
};

FocusManager.prototype.whenFreeFocus = function (listener) {
    if (!this.isFocused()) {
        listener();
    } else {
        this.pendingListeners.push(listener);
    }
};

FocusManager.prototype.distract = function (options) {
    if (!this.isFocused()) {
        this.debugMessage("Distract without focus! " + options.focus);
    }

    if (this.focus === options.focus) {
        this.debugMessage("Distract same focus!" + options.focus);
        return;
    }

    if (options.filter && !options.filter()) {
        return;
    }

    if (!options.restore) {
        while (this.stack.length > 1) {
            this.popState();
        }
    }

    this.grabFocus(options.focus, options);
    this.compound(this.freeFocus.bind(this, options.focus), options.actions || [options.action]);
};

FocusManager.prototype.display = function (options) {
    if (this.isFocused() && !options.stack) {
        return;
    }

    if (options.filter && !options.filter()) {
        return;
    }

    this.grabFocus(options.focus, options);
    this.compound(this.freeFocus.bind(this, options.focus), options.actions || [options.action]);
};

FocusManager.prototype.compound = function (f, actions, index, state) {
    index = index || 0;
    state = state || this.stack[this.stack.length - 1];

    if (this.stack[this.stack.length - 1] !== state) {
        state.action = this.compound.bind(this, f, actions, index, state);
        return;
    }

    if (actions.length === index) {
        f();
        return;
    }

    this.setEventNodes([]);

    actions[index](cleverapps.once(this.compound.bind(this, f, actions, index + 1, state)));
};

FocusManager.prototype.showControlsWhileFocused = function (controls, silent) {
    cleverapps.toArray(controls).forEach(function (control) {
        if (this.controls.indexOf(control) === -1) {
            this.controls.push(control);
            if (!this.lastControlsVisible[control] && this.onControlsListeners[control]) {
                this.onControlsListeners[control](true, silent);
                this.lastControlsVisible[control] = true;
            }
        } else if (silent && this.onControlsListeners[control]) {
            this.onControlsListeners[control](true, silent);
        }
    }, this);
};

FocusManager.prototype.isControlEnabled = function (control) {
    return cleverapps.intersect(this.controls, control).length > 0;
};

FocusManager.prototype.hideAllControls = function (silent) {
    this.setControls([]);
    this.updateControlsVisibility(silent);
};

FocusManager.prototype.hideControlsWhileFocused = function (controls) {
    cleverapps.toArray(controls).forEach(function (control) {
        var index = this.controls.indexOf(control);
        if (index !== -1 && !this.shouldBeVisible(control)) {
            this.controls.splice(index, 1);
            if (this.lastControlsVisible[control] && this.onControlsListeners[control] && !this.tempControls[control]) {
                this.onControlsListeners[control](false);
                this.lastControlsVisible[control] = false;
            }
        }
    }, this);
};

FocusManager.prototype.trigger = cleverapps.accumulate(0, function () {
    this.setControlsVisible(cleverapps.silentIntro);

    if (this.isFocused()) {
        return;
    }

    while (this.pendingListeners.length > 0) {
        var first = this.pendingListeners.shift();
        first();
        if (this.isFocused()) {
            return;
        }
    }

    this.onFocusLostListenerPlacements();
    InterstitialAction.showOnLowMonetization();
});

FocusManager.prototype.onFocusLostListenerPlacements = function () {
    cleverapps.placements.onFocusLostListenerPlacements();
};

FocusManager.prototype.checkEventNode = function (node) {
    if (node.alwaysOn) {
        return true;
    }

    var scene = cleverapps.scenes.getRunningScene();

    if (!this.isFocused()) {
        return true;
    }

    var eventNodes = this.eventNodes;

    if (scene.toolMenu) {
        eventNodes = eventNodes.concat(scene.toolMenu, scene.toolMenu.opener);
    }

    if (scene.snapshotBar) {
        eventNodes = eventNodes.concat(scene.snapshotBar);
    }

    if (!eventNodes.length) {
        return false;
    }

    if (eventNodes[0] === scene) {
        return true;
    }

    while (node) {
        if (eventNodes.indexOf(node) !== -1) {
            return true;
        }
        node = node.parent;
    }
    return false;
};

FocusManager.prototype.setEventNodes = function (eventNodes) {
    var state = this.stack[this.stack.length - 1];
    state.eventNodes = this.eventNodes = eventNodes;
};

FocusManager.prototype.setControls = function (controls) {
    var state = this.stack[this.stack.length - 1];
    state.controls = this.controls = controls;
};

FocusManager.prototype.grabFocus = function (name, options) {
    options = options || {};

    console.log("Grab focus: " + name);

    var controls = cleverapps.toArray(options.control || []);
    if (options.keepControls) {
        controls = this.controls;
    }

    this.saveHistory("+" + name);
    this.pushState({
        focus: name,
        controls: controls.slice(),
        eventNodes: []
    });

    this.setControlsVisible(cleverapps.silentIntro);
    this.trigger();

    cleverapps.eventBus.trigger("changeFocus");
};

FocusManager.prototype.freeFocus = function (name) {
    if (this.focus !== name) {
        console.log("Wrong focus clearing: " + this.focus + " > " + name);
        return;
    }

    console.log("Focus freed: " + this.focus);

    this.lastFocus = this.focus;
    this.saveHistory("-" + this.focus);
    this.popState();

    this.trigger();

    cleverapps.eventBus.trigger("changeFocus");

    if (!this.isFocused()) {
        cleverapps.bundleLoader.clearUnusedResources();
    }

    this.resumeCompound();
};

FocusManager.prototype.saveHistory = function (focus) {
    this.focusHistory.push(focus);
    this.focusHistory = this.focusHistory.slice(-20);
};

FocusManager.prototype.pushState = function (state) {
    state.onceNoWindowsListener = this.onceNoWindowsListener;
    this.onceNoWindowsListener = undefined;

    this.stack.push(state);

    this.focus = state.focus;
    this.setControls(state.controls);
    this.setEventNodes(state.eventNodes);
};

FocusManager.prototype.popState = function () {
    if (this.stack.length === 1) {
        this.debugMessage("Attempt to free default state");
        return;
    }

    var state = this.stack.pop();
    state.controls = [];
    state.eventNodes = [];
    state.action = undefined;

    this.onceNoWindowsListener = state.onceNoWindowsListener;
    state.onceNoWindowsListener = undefined;

    state = this.stack[this.stack.length - 1];
    this.focus = state.focus;
    this.setControls(state.controls);
    this.setEventNodes(state.eventNodes);

    setTimeout(function () {
        cleverapps.windows.notifyFocusPop();
    }, 0);
};

FocusManager.prototype.resumeCompound = function () {
    var state = this.stack[this.stack.length - 1];

    var action = state.action;
    state.action = undefined;

    if (action) {
        action();
    }
};

FocusManager.prototype.isFocused = function () {
    return this.focus !== false;
};

FocusManager.prototype.getFocus = function () {
    return this.focus;
};
