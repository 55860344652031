/**
 * Created by Denis Kuzin on 29 march 2022
 */

var MyClanWindow = BaseClanWindow.extend({
    ctor: function (options) {
        options = options || {};

        this.updateTop = true;
        this.updateMessages = true;

        this._super(options);
    },

    onWindowLoaded: function (options) {
        this._super({
            clan: cleverapps.userClan,
            name: "MyClanWindow",
            title: "ClansWindow.chat.title"
        });

        cleverapps.playSession.set(cleverapps.EVENTS.CLANS_DAU, true);
        if (options.hasReq) {
            this.tabs.activateTab(0);
        }
    },

    createTabs: function () {
        return {
            0: {
                icon: bundles.windows.frames.tab_icon_chat_team,
                text: "ClansWindow.chat.title",
                generator: this.createTab.bind(this, ClansChatTab),
                updateTitle: this.setTitle.bind(this, "ClansWindow.chat.title")
            },
            1: {
                icon: bundles.windows.frames.tab_icon_my_team,
                text: "ClansWindow.team.title",
                generator: this.createTab.bind(this, ClansInfoTab),
                updateTitle: this.setTitle.bind(this, "ClansWindow.team.title")
            },
            2: {
                icon: bundles.windows.frames.tab_icon_top_team,
                text: "ClansWindow.top.title",
                generator: this.createTab.bind(this, ClansTopTab),
                updateTitle: this.setTitle.bind(this, "ClansWindow.top.title")
            }
        };
    }
});