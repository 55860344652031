/**
 * Created by r4zi4l on 20.04.2022
 */

var ClanMessage = function (data) {
    this.update(data);

    this.onMessagePosted = function () {};
    this.onHelpUpdated = function () {};
    this.onGiftUpdated = function () {};
};

ClanMessage.prototype.getInfo = function () {
    var data = {
        messageId: this.messageId,
        clanId: this.clanId,
        type: this.type,
        date: this.date,
        playerId: this.playerId,
        playerName: this.playerName,
        playerAvatar: this.playerAvatar
    };

    if (this.helps.length) {
        data.helps = this.helps.map(function (help) {
            return help.getInfo();
        });
    }

    if (this.gift) {
        data.gift = this.gift.getInfo();
    }

    return data;
};

ClanMessage.prototype.update = function (data) {
    this.messageId = data.messageId;
    this.clanId = data.clanId;
    this.type = data.type;
    this.date = data.date && new Date(data.date).getTime() || Date.now();
    this.playerId = data.playerId;
    this.playerName = data.playerName;
    this.playerAvatar = data.playerAvatar;

    this.helps = (data.helps || [])
        .sort(function (a, b) {
            return a.helpId - b.helpId;
        })
        .map(function (helpData) {
            var help = new ClanHelp(helpData);
            help.message = this;
            return help;
        }.bind(this));

    this.gift = data.gift && new ClanGift(data.gift);
    if (this.gift) {
        this.gift.message = this;
    }
};

ClanMessage.prototype.posted = function (messageId) {
    this.messageId = messageId;
    this.onMessagePosted();
};

ClanMessage.prototype.isHelpMessage = function () {
    return this.type === ClansConfig.MESSAGE_TYPE_ENERGY;
};

ClanMessage.prototype.isGiftMessage = function () {
    return this.type === ClansConfig.MESSAGE_TYPE_GIFT;
};

ClanMessage.prototype.isUserMessage = function () {
    return this.playerId === connector.platform.getUserID();
};

ClanMessage.prototype.isFresh = function () {
    return this.messageId === undefined;
};

ClanMessage.prototype.getPlayerAvatar = function () {
    return this.isUserMessage() ? cleverapps.info.getValue("avatar") : this.playerAvatar;
};

ClanMessage.prototype.getPlayerName = function () {
    return this.isUserMessage() ? Messages.get("ClanMessage.you") : this.playerName || "Player_" + this.playerId.substr(-3);
};

ClanMessage.prototype.getText = function () {
    return ClansConfig.MESSAGES[this.type].phrase;
};

ClanMessage.prototype.listHelp = function () {
    var config = ClansConfig.MESSAGES[this.type].help;

    return this.helps.slice(0, config && config.times || 0);
};

ClanMessage.prototype.canHelp = function () {
    var config = ClansConfig.MESSAGES[this.type].help;
    var times = config && config.times || 0;
    var userHelp = this.helps.find(function (help) {
        return help.senderId === connector.platform.getUserID();
    });

    return this.helps.length < times && !userHelp;
};

ClanMessage.prototype.getTotalHelp = function () {
    var config = ClansConfig.MESSAGES[this.type].help;
    return config && (config.energy * config.times) || 0;
};

ClanMessage.prototype.getCurrentHelp = function () {
    var config = ClansConfig.MESSAGES[this.type].help;
    var list = this.listHelp();

    if (this.isUserMessage()) {
        list = list.filter(function (help) {
            return help.status === ClansConfig.HELP_RECEIVED;
        });
    }

    return config && (config.energy * list.length) || 0;
};

ClanMessage.prototype.getHelpAmount = function () {
    var config = ClansConfig.MESSAGES[this.type].help;
    return config && config.energy || 0;
};

ClanMessage.prototype.getTimeLeft = function () {
    if (ClansConfig.MESSAGES[this.type].help) {
        return Math.max(0, this.date + ClansConfig.MESSAGE_HELP_LIFETIME - Date.now());
    }
    if (this.type === ClansConfig.MESSAGE_TYPE_GIFT) {
        return Math.max(0, this.date + ClansConfig.MESSAGE_GIFT_LIFETIME - Date.now());
    }
};
