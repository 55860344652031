/**
 * Created by andrey on 15.11.2024
 */

var VKLogic = function () {
    PlatformLogic.call(this);
};

VKLogic.prototype = Object.create(PlatformLogic.prototype);
VKLogic.prototype.constructor = VKLogic;

VKLogic.prototype.logPushes = function () {
    var params = connector.utils.getRequestParameters();

    var pushId = params.ref_notification_id;
    var push = pushId && cleverapps.LocalPushes.GetTypeById(pushId);
    var pushCode = push && push.code || pushId;

    if (pushCode) {
        cleverapps.localPushes.logClickEvent(pushCode);
    }
};