/**
 * Created by Andrey Popov on 10.11.2021
 */

var TextureImage = function (atlasAnalyzer) {
    this.atlasAnalyzer = atlasAnalyzer;

    cleverapps.EventEmitter.call(this);

    this.textureMap = undefined;
    this.texturePng = undefined;
    this.selectedTextureFrame = undefined;

    this.atlasAnalyzer.bundlesExplorer.on("selectionChanged", this.onSelectionChanged.bind(this));
};

TextureImage.prototype = Object.create(cleverapps.EventEmitter.prototype);
TextureImage.prototype.constructor = TextureImage;

TextureImage.prototype.onSelectionChanged = function (tab, selectedItem, frameToSelect) {
    this.trigger("showLoading");

    this.textureMap = undefined;
    this.texturePng = undefined;

    new ActionPlayer([
        function (f) {
            var bundle = bundles[selectedItem];
            if (bundle.meta.virtualDebugBundle) {
                this.prepareVirtualDebugBundle(selectedItem, f);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            var urls = bundles[selectedItem].listUrls();

            cc.loader.load(urls, function (status) {
                if (status) {
                    console.error("load failed: ", selectedItem);
                } else {
                    f();
                }
            });
        },

        function (f) {
            this.showTexture(tab, selectedItem, frameToSelect);
            f();
        }.bind(this)
    ]).play();
};

TextureImage.prototype.prepareVirtualDebugBundle = function (bundleName, callback) {
    cleverapps.RestClient.get("/pack/" + bundleName, {}, function (response) {
        var resolutionName = cleverapps.resolution.resolutionName;

        var selectResolution = function (obj) {
            for (var key in obj) {
                var value = obj[key];
                if (value[resolutionName]) {
                    obj[key] = value[resolutionName];
                }
            }
        };

        var selectUrls = function (urls) {
            for (var key in urls) {
                urls[key] = "//" + location.host + (cleverapps.isLocalhost() ? "/" + cleverapps.config.name : "") + "/" + urls[key];
            }
        };

        selectResolution(response.frames);
        selectResolution(response.urls);
        selectUrls(response.urls);

        console.log("prepareVirtualDebugBundle success: ", response);

        bundles[bundleName] = new Bundle(bundleName, response);

        callback();
    }, function (error) {
        console.log("prepareVirtualDebugBundle error: ", error);
    });
};

TextureImage.prototype.showTexture = function (tab, selectedItem, frameToSelect) {
    var bundle = bundles[selectedItem];

    this.texturePng = bundle.urls.png || bundle.urls.particle_texture;

    this.trigger("showTexture", this.texturePng);

    if (!bundle.urls.json || !this.texturePng) {
        return;
    }

    var textureJsons = cc.loader.getRes(bundle.urls.json);

    if (!bundle.jsons.atlas || !textureJsons[bundle.jsons.atlas.name]) {
        return;
    }

    var atlas = this.parseAtlas(selectedItem, textureJsons[bundle.jsons.atlas.name]);
    this.textureMap = atlas;

    if (!this.textureMap) {
        console.error("missing description for ", selectedItem);
        return;
    }

    var textureInfoName = bundle.jsons.texture_info_debug_json && bundle.jsons.texture_info_debug_json.name;
    var textureInfo = textureInfoName && textureJsons[textureInfoName] && JSON.parse(textureJsons[textureInfoName]);
    this.textureMap.texture.byteSize = textureInfo && textureInfo.byteSize || "";

    this.textureMap.frames.forEach(function (frame) {
        this.calculateFrameLinks(selectedItem, frame);
    }.bind(this));

    if (this.textureMap.frames.length > 0) {
        var selectedFrame = this.textureMap.frames[0];
        if (frameToSelect) {
            var candidates = this.textureMap.frames.filter(function (frame) {
                return frame.name === frameToSelect;
            });
            if (candidates.length > 0) {
                selectedFrame = candidates[0];
            }
        }

        this.trigger("selectTextureFrame", selectedFrame, true);
    }
};

TextureImage.prototype.parseAtlas = function (name, atlas) {
    atlas = BundleUtils.parseAtlas(atlas);

    var map = {
        texture: {
            name: name,
            fileName: atlas.meta.image,
            size: {
                width: atlas.meta.size.w,
                height: atlas.meta.size.h
            }
        },
        frames: []
    };

    var nameKeys = {};

    for (var frameKey in bundles[name].frames) {
        nameKeys[bundles[name].frames[frameKey].name] = frameKey;
    }

    Object.keys(atlas.frames).forEach(function (filename) {
        var frame = atlas.frames[filename];
        map.frames.push({
            name: filename,
            key: nameKeys[filename],
            position: cc.p(frame.rect.x, atlas.meta.size.h - frame.rect.y),
            size: frame.rotated ? cc.size(frame.rect.height, frame.rect.width)
                : cc.size(frame.rect.width, frame.rect.height)
        });
    });

    return map;
};

TextureImage.prototype.calculateFrameLinks = function (bundleName, frame) {
    var originalPaths = bundles[bundleName].originalPaths;
    var unused = bundles[bundleName].unused || {};

    var unpacked = originalPaths[frame.name]
        || originalPaths[frame.key]
        || originalPaths[frame.name.replace(".png", ".jpg")];

    if (unpacked) {
        var repoInd = unpacked.indexOf("/");
        var repo = unpacked.substring(0, unpacked.indexOf("/"));
        var linkWithoutRepo = unpacked.substring(repoInd);
        frame.unpackedName = unpacked.split("/").pop();
        frame.link = unpacked;
        frame.gitLink = "https://github.com/rvvslv/" + repo + "/tree/master/" + linkWithoutRepo;

        frame.unused = unused[frame.name]
            || unused[frame.key]
            || unused[frame.name.replace(".png", ".jpg")];
    }
};

TextureImage.prototype.handleTextureClick = function (x, y) {
    if (!this.textureMap) {
        return;
    }

    for (var i = 0; i < this.textureMap.frames.length; i++) {
        var frame = this.textureMap.frames[i];

        if (x >= frame.position.x && x <= frame.position.x + frame.size.width
            && y >= frame.position.y - frame.size.height && y <= frame.position.y) {
            this.selectFrame(frame);
            break;
        }
    }
};

TextureImage.prototype.selectFrame = function (frame, animate) {
    this.selectedTextureFrame = frame;
    this.trigger("selectTextureFrame", frame, animate);

    cleverapps.setUrlHash({
        atlasAnalyzer: true,
        tab: this.atlasAnalyzer.bundlesExplorer.selectedTab,
        bundleName: this.atlasAnalyzer.bundlesExplorer.selectedItem,
        frame: frame.name
    });
};

TextureImage.prototype.clear = function () {
    this.trigger("clear");

    delete this.textureMap;
    delete this.texturePng;
    delete this.selectedTextureFrame;
};
