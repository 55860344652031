/**
 * Created by razial on 18.07.2023
 */

var UnitComponent = function (unit) {
    this.unit = unit;
};

UnitComponent.prototype.setView = function (view) {
    this.view = view;
};

UnitComponent.prototype.removeView = function () {
    this.view = undefined;
};

UnitComponent.prototype.clearAnimations = function () {
    if (this.view && this.view.clearAnimations) {
        this.view.clearAnimations();
    }
};
