/**
 * Created by Denis Kuzin on 31 january 2022
 */

var Breakable = function (unit, options) {
    UnitComponent.call(this, unit);

    this.value = options.harvested || 0;

    var data = unit.getData();

    this.nextUnit = data.breakable.nextUnit;

    this.limit = data.breakable.limit;
    this.prize = data.breakable.prize;
};

Breakable.prototype = Object.create(UnitComponent.prototype);
Breakable.prototype.constructor = Breakable;

Breakable.prototype.save = function (data) {
    data.harvested = this.value;
};

Breakable.prototype.onPrizeHarvested = function () {
    this.incValue();
};

Breakable.prototype.onNeedToBeRemoved = function () {
    this.goToNextUnit();
};

Breakable.prototype.incValue = function () {
    this.value++;
    this.unit.save();
};

Breakable.prototype.goToNextUnit = function () {
    if (this.isCompleted()) {
        if (this.nextUnit) {
            this.unit.onRemove();
            this.unit.remove(true);

            var unit = new Unit(this.nextUnit);
            unit.setPosition(this.unit.x, this.unit.y);
            Map2d.currentMap.onAddUnit(unit.x, unit.y, unit);
            Map2d.currentMap.onUnitAvailable(unit);
        } else {
            this.unit.onDestruction();
            this.unit.remove(true);
        }

        Map2d.currentMap.blockedGrounds.updateBlockedGrounds();
    }
};

Breakable.prototype.isCompleted = function () {
    return this.value >= this.getLimit();
};

Breakable.prototype.getPrizeData = function () {
    if (this.value === this.getLimit() - 1) {
        return this.prize;
    }
};

Breakable.prototype.getLimit = function () {
    return this.limit || Breakable.LIMIT;
};

Breakable.IsIntact = function (unit) {
    var breakable = unit.findComponent(Breakable);
    return !breakable || !breakable.isCompleted();
};

Breakable.LIMIT = 3;
