/**
 * Created by mac on 2/25/20
 */

var FogBlock = function (id, fogs) {
    cleverapps.EventEmitter.call(this);
    this.id = cleverapps.castType(id);
    this.fogs = fogs;
    this.options = fogs && fogs.config[id] || {};
    this.money = this.options.money;

    this.tiles = [];

    this.state = FogBlock.UNAVAILABLE;

    this.onGetView = function () {};
};

FogBlock.prototype = Object.create(cleverapps.EventEmitter.prototype);
FogBlock.prototype.constructor = FogBlock;

FogBlock.prototype.findTile = function (x, y) {
    var p = cc.p(x, y);
    for (var i = 0; i < this.tiles.length; i++) {
        if (cc.pointEqualToPoint(this.tiles[i], p)) {
            return this.tiles[i];
        }
    }

    if (cc.pointEqualToPoint(this.balloonTile, p)) {
        return this.balloonTile;
    }
};

FogBlock.prototype.getKey = function () {
    return this.id;
};

FogBlock.prototype.isMoney = function () {
    return this.money;
};

FogBlock.prototype.isSoftMoney = function () {
    return this.isMoney() && this.options.soft;
};

FogBlock.prototype.isSpecial = function () {
    return this.options.special;
};

FogBlock.prototype.isAutoOpen = function () {
    return this.options.autoOpen;
};

FogBlock.prototype.isSilentUnblock = function () {
    return this.options.silentUnblock;
};

FogBlock.prototype.isFree = function () {
    return !this.options.price;
};

FogBlock.prototype.isTreasureMapTarget = function () {
    return this.options.treasureMapTarget;
};

FogBlock.prototype.click = function (pointOfInterest) {
    if (this.state !== FogBlock.UNAVAILABLE) {
        var conditions = this.listNotFinishedConditions();
        if (conditions.length) {
            this.displayCondition(conditions[conditions.length - 1]);
        }
        if (!this.isMoney()) {
            if (this.state === FogBlock.CANTOPEN) {
                this.displayWandsCondition();
            }
            if (this.state === FogBlock.CANOPEN) {
                cleverapps.centerHint.createTextHint("Fog.clear.clearNow");
            }
        }
        for (var i = 0; i < this.tiles.length; i++) {
            this.tiles[i].onClick();
        }
    } else if (pointOfInterest) {
        if (Families[pointOfInterest.code].units[pointOfInterest.stage].temporary) {
            pointOfInterest = cleverapps.clone(pointOfInterest, true);
            pointOfInterest.stage++;
        }
        cleverapps.centerHint.createTextHint("Fog.pointOfInterest", { name: cleverapps.unitsLibrary.getUnitName(pointOfInterest) });
    }
};

FogBlock.prototype.displayCondition = function (condition) {
    if (condition.level) {
        cleverapps.centerHint.createTextHint(
            "Fog.clear.levelCondition",
            { level: condition.level }
        );
    }
    if (condition.hero) {
        this.displayHeroCondition(condition);
    }
    if (condition.familyLastUnit) {
        this.displayCastleCondition(condition.familyLastUnit);
    }
    if (condition.unit) {
        this.displayUnitCondition(condition.unit);
    }
};

FogBlock.prototype.displayItemCondition = cleverapps.throttle(500, function (params) {
    InfoView.CloseInfo();
    Map2d.currentMap.showLargestGroup(params);
});

FogBlock.prototype.displayHeroCondition = function (condition) {
    var unit = {
        code: condition.hero,
        stage: Unit.getAllStagesTargets(condition.hero).pop().stage
    };

    var text = [
        { text: Messages.get("CenterHint.merge.begins") + " x3" },
        { icon: UnitView.getUnitImage({ code: unit.code, stage: unit.stage - 1 }, { preferStatic: true }) },
        { text: Messages.get("CenterHint.merge.ends") + " " },
        { icon: UnitView.getUnitImage(unit, { preferStatic: true }) }
    ];

    if (cleverapps.unitsLibrary.getCurrentHero() === condition.hero) {
        this.displayItemCondition({
            itemCode: condition.hero,
            text: text
        });
    } else {
        cleverapps.centerHint.createTextHint(text);
    }
};

FogBlock.prototype.displayCastleCondition = function (familyLastUnit) {
    this.displayItemCondition({
        itemCode: familyLastUnit.code,
        itemStage: familyLastUnit.stage - 1,
        text: Messages.get("Fog.clear.buildCastle", { castle: cleverapps.unitsLibrary.getUnitName(familyLastUnit) })
    });
};

FogBlock.prototype.displayUnitCondition = function (unit) {
    this.displayItemCondition({
        itemCode: unit.code,
        itemStage: unit.stage - 1,
        text: Messages.get("Fog.clear.unitCondition", { unit: cleverapps.unitsLibrary.getUnitName(unit) })
    });
};

FogBlock.prototype.displayWandsCondition = function () {
    this.displayItemCondition({
        itemCode: "wands",
        text: Messages.get("Fog.clear.notEnoughWands", { wands: this.getOpenPrice() - this.fogs.wands })
    });
};

FogBlock.prototype.setState = function (newState) {
    if (this.state !== newState) {
        var update = function (silent) {
            this.updateState(newState, silent);
            this.trigger("updateBlocker", newState, silent);
            this.trigger("updateState", newState, silent);
        }.bind(this);

        if (cleverapps.config.wysiwygMode) {
            update();
            return;
        }

        if (this.state < newState && Map2d.currentMap.fogs.initStageRunned && [FogBlock.UNAVAILABLE, FogBlock.NOTREADY, FogBlock.CANTOPEN].includes(this.state)) {
            var merge = Game.currentGame;
            if ((merge && merge.tutorial.isActive()) || this.isSilentUnblock()) {
                update();
                return;
            }
            if (cleverapps.focusManager.isFocused()) {
                this.fogs.wantsCalcFogStates = true;
                return;
            }
            if (this.state !== FogBlock.CANTOPEN || !this.onGetView()) {
                this.scrollAndUpdateStateAction(newState);
                return;
            }
        }

        update(true);
    } else if (newState === FogBlock.NOTREADY) {
        this.trigger("updateBlocker", newState, true);
    }
};

FogBlock.prototype.updateState = function (newState, silent, silentBalloon) {
    this.state = newState;

    for (var i = 0; i < this.tiles.length; i++) {
        this.tiles[i].updateState(this.state, silent);
    }
    if (this.balloonTile && this.balloonTile.fake) {
        this.balloonTile.updateState(this.state, silentBalloon || silent);
    }
};

FogBlock.prototype.onChangeWandsAmount = function () {
    if (this.state === FogBlock.CANTOPEN || this.state === FogBlock.CANOPEN) {
        this.setState(this.canOpen() ? FogBlock.CANOPEN : FogBlock.CANTOPEN);
        this.trigger("wandsChanged");
    }
};

FogBlock.prototype.calcState = function () {
    var newState = FogBlock.UNAVAILABLE;

    if (this.isAvailable()) {
        newState = FogBlock.NOTREADY;
        if (this.isReady()) {
            newState = FogBlock.CANTOPEN;
            if (this.canOpen()) {
                newState = FogBlock.CANOPEN;
            }
        } else {
            this.blockerConditions = this.listNotFinishedConditions();
        }
    }

    this.setState(newState);
};

FogBlock.prototype.add = function (x, y, options) {
    if (this.findTile(x, y)) {
        return;
    }

    var FogTileClass = FogTile;
    if (cleverapps.config.editorMode && typeof EditorFogTile !== "undefined") {
        FogTileClass = EditorFogTile;
    }

    var head = options && options.head;
    var fake = options && options.fake;

    var tile = new FogTileClass(x, y, this, options);
    if (head) {
        this.head = tile;
    }
    if (!fake) {
        this.tiles.push(tile);
    }
    return tile;
};

FogBlock.prototype.addBalloon = function (x, y) {
    var tile = this.findTile(x, y) || this.add(x, y, { fake: true });
    this.balloonTile = tile;

    var FogBalloonClass = FogBalloon;
    if (cleverapps.config.editorMode && typeof EditorFogBalloon !== "undefined") {
        FogBalloonClass = EditorFogBalloon;
    }

    var balloon = this.balloon = new FogBalloonClass(tile, this);
    tile.addBalloon(balloon);
    tile.onUpdateState(this.state, true);
};

FogBlock.prototype.getBalloon = function () {
    return this.balloon;
};

FogBlock.prototype.getDelayBeforeOpening = function () {
    return FogBlock.DELAY_BEFORE_OPENING[cleverapps.config.name] || 0;
};

FogBlock.prototype.getOpenAnimationDuration = function () {
    var openDelay = 0;
    this.tiles.forEach(function (tile) {
        openDelay = Math.max(openDelay, tile.getOpenDelay() + tile.getOpenDuration());
    });

    return openDelay;
};

FogBlock.prototype.prepareOpenOrderForDispelling = function () {
    var tiles = cleverapps.Random.shuffle(this.tiles, this.id);

    var openDelay = 0;

    tiles.forEach(function (tile, index) {
        tile.openDelay = openDelay;

        openDelay += 200 / (0.6 * index + 1);
    });
};

FogBlock.prototype.prepareOpenOrderForWind = function () {
    var tiles = this.tiles.slice();

    tiles.sort(function (a, b) {
        return a.x - b.x;
    });

    var x, openOrder = -1;
    tiles.forEach(function (tile) {
        if (x !== tile.x) {
            x = tile.x;
            openOrder++;
        }

        tile.openDelay = openOrder * 180;
    });
};

FogBlock.prototype.prepareOpenDelay = function () {
    if (!this.head) {
        return;
    }

    var styles = cleverapps.styles.FogTileView[this.head.getFogType()];
    if (styles && styles.dispelling) {
        this.prepareOpenOrderForDispelling();
    } else if (styles && styles.whirlwind) {
        this.prepareOpenOrderForWind();
    } else if (!styles || !styles.groundStateOnly) {
        this.prepareOpenOrder();
    }
};

FogBlock.prototype.prepareOpenOrder = function () {
    var tiles = this.tiles.slice();

    tiles.sort(function (a, b) {
        return a.x - b.x;
    });

    var x, line = -1;
    tiles.forEach(function (tile) {
        if (x !== tile.x) {
            x = tile.x;
            line++;
        }

        tile.line = line % 2;
    });

    tiles.sort(function (a, b) {
        return a.y - b.y;
    });

    var pass = function (currentLine) {
        var y, openOrder = -1;
        tiles.forEach(function (tile) {
            if (tile.line === currentLine) {
                if (y !== tile.y) {
                    y = tile.y;
                    openOrder++;
                }

                tile.openDelay = openOrder * 180;
            }
        });
    };

    pass(0);
    tiles.reverse();
    pass(1);
};

FogBlock.prototype.getOpenPrice = function () {
    if (cleverapps.ABTest.MONEY_FOG1() && !cleverapps.travelBook.isExpedition() && this.id === "moneyFog1" && cleverapps.paymentsHistory.isPayer()) {
        return 600;
    }

    return this.options.price;
};

FogBlock.prototype.getBubbles = function () {
    return this.options.bubbles || [];
};

FogBlock.prototype.isAvailable = function () {
    if (this.options.available === false || this.options.debugMode === true && !cleverapps.config.debugMode) {
        return false;
    }

    return this.options.parentFogId === undefined || this.fogs.isOpened(this.options.parentFogId);
};

FogBlock.prototype.scrollAndUpdateStateAction = function (newState) {
    var state = this.state;

    cleverapps.focusManager.display({
        focus: "scrollToFogAndUpdateState",
        keepControls: true,
        actions: [
            function (f) {
                cleverapps.timeouts.setTimeout(f, 300);
            },

            function (f) {
                this.fogs.map2d.getScrollView().runAction(new cc.CellScrollAction(this.head, {
                    allowScrollWithFocus: true
                }).easing(cc.easeInOut(2)));
                cleverapps.timeouts.setTimeout(f, cc.ScrollAction.SCROLL_TO_DURATION * 1000);
            }.bind(this),

            function (f) {
                this.trigger("updateBlocker", newState);

                if (state < FogBlock.CANTOPEN && FogBlock.CANTOPEN <= newState) {
                    cleverapps.timeouts.setTimeout(f, this.getDelayBeforeOpening());
                } else {
                    f();
                }
            }.bind(this),

            function (f) {
                this.updateState(newState, false, true);

                if (state < FogBlock.CANTOPEN && FogBlock.CANTOPEN <= newState) {
                    this.hideBalloon(true);
                    cleverapps.timeouts.setTimeout(f, this.getOpenAnimationDuration());
                } else {
                    f();
                }
            }.bind(this),

            function (f) {
                this.trigger("updateState", newState);
                cleverapps.timeouts.setTimeout(f, 300);
            }.bind(this),

            function (f) {
                if (this.state < FogBlock.CANTOPEN) {
                    f();
                    return;
                }

                if (this.isMoney()) {
                    cleverapps.timeouts.setTimeout(function () {
                        cleverapps.focusManager.hideAllControls();
                        this.createBuyWindow();
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }.bind(this), 300);
                } else {
                    if (this.isFree()) {
                        this.open();
                    }
                    f();
                }
            }.bind(this),

            function (f) {
                f();
                this.fogs.calcFogStatesStage();
            }.bind(this)
        ]
    });
};

FogBlock.prototype.showBuyWindow = function () {
    if (this.isMoney()) {
        cleverapps.focusManager.display({
            focus: "BuyFogWindow",
            action: function (f) {
                this.createBuyWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this)
        });
    }
};

FogBlock.prototype.createBuyWindow = function () {
    if (cleverapps.config.name === "hustlemerge") {
        Map2d.currentMap.getScrollView().runAction(
            new cc.Sequence(
                new cc.ZoomAction(0.5, { zoom: 0.8 }),
                new cc.CellScrollAction(this.head, {
                    allowScrollWithFocus: true
                }).easing(cc.easeInOut(2))
            )
        );
    }

    new BuyFogWindow(this);
};

FogBlock.prototype.getProduct = function () {
    if (!this.isMoney() || this.isSoftMoney()) {
        return;
    }

    return Product.CreateByHardPrice(this.getOpenPrice(), {
        type: "fogs",
        fogId: this.id,
        expedition: cleverapps.travelBook.getCurrentPage().id
    }, ["island1", "island2", "island5", "island10", "island15", "island20", "island30", "island45"]);
};

FogBlock.prototype.onBuy = function (f, success) {
    if (success) {
        this.open();

        var world = cleverapps.travelBook.isExpedition() ? ("_" + cleverapps.travelBook.getCurrentPage().prefix) : "";
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SPENT.FOGBUY + world + "_" + this.id);
        cleverapps.abTest.allLogEvent(cleverapps.EVENTS.SPENT.FOGBUY + world + "_" + this.id);

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SPENT.FOG);

        f(true);
    } else {
        f(false);
    }
};

FogBlock.prototype.buy = function (f) {
    var product = this.getProduct();
    if (product) {
        product.buy(function (success) {
            this.onBuy(f, success);
        }.bind(this));
        return;
    }

    this.onBuy(f, cleverapps.user.spendSoft(cleverapps.EVENTS.SPENT.PAY_ISLAND, this.getOpenPrice(), { source: this.onGetView() }));
};

FogBlock.prototype.getPriceTag = function () {
    var product = this.getProduct();
    if (product) {
        return product.getCurrentPrice();
    }

    return "@@ " + this.getOpenPrice();
};

FogBlock.prototype.isReady = function () {
    if (cleverapps.environment.isEditorScene()) {
        return false;
    }
    return this.listNotFinishedConditions().length === 0;
};

FogBlock.prototype.canOpen = function () {
    if (this.isMoney()) {
        return true;
    }
    return this.getOpenPrice() <= this.fogs.wands;
};

FogBlock.prototype.listNotFinishedConditions = function () {
    var unfinished = [];

    if (cleverapps.config.editorMode || !this.options.conditions) {
        return unfinished;
    }

    var level = this.options.conditions.level;
    if (cleverapps.gameLevel && cleverapps.gameLevel.getLevel() < level) {
        unfinished.push({
            level: level
        });
    }

    var hero = this.options.conditions.hero;
    if (hero && !cleverapps.unitsLibrary.isHeroAvailable(hero)) {
        unfinished.push({
            hero: hero
        });
    }

    var family = this.options.conditions.family;
    if (family) {
        cleverapps.toArray(family).forEach(function (family) {
            var lastUnit = UnitsLibrary.LastStage(family);

            if (!cleverapps.unitsLibrary.isOpened(lastUnit)) {
                unfinished.push({
                    familyLastUnit: lastUnit
                });
            }
        });
    }

    var unit = this.options.conditions.unit;
    if (unit) {
        cleverapps.toArray(unit).forEach(function (unit) {
            if (!cleverapps.unitsLibrary.isOpened(unit)) {
                unfinished.push({
                    unit: unit,
                    hidden: unit.hidden
                });
            }
        });
    }

    return unfinished;
};

FogBlock.prototype.open = function () {
    this.unlock();
};

FogBlock.prototype.unlock = function () {
    var merge = Game.currentGame;
    if (this.options.exp) {
        merge.addReward("exp", this.options.exp, this.head);
    }

    this.trigger("open");
    this.purge();

    this.head && this.head.onOpenFogBlock();

    for (var i = 0; i < this.tiles.length; i++) {
        this.tiles[i].onOpen(undefined, true);
        this.fogs.map2d.blockedGrounds.add(this.tiles[i].x, this.tiles[i].y);
    }
    this.trigger("open");
    if (this.balloonTile && this.balloonTile.fake) {
        this.balloonTile.open();
    }
    delete this.balloonTile;

    this.tiles = [];
    this.head = undefined;

    if (this.getBubbles().length > 0) {
        var pocket = new Pocket();
        pocket.addUnits(this.getBubbles());
    }

    if (!this.isMoney()) {
        this.fogs.takeWands(this.getOpenPrice());
    }

    Map2d.mapEvent(Map2d.OPENFOG, { fog: this.id });

    this.fogs.open(this.id);
    if (merge) {
        merge.unitStories && merge.unitStories.onFogOpened(this.id);
        Map2d.currentMap.customers.onFogOpened();
    }

    if (this.id === "dragonIsland") {
        Map2d.mapEvent(Map2d.OPENDRAGON);
    }

    var expedition = cleverapps.travelBook.getCurrentExpedition();
    if (expedition) {
        var startTime = expedition.getStartTime();
        if (startTime < Date.now()) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.OPEN_FOG + expedition.prefix + "-" + this.id);

            if (startTime > 0) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.OPEN_FOG_TIME + expedition.prefix + "-" + this.id, {
                    value: Math.floor((Date.now() - startTime) / 60 / 60 / 1000)
                });
            }
        }
    }
};

FogBlock.prototype.showBalloon = function (silent) {
    if (this.balloon) {
        this.balloon.show(silent);
    }
};

FogBlock.prototype.hideBalloon = function (silent) {
    if (this.balloon) {
        this.balloon.hide(silent);
    }
};

FogBlock.prototype.getConsoleInfo = function () {
    return {
        id: this.id,
        options: this.options,
        state: this.state
    };
};

FogBlock.prototype.isBorderTile = function (tileToCheck) {
    var amountNeighbours = 0;
    this.tiles.forEach(function (tile) {
        if (Math.abs(tileToCheck.x - tile.x) + Math.abs(tileToCheck.y - tile.y) === 1) {
            amountNeighbours++;
        }
    });
    return amountNeighbours < 4;
};

FogBlock.RestoreExpeditionId = function (context) {
    return context.expedition;
};

FogBlock.RestoreActions = function (context, consume) {
    var fogBlock = Map2d.currentMap.fogs.getBlockById(context.fogId);
    if (!fogBlock) {
        return;
    }

    return [
        function (f) {
            Map2d.currentMap.getScrollView().runAction(new cc.CellScrollAction(fogBlock.head, {
                allowScrollWithFocus: true
            }).easing(cc.easeInOut(2)));

            cleverapps.timeouts.setTimeout(f, cc.ScrollAction.SCROLL_TO_DURATION * 1000);
        },

        function (f) {
            f = cleverapps.wait(2, f);

            consume();
            fogBlock.onBuy(f, true);
            cleverapps.timeouts.setTimeout(f, 500);
        }
    ];
};

FogBlock.UNAVAILABLE = 0;
FogBlock.NOTREADY = 1;
FogBlock.CANTOPEN = 2;
FogBlock.CANOPEN = 3;

FogBlock.DELAY_BEFORE_OPENING = {
    mergecraft: 500,
    wondermerge: 1200
};

Product.RESTORE_LOGIC["fogs"] = FogBlock;