/**
 * Created by r4zi4l on 17.12.2021
 */

var DraggableView = function (component, unitView) {
    this.unitView = unitView;
};

DraggableView.prototype.handleDragStart = function () {
    var spine = this.unitView.getAnimation();
    if (spine && spine.hasAnimation("idle_up")) {
        spine.setCompleteListener();
        spine.setAnimation(0, "idle_up", true);
    }
};

DraggableView.prototype.handleDragEnd = function (accepted) {
    if (accepted) {
        this.unitView.stopAllActions();
    }

    var spine = this.unitView.getAnimation();
    if (spine && spine.hasAnimation("idle_up")) {
        spine.setCompleteListener();

        var animations = UnitView.getUnitAnimations(this.unitView.unit);
        if (animations) {
            spine.setIdleSet(animations);
        } else {
            spine.setAnimation(0, "animation", true);
        }
    }
};

DraggableView.prototype.showTarget = function (target) {
    var styles = cleverapps.styles.DraggableView;

    target = target.onGetView();

    if (target) {
        target.setImageOpacity(255, true);
        target.markDragTarget();

        target.draggableArrow = new cc.Sprite(bundles.merge_animations.frames.pointing_arrow);
        target.draggableArrow.setPositionRound(styles.arrow);

        target.animateAppearAdditionalView("arrow", target.draggableArrow);

        target.draggableArrow.runAction(new cc.RepeatForever(new cc.Sequence(
            new cc.MoveBy(1, 0, styles.arrow.delta).easing(cc.easeInOut(1.7)),
            new cc.MoveBy(1, 0, -styles.arrow.delta).easing(cc.easeInOut(1.7))
        )));

        var prizeView = target.getAdditionalView("prize");
        if (prizeView) {
            prizeView.hide();
        }
    }
};

DraggableView.prototype.pointToTarget = function (target) {
    var styles = cleverapps.styles.DraggableView;
    var targetView = target.onGetView();
    targetView.setImageOpacity(255, true);
    targetView.markDragTarget();

    targetView.draggableArrow = new cc.Sprite(bundles.merge_animations.frames.pointing_arrow);
    targetView.draggableArrow.setPositionRound(styles.arrow);

    targetView.animateAppearAdditionalView("arrow", targetView.draggableArrow);

    targetView.draggableArrow.runAction(new cc.Sequence(
        new cc.Repeat(new cc.Sequence(
            new cc.MoveBy(0.5, 0, styles.arrow.delta).easing(cc.easeInOut(1.7)),
            new cc.MoveBy(0.5, 0, -styles.arrow.delta).easing(cc.easeInOut(1.7))
        ), 2),
        new cc.CallFunc(this.unShowTarget.bind(this, target))
    ));
};

DraggableView.prototype.unShowTarget = function (target) {
    target = target.onGetView();

    if (target && target.draggableArrow) {
        target.restoreOpacity();
        target.markNormal();

        target.removeAdditionalView("arrow");

        target.draggableArrow = undefined;

        var prizeView = target.getAdditionalView("prize");
        if (prizeView) {
            prizeView.animateAppear();
        }
    }
};

DraggableView.prototype.canBeAccepted = function (accepted) {
    this.unitView.stopAllActions();

    if (accepted) {
        this.acceptAction = this.unitView.runAction(new cc.RepeatForever(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.ScaleTo(0.15, 1.15).easing(cc.easeInOut(2)),
            new cc.ScaleTo(0.20, 0.97).easing(cc.easeInOut(2)),
            new cc.ScaleTo(0.20, 1.15).easing(cc.easeInOut(2)),
            new cc.ScaleTo(0.25, 1).easing(cc.easeInOut(2)),
            new cc.DelayTime(0.3)
        )));
    } else {
        this.acceptAction = this.unitView.runAction(new cc.ScaleTo(0.1, 1));
    }
};

DraggableView.prototype.clearAnimations = function () {
    if (this.acceptAction && !this.acceptAction.isDone()) {
        this.unitView.stopAction(this.acceptAction);
        delete this.acceptAction;
    }
};

cleverapps.styles.DraggableView = {
    arrow: {
        x: { align: "center" },
        y: { align: "top", dy: 30 },
        delta: 10
    }
};