/**
 * Created by razial on 15.05.2024.
 */

var SimpleInfoView = cc.Node.extend({
    ctor: function (info, unit) {
        this._super();

        this.debugId = "InfoView";

        this.unit = unit;

        var styles = cleverapps.styles.SimpleInfoView;

        var background = this.createBackground(info);
        background.setPosition(background.width / 2, background.height / 2);

        this.setContentSize(background.width, background.height);
        this.setAnchorPoint(0.5, 0.5);
        this.addChild(background);

        var rect = cc.rectSubPadding(cc.rect(0, 0, this.width, this.height), cc.padding(styles.padding));

        var content = this.content = this.createContent(info);
        cleverapps.UI.fitToBox(content, {
            width: rect.width,
            height: rect.height
        });
        content.setPositionRound(styles.content);
        this.addChild(content);

        cleverapps.UI.onClick(this, this.onClicked.bind(this, info), {
            interactiveScale: false
        });

        this.selectAdviceTarget(info);
    },

    createBackground: function (info) {
        var styles = cleverapps.styles.SimpleInfoView;

        var warning = false;

        if (info.buttons && info.buttons.energy && info.buttons.energy.energy > cleverapps.lives.amount) {
            warning = true;
        }

        var background = new cc.Sprite(warning ? bundles.merge.frames.simple_info_bg_warning : bundles.merge.frames.simple_info_bg);

        var pointer = new cc.Sprite(warning ? bundles.merge.frames.simple_info_pointer_warning : bundles.merge.frames.simple_info_pointer);
        pointer.setPositionRound(styles.pointer);
        background.addChild(pointer);

        return background;
    },

    createContent: function (info) {
        if (info.buttons && info.buttons.energy) {
            return new TextWithIcon(info.buttons.energy.energy + "%%", {
                font: cleverapps.styles.FONTS.SIMPLEINFO_TEXT
            });
        }

        if (info.buttons && info.buttons.explode) {
            return new cc.Sprite(bundles.merge.frames.icon_explode);
        }
    },

    selectAdviceTarget: function (info) {
        if (info.buttons && info.buttons.energy) {
            this.adviceTarget = "energy";
        }
    },

    onClicked: function (info) {
        if (this.clicked) {
            return;
        }
        this.clicked = true;

        var action;

        if (info.buttons && info.buttons.energy) {
            action = info.buttons.energy.action;
        } else if (info.buttons && info.buttons.explode) {
            action = info.buttons.explode.action;
        }

        cleverapps.audio.playSound(bundles.main.urls.click_effect);

        InfoView.CloseInfo(Boolean(action), false, 160);

        if (action && action()) {
            this.animateDelta(info);
        }
    },

    animateDelta: function (info) {
        var movingNode = cleverapps.scenes.getMovingNode(this);

        this.content.replaceParentSamePlace(movingNode, {
            keepScale: true
        });

        var styles = cleverapps.styles.SimpleInfoView.backlight;

        var backlight = new cc.Sprite(bundles.merge.frames.simple_info_backlight);
        backlight.setPosition(this.content.width / 2, this.content.height / 2);
        backlight.setLocalZOrder(-1);
        backlight.setOpacity(0);

        this.content.setCascadeOpacityEnabledRecursively(true);

        this.content.runAction(new cc.Sequence(
            new cc.ScaleTo(0.15, this.content.scaleX * 1.05, this.content.scaleY * 0.7),
            new cc.ScaleTo(0.075, this.content.scaleX, this.content.scaleY),
            new cc.ScaleTo(0.075, this.content.scaleX * 0.8, this.content.scaleY * 1.05),
            new cc.ScaleTo(0.225, this.content.scaleX, this.content.scaleY)
        ));

        this.content.runAction(new cc.Sequence(
            new cc.DelayTime(0.15),
            new cc.CallFunc(function () {
                if (info.buttons && info.buttons.energy) {
                    var width = this.content.width * this.content.scaleX;
                    this.content.setString("-" + this.content.getString());
                    this.content.x -= (this.content.width * this.content.scaleX - width) / 2;
                }

                this.content.addChild(backlight);
                backlight.runAction(new cc.Sequence(
                    new cc.FadeTo(0.1, 160),
                    new cc.DelayTime(0.25),
                    new cc.FadeOut(1)
                ));
            }.bind(this)),
            new cc.MoveBy(0.15, 0, styles.deltaY / 3),
            new cc.MoveBy(0.6, 0, styles.deltaY / 3 * 2).easing(cc.easeOut(1.4))
        ));

        this.content.runAction(new cc.Sequence(
            new cc.DelayTime(0.9),
            new cc.FadeOut(0.45),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SIMPLEINFO_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.SimpleInfoView = {
    padding: {
        x: 23,
        y: 20
    },

    content: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    pointer: {
        x: { align: "center" },
        y: { align: "bottom", dy: -14 }
    },

    backlight: {
        x: { align: "center" },
        y: { align: "center" },
        deltaY: 130
    }
};
