/**
 * Created by Ivan on 18.01.2023
 */

var EditorFogBalloonView = FogBalloonView.extend({
    ctor: function (balloon) {
        this._super(balloon);

        balloon.moveTo = this.createListener(this.moveTo.bind(this));
        balloon.onDragMove = this.createListener(this.onDragMove.bind(this));

        this.animation.setScale(0.5);
    },

    show: function () {
        this.animation.setAnimation(0, "idle", true);
    },

    onDragMove: function (touch) {
        this.setPositionRound(this.parent.convertTouchToNodeSpace(touch));
    },

    moveTo: function (x, y) {
        var position = Map2d.currentMap.getMapView().alignInIsometricGrid(x, y);
        this.runAction(new cc.MoveTo(0.05, position.x, position.y));
    }
});