/**
 * Created by andrey on 26.02.2021.
 */

MergeTutorial.Types = {};

MergeTutorial.Types[Map2d.MERGE] = {
    affected: true
};

MergeTutorial.Types[Map2d.SPAWN] = {
    affected: true
};

MergeTutorial.Types[Map2d.REFILL] = {
    affected: true
};

MergeTutorial.Types[Map2d.FACTORY_SPEEDUP] = {
    affected: true,
    types: [Map2d.FACTORY_SPEEDUP, Map2d.SPAWN]
};

MergeTutorial.Types[Map2d.HARVEST] = {
    affected: true
};

MergeTutorial.Types[Map2d.SPEED_UP] = {
    affected: true,
    types: [Map2d.SPEED_UP, Map2d.MINE, Map2d.BUILD]
};

MergeTutorial.Types[Map2d.BUY_FREE_UNIT] = {
    affected: true,
    types: []
};

MergeTutorial.Types[Map2d.CLICK_UNIT] = {
    affected: true
};

MergeTutorial.Types[Map2d.EXCHANGE] = {
    noScroll: true
};

MergeTutorial.Types[Map2d.FORCE] = {
    controls: []
};

MergeTutorial.Types[Map2d.CLOSE_WINDOWS] = {
    controls: []
};