/**
 * Created by andrey on 01.02.2021.
 */

var HeroesTab = cc.Node.extend({
    ctor: function (codes, options) {
        this._super();
        this.setContentSize2(options);
        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound({
            x: { align: "center" },
            y: { align: "center" } 
        });

        this.styles = cleverapps.styles.HeroesTab;
        this.icons = this.createHeroIcons();
        this.scroll = this.createScroll();

        this.selectHero(0);
    },

    createHeroIcons: function () {
        var nextHero = cleverapps.unitsLibrary.getCurrentHero();

        var heroes = cleverapps.unitsLibrary.listCodesByType("hero").map(function (code) {
            if (cleverapps.unitsLibrary.isHeroAvailable(code) || nextHero === code) {
                return {
                    code: code,
                    stage: Families[code].units.length - 1,
                    type: "hero"
                };
            }
            return {
                type: "hero",
                unknownHero: true
            };
        });

        for (var i = 0; i < HeroesTab.UNKNOWN_HEROES; ++i) {
            heroes.push({
                type: "hero",
                unknownHero: true
            });
        }

        return heroes.map(function (hero, index) {
            return new HeroIcon(hero, function () {
                cleverapps.audio.playSound(bundles.main.urls.click_effect);
                this.selectHero(index);
            }.bind(this));
        }, this);
    },

    createScroll: function () {
        var grid = new cleverapps.Layout(this.icons, {
            direction: cleverapps.UI.HORIZONTAL,
            padding: this.styles.scroll.padding,
            margin: this.styles.scroll.margin
        });

        var scroll = new cleverapps.UI.ScrollView(grid, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            scrollBarEnabled: false,
            direction: cleverapps.UI.ScrollView.DIR_HORIZONTAL
        });
        scroll.setContentSize(this.styles.scroll.width, grid.height);
        scroll.setPositionRound(this.styles.scroll);
        scroll.setLocalZOrder(2);
        this.addChild(scroll);

        return scroll;
    },

    createHeroDetails: function (selectedIndex, searchUnit) {
        if (this.details) {
            this.details.removeFromParent();
        }

        var hero = this.icons[selectedIndex].unit;

        var units = hero.code ? cleverapps.unitsLibrary.listOpenedPlusOneWithUnknown(hero.code) : [];

        var details = new HeroDetailsLayout(hero, units, {
            width: this.width,
            searchUnit: searchUnit
        });
        details.setPositionRound(this.styles.details);
        details.setLocalZOrder(1);

        this.details = details;
        this.addChild(details);

        return details;
    },

    selectHero: function (selectedIndex, searchUnit) {
        if (this.selectedIndex === selectedIndex) {
            return;
        }

        this.icons.forEach(function (icon, index) {
            if (index === selectedIndex) {
                icon.onSelect();
            } else {
                icon.onUnSelect();
            }
        });

        this.selectedIndex = selectedIndex;
        this.createHeroDetails(selectedIndex, searchUnit);
    },

    onSelectTab: function (params) {
        params = params || {};

        var heroes = this.icons.map(function (icon) {
            return { code: icon.unit.code }; 
        });
        var hero = params.searchUnit && Unit.findUnit(heroes, { code: params.searchUnit.code })
            || cleverapps.unitsLibrary.findFreshUnit(heroes)
            || Unit.findUnit(heroes, { code: cleverapps.unitsLibrary.getCurrentHero() });
        var index = hero ? heroes.indexOf(hero) : 0;

        this.scroll.scrollToDefault();
        this.scroll.runAction(new cc.ScrollAction(this.icons[index], {
            silent: true,
            visibleBox: {
                left: 0.96,
                right: 0.96
            }
        }));

        this.selectHero(index, params.searchUnit);
    },

    onUnSelectTab: function () {
        this.scroll.stopAutoScroll();
    }
});

HeroesTab.UNKNOWN_HEROES = cleverapps.config.name === "mergecraft" ? 1 : 3;

cleverapps.styles.HeroesTab = {
    details: {
        x: { align: "center" },
        y: { align: "top", dy: -60 }
    },

    scroll: {
        width: 1960,

        x: { align: "center" },
        y: { align: "bottom" },

        padding: {
            x: 60,
            y: 65
        },

        margin: 40
    }
};