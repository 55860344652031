/**
 * Created by andrey on 16.12.2020.
 */

var EditorMap2d = function (layer, options) {
    EditorMap2dBase.call(this, layer, options);
};

EditorMap2d.prototype = Object.create(EditorMap2dBase.prototype);
EditorMap2d.prototype.constructor = EditorMap2d;

EditorMap2d.prototype.produceMap = function (x, y, active) {
    this.setValue(Map2d.LAYER_WATER, Iso.SAME, undefined);

    if (Map2d.BORDERS) {
        this.setValue(Map2d.LAYER_BORDERS, Iso.SAME, undefined);
    }

    this.setValue(Map2d.LAYER_GROUND, Iso.SAME, undefined);
    this.setValue(Map2d.ABOVE_GROUND, Iso.SAME, undefined);

    if (active.tile === Map2d.TILE_WATER || active.tile === Map2d.TILE_WATER_UNIT) {
        this.setValue(Map2d.LAYER_UNITS, Iso.SAME, undefined);

        this.setValue(Map2d.LAYER_WATER, Iso.SAME, active.tile);

        if (Map2d.BORDERS) {
            this.setValue(Map2d.LAYER_BORDERS, Iso.SAME, Map2d.TILE_BORDER);
        }
    } else if (active.tile !== undefined) {
        this.setTerrain(x, y, active.terrain);
        this.setValue(Map2d.LAYER_GROUND, Iso.SAME, active.tile);
        this.setValue(
            Map2d.ABOVE_GROUND, 
            Iso.SAME,
            this.isImpassableGround(this.pointer.x, this.pointer.y) ? Map2d.TILE_STARRED_GROUND_CHESS : Map2d.TILE_GROUND_CHESS
        );
    }

    var dx, dy;

    for (dy = -2; dy <= 2; dy++) {
        for (dx = -2; dx <= 2; dx++) {
            if (!this.containsCell(x + dx, y + dy)) {
                continue;
            }

            this.layers.forEach(function (layer, layerId) {
                this.setPointer(x + dx, y + dy);
                if (!layer[y + dy]) {
                    return;
                }

                var value = layer[y + dy][x + dx];
                if (layerId === Map2d.LAYER_GROUND && value === Map2d.TILE_GROW) {
                    value = Map2d.TILE_GREEN_LEVEL_1;
                }
                this.setValue(layerId, Iso.SAME, value);
            }.bind(this));
        }
    }

    for (dy = -3; dy <= 3; dy++) {
        for (dx = -3; dx <= 3; dx++) {
            if (this.containsCell(x + dx, y + dy)) {
                this.constructGrowth(x + dx, y + dy);
            }
        }
    }

    for (dy = -3; dy <= 3; dy++) {
        for (dx = -3; dx <= 3; dx++) {
            if (this.containsCell(x + dx, y + dy)) {
                this.edges.updateEdges(x + dx, y + dy);
                this.removeTiles(x + dx, y + dy);
                this.showTiles(x + dx, y + dy);
            }
        }
    }
};

EditorMap2d.prototype.produceUnit = function (x, y, active) {
    if (!this.isGround(x, y) && !this.isWaterUnit(x, y)) {
        return;
    }

    this.setValue(Map2d.LAYER_UNITS, Iso.SAME, undefined);

    var options = {
        code: active.code,
        stage: active.stage
    };

    var data = Families[active.code].units[active.stage];

    if (data.source || data.grounded) {
        options.grounded = true;
    }

    var unit = new Unit(options);
    unit.setPosition(x, y);
    if (unit.findComponent(LockedComponent)) {
        unit.findComponent(LockedComponent).setLockActive(true);
    }

    this.add(Map2d.LAYER_UNITS, x, y, unit);
    this.onAddUnit(x, y, unit);
};

EditorMap2d.prototype.getDraggable = function (x, y) {
    var fog = this.getFog(x, y);
    var fogId = fog && fog.fogBlock && fog.fogBlock.id;
    var selected = this.fogs.selected;
    var selectedCode = selected && selected.code;

    if (fogId === selectedCode && selectedCode) {
        return [fog.fogBlock.balloon, fog.fogBlock.editorHead].find(function (draggable) {
            return cc.pointEqualToPoint(draggable && draggable.tile || draggable, cc.p(x, y));
        });
    }
};

EditorMap2d.prototype.processFogEraser = function (cell) {
    var fogTile = Map2d.currentMap.getFog(cell.x, cell.y);
    if (fogTile && fogTile.fogBlock && fogTile.fogBlock.id !== this.fogs.selected.code) {
        return false;
    }

    var brushMode = cleverapps.keyboardController.isPressed(cc.KEY.b);
    if (brushMode) {
        this.fogs.clearRegion(this.fogs.selected);
    } else {
        this.fogs.remove(cell.x, cell.y, this.fogs.selected.code);
    }

    Map2d.currentMap.saveLevel();
    return false;
};

EditorMap2d.prototype.saveLevel = cleverapps.timeredThrottle(cleverapps.parseInterval("1 seconds"), function () {
    var locationName = cleverapps.administrator.adminLocations.activeLocation.bundleName;
    var content = bundles[locationName].jsons.map_json.getJson();

    content.map = this.getLevelMap();
    content.terrains = this.getLevelTerrain();
    content.field = this.getLevelField();
    content.regions = this.getLevelRegion();

    content.decorators = this.decorators.getSaveInfo();

    cleverapps.administrator.adminLocations.save(content);
});

EditorMap2d.prototype.getLevelRegion = function () {
    for (var code in this.regions) {
        var region = this.regions[code];
        var positionsUnique = cleverapps.unique(region.positions, Unit.GetPositionKey);
        if (region.positions.length !== positionsUnique.length) {
            region.positions = positionsUnique;
            cleverapps.throwAsync("Region " + code + " positions non unique");
        }
    }
    return this.regions;
};

EditorMap2d.prototype.getLevelTerrain = function () {
    return this.terrains.map(function (line, y) {
        return line.map(function (terrain, x) {
            return this.layers[Map2d.LAYER_GROUND][y][x] ? terrain : Map2d.TERRAIN_EMPTY;
        }.bind(this)).join("");
    }.bind(this));
};

EditorMap2d.prototype.getLevelMap = function () {
    var map = [];
    for (var y = 0; y < this.getHeight(); y++) {
        var row = [];
        for (var x = 0; x < this.getWidth(); x++) {
            var value = this.isWaterUnit(x, y) ? Map2d.TILE_WATER_UNIT : Map2d.TILE_WATER;
            if (this.layers[Map2d.LAYER_GROUND][y][x] === Map2d.TILE_GROW) {
                value = Map2d.TILE_GREEN_LEVEL_1;
            }
            if (this.isLand(x, y)) {
                value = this.layers[Map2d.LAYER_GROUND][y][x];
            }
            row.push(value);
        }
        map.push(row.join(""));
    }
    return map;
};

EditorMap2d.prototype.getLevelField = function () {
    var field = [];
    for (var y = 0; y < this.getHeight(); y++) {
        for (var x = 0; x < this.getWidth(); x++) {
            var unit = this.getUnit(x, y);
            if (unit && !unit.isMultiCellBody()) {
                var unitData = {
                    x: x,
                    y: y,
                    code: unit.code,
                    stage: unit.stage
                };

                if (unit.isLockedUnit()) {
                    unitData.lock = 1;
                }

                field.push(unitData);
            }
        }
    }
    return field;
};

EditorMap2d.prototype.add = function (layer, x, y, unit) {
    if (unit && (unit.chooser || unit.head && unit.head.chooser)) {
        return;
    }

    EditorMap2dBase.prototype.add.call(this, layer, x, y, unit);
};

EditorMap2d.prototype.remove = function (layer, x, y) {
    this.trigger("removeValue", layer, x, y);
    EditorMap2dBase.prototype.remove.call(this, layer, x, y);
    this.saveLevel();
};
