/**
 * Created by iamso on 24.12.2021
 */

var Improver = function (unit, options) {
    UnitComponent.call(this, unit);

    this.present = undefined;
    if (options.pc !== undefined) {
        this.present = {
            code: options.pc,
            stage: options.ps
        };
    }
    this.time = options.time || 0;

    if (this.present) {
        this.timeout = new cleverapps.LongTimeout(this.complete.bind(this), this.getTimeLeft());
    }
};

Improver.prototype = Object.create(UnitComponent.prototype);
Improver.prototype.constructor = Improver;

Improver.CODES = ["drgiftaxe", "drgiftcuirass", "drgifthelm", "drgiftshield",
    "seaanchor", "seawheel", "seaspyglass", "seapiratehook"];

Improver.prototype.onPrizeHarvested = function () {
    this.harvestProcessed();
};

Improver.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

Improver.prototype.canAcceptDraggable = function (unit) {
    if (this.present) {
        return false;
    }

    return Improver.CODES.indexOf(unit.code) !== -1 && unit.stage <= 4;
};

Improver.prototype.acceptDraggable = function (unit) {
    this.present = {
        code: unit.code,
        stage: unit.stage
    };
    this.unit.save();
    unit.getDnDAccepted(this.unit);
    this.start();
};

Improver.prototype.destructor = function () {
    clearTimeout(this.timeout);
};

Improver.prototype.start = function () {
    clearTimeout(this.timeout);
    this.time = Date.now();
    this.timeout = new cleverapps.LongTimeout(this.complete.bind(this), this.getTimeLeft());
    this.unit.save();
    this.onUpdateState();
};

Improver.prototype.complete = function () {
    clearTimeout(this.timeout);
    this.time = 0;

    var targetUnitData = this.calcRewardUnit();
    if (!targetUnitData) {
        this.reset();
        return;
    }

    this.unit.setPrizes([targetUnitData], Families[targetUnitData.code].units[targetUnitData.stage].createPrize.exp);

    this.onUpdateState();
};

Improver.prototype.harvestProcessed = function () {
    this.reset();
    this.onUpdateState();
};

Improver.prototype.reset = function () {
    clearTimeout(this.timeout);
    this.time = 0;
    delete this.present;
    this.unit.save();
};

Improver.prototype.calcRewardUnit = function () {
    var code = this.present.code;
    var stage = this.present.stage + 1;

    if (Families[code] && Families[code].units[stage]) {
        return { code: code, stage: stage };
    }
};

Improver.prototype.getTimeLeft = function () {
    return Math.max(0, this.time + Improver.GENERATE_DURATION - Date.now());
};

Improver.prototype.isWorking = function () {
    return this.getTimeLeft() > 0;
};

Improver.prototype.save = function (data) {
    if (this.present) {
        data.pc = this.present.code;
        data.ps = this.present.stage;
        data.time = this.time;
    }
};

Improver.prototype.getActionInfo = function () {
    var info = {
        title: this.unit,
        windowParameters: {
            unit: this.unit,
            guideOptions: ImproverGuideOptions
        }
    };

    if (this.getTimeLeft()) {
        info.countdown = this.getTimeLeft();
        info.buttons = {
            speedup: {
                unit: this.unit,
                action: this.complete.bind(this),
                duration: this.getTimeLeft(),
                price: 99
            }
        };
    }

    return info;
};

Improver.GENERATE_DURATION = cleverapps.parseInterval("30 minutes");