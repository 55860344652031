/**
 * Created by iamso on 18.11.2021
 */

var RuinsView = cc.Node.extend({
    ctor: function (ruins, unitParentView) {
        this._super();

        this.ruins = ruins;
        this.unitView = unitParentView;

        this.restoreState();
        cleverapps.bundleLoader.ensureSoundsLoaded(bundles.merge.urls.ruins_explode);
    },

    restoreState: function () {
        if (cleverapps.gameModes.hideTankProgress || !this.ruins.getTimeLeft()) {
            this.removeTimer();
        } else {
            this.createTimer();
        }
    },

    createTimer: function () {
        var styles = cleverapps.styles.RuinsView.timer;
        var timer = new ComponentTimer(new cleverapps.CountDown(this.ruins.getTimeLeft()), Ruins.LIFETIME);
        timer.setPositionRound(styles);
        this.unitView.createAdditionalView("timer", timer);
    },

    removeTimer: function () {
        this.unitView.removeAdditionalView("timer");
    },

    blowUp: function (tierName) {
        cleverapps.audio.playSound(bundles.merge.urls.ruins_explode);
        this.unitView.sprite.setAnimation(0, tierName === "cheap" ? "boom_small" : "boom_big", false);
        this.unitView.sprite.setCompleteListener(function () {
            this.unitView.removeFromParent();
        }.bind(this));
    }
});

cleverapps.styles.RuinsView = {
    timer: {
        x: { align: "center" },
        y: { align: "bottom" }
    }
};