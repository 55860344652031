/**
 * Created by spepa on 22.06.2023
 */

var Map2dFences = function (map) {
    this.map = map;
    this.fences = [];
    this.regions = [];

    for (var name in this.map.regions) {
        if (name.startsWith("fence_")) {
            this.map.regions[name].positions.forEach(function (cell) {
                this.addRegion(cell.x, cell.y, name);
            }, this);
        }
    }

    for (var y in this.regions) {
        for (var x in this.regions[y]) {
            this.addFence(+x, +y);
        }
    }

    Map2dFences.AutoCreateRegions(map);
};

Map2dFences.prototype.addRegion = function (x, y, regionName) {
    if (!this.regions[y]) {
        this.regions[y] = {};
    }

    this.regions[y][x] = regionName;
};

Map2dFences.prototype.removeRegion = function (x, y) {
    if (this.regions[y]) {
        delete this.regions[y][x];
    }
};

Map2dFences.prototype.getRegion = function (x, y) {
    return this.regions[y] && this.regions[y][x];
};

Map2dFences.prototype.addFence = function (x, y) {
    var regionName = this.getRegion(x, y);

    if (!regionName) {
        return;
    }

    if (!this.fences[y]) {
        this.fences[y] = {};
    }

    if (!this.fences[y][x]) {
        this.fences[y][x] = [];
    }

    var type = this.calcCellFenceType(x, y);
    type.codes.forEach(function (code) {
        var fence = new Map2dFence(regionName, code, x, y);
        this.fences[y][x].push(fence);
    }.bind(this));
};

Map2dFences.prototype.removeFence = function (x, y) {
    if (this.fences[y] && this.fences[y][x]) {
        delete this.fences[y][x];
    }
};

Map2dFences.prototype.getFences = function (x, y) {
    return this.fences[y] && this.fences[y][x];
};

Map2dFences.prototype.calcCellFenceType = function (x, y) {
    var regionName = this.getRegion(x, y);

    var emptyDirs = "UDRL";

    for (var dir in Map2dFences.DIRS) {
        var neighbour = cc.pAdd(cc.p(x, y), Map2dFences.DIRS[dir]);
        var neighbourRegion = this.getRegion(neighbour.x, neighbour.y);

        var hasNeighbor = neighbourRegion && (neighbourRegion === regionName || Map2dFences.SINGLE_BORDER && "DL".includes(dir));

        if (hasNeighbor) {
            emptyDirs = emptyDirs.replace(dir, "");
        }
    }

    var fenceName = Map2dFence.getFenceName(regionName);
    var types = bundles[fenceName].meta.corner ? Map2dFences.CORNER_TYPES : Map2dFences.TYPES;

    return types[emptyDirs || "inner"];
};

Map2dFences.prototype.addFencesViews = function (x, y) {
    var fences = this.getFences(x, y);
    if (!fences) {
        return;
    }

    var mapView = Map2d.currentMap.getMapView();

    for (var i = 0; i < fences.length; i++) {
        var fence = fences[i];
        if (!fence.isDisplayed()) {
            return;
        }

        var view = fence.getView();
        if (!view) {
            mapView.addTile(Map2d.LAYER_UNITS, fence.x, fence.y, new Map2dFenceView(fence));
        } else {
            view.invisible = false;
            cc.renderer.childrenOrderDirty = true;
        }
    }
};

Map2dFences.AutoCreateRegions = function (map) {
    Object.keys(bundles).forEach(function (name) {
        if (name.startsWith("fence_") && !map.regions[name]) {
            if (bundles[name].injectTo && bundles[name].injectTo.includes(Map2d.getUnitsTexture(map.unitsSkin))) {
                map.regions[name] = { positions: [] };
            }
        }
    });
};

Map2dFences.DIRS = {
    U: { y: -1, x: 0 },
    R: { y: 0, x: 1 },
    D: { y: 1, x: 0 },
    L: { y: 0, x: -1 }
};

Map2dFences.TYPES = {
    inner: { codes: ["inner"] },
    U: { codes: ["up"] },
    UR: { codes: ["up", "right"] },
    R: { codes: ["right"] },
    DR: { codes: ["down", "right"] },
    D: { codes: ["down"] },
    DL: { codes: ["left", "down"] },
    L: { codes: ["left"] },
    UL: { codes: ["up", "left"] },
    RL: { codes: ["right", "left"] },
    UD: { codes: ["up", "down"] },
    DRL: { codes: ["left", "right", "down"] },
    UDL: { codes: ["up", "left", "down"] },
    URL: { codes: ["up", "right", "left"] },
    UDR: { codes: ["up", "right", "down"] },
    UDRL: { codes: ["up", "left", "right", "down"] }
};

Map2dFences.CORNER_TYPES = {
    inner: { codes: ["inner"] },
    U: { codes: ["upleft"] },
    UR: { codes: ["up", "right"] },
    R: { codes: ["rightdown"] },
    DR: { codes: ["right", "downright"] },
    D: { codes: ["down"] },
    DL: { codes: ["leftup", "down"] },
    L: { codes: ["left"] },
    UL: { codes: ["up", "leftup"] },
    RL: { codes: ["right", "leftup"] },
    UD: { codes: ["up", "down"] },
    DRL: { codes: ["rightdown", "left", "downright"] },
    UDL: { codes: ["upleft", "leftup", "down"] },
    URL: { codes: ["up", "right", "leftup"] },
    UDR: { codes: ["up", "right", "downright"] },
    UDRL: { codes: ["up", "right", "downright", "leftup"] }
};

if (cleverapps.config.name === "wordsoup") {
    Map2dFences.SINGLE_BORDER = true;
}