/**
 * Created by evgeny on 01.08.2024
 */

var HighLight = function (unit) {
    UnitComponent.call(this, unit);

    this.unit = unit;
};

HighLight.prototype = Object.create(UnitComponent.prototype);
HighLight.prototype.constructor = HighLight;

HighLight.prototype.removeHighlight = function () {
    this.isHighlighted = false;
    if (this.view) {
        this.view.restoreState(true);
    }
};

HighLight.prototype.addHighlight = function () {
    this.isHighlighted = true;
    if (this.view) {
        this.view.restoreState(true);
    }
};