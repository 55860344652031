var CupComponentView = cc.Node.extend({
    ctor: function (cup, unitView) {
        this._super();

        this.cup = cup;
        this.unitView = unitView;

        this.createProgress();
        this.restoreState();
    },

    restoreState: function (animated) {
        if (cleverapps.gameModes.hideGuideAndProgress || this.cup.isFull() || this.cup.isEmpty()) {
            this.hideProgress(animated);
        } else {
            this.showProgress(animated);
        }

        this.updateProgress(animated);
        if (animated && !cleverapps.gameModes.muteCupsSound) {
            cleverapps.audio.playSound(bundles.cup.urls[this.cup.unit.code + "_effect"]);
        }
    },

    createProgress: function () {
        var styles = cleverapps.styles.CupComponentView;

        this.progress = new ScaledProgressBar({
            type: ScaledProgressBar.Types.blue_small_with_frames
        });
        this.progress.hidden = false;
        this.unitView.addChild(this.progress);
        this.progress.setPositionRound(styles.progress);
        this.progress.setLength(styles.progress.width);
    },

    updateProgress: function (animated) {
        this.progress.setGoal(this.cup.unit.getData().cup.amount);
        this.progress.setPercentage(this.cup.amount, {
            animated: animated
        });
    },

    showProgress: function (animated) {
        if (!this.progress.hidden) {
            return;
        }
        this.progress.hidden = false;
        this.progress.stopAllActions();

        if (!animated) {
            this.progress.setVisible(true);
            return;
        }

        this.progress.runAction(new cc.Sequence(
            new cc.MoveBy(0, 0, 15),
            new cc.ScaleTo(0, 0.5),
            new cc.Show(),
            new cc.Spawn(
                new cc.MoveBy(0.1, 0, -15),
                new cc.ScaleTo(0.1, 1)
            )
        ));
    },

    hideProgress: function (animated) {
        if (this.progress.hidden) {
            return;
        }
        this.progress.hidden = true;
        this.progress.stopAllActions();

        if (!animated) {
            this.progress.setVisible(false);
            return;
        }

        this.progress.setVisible(true);
        this.progress.runAction(new cc.Sequence(
            new cc.ScaleTo(0.1, 0.3),
            new cc.Hide()
        ));
    }
});

cleverapps.styles.CupComponentView = {
    progress: {
        width: 84,
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -50 }
    }
};
