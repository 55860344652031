/**
 * Created by razial on 20.11.2023
 */

Telly.PRIZES.dragonia3 = Telly.PRIZES.dragonia;

MissionTree.PRIZES.dragonia3 = [{
    amount: 5,
    units: [
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 2 }
    ]
}];

Ruins.TIERS.dragonia3 = {
    cheap: {
        soft: 59,
        rewards: [
            { code: "drfreechest", stage: 0, amount: 1 },
            { code: "worker", stage: 0, amount: 1 },
            { code: "crystal", stage: 0, amount: 1 }
        ]
    },
    costly: {
        hard: 249,
        rewards: [
            { code: "drshopchest", stage: 1, amount: 1 },
            { code: "crystal", stage: 3, amount: 1 },
            { code: "drmagicplant", stage: 5, amount: 1 },
            { code: "worker", stage: 4, amount: 1 }
        ]
    }
};

Landmarks.WORLDS.dragonia3 = {
    target: { code: "drcustomermain0", stage: 0 },
    force: Forces.LANDMARK_DONOR_SLOT2,
    landmarks: [{
        code: "landmark",
        stage: 11,
        targetExpedition: "main",
        donors: ["drcustomermain0", "drcustomermain1", "drcustomermain2", "drcustomermain3"]
    }]
};

FieldComponent.RECIPES.dragonia3 = [{
    prize: { code: "drproduct0a", stage: 0 },
    duration: cleverapps.parseInterval("15 minute")
}, {
    prize: { code: "drproduct0b", stage: 0 },
    duration: cleverapps.parseInterval("15 minute"),
    opened: true
}];

CustomerRecipes.dragonia3 = {
    drproducer1a_0: [
        {
            ingredients: [{
                code: "drproduct0a",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "drproduct1a",
                stage: 0,
                amount: 1
            }]
        }, // 1
        {
            opened: true,
            ingredients: [{
                code: "drproduct0b",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "drproduct1a",
                stage: 0,
                amount: 1
            }]
        }, // 2
        {
            opened: true,
            ingredients: [{
                code: "drproduct0a",
                stage: 0,
                amount: 1
            }, {
                code: "drproduct0b",
                stage: 0,
                amount: 2
            }],
            order: [{
                code: "drproduct1a",
                stage: 0,
                amount: 1
            }]
        }, // 3
        {
            opened: true,
            ingredients: [{
                code: "drproduct0a",
                stage: 0,
                amount: 2
            }, {
                code: "drproduct0b",
                stage: 0,
                amount: 1
            }],
            order: [{
                code: "drproduct1a",
                stage: 0,
                amount: 1
            }]
        }// 4
    ],

    drproducer1b_0: [
        {
            opened: true,
            ingredients: [{
                code: "drproduct0a",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "drproduct1b",
                stage: 0,
                amount: 1
            }]
        }, // 1
        {
            opened: true,
            ingredients: [{
                code: "drproduct0b",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "drproduct1b",
                stage: 0,
                amount: 1
            }]
        }, // 2
        {
            opened: true,
            ingredients: [{
                code: "drproduct0a",
                stage: 0,
                amount: 1
            }, {
                code: "drproduct0b",
                stage: 0,
                amount: 2
            }],
            order: [{
                code: "drproduct1b",
                stage: 0,
                amount: 1
            }]
        }, // 3
        {
            opened: true,
            ingredients: [{
                code: "drproduct0a",
                stage: 0,
                amount: 2
            }, {
                code: "drproduct0b",
                stage: 0,
                amount: 1
            }],
            order: [{
                code: "drproduct1b",
                stage: 0,
                amount: 1
            }]
        }// 4
    ],

    drproducer2a_0: [
        {
            ingredients: [{
                code: "drproduct1a",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "drproduct2a",
                stage: 0,
                amount: 1
            }]
        }, // 1
        {
            ingredients: [{
                code: "drproduct1b",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "drproduct2a",
                stage: 0,
                amount: 1
            }]
        }, // 2
        {
            ingredients: [{
                code: "drproduct1a",
                stage: 0,
                amount: 1
            }, {
                code: "drproduct1b",
                stage: 0,
                amount: 2
            }],
            order: [{
                code: "drproduct2a",
                stage: 0,
                amount: 1
            }]
        }, // 3
        {
            ingredients: [{
                code: "drproduct1a",
                stage: 0,
                amount: 2
            }, {
                code: "drproduct1b",
                stage: 0,
                amount: 1
            }],
            order: [{
                code: "drproduct2a",
                stage: 0,
                amount: 1
            }]
        }// 4
    ],

    drproducer2b_0: [
        {
            ingredients: [{
                code: "drproduct1a",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "drproduct2a",
                stage: 0,
                amount: 1
            }]
        }, // 1
        {
            ingredients: [{
                code: "drproduct1b",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "drproduct2a",
                stage: 0,
                amount: 1
            }]
        }, // 2
        {
            ingredients: [{
                code: "drproduct1a",
                stage: 0,
                amount: 1
            }, {
                code: "drproduct1b",
                stage: 0,
                amount: 2
            }],
            order: [{
                code: "drproduct2a",
                stage: 0,
                amount: 1
            }]
        }, // 3
        {
            ingredients: [{
                code: "drproduct1a",
                stage: 0,
                amount: 2
            }, {
                code: "drproduct1b",
                stage: 0,
                amount: 1
            }],
            order: [{
                code: "drproduct2a",
                stage: 0,
                amount: 1
            }]
        }// 4
    ],

    drproducertrash_0: [{
        ingredients: [{
            code: "drproduct2a",
            stage: 0,
            amount: 3
        }],
        order: [{
            code: "worker",
            stage: 1,
            amount: 1
        }, {
            code: "energy",
            stage: 1,
            amount: 1
        }]
    }],

    drcustomermain0_0: [{
        ingredients: [{
            code: "drproduct2a",
            stage: 0,
            amount: 3
        }],
        order: [{
            code: "worker",
            stage: 2,
            amount: 1
        }, {
            code: "crystal",
            stage: 1,
            amount: 1
        }, {
            code: "energy",
            stage: 1,
            amount: 1
        }]
    }],

    drcustomerbridge1_0: [{
        ingredients: [{
            code: "drproduct1a",
            stage: 0,
            amount: 3
        }],
        order: [{
            code: "drgrowing",
            stage: 1,
            amount: 3
        }]
    }]
};

CustomerRecipes.dragonia3.drcustomermain1_0 = CustomerRecipes.dragonia3.drcustomermain0_0;
CustomerRecipes.dragonia3.drcustomermain2_0 = CustomerRecipes.dragonia3.drcustomermain0_0;
CustomerRecipes.dragonia3.drcustomermain3_0 = CustomerRecipes.dragonia3.drcustomermain0_0;
