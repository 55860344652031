/**
 * Created by mac on 12/23/20
 */

var Pulsing = function (unit) {
    UnitComponent.call(this, unit);

    var data = unit.getData();
    this.active = !data.resourceCollectible && !data.feedableTarget;
};

Pulsing.prototype = Object.create(UnitComponent.prototype);
Pulsing.prototype.constructor = Pulsing;

Pulsing.prototype.buildingComplete = function () {
    this._updatePulsing();
};

Pulsing.prototype.beforeSpawn = function () {
    if (this.view) {
        this.view.delayFlickering();
    } 
};

Pulsing.prototype.setRemoved = function (removed) {
    this.removed = removed;
    this._updatePulsing();
};

Pulsing.prototype.setActive = function (active) {
    this.active = active;
    this._updatePulsing();
};

Pulsing.prototype.setDragging = function (dragging) {
    this.dragging = dragging;
    this._updatePulsing();
};

Pulsing.prototype.isPulsing = function () {
    if (cleverapps.gameModes.unitsPulsingDisabled) {
        return false;
    }

    return this.active && this.unit.isBuilt() && !this.dragging && !this.removed;
};

Pulsing.prototype._updatePulsing = function () {
    if (this.view) {
        this.view.restoreState();
    }
};
