/**
 * Created by andrey on 09.02.2021.
 */

var DecoratorUnit = function (options) {
    this.code = options.code;
    this.decorator = true;

    this.onUpdate = function () {};
};

DecoratorUnit.prototype.isSelected = function () {
    return Editor.currentEditor.selected === this;
};

DecoratorUnit.prototype.onClick = function () {
    if (this.isSelected()) {
        Editor.currentEditor.unselect();
    } else {
        Editor.currentEditor.select(this);
    }
};