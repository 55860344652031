/**
 * Created by vtbelo on 17/03/21
 */

var PushingCollection = function (collectionName) {
    this.name = collectionName;
    this.gravity = cleverapps.styles.PushingCollection.gravity;
    this.elements = [];
    this._pushingDirty = undefined;
};

PushingCollection.prototype.destructor = function () {
    this.elements = [];
};

PushingCollection.prototype.isEmpty = function () {
    return !this.elements.length;
};

PushingCollection.prototype.setPushingDirty = function (element) {
    this._pushingDirty = true;
    element._pushingDirty = true;
    element._closestPushing = undefined;
};

PushingCollection.prototype.add = function (element) {
    element._pushingRadius = (element.width + element.height) / 4;
    this.elements.push(element);
    this.setPushingDirty(element);
};

PushingCollection.prototype.remove = function (element) {
    var i;
    for (i = 0; i < this.elements.length; i++) {
        if (this.elements[i] === element) {
            this.elements.splice(i, 1);
            break;
        }
    }

    for (i = 0; i < this.elements.length; i++) {
        if (this.elements[i]._closestPushing) {
            if (this.elements[i]._closestPushing.closest === element) {
                this.elements[i]._closestPushing = undefined;
            }
        }
    }
};

PushingCollection.prototype.pausePushing = function (element) {
    element._pausePushing = true;
};

PushingCollection.prototype.resumePushing = function (element) {
    element._pausePushing = undefined;
    this.setPushingDirty(element);
};

PushingCollection.prototype.pushingDistance = function (element1, element2) {
    var d = (element1.x - element2.x) * (element1.x - element2.x) + (element1.y - element2.y) * (element1.y - element2.y);
    if (d < (element1._pushingRadius + element2._pushingRadius) * (element1._pushingRadius + element2._pushingRadius)) {
        return {
            distance: d
        };
    }
    return undefined;
};

PushingCollection.prototype.findClosest = function (element) {
    var best = undefined;
    for (var i = 0; i < this.elements.length; i++) {
        if (this.elements[i] !== element && !this.elements[i]._pausePushing) {
            var d = this.pushingDistance(element, this.elements[i]);
            if (d) {
                this.elements[i]._pushingDirty = true;
                if (best === undefined || best.distance > d.distance) {
                    best = {
                        distance: d.distance,
                        closest: this.elements[i]
                    };
                }
            }
        }
    }

    return best;
};

PushingCollection.prototype.pushing = function (dt) {
    if (!this._pushingDirty) {
        return;
    }
    this._pushingDirty = undefined;

    for (var i = 0; i < this.elements.length; i++) {
        var element = this.elements[i];
        if (element._pushingDirty) {
            element._pushingDirty = undefined;
            if (element._pausePushing) {
                continue;
            }

            if (element._closestPushing) {
                var distance = this.pushingDistance(element, element._closestPushing.closest);
                if (!distance || element._closestPushing.closest._pausePushing) {
                    element._closestPushing = undefined;
                } else {
                    element._closestPushing.distance = distance.distance;
                }
            }

            if (!element._closestPushing) {
                element._closestPushing = this.findClosest(element);
            }

            if (element._closestPushing) {
                var closest = element._closestPushing.closest;

                if (closest._closestPushing && closest._closestPushing.distance > element._closestPushing.distance) {
                    closest._closestPushing = {
                        closest: element,
                        distance: element._closestPushing.distance
                    };
                    closest._pushingDirty = true;
                }

                var op = { x: element.x - closest.x, y: element.y - closest.y };
                var r = cc.pLength(op);
                if (r < 1) {
                    op = { x: (1 - 2 * Math.random()), y: (1 - 2 * Math.random()) };
                } else {
                    op = { x: op.x / r * this.gravity * dt, y: op.y / r * this.gravity * dt };
                }

                element.setPosition(element.x + op.x, element.y + op.y);

                element._pushingDirty = true;
                this._pushingDirty = true;
            }
        }
    }
};

cleverapps.styles.PushingCollection = {
    gravity: 100
};
