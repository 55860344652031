/**
 * Created by Vladislav on 25.09.2024.
 */

var hiddenByDefault = function () {
    var scenes = [cleverapps.Environment.SCENE_OCTOPUS_HUNT];

    if (["match3"].includes(cleverapps.config.type)) {
        scenes.push(cleverapps.Environment.SCENE_GAME);
    }

    return scenes;
};

var SoftMenuBarItem = {
    name: "CoinsItem",
    targets: "soft",
    plusButton: true,
    value: function () {
        return cleverapps.user.soft;
    },
    updater: function (f) {
        cleverapps.user.on("changeSoft", f, this);
    },
    attentionUpdater: function (f) {
        cleverapps.adsLimits.on("update", f, this);
    },
    attention: function () {
        return false;
    },
    onClickWindow: SoftCurrencyShopWindow,
    icon: bundles.menubar.frames.coin_png,
    force: Forces.MENU_BAR_SOFT_FORCE,
    availableOnScenes: function () {
        return [
            cleverapps.Environment.SCENE_MAIN,
            cleverapps.Environment.SCENE_GAME,
            cleverapps.Environment.SCENE_OCTOPUS_HUNT
        ];
    },
    hiddenByDefault: hiddenByDefault(),
    sparks: true,
    deltaSound: bundles.menubar.urls.coins_effect
};