/**
 * Created by r4zi4l on 27.01.2022
 */

var ResourceDetails = cc.Node.extend({ 
    ctor: function (action, resourceView) {
        this._super();
        this.action = action;
        this.resourceView = resourceView;

        var styles = cleverapps.styles.ResourceDetails;
        var items = [];

        if (this.action.title) {
            items.push(this.createTitle(this.action.title));
        } else if (this.action.unit) {
            items.push(this.createTitle(cleverapps.unitsLibrary.getUnitName(this.action.unit)));
        }

        if (this.action.resources) {
            items.push(this.createResources(this.action.resources));
        }

        if (this.action.duration) {
            items.push(this.createTimer(this.action.duration));
        }

        if (typeof this.action.locked === "string") {
            items.push(this.createTitle(this.action.locked));
        }

        var layout = new cleverapps.Layout(items, {
            direciton: cleverapps.UI.HORIZONTAL,
            padding: styles.padding,
            margin: styles.margin
        });

        this.setContentSize2(layout.width, layout.height);

        var background = cleverapps.UI.createScale9Sprite(bundles.merge.frames.background, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(this.width, this.height);
        background.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(background);

        layout.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(layout);

        cleverapps.UI.applyHover(this, {
            onMouseOver: this.onMouseOver.bind(this),
            onMouseOut: this.onMouseOut.bind(this)
        });
    },

    createTitle: function (text) {
        var title = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.RESOURCEVIEW_TEXT);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        title.fitTo(cleverapps.styles.ResourceDetails.width);
        return title;
    },

    createResources: function (resources) {
        var styles = cleverapps.styles.ResourceDetails.resources;

        var items = Object.keys(resources).map(function (code) {
            var background = cleverapps.UI.createScale9Sprite(bundles.progress_bar.frames.bg_green);
            background.setContentSize2(styles.background);

            var current = Game.currentGame.harvested.get(code);
            var needed = resources[code];

            var amount = cleverapps.UI.generateImageText(current + "/" + needed, cleverapps.styles.FONTS.RESOURCEVIEW_ITEM_TEXT);
            amount.setAnchorPoint(0.5, 0.5);
            amount.fitTo(styles.amount.width);
            if (current < needed) {
                amount.setColor(cleverapps.styles.COLORS.COLOR_RED);
            }

            background.addChild(amount);
            amount.setPositionRound(styles.amount);

            var icon = UnitView.getUnitImage({
                code: code,
                stage: 0
            }, {
                alignAnchorX: true,
                preferStatic: true
            });
            cleverapps.UI.fitToBox(icon, styles.icon);
            icon.setPositionRound(styles.icon);

            var container = new cc.Node();
            container.setAnchorPoint(1, 0.5);
            container.addChild(background);
            container.addChild(icon);
            cleverapps.UI.wrap(container);

            return container;
        });

        return new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL
        });
    },

    createTimer: function (duration) {
        return new cleverapps.CountDownView(duration, {
            font: cleverapps.styles.FONTS.RESOURCEVIEW_TIMER_TEXT,
            icon: {
                frame: bundles.timer_merge.frames.timer_png,
                json: bundles.timer_merge.jsons.timer_icon_json,
                animation: "animation",
                stopped: true
            }
        });
    },

    onMouseOver: function () {
        this.resourceView.extendDetails();
    },

    onMouseOut: function () {
        this.resourceView.hideDetails();
    }
});

ResourceDetails.IsAvailable = function (action) {
    return action.resources || action.duration || typeof action.locked === "string";
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    RESOURCEVIEW_TEXT: {
        size: 23,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    RESOURCEVIEW_TIMER_TEXT: {
        name: "nostroke",
        size: 24,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    RESOURCEVIEW_ITEM_TEXT: {
        size: 25,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.ResourceDetails = {
    width: 185,
    margin: 5,

    padding: {
        x: 12,
        y: 20
    },

    resources: {
        background: {
            width: 120,
            height: 46
        },

        icon: {
            x: { align: "center", dx: -55 },
            y: { align: "center" },
            height: 60,
            width: 100
        },

        amount: {
            x: { align: "center", dx: 14 },
            y: { align: "center", dy: 1 },
            width: 72
        }
    }
};
