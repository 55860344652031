/**
 * Created by vladislav on 26/10/2022
 */

var CustomerOrderView = cc.Node.extend({
    ctor: function (order) {
        this._super();

        this.order = order;

        var styles = cleverapps.styles.CustomerOrderView;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);

        var icon;
        if (this.order.unit) {
            icon = UnitView.getUnitImage(this.order.unit, { preferStatic: true });
        } else if (this.order.ingredient) {
            icon = Game.currentGame.harvested.getIngredientSmallIcon(this.order.ingredient);
        }

        if (icon) {
            this.addChild(icon);
            icon.setPositionRound(this.width / 2, this.height / 2);
            cleverapps.UI.fitToBox(icon, { width: this.width, height: this.height, maxScale: 1.3 });
        }

        if (this.order.amount) {
            var amount = cleverapps.UI.generateImageText(this.order.amount, cleverapps.styles.FONTS.CUSTOMER_ORDER_TEXT);
            this.addChild(amount);
            amount.setPositionRound(styles.amount);
        }
    },

    showUp: function () {
        this.setScale(0.3);
        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(0.7),
            new cc.Show(),
            new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut())
        ));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CUSTOMER_ORDER_TEXT: {
        size: 40,
        name: "default"
    }
});

cleverapps.styles.CustomerOrderView = {
    width: 130,
    height: 130,

    amount: {
        x: { align: "right", dx: -10 },
        y: { align: "bottom", dy: 2 }
    }
};