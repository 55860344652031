/**
 * Created by razial on 26.10.2021
 */

var PocketBubble = function (unit) {
    this.code = unit.code;
    this.stage = unit.stage;
    this.x = unit.x;
    this.y = unit.y;

    this.onRemoved = function () {};
    this.onShowFinger = function () {};
    this.onGetView = function () {};
};

PocketBubble.prototype.remove = function () {
    Game.currentGame.pocket.removeBubble(this);
    this.onRemoved();
};

PocketBubble.prototype.setPosition = function (x, y) {
    this.x = Math.round(x);
    this.y = Math.round(y);
    Game.currentGame.pocket.save();
};

PocketBubble.prototype.spawn = function (node) {
    if (this.spawned) {
        return;
    }

    var remains = Game.currentGame.spawn({ code: this.code, stage: this.stage }, node, {
        fromNode: true
    });
    if (!remains.length) {
        this.spawned = true;
        this.remove();
    }
};

PocketBubble.prototype.showFinger = function () {
    this.onShowFinger();
};
