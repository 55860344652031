/**
 * Created by r4zi4l on 14.05.2021
 */
var PassRulesOptions = {
    name: "PassRulesWindow",

    getGuideBundle: function () {
        return bundles.passruleswindow;
    },

    stagesContent: function () {
        var styles = cleverapps.styles.PassRulesWindow;
        return [
            this.initStage(styles.firstStage, function (styles, container) {
                this.addSprite(container, this.bundle.frames.blue_light, styles.light);
                this.addSprite(container, this.bundle.frames.worker_with_building, styles.unit);
            }.bind(this)),

            this.initStage(styles.secondStage, function (styles, container) {
                this.addSprite(container, this.bundle.frames.stand_ground, styles.ground);
                this.addSprite(container, this.bundle.frames.guide_rays, styles.light);
                this.addUnitIcon(container, "wood", 6, styles.unit);
                var star = new cleverapps.Spine(bundles.merge.jsons.buildpass_point);
                star.setScale(styles.star.scale || 1.0);
                star.setAnchorPoint(0.5, 0.5);
                star.setPositionRound(styles.star);
                star.setAnimation(0, "idle", true);
                container.addChild(star);
                star.runAction(new cc.Sequence(
                    new cc.DelayTime(0.1),
                    new cc.CallFunc(star.clearTrack.bind(star, 0))
                ));
                this.addSprite(star, bundles.finger.frames.tutorial_hand_png, styles.finger);
            }.bind(this)),

            this.initStage(styles.thirdStage, function (styles, container) {
                this.addSprite(container, this.bundle.frames.yellow_light, styles.light);
                this.addSprite(container, this.bundle.frames.stand_ground, styles.ground);
                this.addSprite(container, this.bundle.frames.prizes_heap, styles.prizes);
            }.bind(this))
        ];
    }
};

cleverapps.styles.PassRulesWindow = {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        light: {
            x: 0,
            y: 0,
            scale: 1.2
        },

        unit: {
            x: 0,
            y: 0,
            scale: 0.8
        }
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        ground: {
            x: 0,
            y: -70
        },

        light: {
            x: 0,
            y: -20
        },

        unit: {
            x: -5,
            y: 0,
            scale: 1.5
        },

        star: {
            x: -15,
            y: 25,
            scale: 1.5
        },

        finger: {
            x: 160,
            y: 0
        }
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        light: {
            x: 0,
            y: 0
        },

        ground: {
            x: 0,
            y: -50
        },

        prizes: {
            x: 0,
            y: 0,
            scale: 0.7
        }
    }
};
