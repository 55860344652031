/**
 * Created by andrey on 09.02.2021.
 */

var DecoratorUnits = function () {
    cleverapps.EventEmitter.call(this);

    var targetBundles = [
        Map2d.getTilesTexture(Map2d.currentMap.tilesSkin),
        Map2d.getUnitsTexture(Map2d.currentMap.unitsSkin)
    ];

    var codes = Object.keys(bundles).filter(function (name) {
        return name.indexOf("decorator_") === 0 && cleverapps.intersect(bundles[name].injectTo, targetBundles).length;
    }).map(function (name) {
        return name.slice(10);
    });

    this.units = codes.map(function (code) {
        return new DecoratorUnit({
            code: code
        });
    });

    var terrains = [];

    Object.values(Map2d.TERRAINS).forEach(function (terrain) {
        var bundle = Map2d.getTilesTexture(Map2d.currentMap.tilesSkin) + "_" + terrain;

        if (!bundles[bundle]) {
            return;
        }

        for (var frame in bundles[bundle].frames) {
            terrains.push(bundle + "#" + frame);
        }
    });

    this.terrains = terrains.map(function (code) {
        return new DecoratorUnit({
            code: code
        });
    });
};

DecoratorUnits.prototype = Object.create(cleverapps.EventEmitter.prototype);
DecoratorUnits.prototype.constructor = DecoratorUnits;

DecoratorUnits.prototype.getUnits = function (options) {
    switch (options && options.type) {
        case DecoratorUnits.TYPE_TERRAIN:
            return this.terrains;
        default:
            return this.units;
    }
};

DecoratorUnits.prototype.getUnitIcons = function (options) {
    return this.getUnits(options).map(function (unit) {
        return new DecoratorUnitIcon(unit);
    });
};

DecoratorUnits.prototype.produce = function (x, y, selected) {
    Map2d.currentMap.decorators.add({
        x: x,
        y: y,
        code: selected.code,
        rotation: 0
    });
};

DecoratorUnits.TYPE_DECORATOR = "decorator";
DecoratorUnits.TYPE_TERRAIN = "terrain";
