/**
 * Created by r4zi4l on 04.05.2022
 */

var OfferManager = function () {
    this.offers = {};
    this.inactiveOffersData = {};

    cleverapps.flags.on("change:monetization", this.refreshProductOffers.bind(this));

    this.shownOfferTypes = [];
    this.pendingWindowShowTime = 0;
};

OfferManager.prototype.initialize = function () {
    this.inited = true;

    var data = cleverapps.dataLoader.load(DataLoader.TYPES.OFFERS);
    if (!data) {
        data = this.loadFromOldFormat();
    }

    this.migratePacksData(data);

    this.inactiveOffersData = this._expandData(data) || {};
    this.refreshAll();
};

OfferManager.prototype.loadFromOldFormat = function () {
    var data = {};

    for (var name in Offers.TYPES) {
        var type = Offers.TYPES[name];
        var savedPurchaseTime = cleverapps.dataLoader.load(DataLoader.TYPES.OFFER_PURCHASE_TIME + type);
        if (savedPurchaseTime) {
            data[type] = {
                finished: savedPurchaseTime
            };
        }
    }

    return data;
};

OfferManager.prototype.migratePacksData = function (data) {
    var oldData = cleverapps.dataLoader.load(DataLoader.TYPES.PACK);
    if (data[Offers.TYPES.PACK] || !oldData || !oldData.used) {
        return;
    }

    data[Offers.TYPES.PACK] = {
        stage: oldData.used
    };
};

OfferManager.prototype.updateInfo = function (data) {
    for (var type in this.offers) {
        this.removeOffer(Number(type), true);
    }
    this.offers = {};
    this.inactiveOffersData = this._expandData(data) || {};
    this.refreshAll();

    this.save(true);
};

OfferManager.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.OFFERS, this.getInfo());
    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("offers");
    }
};

OfferManager.prototype.getInfo = function () {
    var activeOffersData = {};
    for (var type in this.offers) {
        activeOffersData[type] = this.offers[type].getInfo();
    }

    var fullData = Object.assign({}, this.inactiveOffersData, activeOffersData);
    return this._compactData(fullData);
};

OfferManager.prototype.refreshAll = function () {
    for (var name in Offers.TYPES) {
        this.refreshOffer(Offers.TYPES[name]);
    }

    for (var type in this.offers) {
        this.offers[type].checkCompletion();
    }

    for (var activeOfferType in this.offers) {
        cleverapps.playSession.set(cleverapps.EVENTS.OFFER_DAU + "_" + this.offers[activeOfferType].name, true);
    }
};

OfferManager.prototype.refreshProductOffers = function () {
    for (var name in Offers.TYPES) {
        var offerType = Offers.TYPES[name];

        if (Offers[offerType] && Offers[offerType].product) {
            this.refreshOffer(offerType);
        }
    }
};

OfferManager.prototype.refreshOffer = function (offerType) {
    if (!Offers[offerType]) {
        return;
    }

    if (this.isOfferAvailable(offerType)) {
        if (!this.offers[offerType]) {
            this.addOffer(offerType);
        }
    } else if (this.offers[offerType]) {
        this.removeOffer(offerType);
    }
};

OfferManager.prototype.addOffer = function (offerType) {
    var offer = new Offer(offerType, this.inactiveOffersData[offerType]);
    delete this.inactiveOffersData[offerType];

    this.offers[offerType] = offer;

    if (!cleverapps.sideBar.findOfferIcon(offerType)) {
        var icon = new OfferIcon(offer);
        cleverapps.sideBar.addTemporaryIcon(icon);
    }

    this.save();
};

OfferManager.prototype.removeOffer = function (offerType, fromServer) {
    this.offers[offerType].beforeRemove();

    var offerInfo = this.offers[offerType].getInfo();
    this.inactiveOffersData[offerType] = offerInfo;

    delete this.offers[offerType];

    var icon = cleverapps.sideBar.findOfferIcon(offerType);
    if (icon) {
        cleverapps.sideBar.removeTemporaryIcon(icon);
    }

    if (!fromServer) {
        this.save();
    }
};

OfferManager.prototype.refreshByMission = function (missionType) {
    var offer = Offers.offerByMission[missionType] || Offers.offerByMission[Mission.GetChildType(missionType)];
    if (offer) {
        this.refreshOffer(offer.type);
    }
};

OfferManager.prototype.refreshByHero = function (unit) {
    var offer = Offers.offerByHero[Unit.GetKey(unit)];
    if (offer) {
        this.refreshOffer(offer.type);
    }
};

OfferManager.prototype.refreshByFog = function (fog) {
    var offers = Offers.offersByFog[fog];
    if (offers) {
        offers.forEach(function (offer) {
            this.refreshOffer(offer.type);
        }.bind(this));
    }
};

OfferManager.prototype.clearForceByMission = function (missionType) {
    if (Offers.offerByMission[missionType] && Offers.offerByMission[missionType].force) {
        cleverapps.forces.clearForce(Offers.offerByMission[missionType].force.id);
    }
};

OfferManager.prototype.findOffer = function (options) {
    if (options.type) {
        return this.offers[options.type];
    }

    var product = options.product;
    var mission = options.mission === undefined ? undefined : Mission.GetChildType(options.mission);

    for (var type in this.offers) {
        var offer = this.offers[type];

        if (product !== undefined && product !== offer.logic.product) {
            continue;
        }

        if (mission !== undefined && mission !== offer.logic.mission && options.mission !== offer.logic.mission) {
            continue;
        }

        return offer;
    }
};

OfferManager.prototype.isOfferAvailable = function (offerType) {
    var offerData = Offers[offerType];

    if (!this.inited || !offerData) {
        return false;
    }

    if (offerData.available && !levels.user.checkAvailable(offerData.available)) {
        return false;
    }

    if (offerData.mission) {
        var mission = cleverapps.missionManager.findByType(offerData.mission);
        if (!mission || mission.isCompleted()) {
            return false;
        }

        var expedition = Missions[offerData.mission].id || Missions[offerData.mission].expedition;
        if (expedition && (cleverapps.travelBook.getCurrentPage().id !== expedition)) {
            return false;
        }
    } else {
        if (cleverapps.flags.monetization !== cleverapps.Flags.MONETIZATION_REGULAR) {
            return false;
        }

        if (offerData.feature && !cleverapps.eventManager.isActive(offerData.feature)) {
            return false;
        }

        if (offerData.expedition) {
            if (offerData.expedition && offerData.expedition !== cleverapps.travelBook.getCurrentPage().id) {
                return false;
            }
        } else if (!((cleverapps.environment.isMainScene() || cleverapps.environment.isGameScene()) && !cleverapps.travelBook.isExpedition())) {
            return false;
        }
    }

    if (offerData.debugMode && !cleverapps.config.debugMode) {
        return false;
    }

    if (offerData.hero && cleverapps.unitsLibrary.isOpened(offerData.hero)) {
        return false;
    }

    if (offerData.product) {
        if (!connector.payments.getProduct(offerData.product) || cleverapps.flags.monetization !== cleverapps.Flags.MONETIZATION_REGULAR) {
            return false;
        }
    }

    var game = Game.currentGame;
    if (offerData.fog && (!game || !game.map || !game.map.fogs.isOpened(offerData.fog))) {
        return false;
    }

    var offerInfo = this.inactiveOffersData[offerType] || {};
    if (this.offers[offerType]) {
        offerInfo = this.offers[offerType].getInfo();
    }
    if (offerData.cooldown && offerInfo.finished && Date.now() <= offerInfo.finished + cleverapps.parseInterval(offerData.cooldown)) {
        return false;
    }

    if (offerData.availableByStages) {
        var currentStage = offerInfo.stage || 0;
        if (currentStage >= offerData.availableByStages.length || !levels.user.checkAvailable(offerData.availableByStages[currentStage])) {
            return false;
        }
    }

    return true;
};

OfferManager.prototype.findOfferWithPendingWindow = function () {
    if (this.pendingWindowShowTime + OfferManager.SHOW_WINDOW_INTERVAL > Date.now()) {
        return;
    }

    for (var type in this.offers) {
        if (this.shownOfferTypes.indexOf(Number(type)) === -1 && Offers[type].pendingWindow) {
            return this.offers[type];
        }
    }
};

OfferManager.prototype.showOfferWindow = function (offer) {
    cleverapps.focusManager.display({
        focus: offer.name + "IconClicked",
        action: function (f) {
            if (this.shownOfferTypes.indexOf(offer.type) === -1) {
                this.shownOfferTypes.push(offer.type);
            }
            this.pendingWindowShowTime = Date.now();

            var WindowCtor = Offers[offer.type].view;
            new WindowCtor(offer);

            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.OFFER_OPEN + "_" + Offers[offer.type].name);

            cleverapps.focusManager.onceNoWindowsListener = f;
        }.bind(this)
    });
};

OfferManager.prototype.getOfferExpedition = function (offerType) {
    var pageId = "main";
    var data = Offers[offerType];

    if (data) {
        if (data.expedition) {
            pageId = cleverapps.travelBook.getPageById(data.expedition) ? data.expedition : pageId;
        } else if (data.mission) {
            var missionType = Mission.ParseCompoundType(data.mission);
            var parentType = missionType.parentType !== undefined ? missionType.parentType : missionType.type;
            pageId = Missions[parentType] && Missions[parentType].id || pageId;
        }
    }

    return pageId;
};

OfferManager.prototype.getShopTileModels = function () {
    if (!cleverapps.environment.isMainScene() && !cleverapps.environment.isGameScene()) {
        return [];
    }
    var tileModels = [];

    for (var type in this.offers) {
        var offer = this.offers[type];
        var shopIcon = offer.name.toLowerCase().replace(/[0-9]/g, "");

        if (bundles.tile_shop.frames[shopIcon] && offer.logic.isIconAvailable() && offer.logic.getProduct()) {
            var product = offer.logic.getProduct();

            tileModels.push(new OfferTileModel({
                offer: offer,
                shopIcon: shopIcon,
                product: product,
                title: Offers[offer.type].mission ? "MissionOfferWindow." + offer.name + ".title" : product.title,
                reward: Offers[offer.type].reward || product.reward
            }));
        }
    }

    if (cleverapps.config.type === "merge") {
        var offerName = cleverapps.travelBook.isExpedition() ? "energyofferexp" : "energyoffer";
        if (Game.currentGame.specialEnergyOffer.getIconLeftTime() > 0 && bundles.tile_shop.frames[offerName]) {
            tileModels.push(new OfferTileModel({
                offer: {
                    name: offerName,
                    complete: function () {
                        Game.currentGame.specialEnergyOffer.refreshAfterBought();
                    }
                },
                shopIcon: offerName,
                product: Game.currentGame.specialEnergyOffer.getProduct(),
                title: "SpecialEnergyOfferWindow.title",
                reward: {
                    energy: Game.currentGame.specialEnergyOffer.getEnergyAmount()
                }
            }));
        }
    }

    return tileModels;
};

OfferManager.prototype._compactData = function (data) {
    var resultData = {};
    for (var type in data) {
        var offer = data[type];
        var offerData = Offers[type];

        var countOfFields = Object.keys(offer).filter(function (k) {
            return offer[k] !== undefined;
        }).length;

        if (countOfFields === 0) {
            continue;
        }

        if (offer.finished && offerData) {
            if (!offerData.cooldown) {
                continue;
            }

            if (countOfFields === 1 && Date.now() > offer.finished + cleverapps.parseInterval(offerData.cooldown)) {
                continue;
            }
        }

        resultData[type] = {
            s: cleverapps.compactTime(offer.started),
            f: cleverapps.compactTime(offer.finished),
            st: offer.stage,
            l: cleverapps.compactTime(offer.lastRewardTime)
        };
    }

    return resultData;
};

OfferManager.prototype._expandData = function (data) {
    var resultData = {};
    for (var type in data) {
        var offer = data[type];
        if (!offer) {
            continue;
        }
        resultData[type] = {
            started: offer.started || cleverapps.expandTime(offer.s),
            finished: offer.finished || cleverapps.expandTime(offer.f),
            stage: offer.stage || offer.st,
            lastRewardTime: offer.lastRewardTime || cleverapps.expandTime(offer.l)
        };
    }
    
    return resultData;
};

OfferManager.SHOW_WINDOW_INTERVAL = cleverapps.parseInterval("1 minute");
