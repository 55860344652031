/**
 * Created by Andrey Popov on 9/23/24.
 */

var FallSale = function () {
    
};

FallSale.prototype.isAvailable = function () {
    return cleverapps.user.checkAvailable(FallSale.CONFIG.available)
        && Date.now() < new Date(FallSale.CONFIG.end).getTime()
        && (connector.info.os === connector.OS_IOS || cleverapps.config.debugMode)
        && cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_REGULAR;
};

FallSale.prototype.needToShowWindow = function () {
    return this.isAvailable() && (!Map2d.currentMap || Map2d.currentMap.fogs.isOpened("fog3")) && !cleverapps.config.adminMode;
};

FallSale.prototype.showWindow = function () {
    cleverapps.focusManager.display({
        focus: "FallSaleWindow",
        action: function (f) {
            new FallSaleWindow();

            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};

FallSale.CONFIG = {
    end: "2024-10-14",
    available: {
        disabled: true,
        projectName: ["mergecraft", "wondermerge", "fairy", "hustlemerge"],
        source: ["test", "instant"]
    }
};