/**
 * Created by vladislav on 20/10/2022
 */

var Creatable = function (unit, options) {
    UnitComponent.call(this, unit);

    this.load(options);
};

Creatable.prototype = Object.create(UnitComponent.prototype);
Creatable.prototype.constructor = Creatable;

Creatable.prototype.onInfoOpened = function () {
    this.onUpdateState();
};

Creatable.prototype.onInfoClosed = function (withAction) {
    if (!withAction) {
        this.onUpdateState();
    }
};

Creatable.prototype.onCompletion = function () {
    if (this.view) {
        this.view.animateCompletion();
    }
};

Creatable.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

Creatable.prototype.isCompleted = function () {
    return this.state === Creatable.STATE_BUILT;
};

Creatable.prototype.load = function (stored) {
    if (stored.state !== undefined) {
        this.state = stored.state;
    } else {
        this.state = stored.unbuilt ? Creatable.STATE_UNBUILT : Creatable.STATE_BUILT;
    }
};

Creatable.prototype.save = function (data) {
    if (this.isCompleted()) {
        return;
    }

    data.state = this.state;
};

Creatable.prototype.getActionInfo = function () {
    if (!this.isCompleted()) {
        return {
            title: "Creatable.info.title",
            buttons: {
                create: {
                    action: this.create.bind(this),
                    energy: this.unit.getData().creatable.energy
                }
            }
        };
    }
};

Creatable.prototype.create = function () {
    if (Game.currentGame.useEnergy(this.unit.getData().creatable.energy, this.unit)) {
        this.state = Creatable.STATE_BUILT;

        this.onCompletion();

        this.unit.completeBuilding();

        this.unit.save();
    } else {
        this.onUpdateState();
    }
};

Creatable.IsCreated = function (unit) {
    var creatable = unit.findComponent(Creatable);
    return !creatable || creatable.isCompleted();
};

Creatable.STATE_UNBUILT = 0;
Creatable.STATE_BUILT = 1;