/**
 * Created by spepa on 01.03.2024
 */

var UnitMagnetHandler = function () {
    cleverapps.EventEmitter.call(this);
    this.targetUnits = [];
    this.targetCell = undefined;
};

UnitMagnetHandler.prototype = Object.create(cleverapps.EventEmitter.prototype);
UnitMagnetHandler.prototype.constructor = UnitMagnetHandler;

UnitMagnetHandler.prototype.resetTargets = function () {
    if (this.sfxInterval) {
        cleverapps.timeouts.clearInterval(this.sfxInterval);
        delete this.sfxInterval;
    }

    if (this.sfxActive) {
        cleverapps.audio.stopSound(this.sfxActive);
        delete this.sfxActive;
    }

    this.clearHighlight();
    if (this.targetUnits.length > 0) {
        this.targetUnits[0].unhighlightMerge();
        this.targetUnits[0].unhighlight();
        this.targetUnits = [];
    }
};

UnitMagnetHandler.prototype.clearHint = function () {
    cleverapps.centerHint.hideHint();
    delete this.hintActive;
    delete this.hintTimeout;
};

UnitMagnetHandler.prototype.clearHighlight = function () {
    if (this.highlight) {
        this.highlight.remove();
        delete this.highlight;
    }
};

UnitMagnetHandler.prototype.highlightCell = function (cell) {
    this.clearHighlight();
    this.highlight = new Highlight(cell.x, cell.y);
    Map2d.currentMap.onAdd(Map2d.LAYER_UNITS, this.highlight.x, this.highlight.y, this.highlight);
};

UnitMagnetHandler.prototype.processNoTarget = function () {
    this.trigger("updateDragView", { animation: "idle" });
    this.resetTargets();
    if (this.hintTimeout) {
        this.clearHint();
    }
};

UnitMagnetHandler.prototype.processNewTarget = function (newTargets) {
    if (this.hintTimeout || this.hintActive) {
        this.clearHint();
    }

    this.sfxActive = cleverapps.audio.playSound(bundles.merge.urls.unitmagnet_ready_sfx);
    this.sfxInterval = cleverapps.timeouts.setInterval(function () {
        this.sfxActive = cleverapps.audio.playSound(bundles.merge.urls.unitmagnet_ready_sfx);
    }.bind(this), 2000);

    this.targetUnits = newTargets;
    this.targetUnits[0].highlightMerge([this.targetUnits], { noMessage: true });

    this.trigger("updateDragView", { animation: "active" });
};

UnitMagnetHandler.prototype.processInvalidTarget = function (targetUnit) {
    if (!this.hintTimeout) {
        this.hintTimeout = true;

        cleverapps.centerHint.schedule(1000, function () {
            delete this.hintTimeout;
            this.hintActive = true;
            cleverapps.centerHint.createTextHint("UnitMagnet.cantUse");
        }.bind(this));
    }

    this.targetUnits = [targetUnit];
    targetUnit.highlight({ isErrorLight: true });
    this.trigger("updateDragView", { animation: "idle" });
};

UnitMagnetHandler.prototype.invalidTouch = function (touch) {
    var toolbarBg = cleverapps.toolbar.onGetView().bg;
    return toolbarBg.hasTouch(touch);
};

UnitMagnetHandler.prototype.searchTargets = function (touch) {
    if (this.invalidTouch(touch)) {
        this.processNoTarget();
        this.clearHighlight();
        return;
    }

    var map = Map2d.currentMap;
    var cell = map.getMapView().getCellByTouch(touch);

    if (cc.pSameAs(this.targetCell, cell) || !map.isValid(cell.x, cell.y)) {
        return;
    }
    this.targetCell = cell;

    var targetUnit = map.getUnitWithHead(cell.x, cell.y);
    if (!targetUnit) {
        this.processNoTarget();
        this.highlightCell(cell);
        return;
    }
    if (targetUnit === this.targetUnits[0]) {
        return;
    }
    this.resetTargets();

    var sameUnits = this.findPullInUnits(targetUnit);
    var newTargets = [targetUnit].concat(sameUnits);

    var currentNeighbors = map.bfs(targetUnit.x, targetUnit.y, map.compareEqual.bind(map, targetUnit));

    if (newTargets.length > 1 && currentNeighbors.length < newTargets.length) {
        this.processNewTarget(newTargets);
    } else {
        this.processInvalidTarget(targetUnit);
    }
};

UnitMagnetHandler.prototype.pullInUnits = function () {
    if (this.targetUnits.length < 2 || !cleverapps.user.spendSoft(cleverapps.EVENTS.SPENT.MAGNET, UnitMagnetHandler.PRICE)) {
        this.trigger("updateDragView", { animation: "idle" });
        this.resetTargets();
        this.clearHint();
        return;
    }

    var targetUnit = this.targetUnits[0];
    var pullUnits = this.targetUnits.slice(1);
    this.resetTargets();

    cleverapps.focusManager.display({
        focus: "magnetpull",
        control: ["toolbar", "MenuBarCoinsItem"],
        actions: [
            function (f) {
                cleverapps.audio.playSound(bundles.merge.urls.unitmagnet_sfx);
                cleverapps.timeouts.setTimeout(f, 1300);
            },

            function (f) {
                this.performPull(targetUnit, pullUnits);
                cleverapps.timeouts.setTimeout(f, 300);
            }.bind(this)
        ]
    });

    return true;
};

UnitMagnetHandler.prototype.findPullInUnits = function (targetUnit) {
    var canAffect = function (target) {
        return target.isMovable() && !target.isGrounded() && !target.isMultiCell() && !target.isDragged() && !target.isLifted();
    };
    if (!canAffect(targetUnit)) {
        return [];
    }

    return Map2d.currentMap.listAvailableUnits(targetUnit).filter(function (unit) {
        return targetUnit !== unit && canAffect(unit);
    });
};

UnitMagnetHandler.prototype._canDisplaceUnit = function (unit) {
    return !unit.isMultiCell() && !unit.isMultiCellBody() && (unit.isMovable() || unit.findComponent(Collectible));
};

UnitMagnetHandler.prototype._getTargetCells = function (startCell) {
    var map = Map2d.currentMap;
    var mapSize = cc.size(map.getWidth(), map.getHeight());
    var hardTerrain = 1e3;
    var paths = {};
    var pathsToRevaluate = {};

    var getCellCost = function (x, y) {
        if (!map.isGround(x, y) || map.getFog(x, y)) {
            return hardTerrain;
        }
        var unit = map.getUnit(x, y);
        return !unit || this._canDisplaceUnit(unit) ? 1 : hardTerrain;
    }.bind(this);

    var evaluateCell = function (x, y) {
        if (startCell.x === x && startCell.y === y) {
            return 1;
        }
        var ownCost = getCellCost(x, y);
        var pathCost = 1e6;

        ISO_NEIGHBORS.forEach(function (dir) {
            var neighbor = paths[x + dir.x + (y + dir.y) * mapSize.width];
            if (neighbor && (pathCost > ownCost + neighbor.cost)) {
                pathCost = ownCost + neighbor.cost;
            }
        });
        return pathCost;
    };

    map.bfs(startCell.x, startCell.y, function (x, y) {
        if (y >= mapSize.height || y < 0 || x >= mapSize.width || x < 0) {
            return false;
        }
        var key = x + y * mapSize.width;
        paths[key] = { cost: evaluateCell(x, y), x: x, y: y };
        if (paths[key].cost > hardTerrain) {
            pathsToRevaluate[key] = paths[key];
        }
        return true;
    });

    while (Object.keys(pathsToRevaluate).length > 0) {
        for (var key in pathsToRevaluate) {
            var path = pathsToRevaluate[key];
            var newCost = evaluateCell(path.x, path.y);
            delete pathsToRevaluate[key];

            if (newCost < path.cost) {
                paths[key].cost = newCost;
                ISO_NEIGHBORS.forEach(function (dir) {
                    var neighborKey = path.x + dir.x + (path.y + dir.y) * mapSize.width;
                    if (paths[neighborKey] && paths[neighborKey].cost > newCost && paths[neighborKey].cost > hardTerrain) {
                        pathsToRevaluate[neighborKey] = paths[neighborKey];
                    }
                });
            }
        }
    }

    return Object.values(paths).sort(function (a, b) {
        return a.cost - b.cost;
    }).map(function (item) {
        return { x: item.x, y: item.y };
    });
};

UnitMagnetHandler.prototype.performPull = function (targetUnit, pullUnits) {
    var isBuilt = targetUnit.isBuilt();
    pullUnits = pullUnits.sort(function (a, b) {
        if (isBuilt === a.isBuilt() && isBuilt !== b.isBuilt()) {
            return -1;
        }
        if (isBuilt !== a.isBuilt() && isBuilt === b.isBuilt()) {
            return 1;
        }
        return 0;
    });

    var map = Map2d.currentMap;
    var targetCells = this._getTargetCells(targetUnit);
    var unitsToDisplace = [];
    var pulled = 0;

    for (var i in targetCells) {
        if (pulled === pullUnits.length) {
            break;
        }
        var x = targetCells[i].x;
        var y = targetCells[i].y;

        if (!map.isGround(x, y) || map.getFog(x, y)) {
            continue;
        }

        var unit = map.getUnit(x, y);
        if (unit) {
            if (unit === targetUnit || !this._canDisplaceUnit(unit)) {
                continue;
            }

            var index = pullUnits.indexOf(unit);
            if (index !== -1) {
                pullUnits.splice(index, 1);
                continue;
            }
            unit.liftUnitFromMap();
            unitsToDisplace.push(unit);
        }

        var pullingUnit = pullUnits[pulled++];
        if (!pullingUnit.onGetView() && Map2d.currentMap.isVisibleCell(x, y)) {
            Map2d.currentMap.onAddUnit(pullingUnit.x, pullingUnit.y, pullingUnit);
        }
        pullingUnit.move(x, y);
    }

    unitsToDisplace.forEach(function (unit) {
        var cell = Map2d.currentMap.findEmptySlot(targetUnit.x, targetUnit.y);
        unit.move(cell.x, cell.y);
        if (!unit.onGetView() && Map2d.currentMap.isVisibleCell(cell.x, cell.y)) {
            Map2d.currentMap.onAddUnit(unit.x, unit.y, unit);
        }
    });
};

UnitMagnetHandler.PRICE = 25;