/**
 * Created by spepa on 01.12.2022
 */

Map2d.TERRAIN_CONFIG = {
    default: {
        height: 0,
        frames: {
            level1_down_corner: { y: 0, anchorY: 0.85 }
        }
    },

    tiles_castle_purple: {
        height: 2,
        frames: {
            level1_down_corner: { anchorY: 0.395 }
        }
    },

    tiles_castle_blue: {
        height: 2,
        frames: {
            level1_down_corner: { anchorY: 0.395 },
            level1_up_corner: { anchorY: 0.355 },
            grow12_corner: { anchorY: 0.33 }
        }
    },

    tiles_castle_green: {
        height: 0,
        frames: {
            level1_up_left_only_last: { anchorX: 0 },
            level1_up_right_only_last: { anchorX: 1 }
        }
    },

    tiles_cloudislands_purple: {
        height: 0,
        frames: {
            level1_down_corner: { y: 0, anchorY: 0.64 }
        }
    },

    tiles_cloudislands_green: {
        height: 0,
        frames: {
            level1_down_corner: { y: 0, anchorY: 0.91 }
        }
    },

    tiles_fairyland_green: {
        height: 0,
        frames: {
            level1_down_corner: { y: 0, anchorY: 0.85 },
            level1_down_right_only: { y: 0, anchorX: 0.51, anchorY: 0.115 }
        }
    },

    tiles_coloredseabottom_purple: {
        height: 0,
        frames: {
            grow12_corner: { y: 0, anchorY: 0.15 },
            level1_up_corner: { y: 0, anchorY: 0.45 }
        }
    },

    tiles_coloredseabottom_green: {
        height: 0,
        frames: {
            grow12_corner: { y: 0, anchorY: 0.1 }
        }
    },

    tiles_coloredseabottom_blue: {
        height: 0,
        frames: {
            grow12_corner: { y: 0, anchorY: 0.15 },
            level1_up_corner: { y: 0, anchorY: 0.45 }
        }
    },

    tiles_tomb_purple: {
        height: 0,
        frames: {
            grow12_corner: { y: 0, anchorY: 0.14 },
            level1_up_corner: { y: 0, anchorY: -0.15 },
            level1_down_corner: { y: 0, anchorY: 0.95 }
        }
    },

    tiles_tomb_yellow: {
        height: 0,
        frames: {
            grow12_corner: { y: 0, anchorY: 0.13 },
            level1_up_corner: { y: 0, anchorY: -0.15 },
            level1_down_corner: { y: 0, anchorY: 0.95 }
        }
    },

    tiles_tomb_green: {
        height: 0,
        frames: {
            grow12_corner: { y: 0, anchorY: 0.12 },
            level1_up_corner: { y: 0, anchorY: -0.15 },
            level1_down_corner: { y: 0, anchorY: 0.95 }
        }
    },

    tiles_map0_floor4: {
        height: 2,
        frames: {
            level1_down_corner: { anchorY: 0.395 },
            level1_up_corner: { anchorY: 1 }
        }
    },

    tiles_map0_floor5: {
        height: 2,
        frames: {
            level1_down_corner: { anchorY: 0.395 }
        }
    },

    tiles_map1_floor5: {
        height: 2,
        frames: {
            level1_down_corner: { anchorY: 0.395 },
            level1_up_corner: { anchorY: 1 }
        }
    }
};

(function () {
    var wallBorders = [
        "level1_down_both",
        "level1_down_left_only",
        "level1_down_right_only"
    ];

    var cornerBorder = [
        "level1_left_inner",
        "level1_left_inner_last",
        "level1_right_inner",
        "level1_right_inner_last",
        "level2_left_inner",
        "level2_left_inner_last",
        "level2_right_inner",
        "level2_right_inner_last"
    ];

    for (var name in Map2d.TERRAIN_CONFIG) {
        var conf = Map2d.TERRAIN_CONFIG[name];

        cornerBorder.forEach(function (border) {
            if (!conf.frames[border]) {
                conf.frames[border] = { anchorY: 0.5 };
            }
        });

        wallBorders.forEach(function (border) {
            if (!conf.frames[border]) {
                conf.frames[border] = { anchorY: 0 };
            }
        });

        if (!conf.frames.level1_left_corner) {
            conf.frames.level1_left_corner = { anchorX: 0, anchorY: 0.5 };
        }
        if (!conf.frames.level1_right_corner) {
            conf.frames.level1_right_corner = { anchorX: 1, anchorY: 0.5 };
        }
    }
}());
