/**
 * Created by mac on 12/23/20
 */

var ShineView = function (unitView) {
    var animation = new cleverapps.Spine(bundles.shine.jsons.shine_animation_json);
    animation.setAnimation(0, "animation", true);
    animation.setPositionRound(unitView.width / 2, unitView.height / 2);
    unitView.addChild(animation);
};
