/**
 * Created by andrey on 28.07.2023
 */

var Map2dGroundView = cc.Sprite.extend({
    reuse: function (frame) {
        this.setSpriteFrame(frame);

        this._super();
    }
});

Map2dGroundView.PoolLimit = 500;

Map2dGroundView.GetFromPool = function (frame) {
    return cc.pool.getFromPool(Map2dGroundView, frame) || new Map2dGroundView(frame);
};