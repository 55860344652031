/**
 * Created by andrey on 21.01.2021.
 */

var EditorLayoutControls = cleverapps.Layout.extend({
    ctor: function (callback) {
        var config = [
            { icon: bundles.admin.frames.left_double, delta: -2 },
            { icon: bundles.admin.frames.left, delta: -1 },
            { icon: bundles.admin.frames.right, delta: 1 },
            { icon: bundles.admin.frames.right_double, delta: 2 }
        ];

        var buttons = config.map(function (info) {
            return AdminDetailControls.prototype.createButton.call(this, {
                icon: info.icon,
                action: callback.bind(this, info.delta)
            });
        }, this);

        buttons.push(AdminDetailControls.prototype.createButton.call(this, {
            icon: bundles.admin.frames.enlarge_button,
            action: function () {
                var size = LevelManager.getInstance().level.content.map.length;
                var res = window.prompt("Specify map size;dx;dy", size + ";0;0");
                if (!res) {
                    return;
                }
                var parts = res.split(";");
                var newSize = parseInt(parts[0]);
                var dx = parts[1] && parseInt(parts[1]) || 0;
                var dy = parts[2] && parseInt(parts[2]) || 0;

                if (newSize < 10 || newSize > Map2d.MAXSIZE) {
                    cleverapps.notification.create("Size must be in 10..80 interval");
                    return;
                }

                if (Math.abs(dx) > newSize / 2 || Math.abs(dy) > newSize / 2) {
                    cleverapps.notification.create("dx or dy bigger then half of newSize");
                    return;
                }

                Editor.currentEditor.changeSize(newSize, dx, dy);

                cleverapps.refreshScene();
            }
        }));

        this._super(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.EditorLayoutControls.margin
        });

        this.setPositionRound(cleverapps.styles.EditorLayoutControls.position);
    }
});

cleverapps.styles.EditorLayoutControls = {
    button: {
        width: 60,
        height: 60
    },
    margin: 10,
    position: {
        x: { align: "right", dx: -350 },
        y: { align: "top", dy: -20 }
    }
};