/**
 * Created by Denis Kuzin on 15 february 2022
 */

var BaseClanWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.clan = options.clan;
        this.tabsInfo = this.createTabs();

        this._super({
            name: options.name,
            content: this.createContent(),
            title: options.title,
            closeButton: true,
            foreground: bundles.windows.frames.window_foreground_png
        });

        this.createBanner();
    },

    load: function (onSuccess, onFailure) {
        var noConnectionCallback = this.createListener(function () {
            this.deleteSelf();
            new NoConnectionWindow();
        }.bind(this));

        new ActionPlayer([
            function (f) {
                this._super(f, onFailure);
            }.bind(this),

            function (f) {
                if (this.updateClan) {
                    this.updateClan.load(f, noConnectionCallback);
                } else {
                    f();
                }
            }.bind(this),

            function (f) {
                if (this.updateTop) {
                    cleverapps.clans.updateTop(f, noConnectionCallback);
                } else {
                    f();
                }
            }.bind(this),

            function (f) {
                if (this.updateMessages) {
                    cleverapps.clanMessages.loadMessages(f);
                } else {
                    f();
                }
            }.bind(this)
        ]).play(onSuccess);
    },

    createTabs: function () {
        return {};
    },

    createContent: function () {
        var styles = cleverapps.styles.BaseClanWindow;

        var content = this.content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles.width, styles.height);

        var tabs = this.tabs = new Tabs(this.tabsInfo, styles.tabs);
        tabs.activateTab(0);
        tabs.setAnchorPoint(0.5, 0.5);
        tabs.setPositionRound(styles.tabs);
        content.addChild(tabs);

        return content;
    },

    createTab: function (Tab) {
        var styles = cleverapps.styles.BaseClanWindow;
        var options = {
            width: styles.width,
            height: styles.height
        };

        var content = new Tab(options);
        this.content.addChild(content);
        return content;
    },

    createBanner: function () {
        var styles = cleverapps.styles.BaseClanWindow.banner;

        var banner = new cc.Sprite(bundles.clan_banner.frames.banner);
        banner.setLocalZOrder(2);
        banner.setPositionRound(styles);
        this.window.addChild(banner);

        var emblem = this.bannerEmblem = new cc.Sprite(this.clan ? bundles.clan_banner.frames["emblem_" + this.clan.emblem] : bundles.clan_banner.frames.no_emblem);
        emblem.setPositionRound(styles.emblem);
        banner.addChild(emblem);

        if (this.clan && !this.updateClan) {
            var titleBg = new cc.Scale9Sprite(bundles.clan_banner.frames.title);
            titleBg.setAnchorPoint(0.5, 0.5);
            titleBg.setContentSize2(styles.title.width, titleBg.height);
            titleBg.setPositionRound(styles.title);
            banner.addChild(titleBg);

            var titleText = cleverapps.UI.generateOnlyText(this.clan.name, cleverapps.styles.FONTS.WINDOW_TITLE_TEXT);
            titleText.fitTo(styles.title.text.width, styles.title.text.height);
            titleText.setPositionRound(styles.title.text);
            titleBg.addChild(titleText);
        }
    },

    changeBanner: function (emblem) {
        this.bannerEmblem.setSpriteFrame(new cc.Sprite(bundles.clan_banner.frames["emblem_" + emblem]).getSpriteFrame());
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CLAN_LIGHT_TEXT: {
        size: 52,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    CLAN_SETTING_TEXT: {
        size: 52,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    CLAN_EDIT_TEXT: {
        size: 52,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    CLAN_CHANGE_EMBLEM_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    CLAN_PROPERTY_TEXT: {
        size: 52,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.BaseClanWindow = {
    width: 1280,
    height: 940,

    banner: {
        x: { align: "left", dx: -270 },
        y: { align: "top", dy: 16 },

        emblem: {
            x: { align: "center", dx: 10 },
            y: { align: "center", dy: 16 }
        },

        title: {
            x: { align: "center", dx: 5 },
            y: { align: "top", dy: 65 },
            width: 520,

            text: {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 16 },
                width: 350
            }
        }
    },

    tabs: {
        x: { align: "left", dx: -388 },
        y: { align: "bottom", dy: 0 },
        direction: cleverapps.UI.VERTICAL,
        height: 134,
        width: 320
    }
};
