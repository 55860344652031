/**
 * Created by r4zi4l on 17.06.2021
 */

var KrakenFeastMissionWindow = FeastMissionWindow.extend({
    onWindowLoaded: function (mission) {
        this._super({
            mission: mission,
            name: "KrakenFeastMissionWindow",
            controls: ["MenuBarGoldItem", "MenuBarLivesItem", "MenuBarCoinsItem"],
            showSound: bundles.monster.urls.tentacles_spawn_effect,
            styles: {
                decors: cleverapps.styles.KrakenFeastMissionWindow.decors
            },
            notCloseByTouchInShadow: true
        });
    },

    getPerson: function () {
        return cleverapps.styles.KrakenFeastMissionWindow.person;
    },

    getGuideOptions: function () {
        return {
            name: "KrakenFeastGuideWindow",
            bundle: bundles.krakenfeastguidewindow
        };
    },

    onShow: function () {
        this._super();
    }
});

cleverapps.styles.KrakenFeastMissionWindow = {
    person: {
        left: {
            role: "knight",
            emotion: "happy"
        },
        right: {
            role: "kraken",
            emotion: "idle"
        }
    }
};
