/**
 * Created by andrey on 20.05.2022.
 */

var BankView = cc.Node.extend({
    ctor: function (bank, unitView) {
        this._super();

        this.bank = bank;
        this.unitView = unitView;

        this.restoreState();
    },

    restoreState: function (animated) {
        if (this.bank.isAdsIconVisible()) {
            this.createAdsIcon(animated);
        } else {
            this.removeAdsIcon(animated);
        }

        if (this.bank.isTimerVisible()) {
            this.createTimer(animated);
        } else {
            this.removeTimer(animated);
        }
    },

    createAdsIcon: function (animated) {
        if (this.adsIcon) {
            return;
        }

        var styles = cleverapps.styles.BankView.ads;

        var adsIcon = this.adsIcon = new cleverapps.Spine(bundles.bank.jsons.bank_ads_icon);
        adsIcon.setAnimation(0, "idle", true);
        adsIcon.setPositionRound(styles);
        this.unitView.sprite.addChild(adsIcon);

        cleverapps.UI.onClick(adsIcon, this.unitView.handleClick.bind(this.unitView));

        if (animated) {
            adsIcon.setAnimationAndIdleAfter("animation", "idle");
            adsIcon.setScale(0);
            adsIcon.runAction(new cc.ScaleTo(0.3, 1).easing(cc.easeIn(2)));
        }
    },

    removeAdsIcon: function (animated) {
        if (!this.adsIcon) {
            return;
        }

        if (!animated) {
            this.adsIcon.removeFromParent();
            return;
        }

        this.adsIcon.runAction(new cc.Sequence(
            new cc.ScaleTo(0.1, 0.3).easing(cc.easeIn(2)),
            new cc.Hide()
        ));
        this.adsIcon = undefined;
    },

    createTimer: function (animated) {
        var timer = this.unitView.getAdditionalView("timer");
        if (timer) {
            return;
        }

        var styles = cleverapps.styles.BankView.timer;

        var countdown = new cleverapps.CountDownView(new cleverapps.CountDown(this.bank.getTimeLeft()), {
            font: cleverapps.styles.FONTS.COMPONENT_TIMER_TEXT,
            background: {
                frame: bundles.timer_merge.frames.timer_bg_png,
                width: styles.width,
                height: styles.height
            },
            icon: {
                frame: bundles.timer_merge.frames.timer_png,
                json: bundles.timer_merge.jsons.timer_icon_json,
                animation: "animation"
            }
        });
        countdown.setPositionRound(styles);
        this.unitView.createAdditionalView("timer", countdown);

        if (animated) {
            timer = this.unitView.getAdditionalView("timer");
            timer.animateAppear();
        }
    },

    removeTimer: function (animated) {
        this.unitView.removeAdditionalView("timer", !animated);
    }
});

cleverapps.styles.BankView = {
    ads: {
        x: { align: "center", dx: 37 },
        y: { align: "center", dy: -42 }
    },

    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -55 },
        width: 135,
        height: 29
    }
};
