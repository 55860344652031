/**
 * Created by denis on 26/12/19
 */

cleverapps.Flags = function () {
    cleverapps.EventEmitter.call(this);

    this.init();

    cleverapps.whenAllInitialized(function () {
        cleverapps.paymentsHistory.onAddPaymentListeners.flags = this.update.bind(this);
    }.bind(this));
};

cleverapps.Flags.prototype = Object.create(cleverapps.EventEmitter.prototype);
cleverapps.Flags.prototype.constructor = cleverapps.Flags;

cleverapps.Flags.prototype.init = function () {
    this.norest = connector.platform.oneOf(connector.PLIEGA, connector.PLAYABLE, connector.YOUTUBE, connector.GAMESNACK) || cleverapps.config.norest;
    this.noLogEvents = connector.platform.oneOf(connector.PLIEGA, connector.PLAYABLE);
    this.softCurrencyRealProduct = connector.platform.oneOf(connector.MOBAGE, connector.SP_MOBAGE);
    this.highMonetization = ["merge"].includes(cleverapps.config.type);
    this.lowMonetization = ["crocword", "scramble", "woodenblock"].includes(cleverapps.config.name);

    this.monetization = cleverapps.Flags.MONETIZATION_REGULAR;
    this._updateMonetization();

    var onUpdateSocial = function () {
        this.nologin = connector.social.oneOf(connector.NONE) || cleverapps.config.nologin;
        this.requestsEnabled = connector.social.getStatus() !== connector.STATUS_DISABLED && connector.social.isSupportsNativeRequest;
    }.bind(this);

    connector.social.on("pluginChanged", onUpdateSocial);
    onUpdateSocial();
};

cleverapps.Flags.prototype.toggle = function (flag) {
    this.set(flag, !this[flag]);
};

cleverapps.Flags.prototype.get = function (flag) {
    return this[flag];
};

cleverapps.Flags.prototype.set = function (flag, value) {
    if (this[flag] === value) {
        return;
    }

    this[flag] = value;

    this.trigger("change:" + flag, value);
};

cleverapps.Flags.prototype.reset = function () {
    this.init();
    this.update();
};

cleverapps.Flags.prototype._updateMonetization = function () {
    var monetization = cleverapps.Flags.MONETIZATION_REGULAR;

    if (connector.payments.getStatus() === connector.STATUS_DISABLED) {
        if (connector.ads.getStatus() === connector.STATUS_DISABLED) {
            monetization = cleverapps.Flags.MONETIZATION_DISABLED;
        } else {
            monetization = cleverapps.Flags.MONETIZATION_ADS;
        }
    }

    this.set("monetization", monetization);
};

cleverapps.Flags.prototype.update = function () {
    this._updateMonetization();

    var keepAnnoying = cleverapps.paymentsHistory.classify() === cleverapps.PaymentsHistory.BRACKET_NONE;

    if (!keepAnnoying) {
        this.requestsEnabled = false;
        levels.friendRequests.deleteFakeRequests();
    }

    this.norest = this.norest || cleverapps.user.cheater;

    AdsLimits.initialize();
};

cleverapps.Flags.MONETIZATION_DISABLED = "disabled";
cleverapps.Flags.MONETIZATION_ADS = "ads";
cleverapps.Flags.MONETIZATION_REGULAR = "regular";