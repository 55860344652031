/**
 * Created by slava on 30/8/17
 */

var FriendsCache = function (isInvitable) {
    this.data = {};
    this.lastUpdateTime = 0;
    this.isInvitable = isInvitable;

    this.load();
};

FriendsCache.prototype.getStoreKey = function () {
    if (this.isInvitable) {
        return DataLoader.TYPES.INVITABLE_FRIENDS;
    }
    return DataLoader.TYPES.FRIENDS;
};

FriendsCache.prototype.reset = function () {
    this.data = {};
    this.lastUpdateTime = 0;
    this.save();
};

FriendsCache.prototype.load = function () {
    var stored = cleverapps.dataLoader.load(this.getStoreKey());
    if (stored) {
        this.data = stored.data;
        this.lastUpdateTime = stored.when;
    }
};

FriendsCache.prototype.save = function () {
    var info = {
        data: this.data,
        when: this.lastUpdateTime
    };
    cleverapps.dataLoader.save(this.getStoreKey(), info);
};

FriendsCache.prototype.setData = function (friends) {
    // console.log("Loaded new friends list: ", friends);

    this.data = {};
    friends.forEach(function (friend) {
        this.data[friend.id] = {
            id: friend.id,
            name: friend.firstName || friend.name,
            avatar: friend.avatar
        };
    }, this);

    this.lastUpdateTime = Date.now();
    this.save();
};

FriendsCache.prototype.getById = function (id) {
    return this.data[id];
};

FriendsCache.prototype.listIds = function () {
    return Object.keys(this.data);
};

FriendsCache.prototype.listFriends = function () {
    return Object.values(this.data);
};

FriendsCache.prototype.inApp = function (id) {
    return this.data[id] !== undefined;
};

FriendsCache.prototype.needReload = function () {
    return this.listIds().length === 0 || this.lastUpdateTime + FriendsCache.RELOAD_INTERVAL < Date.now();
};

FriendsCache.prototype.reload = function (callback) {
    var onLoaded = function (code, friends) {
        if (code === cleverapps.CODE_SUCCEED) {
            this.setData(friends);
        }

        callback();
    }.bind(this);

    if (this.isInvitable) {
        connector.social.listInvitableFriends(onLoaded);
    } else {
        connector.social.listFriends(onLoaded);
    }
};

FriendsCache.loadFriends = function (callback) {
    var waitWindow;

    cleverapps.focusManager.display({
        stack: true,
        focus: "LoadFriends",
        actions: [
            function (f) {
                waitWindow = new WaitWindow();
                f();
            },

            function (f) {
                connector.social.askFriendsPermission(cleverapps.waitNoMore(cleverapps.parseInterval("10 seconds"), f));
            },

            function (f) {
                if (cleverapps.friends.needReload()) {
                    cleverapps.friends.reload(f);
                } else {
                    f();
                }
            },

            function (f) {
                if (cleverapps.invitalbleFriends.needReload()) {
                    cleverapps.invitalbleFriends.reload(f);
                } else {
                    f();
                }
            },

            function (f) {
                waitWindow.close();
                f();

                callback();
            }
        ]
    });
};

FriendsCache.RELOAD_INTERVAL = cleverapps.parseInterval("7 days");