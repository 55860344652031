/**
 * Created by r4zi4l on 09.02.2021
 */

var MergeAdviceOpenFogLogic = function (options) {
    MergeAdviceBaseLogic.call(this, options);
};

MergeAdviceOpenFogLogic.prototype = Object.create(MergeAdviceBaseLogic.prototype);
MergeAdviceOpenFogLogic.prototype.constructor = MergeAdviceOpenFogLogic;

MergeAdviceOpenFogLogic.prototype.findMove = function () {
    for (var i = 0; i < this.targets.length; ++i) {
        var fogBlock = Map2d.currentMap.fogs.blocks[this.targets[i]];

        if (fogBlock && fogBlock.head && fogBlock.state === FogBlock.CANOPEN) {
            return {
                cells: [cc.p(fogBlock.head.x, fogBlock.head.y)],
                fogView: ["openfog"]
            };
        }
    }
};
