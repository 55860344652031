/**
 * Created by razial on 05.07.2024.
 */

var Composite = function (unit) {
    UnitComponent.call(this, unit);

    var data = this.unit.getData();

    this.parts = data.composite;
};

Composite.prototype = Object.create(UnitComponent.prototype);
Composite.prototype.constructor = Composite;

Composite.prototype.getParts = function () {
    return this.parts;
};
