/**
 * Created by spepa on 20.12.2022
 */

var PromotionSaleLogic = function (offer) {
    this.offer = offer;

    this.onUpdateLots = function () {};
    this.onWindowShowUpFinished = function () {};
    this.onLotBought = {};
};

PromotionSaleLogic.prototype.getStage = function () {
    return this.offer.stage || 0;
};

PromotionSaleLogic.prototype.getLots = function () {
    return Offers[this.offer.type].reward || RewardsConfig.PromotionSale;
};

PromotionSaleLogic.prototype.isSoldLot = function (lotId) {
    return this.getStage() > lotId;
};

PromotionSaleLogic.prototype.isDisabled = function (lotId) {
    return this.isSoldLot(lotId) || (lotId > 0 && !this.isSoldLot(lotId - 1));
};

PromotionSaleLogic.prototype.isAllSold = function () {
    return this.getLots().every(function (lot) {
        return this.isSoldLot(lot.id);
    }.bind(this));
};

PromotionSaleLogic.prototype.getTimeLeft = function () {
    var conf = Offers[this.offer.type];
    if (conf.feature) {
        var event = cleverapps.eventManager.getFeatureEvent(conf.feature);
        return (event && event.getTimeLeft()) || 0;
    }
    return Math.max(0, (this.offer.started || 0) + cleverapps.parseInterval(conf.duration) - Date.now());
};

PromotionSaleLogic.prototype.isIconAvailable = function () {
    return true;
};

PromotionSaleLogic.prototype.hasIconAttention = function () {
    return false;
};

PromotionSaleLogic.prototype.checkIfComplete = function () {
    if (this.isAllSold()) {
        this.offer.complete();
    }
};

PromotionSaleLogic.prototype.getProduct = function (lot) {
    return Product.CreateByHardPrice(lot.hardPrice, {
        type: "promotion_sale",
        offer: this.offer.type,
        lot: lot.id
    });
};

PromotionSaleLogic.prototype.buyLot = function (lot) {
    if (!this.isSoldLot(lot.id)) {
        var product = this.getProduct(lot);
        product.buy(this.processLotBought.bind(this, lot.id), { noRewardWindow: true });
    }
};

PromotionSaleLogic.prototype.processLotBought = function (lotId, success) {
    if (!success) {
        return;
    }

    this.offer.incStage();
    if (this.onUpdateLots) {
        this.onUpdateLots();
    }
    this.checkIfComplete();

    if (this.onLotBought[lotId]) {
        this.onLotBought[lotId]();
    }
};

PromotionSaleLogic.RestoreExpeditionId = function (context) {
    return cleverapps.offerManager.getOfferExpedition(context.offer);
};

PromotionSaleLogic.RestoreActions = function (context, consume) {
    var offer = cleverapps.offerManager.findOffer({ type: context.offer });
    if (!offer || offer.logic.isSoldLot(context.lot)) {
        return;
    }

    return [
        function (f) {
            offer.logic.onWindowShowUpFinished = cleverapps.once(f);
            new PromotionSaleWindow(offer);
        },

        function (f) {
            consume();
            offer.logic.processLotBought(context.lot, true);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    ];
};

Product.RESTORE_LOGIC["promotion_sale"] = PromotionSaleLogic;