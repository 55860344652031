/**
 * Created by andrey on 03.01.2021.
 */

var Recipes = {};

Recipes.hero = [
    {
        code: "icecream",
        level: 1,
        time: "10 seconds",
        ingredients: {
            wheat: 2
        },
        prize: [
            { code: "wands", stage: 0 }
        ]
    },
    {
        code: "cookies",
        level: 4,
        time: "45 seconds",
        ingredients: {
            candies: 2
        },
        prize: [
            { code: "wands", stage: 0 },
            { code: "coins", stage: 0 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "gingerbread",
        level: 4,
        time: "90 seconds",
        ingredients: {
            candies: 2,
            wheat: 3
        },
        prize: [
            { code: "wands", stage: 0 },
            { code: "coins", stage: 0 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "croissant",
        level: 5,
        time: "15 minutes",
        ingredients: {
            candies: 3,
            wheat: 4
        },
        prize: [
            { code: "wands", stage: 0 },
            { code: "wands", stage: 0 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "caramelpie",
        level: 6,
        time: "5 minutes",
        ingredients: {
            candies: 2,
            wheat: 2
        },
        prize: [
            { code: "wands", stage: 0 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "bakedapple",
        level: 7,
        time: "5 minutes",
        ingredients: {
            apple: 4
        },
        prize: [
            { code: "wands", stage: 0 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "applejam",
        level: 7,
        time: "15 minutes",
        ingredients: {
            apple: 5,
            wheat: 2
        },
        prize: [
            { code: "wands", stage: 1 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "caramelapple",
        level: 8,
        time: "10 minutes",
        ingredients: {
            apple: 4,
            wheat: 2
        },
        prize: [
            { code: "wands", stage: 0 },
            { code: "wands", stage: 0 },
            { code: "coins", stage: 0 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "applepie",
        level: 8,
        time: "25 minutes",
        ingredients: {
            candies: 6,
            apple: 2,
            wheat: 2
        },
        prize: [
            { code: "wands", stage: 0 },
            { code: "wands", stage: 1 },
            { code: "coins", stage: 1 }
        ]
    },
    {
        code: "strawberryjuice",
        level: 9,
        time: "5 minutes",
        ingredients: {
            strawberry: 4
        },
        prize: [
            { code: "wands", stage: 0 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "strawberrycake",
        level: 9,
        time: "30 minutes",
        ingredients: {
            candies: 4,
            wheat: 3,
            strawberry: 5
        },
        prize: [
            { code: "wands", stage: 1 },
            { code: "wands", stage: 1 },
            { code: "coins", stage: 0 },
            { code: "coins", stage: 1 }
        ]
    },
    {
        code: "salad",
        level: 10,
        time: "18 minutes",
        ingredients: {
            candies: 2,
            strawberry: 6
        },
        prize: [
            { code: "wands", stage: 1 },
            { code: "coins", stage: 0 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "bubblegum",
        level: 11,
        time: "20 minutes",
        ingredients: {
            wheat: 6,
            apple: 3
        },
        prize: [
            { code: "wands", stage: 1 },
            { code: "coins", stage: 1 }
        ]
    },
    {
        code: "granola",
        level: 12,
        time: "20 minutes",
        ingredients: {
            candies: 4,
            apple: 5
        },
        prize: [
            { code: "wands", stage: 1 },
            { code: "coins", stage: 1 }
        ]
    },
    {
        code: "strawberrypie",
        level: 12,
        time: "15 minutes",
        ingredients: {
            strawberry: 4,
            candies: 3
        },
        prize: [
            { code: "wands", stage: 1 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "mushroomcnacks",
        level: 13,
        time: "5 minutes",
        ingredients: {
            mushrooms: 2,
            wheat: 3
        },
        prize: [
            { code: "wands", stage: 0 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "mintcookies",
        level: 13,
        time: "18 minutes",
        ingredients: {
            mushrooms: 2,
            candies: 2,
            apple: 3
        },
        prize: [
            { code: "wands", stage: 1 },
            { code: "coins", stage: 0 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "stage18",
        level: 14,
        time: "30 minutes",
        ingredients: {
            wheat: 4,
            apple: 3,
            strawberry: 2
        },
        prize: [
            { code: "wands", stage: 0 },
            { code: "wands", stage: 1 },
            { code: "coins", stage: 1 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "stage19",
        level: 15,
        time: "15 minutes",
        ingredients: {
            mushrooms: 2,
            strawberry: 2,
            apple: 2
        },
        prize: [
            { code: "wands", stage: 1 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "stage20",
        level: 16,
        time: "20 minutes",
        ingredients: {
            mushrooms: 2,
            candies: 3,
            corn: 4
        },
        prize: [
            { code: "wands", stage: 0 },
            { code: "wands", stage: 0 },
            { code: "coins", stage: 0 },
            { code: "coins", stage: 0 }
        ]
    },
    {
        code: "kingfood",
        customerCode: "king",
        level: 4,
        time: "0 seconds",
        ingredients: {
            kingfood: 1
        },
        prize: [
            { code: "energy", stage: 2 }
        ]
    },
    {
        code: "dwarffood",
        customerCode: "dwarf",
        level: 4,
        time: "0 seconds",
        ingredients: {
            dwarffood: 1
        },
        prize: [
            { code: "energy", stage: 2 }
        ]
    }
];

var RecipesIndex = {};

(function () {
    for (var type in Recipes) {
        RecipesIndex[type] = {};
        Recipes[type].forEach(function (recipe, index) {
            RecipesIndex[type][recipe.code] = index;

            recipe.prize.sort(function (a, b) {
                var isA = a.code === "wands", isB = b.code === "wands";
                return isB - isA || b.stage - a.stage;
            });
        });
    }
}());

if (typeof cc === "undefined") {
    module.exports = {
        Recipes: Recipes,
        RecipesIndex: RecipesIndex
    };
}