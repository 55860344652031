/**
 * Created by Aleksandr on 16.06.2023
 */

var MSStartSender = function () {};

// TODO: how many PWA players/payers are there?
MSStartSender.prototype.sendPush = function (info, timeout) {
    if (!connector.platform.isConnected()) {
        return;
    }

    if (timeout > cleverapps.parseInterval("7 days")) {
        timeout = cleverapps.parseInterval("7 days");
    }

    var data = cleverapps.LocalPushes.GetPushData(info);

    // TODO: validate length 60 & 200 chars https://assets.msn.com/staticsb/statics/latest/msstart-games-sdk/documentation/docs/notification-apis/index.html
    var title = data.title;
    var message = data.text;
    var id = this.getId(info);

    // TODO: add image
    var image = undefined;
    // TODO: parse payload https://assets.msn.com/staticsb/statics/latest/msstart-games-sdk/documentation/docs/game-url/index.html
    var payload = JSON.stringify({ push_code: info.id });
    // TODO: test callToAction, screenshots with/without it
    var callToAction = undefined;

    if (!message) {
        cleverapps.throwAsync("empty msstart push - " + info.code);
        return;
    }

    $msstart.scheduleNotificationAsync({
        title: title,
        description: message,
        type: id,
        image: image,
        payload: payload,
        minDelayInSeconds: Math.round(timeout / 1000),
        callToAction: callToAction
    }).then(function (response) {
        console.log("MSStartSender - " + response);
    }).catch(function (error) {
        // TODO: add error handling https://assets.msn.com/staticsb/statics/latest/msstart-games-sdk/documentation/docs/notification-apis/index.html
        console.log("MSStartSender error - " + JSON.stringify(error));
    });

    return true;
};

MSStartSender.prototype.cancelPush = function () {};

MSStartSender.prototype.getId = function (info) {
    return info.id % 16;
};

MSStartSender.isAppropriate = function () {
    return connector.platform.oneOf(connector.MSSTART);
};