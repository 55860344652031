/**
 * Created by spepa on 18.07.2024
 */

var LazyAssets = function () {
    cleverapps.EventEmitter.call(this);

    this.kept = {};
    this.preferred = {};
};

LazyAssets.prototype.unload = function () {
    Object.values(this.kept).forEach(function (virtualResource) {
        this.deleteResource(virtualResource);
    }.bind(this));

    this.kept = {};
    this.preferred = {};
};

LazyAssets.prototype.keepResource = function (virtualResource) {
    if (!this.kept[virtualResource]) {
        this.kept[virtualResource] = virtualResource;
        this.loadResource(virtualResource);
    }
};

LazyAssets.prototype.loadResource = function (virtualResource, callback) {
    cleverapps.bundleLoader.loadBundle(virtualResource.getBundleName(), {
        onSuccess: callback
    });
};

LazyAssets.prototype.deleteResource = function (virtualResource) {
    cleverapps.bundleLoader.deleteBundle(virtualResource.getBundleName());
};

LazyAssets.prototype.isLoaded = function (virtualResource) {
    return cleverapps.bundleLoader.isLoaded(virtualResource.getBundleName());
};

LazyAssets.prototype.setPreferredBundles = function (names) {
    cleverapps.toArray(names).forEach(function (bundleName) {
        if (bundles[bundleName]) {
            this.preferred[bundleName] = true;
        }
    }.bind(this));
};

LazyAssets.prototype.isPreferredBundle = function (bundleName) {
    return Boolean(this.preferred[bundleName]);
};