/**
 * Created by Aleksandr on 02.08.2024
 */

var CompleteLocationComponent = function (unit, options, completeLocationId) {
    UnitComponent.call(this, unit);

    if (cleverapps.meta.selectedLocationId === completeLocationId) {
        cleverapps.meta.getMainObject().complete();
    }
};

CompleteLocationComponent.prototype = Object.create(UnitComponent.prototype);
CompleteLocationComponent.prototype.constructor = CompleteLocationComponent;
