/**
 * Created by andrey on 10.02.2021.
 */

var Quests = function (options) {
    cleverapps.EventEmitter.call(this);

    this.quests = [];
    this.lastFinished = 0;

    this.slot = Game.currentGame.slot;
    this.pageId = cleverapps.travelBook.getCurrentPage().id;
    this.config = QuestsConfig[this.pageId];

    if (!this.config) {
        Object.assign(this, Quests.FakeQuests);
        return;
    }

    Quests.InitConfig(Game.currentGame.level);

    cleverapps.eventBus.on("unitAvailable", this.unitAvailable.bind(this), this);
    cleverapps.eventBus.on("mapEvent", this.onMapEvent.bind(this), this);

    var isNewGame = options && options.isNewGame;
    if (!isNewGame) {
        this.load();
    }
};

Quests.prototype = Object.create(cleverapps.EventEmitter.prototype);
Quests.prototype.constructor = Quests;

Quests.prototype.migrateLinear = function (stored) {
    var quests = (stored && stored.quests || []);
    quests.forEach(function (quest) {
        if (quest.id === "woodsource_1") {
            quest.id = quest.configIndex === 1 ? "woodsource_1_1" : "woodsource_1_0";
        } else if (quest.id === "wood_9" && quest.configIndex === 1) {
            quest.id = "wheat_4";
        }
    });

    var added = {};
    stored.quests = quests.filter(function (quest) {
        if (added[quest.id]) {
            return false;
        }

        var config = this.config[quest.id];
        if (config && config.dynamic) {
            if (config.dynamic.filter && !config.dynamic.filter()) {
                return false;
            }

            if (config.dynamic.getUnit && quest.unit === undefined) {
                return false;
            }
        }

        added[quest.id] = true;
        return true;
    }, this);
};

Quests.prototype.load = function () {
    if (cleverapps.gameModes.skipQuests) {
        return;
    }

    var stored = cleverapps.dataLoader.load(DataLoader.TYPES.QUESTS + this.slot) || {};

    var needSave = false;
    if (!stored && Game.currentGame.isMainGame()) {
        stored = needSave = cleverapps.GameSaver.loadProperty(Meta.SLOT_MAIN, "quests");
    }

    stored = stored || {};

    this.migrateLinear(stored);

    this.quests = (stored && stored.quests || []).filter(function (data) {
        return this.config[data.id];
    }, this).map(function (data) {
        var quest = new Quest(data);
        quest.replayTutorial();
        return quest;
    });

    var storedLocal = cleverapps.dataLoader.load(DataLoader.TYPES.QUESTS_LOCAL + this.slot);

    var selected = stored.selected || storedLocal && storedLocal.selected;
    if (selected === undefined || selected < 0 || selected >= this.quests.length) {
        selected = this.quests.length - 1;
    }

    if (this.quests[selected]) {
        this.select(this.quests[selected], selected === stored.selected);
    }

    if (needSave) {
        this.save();
    }
};

Quests.prototype.finishHeroChestQuests = function () {
    this.quests.forEach(function (quest) {
        if (quest.id === "r_open_herochest" && quest.progress > 0 && !cleverapps.unitsLibrary.getCurrentHero()) {
            quest.progress = quest.goal;
        }
    }, this);
};

Quests.prototype.finishQuestsByUnit = function (unit, type) {
    var isLast = unit.isLast();
    this.quests.forEach(function (quest) {
        if (quest.unit && quest.unit.code === unit.code && (isLast || quest.unit.stage === unit.stage) && quest.type === type) {
            quest.progress = quest.goal;
        }
    });
};

Quests.prototype.chooseSelected = function (preffered) {
    if (this.selected === undefined) {
        this.selected = -1;
    }

    if (this.quests.length > 0) {
        if (this.quests.indexOf(preffered) !== -1) {
            this.select(preffered);
        } else {
            for (var i = this.quests.length - 1; i >= 0; i--) {
                if (!this.quests[i].isFresh()) {
                    this.select(this.quests[i]);
                    break;
                }
            }
        }
    }

    this.saveLocal();
};

Quests.prototype.getInfo = function () {
    var quests = this.quests.map(function (quest) {
        return quest.save();
    });

    return {
        quests: quests
    };
};

Quests.prototype.save = function () {
    this.saveLocal();
    cleverapps.dataLoader.save(DataLoader.TYPES.QUESTS + this.slot, this.getInfo());

    cleverapps.synchronizer.addUpdateTask("quests" + this.slot);
};

Quests.prototype.saveLocal = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.QUESTS_LOCAL + this.slot, {
        selected: this.selected
    });
};

Quests.prototype.getSelectedQuest = function () {
    return this.quests[this.selected];
};

Quests.prototype.select = function (quest) {
    var index = this.quests.indexOf(quest);

    if (index === -1 || index === this.selected) {
        return;
    }

    var oldQuest = this.selected !== undefined && this.quests[this.selected];
    oldQuest && oldQuest.unselect();

    this.selected = index;
    quest.select();

    this.trigger("select", this.selected);
    this.saveLocal();
};

Quests.prototype.isRunning = function (id) {
    return this.quests.some(function (quest) {
        return id === quest.id;
    });
};

Quests.prototype.isEmpty = function () {
    return this.quests.length === 0;
};

Quests.prototype.count = function () {
    return this.quests.length;
};

Quests.prototype.create = function (trigger) {
    if (cleverapps.gameModes.skipQuests) {
        return;
    }

    var configs = Object.values(this.config).filter(function (config) {
        if (!config.trigger || this.isRunning(config.id)) {
            return false;
        }

        if (config.dynamic && config.dynamic.filter && !config.dynamic.filter({ create: true })) {
            return false;
        }

        if (config.dynamic && config.dynamic.getGoal && !config.dynamic.getGoal()) {
            return false;
        }

        if (config.trigger.unit) {
            return Unit.Equals(trigger.unit, config.trigger.unit);
        }

        if (config.trigger.fog) {
            return config.trigger.fog === trigger.fog;
        }

        if (config.trigger.quest) {
            return config.trigger.quest === trigger.quest;
        }

        return false;
    }.bind(this));

    this.startQuests(configs);
};

Quests.prototype.startQuests = function (configs) {
    if (configs.length > 0) {
        configs.forEach(function (config) {
            var quest = new Quest(config.quest, true);
            quest.setFresh(true);
            this.quests.push(quest);
        }.bind(this));

        this.save();
        Game.currentGame.counter.trigger(100);
    }
};

Quests.prototype.unitAvailable = function (unit) {
    this.onMapEvent(Map2d.OPEN_UNIT, { unit: unit });
    this.create({ unit: unit });
};

Quests.prototype.applyDynamicFilter = function () {
    var toRemove = [];
    this.quests.forEach(function (quest, index) {
        var config = this.config[quest.id];
        if (config && config.dynamic && config.dynamic.filter && !config.dynamic.filter()) {
            toRemove.push(index);
        }
    }, this);

    if (toRemove.length > 0) {
        for (var i = toRemove.length - 1; i >= 0; i--) {
            this.quests.splice(toRemove[i], 1);
        }
        this.chooseSelected();
        this.save();
    }
};

Quests.prototype.finish = function () {
    if (cleverapps.focusManager.isFocused()) {
        return;
    }

    for (var index = 0; index < this.quests.length; index++) {
        var quest = this.quests[index];

        if (quest.dynamicFinished()) {
            quest.progress = quest.goal;
        }

        if (quest.isFinished()) {
            break;
        }
    }

    if (index === this.quests.length) {
        return;
    }

    var selected = this.getSelectedQuest();

    var callback = function () {
        var quest = this.quests[index];
        this.quests.splice(index, 1);
        this.chooseSelected(selected);
        this.save();

        this.create({ quest: quest.id });
    }.bind(this);

    cleverapps.eventBus.trigger("taskEvent", DailyTasks.FINISH_QUEST);

    if (!quest.config.prize) {
        cleverapps.throwAsync("FinishQuest " + quest.id + " empty prize");
    }

    this.lastFinished = Date.now();
    cleverapps.focusManager.display({
        focus: "FinishQuest",
        actions: [

            function (f) {
                var questsGroupIcon = cleverapps.sideBar.findIconByClassName(QuestsGroupIcon);
                var finished = questsGroupIcon.tryFinishQuest(quest, function () {
                    callback();
                    f();
                });

                if (!finished) {
                    var rewardsList = new RewardsList(quest.config.prize || {}, {
                        toMainWorld: quest.config.toMainWorld,
                        event: cleverapps.EVENTS.EARN.OTHER
                    });
                    rewardsList.receiveRewards();
                    rewardsList.updateViewAmount();
                }
            },

            function (f) {
                Game.currentGame.counter.setTimeout(f, 300);
            },

            function (f) {
                quest.showTutorial(f, true);
            }
        ]
    });
};

Quests.prototype.getFreshQuestsBatch = function () {
    var quests = this.quests.filter(function (quest) {
        return quest.isFresh();
    });

    var storyQuests = quests.filter(function (quest) {
        return quest.getTutorial() || quest.getDialogue();
    });

    quests = cleverapps.substract(quests, storyQuests, Quest.GetId);

    quests = storyQuests.slice(0, 1).concat(quests);

    return quests.slice(0, 2);
};

Quests.prototype.processFresh = function () {
    if (cleverapps.focusManager.isFocused() || cleverapps.gameModes.skipQuests) {
        return;
    }

    var quests = this.getFreshQuestsBatch();
    var quest = quests[0];

    if (quests.length === 0) {
        return;
    }

    var units = [];

    if (quest.triggerUnit) {
        var center = Map2d.currentMap.getScreenCenterCell();
        units = Map2d.currentMap.listAvailableUnits(quest.triggerUnit).filter(function (unit) {
            return unit.isBuilt();
        }).sort(function (a, b) {
            return cc.getDistanceChebyshev(a, center) - cc.getDistanceChebyshev(b, center);
        }).slice(0, 1);
    }

    if (quest.dialogueTargets) {
        units = units.concat(Map2d.currentMap.listAvailableUnits(quest.dialogueTargets));
    }

    var controls = quest.config.controls || ["MenuBarGameLevelItem", "MenuBarCoinsItem"];

    cleverapps.focusManager.display({
        focus: "StartQuest",
        control: controls,
        actions: [
            function (f) {
                if (quest.config.silent) {
                    var questsGroupIcon = cleverapps.sideBar.findIconByClassName(QuestsGroupIcon);
                    questsGroupIcon.onAddIconViewListener(new QuestIcon(quest, { images: QuestIcon.Images.questsgroup }));
                    f();
                    return;
                }

                Game.currentGame.unitStories.showUnitStory(f, quest.getDialogue(), {
                    unit: units
                });
            },

            function (f) {
                cleverapps.focusManager.hideControlsWhileFocused(controls);
                quests.forEach(function (quest) {
                    quest.setFresh(false);
                });
                this.select(quests[quests.length - 1]);
                this.save();

                if (quest.config.silent) {
                    f();
                    return;
                }

                new StartQuestWindow(quests);

                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this),

            function (f) {
                quest.showTutorial(f);
            }
        ]
    });
};

Quests.prototype.onMapEvent = function (type, options) {
    this.quests.forEach(function (quest) {
        if (quest.isApplicable(type, options)) {
            quest.addProgress(options && options.amount || 1);
        }
    }, this);

    this.finishHeroChestQuests();

    if (type === Map2d.OPENFOG) {
        this.create(options);
    }
};

Quests.prototype.isRepeatedQuestsAvailable = function () {
    return !cleverapps.travelBook.isExpedition() && cleverapps.gameLevel.getLevel() >= Quests.REPEATED_AVAILABLE.level
        && this.lastFinished + Quests.START_REPEATED_TIMEOUT < Date.now();
};

Quests.prototype.runRepeatedQuests = function () {
    if (!this.isRepeatedQuestsAvailable() || this.quests.length >= 5) {
        return;
    }

    var activeIds = this.quests.map(Quest.GetId);
    var activeRepeatedIds = cleverapps.intersect(Object.keys(RepeatedQuests), activeIds);

    var toStart = Quests.CONCURRENT_REPEATED_QUESTS - activeRepeatedIds.length;
    if (toStart <= 0) {
        return;
    }

    cleverapps.Random.seed(Date.now());
    var candidates = [];
    for (var key in RepeatedQuests) {
        if (RepeatedQuests[key].dynamic && RepeatedQuests[key].dynamic.filter) {
            if (RepeatedQuests[key].dynamic.filter()) {
                candidates.push(key);
            }
            continue;
        }

        candidates.push(key);
    }
    candidates = cleverapps.Random.shuffle(candidates);
    candidates = cleverapps.substract(candidates, activeRepeatedIds);

    var generated = [];
    for (var i = 0; i < candidates.length && generated.length < toStart; i++) {
        var source = RepeatedQuests[candidates[i]];
        var config = this.generateConfig(source);
        if (config) {
            generated.push(config);
        }
    }

    if (generated.length > 0) {
        this.startQuests(generated);
    }
};

Quests.prototype.generateConfig = function (source) {
    var config = Object.assign({}, source);
    config.quest = cleverapps.clone(config.quest, true);

    if (Array.isArray(config.quest.goal)) {
        config.quest.goal = cleverapps.Random.random(config.quest.goal[0], config.quest.goal[1]);
    }

    if (config.dynamic && config.dynamic.getUnit) {
        config.quest.unit = config.dynamic.getUnit();
        if (!config.quest.unit) {
            return;
        }
    }

    return config;
};

Quests.prototype.destructor = function () {
    runCleaners(this);
};

CustomSyncers.registerBySlots("quests", function (slot) {
    return cleverapps.dataLoader.load(DataLoader.TYPES.QUESTS + slot);
}, function (slot, serverData) {
    cleverapps.dataLoader.save(DataLoader.TYPES.QUESTS + slot, serverData);
});

Quests.reset = function () {
    Meta.SLOTS.forEach(function (slot) {
        cleverapps.dataLoader.save(DataLoader.TYPES.QUESTS + slot, {});
        cleverapps.synchronizer.addUpdateTask("quests" + slot);
    });
};

Quests.FakeQuests = {
    isRepeatedQuestsAvailable: function () {},
    finishHeroChestQuests: function () {},
    finishQuestsByCode: function () {},
    finish: function () {},
    processFresh: function () {},
    destructor: function () {},
    load: function () {},
    startQuests: function () {},
    create: function () {},
    chooseSelected: function () {},
    save: function () {},
    saveLocal: function () {}
};

Quests.CONCURRENT_REPEATED_QUESTS = 2;
Quests.REPEATED_AVAILABLE = { episode: 0, level: 5 };
Quests.START_REPEATED_TIMEOUT = cleverapps.parseInterval("1 minute");
