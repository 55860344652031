/**
 * Created by andrey on 05.02.2021.
 */

var RegionsUnits = function () {
    cleverapps.EventEmitter.call(this);

    var fogs = Object.keys(Map2d.currentMap.fogs.config);
    this.fogs = fogs.map(function (code) {
        return new RegionUnit({
            code: code,
            type: RegionUnit.TYPE_FOG
        });
    });

    var codes = cleverapps.substract(Object.keys(Map2d.currentMap.regions), fogs).sort();
    codes.push(RegionUnit.CODE_CREATE);
    this.units = codes.map(function (code) {
        return new RegionUnit({
            code: code,
            type: RegionUnit.TYPE_REGION
        });
    }, this);
};

RegionsUnits.prototype = Object.create(cleverapps.EventEmitter.prototype);
RegionsUnits.prototype.constructor = RegionsUnits;

RegionsUnits.prototype.getUnits = function (options) {
    switch (options && options.type) {
        case RegionUnit.TYPE_REGION:
            return this.units;
        default:
            return this.fogs;
    }
};

RegionsUnits.prototype.getUnitIcons = function (options) {
    return this.getUnits(options).map(function (unit) {
        return new RegionUnitIcon(unit);
    });
};

RegionsUnits.prototype.validateCode = function (code) {
    if (/[^a-z0-9_-]/.test(code)) {
        return "Invalid region code: " + code + ". The region code must match the regex [a-z0-9_-]";
    }

    if (Map2d.currentMap.regions[code]) {
        return "Region code " + code + " already exists";
    }

    if (!code) {
        return "Error: empty code";
    }
};

RegionsUnits.prototype.createNewRegion = function () {
    var code = window.prompt("New region code");
    var error = this.validateCode(code);

    if (error) {
        cleverapps.notification.create(error);
        return;
    }

    var unit = new RegionUnit({
        code: code,
        type: RegionUnit.TYPE_REGION
    });
    this.units.push(unit);

    this.sort();

    this.trigger("refresh"); 

    Editor.currentEditor.map.fogs.createRegion(code);
    Editor.currentEditor.select(unit);
};

RegionsUnits.prototype.renameRegion = function (unit) {
    var oldCode = unit.code;
    var code = window.prompt(unit.code + " - new region code?");
    if (unit.code === code) {
        return;
    }

    var error = this.validateCode(code);

    if (error) {
        cleverapps.notification.create(error);
        return;
    }

    Editor.currentEditor.map.fogs.renameRegion(oldCode, code);

    unit.code = code;
    this.sort();

    this.trigger("refresh");
    Editor.currentEditor.select(unit);
};

RegionsUnits.prototype.deleteRegion = function (unit) {
    if (!confirm("Delete region with code \"" + unit.code + "'?")) {
        return;
    }

    var index = this.units.indexOf(unit);
    if (index === -1) {
        cleverapps.notification.create("Cant find unit with code " + unit.code);
        return;
    }

    Editor.currentEditor.map.fogs.deleteRegion(unit.code);

    unit.unselect();
    this.units.splice(index, 1);
    this.trigger("refresh");
};

RegionsUnits.prototype.clearRegion = function (unit) {
    if (!confirm("Clear region with code \"" + unit.code + "'?")) {
        return;
    }

    Editor.currentEditor.map.fogs.clearRegion(unit);
};

RegionsUnits.prototype.sort = function () {
    this.units.sort(function (a, b) {
        return a.code.localeCompare(b.code);
    });
};
