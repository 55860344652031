/**
 * Created by slava on 4/14/17.
 */

var CustomSyncers = {
    extractors: {
        users: function () {
            return cleverapps.user.getInfo();
        },

        tournament: function () {
            if (!Game.currentGame || !Game.currentGame.competition || !Game.currentGame.competition.options || Game.currentGame.competition.options.type !== "tournament") {
                return;
            }
            var results = Game.currentGame.competition.results.filter(function (result) {
                return !result.player;
            });

            return {
                level: Game.currentGame.competition.options.level.getHumanReadableNumber(),
                results: results
            };
        },

        userdelete: function () {
            return cleverapps.userDelete.getInfo();
        },

        bonuses: function () {
            if (!cleverapps.miniGame) {
                return;
            }
            return {
                event: cleverapps.miniGame.when
            };
        },

        settings: function () {
            return cleverapps.settings.getSaveInfo();
        },

        serverflags: function () {
            return cleverapps.serverFlags.getInfo();
        },

        tempgoods: function () {
            if (!cleverapps.unlimitedLives) {
                return;
            }
            return cleverapps.unlimitedLives.getInfo();
        },

        boosters: function () {
            if (!cleverapps.boosters) {
                return;
            }
            return cleverapps.boosters.data;
        },

        starchests: function () {
            if (!cleverapps.starChest) {
                return;
            }
            return {
                stars: cleverapps.starChest.stars
            };
        },

        dailytasks: function () {
            if (!cleverapps.dailyTasks) {
                return;
            }
            return cleverapps.dailyTasks.getInfo();
        },

        missions: function () {
            return cleverapps.missionManager.getInfo();
        },

        offers: function () {
            return cleverapps.offerManager.getInfo();
        },

        heroes: function () {
            if (typeof match3 === "undefined" || !match3 || !match3.heroes) {
                return;
            }
            return match3.heroes.getInfo();
        },

        subscription: function () {
            return cleverapps.subscription.getInfo(true);
        },

        forces: function () {
            return cleverapps.forces.getInfo();
        },

        metha: function () {
            if (cleverapps.meta.getInfo) {
                return cleverapps.meta.getInfo();
            } if (cleverapps.meta.getMainObject().getInfo) {
                return cleverapps.meta.getMainObject().getInfo();
            }
        },

        cookiejar: function () {
            if (!levels || !levels.cookieJar) {
                return;
            }
            return levels.cookieJar.getCounterValue();
        },

        competitionstatistics: function () {
            return cleverapps.competitionStatistics.getInfo();
        },

        growthfund: function () {
            if (!cleverapps.growthFund) {
                return;
            }
            return cleverapps.growthFund.getInfo();
        },

        unitsshop: function () {
            if (!cleverapps.unitsShop) {
                return;
            }
            return cleverapps.unitsShop.getInfo();
        },

        paymentshistory: function () {
            return cleverapps.paymentsHistory.getInfo();
        },

        army: function () {
            if (!cleverapps.army) {
                return;
            }

            return cleverapps.army.getInfo();
        },

        armylibrary: function () {
            if (!cleverapps.armyLibrary) {
                return;
            }

            return cleverapps.armyLibrary.getInfo();
        },

        achievements: function () {
            if (!cleverapps.achievements) {
                return;
            }
            return cleverapps.achievements.getInfo();
        },

        noAds: function () {
            return cleverapps.noAds.getState();
        },

        stickersbook: function () {
            if (!cleverapps.stickersBook) {
                return;
            }
            return cleverapps.stickersBook.getInfo();
        }
    },

    importerData: {
        users: function (serverData) {
            cleverapps.user.updateInfo(serverData);

            if (cleverapps.hose) {
                cleverapps.hose.reset();
            }
            if (cleverapps.playButton) {
                cleverapps.playButton.update();
            }
        },

        tournament: function (serverData) {
            if (serverData && serverData.level && serverData.results) {
                cleverapps.dataLoader.save(DataLoader.TYPES.COMPETITION + "_tournament", serverData);
            }
        },

        userdelete: function (serverData) {
            cleverapps.userDelete.updateInfo(serverData);
        },

        bonuses: function (serverData) {
            if (!cleverapps.miniGame) {
                return;
            }

            var bonus = cleverapps.miniGame;

            if (serverData.event < bonus.when || serverData.event > (Date.now() + bonus.interval)) {
                return;
            }

            bonus.locked = true;
            bonus.setWhenUnlock(serverData.event);
            bonus.save(false);
            bonus.changeLockedListener();
        },

        settings: function () {
        },

        boosters: function (serverData) {
            if (!cleverapps.boosters) {
                return;
            }

            cleverapps.boosters.data = serverData;
            cleverapps.boosters.save(false);

            cleverapps.boosters.addInitAmount();
        },

        serverflags: function (serverData) {
            cleverapps.serverFlags.updateInfo(serverData, true);
        },

        tempgoods: function (serverData) {
            if (!cleverapps.unlimitedLives) {
                return;
            }

            cleverapps.unlimitedLives.updateInfo(serverData, true);
        },

        missions: function (serverData) {
            cleverapps.missionManager.updateInfo(serverData, true);
            cleverapps.travelBook.updatePages();
        },

        offers: function (serverData) {
            cleverapps.offerManager.updateInfo(serverData, true);
        },

        starchests: function (serverData) {
            if (!cleverapps.starChest) {
                return;
            }

            cleverapps.starChest.updateInfo(serverData, true);
        },

        dailytasks: function (serverData) {
            if (!cleverapps.dailyTasks) {
                return;
            }

            cleverapps.dailyTasks.load(serverData);
            cleverapps.dailyTasks.save(false);
            cleverapps.dailyTasks.update();
        },

        heroes: function (serverData) {
            if (typeof match3 === "undefined" || !match3 || !match3.heroes) {
                return;
            }

            match3.heroes.updateInfo(serverData, true);
        },

        subscription: function (serverData) {
            cleverapps.subscription.updateInfo(serverData, true);
        },

        forces: function (serverData) {
            cleverapps.forces.updateInfo(serverData, true);
        },

        metha: function (serverData) {
            if (cleverapps.meta.updateInfo) {
                cleverapps.meta.updateInfo(serverData);
            } else if (cleverapps.meta.getMainObject().updateInfo) {
                cleverapps.meta.getMainObject().updateInfo(serverData);
            }
        },

        cookiejar: function (serverData) {
            var countOfCookies = serverData && serverData.counterValue !== undefined ? serverData.counterValue : serverData || 0;
            cleverapps.dataLoader.save(DataLoader.TYPES.COOKIE_JAR, countOfCookies);

            if (levels && levels.cookieJar) {
                levels.cookieJar.load();
            }
        },

        competitionstatistics: function (serverData) {
            cleverapps.competitionStatistics.updateInfo(serverData, true);
        },

        growthfund: function (serverData) {
            if (!cleverapps.growthFund) {
                return;
            }

            cleverapps.growthFund.updateInfo(serverData);
        },

        unitsshop: function (serverData) {
            cleverapps.unitsShop && cleverapps.unitsShop.updateInfo(serverData);
        },

        paymentshistory: function (serverData) {
            cleverapps.paymentsHistory.updateInfo(serverData);
        },

        army: function (serverData) {
            if (!cleverapps.army) {
                return;
            }

            return cleverapps.army.updateInfo(serverData);
        },

        armylibrary: function (serverData) {
            if (!cleverapps.armyLibrary) {
                return;
            }

            return cleverapps.armyLibrary.updateInfo(serverData);
        },

        achievements: function (serverData) {
            if (!cleverapps.achievements) {
                return;
            }
            cleverapps.achievements.load(serverData);
            cleverapps.achievements.save(false);
        },

        noAds: function (serverData) {
            if (serverData) {
                cleverapps.noAds.enable(true);
            }
        },

        stickersbook: function (serverData) {
            if (!cleverapps.stickersBook) {
                return;
            }
            cleverapps.stickersBook.updateInfo(serverData);
        }
    },

    registerBySlots: function (name, extractor, importer) {
        Meta.SLOTS.forEach(function (slot) {
            CustomSyncers.extractors[name + slot] = function () {
                return extractor(slot);
            };

            CustomSyncers.importerData[name + slot] = function (serverData) {
                return importer(slot, serverData);
            };
        });

        if (cleverapps.config.debugMode) {
            CustomSyncers.extractors[name + "_snapshot"] = extractor;
            CustomSyncers.importerData[name + "_snapshot"] = importer;

            extractor.useSlots = true;
            importer.useSlots = true;
        }
    }
};
