/**
 * Created by Anastasiya on 08.06.2024
 */

var PixelOfferView = cc.Node.extend({
    ctor: function (offer) {
        this._super();
        this.offer = offer;

        var styles = cleverapps.styles.PixelOfferView;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles.width, styles.height);

        this.target = new cc.Node();
        this.target.setPositionRound(styles.collect);
        this.addChild(this.target);

        this.animation = new LazyAsset(bundles.pixeloffer.jsons.pixeloffer_json);
        this.animation.setPositionRound(styles.animation);
        this.animation.setAnimation(0, "animation", true);
        this.addChild(this.animation);

        cleverapps.UI.onDrag(this, {
            onClick: this.offer.collect.bind(this.offer),

            followPointer: true,

            onDragStart: function () {
                this.offer.setFlying(false);
                this.setLocalZOrder(1);

                return true;
            }.bind(this),
            onDragMove: function (touch) {
                this.setPositionRound(this.parent.convertTouchToNodeSpace(touch));
            }.bind(this),
            onDragEnd: function () {
                this.offer.setFlying(true);
                this.setLocalZOrder(0);
            }.bind(this)
        });

        this.offer.onFlyingChanged = this.createListener(this.changeFlying.bind(this));
        this.offer.onDie = this.createListener(this.explode.bind(this));

        this.minFlyTime = cleverapps.parseInterval(PixelOfferView.FLY_TIME);

        Game.currentGame.pushingComponent.add(this, "pixels");

        var cell = Map2d.currentMap.getMapView().alignInIsometricGrid(this.offer.x, this.offer.y);
        this.setPosition(cell.x, cell.y);

        this.speed = styles.speed;
        this.distanceDisappear = styles.distanceDisappear;

        this.flyStartTime = Date.now();
        this.offer.setFlying(true);

        this.setScale(0);
        this.runAction(new cc.ScaleTo(0.2, 1));

        this.lastCheck = 0;
    },

    update: function (dt) {
        this.setPosition(this.x + this.speed.x * dt, this.y + this.speed.y * dt);
        var screenSize = cleverapps.resolution.getBgSize();

        var ct = Date.now();
        if (this.flyStartTime + this.minFlyTime < ct && this.lastCheck < ct - 3000) {
            this.lastCheck = ct;
            var pos = this.parent.convertToWorldSpace(this.getPosition());

            if (screenSize.width + this.distanceDisappear < pos.x || pos.y < -this.distanceDisappear) {
                this.offer.die(true);
            }
        }

        var onScreen = cc.rectIntersectsRect(this.animation.getGlobalBoundingBox(), cc.rect(0, 0, screenSize.width, screenSize.height));
        this.setVisible(onScreen);
    },

    changeFlying: function (flying) {
        if (flying) {
            this.scheduleUpdate();
            this.resumePushing();
        } else {
            this.unscheduleUpdate();
            this.pausePushing();
        }
    },

    explode: function (silent) {
        cc.eventManager.removeListeners(this);

        var callback = cleverapps.once(function () {
            this.removeFromParent();
        }.bind(this));

        this.animation.setAnimation(0, "animation", false);
        this.animation.setCompleteListener(callback);

        if (silent) {
            this.removeFromParent();
        }
    }
});

cleverapps.styles.PixelOfferView = {
    width: 80,
    height: 80,
    animation: {
        x: { align: "center" },
        y: { align: "center", dy: -40 }
    },
    collect: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -150 },
        delay: 150
    },
    speed: {
        x: 35,
        y: -18
    },
    distanceDisappear: 1000
};

PixelOfferView.FLY_TIME = "90 seconds";
