/**
 * Created by olga on 17.01.2023
 */

var FilterBox = cc.Scale9Sprite.extend({
    ctor: function (bundlesExplorer) {
        this._super(bundles.wysiwyg.frames.window_bg);
        var styles = cleverapps.styles.FilterBox;
        this.setContentSize2(styles.width, styles.height);
        this.createContent();

        this.bundlesExplorer = bundlesExplorer;
    },

    createContent: function () {
        var styles = cleverapps.styles.FilterBox;
        var icon = new cc.Sprite(bundles.tool.frames.search);
        icon.setColor(cleverapps.styles.COLORS.COLOR_DARK_GRAY);
        icon.setPositionRound(styles.icon);
        this.addChild(icon);

        var editBox = this.editBox = new cc.EditBox(cc.size(styles.editbox.width, styles.editbox.height), undefined, true);
        editBox.setDelegate(this);
        editBox.stayOnTop(true);
        editBox.setTouchEnabled();
        editBox.setFontColor(cleverapps.styles.FONTS.ANALYZER_SEARCH_TEXT.color);
        editBox.setFont(cleverapps.UI.getFontName(), cleverapps.styles.FONTS.ANALYZER_SEARCH_TEXT.size);
        editBox.setPositionRound(styles.editbox);
        this.addChild(editBox);

        var closeButton = this.closeButton = new cc.Sprite(bundles.tool.frames.close_icon);
        closeButton.setPositionRound(styles.closeButton);
        closeButton.setColor(cleverapps.styles.COLORS.COLOR_DARK_GRAY);
        closeButton.setVisible(false);
        this.addChild(closeButton);
        cleverapps.UI.applyHover(closeButton);
        cleverapps.UI.onClick(closeButton, this.reset.bind(this));
    },

    reset: function () {
        this.closeButton.setVisible(false);
        this.editBox.setString("");
        this.bundlesExplorer.setFilter("");
    },

    editBoxTextChanged: function (sender) {
        var str = sender.getString();
        this.closeButton.setVisible(str && str.length > 0);
        this.bundlesExplorer.setFilter(str);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ANALYZER_SEARCH_TEXT: {
        size: 25,
        color: cleverapps.styles.COLORS.COLOR_DARK_GRAY
    }
});

cleverapps.styles.FilterBox = {
    width: 350,
    height: 45,
    editbox: {
        width: 250,
        height: 40,
        x: { align: "center", dx: 10 },
        y: { align: "center", dy: -3 }
    },
    icon: {
        x: { align: "left", dx: 20 },
        y: { align: "center", dy: 0 }
    },
    closeButton: {
        x: { align: "right", dx: -10 },
        y: { align: "center", dy: 0 }
    }
};