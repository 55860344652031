/**
 * Created by vladislav on 13.04.2022
 */

var Ship = function (unit) {
    UnitComponent.call(this, unit);

    this.unit.setPrizes({
        sprite: bundles.merge.frames.prize_exclamation
    });
};

Ship.prototype = Object.create(UnitComponent.prototype);
Ship.prototype.constructor = Ship;

Ship.prototype.onCustomSpawn = function (delay) {
    if (this.view) {
        this.view.onCustomSpawn(delay);
    } 
};

Ship.prototype.handleClick = function () {
    this.unit.squeeze();

    var mission = this.getMission();

    cleverapps.focusManager.display({
        focus: "shipMissionWindow",
        action: function (f) {
            if (!mission.isRunning()) {
                mission.displayCompleted(f);
                return;
            }

            new (mission.getViewClass())(mission);

            cleverapps.focusManager.onceNoWindowsListener = f;
        } 
    });

    return true;
};

Ship.prototype.getMission = function () {
    return cleverapps.missionManager.findByType(Mission.TYPE_CARAVAN);
};

Ship.prototype.handlePrizes = function () {
    var mission = this.getMission();

    if (!mission) {
        return true;
    }

    cleverapps.focusManager.display({
        focus: "openCaravanWindow",
        action: function (f) {
            new (mission.getViewClass())(mission);

            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });

    return true;
};