/**
 * Created by Andrey Popov on 01.08.2022
 */

var ExpeditionFeastGuideOptions = {
    name: "FeastMissionRulesWindow.ExpeditionFeast",

    getGuideBundle: function () {
        return cleverapps.skins.getSlot("expeditionFeastBundle");
    },

    stagesContent: function () {
        var styles = cleverapps.styles.ExpeditionFeastGuideWindow;

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.step1, styles.energies);
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            var ratingImg = this.bundle.frames.step2;
            this.addSprite(container, ratingImg, styles.rating);
        }.bind(this));

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.step3, styles.expeditionPresent);
        }.bind(this));

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.ExpeditionFeastGuideWindow = {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: 60 }
        },

        light: {
            x: 0,
            y: 20,
            scale: 0.9
        },

        energies: {
            x: 0,
            y: 20
        }
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: 10 }
        },

        rating: {
            x: 0,
            y: 0,
            scale: 0.9
        }
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: 60 }
        },

        light: {
            x: 0,
            y: 20,
            scale: 0.9
        },

        expeditionPresent: { x: 0, y: 0 }
    }
};
