/**
 * Created by mac on 12/11/20
 */

var Unit = function (options) {
    this.load(options);

    this.counter = 0;

    this.__instanceId = classManager.getNewInstanceId();

    if (typeof this.code !== "string" || !Families[this.code] || !Families[this.code].units[this.stage]) {
        // console.log(this);
        throw "creating unknown unit " + this.code + " " + this.stage;
    }

    if (this.code === "unknown") {
        cleverapps.playSession.set(cleverapps.EVENTS.DEBUG.MERGE.UNKNOWN_UNIT_DAU, true, cleverapps.travelBook.getCurrentPage().id + "-" + (options._data && options._data.code));
    }

    var level = Game.currentGame && Game.currentGame.level;
    if (level && !level.families[this.code]) {
        cleverapps.throwAsync("Unit from other world - " + this.getKey());
    }

    this.additionalViewsHidden = false;
};

Unit.prototype.onInfoOpened = function () {
    this.components.forEach(function (component) {
        if (component.onInfoOpened) {
            component.onInfoOpened();
        }
    });
};

Unit.prototype.onInfoClosed = function (withAction) {
    this.components.forEach(function (component) {
        if (component.onInfoClosed) {
            component.onInfoClosed(withAction);
        }
    });
};

Unit.prototype.onPrizeHarvested = function () {
    this.components.forEach(function (component) {
        if (component.onPrizeHarvested) {
            component.onPrizeHarvested();
        }
    });
};

Unit.prototype.onPrizeNotHarvested = function () {
    this.components.forEach(function (component) {
        if (component.onPrizeNotHarvested) {
            component.onPrizeNotHarvested();
        }
    });
};

Unit.prototype.onNeedToBeRemoved = function () {
    this.components.forEach(function (component) {
        if (component.onNeedToBeRemoved) {
            component.onNeedToBeRemoved();
        }
    });
};

Unit.prototype.setView = function (view) {
    this.view = view;
};

Unit.prototype.removeView = function () {
    if (this.view) {
        this.view.removeAdditionalViews();
        this.view = undefined;
    }

    this.components.forEach(function (component) {
        component.removeView();
    });
};

Unit.prototype.onGetView = function () {
    return this.view;
};

Unit.prototype.checkTouchInside = function (touch, isTallUnit) {
    if (this.view) {
        return this.view.checkTouchInside(touch, isTallUnit);
    }
};

Unit.prototype.onClickEvent = function (touch) {
    if (this.view) {
        return this.view.handleClick(touch);
    }
};

Unit.prototype.onDragStart = function () {
    if (this.view) {
        return this.view.handleDragStart();
    }
};

Unit.prototype.onDragMove = function () {
    if (this.view) {
        this.view.handleDragMove();
    }
};

Unit.prototype.onFollowPointer = function (touch) {
    if (this.view) {
        this.view.handleFollowPointer(touch);
    }
};

Unit.prototype.onDragEnd = function (cancelled) {
    if (this.view) {
        this.view.handleDragEnd(cancelled);
    }
};

Unit.prototype.onShowUp = function (callback) {
    if (this.view) {
        this.view.showUp(callback);
    } else {
        callback();
    }
};

Unit.prototype.onAdd = function () {
    if (this.view) {
        this.view.playAddAnimation();
    }
};

Unit.prototype.onDestruction = function (silent, originUnit) {
    if (this.view) {
        this.view.playDestruction(silent, originUnit);
    }
};

Unit.prototype.onRemove = function () {
    if (this.view) {
        this.view.onRemove();
    }
};

Unit.prototype.onTradeSell = function (callback) {
    if (this.view) {
        this.view.onTradeSell(callback);
    } else {
        callback();
    }
};

Unit.prototype.onTradeBuy = function (callback) {
    if (this.view) {
        this.view.onTradeBuy(callback);
    } else {
        callback();
    }
};

Unit.prototype.onMove = function (isNewPlace) {
    if (this.view) {
        this.view.move(isNewPlace);
    }
};

Unit.prototype.onStartDidMerged = function () {
    if (this.view) {
        this.view.startDidMerged();
    }
};

Unit.prototype.onDidMerged = function (startPoint, index) {
    if (this.view) {
        this.view.didMerged(startPoint, index);
    }
};

Unit.prototype.onMerge = function (target) {
    if (this.view) {
        this.view.merge(target);
    }
};

Unit.prototype.onStartAnimateMerge = function (target) {
    if (this.view) {
        this.view.startAnimateMerge(target);
    }
};

Unit.prototype.onStopAnimateMerge = function (target) {
    if (this.view) {
        this.view.stopAnimateMerge(target);
    }
};

Unit.prototype.onSqueeze = function () {
    if (this.view) {
        this.view.squeeze();
    }
};
Unit.prototype.onSpawned = function (parentUnit, id, options) {
    if (this.view) {
        this.view.spawn(parentUnit, id, options);
    }
};

Unit.prototype.onPrizeChanged = function () {
    if (this.view) {
        this.view.restoreState();
        this.view.animatePrizeView();
    }
};

Unit.prototype.onUpdateCustomerMark = function () {
    if (this.view) {
        this.view.updateCustomerMark();
    }
};

Unit.prototype.onMarkError = function () {
    if (this.view) {
        this.view.markError();
    }
};

Unit.prototype.onMarkNormal = function () {
    if (this.view) {
        this.view.markNormal();
    }
};

Unit.prototype.onMarkDragging = function () {
    if (this.view) {
        this.view.markDragging();
    }
};

Unit.prototype.onShowPoints = function () {
    if (this.view) {
        this.view.animateAppearPoints();
    }
};

Unit.prototype.onHidePoints = function () {
    if (this.view) {
        this.view.hidePoints();
    }
};

Unit.prototype.onClaimPoints = function (callback) {
    if (this.view) {
        this.view.claimPoints(callback);
    } else {
        callback();
    }
};

Unit.prototype.onGetDnDAccepted = function (targetUnit) {
    if (this.view) {
        this.view.getDnDAccepted(targetUnit);
    }
};

Unit.prototype.onShowFresh = function () {
    if (this.view) {
        this.view.showFreshAnimation();
    }
};

Unit.prototype.onCollectIngredients = function (collected) {
    if (this.view) {
        this.view.collectIngredients(this, collected, function () {
            Game.currentGame.harvested.onChange();
        });
    } else {
        Game.currentGame.harvested.onChange();
    }
};

Unit.prototype.getResource = function () {
    return Families[this.code].resource;
};

Unit.prototype.getType = function () {
    return Families[this.code].type;
};

Unit.prototype.playClickSound = function () {
    if (this.isBuilt()) {
        var key = this.code + "_" + this.stage + "_click";
        if (this.isCastle() || (this.getType() === "resource" && this.stage === UnitsLibrary.LastStage(this.code).stage - 1)) {
            key = this.code + "_click";
        }

        if (bundles.click_sounds.urls[key]) {
            cleverapps.audio.playSound(bundles.click_sounds.urls[key]);
        }
    }
};

Unit.prototype.getData = function () {
    return Families[this.code].units[this.stage];
};

Unit.prototype.load = function (stored) {
    this.code = stored.code;
    this.stage = stored.stage;
    this.prizesCount = stored.prizesCount;

    if (stored.points) {
        if (cleverapps.missionManager.findLocalPass()) {
            this.points = stored.points;
        }
    }

    if (stored._data) {
        this._data = stored._data;
    }

    this.components = [];
    ComponentsFactory(this.components, this, stored);
};

Unit.prototype.getInfo = function () {
    var data = {
        stage: this.stage,
        code: this.code,
        prizesCount: this.prizesCount
    };

    if (this.points) {
        if (cleverapps.missionManager.findLocalPass()) {
            data.points = this.points;
        }
    }

    this.components.forEach(function (component) {
        if (component.save) {
            component.save(data);
        }
    });

    if (this.intact) {
        this.intact = undefined;
        Map2d.currentMap.removeUnit(this);
    }

    if (this.isIntact(data)) {
        this.intact = true;

        data = { code: "intact", stage: 0 };
    }

    return data;
};

Unit.prototype.save = function () {
    var level = Game.currentGame && Game.currentGame.level;
    if (level && !level.families[this.code]) {
        cleverapps.throwAsync("Save unit to other world - " + Unit.GetKey(this));
        return;
    }
    Map2d.currentMap.saveUnit(this);
};

Unit.prototype.showUp = function (callback) {
    this.onShowUp(callback);
};

Unit.prototype.destructor = function () {
    this.clearSqueeze();
    this.components.forEach(function (component) {
        component.destructor && component.destructor();
    });

    this.light && this.light.destructor();

    this.removeView();
};

Unit.prototype.clearSqueeze = function () {
    clearTimeout(this.squeezeTimeout);
    this.squeezeTimeout = undefined;
};

Unit.prototype.isLockedUnit = function () {
    var lock = this.findComponent(LockedComponent);
    return lock && lock.isLockActive();
};

Unit.prototype.getKey = function () {
    return Unit.GetKey(this);
};

Unit.prototype.findComponent = function (Component) {
    for (var i = 0; i < this.components.length; i++) {
        if (this.components[i] instanceof Component) {
            return this.components[i];
        }
    }
};

Unit.prototype.setCustomerMark = function (customerMark) {
    if (!!customerMark === !!this.customerMark) {
        return;
    }

    this.customerMark = customerMark;

    this.onUpdateCustomerMark();
};

Unit.prototype.setPrizes = function (prizes, exp) {
    if (cleverapps.config.editorMode) {
        return;
    }

    var prizesCount = this.prizesCount;

    prizes = cleverapps.clone(prizes, true);

    if (prizes && prizes.resource) {
        prizes = [prizes];
    }

    if (prizes && prizes.length) {
        prizes.sort(function (a, b) {
            return a.resource && b.resource && (a.resource.localeCompare(b.resource) || b.amount - a.amount)
                || Boolean(b.resource) - Boolean(a.resource)
                || a.ingredient && b.ingredient && (a.ingredient.localeCompare(b.ingredient) || b.amount - a.amount)
                || Boolean(b.ingredient) - Boolean(a.ingredient);
        });

        if (prizesCount) {
            prizes = prizes.slice(Math.max(0, prizes.length - prizesCount));
        }
    }

    this.prizeWarning = undefined;
    if (!prizes || prizes.length === 0) {
        this.prizes = undefined;
        this.prizesCount = undefined;
        this.prizesExp = undefined;
    } else {
        this.prizes = prizes;
        this.prizesCount = prizes.length;
        this.prizesExp = exp;
    }
    this.onPrizeChanged();
    this.save();
};

Unit.prototype.takePrizes = function (force) {
    if (!this.prizes) {
        return false;
    }

    this.squeeze();

    for (var i = 0; i < this.components.length; i++) {
        var component = this.components[i];
        if (component.handlePrizes && component.handlePrizes()) {
            return true;
        }
    }

    if (this.prizes.sprite) {
        this.prizes = undefined;
        this.onPrizeChanged();
        return false;
    }

    var expDelay = 0;

    if (this.prizes.length) {
        var resources = {};
        var ingredients = [];
        var units = [];
        var remains = [];

        this.prizes.forEach(function (prize) {
            if (prize.resource) {
                resources[prize.resource] = prize.amount;
            } else if (prize.ingredient) {
                ingredients.push(prize);
            } else {
                units.push({ code: prize.code, stage: prize.stage, lock: prize.lock });
            }
        });

        if (this.code === "energyplant" && resources.energy) {
            var data = cleverapps.playSession.get(cleverapps.EVENTS.EARNED_ENERGYPLANT + "_" + this.stage) || {};
            data[cleverapps.user.level] = (data[cleverapps.user.level] || 0) + resources.energy;
            cleverapps.playSession.set(cleverapps.EVENTS.EARNED_ENERGYPLANT + "_" + this.stage, data);
        }

        var rewards = new RewardsList(resources, { event: cleverapps.EVENTS.EARN.OTHER });
        if (rewards.listRewards().length) {
            rewards.receiveRewards();
            rewards.collectRewardsAnimation(this, { withoutDelta: true, rewardsInterval: cleverapps.skins.getSlot("useLongJumpAnimation") ? 300 : 500 });
        }

        ingredients = ingredients.filter(function (prize) {
            if (Game.currentGame.harvested.isFull() && !force) {
                Game.currentGame.harvested.needsToShowTip();
                remains.push(prize);
                return false;
            }

            Game.currentGame.harvested.add(prize.ingredient, prize.amount, true);
            return true;
        });

        if (ingredients.length) {
            this.onCollectIngredients(ingredients);
        }

        remains = remains.concat(Game.currentGame.spawn(units, this, { radius: Map2d.RADIUS_SMALL }));

        if (this.isCastle()) {
            units.forEach(function (prize) {
                var data = cleverapps.playSession.get(cleverapps.EVENTS.CASTLE_PRIZES) || {};

                if (!data[cleverapps.user.level]) {
                    data[cleverapps.user.level] = {};
                }

                var code = prize.code + "_" + prize.stage;

                data[cleverapps.user.level][code] = (data[cleverapps.user.level][code] || 0) + 1;

                cleverapps.playSession.set(cleverapps.EVENTS.CASTLE_PRIZES, data);
            });
        }

        if (this.code === "coinsplant") {
            cleverapps.substract(units, remains).forEach(function (unit) {
                if (unit.code === "coins") {
                    var data = cleverapps.playSession.get(cleverapps.EVENTS.EARNED_COINSPLANT + "_" + this.stage) || {};

                    data[cleverapps.user.level] = (data[cleverapps.user.level] || 0) + Families[unit.code].units[unit.stage].value;

                    cleverapps.playSession.set(cleverapps.EVENTS.EARNED_COINSPLANT + "_" + this.stage, data);
                }
            }, this);
        }

        if (this.code === "rubiesplant") {
            cleverapps.substract(units, remains).forEach(function (unit) {
                if (unit.code === "rubies") {
                    var data = cleverapps.playSession.get(cleverapps.EVENTS.EARNED_RUBIESPLANT + "_" + this.stage) || {};

                    data[cleverapps.user.level] = (data[cleverapps.user.level] || 0) + Families[unit.code].units[unit.stage].value;

                    cleverapps.playSession.set(cleverapps.EVENTS.EARNED_RUBIESPLANT + "_" + this.stage, data);
                }
            }, this);
        }

        if (this.code === "magicplant") {
            cleverapps.substract(units, remains).forEach(function (unit) {
                if (unit.code === "crystal") {
                    var data = cleverapps.playSession.get(cleverapps.EVENTS.EARNED_MAGICPLANT) || {};

                    data[cleverapps.user.level] = (data[cleverapps.user.level] || 0) + 1;

                    cleverapps.playSession.set(cleverapps.EVENTS.EARNED_MAGICPLANT, data);
                }
            }, this);
        }

        if (remains.length > 0) {
            this.prizesCount = remains.length;
            this.prizes = remains;
            this.prizeWarning = true;

            this.save();
            this.onPrizeChanged();
            this.onPrizeNotHarvested();
        } else {
            expDelay = cleverapps.skins.getSlot("useLongJumpAnimation") ? 200 : units.length * 100 + 500;

            this.prizesCount = undefined;
            this.prizes = undefined;
            this.prizeWarning = undefined;
            this.save();
            this.onPrizeChanged();
            this.onPrizeHarvested();
            this.onNeedToBeRemoved();

            Map2d.mapEvent(Map2d.HARVEST, { affected: this });
            Map2d.mapEvent(Map2d.PRIZE_HARVESTED, { affected: this, prizes: units });
        }
    }

    if (!this.prizes && this.prizesExp && !cleverapps.gameModes.skipExpPrize) {
        Game.currentGame.addReward("exp", this.prizesExp, this, {
            delay: expDelay
        });
        this.prizesExp = undefined;
    }

    return true;
};

Unit.prototype.isGrounded = function () {
    return this.findComponent(Grounded) !== undefined;
};

Unit.prototype.isMultiCell = function () {
    return this.findComponent(MultiCell) !== undefined;
};

Unit.prototype.isUpgradable = function () {
    return this.findComponent(Upgradable);
};

Unit.prototype.isFeedable = function () {
    return this.findComponent(Feedable) !== undefined;
};

Unit.prototype.isMultiCellBody = function () {
    return this.code === "multiCellBody";
};

Unit.prototype.isStopsFog = function () {
    return Boolean(this.getData().stopsfog);
};

Unit.prototype.canShareBodyCell = function (otherUnit) {
    if (!this.isMultiCellBody() || !this.head.isGrounded() && !this.head.isStopsFog()) {
        return false;
    }

    otherUnit = otherUnit.head || otherUnit;
    return otherUnit.isGrounded() || otherUnit.isStopsFog() || otherUnit.code === "unknown";
};

Unit.prototype.shareBodyCell = function (otherUnit) {
    var unit = this.head;

    this.x = undefined;
    this.y = undefined;

    unit.sharedWith = unit.sharedWith || [];
    if (unit.sharedWith.indexOf(otherUnit) === -1) {
        unit.sharedWith.push(otherUnit);
    }

    otherUnit.sharedWith = otherUnit.sharedWith || [];
    if (otherUnit.sharedWith.indexOf(unit) === -1) {
        otherUnit.sharedWith.push(unit);
    }
};

Unit.prototype.restoreSharedBodyCells = function () {
    if (this.sharedWith) {
        this.sharedWith.forEach(function (unit) {
            var index = unit.sharedWith.indexOf(this);
            if (index !== -1) {
                unit.sharedWith.splice(index, 1);
            } else {
                cleverapps.throwAsync("Unit not found in shared cell");
            }

            var multicell = unit.findComponent(MultiCell);
            if (multicell) {
                multicell.restore();
            }
        }, this);
        this.sharedWith = undefined;
    }
};

Unit.prototype.getActionInfo = function () {
    var info;

    if (!Buildable.IsBuilt(this)) {
        return this.findComponent(Buildable).getActionInfo();
    }

    if (!Creatable.IsCreated(this)) {
        return this.findComponent(Creatable).getActionInfo();
    }

    for (var i = this.components.length - 1; i >= 0; --i) {
        var component = this.components[i];

        if (component.getActionInfo) {
            info = component.getActionInfo();
        }

        if (info === false) {
            return;
        }

        if (info) {
            break;
        }
    }

    if (this.isSellable()) {
        info = info || {};
        info.title = info.title || cleverapps.unitsLibrary.getUnitName(this);
        if (cleverapps.gameLevel.getLevel() >= Unit.MINIMAL_SELL_LEVEL
            && (!cleverapps.travelBook.isExpedition() || Map2d.currentMap.fogs.isOpened(Unit.MINIMAL_SELL_FOG) || cleverapps.travelBook.getCurrentPage().id === "collections")) {
            info.buttons = info.buttons || {};
            info.buttons.sell = { unit: this };
        }
    }

    var data = this.getData();

    if (data.rarity) {
        info = info || {};
        info.rarity = data.rarity;
    }

    if (data.unitsLibrary && !info.windowParameters) {
        info = info || {};
        info.windowParameters = {
            unit: this,
            unitsLibrary: true
        };
    }

    var upgradable = this.findComponent(Upgradable);
    if (upgradable && upgradable.level) {
        info = info || {};
        info.upgradableLevel = upgradable.level;
    }

    if (this.code === "unknown") {
        info = {};
        info.description = "Units.unknown.description";

        if (this._data) {
            info.buttons = {};
            info.buttons.sell = { unit: this };

            if (cleverapps.config.debugMode) {
                info.title = this._data.code + " stage" + this._data.stage;
            }
        }
    }

    return info;
};

Unit.prototype.isSellable = function () {
    var collectible = this.findComponent(Collectible);
    if (collectible && collectible.canRemoved()) {
        return true;
    }

    var family = Families[this.code];

    switch (this.code) {
        case "coins": case "rubies": case "drgrowing": case "seagrowing": case "xmgrowing":
            return this.stage === 0;
        case "worker": case "xmmagicplant": case "drmagicplant": case "seamagicplant":
            return this.stage < family.units.length - 1;
        case "magicplant":
            return this.stage < family.units.length - 2;
        case "crystal": case "dr2wheat": case "dr2coal": case "dr2barrel":
            return true;
    }

    switch (family.type) {
        case "resource":
            return this.stage < family.units.length - 2 && this.isBuilt();
        case "fruit": case "seafruit": case "clfruit": case "drfruit":
            return this.stage < 3;
        case "source":
            return !this.findComponent(Mineable);
        case "xmresource": case "drresource": case "searesource":
            return this.stage < family.units.length - 1 && this.isBuilt();
        case "drgift": case "seagift": case "clpet": case "clpetrare": case "clpetlegend":
        case "thanksgiving": case "xmproduct0": case "xmproduct1": case "xmproduct2":
        case "drproduct0": case "drproduct1": case "drproduct2":
            return true;
    }

    return false;
};

Unit.prototype.isMovable = function () {
    if (this.isMultiCellBody()) {
        return this.head.isMovable();
    }

    if (cleverapps.config.editorMode || cleverapps.config.demoMode) {
        return true;
    }

    var data = this.getData();

    if (this.movable === false || data.movable === false || data.stopsfog) {
        return false;
    }

    if (this.findComponent(InstantWorker) && this.findComponent(InstantWorker).isWorking()) {
        return false;
    }

    var notMovableComponents = [Grounded, ThirdElement];
    for (var i = 0; i < notMovableComponents.length; i++) {
        if (this.findComponent(notMovableComponents[i]) !== undefined) {
            return false;
        }
    }

    if (this.findComponent(Collectible) !== undefined && data.unmergeable && this.isBuilt()) {
        if (["drfruit", "seafruit", "clfruit"].indexOf(this.getType()) !== -1) {
            return true;
        }
        if (this.getType() !== "resource") {
            return false;
        }
        if (Map2d.currentMap.listAvailableUnits().some(function (unit) {
            return unit.code === this.code && unit.isUpgradable();
        }.bind(this))) {
            return false;
        }
    }

    return true;
};

Unit.prototype.isBlockedCell = function (x, y) {
    var map = Map2d.currentMap;
    if (!map.isGround(x, y)) {
        return true;
    }

    var unit = map.getUnit(x, y);
    if (unit && !unit.isMovable()) {
        return true;
    }

    if (unit && unit.head && unit.head !== this) {
        return true;
    }

    if (unit && unit.isMultiCell() && unit !== this) {
        return true;
    }

    if (map.getFog(x, y) !== undefined) {
        return true;
    }

    if (cleverapps.config.editorMode && unit && unit !== this && (!unit.head || unit.head !== this)) {
        return true;
    }

    return false;
};

Unit.prototype.isIntact = function (data) {
    if (cleverapps.config.type === "merge" && (!Game.currentGame || Game.currentGame.slot !== CustomSyncers.EXPEDITION_SLOT4)) {
        return false;
    }

    var fakeUnit = Map2d.currentMap.fogs.getFakeUnit(this.x, this.y);

    return this.equals(fakeUnit) && Object.keys(data).every(function (key) {
        return key === "code" || key === "stage" || data[key] === undefined;
    });
};

Unit.prototype.canMoveTo = function (x, y) {
    return this.getShape().every(function (part) {
        return !this.isBlockedCell(x + part.x, y + part.y);
    }, this);
};

Unit.prototype.handleClick = function () {
    cleverapps.userStatus.reportUserAction();

    if (this.points) {
        this.claimPoints();
        return true;
    }

    var worker = Map2d.currentMap.workers.findAssigned(this);
    if (worker !== undefined) {
        if (SpeedUpWindow.CalcPrice(worker.getTimeLeft()) > 0 && !worker.speedupInProgress) {
            cleverapps.focusManager.display({
                focus: "SpeedUp",
                action: function (f) {
                    new SpeedUpWindow(worker);
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
        return true;
    }

    if (this.takePrizes()) {
        this.playClickSound();
        return true;
    }

    for (var i = 0; i < this.components.length; i++) {
        var component = this.components[i];

        if (component instanceof LockedComponent && component.handleClick()) {
            break;
        } else if (component.handleClick && component.handleClick()) {
            return true;
        }
    }

    return false;
};

Unit.prototype.distanceTo = function (target) {
    return Math.abs(this.x - target.x) + Math.abs(this.y - target.y);
};

Unit.prototype.isMergeable = function (otherUnit, canBeCrystal) {
    if (otherUnit.isDragged()) {
        return false;
    }

    if (this.getData().stopsfog || otherUnit.getData().stopsfog) {
        return false;
    }

    if (this.getData().unmergeable || otherUnit.getData().unmergeable) {
        return false;
    }

    if (this.isLockedUnit() || otherUnit.isLockedUnit()) {
        return false;
    }

    if (!this.isBuilt() || !otherUnit.isBuilt()) {
        return false;
    }

    if (otherUnit.isLast() && otherUnit.getData().mergeUnit === undefined) {
        return false;
    }

    if (!CupComponent.CanMerge(this, otherUnit)) {
        return false;
    }

    if (canBeCrystal && this.isMagicCrystal() && otherUnit.code !== this.code) {
        return true;
    }

    return otherUnit.code === this.code && otherUnit.stage === this.stage;
};

Unit.prototype.isBuilt = function () {
    return Buildable.IsBuilt(this) && Creatable.IsCreated(this);
};

Unit.prototype.isMagicCrystal = function () {
    return this.code === "crystal" && this.isLast();
};

Unit.prototype.getMergeUnit = function () {
    if (this.getData().mergeUnit) {
        return this.getData().mergeUnit;
    }

    var nextStage = this.stage + 1;
    while (Families[this.code].units[nextStage] && Families[this.code].units[nextStage].deleted) {
        nextStage++;
    }

    if (!Families[this.code].units[nextStage]) {
        return undefined;
    }

    return {
        code: this.code,
        stage: nextStage
    };
};

Unit.prototype.setPosition = function (x, y) {
    if (!this.isLifted()) {
        if (cleverapps.config.debugMode) {
            console.log(this);
            throw "setPosition for already positioned unit";
        }
    }

    var map = Map2d.currentMap;
    var multicell = this.findComponent(MultiCell);

    this.getShape().forEach(function (part, i) {
        var unit = (part.x === 0 && part.y === 0) ? this : multicell.bodyUnits[i];

        unit.x = x + part.x;
        unit.y = y + part.y;

        var wasUnit = map.getUnit(unit.x, unit.y);
        if (wasUnit && !cleverapps.config.wysiwygMode) {
            if (unit.canShareBodyCell(wasUnit)) {
                unit.shareBodyCell(wasUnit);
                return;
            }

            if (wasUnit.canShareBodyCell(unit)) {
                wasUnit.shareBodyCell(unit);
            } else {
                wasUnit = wasUnit.head || wasUnit;
                wasUnit.remove();
                Game.currentGame && Game.currentGame.pocket && Game.currentGame.pocket.addUnits(wasUnit);
            }
        }

        map.add(Map2d.LAYER_UNITS, unit.x, unit.y, unit);
    }, this);

    this.save();
};

Unit.prototype.isLifted = function () {
    return this.x === undefined || this.y === undefined;
};

Unit.prototype.isDragged = function () {
    return this.dragX !== undefined || this.dragY !== undefined;
};

Unit.prototype.remove = function (silent) {
    this.unhighlight();

    if (!this.isLifted()) {
        var map = Map2d.currentMap;
        var mapUnit = map.getUnit(this.x, this.y);

        if (!mapUnit || mapUnit !== this) {
            if (cleverapps.config.debugMode && !cleverapps.config.wysiwygMode) {
                console.log(this, mapUnit);
                throw "Cant be! ";
            }
        }

        map.remove(Map2d.LAYER_UNITS, this.x, this.y);

        if (this.intact) {
            this.intact = undefined;
        }

        map.removeUnit(this);
    }

    for (var i = 0; i < this.components.length; i++) {
        var component = this.components[i];
        component.handleRemove && component.handleRemove();
    }

    this.restoreSharedBodyCells();

    if (!silent) {
        this.onRemove();
    }

    Map2d.currentMap.onUnitRemoved(this);

    this.destructor();
};

Unit.prototype.sell = function () {
    var price = Unit.CalcSellPrice(this);
    if (price) {
        Game.currentGame.addReward("soft", price, this.onGetView(), {
            withoutDelta: true
        });
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.UNITS_SOLD, { value: price });
    }

    this.onDestruction();
    this.remove(true);

    if (this.code === "unknown") {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.UNKNOWN_UNIT_SOLD + "_" + cleverapps.travelBook.getCurrentPage().id);
    }
};

Unit.prototype.liftUnitFromMap = function () {
    if (this.isLifted()) {
        if (cleverapps.config.debugMode) {
            console.log(this);
            throw "Lift twice!";
        }
        return;
    }

    if (this.intact) {
        this.intact = undefined;
    }

    Map2d.currentMap.removeUnit(this);

    var map = Map2d.currentMap;

    var x = this.x;
    var y = this.y;
    this.getShape().forEach(function (part) {
        var unit = map.getUnit(x + part.x, y + part.y);
        if (!unit) {
            var message = "Unit undefined - " + JSON.stringify({
                code: this.code, stage: this.stage, x: x, y: y, partX: part.x, partY: part.y
            });
            message += " stack - " + new Error().stack;
            message += " removeStack - " + this.removeStack;
            if (part.x === 0 && part.y === 0) {
                throw message;
            } else {
                cleverapps.throwAsync(message);
                return;
            }
        }

        delete unit.x;
        delete unit.y;

        if (unit !== this && unit.head !== this) {
            if (cleverapps.config.debugMode) {
                console.log(this, unit);
                throw "Error while liftUnitFromMap!";
            }
        }

        map.remove(Map2d.LAYER_UNITS, x + part.x, y + part.y);
    }, this);
};

Unit.prototype.seedRandom = function (seed) {
    seed = seed || 0;
    if (this.isLifted()) {
        if (Map2d.currentMap.currentLoadingUnitPosition) {
            seed += Map2d.currentMap.currentLoadingUnitPosition.x * 1000 + Map2d.currentMap.currentLoadingUnitPosition.y;
        }
    } else {
        seed += this.x * 1000 + this.y;
    }
    return cleverapps.Random.seed(connector.platform.getUserID() + seed);
};

Unit.prototype.getShape = function () {
    return Unit.GetShape(this);
};

Unit.prototype.highlight = function (options) {
    options = options || {};

    this.unhighlight();

    this.light = new Highlight(this.x, this.y, {
        isErrorLight: options.isErrorLight,
        unit: this,
        drag: options.drag
    });
    if (options.drag) {
        Map2d.currentMap.onAnimationsAdd(this.light);
        this.light.move(this.x, this.y, true);
    } else {
        Map2d.currentMap.onAdd(Map2d.LAYER_UNITS, this.light.x, this.light.y, this.light);
    }

    if (options.duration) {
        this.light.schedule(this.unhighlight.bind(this), options.duration);
    }

    this.components.forEach(function (component) {
        if (component.onHighlight) {
            component.onHighlight();
        }
    });
};

Unit.prototype.unhighlight = function () {
    if (this.light) {
        this.light.remove();
        delete this.light;
    }
    this.components.forEach(function (component) {
        if (component.onUnhighlight) {
            component.onUnhighlight();
        }
    });
};

Unit.prototype.unhighlightMerge = function () {
    if (!this.clusters) {
        return;
    }

    this.onStopAnimateMerge(this);
    cleverapps.centerHint.finishCenterHint();

    this.clusters.forEach(function (affected) {
        affected.forEach(function (p) {
            var unit = Map2d.currentMap.getUnit(p.x, p.y);
            if (unit) {
                unit.unhighlight();
                unit.onStopAnimateMerge(this);
            }
        });
    });

    this.clusters = undefined;
};

Unit.prototype.highlightMerge = function (clusters, params) {
    this.clusters = clusters;
    params = params || {};

    if (clusters.length) {
        if (!params.wrongMerged) {
            this.onStartAnimateMerge(this);
        }
        if (!params.noMessage) {
            var amount = clusters.reduce(function (sum, affected) {
                return sum + affected.length;
            }, 1);
            cleverapps.centerHint.create({
                amount: amount, unit: this, wrongMerged: params.wrongMerged
            });
        }

        clusters.forEach(function (affected) {
            var target = affected[0];
            affected.forEach(function (p) {
                var unit = Map2d.currentMap.getUnit(p.x, p.y);
                unit.highlight({ isErrorLight: params.wrongMerged });

                if (cleverapps.gameModes.lightUpOnHoveredUnit && unit.distanceTo(target) === 0 && unit !== this) {
                    unit.light.up();
                }

                if (!params.wrongMerged) {
                    unit.onStartAnimateMerge(target);
                }
            }.bind(this));
        }.bind(this));
    }
};

Unit.prototype.didMerged = function (parentUnit, index) {
    if (Unit.Equals(parentUnit, this)) {
        var rewards = this.isBuilt() ? this.getCreatePrize() : {};

        var stars = cleverapps.clanCup.isRunning() && Unit.CalcCupStars(this);
        if (stars) {
            var icon = cleverapps.sideBar.getIconByClassName(ClansCupIcon);
            if (icon && !icon.disabled) {
                rewards.cup = {
                    type: CupsConfig.TYPE_CLAN,
                    amount: stars
                };
            }
        }

        var rewardList = new RewardsList(rewards, { event: cleverapps.EVENTS.EARN.OTHER });
        rewardList.receiveRewards();
        rewardList.collectRewardsAnimation(this, {
            delay: 700
        });
    }

    this.onDidMerged(parentUnit, index);
};

Unit.prototype.listSameItemsGroup = function (x, y) {
    var map = Map2d.currentMap;
    var unit = map.getUnit(x, y);
    var affected = [];

    if (map.compareEqual(this, x, y)) {
        affected = map.bfs(x, y, map.compareEqual.bind(map, unit));
    }

    if (affected.length >= 2) {
        return affected;
    }
};

Unit.prototype.listMergeAtPoint = function (x, y) {
    var map = Map2d.currentMap;
    var unit = map.getUnit(x, y);
    var affected = [];

    if (unit && map.isGround(x, y) && !map.getFog(x, y) && this.isMergeable(unit, true)) {
        affected = map.bfs(x, y, map.compareMergeable.bind(map, unit));
    }

    if (affected.length >= 2) {
        return affected;
    }
};

Unit.prototype.handleDragStart = function () {
    if (!this.isMovable()) {
        return false;
    }

    if (this.points) {
        this.claimPoints();
        return false;
    }

    cleverapps.userStatus.reportUserAction();
    Map2d.currentMap.setDragging(this);
    this.highlight({ drag: true });
    this.onMarkDragging();

    var draggable = this.findComponent(Draggable);
    if (draggable) {
        draggable.handleDragStart();
    }

    var pulsing = this.findComponent(Pulsing);
    if (pulsing) {
        pulsing.setDragging(true);
    }

    this.dragX = this.x;
    this.dragY = this.y;

    return true;
};

Unit.prototype.handleDragMove = function (rawX, rawY) {
    var x = Math.round(rawX),
        y = Math.round(rawY);

    if (this.isMultiCell()) {
        var size = this.findComponent(MultiCell).getSize();

        x = Math.round(rawX - size.width / 2);
        y = Math.round(rawY - size.height / 2);
    }

    var cellsInRadius = [{ x: x, y: y }];
    var diffX = rawX - x;
    if (Math.abs(diffX) > 0.35) {
        cellsInRadius.push(diffX > 0 ? { x: x + 1, y: y } : { x: x - 1, y: y });
    }

    var diffY = rawY - y;
    if (Math.abs(diffY) > 0.35) {
        cellsInRadius.push(diffY > 0 ? { x: x, y: y + 1 } : { x: x, y: y - 1 });
    }

    var affected, wrongMerged, i, cell;
    if (!cleverapps.gameModes.automerge) {
        for (i = 0; i < cellsInRadius.length; i++) {
            cell = cellsInRadius[i];
            affected = this.listMergeAtPoint(cell.x, cell.y);
            if (affected) {
                x = cell.x;
                y = cell.y;
                break;
            }
        }

        if (!affected) {
            for (i = 0; i < cellsInRadius.length; i++) {
                cell = cellsInRadius[i];
                wrongMerged = this.listSameItemsGroup(cell.x, cell.y);
                if (wrongMerged) {
                    x = cell.x;
                    y = cell.y;
                    break;
                }
            }
        }
    }

    if (this.dragX === x && this.dragY === y) {
        return;
    }

    this.dragX = x;
    this.dragY = y;

    if (cleverapps.actionsRecording) {
        this.dragRawX = Number(rawX.toFixed(4));
        this.dragRawY = Number(rawY.toFixed(4));
    }

    if (cleverapps.gameModes.automerge) {
        AutomergeHelper.handleDragMove(this, x, y);
    } else {
        this.unhighlightMerge();
        if (this.light) {
            this.light.show();
        }
        if (affected || wrongMerged) {
            if (this.light) {
                this.light.hide();
            }
            if (affected) {
                this.highlightMerge([affected]);
            } else {
                this.highlightMerge([wrongMerged], {
                    wrongMerged: true
                });
            }
        }
    }

    if (this.light) {
        this.light.move(x, y);
    }

    var draggable = this.findComponent(Draggable);
    if (draggable && draggable.handleDragMove(x, y)) {
        return;
    }

    if (!this.light || this.light.error) {
        this.onMarkError();
    } else {
        this.onMarkDragging();
    }
};

Unit.prototype.handleDragEnd = function (cancelled) {
    var error = !this.light || this.light.error;

    this.unhighlightMerge();
    this.unhighlight();

    var pulsing = this.findComponent(Pulsing);
    if (pulsing) {
        pulsing.setDragging(false);
    }

    cleverapps.userStatus.reportUserAction();
    Map2d.currentMap.setDragging(undefined);
    cleverapps.centerHint.finishCenterHint();

    var draggable = this.findComponent(Draggable);
    if (draggable && draggable.handleDragEnd(this.dragX, this.dragY)) {
        this.dragX = undefined;
        this.dragY = undefined;
        return;
    }

    if (cancelled || error || this.x === this.dragX && this.y === this.dragY) {
        this.dragX = undefined;
        this.dragY = undefined;
        this.onMove();
        return;
    }

    var affected = this.listMergeAtPoint(this.dragX, this.dragY);
    if (!affected || cleverapps.config.editorMode) {
        this.move(this.dragX, this.dragY, true);
        this.afterDragEnd(affected);
        return;
    }

    var mergeInfoUnit = Map2d.currentMap.getUnit(this.dragX, this.dragY);
    var needConfirmation = this.isMagicCrystal() && (Game.currentGame && Game.currentGame.tutorial && !Game.currentGame.tutorial.isActive()) && !cleverapps.gameModes.skipOpenChestWindow;

    if (!needConfirmation) {
        Game.currentGame && Game.currentGame.merge && Game.currentGame.merge(affected, this, mergeInfoUnit);
        this.afterDragEnd(affected);
        return;
    }

    var showConfirmationWindow = function () {
        var amount = Game.currentGame.mergeBonus(mergeInfoUnit, affected.length + 1).bonus;

        new ConfirmMergeWindow(this, mergeInfoUnit, amount, function (confirmed) {
            if (confirmed) {
                Game.currentGame.merge(affected, this, mergeInfoUnit);

                this.afterDragEnd(affected);
            } else {
                this.dragX = undefined;
                this.dragY = undefined;
                this.onMove();
            }
        }.bind(this));
    }.bind(this);

    cleverapps.focusManager.display({
        stack: true,
        focus: "ConfirmMergeWindow",
        action: function (f) {
            showConfirmationWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};

Unit.prototype.afterDragEnd = function (affected) {
    this.dragX = undefined;
    this.dragY = undefined;

    if (!affected && cleverapps.gameModes.automerge) {
        AutomergeHelper.handleDragEnd(this, this.x, this.y);
    }

    Game.currentGame && Game.currentGame.tutorial && Game.currentGame.tutorial.processFresh();
};

Unit.prototype.getCreatePrize = function () {
    if (cleverapps.gameModes.skipUnitCreatePrize) {
        return {};
    }

    var rewards = {};
    var createPrize = this.getData().createPrize || {};

    if (createPrize.soft) {
        rewards.soft = createPrize.soft;
    }

    if (createPrize.exp) {
        rewards.exp = createPrize.exp;
    }
    return rewards;
};

Unit.prototype.move = function (x, y, isNewPlace) {
    var map = Map2d.currentMap;

    var occupiedList = [];

    this.getShape().forEach(function (part) {
        var occupied = map.getUnit(x + part.x, y + part.y);
        if (!occupied) {
            return;
        }
        if (occupied.head) {
            occupied = occupied.head;
        }
        if (occupied !== this) {
            occupiedList.push(occupied);
        }
    }, this);

    occupiedList = cleverapps.unique(occupiedList, Unit.GetPositionKey);

    if (!this.isLifted()) {
        this.liftUnitFromMap();
    }

    occupiedList.forEach(function (occupied) {
        occupied.liftUnitFromMap();
    });

    this.setPosition(x, y);
    this.unhighlight();
    this.onMove(isNewPlace);

    occupiedList.forEach(function (occupied) {
        var empty = map.findEmptySlot(x, y, occupied, {
            skipCheckEqual: true
        });

        if (!empty) {
            occupied.remove();
            Game.currentGame.pocket && Game.currentGame.pocket.addUnits(occupied, occupied);
        } else {
            occupied.setPosition(empty.x, empty.y);
            occupied.onMove();
        }
    });

    if (Map2d.currentMap.workers.findAssigned(this)) {
        Map2d.currentMap.workers.save();
    }
};

Unit.prototype.suspendBuilding = function (timeSpent) {
    var buildable = this.findComponent(Buildable);
    if (buildable) {
        buildable.buildingSuspend(timeSpent);
        this.save();
    }
};

Unit.prototype.completeBuilding = function () {
    var level = Game.currentGame && Game.currentGame.level;
    if (level && !level.families[this.code]) {
        cleverapps.throwAsync("Built unit from other world : " + JSON.stringify({
            unit: Unit.GetKey(this),
            position: Unit.GetPositionKey(this)
        }));
    }

    [Buildable, Fruit, Upgradable, Pulsing].forEach(function (Component) {
        var component = this.findComponent(Component);
        if (component) {
            component.buildingComplete();
        }
    }.bind(this));

    var rewards = this.getCreatePrize();
    var rewardList = new RewardsList(rewards, { event: cleverapps.EVENTS.EARN.OTHER });
    rewardList.receiveRewards();
    rewardList.collectRewardsAnimation(this, {
        delay: 700
    });

    Map2d.mapEvent(Map2d.BUILD, {
        affected: this,
        time: cleverapps.parseInterval(this.getData().creatable ? "0 seconds" : this.getData().buildable)
    });

    Map2d.currentMap.onUnitFresh(this);
    Map2d.currentMap.onUnitAvailable(this);

    var mission = cleverapps.missionManager.findLocalPass();
    if (mission && Mission.TYPE_BUILDPASS === mission.type) {
        if (!mission.logic.isAllTasksCompleted()) {
            this.setPoints(PassMissionLogic.BuildableUnitPoints(this));
        }
    }

    cleverapps.travelBook.updateBuilt();

    this.save();
};

Unit.prototype.getFamily = function () {
    return Families[this.code];
};

Unit.prototype.isLast = function () {
    return (this.getFamily().units.length - 1) === this.stage;
};

Unit.prototype.isCastle = function () {
    return this.getType() === "resource" && this.isLast();
};

Unit.getPreviousStagesTargets = function (code, stage) {
    return cleverapps.arrayFill(stage).map(function (value, index) {
        return { code: code, stage: index };
    });
};

Unit.getAllStagesTargets = function (code) {
    return Unit.getPreviousStagesTargets(code, Families[code].units.length);
};

Unit.prototype.setPoints = function (amount) {
    this.points = amount;
    this.save();

    if (amount) {
        this.onShowPoints();
    } else {
        this.onHidePoints();
    }
};

Unit.prototype.claimPoints = function () {
    if (!this.points) {
        return;
    }

    var points = this.points;
    delete this.points;

    this.save();

    var mission = cleverapps.missionManager.findLocalPass();
    if (!mission) {
        this.onHidePoints();
        return;
    }

    var reward = new Reward("mission", {
        missionType: mission.type,
        amount: points
    });
    reward.receiveReward();

    this.onClaimPoints(function () {
        reward.onAnimationFinished();
        Game.currentGame.bpPointsPlanner.spawnOfferUnit();
    });
};

Unit.prototype.squeeze = function (callback) {
    if (this.getData().squeezable !== false) {
        this.onSqueeze();
    }

    if (callback) {
        this.clearSqueeze();
        this.squeezeTimeout = setTimeout(callback, Unit.SQUEEZE_DURATION);
    }
};

Unit.prototype.getDnDAccepted = function (targetUnit) {
    this.onGetDnDAccepted(targetUnit);
    this.remove(true);
};

Unit.prototype.animateOutOfSync = function () {
    if (cleverapps.config.name === "wondermerge"
        && Families[this.code].type === "resource"
        && this.getData().buildable) {
        return true;
    }

    if (Families[this.code].type === "drhero" && this.stage === 0) {
        return true;
    }

    return false;
};

Unit.prototype.showAdditionalViews = function () {
    if (this.additionalViewsHidden) {
        this.additionalViewsHidden = false;

        if (this.view) {
            this.view.showAdditionalViews();
        }
    }
};

Unit.prototype.hideAdditionalViews = function () {
    if (!this.additionalViewsHidden) {
        this.additionalViewsHidden = true;

        if (this.view) {
            this.view.hideAdditionalViews();
        }
    }
};

Unit.prototype.clearAnimations = function () {
    this.components.forEach(function (component) {
        if (component.clearAnimations) {
            component.clearAnimations();
        }
    });
};

Unit.prototype.equals = function (other) {
    return Unit.Equals(this, other);
};

Unit.prototype.findGreeters = function () {
    return this.components.filter(function (component) {
        return component.getGreeting && component.getGreeting();
    });
};

Unit.prototype.findAcceptorComponent = function (targets, options) {
    targets = cleverapps.toArray(targets);

    for (var i = 0; i < this.components.length; ++i) {
        var component = this.components[i];
        if (component.canAcceptDraggable && targets.some(function (target) {
            return component.canAcceptDraggable(target, options);
        })) {
            return component;
        }
    }
};

Unit.prototype.wantsToGreet = function () {
    return this.findGreeters().length > 0;
};

Unit.prototype.makeGreetings = function (f) {
    var greetings = this.findGreeters().map(function (greeter) {
        return greeter.getGreeting();
    });

    if (greetings.length) {
        cleverapps.focusManager.compound(f, greetings);
    } else {
        f();
    }
};

Unit.prototype.block = function (duration) {
    this.counter++;

    cleverapps.timeouts.setTimeout(function () {
        this.counter--;
    }.bind(this), duration);
};

Unit.prototype.isBlocked = function () {
    return this.counter > 0;
};

Unit.prototype.getConsoleInfo = function () {
    return {
        code: this.code,
        stage: this.stage,
        x: this.x,
        y: this.y,
        name: cleverapps.unitsLibrary.getUnitName(this)
    };
};

Unit.prototype.isCloseToMapLeft = function () {
    var centerCell = Map2d.currentMap.getScreenCenterCell();
    return Map2dInnerView.IsoToScreen(this.x, this.y).x < Map2dInnerView.IsoToScreen(centerCell.x, centerCell.y).x;
};

Unit.Equals = function (unit1, unit2) {
    return unit1 && unit2 && unit1.code !== undefined && unit1.stage !== undefined
        && unit1.code === unit2.code && unit1.stage === unit2.stage;
};

Unit.SameLine = function (unit1, unit2) {
    return unit1 && unit2 && unit1.code !== undefined && unit1.code === unit2.code;
};

Unit.CalcSellPrice = function (unit) {
    var price = function (stage) {
        return Math.round((stage + 1) * Math.sqrt(stage + 1));
    };

    if (!Families[unit.code]) {
        cleverapps.throwAsync("No family for code " + unit.code);
        return 0;
    }

    if (unit.code === "unknown") {
        return 0;
    }

    var family = Families[unit.code];

    switch (family.type) {
        case "source": case "xmresource": case "searesource": case "drgrowing": case "seagrowing":
        case "clpet": case "clpetrare": case "clpetlegend": case "thanksgiving":
            return 0;
        case "fruit": case "seafruit": case "clfruit": case "drfruit":
            if (family.units[unit.stage].collectible) {
                return 0;
            }
            return price(unit.stage);
        case "chest": case "drchest": case "seachest": case "hlchest": case "rpchest": case "xmchest": case "eachest": case "clchest":
            return unit.findComponent(Chest).calcSellPrice();
    }

    switch (unit.code) {
        case "worker": case "magicplant": case "crystal":
            return price(unit.stage + 1);
        case "xmmagicplant": case "drmagicplant": case "seamagicplant":
            return price(unit.stage - 2);
    }

    return price(unit.stage - 1);
};

Unit.CalcCupStars = function (unit) {
    var amount = unit.stage;

    if (unit.stage >= 3) {
        amount = Math.floor(Math.pow(2.1, unit.stage - 3)) * 10;
    }

    return amount;
};

Unit.IsApplicable = function (target, unit) {
    if (Array.isArray(target)) {
        return unit && target.some(function (targetItem) {
            return Unit.IsApplicable(targetItem, unit);
        });
    }

    var type = unit.getType ? unit.getType() : unit.type;

    return !target || (target.type === undefined || target.type === type)
        && (target.code === undefined || target.code === unit.code)
        && (target.stage === undefined || target.stage === unit.stage);
};

Unit.GetKey = function (unit) {
    return unit.code + "_" + unit.stage;
};

Unit.GetPositionKey = function (unit) {
    return unit.x + "_" + unit.y;
};

Unit.GetShape = function (unit) {
    var data = unit && Families[unit.code] && Families[unit.code].units[unit.stage];
    return data && data.multicell || FamiliesHelper.ShapeCell;
};

Unit.GetDropType = function (code) {
    var type = Families[code] && Families[code].dropType;
    return type !== undefined ? type : FamiliesHelper.Drop.Default;
};

Unit.ParseKey = function (key) {
    key = key.split("_");

    if (key.length !== 2) {
        return;
    }

    return {
        code: key[0],
        stage: cleverapps.castType(key[1])
    };
};

Unit.findUnit = function (units, searchUnit) {
    searchUnit = searchUnit || {};
    for (var i = 0; i < units.length; ++i) {
        var unit = units[i];
        if ((searchUnit.code === undefined || unit.code === searchUnit.code) && (searchUnit.stage === undefined || unit.stage === searchUnit.stage)) {
            return unit;
        }
    }
};

Unit.RepairDeletedStages = function (code, stage) {
    if (!Families[code]) {
        cleverapps.throwAsync("RepairDeletedStages wrong code - " + code);
        return stage;
    }

    var amountDeletedStagesBefore = 0;
    for (var i = 0; i < stage; i++) {
        if (Families[code].units[i] && Families[code].units[i].deleted) {
            amountDeletedStagesBefore++;
        }
    }

    stage += amountDeletedStagesBefore;
    if (stage >= Families[code].units.length) {
        stage = Families[code].units.length - 1;
    }

    if (Families[code].units[stage].deleted) {
        stage = Unit.calcNewStageThenDeleted(code, stage);
    }

    return stage;
};

Unit.calcNewStageThenDeleted = function (code, stage) {
    var maxIncStage = Families[code].units.length - 3;
    if (Families[code].type === "resource") {
        maxIncStage--;
    }
    if (stage <= maxIncStage) {
        return stage + 1;
    }
    return stage - 1;
};

Unit.SQUEEZE_DURATION = 200;
Unit.MINIMAL_SELL_LEVEL = 6;
Unit.MINIMAL_SELL_FOG = "fog3";
