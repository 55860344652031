/**
 * Created by vladislav on 25/10/2022
 */

QuestsConfig.rapunzel2 = {
    rprapunzel_7_2: {
        trigger: { unit: { code: "rprapuncust", stage: 0 } },
        tutorial: "rapunzel2_doll",
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rprapuncust", stage: 1 }
        }
    },
    rapunzel2_chop: {
        trigger: { unit: { code: "rprapuncust", stage: 1 } },
        quest: {
            type: Map2d.MINE,
            unit: { code: "rpdollsource", stage: 0 }
        }
    },
    rptelescope_open: {
        trigger: { unit: { code: "rprapuncust", stage: 1 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rptelescope", stage: 0 }
        }
    },

    rpcentaur_0_2: {
        trigger: { unit: { code: "rpcentaur", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rptrolley", stage: 0 }
        },
        dynamic: {
            getIcon: function () {
                return UnitView.getUnitImage({ code: "rptrolley", stage: 0 }, { preferStatic: true });
            }
        }
    },
    rpprincehero_1: {
        trigger: { unit: { code: "rpprincehero", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpprincehero", stage: 1 }
        }
    },
    rpprincehero_2: {
        trigger: { unit: { code: "rpprincehero", stage: 1 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpprincehero", stage: 2 }
        }
    },
    rpprincehero_3: {
        trigger: { unit: { code: "rpprincehero", stage: 2 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpprincehero", stage: 3 }
        }
    },
    rpprincehero_4: {
        trigger: { unit: { code: "rpprincehero", stage: 3 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpprincehero", stage: 4 }
        }
    },
    rpprincehero_5: {
        trigger: { unit: { code: "rpprincehero", stage: 4 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpprincehero", stage: 5 }
        }
    },
    rpprincehero_6: {
        trigger: { unit: { code: "rpprincehero", stage: 5 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpprincehero", stage: 6 }
        }
    },
    rpprincehero_7: {
        trigger: { unit: { code: "rpprincehero", stage: 0 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpprincehero", stage: 7 }
        }
    }
};