/**
 * Created by andrey on 21.12.2021.
 */

var GameDataSaver = function (key) {
    this.key = key;
};

GameDataSaver.prototype.save = function (data) {
    var current = cleverapps.dataLoader.load(DataLoader.TYPES.GAME_DATA) || {};
    current[this.key] = data;
    cleverapps.dataLoader.save(DataLoader.TYPES.GAME_DATA, current);
};

GameDataSaver.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.GAME_DATA);
    return data && data[this.key];
};

GameDataSaver.prototype.add = function (data) {
    var current = this.load() || {};
    current = Object.assign({}, current, data);
    this.save(current);
};

GameDataSaver.prototype.clear = function () {
    this.save();
};

GameDataSaver.prototype.loadAndClear = function () {
    var data = this.load();
    if (data) {
        this.clear();
    }
    return data;
};

GameDataSaver.Reset = function () {
    cleverapps.dataLoader.remove(DataLoader.TYPES.GAME_DATA);
};

GameDataSaver.Create = function (key) {
    var slot = Game.currentGame && Game.currentGame.slot || Meta.SLOT_MAIN;
    return new GameDataSaver(key + slot);
};