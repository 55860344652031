/**
 * Created by r4zi4l on 04.05.2021
 */

var LivesFeastMissionWindow = FeastMissionWindow.extend({
    onWindowLoaded: function (mission) {
        this._super({
            mission: mission,
            name: "LivesFeastMissionWindow",
            controls: ["MenuBarGoldItem", "MenuBarLivesItem", "MenuBarWorkersItem"],
            notCloseByTouchInShadow: true
        });
    },

    getPerson: function () {
        return {
            role: cleverapps.config.name === "hustlemerge" ? "dwarf" : "warlock",
            emotion: "happy"
        };
    },

    getGuideOptions: function () {
        return {
            name: "LivesFeastGuideWindow",
            bundle: bundles.livesfeastguidewindow
        };
    }
});