/**
 * Created by Denis Kuzin on 15 february 2022
 */

var ClansJoinTab = cc.Node.extend({
    ctor: function (options) {
        this._super();

        this.setContentSize2(options.width, options.height);

        this.createTableBg();
        this.createTable();
        this.createTransparentBorder();
        this.createSearch();
        this.createNewClanButton();
        this.createAutofitButton();

        cleverapps.clans.onClansListRefreshed = this.createListener(this.createTable.bind(this));
    },

    createTableBg: function () {
        var styles = cleverapps.styles.ClansJoinTab;

        var rect = cc.rectSubPadding(cc.rect(0, 0, this.width, this.height), cc.padding(styles.padding));

        var tableBg = this.tableBg = cleverapps.UI.createScale9Sprite(bundles.clan.frames.table_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        tableBg.setContentSize2(rect.width, rect.height);
        tableBg.setAnchorPoint(0, 0);
        tableBg.setPositionRound(rect.x, rect.y);
        this.addChild(tableBg);

        var foreground = cleverapps.UI.createScale9Sprite(bundles.clan.frames.table_fg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        foreground.setContentSize2(tableBg.width, tableBg.height);
        foreground.setAnchorPoint(0, 0);
        foreground.setPositionRound(0, 0);
        tableBg.addChild(foreground, 1);
    },

    createTable: function () {
        var styles = cleverapps.styles.ClansJoinTab.table;

        var rect = cc.rectSubPadding(cc.rect(0, 0, this.tableBg.width, this.tableBg.height), cc.padding(styles.padding));

        if (this.tableView) {
            this.tableView.removeFromParent();
        }

        var results = cleverapps.clans.listClans().map(function (clan, index) {
            return {
                id: clan.id,
                clan: clan,
                place: index + 1,
                emblem: clan.emblem,
                text: clan.name
            };
        });

        var table = new cleverapps.Table();
        var tableView = this.tableView = table.createTableView({
            id: "joinClans",
            data: results,
            height: rect.height,
            bg: false,
            rowConstructor: ClansJoinRow,
            innerPadding: styles.innerPadding
        });
        tableView.setPositionRound(rect.x + rect.width / 2, rect.y + rect.height / 2);
        this.tableBg.addChild(tableView);
    },

    createTransparentBorder: function () {
        if (!bundles.clans_window.frames.transparent_border) {
            return;
        }

        var styles = cleverapps.styles.ClansJoinTab.transparentBorder;

        var transparentBorder = cleverapps.UI.createScale9Sprite(bundles.clans_window.frames.transparent_border, cleverapps.UI.Scale9Rect.TwoPixelXY);
        transparentBorder.setContentSize(styles.width, styles.height);
        transparentBorder.setPositionRound(styles);
        this.addChild(transparentBorder);
    },

    createSearch: function () {
        var styles = cleverapps.styles.ClansJoinTab.search;

        var bg = cleverapps.UI.createScale9Sprite(bundles.clans_window.frames.search_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(styles.bg.width, styles.bg.height);

        var placeholder = cleverapps.UI.generateOnlyText("ClansJoinTab.search_placeholder", cleverapps.styles.FONTS.CLAN_EDIT_TEXT);
        placeholder.fitTo(styles.placeholder.width);
        placeholder.setPositionRound(styles.placeholder);
        placeholder.setOpacity(140);
        bg.addChild(placeholder);

        var editBox = this.editBox = new cc.EditBox(cc.size(styles.editbox.width, styles.editbox.height), undefined, true);
        editBox.setMaxLength(styles.editbox.maxLength);
        editBox.setString(" ");
        editBox.setDelegate(this);
        editBox.stayOnTop(true);
        editBox.setTouchEnabled();
        editBox.setFontSize(cleverapps.styles.FONTS.CLAN_EDIT_TEXT.size);
        editBox.setFontColor(cleverapps.styles.FONTS.CLAN_EDIT_TEXT.color);
        editBox.setFontName(cleverapps.UI.getFontName());
        editBox.setString("");
        editBox.setPositionRound(styles.editbox);
        editBox.customPlaceholder = placeholder;
        bg.addChild(editBox);

        bg.setPositionRound(styles.bg);
        this.addChild(bg);

        var searchButton = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            type: cleverapps.styles.UI.Button.Images.button_blue,
            text: "%%",
            icons: {
                "%%": bundles.clans_window.frames.magnifier_icon
            },
            onClicked: this.startSearch.bind(this)
        });
        searchButton.setPositionRound(styles.button);
        this.addChild(searchButton);
    },

    startSearch: function () {
        var teamName = this.editBox.getString();
        teamName = teamName.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, "");

        if (this.lastTeamName !== teamName) {
            this.lastTeamName = teamName;

            this.editBox.stayOnTop(false);
            this.editBox.setFontSize(cleverapps.styles.FONTS.CLAN_EDIT_TEXT.size);
            this.editBox.setFontColor(cleverapps.styles.FONTS.CLAN_EDIT_TEXT.color);
            this.editBox.setFontName(cleverapps.UI.getFontName());

            cleverapps.clans.search(teamName, function () {
                this.editBox.stayOnTop(true);
            }.bind(this));
        }
    },

    createNewClanButton: function () {
        var styles = cleverapps.styles.ClansJoinTab.createClanButton;

        var text = cleverapps.UI.generateOnlyText("Create", cleverapps.styles.FONTS.BUTTON_TEXT);
        var plus = new cc.Sprite(bundles.clans_window.frames.plus_icon);
        var content = new cleverapps.Layout([text, plus], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        var createClanButton = new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            type: styles.type,
            content: content,
            onClicked: function () {
                new AddClanWindow();
            }
        });
        createClanButton.setPositionRound(styles);
        this.addChild(createClanButton);
    },

    createAutofitButton: function () {
        var styles = cleverapps.styles.ClansJoinTab;

        var text = cleverapps.UI.generateOnlyText("ClansJoinTab.autoFitButton", cleverapps.styles.FONTS.BUTTON_TEXT);
        var icon = new cc.Sprite(bundles.clans_window.frames.team_icon);
        var content = new cleverapps.Layout([icon, text], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        var autofitButton = new cleverapps.UI.Button({
            width: styles.autofitButton.width,
            height: styles.autofitButton.height,
            type: cleverapps.styles.UI.Button.Images.button_green,
            content: content,
            onClicked: function () {
                cleverapps.clans.autofit();
            }
        });
        autofitButton.setPositionRound(styles.autofitButton);
        this.addChild(autofitButton);
    },

    onSelectTab: function () {
        this.editBox.stayOnTop(true);
    },

    onUnSelectTab: function () {
        this.editBox.stayOnTop(false);
    },

    editBoxEditingDidBegin: function (sender) {
        sender.customPlaceholder.setVisible(false);
    },

    editBoxEditingDidEnd: function (sender) {
        if (sender.getString() === "") {
            sender.customPlaceholder.setVisible(true);
        }
    },

    editBoxTextChanged: function (sender, text) {
        if (this._textBeforeReturn || text === "\n") {
            sender.setString(this._textBeforeReturn || "");
            this._textBeforeReturn = undefined;

            this.startSearch();
        }
    },

    editBoxReturn: function (sender) {
        this._textBeforeReturn = sender.getString();

        sender.setString("");
    }
});

cleverapps.styles.ClansJoinTab = {
    margin: 10,
    padding: {
        x: -30,
        top: -10,
        bottom: 100
    },

    createClanButton: {
        margin: 10,
        width: 260,
        height: 80,
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -16 },
        type: cleverapps.styles.UI.Button.Images.button_blue
    },

    autofitButton: {
        width: 300,
        height: 90,
        x: { align: "center" },
        y: { align: "bottom", dy: -5 }
    },

    table: {
        padding: {
            x: 0,
            top: 130,
            bottom: 20
        }
    },

    search: {
        bg: {
            width: 630,
            height: 84,
            x: { align: "left", dx: 95 },
            y: { align: "top", dy: -13 }
        },

        editbox: {
            width: 585,
            height: 72,
            maxLength: 25,
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: 0 }
        },

        placeholder: {
            width: 580,
            x: { align: "center", dx: 10 },
            y: { align: "center", dy: 0 }
        },

        button: {
            width: 100,
            height: 100,
            x: { align: "left", dx: 20 },
            y: { align: "top", dy: -5 }
        }
    },

    transparentBorder: {
        width: 1300,
        height: 41,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -90 }
    }
};