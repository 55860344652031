/**
 * Created by andrey on 13.05.2022.
 */

var UserClan = function () {
    Clan.apply(this, arguments);

    this.loadSave();
    this.load();
};

UserClan.prototype = Object.create(Clan.prototype);
UserClan.prototype.constructor = UserClan;

UserClan.prototype.loadRequest = function (onSuccess, onFailure, options) {
    cleverapps.RestClient.get("/clans/userclan/" + encodeURIComponent(connector.platform.getUserID()), {}, onSuccess, onFailure, options);
};

UserClan.prototype.changeUserId = function (oldUserId, newUserId) {
    this.setOldUserId(oldUserId);

    cleverapps.RestClient.post("/clans/changeuserid", { oldUserId: oldUserId, newUserId: newUserId }, function (data) {
        console.log("changeUserId result", data);

        if (!data || !data.error) {
            this.setOldUserId(undefined);
        }
    }.bind(this));
};

UserClan.prototype.loadSave = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.USER_CLAN);
    this.oldUserId = data && data.oldUserId;

    if (this.oldUserId === connector.platform.getUserID()) {
        this.setOldUserId(undefined);
    }

    if (this.oldUserId) {
        this.changeUserId(this.oldUserId, connector.platform.getUserID());
    }
};

UserClan.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.USER_CLAN, { oldUserId: this.oldUserId });
};

UserClan.prototype.setOldUserId = function (oldUserId) {
    if (this.oldUserId !== oldUserId) {
        this.oldUserId = oldUserId;
        this.save();
    }
};
