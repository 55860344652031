/**
 * Created by r4zi4l on 08.02.2021
 */

var MergeAdviceBaseLogic = function (options) {
    options = options || {};

    this.region = options.region;
    this.preferCell = options.preferCell;

    if (options.cells) {
        this.cells = cleverapps.toArray(options.cells);
    }

    var targets = cleverapps.toArray(options.targets);
    if (targets && targets.length > 0) {
        this.targets = targets;
    }
};

MergeAdviceBaseLogic.prototype.findMove = function () {

};

MergeAdviceBaseLogic.prototype.listAvailableUnits = function () {
    var units;

    if (this.cells) {
        units = this.cells.map(function (cell) {
            return Map2d.currentMap.getUnit(cell.x, cell.y);
        }).filter(function (unit) {
            return unit;
        });
    } else if (this.region) {
        units = Map2d.currentMap.listAvailableUnitsInRegion(this.region);
    } else if (this.preferCell) {
        var preferUnit = Map2d.currentMap.getUnit(this.preferCell.x, this.preferCell.y);
        units = preferUnit ? [preferUnit] : Map2d.currentMap.listAvailableUnits();
    } else {
        units = Map2d.currentMap.listAvailableUnits();
    }

    if (this.targets) {
        var groups = {};
        this.targets.forEach(function (target) {
            groups[Unit.GetKey(target)] = [];
        });

        units.forEach(function (unit) {
            var key = Unit.GetKey(unit);
            if (groups[key]) {
                groups[key].push(unit);
            }
        });

        units = this.targets.reduce(function (array, target) {
            return array.concat(groups[Unit.GetKey(target)]);
        }, []);
    }

    if (this.priority) {
        units = units.sort(function (a, b) {
            return this.getPriorityIndex(a) - this.getPriorityIndex(b);
        }.bind(this));
    }

    return units;
};

MergeAdviceBaseLogic.prototype.isApplicable = function (unit) {
    return !this.targets || Unit.IsApplicable(this.targets, unit);
};

MergeAdviceBaseLogic.prototype.isPriorityUnit = function (unit) {
    return this.getPriorityIndex(unit) < 10000;
};

MergeAdviceBaseLogic.prototype.getPriorityIndex = function (unit) {
    return this.priority && this.priority[Unit.GetKey(unit)] || 10000;
};
