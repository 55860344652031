/**
 * Created by iamso on 05.10.2021
 */

var SeasonStage = cc.Node.extend({
    ctor: function (season, unitData) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.styles.SeasonStage);

        this.season = season;

        var frame = bundles.season_window.frames.stage_unopen_bg;
        if (season.isFresh(unitData.stage)) {
            frame = bundles.season_window.frames.stage_unopen_bg;
        } else if (season.isOpened(unitData.stage)) {
            frame = bundles.season_window.frames.stage_open_bg;
        }
        var bg = this.bg = cleverapps.UI.createScale9Sprite(frame, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(cleverapps.styles.SeasonStage.bg);
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(bg);

        this.unitData = unitData;

        this.createUnit();
        this.updateState();
    },

    createUnit: function () {
        var styles = cleverapps.styles.SeasonStage.task;

        var bg = cleverapps.UI.createScale9Sprite(bundles.merge.frames.icon_background_light, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(styles);
        this.addChild(bg);
        bg.setPositionRound(styles);

        var unitImg;
        if (!this.season.isOpened(this.unitData.stage) && !this.season.isOpened(this.unitData.stage - 1)) {
            unitImg = new cc.Sprite(bundles.merge.frames.unit_unknown);
        } else {
            unitImg = UnitView.getUnitImage(this.unitData, {
                alignAnchorX: true
            });
        }
        this.unitIcon = unitImg;
        bg.addChild(unitImg);
        unitImg.setPositionRound(styles.item);

        var createText = function (msg, font, pos) {
            var nameText = cleverapps.UI.generateOnlyText(msg, font);
            nameText.setDimensions(styles.text.width, 0);
            nameText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);
            nameText.fitTo(undefined, styles.text.height);
            this.addChild(nameText);
            nameText.setPositionRound(pos);
        }.bind(this);

        createText(cleverapps.unitsLibrary.getUnitName(this.unitData), cleverapps.styles.FONTS.SEASONAL_REWARD_TEXT, styles.text.name);

        cleverapps.UI.fitToBox(unitImg, {
            width: styles.width * 0.8,
            height: styles.height * 0.8,
            maxScale: styles.maxScale
        });
    },

    freshStageAnimation: function () {
        var baseScale = this.scale;
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.3, baseScale * 1.07).easing(cc.easeBackIn()),
            new cc.PlaySound(bundles.season_window.urls.new_stage_effect),
            new cc.CallFunc(function () {
                this.setDefaultColor();
                this.bg.setSpriteFrame(new cc.Sprite(bundles.season_window.frames.stage_fresh_bg).getSpriteFrame());

                var rewardData = this.season.getReward(this.unitData.stage);
                if (rewardData.unit) {
                    AnimationsLibrary.rays(this.rewardIcon, { skin: "white", size: cleverapps.styles.SeasonStage.rays.size });
                }
            }.bind(this)),
            new cc.ScaleTo(0.15, baseScale)
        ));
    },

    addReward: function () {
        this.removeReward();

        var styles = cleverapps.styles.SeasonStage.reward;
        var rewardData = this.season.getReward(this.unitData.stage);

        var reward = this.rewardIcon = UnitView.getUnitImage(rewardData.unit);
        cleverapps.UI.fitToBox(reward, {
            width: styles.width,
            height: styles.height,
            maxScale: styles.maxScale
        });
        this.addChild(reward);
        reward.setPositionRound(styles);

        var rewardAmount = this.rewardAmount = cleverapps.UI.generateImageText("x" + rewardData.unit.amount, cleverapps.styles.FONTS.SEASONAL_REWARD_TEXT);
        this.addChild(rewardAmount);
        rewardAmount.setPositionRound(styles.amount);
    },

    removeReward: function () {
        if (this.rewardIcon) {
            this.rewardIcon.removeFromParent();
            delete this.rewardIcon;
        }

        if (this.rewardAmount) {
            this.rewardAmount.removeFromParent();
            delete this.rewardAmount;
        }
    },

    addMark: function () {
        if (this.mark) {
            return;
        }

        var mark = this.mark = new cc.Sprite(bundles.season_window.frames.checkmark_stroke_png);
        this.addChild(mark);
        mark.setPositionRound(cleverapps.styles.SeasonStage.reward);
    },

    setDiscolor: function () {
        this.unitIcon.setOpacity(120);
        this.unitIcon.setColor(new cc.Color(0, 150, 255, 255));
        this.rewardIcon && this.rewardIcon.setOpacity(120);
        this.rewardIcon && this.rewardIcon.setColor(new cc.Color(0, 150, 255, 255));
        this.rewardAmount && this.rewardAmount.setOpacity(120);
    },

    setDefaultColor: function () {
        this.unitIcon.setOpacity(255);
        this.unitIcon.setColor(new cc.Color(255, 255, 255, 255));
        this.rewardIcon && this.rewardIcon.setOpacity(255);
        this.rewardIcon && this.rewardIcon.setColor(new cc.Color(255, 255, 255, 255));
        this.rewardAmount && this.rewardAmount.setOpacity(255);
    },

    updateState: function () {
        var opened = this.season.isOpened(this.unitData.stage);
        var fresh = this.season.isFresh(this.unitData.stage);
        var rewardData = this.season.getReward(this.unitData.stage);

        if (opened && !fresh || !rewardData.unit) {
            this.addMark();
            this.removeReward();
            this.setDefaultColor();
        } else {
            this.addReward();
            this.setDiscolor();
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SEASONAL_REWARD_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SEASONAL_NAME_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.SeasonStage = {
    height: 160,
    width: 980,

    bg: {
        height: 150,
        width: 970
    },

    rays: {
        size: 100
    },

    task: {
        height: 135,
        width: 135,
        maxScale: 1.0,
        x: { align: "left", dx: 15 },
        y: { align: "center", dy: -3 },

        item: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 4 }
        },

        text: {
            width: 490,
            height: 88,
            name: {
                x: { align: "left", dx: 210 },
                y: { align: "center", dy: 0 }
            }
        }
    },

    reward: {
        height: 135,
        width: 135,
        maxScale: 1.25,
        x: { align: "center", dx: 300 },
        y: { align: "center", dy: 0 },

        amount: {
            x: { align: "right", dx: -80 },
            y: { align: "center", dy: 0 }
        }
    }
};