/**
 * Created by olga on 14.11.2024
 */

var BaseLocation = function (locationId) {
    cleverapps.EventEmitter.call(this);
    this.locationId = locationId;
    this.isCompleted = false;

    var stored = cleverapps.meta.loadLocation(this.getSlotId());
    this.load(stored);
};

BaseLocation.prototype = Object.create(cleverapps.EventEmitter.prototype);
BaseLocation.prototype.constructor = Location;

BaseLocation.prototype.load = function () {
};

BaseLocation.prototype.getInfo = function () {
    return undefined;
};

BaseLocation.prototype.save = function () {
    cleverapps.meta.saveLocation(this);
};

BaseLocation.prototype.getSlotId = function () {
    return Meta.SLOT_MAIN;
};

BaseLocation.prototype.complete = function () {
    this.isCompleted = true;
};

BaseLocation.prototype.checkCompleted = function () {
    return this.isCompleted;
};

BaseLocation.prototype.onComplete = function (f) {
    f();
};

BaseLocation.prototype.onOpen = function (f) {
    f();
};

BaseLocation.prototype.gamePlayed = function (outcome, game) {
    if (outcome === GameBase.OUTCOME_VICTORY) {
        game.rewards.metaStar = this.amountLevelStars(game.level);
    }
};

BaseLocation.prototype.amountLevelStars = function () {
    return 0;
};

BaseLocation.prototype.isCurrentLocation = function () {
    return this.locationId === cleverapps.meta.currentLocationId;
};

BaseLocation.prototype.processChatMessage = function () {
};

BaseLocation.listLocationsIds = function () {
    return [0];
};

BaseLocation.listActiveLocationsIds = function (currentLocationId) {
    return [currentLocationId];
};
