/**
 * Created by r4zi4l on 20.01.2022
 */

var ResourceView = cc.Node.extend({
    ctor: function (info, unit) {
        this.unit = unit;

        var styles = cleverapps.styles.ResourceView;

        var resources = info.actions.map(function (action) {
            return new ResourceIcon(action, this);
        }, this);

        var content = new cleverapps.Layout(resources, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });

        this._super();
        this.setContentSize2(Math.max(styles.width, content.width), content.height);

        var background = cleverapps.UI.createScale9Sprite(bundles.merge.frames.background, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(this.width, this.height);
        background.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(background);

        var pointer = new cc.Sprite(bundles.merge.frames.pointer2);
        pointer.setPositionRound(styles.pointer.x, styles.pointer.y);
        this.addChild(pointer);

        content.setPositionRound(this.width / 2, this.height / 2);
        content.setLocalZOrder(1);
        this.addChild(content);
    },

    showDetails: function (icon, action) {
        if (!ResourceDetails.IsAvailable(action)) {
            return;
        }

        if (this.details && this.details.action === action) {
            this.extendDetails();
            return;
        }

        if (this.details) {
            this.details.removeFromParent();
        }

        var styles = cleverapps.styles.ResourceView.details;
        var iconPosition = this.convertToNodeSpace(icon.convertToWorldSpace(cc.p(icon.width / 2, icon.height / 2)));

        this.details = new ResourceDetails(action, this);
        this.addChild(this.details);

        this.details.setAnchorPoint(0.5, 0);
        this.details.setPositionRound(iconPosition.x, this.height + styles.topOffset);
    },

    extendDetails: function () {
        if (this.details) {
            this.details.stopAllActions();
        }
    },

    hideDetails: function () {
        if (this.details) {
            this.details.stopAllActions();
            this.details.runAction(new cc.Sequence(
                new cc.DelayTime(0.4),
                new cc.CallFunc(function () {
                    this.details.removeFromParent();
                    this.details = undefined;
                }.bind(this))
            ));
        }
    }
});

ResourceView.ACTION_PLANT = 1;
ResourceView.ACTION_HARVEST = 2;

cleverapps.styles.ResourceView = {
    width: 174,
    height: 130,
    margin: 16,

    padding: {
        x: 28,
        y: 10
    },

    pointer: {
        x: { align: "center" },
        y: 1
    },

    details: {
        topOffset: 5
    }
};
