/**
 * Created by r4zi4l on 15.03.2021
 */

var GeneratorGuideOptions = {
    name: "GeneratorGuideWindow",

    getGuideBundle: function () {
        return bundles.generatorguidewindow;
    },

    stagesContent: function () {
        var styles = cleverapps.styles.GeneratorGuideWindow;
        var code = this.unit.code.replace("generator", "source");

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.stage_1_shine, styles.light);
            this.addUnitIcon(container, this.unit.code, this.unit.stage, styles.generator);
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            styles.units.forEach(function (unitStyles) {
                this.addSprite(container, this.bundle.frames.stage_2_platform, unitStyles);
            }, this);
            styles.units.forEach(function (unitStyles) {
                var unitView = UnitView.getUnitImage({ code: code, stage: 2 }, { alignAnchorX: true });
                unitView.setPositionRound(unitStyles.x + styles.unit.dx, unitStyles.y + styles.unit.dy);
                unitView.setScale(styles.unit.scale);
                container.addChild(unitView);
            }, this);
        }.bind(this));

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.stage_3_shine, styles.light);
            this.addUnitIcon(container, code, 3, styles.result);
        }.bind(this));

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.GeneratorGuideWindow = {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        light: {
            x: 0,
            y: 0
        },

        generator: {
            x: 0,
            y: 0
        }
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        units: [
            {
                x: -100,
                y: 56
            },
            {
                x: 12,
                y: 2
            },
            {
                x: -89,
                y: -59
            }
        ],

        unit: {
            dx: 4,
            dy: 18,
            scale: 1
        }
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        light: {
            x: 0,
            y: 0
        },

        result: {
            x: 0,
            y: 0,
            scale: 1.4
        }
    }
};
