/**
 * Created by mac on 10/6/20
 */

var AdminLevelMiniature = {
    generate: function (adminLevel, size) {
        var bg = AdminLevelMiniature.default();

        var MiniatureGenerator = AdminLevelMiniature[cleverapps.config.name] || AdminLevelMiniature[cleverapps.config.type];
        if (MiniatureGenerator) {
            try {
                var miniature = MiniatureGenerator(adminLevel);
                cleverapps.UI.fitToBox(miniature, size || cleverapps.styles.AdminLevelMiniature);
                bg.addChild(miniature);
                miniature.setPositionRound(cleverapps.styles.AdminLevelMiniature);
            } catch (e) {
                console.log("Error in level miniature " + cleverapps.humanReadableNumber(adminLevel.episodeNo, adminLevel.levelNo), e);
            }
        }

        return bg;
    },

    battlefield: function (adminLevel) {
        var game = new FakeGame(adminLevel);

        var battlefieldView = new BattlefieldView();
        for (var x = 0; x < Battlefield.GRID.x / 2; x++) {
            for (var y = 0; y < Battlefield.GRID.y; y++) {
                battlefieldView.cells[x][y].setVisible(false);
                var squad = game.battlefield.findSquad(x, y);
                if (squad) {
                    squad.remove();
                }
            }
        }
        battlefieldView.scale = 1;

        cc.eventManager.removeListener(battlefieldView.setupDragListener);

        var wrap = new cc.Node();
        wrap.setContentSize2(
            battlefieldView.width / 2,
            battlefieldView.height + 2 * cleverapps.styles.BattlefieldView.additionalHeight
        );
        wrap.addChild(battlefieldView);
        battlefieldView.setPositionRound(-wrap.width, 0);

        return wrap;
    },

    merge: function (adminLevel) {
        var game = new FakeGame(adminLevel);

        var map = new Map2d(adminLevel.map, {
            units: game.level.meta.units,
            tiles: game.level.meta.tiles,
            visibleBox: adminLevel.visibleBox,
            terrains: adminLevel.terrains,
            field: adminLevel.field,
            waterBorder: 0
        });

        map.layers = [
            // map.layers[Map2d.LAYER_WATER],
            [[]],
            [[]],
            // [[]],
            map.layers[Map2d.LAYER_GROUND],
            [[]],
            [[]],
            [[]],
            [[]]
        ];

        game.map = map;

        var view = new Map2dInnerView(map);

        var wrap = new cc.Node();
        wrap.setContentSize2(view.width, view.height);
        wrap.setAnchorPoint(0.5, 0.5);

        view.setPositionRound({ align: "center" }, { align: "center" });

        wrap.addChild(view);

        view.setVisibleRect(cc.rect(0, 0, view.width, view.height));

        return wrap;
    },

    wordsoup: function (location) {
        var map = this.map = new Map2d(location.map, {
            tiles: location.tiles,
            units: location.units,
            visibleBox: location.visibleBox,
            regions: location.regions,
            decorators: location.decorators,
            terrains: location.terrains,
            field: location.field,
            families: location.families
        });
        Map2d.InsertDefaults(map, location.field);
        var view = new Map2dInnerView(map);

        var wrap = new cc.Node();
        wrap.setContentSize2(view.width, view.height);
        wrap.setAnchorPoint(0.5, 0.5);

        view.setPositionRound({ align: "center" }, { align: "center" });

        wrap.addChild(view);

        view.setVisibleRect(cc.rect(0, 0, view.width, view.height));

        return wrap;
    },

    default: function () {
        var bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_miniature_bg);
        bg.setContentSize2(cleverapps.styles.AdminLevelMiniature.bg);
        return bg;
    },

    tileGame: function (adminLevel, orientation) {
        new FakeGame(adminLevel);
        var table = Game.currentGame.table = cleverapps.config.type === "solitaire" ? new CardTable() : new TileTable();
        var screen = Game.currentGame.level.content.screens[0];
        var cards = screen.cards || screen.horizontalCards;
        if (orientation === undefined) {
            if (screen.horizontalCards && cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
                cards = screen.horizontalCards;
            }
        } else if (orientation === TileTable.ORIENTATION_HORIZONTAL) {
            cards = screen.horizontalCards;
        }

        table.setCards(cards, TileTable.ListMagicCards(screen), screen.maxValue);
        table.setRect();

        var view = new CardTableView(table);
        table.updateSize();
        table.flipNormalize();
        table.showCards(function () {}, true);

        cleverapps.UI.fitToBox(view, cleverapps.styles.AdminLevelMiniature);
        return view;
    },

    scramble: function (adminLevel) {
        var text = cleverapps.UI.generateOnlyText(adminLevel.hint, cleverapps.styles.FONTS.ADMIN_DATA_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);
        text.setVerticalAlignment(cc.VERTICAL_TEXT_ALIGNMENT_TOP);
        text.setDimensions(cleverapps.styles.AdminLevelMiniature.width, cleverapps.styles.AdminLevelMiniature.height / 2);
        return text;
    },

    differences: function (adminLevel) {
        var placeholder = new cc.Node();
        placeholder.setContentSize2(cleverapps.styles.AdminLevelMiniature);
        placeholder.setAnchorPoint(0.5, 0.5);

        var loading = new cc.Sprite(bundles.admin.frames.loading_png);
        placeholder.addChild(loading);
        loading.setPositionRound(placeholder.width / 2, placeholder.height / 2);

        var game = new FakeGame(adminLevel);
        var name = game.level.getImageBundle();
        if (bundles[name]) {
            cleverapps.bundleLoader.loadBundle(name, {
                onSuccess: function () {
                    var view = new cc.Sprite(bundles[name].urls.a);
                    var w = view.width;
                    var h = view.height;
                    var frame = new cc.Sprite(bundles[name].urls.a, cc.rect((w - h) / 2, 0, h, h));

                    cleverapps.UI.fitToBox(frame, cleverapps.styles.AdminLevelMiniature);
                    placeholder.addChild(frame);
                    frame.setPositionRound(placeholder.width / 2, placeholder.height / 2);

                    loading.removeFromParent();
                }
            });
        }

        return placeholder;
    },

    board: function (adminLevel) {
        var badWords = 0;
        var maxLength = 0;
        var mainWordsCount = 0;
        var words = adminLevel.words.map(function (word, id) {
            if (word.length > maxLength) {
                maxLength = word.length;
                mainWordsCount = 1;
                badWords = 0;
            } else if (word.length === maxLength) {
                mainWordsCount++;
            }
            var badWord = false;
            if ((word.length > 3 && word.lastIndexOf("ing") === word.length - 3) || word.lastIndexOf("s") === word.length - 1
                || word.lastIndexOf("ed") === word.length - 2) {
                badWord = true;
                badWords++;
            }
            return (id + 1) + ". " + word + (badWord ? " *" : "");
        }).join("\n");

        return cleverapps.UI.generateOnlyText(words, badWords === mainWordsCount ? cleverapps.styles.FONTS.ADMIN_RED_TEXT : cleverapps.styles.FONTS.ADMIN_DATA_TEXT);
    },

    bundles: {
        match3: function () {
            var result = [];

            if (typeof Editor !== "undefined") {
                result = result.concat(Editor.BUNDLES.filter(function (bundle) {
                    return bundles[bundle];
                }));
            }

            return result;
        }
    },

    match3: function (adminLevel) {
        var game = new FakeGame(adminLevel);

        var view = AdminLevelMiniature._match3(game, adminLevel);

        view.addCleaner(function () {
            if (Game.currentGame === game) {
                delete Game.currentGame;
            }
        });

        return view;
    },

    _match3: function (game, adminLevel) {
        var field = game.field;
        adminLevel.gameField = field;

        field.load(adminLevel);

        var fieldView = new cc.Node();
        fieldView.setAnchorPoint(0.5, 0.5);

        var cellIterator = function (cell) {
            if (cell.innerCell) {
                cellIterator(cell.innerCell);
            }
            var ViewClass = cell.getViewClass();
            var cellView = new ViewClass(cell);
            fieldView.addChild(cellView);
        };

        field.iterateCells(cellIterator);

        cleverapps.UI.wrap(fieldView);

        var view = new cc.Node();
        view.setContentSize(fieldView.width, fieldView.height);
        view.setAnchorPoint(0.5, 0.5);
        view.addChild(fieldView);

        fieldView.setPositionRound(view.width / 2, view.height / 2);
        fieldView.setAnchorPoint(0.5, 0.5);

        var floorView = new FloorView(field);
        floorView.setContentSize(view.width, view.height);
        floorView.setPositionRound(view.width / 2, view.height / 2);
        floorView.setLocalZOrder(-1);
        floorView.setAnchorPoint(0.5, 0.5);
        view.addChild(floorView);

        var tileIterator = function (tile) {
            var ViewClass = tile.getViewClass();
            var tileView = new ViewClass(tile);
            floorView.addChild(tileView);
        };

        field.iterateTiles(tileIterator);

        var barriersView = new BarriersView(field, BaseCellView.alignInTheGrid);
        barriersView.setContentSize(view.width, view.height);
        barriersView.setPositionRound(view.width / 2, view.height / 2);
        barriersView.setLocalZOrder(-1);
        barriersView.setAnchorPoint(0.5, 0.5);
        view.addChild(barriersView);

        for (var i = 0; i < Field.SIZE; i++) {
            for (var j = 0; j < Field.SIZE; j++) {
                if (field.cells[i][j]) {
                    field.cells[i][j].onAfterExplodeListener = function () {};
                }
                if (field.floor[i][j]) {
                    field.floor[i][j].onAfterExplodeListener = function () {};
                }
            }
        }

        return view;
    },

    blocks: function (adminLevel) {
        var grid = new Grid(adminLevel.content);
        var view = new GridView(grid);
        view.pointHandler.remove();
        cleverapps.UI.fitToBox(view, cleverapps.styles.AdminLevelMiniature);
        return view;
    }
};

AdminLevelMiniature.tile3 = AdminLevelMiniature.solitaire = AdminLevelMiniature.tileGame;

cleverapps.styles.AdminLevelMiniature = {
    width: 125,
    height: 125,

    x: { align: "center", dx: 0 },
    y: { align: "center", dy: 0 },

    bg: {
        width: 135,
        height: 135
    }
};
