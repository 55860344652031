/**
 * Created by mac on 7/26/18
 */

var RewardWindow = CleverappsWindow.extend({
    ctor: function (reward, options) {
        this.options = options = options || {};
        this.reward = new RewardsList(reward, options);
        this.reward.receiveRewards();

        cleverapps.dataLoader.processSaveQueue();

        this._super();
    },

    onWindowLoaded: function () {
        this._super({
            title: this.options.title || "RewardWindow.title",
            name: this.options.name || "rewardwindow",
            content: this.createContent(),
            noBackground: true,
            tapToContinueText: "Window.ClickToClaim",
            shareId: this.options.shareId || this.reward.isBigReward() && "reward",
            showSound: this.options.sound || bundles.main.urls.reward_window_effect,
            fireworks: this.options.fireworks,
            closeButtonManual: true
        });

        this.adjustRewardList();

        this.rewardsList.showUp(this.delayedShowUp.bind(this));
    },

    adjustRewardList: function () {
        var width = this.rewardsList.width * this.rewardsList.scale;
        if (width > this.window.width) {
            this.rewardsList.setScale(this.rewardsList.scale * this.window.width / width);
        }
    },

    createContent: function () {
        var styles = cleverapps.styles.RewardWindow;

        if (this.options.text) {
            var text = this.rewardText = cleverapps.UI.generateTTFText(this.options.text, cleverapps.styles.FONTS.REWARDWINDOW_TITLE_TEXT);
            text.setWrapWidth(styles.textWidth);
            text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        }

        this.rewardsList = this.createRewardsList();

        return new cleverapps.Layout([text, this.rewardsList].filter(Boolean), {
            padding: styles.padding,
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    getPerson: function () {
        return {
            role: "hero",
            emotion: "happy"
        };
    },

    createRewardsList: function () {
        var styles = cleverapps.styles.RewardWindow.rewards;
        return new RewardsListComponent(this.reward, {
            font: cleverapps.styles.FONTS.REWARDWINDOW_VALUE_TEXT,
            iconWrap: styles.iconWrap,
            margin: styles.margin,
            textMargin: styles.textMargin,
            toMainWorld: this.options.toMainWorld,
            shine: true,
            columns: "auto",
            fitToBox: styles.fitToBox[cleverapps.resolution.mode],
            filterCustomAnimation: true
        });
    },

    onClose: function () {
        if (this.reward) {
            this.reward.onAnimationFinished();
            this.reward = undefined;
        }
    },

    createReceiveAction: function () {
        return new cc.TargetedAction(this.rewardsList, this.rewardsList.receiveRewardsAction());
    },

    createCloseAction: function () {
        return new cc.Sequence(
            new cc.CallFunc(function () {
                if (this.persons) {
                    this.persons.hide();
                    delete this.persons;
                }

                if (this.rewardText) {
                    this.rewardText.runAction(new cc.FadeOut(0.3));
                }

                if (this.windowTitle) {
                    this.windowTitle.hide();
                }
            }.bind(this)),
            new cc.CallFunc(function () {
                if (this.styles.shadow) {
                    cleverapps.windows.trigger("hideShadow");
                }
            }.bind(this)),
            new cc.DelayTime(0.3),
            new cc.FadeOut(0.3),
            new cc.CallFunc(this.hideSelf.bind(this))
        );
    },

    beforeCloseAnimation: function (callback) {
        if (!this.reward) {
            callback();
            return;
        }

        var reward = this.reward;
        this.reward = undefined;

        var receiveAction = this.createReceiveAction();
        var closeAction = this.createCloseAction();

        var actions, index = reward.underShadowRewardIndex();
        if (index !== -1) {
            actions = new cc.Spawn(
                receiveAction,
                new cc.Sequence(
                    new cc.DelayTime(0.2 + 0.3 * index),
                    closeAction
                )
            );
        } else {
            actions = new cc.Sequence(receiveAction, closeAction);
        }
        actions = new cc.Sequence(actions, new cc.CallFunc(callback || function () {}));

        this.runAction(actions);
    },

    onPressed: function () {
        this.close();
    },

    listBundles: function () {
        return this.reward.listBundles();
    },

    getPreferredBundles: function () {
        if (cleverapps.config.type === "merge") {
            return ["game", "main"];
        }
        return this._super();
    }
});

RewardWindow.createPurchaseWindow = function (reward) {
    return new RewardWindow(reward, {
        title: "PurchaseRewardWindow.title",
        name: "purchaserewardwindow",
        event: cleverapps.EVENTS.EARN.PURCHASE
    });
};

RewardWindow.createCupsWindow = function (reward, type) {
    return new RewardWindow(reward, {
        name: "cupsrewardwindow",
        title: "Cups." + type + ".title",
        text: "Cups." + type + ".reward",
        event: cleverapps.EVENTS.EARN.OTHER
    });
};

RewardWindow.createDailyTaskWindow = function (task) {
    return new RewardWindow(task.getReward(), {
        title: "DailyTaskRewardWindow.title",
        name: "dailytaskrewardwindow",
        text: "DailyTaskRewardWindow.text",
        toMainWorld: cleverapps.travelBook.isExpedition(),
        event: cleverapps.EVENTS.EARN.DAILY
    });
};

RewardWindow.createMissionWindow = function (reward, missionData, options) {
    options = options || {};

    return new RewardWindow(reward, {
        shareId: options.shareId || missionData.rewardShareId,
        title: missionData.rewardTitle,
        text: missionData.rewardText,
        toMainWorld: missionData.mainWorldReward,
        event: options.event || cleverapps.EVENTS.EARN.MISSION
    });
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    REWARDWINDOW_TITLE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    REWARDWINDOW_VALUE_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.RewardWindow = {
    textWidth: 800,
    margin: 40,

    rewards: {
        iconWrap: {
            height: 130
        },

        margin: {
            x: 100,
            y: 60
        },

        fitToBox: [
            { width: 650, height: 650 },
            { width: 900, height: 650 },
            { width: 900, height: 650 }
        ],

        textMargin: 0
    },

    padding: {
        top: 60,
        bottom: 60
    }
};
