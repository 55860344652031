/**
 * Created by Vladislav on 18.09.2024.
 */

cleverapps.InitByType["merge"] = function () {
    cleverapps.gameLevel = new GameLevel();

    Families.initialize();
    Pocket.initialize();
    Landmarks.initialize();

    if (typeof StopHustlemergeFeatures !== "undefined") {
        StopHustlemergeFeatures();
    }

    if (["mergecraft", "wondermerge", "fairy"].indexOf(cleverapps.config.name) !== -1) {
        cleverapps.growthFund = new GrowthFund();
    }
    cleverapps.unitSavers = new UnitSavers();
    cleverapps.fogsSavers = new FogSavers();

    cleverapps.travelBookHint = new TravelBookHint();

    cleverapps.unitsShop = new UnitsShop();

    cleverapps.userClan = new UserClan();
    cleverapps.clans = new Clans();
    cleverapps.clanMessages = new ClanMessages();
};