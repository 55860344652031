/**
 * Created by razial on 07.11.2022
 */

cleverapps.overrideStyles(cleverapps.styles.Decorators, {
    "xmas_window_right_top": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "up_right",
        x: { align: "right", dx: 20 },
        y: { align: "top", dy: 0 },
        lovesBackgroundAndForeground: true
    },

    "xmas_window_right_top_bgonly": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "up_right",
        x: { align: "right", dx: 20 },
        y: { align: "top", dy: 0 },
        lovesBackground: true
    },

    "xmas_window_right_bottom": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "down_right",
        x: { align: "right", dx: 15 },
        y: { align: "bottom", dy: -36 },
        lovesBackgroundAndForeground: true
    },

    "xmas_window_left_bottom": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "down_left",
        x: { align: "left", dx: -19 },
        y: { align: "bottom", dy: -37 },
        lovesBackgroundAndForeground: true
    },

    "xmas_window_left_top": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "up_left",
        x: { align: "left", dx: -34 },
        y: { align: "top", dy: 3 },
        lovesBackgroundAndForeground: true
    },

    "xmas_window_left_top_bgonly": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "up_left",
        x: { align: "left", dx: -34 },
        y: { align: "top", dy: 3 },
        lovesBackground: true
    },

    "xmas_window_title_right": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window_title,
        animation: "right",
        x: { align: "right", dx: 90 },
        y: { align: "top", dy: 19 },
        lovesTitle: "bg_window"
    },

    "xmas_window_title_left": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window_title,
        animation: "left",
        x: { align: "left", dx: -90 },
        y: { align: "top", dy: 20 },
        lovesTitle: "bg_window"
    },

    "xmas_window_alternative_title_right": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window_title,
        animation: "right",
        x: { align: "right", dx: 55 },
        y: { align: "top", dy: 4 },
        zOrder: -1,
        lovesTitle: "nobg_window"
    },

    "xmas_window_alternative_title_left": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window_title,
        animation: "left",
        x: { align: "left", dx: -55 },
        y: { align: "top", dy: 5 },
        zOrder: -1,
        lovesTitle: "nobg_window"
    },

    "halloween_window_candle_right": {
        json: bundles.windows.jsons.candle_json,
        type: Decors.TYPE_ANIMATION,
        animation: "animation",
        x: { align: "right", dx: 97 },
        y: { align: "top", dy: -82 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_candle_left": {
        json: bundles.windows.jsons.candle_json,
        type: Decors.TYPE_ANIMATION,
        animation: "animation",
        x: { align: "left", dx: -97 },
        y: { align: "top", dy: -82 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: -1,
            y: 1
        }
    },

    "halloween_window_lian_left": {
        image: bundles.windows.frames.lian_down_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "left", dx: -17 },
        y: { align: "top", dy: -286 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_lian_top": {
        image: bundles.windows.frames.lian_left_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "left", dx: -4 },
        y: { align: "top", dy: 35 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_lian_right": {
        image: bundles.windows.frames.lian_left_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "right", dx: 63 },
        y: { align: "top", dy: -300 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        },
        rotation: 90
    },

    "halloween_window_lian_bottom": {
        image: bundles.windows.frames.lian_right_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "right", dx: -70 },
        y: { align: "bottom", dy: -24 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: -1,
            y: -1
        }
    },

    "halloween_window_pumpkin": {
        image: bundles.windows.frames.pumpkin_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "right", dx: -300 },
        y: { align: "top", dy: 132 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_spider": {
        image: bundles.windows.frames.spider_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "left", dx: 3 },
        y: { align: "bottom", dy: 6 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SceneDecors, {
    xmas_menubar: {
        image: bundles.menubar.frames.xmas_menubar,
        x: { align: "left", dx: 25 },
        y: { align: "top", dy: 17 }
    },

    xmas_menubar_level: {
        image: bundles.menubar.frames.xmas_menubar_level,
        x: { align: "left", dx: 76 },
        y: { align: "top", dy: 16 }
    },

    xmas_control_buttons: {
        image: bundles.controlbuttons.frames.xmas_control_buttons,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 4 }
    },

    xmas_sidebar: {
        image: bundles.sidebar.frames.xmas_sidebar,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -8 }
    },

    xmas_toolbar: [{
        image: bundles.toolbar.frames.xmas_toolbar,
        x: { align: "left", dx: -34 },
        y: { align: "top", dy: 32 }
    }, {
        image: bundles.toolbar.frames.xmas_toolbar,
        x: { align: "right", dx: 34 },
        y: { align: "top", dy: 32 },
        scale: {
            x: -1,
            y: 1
        }
    }],

    halloween_menubar_level: {
        image: bundles.menubar.frames.spiderweb_decor,
        x: { align: "right", dx: -6 },
        y: { align: "top", dy: -29 }
    },

    halloween_menubar: {
        image: bundles.menubar.frames.spiderweb_decor,
        x: { align: "right", dx: -4 },
        y: { align: "top", dy: -2 }
    }
});
