/**
 * Created by iamso on 24.12.2021
 */

var ImproverView = cc.Node.extend({
    ctor: function (improver, unitView) {
        this._super();

        this.improver = improver;
        this.unitView = unitView;

        cleverapps.bundleLoader.ensureSoundsLoaded([bundles.merge.urls.giftgenerator_start_effect, bundles.merge.urls.giftgenerator_complete_effect]);
        this.restoreState();
    },

    restoreState: function (animated) {
        if (this.improver.isWorking()) {
            this.unitView.getAnimation().setAnimation(0, "animation", true);
            this.createTimer(animated);

            if (animated) {
                cleverapps.audio.playSound(bundles.merge.urls.giftgenerator_start_effect);
            }
        } else {
            this.unitView.getAnimation().setAnimation(0, "idle", true);
            this.removeTimer(animated);

            if (animated) {
                cleverapps.audio.playSound(bundles.merge.urls.giftgenerator_complete_effect);
            }
        }
    },

    createTimer: function (animated) {
        if (this.unitView.getAdditionalView("timer")) {
            return;
        }

        var timer = new ComponentTimer(new cleverapps.CountDown(this.improver.getTimeLeft()), Improver.GENERATE_DURATION);
        timer.setPositionRound(cleverapps.styles.ImproverView.timer);

        if (animated) {
            this.unitView.animateAppearAdditionalView("timer", timer);
        } else {
            this.unitView.createAdditionalView("timer", timer);
        }
    },

    removeTimer: function (animated) {
        this.unitView.removeAdditionalView("timer", !animated);
    }
});

cleverapps.styles.ImproverView = {
    timer: {
        x: { align: "center" },
        y: { align: "bottom", dy: 10 }
    }
};