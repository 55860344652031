/**
 * Created by spepa on 21.06.2023
 */

var Map2dFence = function (regionName, code, x, y) {
    this.regionName = regionName;
    this.fenceName = Map2dFence.getFenceName(regionName);
    this.code = code;
    this.x = x;
    this.y = y;

    this.getView = function () {};
};

Map2dFence.prototype.getJson = function () {
    return bundles[this.fenceName].jsons[this.fenceName + "_" + this.code];
};

Map2dFence.prototype.isDisplayed = function () {
    return this.code !== "inner";
};

Map2dFence.prototype.getLocalZOrder = function () {
    return ["left", "down"].includes(this.code) ? 10 : -10;
};

Map2dFence.getFenceName = function (regionName) {
    if (bundles[regionName]) {
        return regionName;
    }

    var fenceName = regionName;

    var parts = fenceName.split("_");
    fenceName = parts.slice(0, -1).join("_");
    if (cleverapps.isNumber(parts[parts.length - 1]) && bundles[fenceName]) {
        return fenceName;
    }

    throw new Error("Unrecognized fence name " + regionName);
};