/**
 * Created by dmitry on 06.11.2024
 */

var MakesOrderView = function (makesOrder, unitView) {
    this.makesOrder = makesOrder;
    this.unitView = unitView;

    this.restoreState();
};

MakesOrderView.prototype.restoreState = function () {
    if (this.makesOrder.isProcessingCustomOrder()) {
        this.setHangerAnimation();
    } else {
        this.setMainAnimation();
    }
};

MakesOrderView.prototype.setHangerAnimation = function () {
    if (this.unitView.sprite instanceof cleverapps.Spine && this.unitView.sprite.hasAnimation("hungry")) {
        this.unitView.sprite.setCompleteListener();
        this.unitView.sprite.setAnimation(0, "hungry", true);
    }
};

MakesOrderView.prototype.setMainAnimation = function () {
    if (this.unitView.sprite instanceof cleverapps.Spine) {
        UnitView.applyDefaultAnimation(this.unitView.sprite, this.makesOrder.unit);
    }
};
