/**
 * Created by Denis Kuzin on 04 april 2023
 */

Landmarks.ONMAP.collections = [
    { code: "cllandmark", stage: 0, targetExpedition: "collections" },
    { code: "cllandmark", stage: 1, targetExpedition: "collections" },
    { code: "cllandmark", stage: 2, targetExpedition: "collections" },
    { code: "cllandmark", stage: 3, targetExpedition: "collections" },
    { code: "cllandmark", stage: 4, targetExpedition: "collections" },
    { code: "cllandmark", stage: 5, targetExpedition: "collections" },
    { code: "cllandmark", stage: 6, targetExpedition: "collections" },
    { code: "cllandmark", stage: 7, targetExpedition: "collections" },
    { code: "cllandmark", stage: 8, targetExpedition: "collections" },
    { code: "cllandmark", stage: 9, targetExpedition: "collections" }
];

var CollectionsFruitMine = {
    prize: function (level, unit) {
        return [{
            amount: 2,
            units: [{ code: unit.code, stage: 0 }]
        },

        {
            probabilities: [0.2, 1],
            units: [
                { code: unit.code, stage: 1 },
                { code: unit.code, stage: 0 }
            ]
        },

        {
            probabilities: [0.3],
            units: [{ code: "coins", stage: 0 }]
        }
        ];
    }
};

PassLevelsConfig[Mission.TYPE_COLLECTIONS_PASS] = [
    {
        task: {
            goal: 10
        },
        reward: {
            unit: { code: "clstrawberry", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "clstrawberry", stage: 1, amount: 1 }
        }
    }, // 1
    {
        task: {
            goal: 20
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 2, amount: 1 }
        }
    }, // 2
    {
        task: {
            goal: 30
        },
        reward: {
            unit: { code: "clsack", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "clsimplechest", stage: 0, amount: 2 }
        }
    }, // 3
    {
        task: {
            goal: 40
        },
        reward: {
            unit: { code: "crystal", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 1, amount: 3 }
        }
    }, // 4
    {
        task: {
            goal: 50
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "clstrawberry", stage: 1, amount: 3 }
        }
    }, // 5
    {
        task: {
            goal: 50
        },
        reward: {
            unit: { code: "clstrawberry", stage: 0, amount: 2 }
        },
        premiumReward: {
            unit: { code: "clsimplechest", stage: 1, amount: 1 }
        }
    }, // 6
    {
        task: {
            goal: 60
        },
        reward: {
            unit: { code: "clsimplechest", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 2, amount: 3 }
        }
    }, // 7
    {
        task: {
            goal: 70
        },
        reward: {
            unit: { code: "coins", stage: 3, amount: 1 }
        },
        premiumReward: {
            unit: { code: "clsack", stage: 0, amount: 2 }
        }
    }, // 8
    {
        task: {
            goal: 80
        },
        reward: {
            unit: { code: "clstrawberry", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "clstrawberry", stage: 2, amount: 3 }
        }
    }, // 9
    {
        task: {
            goal: 90
        },
        reward: {
            unit: { code: "crystal", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "clpaychest", stage: 0, amount: 2 }
        }
    }, // 10
    {
        task: {
            goal: 100
        },
        reward: {
            unit: { code: "clsimplechest", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 3, amount: 2 }
        }
    }, // 11
    {
        task: {
            goal: 100
        },
        reward: {
            unit: { code: "clsack", stage: 0, amount: 2 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 2, amount: 3 }
        }
    }, // 12
    {
        task: {
            goal: 110
        },
        reward: {
            unit: { code: "clstrawberry", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "clpaychest", stage: 1, amount: 1 }
        }
    }, // 13
    {
        task: {
            goal: 120
        },
        reward: {
            unit: { code: "clsimplechest", stage: 1, amount: 2 }
        },
        premiumReward: {
            unit: { code: "clsack", stage: 0, amount: 3 }
        }
    }, // 14
    {
        task: {
            goal: 130
        },
        reward: {
            unit: { code: "coins", stage: 3, amount: 2 }
        },
        premiumReward: {
            unit: { code: "clstrawberry", stage: 3, amount: 2 }
        }
    }, // 15
    {
        task: {
            goal: 140
        },
        reward: {
            unit: { code: "clsimplechest", stage: 1, amount: 2 }
        },
        premiumReward: {
            unit: { code: "clpaychest", stage: 2, amount: 2 }
        }
    }, // 16
    {
        task: {
            goal: 150
        },
        reward: {
            unit: { code: "crystal", stage: 2, amount: 2 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 3, amount: 3 }
        }
    }, // 17
    {
        task: {
            goal: 160
        },
        reward: {
            unit: { code: "clsack", stage: 0, amount: 3 }
        },
        premiumReward: {
            unit: { code: "clsack", stage: 0, amount: 5 }
        }
    }, // 18
    {
        task: {
            goal: 180
        },
        reward: {
            unit: { code: "clpaychest", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 4, amount: 3 }
        }
    }, // 19
    {
        task: {
            goal: 200
        },
        reward: {
            unit: { code: "clstrawberry", stage: 3, amount: 2 }
        },
        premiumReward: {
            unit: { code: "clpaychest", stage: 2, amount: 3 }
        }
    } // 20
];

cleverapps.styles.collections = {
    miningAnimation: {
        x: { align: "center", dx: 0, leftDx: 0 },
        y: { align: "center", dy: 0 }
    },

    fogBlockerLock: {
        width: 100,
        height: 100,
        margin: 25,

        content: {
            width: 68,
            height: 68
        },
        position: {
            x: { align: "center" },
            y: { align: "center", dy: 50 }
        },
        text: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        icon: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        cloud: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -39 }
        },
        lock: {
            x: { align: "center" },
            y: { align: "center" }
        }
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LEVELUPWINDOW_COLLECTIONS_VALUE_TEXT: {
        size: 220,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

Mines.clfruit = CollectionsFruitMine.prize;
