/**
 * Created by r4zi4l on 19.03.2021
 */

var InstantWorkerGuideOptions = {
    name: "InstantWorkerGuideWindow",

    getGuideBundle: function () {
        return bundles.instantworkerguidewindow;
    },

    stagesContent: function () {
        var styles = cleverapps.styles.InstantWorkerGuideWindow;

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.blue_light, styles.background);
            this.addUnitIcon(container, this.unit.code, this.unit.stage - 1, styles.unit);
            this.addSprite(container, this.bundle.frames.three_white_arrows, styles.arrows);
            styles.components.forEach(function (componentStyles) {
                this.addSprite(container, bundles.merge.frames.stand_ground, {
                    x: componentStyles.x,
                    y: componentStyles.y - styles.unit.dy
                });
                this.addUnitIcon(container, this.unit.code, this.unit.stage - 2, componentStyles);
            }, this);
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.blue_light, styles.background);
            this.addUnitIcon(container, this.unit.code, this.unit.stage, styles.unit);
            this.addSprite(container, this.bundle.frames.blue_light_foreground, styles.foreground);
        }.bind(this));

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            var rays = this.addSprite(container, this.bundle.frames.guide_rays, styles.rays);
            this.addUnitIcon(rays, this.unit.code, this.unit.stage, styles.unit);
            var building = this.addSprite(container, this.bundle.frames.instant_worker_buildable, styles.building);
            this.addSprite(building, this.bundle.frames.guide_attention, styles.attention);
            this.addSprite(container, this.bundle.frames.white_arrow_up, styles.arrow);
            this.addSprite(container, bundles.finger.frames.tutorial_hand_png, styles.hand);
        }.bind(this));

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.InstantWorkerGuideWindow = {
    firstStage: {
        container: {
            x: { align: "center", dx: 25 },
            y: { align: "center", dy: 130 }
        },

        unit: {
            x: -25,
            y: 5,
            scale: 1.4,
            dy: 40
        },

        arrows: {
            x: -22,
            y: -112
        },

        components: [
            {
                x: 78,
                y: -147,
                scale: 1.2
            },
            {
                x: -129,
                y: -140,
                scale: 1.2
            },
            {
                x: -32,
                y: -214,
                scale: 1.2
            }
        ]
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -40 }
        },

        unit: {
            x: 0,
            y: 0,
            scale: 1.6
        },

        background: {
            x: 0,
            y: 0,
            scale: 0.8
        }
    },

    thirdStage: {
        container: {
            x: { align: "center", dx: -50 },
            y: { align: "center", dy: -20 }
        },

        rays: {
            x: 0,
            y: 0
        },

        unit: {
            x: { align: "center", dx: -10 },
            y: { align: "center", dy: -20 },
            scale: 1.6
        },

        building: {
            x: 145,
            y: 163,
            scale: 1.4
        },

        attention: {
            x: { align: "center" },
            y: { align: "center" },
            scale: 0.7
        },

        hand: {
            x: 70,
            y: -100,
            scale: 0.7
        },

        arrow: {
            x: 65,
            y: 85
        }
    }
};
