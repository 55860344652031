/**
 * Created by mac on 12/15/20
 */

var HighlightView = cc.Node.extend({
    ctor: function (highlight) {
        this._super();

        this.highlight = highlight;

        if (Game.currentGame && ["undersea", "undersea2", "undersea3"].indexOf(cleverapps.travelBook.getCurrentPage().id) !== -1) {
            this.setAnchorPoint(0.52, 0.44);
        } else {
            this.setAnchorPoint(0.5, 0.5);
        }

        this.setContentSize2(cleverapps.styles.Map2dView.cell);

        this.setLocalZOrder(cleverapps.config.name === "wordsoup" || typeof Editor !== "undefined" && Editor.currentEditor ? 1 : -1);

        this.setRecCascadeColorEnabled();

        highlight.onMove = this.createListener(this.move.bind(this));
        highlight.hide = this.createListener(this.hide.bind(this));
        highlight.show = this.createListener(this.show.bind(this));
        highlight.onRemove = this.createListener(this.removeFromParent.bind(this));
        highlight.up = this.createListener(this.up.bind(this));

        if (this.highlight.isErrorLight) {
            this.setColor(cleverapps.styles.COLORS.COLOR_RED);
        }

        this.views = this.addViews();
    }
});

HighlightView.prototype.addViews = function () {
    var views = {};

    var multiCell = this.highlight.unit && this.highlight.unit instanceof Unit && this.highlight.unit.findComponent(MultiCell);
    if ((cleverapps.config.name === "wordsoup" || typeof Editor !== "undefined" && Editor.currentEditor) && multiCell) {
        multiCell.shape.forEach(function (shape) {
            var view = new cleverapps.Spine(bundles.highlight_animations.jsons.hint_json);
            var iso = Map2dInnerView.IsoToScreen(
                shape.x,
                shape.y
            );

            if (shape.x === 0 && shape.y === 0) {
                var headText = cleverapps.UI.generateOnlyText("*", cleverapps.styles.FONTS.WHITE_TEXT);
                headText.setPositionRound({
                    x: { align: "center" },
                    y: { align: "center" }
                });
                view.addChild(headText);
            }

            view.setPositionRound(
                this.width / 2 + iso.x,
                this.height / 2 + iso.y
            );

            view.setSkin("tile_light_1");
            view.setAnimation(0, this.getAnimation(this.highlight.x, this.highlight.y, view), true);
            this.addChild(view);

            views[shape.x + "_" + shape.y] = view;
        }.bind(this));
    } else {
        var view = new cleverapps.Spine(bundles.highlight_animations.jsons.hint_json);
        view.setPositionRound(this.width / 2, this.height / 2);
        view.setSkin(this.highlight.getShape() !== FamiliesHelper.ShapeCell ? "tile_light_2" : "tile_light_1");
        view.setAnimation(0, this.getAnimation(this.highlight.x, this.highlight.y, view), true);
        this.addChild(view);

        if (this.highlight.getShape() === FamiliesHelper.ShapeSquare) {
            view.x += this.width / 2;
        }

        if (this.highlight.getShape() === FamiliesHelper.ShapeTopRightCorner) {
            view.x -= this.width / 2;
        }

        views["0_0"] = view;
    }

    return views;
};

HighlightView.prototype.hide = function () {
    this.visible = false;
};

HighlightView.prototype.show = function () {
    this.visible = true;
};

HighlightView.prototype.up = function () {
    this.setLocalZOrder(1);
};

HighlightView.prototype.move = function (x, y, silent) {
    Object.keys(this.views).forEach(function (pos) {
        var view = this.views[pos];
        view.setAnimation(0, this.getAnimation(x, y, view), true);
    }.bind(this));

    this.stopAllActions();
    var mapView = Map2d.currentMap.getMapView();
    var position = mapView.alignInIsometricGrid(x, y);
    if (silent) {
        this.setPositionRound(position);
    } else {
        this.runAction(new cc.MoveTo(0.05, position.x, position.y));
    }

    if (this.highlight.intersects && Object.keys(this.highlight.intersects).length || this.highlight.isErrorLight || this.highlight.error) {
        Object.keys(this.views).forEach(function (pos) {
            var view = this.views[pos];
            view.setColor(cleverapps.styles.COLORS.WHITE);
        }.bind(this));

        Object.keys(this.highlight.intersects || this.views).forEach(function (pos) {
            var view = this.views[pos];
            view.setColor(cleverapps.styles.COLORS.COLOR_RED);
        }.bind(this));
    } else {
        Object.keys(this.views).forEach(function (pos) {
            var view = this.views[pos];
            view.setColor(cleverapps.styles.COLORS.WHITE);
        }.bind(this));
    }
};

HighlightView.prototype.getAnimation = function (x, y, view) {
    var animation = (Map2d.currentMap.getTerrainCode(x, y) === "s" && !this.highlight.error && !this.highlight.isErrorLight) ? "blue" : "yellow";
    return this.highlight.drag && view.hasAnimation(animation + "_dragging") ? animation + "_dragging" : animation;
};