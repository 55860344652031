/**
 * Created by evgeny on 31.07.2024
 */

var Throwaway = function (unit, options, type) {
    UnitComponent.call(this, unit);
    this.type = type;
    this.unit = unit;

    this.onAnimateDestroy = function () {};
};

Throwaway.prototype = Object.create(UnitComponent.prototype);
Throwaway.prototype.constructor = Throwaway;

Throwaway.prototype.animateDestroy = function (f) {
    this.onAnimateDestroy(function () {
        this.unit.remove();
        f();
    }.bind(this));
};

Throwaway.TYPE_SWEEPING = "sweeping";
Throwaway.TYPE_UPGRADE = "upgrade";