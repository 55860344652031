/**
 * Created by Reda on 26.08.2024
 */

var OfferButtonComponent = cc.Node.extend({
    ctor: function (options) {
        this.options = options || {};
        var styles = cleverapps.styles.OfferButtonComponent[this.options.offerType] || cleverapps.styles.OfferButtonComponent;
        this._super();
        this.createButton();
        this.setPositionRound(styles.position);
    },

    createButton: function () {
        var styles = cleverapps.styles.OfferButtonComponent[this.options.offerType] || cleverapps.styles.OfferButtonComponent;

        var button = this.button = new cleverapps.UI.Button({
            text: this.options.text,
            onClicked: this.options.onClicked,
            height: styles.button.size.height,
            width: styles.button.size.width
        });
        cleverapps.UI.wrapWithPadding(
            button,
            styles.padding || { x: 0, y: 0 },
            true
        );
        button.setPositionRound(styles.button.position);
        if (this.options.badgeText) {
            var badge = new TileBadge({
                text: this.options.badgeText,
                rotation: styles.badge.rotation,
                largeFont: styles.badge.largeFont
            });
            badge.setPositionRound(styles.badge.position);
            cleverapps.UI.fitToBox(badge, styles.badge.size);
            button.addChild(badge);
        }
        button.setVisible(false);

        this.setContentSize(styles.size);
        this.addChild(button);
    },

    show: function () {
        AnimationsLibrary.run("showUp", this.button);
    },

    remove: function () {
        AnimationsLibrary.run("disappear", this.button, {
            callback: function () {
                this.removeFromParent();
            }.bind(this)
        });
    }
});

cleverapps.styles.OfferButtonComponent = {
    position: {
        x: {
            align: "center",
            dx: 0
        },
        y: {
            align: "bottom",
            dy: 30
        }
    },
    button: {
        position: {
            x: {
                align: "center",
                dx: 0
            },
            y: {
                align: "center",
                dy: 0
            }
        },
        size: {
            height: 90,
            width: 500
        }
    },
    padding: {
        x: 50,
        y: 50
    },
    size: {
        height: 150,
        width: 600
    },
    badge: {
        position: {
            x: {
                align: "right",
                dx: 5
            },
            y: {
                align: "top",
                dy: 10
            }
        },
        size: {
            width: 130,
            height: 130
        },
        largeFont: true,
        rotation: 10
    }
};

cleverapps.styles.OfferButtonComponent[Offers.TYPES.STICKERS_PACK] = cleverapps.overrideStyles(cleverapps.styles.OfferButtonComponent, {
    badge: {
        rotation: 30,
        largeFont: false
    }
}, true);

cleverapps.styles.OfferButtonComponent[Offers.TYPES.PINBALL_PACK] = cleverapps.overrideStyles(cleverapps.styles.OfferButtonComponent, {
    badge: {
        rotation: 30,
        largeFont: true
    },
    position: {
        x: {
            align: "center",
            dx: 0
        },
        y: {
            align: "bottom",
            dy: -30
        }
    }
}, true);