/**
 * Created by evgeny on 13.06.2024
 */

var Map2dEditor = function (GameOrangery, level) {
    EditorBase.call(this);
    this.GameOrangery = GameOrangery;
    if (Map2d.currentMap) {
        Map2d.currentMap.stop();
    }
    
    this.map = Map2d.currentMap = new EditorMap2d(level.map || level.content.map, {
        tiles: level.meta ? level.meta.tiles : level.tiles,
        units: level.meta ? level.meta.units : level.units,
        visibleBox: level.visibleBox || level.content.visibleBox,
        regions: level.regions || level.content.regions,
        decorators: level.decorators || level.content.decorators,
        terrains: level.terrains || level.content.terrains,
        field: level.field || level.content.field,
        families: level.families || level.content && level.content.families
    });

    Map2d.InsertDefaults(this.map, level.field || level.content.field);

    this.regions = new RegionsUnits();
    this.decorators = new DecoratorUnits();

    this.orangery = new Orangery(GameOrangery);

    this.orangery.onSelectionChanged = this.select.bind(this);

    this.onChangeLayer = function () {};
    this.setLayer(Map2dEditor.LAYER_UNITS);
};

Map2dEditor.prototype = Object.create(EditorBase.prototype);
Map2dEditor.prototype.constructor = Map2dEditor;

Map2dEditor.prototype._load = function () {
};

Map2dEditor.prototype.setLayer = function (layer) {
    if (layer === Map2dEditor.LAYER_UNITS) {
        Map2d.setUnitsVisible(true);
        Map2d.setDecoratorsVisible(true);
    }
    if (layer === Map2dEditor.LAYER_DECORATORS) {
        Map2d.setUnitsVisible(false);
        Map2d.setDecoratorsVisible(true);
    }
    if (layer === Map2dEditor.LAYER_GROUND) {
        Map2d.setUnitsVisible(false);
        Map2d.setDecoratorsVisible(false);
    }

    this.layer = layer;

    this.onChangeLayer(layer);
};

Map2dEditor.prototype.changeSize = function (newSize, dx, dy) {
    var sizeX = newSize;
    var sizeY = newSize;

    dx = dx || 0;
    dy = dy || 0;

    var content = LevelManager.getInstance().level.content;

    var map = content.map;
    dx = Math.floor((sizeX - map[0].length) / 2) + dx;
    dy = Math.floor((sizeY - map.length) / 2) + dy;

    map = [];
    var terrains = [];

    for (var y = 0; y < sizeY; y++) {
        map.push("");
        terrains.push("");
        for (var x = 0; x < sizeX; x++) {
            map[y] += content.map[y - dy] && content.map[y - dy][x - dx] || "0";
            terrains[y] += content.terrains[y - dy] && content.terrains[y - dy][x - dx] || ".";
        }
    }

    content.map = map;
    content.terrains = terrains;

    content.field.forEach(function (unit) {
        unit.x += dx;
        unit.y += dy;
    });

    content.decorators.forEach(function (decorator) {
        decorator.x += dx;
        decorator.y += dy;
    });

    var regionKeys = Object.keys(content.regions);
    regionKeys.forEach(function (key) {
        var region = content.regions[key];
        region.positions.forEach(function (position) {
            position.x += dx;
            position.y += dy;
        });
        if (region.head) {
            region.head.x += dx;
            region.head.y += dy;
        }
    });

    LevelManager.getInstance().update(content);
    LevelManager.getInstance().save();
};

Map2dEditor.prototype.produce = function (x, y, selected) {
    if (cleverapps.keyboardController.isPressed(cc.KEY.d)) {
        this.map.remove(Map2d.LAYER_UNITS, x, y);
        this.map.saveLevel();
        return;
    }

    selected = selected || this.selected;
    if (selected === undefined) {
        return;
    }

    this.map.setPointer(x, y);

    if (selected.eraser) {
        if (selected.id === this.GameOrangery.REGION_ID) {
            if (this.map.fogs.selected) {
                this.map.processFogEraser({ x: x, y: y });
            }
        } else if (this.selected.id === this.GameOrangery.DECORATOR_ID) {
            // do nothing
        } else {
            this.map.remove(Map2d.LAYER_UNITS, x, y);
        }
    } else if (selected.decorator) {
        this.decorators.produce(x, y, selected);
    } else if (selected.region) {
        this.map.fogs.produce(x, y, selected);
    } else if (selected.code) {
        this.map.produceUnit(x, y, selected);
    } else {
        this.map.produceMap(x, y, selected);
    }

    this.map.saveLevel();
};

Map2dEditor.prototype.select = function (selected) {
    if (this.selected !== selected) {
        this.nextSelected = selected;
        this.unselect();

        if (selected) {
            this.selected = selected;

            if (selected.decorator) {
                selected.onUpdate();
            } else if (selected.region) {
                selected.onUpdate();
                this.map.fogs.select(selected);
            }
        }
    }
};

Map2dEditor.prototype.unselect = function () {
    if (this.selected) {
        var selected = this.selected;
        this.selected = undefined;

        if (selected.eraser && selected.id === this.GameOrangery.REGION_ID) {
            if (this.nextSelected) {
                this.map.fogs.unselect(this.map.fogs.selected);
            }
            Editor.currentEditor.orangery.select(this.nextSelected || this.map.fogs.selected);
            return;
        }

        if (selected) {
            if (selected.decorator) {
                selected.onUpdate();
            } else if (selected.region) {
                if (Editor.currentEditor.orangery.selected && Editor.currentEditor.orangery.selected.eraser) {
                    return;
                }
                selected.onUpdate();
                this.map.fogs.unselect(selected);
            } else {
                var orangery = Editor.currentEditor.orangery;
                orangery.unselect(selected);
            }
        }
    }
};

Editor = Map2dEditor;

Map2dEditor.BUNDLES = ["merge", "admin", "episode_editor"].concat(Map2d.AVAILABLE_TILES);

Map2dEditor.LAYER_GROUND = 0;
Map2dEditor.LAYER_DECORATORS = 1;
Map2dEditor.LAYER_UNITS = 2;

Map2dEditor.LAYERS = [
    "ground",
    "decorators",
    "units"
];
