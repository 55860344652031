/**
 * Created by r4zi4l on 04.05.2022
 */

var Offers = {
    TYPES: {}
};

Offers.TYPES.LIVESFEAST = 1;
Offers.TYPES.SOFTFEAST = 2;
Offers.TYPES.KRAKENFEAST = 3;
Offers.TYPES.BUILDPASS = 4;
Offers.TYPES.SALEPASS = 5;
Offers.TYPES.EXPEDITION = 6;
Offers.TYPES.UNDERSEA = 7;
Offers.TYPES.UNDERSEA2 = 8;
Offers.TYPES.SNAILFEAST_UNDERSEA2 = 9;
Offers.TYPES.HALLOWEEN = 10;
Offers.TYPES.SNAILFEAST = 11;
Offers.TYPES.RAPUNZEL = 12;
Offers.TYPES.XMAS = 13;
Offers.TYPES.RAPUNZEL2 = 14;

Offers.TYPES.EASTER = 16;
Offers.TYPES.DRAGONIA2 = 17;
Offers.TYPES.DRAGONIA3 = 18;
Offers.TYPES.SNAILFEAST_DRAGONIA3 = 19;
Offers.TYPES.CHINA = 20;
Offers.TYPES.CHAIN_SALE = 21;
Offers.TYPES.SUPPLIES_SALE = 22;
Offers.TYPES.PROMOTION_SALE = 23;
Offers.TYPES.RAPUNZEL3 = 24;
Offers.TYPES.CLCHAIN_SALE = 25;
Offers.TYPES.CLSUPPLIES_SALE = 26;
Offers.TYPES.CLPROMOTION_SALE = 27;
Offers.TYPES.PACK = 28;
Offers.TYPES.PIXEL_OFFER = 29;
Offers.TYPES.PERIODIC_PROMOTION_SALE = 30;
Offers.TYPES.CLPASS = 31;
Offers.TYPES.STICKERS_PACK = 32;
Offers.TYPES.SNAILFEAST_UNDERSEA3 = 33;
Offers.TYPES.UNDERSEA3 = 34;
Offers.TYPES.PINBALL_PACK = 35;
Offers.TYPES.SNAILFEAST_HALLOWEEN = 36;
Offers.TYPES.BLACKFRIDAY = 37;
Offers.TYPES.SNAILFEAST_BLACKFRIDAY = 38;
Offers.TYPES.BOOSTER_OFFER = 39;
Offers.TYPES.BLACKFRIDAY_MAIN = 40;

Offers.initialize = function () {
    if (cleverapps.config.name !== "woodenblock") {
        Offers[Offers.TYPES.PACK] = {
            name: "Pack",
            cooldown: cleverapps.config.debugMode ? "10 seconds" : "1 day",
            logic: PackOfferLogic,
            bundle: "starter_pack",
            sideBarJson: [bundles.sidebar.jsons.pack_icon_json, bundles.sidebar.jsons.pack1_icon_json, bundles.sidebar.jsons.pack2_icon_json],
            view: PackWindow,
            pendingWindow: true,
            availableByStages: [
                {
                    level: cleverapps.config.type === "merge" ? 6.2 : 2.65
                }, {
                    level: cleverapps.config.type === "merge" ? 8.2 : 4.65
                }, {
                    level: cleverapps.config.type === "merge" ? 10.2 : 6.65
                }
            ]
        };
    }

    if (cleverapps.config.features.includes("chainsale")) {
        Offers[Offers.TYPES.CHAIN_SALE] = {
            available: {
                level: 5.3
            },
            name: "ChainSale",
            duration: cleverapps.config.debugMode ? "5 minutes" : "3 day",
            cooldown: cleverapps.config.debugMode ? "5 minutes" : "14 days",
            logic: ChainSaleLogic,
            bundle: "chain_sale",
            sideBarJson: bundles.sidebar.jsons.chain_sale_json,
            view: ChainSaleWindow,
            pendingWindow: true
        };
    }

    if (cleverapps.config.features.includes("suppliessale")) {
        Offers[Offers.TYPES.SUPPLIES_SALE] = {
            available: {
                level: 5.3,
                debugMode: ["spades"].includes(cleverapps.config.name)
            },
            name: "SuppliesSale",
            cooldown: cleverapps.config.debugMode ? "10 minutes" : "30 days",
            logic: SuppliesSaleLogic,
            sideBarJson: bundles.sidebar.jsons.supplies_icon_json,
            view: SuppliesWindow,
            pendingWindow: true
        };
    }

    if (cleverapps.config.features.includes("promotionsale")) {
        Offers[Offers.TYPES.PROMOTION_SALE] = {
            available: {
                level: 4.7,
                debugMode: ["solitaire"].includes(cleverapps.config.type)
            },
            name: "PromotionSale",
            duration: cleverapps.config.debugMode ? "5 minutes" : "3 day",
            cooldown: cleverapps.config.debugMode ? "5 minutes" : "14 days",
            logic: PromotionSaleLogic,
            bundle: "promotion_sale",
            sideBarJson: bundles.sidebar.jsons.promotion_sale_json,
            view: PromotionSaleWindow,
            pendingWindow: true
        };
    }

    if (cleverapps.config.type === "merge") {
        Offers[Offers.TYPES.LIVESFEAST] = {
            mission: Mission.TYPE_LIVESFEAST,
            price: 250,
            view: MissionOfferWindow,
            reward: RewardsConfig.LivesFeast.offer,
            sideBarJson: bundles.sidebar.jsons.lives_feast_offer,
            name: "LivesFeastOffer",
            bundle: "lives_offer"
        };

        Offers[Offers.TYPES.BUILDPASS] = {
            mission: Mission.TYPE_BUILDPASS,
            price: 299,
            reward: RewardsConfig.BuildPass.offer,
            view: MissionOfferWindow,
            sideBarJson: bundles.sidebar.jsons.buildpass_offer,
            name: "BuildPassOffer",
            bundle: "buildpass_offer"
        };

        Offers[Offers.TYPES.SALEPASS] = {
            mission: Mission.TYPE_SALEPASS,
            price: 299,
            reward: RewardsConfig.SalePass.offer,
            view: MissionOfferWindow,
            sideBarJson: bundles.sidebar.jsons.salepass_offer,
            name: "SalePassOffer",
            bundle: "salepass_offer"
        };

        if (["mbga", "sp_mbga"].indexOf(connector.info.source) === -1) {
            Offers[Offers.TYPES.SOFTFEAST] = {
                mission: Mission.TYPE_SOFTFEAST,
                price: 250,
                reward: RewardsConfig.SoftFeast.offer,
                view: MissionOfferWindow,
                sideBarJson: bundles.sidebar.jsons.soft_feast_offer,
                name: "SoftFeastOffer",
                bundle: "soft_offer"
            };
        }

        Offers[Offers.TYPES.KRAKENFEAST] = {
            mission: Mission.TYPE_KRAKENFEAST,
            reward: RewardsConfig.KrakenFeast.offer,
            price: 299,
            view: MissionOfferWindow,
            sideBarJson: bundles.sidebar.jsons.kraken_offer,
            name: "KrakenFeastOffer",
            bundle: "kraken_offer"
        };

        Offers[Offers.TYPES.EXPEDITION] = {
            mission: Mission.TYPE_DRAGONIA_EXPEDITION,
            hero: { code: "dragonpack", stage: 0 },
            product: "expeditionPack",
            force: Forces.OFFER_ICON_SLOT2,
            fog: "fog4",
            reward: RewardsConfig.DragoniaPack,
            view: MissionOfferWindow,
            sideBarJson: bundles.sidebar.jsons.dragonia_offer,
            name: "DragoniaPack",
            bundle: "dragonia_offer"
        };

        Offers[Offers.TYPES.SNAILFEAST] = {
            mission: Mission.CompoundType(Mission.TYPE_SNAIL_FEAST, Mission.TYPE_DRAGONIA2_EXPEDITION),
            price: 99,
            view: MissionOfferWindow,
            sideBarJson: function () {
                return cleverapps.skins.getSlot("snailhouse_offer_json") || bundles.sidebar.jsons.snailhouse_offer_json;
            },
            reward: RewardsConfig.SnailHouseDragoniaPack,
            name: "SnailPack",
            bundle: "snailhouse_offer"
        };

        Offers[Offers.TYPES.PIXEL_OFFER] = {
            available: {
                level: 5.3,
                debugMode: ["hustlemerge", "fairy", "wondermerge"].includes(cleverapps.config.name)
            },
            name: "PixelOffer",
            duration: "2 hours",
            cooldown: "12 hours",
            logic: PixelOfferLogic,
            sideBarJson: bundles.sidebar.jsons.pixel_offer_icon_json,
            view: PixelOfferWindow,
            availableByStages: [{}, {}, {}, {}, {}, {}]
        };

        Offers[Offers.TYPES.CLPASS] = {
            mission: Mission.TYPE_COLLECTIONS_PASS,
            price: 999,
            view: MissionOfferWindow,
            reward: RewardsConfig.CollectionsPass.offer,
            sideBarJson: bundles.sidebar.jsons.collections_pass_offer,
            name: "CollectionsPassOffer",
            bundle: "clpass_offer",
            expedition: "collections",
            cooldown: "48 hours",
            available: {
                debugMode: ["hustlemerge"].includes(cleverapps.config.name)
            }
        };

        if (cleverapps.config.features.includes("chainsale")) {
            Offers[Offers.TYPES.CLCHAIN_SALE] = Object.assign({}, Offers[Offers.TYPES.CHAIN_SALE], {
                name: "ClChainSale",
                reward: RewardsConfig.ClChainSale,
                expedition: "collections",
                available: {
                    level: false,
                    gameLevel: 5.3
                }
            });
        }
        if (cleverapps.config.features.includes("suppliessale")) {
            Offers[Offers.TYPES.CLSUPPLIES_SALE] = Object.assign({}, Offers[Offers.TYPES.SUPPLIES_SALE], {
                name: "ClSuppliesSale",
                reward: RewardsConfig.ClSupplies.rewards,
                expedition: "collections",
                available: {
                    level: false,
                    gameLevel: 5.3
                }
            });
        }
        if (cleverapps.config.features.includes("promotionsale")) {
            Offers[Offers.TYPES.CLPROMOTION_SALE] = Object.assign({}, Offers[Offers.TYPES.PROMOTION_SALE], {
                name: "ClPromotionSale",
                reward: RewardsConfig.ClPromotionSale,
                expedition: "collections",
                available: {
                    level: false,
                    gameLevel: 4.7
                }
            });
            Offers[Offers.TYPES.PERIODIC_PROMOTION_SALE] = {
                name: "PrPromotionSale",
                reward: RewardsConfig.PrPromotionSale,
                feature: "periodic_offer",
                logic: PromotionSaleLogic,
                bundle: "periodic_promotion_offer",
                cooldown: "7 days",
                sideBarJson: bundles.sidebar.jsons.periodic_promotion_sale_json,
                view: PromotionSaleWindow,
                pendingWindow: true,
                available: {
                    level: 4.7,
                    vk: { disabled: true },
                    ok: { disabled: true },
                    mm: { disabled: true },
                    mygames: { disabled: true },
                    yandex: { disabled: true },
                    fotostrana: { disabled: true }
                }
            };
        }

        if (cleverapps.config.features.includes("expedition_blackfriday")) {
            Offers[Offers.TYPES.BLACKFRIDAY_MAIN] = {
                sideBarJson: bundles.sidebar.jsons.halloween_offer,
                name: "BlackfridayPackMain",
                bundle: "blackfriday_offer",
                reward: RewardsConfig.BlackFridayPackMain,
                logic: BlackFridayOfferLogic,
                feature: "blackfriday_offer_main",
                view: MissionOfferWindow,
                availableByStages: [{
                    level: 6.2,
                    debugMode: true
                }]
            };
        }

        TravelBook.PAGES.forEach(function (page) {
            (page.offers || []).forEach(function (offerData) {
                if (typeof offerData !== "object") {
                    return;
                }

                var id = offerData.id;
                var baseOfferId = offerData.base;

                offerData.type = id;
                delete offerData.id;
                delete offerData.base;

                Offers[id] = Object.assign({}, Offers[baseOfferId], offerData);

                if (baseOfferId === Offers.TYPES.SNAILFEAST) {
                    Offers[id].mission = Mission.CompoundType(Mission.GetChildType(Offers[baseOfferId].mission), page.missionType);
                    cleverapps.styles.MissionOfferWindow[id] = cleverapps.styles.MissionOfferWindow[baseOfferId];
                    cleverapps.styles.PackOfferComponent[id] = cleverapps.styles.PackOfferComponent[baseOfferId];
                } else if (baseOfferId === Offers.TYPES.EXPEDITION) {
                    Offers[id].mission = page.missionType;
                    Offers[id].force = page.slot === "1" ? Forces.OFFER_ICON_SLOT1 : Forces.OFFER_ICON_SLOT2;
                    Offers[id].sideBarJson = bundles.sidebar.jsons[offerData.bundle];
                    Offers[id].name = page.name.replace("Expedition", "Pack");
                }
            });
        });
    }

    if (cleverapps.config.features.includes("pinball")) {
        Offers[Offers.TYPES.PINBALL_PACK] = {
            mission: Mission.TYPE_PINBALL,
            product: "kit3",
            view: MissionOfferWindow,
            reward: RewardsConfig.PinballPack,
            sideBarJson: bundles.sidebar.jsons.pinball_offer_json,
            name: "PinballOffer",
            bundle: "pinball_offer"
        };
    }

    if (cleverapps.config.name === "woodenblock") {
        Offers[Offers.TYPES.BOOSTER_OFFER] = {
            sideBarJson: bundles.sidebar.jsons.booster_offer_icon_json,
            name: "BoosterOffer",
            bundle: "booster_offer",
            reward: RewardsConfig.BoosterOffer,
            logic: BoosterOfferLogic,
            view: BoosterOfferWindow
        };
    }

    Offers.offerByMission = {};
    Offers.noMissionOffer = {};
    Offers.offerByHero = {};
    Offers.offersByFog = {};

    for (var name in Offers.TYPES) {
        var type = Offers.TYPES[name];
        var offer = Offers[type];

        if (offer) {
            offer.type = type;

            if (offer.mission) {
                Offers.offerByMission[offer.mission] = offer;
            } else {
                Offers.noMissionOffer[offer.name] = offer;
            }

            if (offer.hero) {
                Offers.offerByHero[Unit.GetKey(offer.hero)] = offer;
            }

            if (offer.fog) {
                if (Offers.offersByFog[offer.fog]) {
                    Offers.offersByFog[offer.fog].push(offer);
                } else {
                    Offers.offersByFog[offer.fog] = [offer];
                }
            }
        }
    }
};

if (typeof cc === "undefined") {
    module.exports = Offers;
}
