/**
 * Created by olga on 14.11.2022
 */

var UnmergeableUnitHintContent = function (options) {
    this.unit = options.unit;

    this.doNotBlock = {
        x: this.unit.dragX,
        y: this.unit.dragY
    };
};

UnmergeableUnitHintContent.prototype.hasSameContent = function (options) {
    return Unit.Equals(this.unit, options.unit);
};

UnmergeableUnitHintContent.isApplicable = function (options) {
    return options.wrongMerged && !Game.currentGame.tutorial.isActive();
};

UnmergeableUnitHintContent.prototype.getMessages = function () {
    var messages;
    if (this.unit.isLast()) {
        messages = [{ text: Messages.get("Merge.MaxStage") }];
    } else {
        messages = [
            { text: Messages.get("CenterHint.merge.unmergeableUnit") }
        ];
    }
    return messages;
};
