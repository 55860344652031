/**
 * Created by evgenijsenkevic on 26.09.2024
 */

var Invisible = function (unit) {
    UnitComponent.call(this, unit);
};

Invisible.prototype = Object.create(UnitComponent.prototype);
Invisible.prototype.constructor = Invisible;
