/**
 * Created by mac on 12/28/20
 */

var GeneratorSourceMine = {
    prize: function (level, unit) {
        var prizes = GeneratorSourceMine["stage" + unit.stage](unit);

        if (level === unit.getData().mineable.length - 1) {
            prizes = prizes.concat(GeneratorSourceMine["last" + unit.stage](unit));
        }

        return prizes;

    },

    stage0: function (unit) {
        return [{
            amount: 3,
            probabilities: [0.75, 0.25],
            units: [
                { code: unit.getResource() + 'source', stage: 0 },
                { code: unit.getResource(), stage: 0 }
            ]
        }];
    },

    stage1: function (unit) {
        return [{
            amount: 4,
            probabilities: [0.35, 0.35, 0.15, 0.15],
            units: [
                { code: unit.getResource() + 'source', stage: 0 },
                { code: unit.getResource() + 'source', stage: 2 },
                { code: unit.getResource(), stage: 0 },
                { code: unit.getResource(), stage: 1 }
            ]
        }];
    },

    stage2: function (unit) {
        return [{
            amount: 5,
            probabilities: [0.2, 0.3, 0.3, 0.1, 0.1],
            units: [
                { code: unit.getResource() + 'source', stage: 0 },
                { code: unit.getResource() + 'source', stage: 2 },
                { code: unit.getResource() + 'source', stage: 4 },
                { code: unit.getResource(), stage: 0 },
                { code: unit.getResource(), stage: 1 }
            ]
        }];
    },

    last0: function (unit) {
        return [{
            amount: 3,
            units: [ {code: 'coins', stage: 0} ]
        }, {
            units: [ {code: 'rubies', stage: 0} ]
        }];
    },

    last1: function (unit) {
        return [{
            amount: 4,
            units: [ {code: 'coins', stage: 0} ]
        }, {
            units: [ {code: 'rubies', stage: 0} ]
        }];
    },

    last2: function (unit) {
        return [{
            amount: 2,
            units: [ {code: 'coins', stage: 1} ]
        }, {
            units: [ {code: 'rubies', stage: 0} ]
        }];
    }
};
