/**
 * Created by andrey on 24.09.2024
 */

var RemoteProject = function (project) {
    cleverapps.EventEmitter.call(this);

    this.name = project;

    var firebaseClientKey = cleverapps.config.firebaseClientKeys[project];

    this.remoteConfig = firebase.initializeApp(firebaseClientKey, project).remoteConfig();
    this.remoteConfig.defaultConfig = {};

    if (cleverapps.config.debugMode) {
        this.remoteConfig.settings.minimumFetchIntervalMillis = 1000;
    }

    this.initializeThrottle = cleverapps.throttle(10000, cleverapps.accumulate(0, this.initialize.bind(this)));
    this.fetchAndActivateThrottle = cleverapps.throttle(10000, cleverapps.accumulate(0, this.fetchAndActivate.bind(this)));

    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, function () {
        this.fetchAndActivateThrottle();
    }.bind(this));
};

RemoteProject.prototype = Object.create(cleverapps.EventEmitter.prototype);
RemoteProject.prototype.constructor = RemoteProject;

RemoteProject.Projects = {};

RemoteProject.GetProject = function (name) {
    var remoteProject = RemoteProject.Projects[name];

    if (!remoteProject) {
        remoteProject = RemoteProject.Projects[name] = new RemoteProject(name);
    }

    return remoteProject;
};

RemoteProject.isAvailable = function (name) {
    var result = typeof firebase !== "undefined" && !cleverapps.flags.norest;

    var firebaseKey = cleverapps.config.firebaseClientKeys && cleverapps.config.firebaseClientKeys[name];
    if (result && !firebaseKey) {
        cleverapps.throwAsync("Firebase key missing - " + name);
        return;
    }

    return result;
};

RemoteProject.prototype.setDefaultValue = function (key, value) {
    if (typeof value === "object") {
        value = JSON.stringify(value);
    }

    this.remoteConfig.defaultConfig[key] = value;
};

RemoteProject.prototype.initialize = function () {
    if (this.initialized) {
        return;
    }

    var name = this.name;
    // var start = Date.now();

    this.remoteConfig.ensureInitialized()
        .then(function () {
            // console.log("RemoteJson " + name + " initialize success - " + (Date.now() - start) + "ms");
            this.initialized = true;
            this.trigger("initialize");
            this.trigger("update");
        }.bind(this))
        .catch(function (e) {
            console.log("RemoteJson " + name + " initialize error", e);
        });
};

RemoteProject.prototype.fetchAndActivate = function () {
    var name = this.name;

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CONFIG.FETCH_START + name);

    // var start = Date.now();

    this.remoteConfig.fetchAndActivate()
        .then(function () {
            // console.log("RemoteJson " + name + " fetch success - " + (Date.now() - start) + "ms");
            this.trigger("update");
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CONFIG.FETCH_SUCCESS + name);
        }.bind(this))
        .catch(function (e) {
            console.log("RemoteJson " + name + "fetch error", e);
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CONFIG.FETCH_FAILURE + name);
        });
};

RemoteProject.prototype.getValue = function (key) {
    var value = this.remoteConfig.getValue(key);
    value = value && value.asString();

    if (value && key.endsWith("_json")) {
        value = JSON.parse(value);
    }

    return value;
};