/**
 * Created by razial on 06.12.2023
 */

var MissionTreeHintContent = function (options) {
    this.currentTree = options.currentTree;
    this.nextTree = options.nextTree;
    this.previousTree = options.previousTree;
};

MissionTreeHintContent.isApplicable = function (options) {
    return options.currentTree !== undefined || options.nextTree !== undefined || options.previousTree !== undefined;
};

MissionTreeHintContent.prototype.hasSameContent = function (options) {
    return this.currentTree === options.currentTree && this.nextTree === options.nextTree && this.previousTree === options.previousTree;
};

MissionTreeHintContent.prototype.getMessages = function () {
    if (this.currentTree) {
        return [
            { text: "MissionTree.hint.begins" },
            { icon: new cc.Sprite(bundles.reward_icons_pass.frames.pass_star_png) },
            { text: "MissionTree.hint.ends" }
        ];
    }

    if (this.nextTree) {
        return [
            { text: "MissionTree.hint.next", toReplace: { name: cleverapps.unitsLibrary.getUnitName(this.nextTree) } },
            { icon: UnitView.getUnitImage(this.nextTree, { preferStatic: true }) }
        ];
    }

    if (this.previousTree) {
        return [
            { text: "MissionTree.hint.previous" }
        ];
    }
};
