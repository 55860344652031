/**
 * Created by mac on 12/8/20
 */

var ViewReader = {};

ViewReader.shouldSkipWall = function (map) {
    // https://gyazo.com/ae05b9a9560a1c611804dc80d377fd3d
    if (map.isLand(Iso.DOWN_LEFT) && map.isWater(Iso.UP_LEFT) && map.isWater(Iso.HORIZONTAL_LEFT)) {
        return true;
    }

    // https://gyazo.com/d072f8748ccea8a62eb949facfb95c04
    if (map.isLand(Iso.DOWN_RIGHT) && map.isWater(Iso.UP_RIGHT) && map.isWater(Iso.HORIZONTAL_RIGHT)) {
        return true;
    }

    // left inner corner https://gyazo.com/0073495ad17d0101a21a9c2cfacb2b9e
    var dir = { direction: true };
    var sideIsGround, upIsGround;
    var height = map.getTerrainConfig(Iso.DOWN_LEFT).height;
    if (height > 1) {
        for (var i = 1; i <= height; i++) {
            sideIsGround = map.isLand(cleverapps.override(dir, cc.pAdd(Iso.DOWN_LEFT, cc.pMult(Iso.UP_LEFT, i))));
            upIsGround = map.isLand(cleverapps.override(dir, cc.pMult(Iso.UP_LEFT, i)));
            if (sideIsGround && upIsGround) {
                return true;
            }
        }
    }
    // right inner corner https://gyazo.com/9f92de2a1207019cf7da34196a3a8a28
    height = map.getTerrainConfig(Iso.DOWN_RIGHT).height;
    if (height > 1) {
        for (i = 1; i <= height; i++) {
            sideIsGround = map.isLand(cleverapps.override(dir, cc.pAdd(Iso.DOWN_RIGHT, cc.pMult(Iso.UP_RIGHT, i))));
            upIsGround = map.isLand(cleverapps.override(dir, cc.pMult(Iso.UP_RIGHT, i)));
            if (sideIsGround && upIsGround) {
                return true;
            }
        }
    }
};

ViewReader.calcGrowFrame = function (map) {
    var result = map.getGrowFrameData(map.pointer.x, map.pointer.y);

    if (!result) {
        result = ViewReader._calcGrowFrame(map);
        map.setGrowFrameData(map.pointer.x, map.pointer.y, result);
    }

    return result;
};

ViewReader._calcGrowFrame = function (map) {
    if (!ViewReader.isLevel2Tile(map.getGround(Iso.UP_LEFT)) && !ViewReader.isLevel2Tile(map.getGround(Iso.UP_RIGHT))) {
        return map.getTerrainFrameData("grow12_corner", Iso.SAME);
    }

    if (ViewReader.isLevel2Tile(map.getGround(Iso.UP_LEFT)) && ViewReader.isLevel2Tile(map.getGround(Iso.UP_RIGHT))) {
        return map.getTerrainFrameData("grow12_both", Iso.SAME);
    }

    if (ViewReader.isLevel2Tile(map.getGround(Iso.UP_LEFT))) {
        return map.getTerrainFrameData("grow12_left", Iso.SAME);
    }

    return map.getTerrainFrameData("grow12_right", Iso.SAME);
};

ViewReader.isLevel1Tile = function (ground) {
    return ground === Map2d.TILE_GREEN_LEVEL_1 || ground === Map2d.TILE_IMPASSABLE_LEVEL_1;
};

ViewReader.isLevel2Tile = function (ground) {
    return ground === Map2d.TILE_GREEN_LEVEL_2 || ground === Map2d.TILE_IMPASSABLE_LEVEL_2;
};

ViewReader.calcBorderFrameUp = function (map) {
    if (ViewReader.isLevel2Tile(map.getGround(Iso.UP_ABOVE))
        && !ViewReader.isLevel2Tile(map.getGround(Iso.UP_LEFT)) && !ViewReader.isLevel2Tile(map.getGround(Iso.UP_RIGHT))) {
        return map.getTerrainFrameData("level2_up_corner", Iso.UP_ABOVE);
    }

    if (ViewReader.isLevel2Tile(map.getGround(Iso.UP_LEFT)) && ViewReader.isLevel2Tile(map.getGround(Iso.UP_RIGHT))) {
        return map.getTerrainFrameData("level2_up_both", Iso.UP_RIGHT);
    }

    if (map.isWater(Iso.UP_LEFT) && map.isWater(Iso.UP_RIGHT) && ViewReader.isLevel1Tile(map.getGround(Iso.UP_ABOVE))) {
        return map.getTerrainFrameData("level1_up_corner", Iso.UP_ABOVE);
    }

    if (ViewReader.isLevel1Tile(map.getGround(Iso.UP_LEFT)) && ViewReader.isLevel1Tile(map.getGround(Iso.UP_RIGHT))) {
        return map.getTerrainFrameData("level1_up_both", Iso.UP_RIGHT);
    }
};

ViewReader.calcBorderFrameDown = function (map) {
    if (map.isLand(Iso.DOWN_RIGHT) && map.isLand(Iso.DOWN_LEFT)
        && (map.isLand(Iso.HORIZONTAL_RIGHT) || map.isLand(Iso.HORIZONTAL_LEFT))) {
        return map.getTerrainFrameData("level1_down_both", Iso.DOWN_RIGHT);
    }

    if (map.isWater(Iso.DOWN_LEFT) && map.isWater(Iso.DOWN_RIGHT) && map.isLand(Iso.DOWN_BELOW)) {
        return map.getTerrainFrameData("level1_down_corner", Iso.DOWN_BELOW);
    }

    if (ViewReader.shouldSkipWall(map)) {
        return undefined;
    }

    if (map.isLand(Iso.DOWN_RIGHT)) {
        return map.getTerrainFrameData("level1_down_right_only", Iso.DOWN_RIGHT);
    }

    if (map.isLand(Iso.DOWN_LEFT)) {
        return map.getTerrainFrameData("level1_down_left_only", Iso.DOWN_LEFT);
    }
};

ViewReader.calcBorderFrameLeft = function (map) {
    if (ViewReader.isLevel2Tile(map.getGround(Iso.DOWN_LEFT)) && ViewReader.isLevel2Tile(map.getGround(Iso.UP_LEFT))) {
        if (!ViewReader.isLevel2Tile(map.getGround(Iso.UP_ABOVE))) {
            return map.getTerrainFrameData("level2_left_inner_last", Iso.DOWN_LEFT);
        }
        return map.getTerrainFrameData("level2_left_inner", Iso.DOWN_LEFT);
    }

    if (ViewReader.isLevel1Tile(map.getGround(Iso.DOWN_LEFT)) && ViewReader.isLevel1Tile(map.getGround(Iso.UP_LEFT))) {
        if (!ViewReader.isLevel1Tile(map.getGround(Iso.UP_ABOVE))) {
            return map.getTerrainFrameData("level1_left_inner_last", Iso.DOWN_LEFT);
        }
        return map.getTerrainFrameData("level1_left_inner", Iso.DOWN_LEFT);
    }

    if (ViewReader.isLevel2Tile(map.getGround(Iso.UP_LEFT))) {
        if (map.isWater(Iso.UP_ABOVE)) {
            return map.getTerrainFrameData("level2_up_left_only_last", Iso.UP_LEFT);
        }
        return map.getTerrainFrameData("level2_up_left_only", Iso.UP_LEFT);
    }

    if (ViewReader.isLevel1Tile(map.getGround(Iso.UP_LEFT))) {
        if (!ViewReader.isLevel1Tile(map.getGround(Iso.UP_ABOVE))) {
            return map.getTerrainFrameData("level1_up_left_only_last", Iso.UP_LEFT);
        }
        return map.getTerrainFrameData("level1_up_left_only", Iso.UP_LEFT);
    }

    if (map.isWater(Iso.DOWN_LEFT) && map.isWater(Iso.UP_LEFT) && map.isLand(Iso.HORIZONTAL_LEFT)) {
        return map.getTerrainFrameData("level1_left_corner", Iso.HORIZONTAL_LEFT);
    }
};

ViewReader.calcBorderFrameRight = function (map) {
    if (ViewReader.isLevel2Tile(map.getGround(Iso.DOWN_RIGHT)) && ViewReader.isLevel2Tile(map.getGround(Iso.UP_RIGHT))) {
        if (!ViewReader.isLevel2Tile(map.getGround(Iso.UP_ABOVE))) {
            return map.getTerrainFrameData("level2_right_inner_last", Iso.DOWN_RIGHT);
        }
        return map.getTerrainFrameData("level2_right_inner", Iso.DOWN_RIGHT);
    }

    if (ViewReader.isLevel1Tile(map.getGround(Iso.DOWN_RIGHT)) && ViewReader.isLevel1Tile(map.getGround(Iso.UP_RIGHT))) {
        if (!ViewReader.isLevel1Tile(map.getGround(Iso.UP_ABOVE))) {
            return map.getTerrainFrameData("level1_right_inner_last", Iso.DOWN_RIGHT);
        }
        return map.getTerrainFrameData("level1_right_inner", Iso.DOWN_RIGHT);
    }

    if (ViewReader.isLevel2Tile(map.getGround(Iso.UP_RIGHT))) {
        if (map.isWater(Iso.UP_ABOVE)) {
            return map.getTerrainFrameData("level2_up_right_only_last", Iso.UP_RIGHT);
        }
        return map.getTerrainFrameData("level2_up_right_only", Iso.UP_RIGHT);
    }

    if (ViewReader.isLevel1Tile(map.getGround(Iso.UP_RIGHT))) {
        if (map.isWater(Iso.UP_ABOVE)) {
            return map.getTerrainFrameData("level1_up_right_only_last", Iso.UP_RIGHT);
        }
        return map.getTerrainFrameData("level1_up_right_only", Iso.UP_RIGHT);
    }

    if (map.isWater(Iso.DOWN_RIGHT) && map.isWater(Iso.UP_RIGHT) && map.isLand(Iso.HORIZONTAL_RIGHT)) {
        return map.getTerrainFrameData("level1_right_corner", Iso.HORIZONTAL_RIGHT);
    }
};

ViewReader.unlockBorders = function () {
    ViewReader.lockedBorders = [];
};

ViewReader.frameBorderLocksCache = {};

ViewReader.getFrameBorderLocks = function (frame) {
    var frameKey = frame.toString();

    if (ViewReader.frameBorderLocksCache[frameKey]) {
        return ViewReader.frameBorderLocksCache[frameKey];
    }

    var locks = [];
    var styles = cleverapps.styles.ViewReader.borderLocks;

    if (styles.upBoth.indexOf(frame) !== -1) {
        locks.push(ViewReader.UP_LEFT_LOCK);
        locks.push(ViewReader.UP_RIGHT_LOCK);
    } else if (styles.upLeft.indexOf(frame) !== -1) {
        locks.push(ViewReader.UP_LEFT_LOCK);
    } else if (styles.upRight.indexOf(frame) !== -1) {
        locks.push(ViewReader.UP_RIGHT_LOCK);
    }

    ViewReader.frameBorderLocksCache[frameKey] = locks;
    return ViewReader.frameBorderLocksCache[frameKey];
};

ViewReader.lockBorders = function (frame) {
    if (!frame) {
        return;
    }

    var locks = ViewReader.getFrameBorderLocks(frame);

    if (locks.length === 0) {
        return frame;
    }

    if (cleverapps.intersect(locks, ViewReader.lockedBorders).length > 0) {
        return;
    }

    ViewReader.lockedBorders = ViewReader.lockedBorders.concat(locks);
    return frame;
};

ViewReader.calcBorderType = function (map, temporaryMap) {
    var borderType = map.getBorderType(map.pointer.x, map.pointer.y);
    if (borderType && !temporaryMap) {
        return borderType;
    }

    ViewReader.unlockBorders();

    var up = ViewReader.calcBorderFrameUp(map);
    var down = ViewReader.calcBorderFrameDown(map);
    var left = ViewReader.calcBorderFrameLeft(map);
    var right = ViewReader.calcBorderFrameRight(map);

    borderType = [up, right, left, down].filter(function (border) {
        return border && ViewReader.lockBorders(border.res);
    });

    if (!temporaryMap) {
        map.setBorderType(map.pointer.x, map.pointer.y, borderType);
    }

    return borderType;
};

ViewReader.createTopYAnchorElement = function (data) {
    var width = cleverapps.styles.Map2dView.cell.width;
    var height = cleverapps.styles.Map2dView.cell.height;

    var content = new cc.Sprite(data.res);

    var anchorX = data.options.anchorX !== undefined ? data.options.anchorX : 0.5;
    var anchorY = data.options.anchorY !== undefined ? data.options.anchorY : 1;

    var pos = cc.p(0, 0);
    if (data.options.y !== undefined) {
        pos.y = -height / 2 + data.options.y;
    } else if (anchorY === 0) {
        pos.y = -height / 2;
    } else if (anchorY === 1) {
        pos.y = height / 2 + 1;
    }

    if (data.options.x !== undefined) {
        pos.x = -width / 2 + data.options.x;
    } else if (anchorX === 0) {
        pos.x = -width / 2;
    } else if (anchorX === 1) {
        pos.x = width / 2;
    }

    content.setAnchorPoint(anchorX, anchorY);
    content.setPositionRound(pos);
    return content;
};

ViewReader.hasView = function (code, map) {
    return code === Map2d.TILE_BORDER && ViewReader.calcBorderType(map).length > 0
        || [Map2d.TILE_GROW, Map2d.TILE_WATER, Map2d.TILE_WATER_UNIT, Map2d.TILE_GREEN_LEVEL_1, Map2d.TILE_GREEN_LEVEL_2].indexOf(code) !== -1;
};

ViewReader.parse = function (code, map) {
    var frames = map.getTerrainFrames(Iso.SAME);
    var isOdd = (map.pointer.x + map.pointer.y) % 2;
    var frame;

    switch (code) {
        case Map2d.TILE_GROW:
            return ViewReader.createTopYAnchorElement(ViewReader.calcGrowFrame(map));

        case Map2d.TILE_WATER:
        case Map2d.TILE_WATER_UNIT:
            var tilesTexture = Map2d.getTilesTexture(Map2d.currentMap && Map2d.currentMap.tilesSkin);
            frame = bundles[tilesTexture] && bundles[tilesTexture].frames.water;
            return frame ? Map2dWaterView.GetFromPool(frame) : undefined;

        case Map2d.TILE_BORDER:
            var borderTypes = ViewReader.calcBorderType(map);
            return borderTypes.length > 0 ? new Map2dBorderView(borderTypes) : undefined;

        case Map2d.TILE_GREEN_LEVEL_1:
        case Map2d.TILE_GREEN_LEVEL_2:
            return Map2dGroundView.GetFromPool(isOdd ? frames.green_ground_1 : frames.green_ground_2);
        case Map2d.TILE_IMPASSABLE_LEVEL_1:
        case Map2d.TILE_IMPASSABLE_LEVEL_2:
            if (frames.green_ground_3) {
                return Map2dGroundView.GetFromPool(frames.green_ground_3);
            }

            return Map2dGroundView.GetFromPool(isOdd ? frames.green_ground_1 : frames.green_ground_2);
        case Map2d.TILE_GROUND_CHESS:
            return Map2dChessGroundView.GetFromPool(isOdd ? frames.chess_1 : frames.chess_2);
        case Map2d.TILE_STARRED_GROUND_CHESS:
            if (cleverapps.config.debugMode) {
                return Map2dChessGroundView.GetFromPool(bundles.dev_resources.frames.chess_debug);
            }
    }
};

ViewReader.UP_LEFT_LOCK = 0;
ViewReader.UP_RIGHT_LOCK = 1;

ViewReader.BORDER_ANCHOR = { UP: 0, DOWN: 1 };

cleverapps.styles.ViewReader = {
    borderLocks: {
        upBoth: [
        ],

        upLeft: [
        ],

        upRight: [
        ]
    }
};

(function () {
    var borderLocks = cleverapps.styles.ViewReader.borderLocks;

    var addBorderLocks = function (frames) {
        borderLocks.upBoth = borderLocks.upBoth.concat([
            frames.level1_up_corner,
            frames.level1_up_both,
            frames.level2_up_corner,
            frames.level2_up_both,
            frames.level1_up_right_only,
            frames.level1_up_left_only,
            frames.level2_up_right_only,
            frames.level2_up_left_only
        ]);

        borderLocks.upLeft = borderLocks.upLeft.concat([
            frames.level1_up_left_only_last,
            frames.level2_up_left_only_last,
            frames.level1_left_inner,
            frames.level1_left_inner_last,
            frames.level2_left_inner,
            frames.level2_left_inner_last
        ]);

        borderLocks.upRight = borderLocks.upRight.concat([
            frames.level1_up_right_only_last,
            frames.level2_up_right_only_last,
            frames.level1_right_inner,
            frames.level1_right_inner_last,
            frames.level2_right_inner,
            frames.level2_right_inner_last
        ]);
    };

    Object.values(Map2d.TERRAIN_BUNDLES).forEach(function (bundle) {
        addBorderLocks(bundle.frames);
    });

    borderLocks.upBoth = cleverapps.unique(borderLocks.upBoth);
    borderLocks.upLeft = cleverapps.unique(borderLocks.upLeft);
    borderLocks.upRight = cleverapps.unique(borderLocks.upRight);
}());