/**
 * Created by mac on 9/16/21
 */

var ReturnBonus = function () {
    if (cleverapps.user.isReturnedPlayer()) {
        this.hasReward = true;
    }

    this.checkSocial();
    cleverapps.user.on("incLevel", this.checkSocial.bind(this));
};

ReturnBonus.prototype.checkSocial = function () {
    if (connector.info.source !== "vk") {
        return;
    }

    var actions = Object.keys(ReturnBonus.VK_PROMO).map(function (promoId) {
        promoId = Number(promoId);
        var config = ReturnBonus.VK_PROMO[promoId];

        return function (f) {
            if (!this.isVkBonusAvailable(config)) {
                f();
                return;
            }

            if (!connector.social.isConnected() || this.hasReward) {
                f();
                return;
            }

            this.hasVkPromoGift(promoId, function (result) {
                this.hasReward = result;

                if (this.hasReward) {
                    this.promoId = promoId;
                }
                f();
            }.bind(this));
        }.bind(this);
    }.bind(this));

    new ActionPlayer(actions).play();
};

ReturnBonus.prototype.shouldReward = function () {
    return this.hasReward && !cleverapps.config.wysiwygMode;
};

ReturnBonus.prototype.isVkBonusAvailable = function (config) {
    if (!cleverapps.user.checkAvailable(config)) {
        return false;
    }

    if (connector.platform.isTester()) {
        return true;
    }

    return Date.now() > new Date(config.start).getTime() && Date.now() < new Date(config.end).getTime();
};

ReturnBonus.prototype.hasVkPromoGift = function (promoId, callback) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.RETURNBONUS_CHECK + promoId);

    connector.platform.callNative("apps.promoHasActiveGift", { promo_id: promoId }, function (code, data) {
        callback(code === connector.CODE_SUCCEED && data.response === 1);
    });
};

ReturnBonus.prototype.useVkPromoGift = function (promoId) {
    connector.platform.callNative("apps.promoUseGift", { promo_id: promoId }, function (code, data) {
        code = code === connector.CODE_SUCCEED && data.response === 1 ? connector.CODE_SUCCEED : connector.CODE_FAILED;
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PROMO_REWARD + promoId + "_" + code);
    });
};

ReturnBonus.prototype.giveReward = function () {
    cleverapps.focusManager.display({
        focus: "ReturnBonusRewardWindow",
        action: function (f) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.RETURNBONUS_REWARD);

            this.hasReward = false;

            var windowTitle = "ReturnBonusRewardWindow.title";

            if (this.promoId) {
                var promoId = this.promoId;
                this.useVkPromoGift(promoId);

                if (this.promoId === 34) {
                    windowTitle = "PromoBonusRewardWindow.title";
                }
                delete this.promoId;
            }

            new RewardWindow(RewardsConfig.ReturnBonus, {
                title: windowTitle,
                fireworks: true,
                event: cleverapps.EVENTS.EARN.RETURN
            });

            cleverapps.focusManager.onceNoWindowsListener = f;
        }.bind(this)
    });
};

ReturnBonus.REWARD_TIMEOUT = cleverapps.parseInterval("1 hour");

ReturnBonus.VK_PROMO = {
    33: {
        projectName: ["wordsoup", "mergecraft", "wondermerge"],
        start: "2024-02-05",
        end: "2024-08-06"
    },
    34: {
        projectName: ["wordsoup", "mergecraft", "wondermerge"],
        start: "2024-04-01",
        end: "2024-06-01",
        level: 2
    }
};

if (cleverapps.config.name === "wordsoup") {
    ReturnBonus.VK_PROMO[34].level = 0;
}