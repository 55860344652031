/**
 * Created by iamso on 08.07.2021
 */

var InviteIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.invite_icon_json,
        priority: SideBar.PRIORITY_REDUNDANT
    });
};

InviteIcon.prototype = Object.create(SideBarIcon.prototype);
InviteIcon.prototype.constructor = InviteIcon;

InviteIcon.prototype.onPressed = function () {
    if (connector.social.isLoggedIn() || !connector.social.isNeedsLoginForInvite) {
        this.performInvite();
    } else {
        cleverapps.focusManager.display({
            focus: "InviteIcon",
            action: this.performInvite.bind(this)
        });
    }
};

InviteIcon.prototype.performInvite = function (f) {
    cleverapps.invites.invite(function (success) {
        if (success) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.INVITE_ICON_INVITE);
        }
        f && f();
    });
};

InviteIcon.prototype.resetState = function () {
    this.available = levels.user.checkAvailable(cleverapps.Availables.INVITE_FRIENDS_ICON)
        && connector.social.isSupportsNativeInvite;

    if (this.available) {
        if (cleverapps.config.type === "merge") {
            this.available = cleverapps.environment.isMainScene() && !cleverapps.travelBook.isExpedition();
        }
    }
};
