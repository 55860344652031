/**
 * Created by Denis Kuzin on 26 october 2021
 */

var BarrelView = function (barrel, unitView) {
    this.unitView = unitView;
};

BarrelView.prototype.onSpawn = function () {
    this.unitView.sprite.setAnimationAndIdleAfter("animation", "idle");

    cleverapps.audio.playSound(bundles.merge.urls.barrel_appear_sfx);
};

BarrelView.prototype.onDestruction = function () {
    var hideAnimation = cleverapps.config.name === "wondermerge";

    this.unitView.unit.onDestruction(hideAnimation);

    if (hideAnimation) {
        this.unitView.sprite.setAnimation(0, "hide", false);
        this.unitView.sprite.setCompleteListener(this.unitView.removeFromParent.bind(this));
    }

    cleverapps.audio.playSound(bundles.merge.urls.barrel_hide_sfx);
};