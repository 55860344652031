/**
 * Created by Andrey Popov on 12.03.24
 */

var QuestsGroupIconView = cc.Scale9Sprite.extend({
    ctor: function (model) {
        this.model = model;

        var styles = cleverapps.styles.QuestsGroupIconView;

        var frame = bundles.questsgroupicon.frames.quests_scroll_bg;
        this._super(frame, cleverapps.UI.getScale9Rect(frame, cleverapps.UI.Scale9Rect.TwoPixelXY));

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);

        this.icons = Game.currentGame.quests.quests.map(function (quest) {
            var questIcon = new QuestIcon(quest, { images: QuestIcon.Images.questsgroup });
            this.makeIconInteractive(questIcon);
            return questIcon;
        }.bind(this));
        this.icons.reverse();

        this.icons = this.icons.concat(this.createFakeIcons());

        var iconsLayout = this.iconsLayout = new cleverapps.Layout(this.icons, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });

        var scrollView = this.scrollView = new cleverapps.UI.ScrollView(iconsLayout, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL,
            scrollBarEnabled: false,
            mouseScroll: false,
            snapHandler: this.snapHandler.bind(this)
        });

        this.iconSlotHeight = this.icons[0].height + styles.margin;

        this.updateScrollTouchState();

        this.scrollView.setContentSize(this.width, this.height);
        scrollView.setAnchorPoint(0.5, 0.5);
        scrollView.setPositionRound(this.width / 2, this.height / 2);
        scrollView.scrollToDefault();

        this.addChild(scrollView);

        var aim = new cc.Node();
        aim.setAnchorPoint(0.5, 0.5);
        aim.setPositionRound({ x: { align: "center" }, y: { align: "top", dy: -styles.padding.y - this.icons[0].height / 2 } });
        this.addChild(aim);
        cleverapps.aims.registerTarget(["quests"], aim, { controls: this.model.control });

        this.model.onScrollToItemListener = this.createListener(this.scrollToItem.bind(this));
        this.model.onGetIconViewListener = this.createListener(this.getIconView.bind(this));
        this.model.onAddIconViewListener = this.createListener(this.addIconView.bind(this));
        this.model.onRemoveIconViewListener = this.createListener(this.removeIconView.bind(this));
    },

    addIconView: function (iconView, callback) {
        callback = callback || function () {};
        var currentIcon = this.icons.find(function (icon) {
            return !icon.fake && icon.quest.id === iconView.quest.id;
        });
        if (!currentIcon) {
            this.icons.forEach(function (icon) {
                icon.runAction(new cc.MoveBy(0.3, 0, -this.iconSlotHeight).easing(cc.easeBackInOut()));
            }.bind(this));
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.CallFunc(
                function () {
                    if (currentIcon) {
                        iconView.removeFromParent();
                        iconView = currentIcon;
                    } else {
                        this.icons.unshift(iconView);
                        if (this.icons.length > 3 && this.icons.at(-1).fake) {
                            this.icons.pop().removeFromParent();
                        }
                        if (iconView.getParent()) {
                            iconView.removeTemporarily(false);
                        }
                        this.iconsLayout.setItems(this.icons, false);

                        this.icons.forEach(this.makeIconInteractive.bind(this));
                    }

                    this.scrollView.updateInnerContent();
                    this.scrollView.scrollToDefault();

                    iconView.runAction(new cc.Sequence(
                        new cc.ScaleTo(0.2, 0.8).easing(cc.easeOut(1)),
                        new cc.ScaleTo(0.2, 1).easing(cc.easeBackOut())
                    ));

                    this.updateScrollTouchState();
                    callback();
                }.bind(this)
            )
        ));
    },

    removeIconView: function (iconView, callback) {
        var deltaUp = Math.min(this.iconSlotHeight, this.scrollView.getContainerPosition().y - this.scrollView.min.y);
        var deltaDown = Math.min(this.iconSlotHeight - deltaUp, this.scrollView.max.y - this.scrollView.getContainerPosition().y);
        deltaUp += this.iconSlotHeight - deltaDown - deltaUp;

        var iconIndex = this.icons.indexOf(iconView);

        this.icons.forEach(function (icon, i) {
            if (i === iconIndex) {
                return;
            }

            icon.runAction(new cc.Sequence(
                new cc.DelayTime(0.1),
                new cc.MoveBy(0.2, 0, i < iconIndex ? -deltaDown : deltaUp).easing(cc.easeBackInOut())
            ));
        });

        iconView.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.ScaleTo(0.3, 0).easing(cc.easeOut(3)),
                new cc.MoveBy(0.5, -this.width / 2, 0).easing(cc.easeOut(3))
            ),
            new cc.RemoveSelf(),
            new cc.CallFunc(function () {
                this.icons.splice(iconIndex, 1);
                this.createFakeIcons().forEach(function (fakeIcon) {
                    this.icons.push(fakeIcon);
                    this.iconsLayout.addChild(fakeIcon);
                }.bind(this));

                this.iconsLayout.reshape();
                this.scrollView.updateInnerContent();

                var iconForScrollTo = this.icons[iconIndex];
                if (!iconForScrollTo || iconForScrollTo.fake) {
                    iconForScrollTo = this.icons[iconIndex - 1];
                }
                this.scrollToItem(iconForScrollTo, this.updateScrollTouchState.bind(this), true);

                callback();
            }.bind(this))
        ));
    },

    getIconView: function (quest) {
        return this.icons.filter(function (icon) {
            return icon.quest === quest;
        })[0];
    },

    scrollToItem: function (iconView, callback, silent) {
        callback = callback || function () {};

        if (!iconView || this.icons.indexOf(iconView) === 0) {
            iconView = this.icons[1];
        }

        var iconIndex = this.icons.indexOf(iconView);
        if (iconIndex > 1 && this.icons.length === 4 && this.icons[3].fake) {
            iconView = this.icons[iconIndex - 1];
        }

        var styles = cleverapps.styles.QuestsGroupIconView;
        var p = cc.p(0, Math.max(iconView.y - this.scrollView.innerContent.height / 2 - styles.margin, this.scrollView.min.y));

        if (Math.abs(this.scrollView.getContainerPosition().y - p.y) <= styles.scrollSensitivity) {
            callback();
        } else if (silent) {
            this.scrollView.scrollToPoint(p);
            callback();
        } else {
            this.scrollView.runAction(
                new cc.Sequence(
                    new cc.ScrollAction(p, {
                        duration: 0.3,
                        targetPoint: true
                    }).easing(cc.easeBackInOut()),
                    new cc.DelayTime(0.1),
                    new cc.CallFunc(callback)
                )
            );
        }
    },

    createFakeIcons: function () {
        var icons = [];
        for (var i = 0; i < 4 - this.icons.length; i++) {
            var fakeIcon = new cc.Sprite(bundles.questsgroupicon.frames.quest_icon_bg);
            fakeIcon.setAnchorPoint(0.5, 0.5);
            fakeIcon.fake = true;
            fakeIcon.setVisible(false);
            icons.push(fakeIcon);
        }
        return icons;
    },

    snapHandler: function (position, isMoveUp) {
        var styles = cleverapps.styles.QuestsGroupIconView;
        var h = this.iconSlotHeight;

        var wanted = Math.ceil(position.y / h - 1) * h + h - 2 * styles.padding.y;
        if (this.icons.length % 2 === 0) {
            wanted -= h / 2;
        }

        if (isMoveUp) {
            return cc.p(0, wanted > position.y ? wanted - position.y : wanted + h - position.y);
        }
        return cc.p(0, wanted < position.y ? wanted - position.y : wanted - h - position.y);
    },

    updateScrollTouchState: function () {
        var hasFakeIcons = this.icons.filter(function (icon) {
            return icon.fake;
        }).length > 0;

        this.scrollView.touchScrollDisabled = hasFakeIcons;
    },

    makeIconInteractive: function (icon) {
        if (icon.fake) {
            return;
        }

        icon.update();
        icon.quest.on("updateProgress", icon.updateProgress.bind(icon), icon);

        var clickHandler = function () {
            cleverapps.audio.playSound(bundles.main.urls.click_effect);

            if (this.scrollView.isVisiblePoint(icon)) {
                this.model.onPressed(icon);
            } else {
                this.scrollToItem(icon, function () {
                    this.model.onPressed(icon);
                }.bind(this));
            }
        }.bind(this);

        var dragHandler = cleverapps.UI.onDrag(icon, {
            onClick: clickHandler,
            onDragStart: this.model.hideInfo.bind(this.model)
        });
        dragHandler.interactiveScale = true;

        if (cleverapps.config.debugMode) {
            cleverapps.UI.onClick(icon.progress, clickHandler, {
                onDoubleClick: function () {
                    icon.quest.addProgress(1);
                },
                interactiveScale: false
            });
        }
    },

    onShow: function () {
        this.model.allowInfoView();
    },

    onHide: function () {
        this.model.denyInfoView();
    }
});

cleverapps.styles.QuestsGroupIconView = {
    width: 210,
    height: 720,
    margin: 30,
    scrollSensitivity: 6,

    padding: {
        x: 0,
        y: 20
    }
};
