/**
 * Created by mac on 12/23/20
 */

var Collectible = function (unit) {
    UnitComponent.call(this, unit);

    var data = this.unit.getData();

    if (data.feedableTarget) {
        this.feedables = [];
    }
};

Collectible.prototype = Object.create(UnitComponent.prototype);
Collectible.prototype.constructor = Collectible;

Collectible.prototype.onCollection = function (index, callback) {
    if (this.view) {
        this.view.collectionAnimation(index, callback);
    } else {
        callback();
    }
};

Collectible.prototype.onFeed = function (target) {
    Map2d.currentMap.showLayerTile(Map2d.LAYER_UNITS, this.unit.x, this.unit.y);

    if (this.view) {
        this.view.feedAnimation(target);
    } 
};

Collectible.prototype.needCollectConfirmation = function () {
    var lastStage = Families[this.unit.code].units.length - 1;

    if (this.unit.code === "energy") {
        return this.unit.stage !== lastStage;
    }

    return !cleverapps.gameModes.silentInfoView && this.unit.getType() === "valuables" && this.unit.stage >= 2 && this.unit.stage !== lastStage;
};

Collectible.prototype.getActionInfo = function () {
    if (this.needCollectConfirmation()) {
        return {
            buttons: {
                valuable: {
                    action: this.collect.bind(this),
                    unit: this.unit
                }
            },
            title: "Collect"
        };
    }
};

Collectible.prototype.setFeedables = function (feedables) {
    this.feedables = feedables;
};

Collectible.prototype.canRemoved = function () {
    return this.feedables && this.feedables.length === 0;
};

Collectible.prototype.findFeadableTarget = function () {
    var targetUnit = undefined;
    var minDistance = Number.MAX_VALUE;
    var feedables = this.feedables;
    for (var i = 0; i < feedables.length; i++) {
        var feedable = feedables[i];
        if (Map2d.currentMap.getUnit(feedable.unit.x, feedable.unit.y) === feedable.unit && feedable.canCollect(this.unit)) {
            var distance = cc.pDistance(feedable.unit, this.unit);
            if (distance < minDistance) {
                minDistance = distance;
                targetUnit = feedable.unit;
            }
        }
    }

    return targetUnit;
};

Collectible.prototype.handleClick = function () {
    if (this.tryCollect()) {
        cleverapps.audio.playSound(bundles.main.urls.click_effect);
        return true;
    }
};

Collectible.prototype.tryCollect = function () {
    var data = this.unit.getData();

    if (data.unmergeable && !data.feedableTarget) {
        this.collect();
        return true;
    }

    if (data.feedableTarget) {
        var targetUnit = this.findFeadableTarget();

        if (targetUnit) {
            this.collect(targetUnit);
            return true;
        }
        cleverapps.centerHint.createTextHint("CenterHint.noOneToFeed");
        return false;
    }

    if (this.needCollectConfirmation()) {
        return false;
    }

    if (data.collectible.harvested) {
        var capacity = Game.currentGame.harvested.getCapacity();
        var amount = Game.currentGame.harvested.getItemsAmount();

        if (capacity - amount < this.getValue()) {
            Game.currentGame.harvested.needsToShowTip();
            return false;
        }
    }

    this.collect();
    return true;
};

Collectible.prototype.collect = function (target, index) {
    if (this.collected) {
        return;
    }

    if (this.unit.getType() === "fruit") {
        var x = this.unit.x;
        var y = this.unit.y;

        Game.currentGame.counter.setTimeout(function () {
            [
                { x: 1, y: 0 },
                { x: -1, y: 0 },
                { x: 0, y: 1 },
                { x: 0, y: -1 }
            ].forEach(function (dir) {
                var unit = Map2d.currentMap.getUnit(x + dir.x, y + dir.y);

                if (unit && unit.code === this.unit.code && unit.stage === this.unit.stage && !unit.points) {
                    unit.findComponent(Collectible).collect();
                }
            }, this);
        }.bind(this), 100);
    }

    this.collected = true;

    if (this.unit.findComponent(Pulsing)) {
        this.unit.findComponent(Pulsing).setRemoved(true);
    }

    Game.currentGame.advice.boo();

    if (this.unit.getData().feedableTarget && target && target.isFeedable()) {
        target.findComponent(Feedable).feed();
        this.onFeed(target);
    } else if (this.unit.getData().collectible.harvested) {
        this.onCollection(index, Game.currentGame.harvested.onChange.bind(Game.currentGame.harvested));
        Game.currentGame.harvested.add(this.unit.code, this.getValue(), true);
    } else if (this.unit.code === "wands") {
        this.unit.onDestruction();
        Game.currentGame.addReward(this.unit.code, this.getValue(), this.unit, {
            withoutDelta: true
        });
    } else {
        Game.currentGame.addReward(this.unit.code, this.getValue(), this.unit, {
            path: true,
            flyingAnimation: Reward.COLLECT_ANIMATION
        });
    }

    this.unit.remove(true);

    Game.currentGame.setTimeout(function () {
        Map2d.mapEvent(Map2d.HARVEST, { affected: this.unit });
    }.bind(this), 0);
};

Collectible.prototype.getValue = function () {
    return this.unit.getData().value;
};
