/**
 * Created by Vladislav on 25.09.2024.
 */

var WandsMenuBarItem = {
    name: "WandsItem",
    plusButton: false,
    targets: "wands",
    value: function () {
        return Game.currentGame.wands;
    },
    updater: function (f) {
        Game.currentGame.onChangeWandsListener = f;
    },
    onClickWindow: GuideWindow,
    onClickOptions: function () {
        var expedition = cleverapps.travelBook.getCurrentExpedition();
        return expedition ? ExpeditionWandsGuideOptions : {
            name: "WandsGuideWindow",
            bundle: bundles.wandsguidewindow
        };
    },
    icon: function () {
        return cleverapps.skins.getSlot("merge_wand_png") || bundles.menubar.frames.merge_wand_png;
    },
    deltaSound: bundles.menubar.urls.wand_fly_finish_effect,
    availableOnScenes: function () {
        var page = cleverapps.travelBook.getCurrentPage();
        if (page.withWands()) {
            return [cleverapps.Environment.SCENE_MAIN];
        }
    },
    sparks: true
};