/**
 * Created by evgeny on 18.06.2024
 */

var SwitchableView = cc.Node.extend({
    ctor: function (switchable, unitView) {
        this._super();
        this.switchable = switchable;
        this.unitView = unitView;

        this.showChoice(this.switchable.choice);
    },

    animateChoice: function (choice, f) {
        f = f || function () {};

        if (this.unitView.sprite && this.unitView.sprite.hasAnimation("switch" + choice)) {
            this.unitView.sprite.setAnimationAndIdleAfter("switch" + choice, "choice" + choice);
            this.unitView.sprite.setCompleteListenerOnce(f);
        } else {
            this.unitView.setVisible(false);
            this.unitView.runAction(new cc.Sequence(
                AnimationsLibrary.showUp(this.unitView),
                new cc.CallFunc(function () {
                    f();
                })
            ));
        }
    },

    animateHover: function () {
        this.hoverAnimation = this.unitView.sprite.spine.runAction(
            AnimationsLibrary.blinkShader(this.unitView.sprite.spine, {
                action: new cc.Sequence(
                    new cc.MoveBy(1, 0, 5).easing(cc.easeInOut(2)),
                    new cc.MoveBy(1, 0, -5).easing(cc.easeInOut(2))
                )
            })
        );
    },

    stopAnimateHover: function () {
        if (this.hoverAnimation) {
            this.unitView.sprite.spine.stopAction(this.hoverAnimation);
            delete this.hoverAnimation;
        }
    },

    showChoice: function (choice) {
        this.unitView.sprite.setAnimation(0, "choice" + choice, true);
    }
});