/**
 * Created by iamso on 24.05.21
 */

var Barrel = function (unit) {
    UnitComponent.call(this, unit);

    this.unit.setPrizes({ sprite: bundles.buttons_inlined_icons.frames.ad_icon_png });

    this.onRemove = function () {};
    this.onReward = function () {};
    this.onPrizeChanged = function () {};
};

Barrel.prototype = Object.create(UnitComponent.prototype);
Barrel.prototype.constructor = Barrel;

Barrel.prototype.onPrizeHarvested = function () {
    this.remove();
};

Barrel.prototype.onPrizeNotHarvested = function () {
    this.prizes = this.unit.prizes.slice();
    this.onPrizeChanged();
};

Barrel.prototype.onSpawn = function () {
    if (this.view) {
        this.view.onSpawn();
    }
};

Barrel.prototype.onDestruction = function () {
    if (this.view) {
        this.view.onDestruction();
    }
};

Barrel.prototype.restorePrizes = function (stored) {
    this.prizes = stored.map(function (key) {
        return Unit.ParseKey(key);
    });

    this.unit.setPrizes(this.prizes);
};

Barrel.prototype.remove = function () {
    this.onDestruction();
    this.unit.remove(true);

    this.prizes = undefined;

    this.onRemove();
};

Barrel.prototype.getSaveData = function () {
    var data = {
        stage: this.unit.stage
    };

    if (this.prizes) {
        data.prizes = this.prizes.map(function (unit) {
            return Unit.GetKey(unit);
        });
    }

    return data;
};

Barrel.prototype.awardItem = function (rewards) {
    cleverapps.adsLimits.watch(AdsLimits.TYPES.BARREL);

    this.prizes = rewards;
    this.unit.setPrizes(this.prizes);

    this.onReward();
};

Barrel.prototype.showItemAds = function (rewards, onCollectPrizes) {
    this.playingVideo = true;

    cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.BARREL, function () {
        this.awardItem(rewards);

        onCollectPrizes();
    }.bind(this));
};

Barrel.prototype.handlePrizes = function () {
    if (this.prizes) {
        return false;
    }

    cleverapps.focusManager.display({
        focus: "BarrelAdsOpen",
        control: ["MenuBarGoldItem", "MenuBarCoinsItem"],
        action: function (f) {
            new BarrelAdsWindow(this);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }.bind(this)
    });

    return true;
};

Barrel.prototype.spawn = function () {
    var pos = Map2d.currentMap.regions.barrel.positions[0];
    this.unit.setPosition(pos.x, pos.y);

    Map2d.currentMap.add(Map2d.LAYER_UNITS, this.unit.x, this.unit.y, this.unit);
    Map2d.currentMap.onAddUnit(this.unit.x, this.unit.y, this.unit);
    this.onSpawn(this);
};

Barrel.prototype.listItems = function () {
    cleverapps.Random.seed("" + this.unit.stage + Math.round(Date.now() / cleverapps.parseInterval("1 hour")));

    var chosen = [];
    for (var i = 0; i < 3; i++) {
        var reward = cleverapps.Random.choose(Barrel.ITEMS[i]);
        chosen.push(reward);
    }

    return chosen;
};

Barrel.ITEMS = [
    [
        {
            units: [{ code: "sacks", stage: 0 }],
            amount: 1
        },
        {
            units: [{ currentType: "fruit", stage: 4 }],
            amount: 3
        }
    ],
    [
        {
            units: [{ currentType: "hero", stage: 1 }],
            amount: 2
        },
        {
            units: [{ currentType: "resource", stage: 1 }],
            amount: 3
        }
    ],
    [
        {
            units: [{ code: "wands", stage: 0 }],
            amount: 1
        },
        {
            units: [{ code: "worker", stage: 0 }],
            amount: 2
        }
    ]
];
