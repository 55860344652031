/**
 * Created by mac on 12/23/20
 */

var CollectibleView = function (collectible, unitView) {
    this.collectible = collectible;
    this.unitView = unitView;
};

CollectibleView.prototype.feedAnimation = function (target) {
    Map2d.currentMap.showLayerTile(Map2d.LAYER_UNITS, target.x, target.y);

    var targetView = target.onGetView();

    var movingNode = cleverapps.scenes.getMovingNode(this.unitView);

    var localPosition = cc.p(0.3 * targetView.width, 0.6 * targetView.height);
    var targetPosition = movingNode.convertToNodeSpace(targetView.convertToWorldSpace(localPosition));

    var speed = 30;
    var distance = cc.pDistance(target, this.unitView.unit);
    var duration = Math.max(0.5, distance / speed);

    var feedable = target.findComponent(Feedable);

    this.feedingAction = this.unitView.runAction(new cc.Sequence(
        new cc.ReplaceParent(movingNode),
        new cc.JumpAnimation(
            duration, 
            this.unitView, 
            cc.pSub(targetPosition, this.unitView),
            cleverapps.styles.CollectibleView.feedAnimation.height
        ),
        new cc.CallFunc(function () {
            feedable.onFeed();
        }),
        new cc.RemoveSelf()
    ));
};

CollectibleView.prototype.clearAnimations = function () {
    if (this.feedingAction && !this.feedingAction.isDone()) {
        this.unitView.stopAction(this.feedingAction);
        delete this.feedingAction;
    }
};

CollectibleView.prototype.collectionAnimation = function (index, callback) {
    var target;
    var sound;
    var withDelta;

    switch (this.collectible.unit.getType()) {
        case "fruit":
        case "herofood":
            target = "harvested";
            sound = bundles.fruit.urls.fruit_fly_finish_effect;
            break;
    }

    if (target) {
        target = cleverapps.aims.getTarget(target);
    }

    var actions = [];

    actions.push(new cc.Show());
    actions.push(AnimationsLibrary.animateCollect(this.unitView, target, {
        path: true,
        adjustToTarget: true,
        collectEffect: true,
        sound: sound
    }));

    actions.push(new cc.Hide());

    if (withDelta && target) {
        actions.push(AnimationsLibrary.animateDelta("+" + this.collectible.getValue() + " **", {
            target: target,
            icons: {
                "**": UnitView.getUnitImage(this.collectible.unit)
            }
        }));
    }

    actions.push(new cc.CallFunc(callback));
    actions.push(new cc.RemoveSelf());

    this.unitView.stopAllActions();
    this.unitView.runAction(new cc.Sequence(actions));
};

cleverapps.styles.CollectibleView = {
    feedAnimation: {
        height: 240
    }
};