/**
 * Created by mac on 1/2/21
 */

var SpeedUpWindow = CleverappsWindow.extend({
    onWindowLoaded: function (worker) {
        this.worker = worker;

        var boostTimeMission = cleverapps.missionManager.findByType(Mission.TYPE_BOOSTTIME);
        if (boostTimeMission && MissionManager.hasProperParent(boostTimeMission)) {
            this.boostTimeMission = boostTimeMission;
        }

        this.controls = this.boostTimeMission ? "MenuBarCoinsItem" : "MenuBarGoldItem";

        this._super({
            title: "SpeedUpWindow.title",
            name: "SpeedUpWindow",
            content: this.createContent(),
            closeButton: this.boostTimeMission === undefined
        });

        cleverapps.focusManager.showControlsWhileFocused(this.controls);

        if (this.worker.getTimeLeft() <= 0) {
            this.close();
        }
    },

    getPerson: function () {
        return {
            role: "worker",
            skin: cleverapps.skins.getSlot("bonusWorkerPersonSkin")
        };
    },

    createContent: function () {
        var styles = cleverapps.styles.SpeedUpWindow;
        var items = [];

        if (this.boostTimeMission) {
            items.push(this.createTimer());
            items.push(this.createTitle());
            items.push(this.createCoinsButtons());
        } else if (this.worker.isSpeedUpBonusActive()) {
            var willFinish = this.worker.getTimeLeft() <= cleverapps.parseInterval(Workers.SPEEDUP_BONUS_TIME);
            items.push(willFinish && this.createTitle());
            items.push(this.createTimer());
            items.push(this.createAdsButtons(willFinish));
        } else {
            items.push(this.createTitle());
            items.push(this.createTimer());
            items.push(this.createRubyButton(cleverapps.styles.UI.Button.Images.button_blue));
        }

        return new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createTitle: function () {
        var styles = cleverapps.styles.SpeedUpWindow;

        var msg = this.boostTimeMission ? "SpeedUpWindow.coinsText" : "SpeedUpWindow.text";
        var font = this.boostTimeMission ? cleverapps.styles.FONTS.WINDOW_TEXT : cleverapps.styles.FONTS.SPEEDUP_WINDOW_TEXT;
        var text = cleverapps.UI.generateOnlyText(msg, font);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.textWidth, 0);
        text.fitTo(undefined, styles.textHeight);
        return text;
    },

    createRubyButton: function (type, speedUp) {
        var styles = cleverapps.styles.SpeedUpWindow.buttons;

        return new UseGoldButton({
            price: SpeedUpWindow.CalcPrice(this.worker.getTimeLeft()),
            source: this.worker.unit,
            onClicked: this.speedUpForGold.bind(this),
            freeIcon: TextWithIcon.ICONS_BY_NAME.speedup,
            canCoins: true,
            eventName: cleverapps.EVENTS.SPENT.SPEED_UP,
            type: type,
            width: styles.button.width,
            height: styles.button.height,
            speedUp: speedUp
        });
    },

    createCoinsButtons: function () {
        var styles = cleverapps.styles.SpeedUpWindow.buttons;

        var rubyButton = this.createRubyButton(undefined, true);

        var nothanksButton = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "SpeedUpWindow.nothanks",
            onClicked: this.close.bind(this),
            type: cleverapps.styles.UI.Button.Images.button_blue
        });

        return new cleverapps.Layout([rubyButton, nothanksButton], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.coinsMargin,
            padding: styles.padding
        });
    },

    createAdsButtons: function (willFinish) {
        var styles = cleverapps.styles.SpeedUpWindow.buttons;

        var rubyButton = this.createRubyButton(cleverapps.styles.UI.Button.Images.button_blue);

        if (!willFinish) {
            var rubyTitle = cleverapps.UI.generateOnlyText("SpeedUpWindow.rubyHint", cleverapps.styles.FONTS.SPEEDUP_WINDOW_HINT_TEXT);
            rubyTitle.setString(cleverapps.splitHalfByWord(rubyTitle.getString()).join("\n"));
            rubyTitle.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        }

        var rubyLayout = new cleverapps.Layout([rubyTitle, rubyButton], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.button.margin
        });
        rubyLayout.setAnchorPoint(0.5, 0);

        var watchButton = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "##Watch",
            onClicked: this.speedUpForVideo.bind(this),
            type: cleverapps.styles.UI.Button.Images.button_blue
        });

        if (!willFinish) {
            var watchTitle = cleverapps.UI.generateOnlyText("SpeedUpWindow.watchHint", cleverapps.styles.FONTS.SPEEDUP_WINDOW_HINT_TEXT);
            watchTitle.setString(cleverapps.splitHalfByWord(watchTitle.getString()).join("\n"));
            watchTitle.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        }

        var watchLayout = new cleverapps.Layout([watchTitle, watchButton], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.button.margin
        });
        watchLayout.setAnchorPoint(0.5, 0);

        var separator = cleverapps.UI.generateOnlyText("or", cleverapps.styles.FONTS.SPEEDUP_WINDOW_HINT_TEXT);
        separator.setVerticalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        separator.setDimensions(separator.width, watchButton.height);
        separator.setAnchorPoint(0.5, 0);

        return new cleverapps.Layout([rubyLayout, separator, watchLayout], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });
    },

    createTimer: function () {
        var styles = cleverapps.styles.SpeedUpWindow.timer;

        var timeLeft = this.worker.getTimeLeft();
        if (timeLeft > 0) {
            timeLeft = new cleverapps.CountDown(timeLeft, {
                onFinish: this.close.bind(this)
            });
        } else {
            timeLeft = 0;
        }

        var timer = new cleverapps.CountDownView(timeLeft, {
            font: cleverapps.styles.FONTS.SPEEDUP_WINDOW_TIMER_TEXT,
            bar: {
                type: ScaledProgressBar.Types.blue_large_with_frames,
                width: styles.width,
                height: styles.height,
                fullValue: this.worker.getFullJobTime()
            },
            icon: {
                frame: bundles.timer.frames.timer_icon_large,
                json: bundles.timer.jsons.timer_icon_large_json,
                animation: "animation"
            }
        });

        if (this.isStopAvailable()) {
            var stopButton = new cleverapps.UI.Button({
                width: styles.button.width,
                height: styles.button.height,
                content: new cc.Sprite(bundles.buttons_main.frames.stop_icon),
                onClicked: this.stopWorker.bind(this),
                type: cleverapps.styles.UI.Button.Images.button_red
            });

            return new cleverapps.Layout([timer, stopButton], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.margin
            });
        }

        return timer;
    },

    speedUpForGold: function () {
        this.close();

        cleverapps.focusManager.distract({
            focus: "dospeedup",
            control: this.controls,
            actions: [
                function (f) {
                    setTimeout(f, 300);
                },

                function (f) {
                    this.worker.speedUp();
                    f();
                }.bind(this)
            ]
        });
    },

    speedUpForVideo: function () {
        this.close();
        this.worker.speedUpForVideo();
    },

    stopWorker: function () {
        this.close();

        this.worker.completeAssignment();
    },

    isStopAvailable: function () {
        return this.worker.unit && this.worker.unit.findComponent(Buildable) !== undefined;
    }
});

SpeedUpWindow.CalcPrice = function (duration) {
    var factor = 0.7;
    return Math.max(Math.ceil((duration / 60 / 1000 - 0.5) * factor), 0);
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SPEEDUP_WINDOW_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    SPEEDUP_WINDOW_TIMER_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR
    },

    SPEEDUP_WINDOW_HINT_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.SpeedUpWindow = {
    margin: 40,
    textWidth: 1100,
    textHeight: 135,

    timer: {
        width: 490,
        height: 90,
        margin: 20,

        button: {
            x: { align: "right", dx: 125 },
            y: { align: "center", dy: 0 },
            width: 120,
            height: 120
        }
    },

    buttons: {
        margin: 60,
        coinsMargin: 120,
        padding: {
            x: 0,
            y: 0,
            top: 15,
            bottom: 0
        },

        button: {
            width: 340,
            height: 110
        },

        note: {
            margin: 10
        }
    }
};
