/**
 * Created by andrey on 27.01.2021.
 */

var HeroIcon = cc.Node.extend({
    ctor: function (unit, onClick) {
        this._super();
        this.unit = unit;

        var styles = cleverapps.styles.HeroIcon;

        this.background = new cc.Sprite(bundles.unitslibrarywindow.frames.hero_background_unavailable);
        this.background.setPositionRound(styles.background);

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(this.background.width, this.background.height);
        this.addChild(this.background);

        this.icon = new cc.Sprite(bundles.unitslibrarywindow.frames[this.unit.unknownHero ? "icon_unknown" : "icon_" + this.unit.code]);
        this.icon.setPositionRound(this.unit.unknownHero ? styles.icon_unknown : styles.icon);
        this.addChild(this.icon);

        this.setCascadeOpacityEnabled(true);
        this.setCascadeColorEnabled(true);

        if (bundles.unitslibrarywindow.frames.hero_checkmark) {
            this.checkmark = new cc.Sprite(bundles.unitslibrarywindow.frames.hero_checkmark);
            this.checkmark.setAnchorPoint(0.5, 0.5);
            this.checkmark.setPositionRound(styles.checkmark);
            this.checkmark.setVisible(false);
            this.addChild(this.checkmark);
        }

        if (onClick) {
            this.updateState();

            cleverapps.UI.applyHover(this);
            cleverapps.UI.onClick(this, onClick);

            cleverapps.eventBus.on("unitStatusChanged", this.updateState.bind(this), this);
        }
    },

    updateState: function () {
        if (!this.unit.unknownHero && cleverapps.unitsLibrary.hasFresh(this.unit.code)) {
            this.showAttention();
        } else {
            this.hideAttention();
        }

        if (this.selected) {
            this.interactiveScale = false;
            this.showSelection();
        } else {
            this.interactiveScale = true;
            this.hideSelection();
        }

        if (!this.unit.unknownHero && cleverapps.unitsLibrary.isOpened(this.unit)) {
            this.setOpened(true);

            if (this.selected) {
                if (this.attention) {
                    this.attention.setPositionRound(cleverapps.styles.HeroIcon.attention_selected);
                }
            }
        } else {
            this.setOpened(false);
        }
    },

    showAttention: function () {
        if (this.attention) {
            this.attention.setPositionRound(cleverapps.styles.HeroIcon.attention);
            return;
        }

        this.attention = new Attention();
        this.attention.setPositionRound(cleverapps.styles.HeroIcon.attention);
        this.addChild(this.attention);
    },

    hideAttention: function () {
        if (this.attention) {
            this.attention.runAction(new cc.Sequence(
                new cc.ScaleTo(0.15, 0),
                new cc.RemoveSelf()
            ));
            this.attention = undefined;
        }
    },

    showSelection: function () {
        var styles = cleverapps.styles.HeroIcon.selection;

        if (this.selection) {
            this.selection.setPositionRound(styles);
            return;
        }

        this.selection = new cc.Sprite(bundles.unitslibrarywindow.frames.hero_background_selected);
        this.selection.setPositionRound(styles);
        this.selection.setLocalZOrder(-1);
        this.addChild(this.selection);
    },

    hideSelection: function () {
        if (this.selection) {
            this.selection.removeFromParent();
            this.selection = undefined;
        }
    },

    setOpened: function (opened) {
        if (opened) {
            this.background.setSpriteFrame(bundles.unitslibrarywindow.frames.hero_background);
            cleverapps.UI.convertToGrayShape(this.icon, true);

            if (this.checkmark) {
                this.checkmark.setVisible(true);
            }
        } else {
            this.background.setSpriteFrame(bundles.unitslibrarywindow.frames.hero_background_unavailable);

            if (!this.unit.unknownHero) {
                cleverapps.UI.convertToGrayShape(this.icon);
            }

            if (this.checkmark) {
                this.checkmark.setVisible(false);
            }
        }
    },

    onSelect: function () {
        this.selected = true;
        this.updateState();
    },

    onUnSelect: function () {
        this.selected = false;
        this.updateState();
    }
});

cleverapps.styles.HeroIcon = {
    icon: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 12 }
    },

    icon_unknown: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    background: {
        x: { align: "center" },
        y: { align: "center" }
    },

    checkmark: {
        x: { align: "center" },
        y: { align: "center", dy: -108 }
    },

    attention: {
        x: { align: "center", dx: 90 },
        y: { align: "center", dy: 90 }
    },

    attention_selected: {
        x: { align: "center", dx: 100 },
        y: { align: "center", dy: 100 }
    },

    selection: {
        x: { align: "center" },
        y: { align: "center" }
    }
};
