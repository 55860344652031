/**
 * Created by iamso on 23.11.2021
 */

var LockedComponentView = cc.Node.extend({
    ctor: function (locked, unitParentView) {
        this._super();

        this.setContentSize2(unitParentView.getContentSize());

        this.locked = locked;
        this.unitView = unitParentView;

        if (cleverapps.environment.isEditorScene()) {
            this.addEditorSwitch();
            return;
        }

        this.restoreState();
        cleverapps.bundleLoader.ensureSoundsLoaded([bundles.merge.urls.unitlock_open, bundles.merge.urls.unitlock_start_unlock]);
    },

    restoreState: function (animated) {
        if (!this.locked.isLockActive()) {
            this.removeLockIcon(animated);
            this.removeTimer(animated);
            return;
        }

        this.createLockIcon(animated);

        if (this.locked.isUnlockInProcess() && this.locked.getTimeLeft() > 0) {
            this.createTimer(animated);
        } else {
            this.removeTimer(animated);
        }
    },

    createLockIcon: function (animated) {
        var styles = cleverapps.styles.LockedComponentView.lock;

        if (!this.lockIcon) {
            this.lockIcon = new cleverapps.Spine(bundles.merge.jsons.unit_lock_json);
            this.addChild(this.lockIcon);
        }

        if (this.locked.isUnlockInProcess()) {
            this.lockIcon.setPositionRound(this.width / 2, this.height / 2);
            this.lockIcon.setAnimation(0, "lock", true);

            if (animated) {
                cleverapps.audio.playSound(bundles.merge.urls.unitlock_start_unlock);
                this.lockIcon.setPositionRound(this.width + styles.dx, styles.dy);
                this.lockIcon.runAction(new cc.MoveTo(0.25, cc.p(this.width / 2, this.height / 2)));
            }
        } else {
            this.lockIcon.setAnimation(0, "idle", true);
            this.lockIcon.setPositionRound(this.width + styles.dx, styles.dy);
        }
    },

    removeLockIcon: function () {
        if (this.lockIcon) {
            this.lockIcon.removeFromParent();
            this.lockIcon = undefined;
        }
    },

    createTimer: function (animated) {
        if (this.unitView.getAdditionalView("timer")) {
            return;
        }

        var styles = cleverapps.styles.LockedComponentView.timer;
        var timer = new LockedComponentTimer(this.locked);
        timer.setPositionRound(styles);

        if (animated) {
            this.unitView.animateAppearAdditionalView("timer", timer);
        } else {
            this.unitView.createAdditionalView("timer", timer);
        }
    },

    removeTimer: function (animated) {
        this.unitView.removeAdditionalView("timer", !animated);
    },

    unlock: function () {
        if (this.lockIcon) {
            cleverapps.audio.playSound(bundles.merge.urls.unitlock_open);
            this.lockIcon.setAnimation(0, "open", false);
            this.lockIcon.setCompleteListenerRemove();
            this.lockIcon = undefined;
        }
    },

    getUnlockAnimationDuration: function () {
        var animation = this.lockIcon && this.lockIcon.getAnimationData("open");
        return animation && animation.duration || 0;
    },

    addEditorSwitch: function () {
        if (this.locked.unit.chooser) {
            return;
        }

        var lockIcon = new cc.Sprite(bundles.sidebar.frames.lock);
        lockIcon.scale = lockIcon.baseScale = 0.6;
        var activeColor = new cc.Color(255, 255, 255), inactiveColor = new cc.Color(120, 120, 120);
        lockIcon.setColor(this.locked.isLockActive() ? activeColor : inactiveColor);
        this.addChild(lockIcon);
        lockIcon.setPositionRound({ x: { align: "center" }, y: { align: "bottom" } });

        cleverapps.UI.applyHover(lockIcon, {
            filter: function () {
                return !Editor.currentEditor.map.fogs.selected;
            }
        });

        cleverapps.UI.onClick(lockIcon, function () {
            this.locked.setLockActive(!this.locked.isLockActive());
            lockIcon.setColor(this.locked.isLockActive() ? activeColor : inactiveColor);
            Map2d.currentMap.saveLevel();
        }.bind(this), {
            filter: function () {
                return !Editor.currentEditor.map.fogs.selected;
            }
        });
    }
});

cleverapps.styles.LockedComponentView = {
    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 }
    },

    lock: { dx: -30, dy: 30 }
};
