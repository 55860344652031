/**
 * Created by razial on 01.08.2023
 */

var BannerAd = function () {
    cleverapps.timeouts.setInterval(this.check.bind(this), BannerAd.CHECK_INTERVAL);

    connector.ads.on("sticky:start", this.onStart.bind(this));
    connector.ads.on("sticky:close", this.onClose.bind(this, true));
    connector.ads.on("error:showSticky", this.onError.bind(this));

    connector.ads.on("sticky:render", this.onPaidEvent.bind(this));

    connector.ads.on("changeStatus", this.check.bind(this));

    this.errorTime = 0;
};

BannerAd.prototype.onError = function () {
    if (connector.ads.isAdBlockEnabled) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.AD_BLOCK_STICKY_FAIL);
    }

    this.errorTime = Date.now();
};

BannerAd.prototype.canShow = function () {
    if (cleverapps.config.adminMode || cleverapps.config.editorMode) {
        return false;
    }

    if (this.errorTime + BannerAd.PAUSE_AFTER_ERROR > Date.now()) {
        return false;
    }

    if (cleverapps.adsLimits.state(AdsLimits.TYPES.BANNER) !== AdsLimits.STATE_READY) {
        return false;
    }

    if (cleverapps.noAds.getState() === NoAds.STATE_ENABLED) {
        return false;
    }

    if (cleverapps.environment.isChatScene()
        || cleverapps.environment.isAtlasAnalyzerScene()
        || cleverapps.environment.isSceneWithPreview()
        || cleverapps.config.adminMode) {
        return false;
    }

    if (!connector.platform.oneOf(connector.OK)) {
        if (connector.info.isMobile && (cleverapps.resolution.isLandscapeOrientation() || cleverapps.config.orientation === "horizontal")) {
            return false;
        }
    }

    if (connector.platform.oneOf(connector.TEST) && engine === "creator") {
        return false;
    }

    return true;
};

BannerAd.prototype.onStart = function (bannerSize) {
    console.log("sticky.onStart", bannerSize);

    if (connector.ads.isAdBlockEnabled) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.ADS.AD_BLOCK_STICKY_SUCCESS);
    }

    if (connector.info.isInApp && cleverapps.config.debugMode) {
        cleverapps.notification.create("Show sticky bannerSize: " + JSON.stringify(bannerSize));
    }

    this.bannerSize = bannerSize;

    if (!cleverapps.config.wysiwygMode) {
        cleverapps.resolution.addSafePadding("banner", cc.padding({ bottom: this.bannerSize.height }));
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.BANNER.SHOWN);

    cleverapps.playSession.set(cleverapps.EVENTS.STATS.BANNER.DAU, true);

    var isPaidEventsByInterval = connector.platform.oneOf(connector.INSTANT, connector.YANDEX, connector.MSSTART);
    if (isPaidEventsByInterval) {
        this.removePaidEventInterval();

        this.paidEventInterval = cleverapps.timeouts.setInterval(this.onPaidEvent.bind(this), BannerAd.PAID_EVENT_INTERVAL);
    }

    this.check();
};

BannerAd.prototype.onClose = function () {
    console.log("sticky.onClose");

    cleverapps.resolution.removeSafePadding("banner");

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.BANNER.HIDDEN);

    this.removePaidEventInterval();
};

BannerAd.prototype.removePaidEventInterval = function () {
    if (this.paidEventInterval) {
        cleverapps.timeouts.clearInterval(this.paidEventInterval);
        delete this.paidEventInterval;
    }
};

BannerAd.prototype.check = function () {
    if (connector.info.isInApp && cleverapps.config.debugMode) {
        cleverapps.notification.create("bannerAd check " + this.canShow());
    }

    if (this.canShow()) {
        console.log("sticky.show");

        connector.ads.showStickyCallback();
    } else {
        console.log("sticky.close");

        connector.ads.closeStickyCallback();
    }
};

BannerAd.prototype.onResize = cleverapps.accumulate(300, function () {
    this.check();
});

BannerAd.prototype.getECPM = function () {
    return BannerAd.eCPM[connector.info.source] || 1;
};

BannerAd.prototype.onPaidEvent = function (cost) {
    console.log("sticky.onPaidEvent");

    var impressionCost = cost || this.getECPM() / 1000;
    if (!impressionCost) {
        return;
    }

    cleverapps.playSession.inc(cleverapps.EVENTS.ADS.TYPE + "banner", impressionCost);
    cleverapps.playSession.inc(cleverapps.EVENTS.ADS.TYPE + "banner-impressions", 1);
};

BannerAd.prototype.getGlobalBoundingBox = function () {
    var bgSize = cleverapps.resolution.getBgSize();

    if (connector.ads.isStickyPlaying) {
        var width = this.bannerSize.width
            ? Math.round(bgSize.width * this.bannerSize.width / cleverapps.resolution.getFrameSize().width)
            : bgSize.width;
        return cc.rect(bgSize.width / 2 - width / 2, 0, width, this.bannerSize.height);
    }
};

BannerAd.PAID_EVENT_INTERVAL = cleverapps.parseInterval("1 minute");
BannerAd.CHECK_INTERVAL = cleverapps.parseInterval("1 minute");
BannerAd.PAUSE_AFTER_ERROR = cleverapps.parseInterval("5 minutes");

BannerAd.eCPM = {
    // https://apiok.ru/apps/features/banner_ads
    ok: 20 / connector.EXCHANGE_RATES.RUB,

    // https://dev.vk.com/ru/apps-offer-ad
    vk: 25 / connector.EXCHANGE_RATES.RUB,

    instant: 0.72,
    yandex: 30 / connector.EXCHANGE_RATES.RUB,
    msstart: 5,

    android: 2,
    amazon: 2
};