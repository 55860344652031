/**
 * Created by spepa on 01.11.2022
 */

var UnitLibraryIconFooter = cc.Node.extend({
    ctor: function (unit, collectReward) {
        this._super();
        this.unit = unit;
        this.collectReward = collectReward;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(cleverapps.styles.UnitLibraryIconFooter);

        this.createLevel();
    },

    createLevel: function () {
        var styles = cleverapps.styles.UnitLibraryIconFooter;

        this.level = cleverapps.UI.generateOnlyText(this.generateLevelTxT(), cleverapps.styles.FONTS.UNITICON_LEVEL);
        this.level.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.level.fitTo(styles.width, styles.height);
        this.level.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.level);
    },

    generateLevelTxT: function () {
        var levelText = "UnitLibraryIcon.level";

        var family = Families[this.unit.code];
        if (this.unit.stage === family.units.length - 1) {
            levelText = "UnitLibraryIcon.level.max";
        }

        var stage = this.unit.stage + 1;
        for (var i = 0; i < this.unit.stage; i++) {
            if (Families[this.unit.code].units[i].deleted) {
                stage--;
            }
        }

        levelText = Messages.get(levelText, {
            level: stage
        });

        if (levelText.length > 10) {
            levelText = cleverapps.splitHalfByWord(levelText).join("\n");
        }

        if (["wands", "coins", "rubies", "energy"].indexOf(this.unit.code) !== -1 && family.units[this.unit.stage].value) {
            levelText += "\n(" + family.units[this.unit.stage].value + ")";
        }

        return levelText;
    },

    updateStatus: function () {
        if (cleverapps.unitsLibrary.isFresh(this.unit)) {
            this.level && this.level.setVisible(false);
            this.createCollectBtn();
        } else {
            if (this.collectButton) {
                this.collectButton.removeFromParent();
                delete this.collectButton;
            }
            this.level && this.level.setVisible(true);
        }
    },

    createCollectBtn: function () {
        if (this.collectButton) {
            return;
        }

        var styles = cleverapps.styles.UnitLibraryIconFooter.collectButton;
        this.collectButton = new cleverapps.UI.Button({
            text: "Collect",
            width: styles.width,
            height: styles.height,
            type: styles.type,
            onClicked: this.collectReward
        });
        this.addChild(this.collectButton);
        this.collectButton.setPositionRound(this.width / 2, this.height / 2);
    }
});

cleverapps.styles.UnitLibraryIconFooter = {
    width: 220,
    height: 80,

    collectButton: {
        width: 200,
        height: 80,
        type: cleverapps.styles.UI.Button.Images.button_green
    }
};