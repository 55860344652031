/**
 * Created by Denis Kuzin on 15 march 2022
 */

var ClanConfirmActionWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this._super({
            name: "ClanConfirmActionWindow",
            title: options.title,
            content: this.createContent(),
            closeButton: false
        });

        this.action = options.action;
    },

    createContent: function () {
        var styles = cleverapps.styles.ClanConfirmActionWindow;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles);

        var text = this.createText();
        var buttons = this.createButtons();

        var items = new cleverapps.Layout([text, buttons], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
        content.addChild(items);
        items.setPosition(content.width / 2, content.height / 2);

        return content;
    },

    createText: function () {
        var styles = cleverapps.styles.ClanConfirmActionWindow;

        var text = cleverapps.UI.generateOnlyText("AreYouSure", cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setAnchorPoint(0.5, 0.5);
        text.setDimensions(styles.text.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.fitTo(undefined, styles.text.height);

        return text;
    },

    createButtons: function () {
        var styles = cleverapps.styles.ClanConfirmActionWindow;

        var noButton = new cleverapps.UI.Button({
            text: "No",
            onClicked: this.close.bind(this),
            width: styles.button.width,
            height: styles.button.height
        });

        var yesButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_red,
            text: "Yes",
            onClicked: function () {
                this.action();
            }.bind(this),
            width: styles.button.width,
            height: styles.button.height
        });

        return new cleverapps.Layout([yesButton, noButton], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    }
});

cleverapps.styles.ClanConfirmActionWindow = {
    width: 700,
    height: 270,
    margin: 40,

    text: {
        width: 500,
        height: 150
    },

    button: {
        width: 300,
        height: 100
    }
};