/**
 * Created by r4zi4l on 17.03.2021
 */

var CrystalGuideOptions = {
    name: "CrystalGuideWindow",

    getGuideBundle: function () {
        return bundles.crystalguidewindow;
    },

    stagesContent: function () {
        var styles = cleverapps.styles.CrystalGuideWindow;
        var lastUnitStage = Families[this.unit.code].units.length - 1;

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            this.addUnitIcon(container, this.unit.code, lastUnitStage - 1, styles.unit);
            styles.components.forEach(function (componentStyles) {
                this.addSprite(container, bundles.merge.frames.stand_ground, {
                    x: componentStyles.x,
                    y: componentStyles.y - styles.unit.dy
                });
                this.addUnitIcon(container, this.unit.code, lastUnitStage - 2, componentStyles);
            }, this);
            this.addSprite(container, this.bundle.frames.three_white_arrows, styles.arrows);
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.blue_light, styles.background);
            this.addUnitIcon(container, this.unit.code, lastUnitStage, styles.unit);
            this.addSprite(container, this.bundle.frames.blue_light_foreground, styles.foreground);
        }.bind(this));

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            var units = [
                {
                    code: this.unit.code,
                    stage: Families[this.unit.code].units.length - 1
                },
                {
                    code: "coins",
                    stage: 2
                },
                {
                    code: "coins",
                    stage: 2
                },
                {
                    code: "coins",
                    stage: 3
                }
            ];
            units.forEach(function (unit, index) {
                this.addSprite(container, this.bundle.frames.tile_light, {
                    x: styles.units[index].x,
                    y: styles.units[index].y
                });
            }, this);
            this.addSprite(container, this.bundle.frames.three_white_arrows, styles.arrows);
            units.forEach(function (unit, index) {
                var unitView = UnitView.getUnitImage(unit, { alignAnchorX: true });
                unitView.setPositionRound(styles.units[index].x + styles.units[index].dx, styles.units[index].y + styles.units[index].dy);
                container.addChild(unitView);
            }, this);
        }.bind(this));

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.CrystalGuideWindow = {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: 110 }
        },

        unit: {
            x: 0,
            y: 0,
            scale: 1.4,
            dy: 20
        },

        arrows: {
            x: -10,
            y: -129
        },

        components: [
            {
                x: -107,
                y: -166,
                scale: 1.2
            },
            {
                x: -4,
                y: -224,
                scale: 1.2
            },
            {
                x: 105,
                y: -166,
                scale: 1.2
            }
        ]
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -10 }
        },

        unit: {
            x: 0,
            y: 0,
            scale: 1.8
        },

        background: {
            x: 0,
            y: 0,
            scale: 0.9
        },

        foreground: {
            x: 0,
            y: 0
        }
    },

    thirdStage: {
        container: {
            x: { align: "center", dx: 8 },
            y: { align: "center", dy: 120 }
        },

        units: [
            {
                x: 110,
                y: -206,
                dx: 0,
                dy: 40,
                scale: 1.3
            },
            {
                x: -109,
                y: -196,
                dx: 0,
                dy: 20,
                scale: 1.3
            },
            {
                x: 1,
                y: -254,
                dx: 0,
                dy: 20,
                scale: 1.3
            },
            {
                x: 0,
                y: -30,
                dx: 0,
                dy: 20,
                scale: 1.4
            }
        ],

        arrows: {
            x: -5,
            y: -129
        }
    }
};
