/**
 * Created by r4zi4l on 27.08.2021
 */

var WheelLotteryWindow = CleverappsWindow.extend({
    onWindowLoaded: function (lottery) {
        this.bundle = bundles[WheelLotteryWindow.GetTargetBundleName()];
        this.lottery = lottery;

        this.lottery.on("play", this.createListener(this.play.bind(this)));
        this.lottery.on("finish", this.createListener(this.updateButton.bind(this)));
        this.lottery.on("prepareNext", this.createListener(this.updateButton.bind(this)));

        this._super({
            name: "WheelLotteryWindow",
            content: this.createContent(),
            noBackground: true,
            title: "EnergyLotteryWindow.title",
            openSound: this.bundle.urls.lottery_start_effect,
            notCloseByTouchInShadow: true
        });

        var balloons = this.balloons = new cleverapps.Spine(this.bundle.jsons.lottery_balloons_json);
        balloons.setAnimationAndIdleAfter("open", "idle");
        balloons.setLocalZOrder(-1);
        this.addChild(balloons);

        this.leftCurtain = this.createCurtain(this.bundle.jsons.lottery_curtain_left_json);
        this.addChild(this.leftCurtain);

        this.rightCurtain = this.createCurtain(this.bundle.jsons.lottery_curtain_right_json);
        this.addChild(this.rightCurtain);

        this.setLocalZOrder(UpMenuContainer.ZORDER - 1);

        this.scheduleUpdate();

        cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");

        this.lottery.start();
    },

    onClose: function () {
        cleverapps.focusManager.hideControlsWhileFocused("MenuBarGoldItem");

        if (this.amount) {
            if (typeof this.amount === "object") {
                new RewardWindow({ unit: this.amount.unit });
            } else {
                Game.currentGame.addReward("energy", this.amount, undefined, { event: "lottery" });
            }
        }
    },

    createCurtain: function (json) {
        var curtain = new cleverapps.Spine(json);
        curtain.setAnchorPoint(0.5, 0.5);
        curtain.setAnimationAndIdleAfter("open", "idle");
        curtain.setLocalZOrder(-1);
        return curtain;
    },

    setupChildren: function () {
        this._super();

        var styles = this.getStyles();
        var bgRect = cleverapps.resolution.getBgRect();

        var scale = cleverapps.resolution.getBgSize().height / cleverapps.resolution.getSceneSize().height;
        this.leftCurtain.setScale(scale);
        this.rightCurtain.setScale(scale);

        this.leftCurtain.setPositionRound(styles.curtains.left.x, styles.curtains.left.y, { box: bgRect });
        this.rightCurtain.setPositionRound(styles.curtains.right.x, styles.curtains.right.y, { box: bgRect });

        this.balloons.setPositionRound(styles.balloons);
    },

    createContent: function () {
        var styles = this.getStyles();

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles);

        var table = this.table = new cleverapps.Spine(this.bundle.jsons.lottery_table_json);
        table.setPositionRound(styles.table);
        table.setAnimation(0, "idle", true);
        content.addChild(table);

        if (styles.note) {
            var note = cleverapps.UI.generateOnlyText("EnergyLotteryWindow.description", cleverapps.styles.FONTS.ENERGY_LOTTERY_NOTE_TEXT);
            note.setDimensions(styles.note.width, 0);
            note.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            note.fitTo(undefined, styles.note.height);
            note.setPositionRound(styles.note);
            note.setRotation(styles.note.rotation);
            table.addChild(note);
        }

        this.createWheel(table);

        var axis = new cc.Sprite(this.bundle.frames.lottery_axis);
        axis.setPositionRound(styles.axis);
        table.addChild(axis);

        var arrow = this.arrow = new cleverapps.Spine(this.bundle.jsons.lottery_arrow_json);
        arrow.setPositionRound(styles.arrow);
        arrow.setAnimation(0, "idle", true);
        table.addChild(arrow);

        var rewardNode = this.rewardNode = new cc.Node();
        rewardNode.setAnchorPoint(0.5, 0.5);
        rewardNode.setPositionRound(styles.rewardNode);
        table.addChild(rewardNode);

        this.playButton = new cleverapps.UI.Button({
            width: styles.playButton.width,
            height: styles.playButton.height,
            text: "EnergyLotteryWindow.play",
            onClicked: this.lottery.play.bind(this.lottery)
        });
        this.playButton.setPositionRound(styles.playButton);
        table.addChild(this.playButton);

        this.buyButton = new UseGoldButton({
            type: cleverapps.styles.UI.Button.Images.button_blue,
            price: WheelLotteryWindow.SPIN_PRICE,
            onClicked: this.lottery.play.bind(this.lottery),
            eventName: cleverapps.EVENTS.SPENT.ENERGY_LOTTERY,
            width: styles.playButton.width,
            height: styles.playButton.height
        });
        this.buyButton.setPositionRound(styles.playButton);
        table.addChild(this.buyButton);
        this.buyButton.setVisible(false);

        return content;
    },

    createWheel: function (table) {
        var styles = this.getStyles().wheel;

        var wheel = this.wheel = new cc.Sprite(this.bundle.frames.lottery_wheel);
        wheel.setPositionRound(styles);
        table.addChild(wheel);

        var values = this.lottery.getValues(), reward;
        var r = styles.radius, al = Math.PI / 2;
        for (var i = 0; i < values.length; i++) {
            if (values[i].unit) {
                reward = UnitView.getUnitImage(values[i].unit, {
                    alignAnchorX: true
                });
            } else {
                reward = cleverapps.UI.generateImageText(values[i], cleverapps.styles.FONTS.ENERGY_LOTTERY_VALUE_TEXT);
            }
            reward.setPositionRound(r * Math.cos(al) + wheel.width / 2, r * Math.sin(al) + wheel.height / 2);
            reward.setRotation(-(al - Math.PI / 2) / Math.PI * 180);
            al += 2 * Math.PI / values.length;
            wheel.addChild(reward);
        }
    },

    updateHighlight: function (index) {
        var styles = this.getStyles().wheel.highlight;

        if (this.highlight) {
            this.highlight.removeFromParent();
            this.highlight = undefined;
        }

        if (index !== undefined) {
            var highlight = this.highlight = new cleverapps.Spine(this.bundle.jsons.lottery_wheel_highlight_json);
            highlight.setAnchorPoint(0.5, 0.5);
            highlight.setPositionRound(styles);
            highlight.setRotation(-360 / this.lottery.getValues().length * index);
            highlight.setAnimationAndIdleAfter("win", "idle");
            this.wheel.addChild(highlight);
        }
    },

    updateButton: function () {
        if (this.lottery.isAnimating) {
            this.playButton.disable();
            this.buyButton.disable();

            return;
        }

        if (!this.lottery.canPlay()) {
            this.playButton.setVisible(true);
            this.buyButton.setVisible(false);
            this.playButton.disable();
            return;
        }

        this.playButton.enable();
        this.buyButton.enable();

        if (this.lottery.tries > 0) {
            this.playButton.setVisible(true);
            this.buyButton.setVisible(false);
        } else if (this.lottery.canBuyWithGold) {
            this.buyButton.setVisible(true);
            this.playButton.setVisible(false);
        }
    },

    play: function (options) {
        this.amount = options.amount;

        var values = this.lottery.getValues();
        var total = values.length;
        var index = values.indexOf(this.amount);
        var rotation = Math.floor(360 / total * index);

        this.closeButton.hide();
        this.updateHighlight();
        this.updateButton();
        this.wheel.runAction(new cc.Sequence(
            new cc.RotateTo(6, Math.floor(360 * 4 + rotation + (0.4 - 0.9 * Math.pow(Math.random(), 2)) * 360 / total)).easing(cc.easeCubicActionOut()),
            new cc.CallFunc(this.updateHighlight.bind(this, index)),
            new cc.DelayTime(0.11),
            new cc.CallFunc(function () {
                this.table.setAnimationAndIdleAfter("win", "idle");
                cleverapps.audio.playSound(this.bundle.urls.lottery_prize_effect);
            }.bind(this)),
            new cc.DelayTime(0.2),
            new cc.RotateTo(0.8, rotation).easing(cc.easeBackOut()),
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                this.closeButton.show();
                if (typeof this.amount === "object") {
                    new RewardWindow({ unit: this.amount.unit });
                } else {
                    Game.currentGame.addReward("energy", this.amount, this.rewardNode, {
                        event: "lottery"
                    });
                }
                this.amount = undefined;
            }.bind(this)),
            new cc.DelayTime(0.7),
            new cc.CallFunc(function () {
                this.lottery.onAnimationFinished();
            }.bind(this))
        ));
    },

    update: function () {
        var total = this.lottery.getValues().length;
        var index = Math.floor((this.wheel.rotation + 360 - 180 / total) % 360 / 360 * total);
        if (this.lastIndex === undefined) {
            this.lastIndex = index;
        } else if (this.lastIndex !== index) {
            this.lastIndex = index;
            cleverapps.audio.playSound(this.bundle.urls.lottery_spin_effect, {
                throttle: 0
            });
        }
    },

    getStyles: function () {
        return cleverapps.styles.WheelLotteryWindow;
    },

    getPerson: function () {
        var person = {
            role: cleverapps.config.name === "hustlemerge" ? "dwarf" : cleverapps.persons.choose("lotteryking", "bear"),
            emotion: "happy"
        };

        if (cleverapps.travelBook.isExpedition()) {
            person.role = cleverapps.persons.choose("king", "bear");

            if (["wondermerge", "fairy"].indexOf(cleverapps.config.name) === -1) {
                person.skin = cleverapps.travelBook.getCurrentPage().id;
            }
        }

        return person;
    },

    listBundles: function () {
        return [WheelLotteryWindow.GetTargetBundleName()];
    }
});

WheelLotteryWindow.GetTargetBundleName = function () {
    return "energylottery";
};

WheelLotteryWindow.SPIN_PRICE = 100;

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ENERGY_LOTTERY_NOTE_TEXT: {
        size: 34,
        color: new cc.Color(255, 239, 57, 255),
        stroke: cleverapps.styles.DECORATORS.ENERGY_LOTTERY_STROKE,
        shadow: cleverapps.styles.DECORATORS.ENERGY_LOTTERY_SHADOW
    },

    ENERGY_LOTTERY_VALUE_TEXT: {
        size: 42,
        color: new cc.Color(255, 255, 255, 255),
        stroke: cleverapps.styles.DECORATORS.ENERGY_LOTTERY_STROKE
    }
});

cleverapps.styles.WheelLotteryWindow = {
    width: 800,
    height: 600,

    curtains: {
        left: {
            x: { align: "left", dx: -85 },
            y: { align: "bottom", dy: 0 }
        },

        right: {
            x: { align: "right", dx: 0 },
            y: { align: "bottom", dy: 0 }
        }
    },

    balloons: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -100 }
    },

    table: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -270 }
    },

    note: {
        x: { align: "center", dx: 450 },
        y: { align: "bottom", dy: -23 },
        width: 275,
        height: 120,
        rotation: 16,
        textWidth: 275
    },

    wheel: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 },

        highlight: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        },

        radius: 220
    },

    rewardNode: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 115 }
    },

    axis: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    arrow: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 280 }
    },

    playButton: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -440 },
        width: 240,
        height: 108
    }
};
