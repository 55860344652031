/**
 * Created by vladislav on 25/10/2022
 */

QuestsConfig.universal = {
    rpcustomerbridge0_0: {
        trigger: { fog: "fog1" },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomerbridge0", stage: 0 }
        }
    },
    rpcustomerbridge0_1: {
        trigger: { unit: { code: "rpcustomerbridge0", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge0", stage: 1 }
        },
        dynamic: {
            getIcon: function () {
                var skin = cleverapps.skins.getSlot("skinName");
                var code = "rpcustomerbridge0";

                var icon = bundles.quests.frames[skin + "_" + code] || bundles.quests.frames[code];

                return new cc.Sprite(icon);
            }
        }
    },
    rpcustomermain0_1: {
        trigger: { unit: { code: "rpcustomerbridge0", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain0", stage: 1 }
        }
    },
    rpproducer0: {
        trigger: { unit: { code: "rpcustomermain0", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer0", stage: 0 }
        },
        dynamic: {
            getIcon: function () {
                var icon = bundles.quests.frames[cleverapps.skins.getSlot("skinName") + "_rpproducer0"];
                return icon && new cc.Sprite(icon);
            }
        }
    },
    rpproduct0: {
        trigger: { unit: { code: "rpproducer0", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct0", stage: 0 }
        }
    },
    rpcustomerbridge1_1: {
        trigger: { unit: { code: "rpcustomermain0", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge1", stage: 1 }
        }
    },
    rpcustomermain1_0: {
        trigger: { unit: { code: "rpcustomermain0", stage: 1 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomermain1", stage: 0 }
        }
    },
    rpcustomermain1_1: {
        trigger: { unit: { code: "rpcustomerbridge1", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain1", stage: 1 }
        }
    },
    rpproducer1: {
        trigger: { unit: { code: "rpcustomermain1", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer1", stage: 0 }
        },
        dynamic: {
            getIcon: function () {
                var icon = bundles.quests.frames[cleverapps.skins.getSlot("skinName") + "_rpproducer1"];
                return icon && new cc.Sprite(icon);
            }
        }
    },
    rpproduct1: {
        trigger: { unit: { code: "rpproducer1", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct1", stage: 0 }
        }
    },

    rpcustomerbridge2_1: {
        trigger: { unit: { code: "rpcustomermain1", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge2", stage: 1 }
        }
    },
    rpcustomermain2_0: {
        trigger: { unit: { code: "rpcustomermain1", stage: 1 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomermain2", stage: 0 }
        }
    },
    rpcustomermain2_1: {
        trigger: { unit: { code: "rpcustomerbridge2", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain2", stage: 1 }
        }
    },
    rpproducer2: {
        trigger: { unit: { code: "rpcustomermain2", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer2", stage: 0 }
        },
        dynamic: {
            getIcon: function () {
                var icon = bundles.quests.frames[cleverapps.skins.getSlot("skinName") + "_rpproducer2"];
                return icon && new cc.Sprite(icon);
            }
        }
    },
    rpproduct2: {
        trigger: { unit: { code: "rpproducer2", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct2", stage: 0 }
        }
    },

    rpcustomerbridge3_1: {
        trigger: { unit: { code: "rpcustomermain2", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge3", stage: 1 }
        }
    },
    rpcustomermain3_0: {
        trigger: { unit: { code: "rpcustomermain2", stage: 1 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomermain3", stage: 0 }
        }
    },
    rpcustomermain3_1: {
        trigger: { unit: { code: "rpcustomerbridge3", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain3", stage: 1 }
        }
    },
    rpproducer3: {
        trigger: { unit: { code: "rpcustomermain3", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer3", stage: 0 }
        },
        dynamic: {
            getIcon: function () {
                var icon = bundles.quests.frames[cleverapps.skins.getSlot("skinName") + "_rpproducer3"];
                return icon && new cc.Sprite(icon);
            }
        }
    },
    rpproduct3: {
        trigger: { unit: { code: "rpproducer3", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct3", stage: 0 }
        }
    },

    rpcustomerbridge4_1: {
        trigger: { unit: { code: "rpcustomermain3", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge4", stage: 1 }
        }
    },
    rpcustomermain4_0: {
        trigger: { unit: { code: "rpcustomermain3", stage: 1 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomermain4", stage: 0 }
        }
    },
    rpcustomermain4_1: {
        trigger: { unit: { code: "rpcustomerbridge4", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain4", stage: 1 }
        }
    },
    rpproducer4: {
        trigger: { fog: "fog1" },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer4", stage: 0 }
        },
        dynamic: {
            getIcon: function () {
                var icon = bundles.quests.frames[cleverapps.skins.getSlot("skinName") + "_rpproducer4"];
                return icon && new cc.Sprite(icon);
            }
        }
    },
    rpproduct4: {
        trigger: { unit: { code: "rpproducer4", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct4", stage: 0 }
        }
    }
};
