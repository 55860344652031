/**
 * Created by mac on 12/23/20
 */

var Monster = function (unit, options) {
    UnitComponent.call(this, unit);

    this.mineable = this.unit.findComponent(Mineable);
    this.extraMined = options.extraMined === 1;

    if ((this.mineable.state === Mineable.STATES.FINISHED || cleverapps.gameModes.initMineableWithPrizes) && !this.mineable.isCompleted()) {
        this.unit.setPrizes(this.listPrize());
    }
};

Monster.prototype = Object.create(UnitComponent.prototype);
Monster.prototype.constructor = Monster;

Monster.prototype.destructor = function () {
    this.clearBattleTimeout();
};

Monster.prototype.onPrizeHarvested = function () {
    this.nextPosition();
};

Monster.prototype.onDie = function () {
    if (this.view) {
        this.view.onDie();
    } 
};

Monster.prototype.onSpawn = function (delay) {
    if (this.view) {
        this.view.onSpawn(delay);
    } 
};

Monster.prototype.onBattle = function (callback) {
    if (this.view) {
        var duration = this.view.getBattleAnimationDuration();
        this.battleTimeout = cleverapps.timeouts.setTimeout(callback, duration * 1000);

        this.view.onBattle();
    } else {
        callback();
    }
};

Monster.prototype.die = function (fromPlanner) {
    this.onDie();

    this.unit.remove(true);

    if (!fromPlanner) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MONSTER_DIE);
        Game.currentGame.monstersPlanner.onDieMonster();
    }
};

Monster.prototype.spawn = function (slot, delay) {
    this.unit.setPosition(slot.x, slot.y);
    Map2d.currentMap.add(Map2d.LAYER_UNITS, this.unit.x, this.unit.y, this.unit);
    Map2d.currentMap.onAddUnit(this.unit.x, this.unit.y, this.unit);
    Map2d.currentMap.onUnitAvailable(this.unit);
    Game.currentGame.storeSave();
    this.onSpawn(delay);
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MONSTER_SPAWN);
};

Monster.prototype.save = function (data) {
    if (this.extraMined) {
        data.extraMined = 1;
    }
};

Monster.prototype.nextPosition = function () {
    delete this.extraMined;
};

Monster.prototype.finishAttack = function (bonus) {
    this.clearBattleTimeout();

    this.extraMined = bonus;
    this.mineable.completeMining();
    var task = this.mineable.getTask();

    this.unit.setPrizes(this.listPrize());

    var missionPoints = bonus ? task.bonusWands : task.wands;
    var missionType = this.unit.getData().missionType;
    if (missionPoints && missionType) {
        var mission = cleverapps.missionManager.findByType(missionType);
        if (mission && mission.isRunning()) {
            Game.currentGame.addReward("mission", {
                missionType: missionType,
                amount: missionPoints
            }, this.unit, {
                delay: 0
            });
        }
    }

    Game.currentGame.addReward("exp", task.exp, this.unit, { delay: 1000 });

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS[bonus ? "BATTLE_BONUS" : "BATTLE_REGULAR"] + "_" + this.unit.code);
};

Monster.prototype.listPrize = function () {
    this.unit.seedRandom(this.mineable.mined);
    var info = Mines[this.unit.getType()](this.mineable.mined, this.unit, this.extraMined);
    return info && Prizes.Generate(cleverapps.toArray(info));
};

Monster.prototype.startBattle = function (bonus) {
    if (this.battleTimeout) {
        return;
    }

    if (this.mineable.isCompleted()) {
        return;
    }

    var task = this.mineable.getTask();
    var wands = bonus ? task.bonusWands : task.wands;
    if (this.isTutor()) {
        wands = 0;
    }

    if (Game.currentGame.wands - wands < 0) {
        Map2d.currentMap.showLargestGroup({
            itemCode: "wands",
            text: Messages.get("Battle.notEnoughWands", { wands: wands - Game.currentGame.wands })
        });
        return;
    }

    Map2d.mapEvent(Map2d.START_MINING, { unit: this.unit });

    if (wands > 0) {
        Game.currentGame.takeWands(wands);
    }

    this.onBattle(this.finishAttack.bind(this, bonus));
};

Monster.prototype.getActionInfo = function () {
    if (this.battleTimeout || this.mineable.state !== Mineable.STATES.WAIT || this.mineable.isCompleted()) {
        return false;
    }

    var curTask = this.mineable.getTask();

    var data = {
        title: this.unit,
        buttons: {
            wands: {
                action: this.startBattle.bind(this),
                wands: this.isTutor() ? 0 : curTask.wands,
                label: "Battle.info.regularPrize"
            }
        },
        progress: {
            value: this.mineable.mined,
            total: this.unit.getData().mineable.length
        }
    };

    if (curTask.bonusWands && !this.isTutor()) {
        data.buttons.extraWands = {
            action: this.startBattle.bind(this, true),
            wands: curTask.bonusWands,
            label: "Battle.info.bonusPrize"
        };
    }

    return data;
};

Monster.prototype.isTutor = function () {
    return Game.currentGame.tutorial.isActive() && Game.currentGame.tutorial.step.monster === this.unit.code;
};

Monster.prototype.clearBattleTimeout = function () {
    if (this.battleTimeout) {
        cleverapps.timeouts.clearTimeout(this.battleTimeout);
        delete this.battleTimeout;
    }
};