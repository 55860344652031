/**
 * Created by Andrey Popov on 2/24/22.
 */

Telly.PRIZES.undersea2 = Telly.PRIZES.undersea;

MissionTree.PRIZES.undersea2 = [{
    amount: 5,
    units: [
        { code: "seasnail", stage: 1 },
        { code: "seasnail", stage: 1 },
        { code: "seasnail", stage: 1 },
        { code: "seasnail", stage: 2 }
    ]
}];

Ruins.TIERS.undersea2 = {
    cheap: {
        soft: 59,
        rewards: [
            { code: "energy", stage: 0, amount: 1 },
            { code: "worker", stage: 0, amount: 1 },
            { code: "seastrawberry", stage: 0, amount: 2 }
        ]
    },
    costly: {
        hard: 249,
        rewards: [
            { code: "energy", stage: 2, amount: 2 },
            { code: "crystal", stage: 2, amount: 3 },
            { code: "seasack", stage: 2, amount: 1 },
            { code: "worker", stage: 4, amount: 1 }
        ]
    }
};

Landmarks.WORLDS.undersea2 = {
    fog: "fog5",
    force: Forces.LANDMARK_DONOR_SLOT2,
    landmarks: [{
        code: "landmark",
        stage: 0,
        targetExpedition: "main",
        donors: "sea2fisherman"
    }]
};
