/**
 * Created by razial on 29.02.2024
 */

var WorkersGain = cleverapps.Layout.extend({
    ctor: function (before, after) {
        var styles = cleverapps.styles.WorkersGain;

        if (before !== after) {
            var leftIcon = new cc.Sprite(bundles.workerwindow.frames.reward_worker_png);
            var leftText = cleverapps.UI.generateImageText(before + "/" + before, cleverapps.styles.FONTS.WORKERS_GAIN_TEXT);

            var leftBlock = this.leftBlock = new cleverapps.Layout([leftIcon, leftText], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.margin
            });

            var arrow = this.arrow = new cleverapps.Spine(bundles.guidewindow_arrows.jsons.arrows);
            arrow.setAnimation(0, "horizontal", false);
            arrow.clearTrack(0);
            arrow.setScale(0.5);
        }

        var rightIcon = this.rightIcon = new cc.Sprite(bundles.workerwindow.frames.reward_worker_png);
        var rightText = cleverapps.UI.generateImageText(after + "/" + after, cleverapps.styles.FONTS.WORKERS_GAIN_TEXT);
        rightText.setColor(cleverapps.styles.COLORS.GREEN);

        var rightBlock = this.rightBlock = new cleverapps.Layout([rightIcon, rightText], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        this._super([
            leftBlock,
            arrow,
            rightBlock
        ].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    },

    getPrizeIcon: function () {
        return this.rightIcon;
    },

    receiveAnimation: function (callback) {
        this.setCascadeOpacityEnabledRecursively(true);

        this.leftBlock.runAction(new cc.Spawn(
            new cc.MoveTo(0.3, cc.p(this.width / 2, this.leftBlock.y)),
            new cc.FadeOut(0.3)
        ));

        this.arrow.runAction(new cc.Spawn(
            new cc.MoveTo(0.3, cc.p(this.width / 2, this.arrow.y)),
            new cc.ScaleTo(0.3, 0, 0)
        ));

        this.rightBlock.runAction(new cc.Sequence(
            new cc.MoveTo(0.3, cc.p(this.width / 2, this.rightBlock.y)),
            new cc.CallFunc(callback)
        ));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    WORKERS_GAIN_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.WorkersGain = {
    margin: 20
};
