/**
 * Created by vtbelo on 17/03/21
 */

var PixelView = cc.Node.extend({
    ctor: function (pixel) {
        this._super();
        this.pixel = pixel;

        var styles = this.getStyles();

        this.setAnchorPoint(0.5, 0.5);

        this.target = new cc.Node();
        this.target.setPositionRound(styles.collect);
        this.addChild(this.target);

        this.animation = new LazyAsset(bundles.pixels.jsons[this.pixel.type + "_pixels_json"] || bundles.pixels.jsons["pixels_json"], {
            preview: new cc.Sprite(bundles.merge.frames.pixel_preview)
        });
        this.animation.setAnimation(0, "animation", true);
        this.setContentSize2(this.animation.getContentSize());
        this.animation.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.animation);
        if (styles.timeScale) {
            this.animation.setTimeScale(styles.timeScale.base + Math.random() * styles.timeScale.random);
        }

        cleverapps.UI.onDrag(this, {
            onClick: this.pixel.collect.bind(this.pixel),
            followPointer: true,
            onDragStart: function () {
                this.pixel.setFlying(false);
                this.setLocalZOrder(1);

                return true;
            }.bind(this),
            onDragMove: function (touch) {
                this.setPositionRound(this.parent.convertTouchToNodeSpace(touch));
            }.bind(this),
            onDragEnd: function () {
                this.pixel.setFlying(true);
                this.setLocalZOrder(0);
            }.bind(this)
        });

        this.pixel.onFlyingChanged = this.createListener(this.changeFlying.bind(this));
        this.pixel.onAnimateCollect = this.createListener(this.collect.bind(this));
        this.pixel.onDie = this.createListener(this.explode.bind(this));

        this.minFlyTime = cleverapps.parseInterval(PixelView.FLY_TIME);

        var cell = Map2d.currentMap.getMapView().alignInIsometricGrid(this.pixel.x, this.pixel.y);
        this.setPosition(cell.x, cell.y);

        this.speed = styles.speed;
        this.distanceDisappear = styles.distanceDisappear;

        Game.currentGame.pushingComponent.add(this, "pixels");

        this.flyStartTime = Date.now();
        this.pixel.setFlying(true);

        this.setScale(0);
        this.runAction(new cc.ScaleTo(0.2, 1));

        this.lastCheck = 0;
    },

    getStyles: function () {
        return cleverapps.styles.PixelView[this.pixel.type] || cleverapps.styles.PixelView;
    },

    update: function (dt) {
        this.setPosition(this.x + this.speed.x * dt, this.y + this.speed.y * dt);
        var screenSize = cleverapps.resolution.getBgSize();

        var ct = Date.now();
        if (this.flyStartTime + this.minFlyTime < ct && this.lastCheck < ct - 3000) {
            this.lastCheck = ct;
            var pos = this.parent.convertToWorldSpace(this.getPosition());

            if (screenSize.width + this.distanceDisappear < pos.x || pos.y < -this.distanceDisappear) {
                this.pixel.die(true);
            }
        }

        var onScreen = cc.rectIntersectsRect(this.animation.getGlobalBoundingBox(), cc.rect(0, 0, screenSize.width, screenSize.height));
        this.setVisible(onScreen);
    },

    changeFlying: function (flying) {
        if (flying) {
            this.scheduleUpdate();
            this.resumePushing();
        } else {
            this.unscheduleUpdate();
            this.pausePushing();
        }
    },

    collect: function (callback) {
        var styles = this.getStyles().collect;

        callback({
            source: this.target,
            delay: styles.delay,
            sound: bundles.pixels.urls[this.pixel.type + "_pixel_collect_effect"] || bundles.pixels.urls["pixel_collect_effect"]
        });
    },

    explode: function (silent) {
        cc.eventManager.removeListeners(this);

        var callback = cleverapps.once(function () {
            this.removeFromParent();
        }.bind(this));

        if (this.animation.isLoaded()) {
            this.animation.setAnimation(0, "animation2", false);
            this.animation.setCompleteListener(callback);
        } else {
            var delay = this.getStyles().collect.delay * 0.001 || 0;
            this.animation.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.ScaleTo(0.1, 0.3),
                new cc.Hide(),
                new cc.CallFunc(callback)
            ));
        }

        if (silent) {
            this.removeFromParent();
        }
    }
});

PixelView.FLY_TIME = "90 seconds";

cleverapps.styles.PixelViewTemplate = {
    distanceDisappear: 1000,
    speed: { x: 35, y: -18 },
    collect: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 },
        delay: 150
    }
};

cleverapps.styles.PixelView = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);
cleverapps.styles.PixelView[PixelsSwarm.UNDERSEA] = cleverapps.styles.PixelView[PixelsSwarm.UNDERSEA2] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);
cleverapps.styles.PixelView[PixelsSwarm.DRAGONIA] = cleverapps.styles.PixelView[PixelsSwarm.DRAGONIA2] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);
cleverapps.styles.PixelView[PixelsSwarm.RAPUNZEL] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);
cleverapps.styles.PixelView[PixelsSwarm.XMAS] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);
cleverapps.styles.PixelView[PixelsSwarm.HALLOWEEN] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {}, true);

cleverapps.styles.PixelView[PixelsSwarm.CHINA] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {
    collect: {
        y: { align: "center", dy: 20 },
        delay: 10
    }
}, true);

cleverapps.styles.PixelView[PixelsSwarm.EASTER] = cleverapps.overrideStyles(cleverapps.styles.PixelViewTemplate, {
    collect: {
        x: { align: "center", dx: 10 },
        y: { align: "center", dy: -195 },
        delay: 1350
    }
}, true);