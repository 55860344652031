/**
 * Created by Denis Kuzin on 29 march 2022
 */

var ClanInformationWindow = BaseClanWindow.extend({
    ctor: function (clan) {
        this.updateClan = clan;

        this._super(clan);
    },

    onWindowLoaded: function (clan) {
        this._super({
            clan: clan,
            name: "ClanInformationWindow",
            title: clan.name
        });

        this.changeBanner(this.clan.emblem);
    },

    createContent: function () {
        var content = this._super();

        var tab = new ClansInfoTab({
            width: content.width,
            height: content.height
        }, this.clan);
        tab.setAnchorPoint(0.5, 0.5);
        tab.setPositionRound(content.width / 2, content.height / 2);
        content.addChild(tab);

        return content;
    }
});
