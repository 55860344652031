/**
 * Created by r4zi4l on 14.05.2021
 */

var SalePassRulesOptions = {
    name: "SalePassRulesWindow",

    getGuideBundle: function () {
        return bundles.salepassruleswindow;
    },

    stagesContent: function () {
        var styles = cleverapps.styles.SalePassRulesWindow;

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            ["fruitshop", "resourceshop", "discountshop"].forEach(function (code, index) {
                var unit = this.addUnitIcon(container, code, 0, styles.unit[index]);

                var lightStyles = {
                    scale: styles.light.scale,
                    x: unit.x,
                    y: unit.y,
                    zOrder: -1
                };
                this.addSprite(container, this.bundle.frames.blue_light, lightStyles);
            }, this);
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.stand_ground, styles.ground);
            this.addUnitIcon(container, "herochest", 2, styles.unit);

            var star = new cleverapps.Spine(bundles.merge.jsons.salepass_point);
            star.setScale(styles.star.scale || 1.0);
            star.setAnchorPoint(0.5, 0.5);
            star.setPositionRound(styles.star);
            star.setAnimation(0, "3_idle", true);
            container.addChild(star);
            star.runAction(new cc.Sequence(
                new cc.DelayTime(0.1),
                new cc.CallFunc(star.clearTrack.bind(star, 0))
            ));
            this.addSprite(star, bundles.finger.frames.tutorial_hand_png, styles.finger);
        }.bind(this));

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.yellow_light, styles.light);
            this.addSprite(container, this.bundle.frames.stand_ground, styles.ground);
            this.addSprite(container, this.bundle.frames.prizes_heap, styles.prizes);
        }.bind(this));

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.SalePassRulesWindow = {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -80 }
        },

        light: {
            scale: 0.7
        },

        unit: [{
            x: -50,
            y: -130,
            scale: 1.2
        }, {
            x: -70,
            y: 175,
            scale: 1.2
        }, {
            x: 75,
            y: 15,
            scale: 1.2
        }]
    },

    secondStage: {
        container: {
            x: { align: "center", dx: -50 },
            y: { align: "center", dy: -80 }
        },

        unit: {
            x: 15,
            y: 10,
            scale: 1.7
        },

        ground: {
            x: 0,
            y: -40
        },

        star: {
            x: 0,
            y: 140,
            scale: 1.7
        },

        finger: {
            x: 160,
            y: -50
        }
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -20 }
        },

        light: {
            x: 0,
            y: 30
        },

        ground: {
            x: 0,
            y: -10
        },

        prizes: {
            x: 0,
            y: 30,
            scale: 0.7
        }
    }
};
