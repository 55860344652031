/**
 * Created by r4zi4l on 26.01.2021
 */

var UseEnergyButton = cleverapps.UI.Button.extend({
    ctor: function (options) {
        this.price = options.energy;

        this._super({
            width: options.width,
            height: options.height,
            text: "%%" + this.price,
            textVariant: this.price > cleverapps.lives.amount ? cleverapps.UI.Button.TEXT_EMPHASIS : cleverapps.UI.Button.TEXT_REGULAR,
            onClicked: options.onClicked,
            type: options.type
        });
    }
});