/**
 * Created by Denis Kuzin on 21 february 2022
 */

var ClansRow = cleverapps.Row.extend({
    ctor: function (params, options) {
        options = options || {};

        var styles = cleverapps.styles.ClansRow;
        this._super(params, {
            row_you_png: bundles.clan_table.frames.table_row_you_png,
            row_player_png: bundles.clan_table.frames.table_row_png,

            prizes: options.prizes || false,
            badges: options.badges || false,

            rowWidth: styles.row.width,
            rowHeight: styles.row.height,

            buttonData: options.buttonData
        });

        if (params.cup && params.cup.config.participateAsClan) {
            options.onClick = function () {
                new ClanInformationWindow(new Clan(params));
            };
        }

        if (options.onClick) {
            cleverapps.UI.onClick(this, options.onClick, {
                interactiveScale: false
            });
        }
    },

    createPlaceText: function (place) {
        var font = cleverapps.styles.FONTS.CLANS_ROW_PLACE_TEXT;
        var text = cleverapps.UI.generateImageText(this.notInTop ? "-" : place, font);

        var styles = cleverapps.styles.ClansRow.place;
        if (!styles) {
            return text;
        }

        var background = new cc.Scale9Sprite(this.player ? bundles.clan_table.frames.clan_amount_bg_you_png : bundles.clan_table.frames.clan_amount_bg_png);
        background.setContentSize2(styles.width, styles.height);

        text.setPositionRound(background.width / 2, background.height / 2);
        background.addChild(text);

        return background;
    },

    createName: function (name, id, width) {
        var styles = cleverapps.styles.ClansRow;

        var textString = name || id && "Player_" + id || "";
        textString = cleverapps.UI.cutPlayerName(textString, this.getMaxTextLength());

        if (this.leader) {
            var leader = this.createLeader();
        }

        var font = cleverapps.styles.FONTS.CLANS_ROW_NAME_TEXT;
        var margin = styles.name.margin || 0;
        var textWidth = width;

        if (leader) {
            textWidth -= leader.width - margin;
        }

        var text = cleverapps.UI.generateOnlyText(textString, font);
        text.fitTo(textWidth, styles.name.height);

        var content = new cleverapps.Layout([text, leader].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: margin
        });
        content.setPositionRound(styles.name);
        cleverapps.UI.fitToBox(content, {
            width: width
        });

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(width, styles.row.height);
        container.addChild(content);

        return container;
    },

    createLeader: function () {
        var styles = cleverapps.styles.ClansRow.leader;

        var icon = new cc.Sprite(bundles.clan_table.frames.red_flag);

        var text = cleverapps.UI.generateOnlyText("ClansRow.teamLeader", cleverapps.styles.FONTS.CLANS_ROW_LEADER_TEXT);
        text.fitTo(styles.textWidth);

        return new cleverapps.Layout([icon, text], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    },

    createData: function (params) {
        var styles = cleverapps.styles.ClansRow.data;

        var dataBg = new cc.Scale9Sprite(this.player ? bundles.clan_table.frames.clan_amount_bg_you_png : bundles.clan_table.frames.clan_amount_bg_png);
        dataBg.setContentSize2(styles.width, styles.height);

        this.dataTextWidth = styles.text.width;
        this.dataTextHeight = undefined;

        this.dataAmount = cleverapps.UI.generateImageText("222", cleverapps.styles.FONTS.CLANS_ROW_DATA_TEXT);
        this.dataAmount.fitTo(styles.text.width);
        this.dataAmount.setPositionRound(styles.text);
        dataBg.addChild(this.dataAmount);

        var icon = new cc.Sprite(bundles.tablerow_icons.frames.clancup);
        icon.setPositionRound(styles.icon);
        dataBg.addChild(icon);

        this.updateAmount(params.amount, true);

        return new cleverapps.Layout([dataBg], {
            padding: styles.padding
        });
    },

    createPrize: function (place) {
        var styles = cleverapps.styles.ClansRow.prize;

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(styles.width, styles.height);

        var rewards = this.cup && this.cup.listRewards();
        var reward = rewards && rewards[place - 1];
        if (!reward) {
            return container;
        }

        var icon = new cc.Sprite(bundles.clan_table.frames["clan_prize_" + place] || bundles.clan_table.frames.clan_prize_5);
        icon.setPositionRound(styles.icon);
        container.addChild(icon);

        var amount = cleverapps.UI.generateImageText(reward.soft * ClansConfig.MAX_SIZE, cleverapps.styles.FONTS.CLANS_ROW_PRIZE_TEXT);
        amount.fitTo(icon.width - styles.amount.padding * 2, styles.amount.height);
        amount.setPositionRound(styles.amount);
        icon.addChild(amount);

        cleverapps.tooltipManager.create(icon, {
            rewards: reward,
            text: "ClansRow.prizeTooltip",
            location: cleverapps.UI.Tooltip.LOCATION_BELOW
        });

        return container;
    },

    createPrizeIcon: function (place) {
        var rewards = this.cup && this.cup.listRewards() || [];
        if (rewards[place - 1]) {
            return new cc.Sprite(bundles.clan_table.frames["clan_prize_" + place] || bundles.clan_table.frames.clan_prize_5);
        }
    },

    getRewards: function () {
        if (this.cup) {
            return this.cup.listRewards()[this.currentPlace - 1];
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CLANS_ROW_PLACE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    CLANS_ROW_NAME_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    CLANS_ROW_LEADER_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.GREEN
    },

    CLANS_ROW_PRIZE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    CLANS_ROW_DATA_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.ClansRow = {
    row: {
        width: 1245,
        height: 118
    },

    name: {
        x: { align: "left" },
        y: { align: "center" },
        height: 110,
        margin: 20
    },

    leader: {
        margin: 10,
        textWidth: 320
    },

    prize: {
        width: 220,
        height: 100,

        icon: {
            x: { align: "center" },
            y: { align: "bottom", dy: 6 }
        },

        amount: {
            x: { align: "center" },
            y: { align: "bottom", dy: -6 },
            height: 60,
            padding: 24
        }
    },

    data: {
        width: 200,
        height: 80,

        padding: {
            left: 40
        },

        icon: {
            x: { align: "left", dx: -35 },
            y: { align: "center", dy: -5 }
        },

        text: {
            x: { align: "center", dx: 15 },
            y: { align: "center", dy: 2 },
            width: 160
        }
    }
};