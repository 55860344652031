/**
 * Created by vtbelo on 05.05.2021
 */

var ChestPrizeProbWindow = CleverappsWindow.extend({
    onWindowLoaded: function (unit, probs) {
        this.unit = unit;
        this.probs = probs;
        
        this._super({
            title: "Units." + unit.code + ".stage" + (unit.stage + 1) + ".name",
            name: "ChestPrizeProbWindow",
            content: this.createContent(),
            closeButton: true,
            foreground: bundles.windows.frames.window_foreground_png
        });
    },

    generateContains: function () {
        var contains = [];
        var styles = cleverapps.styles.ChestPrizeProbWindow.prize;

        this.probs.types.forEach(function (unit) {
            var prizeBackground = new cc.Scale9Sprite(bundles.merge.frames.icon_background_light);
            prizeBackground.setContentSize2(styles.background);

            var prizeIcon = UnitView.getUnitImage(unit, { preferStatic: true });
            prizeIcon.clearTrack && prizeIcon.clearTrack(0);
            prizeIcon.setAnchorPoint(0.5, 0.5);
            prizeIcon.setPositionRound(prizeBackground.width / 2, prizeBackground.height / 2);
            cleverapps.UI.fitToBox(prizeIcon, styles.icon);

            prizeBackground.addChild(prizeIcon);

            var text;
            if (unit.amount) {
                text = cleverapps.UI.generateOnlyText("x" + unit.amount, cleverapps.styles.FONTS.UNIT_AMOUNT);
            } else {
                text = cleverapps.UI.generateOnlyText(unit.prob + "%", cleverapps.styles.FONTS.UNIT_PROB);
            }
            text.setPositionRound(styles.probText);
            prizeBackground.addChild(text);

            contains.push(prizeBackground);
        });

        return contains;
    },

    generateSameItemsText: function () {
        if (this.probs.minAmount === 1 && this.probs.maxAmount === 1) {
            return;
        }
        return cleverapps.UI.generateOnlyText("ChestContainSameItems", cleverapps.styles.FONTS.SAME_ITEMS_TEXT);
    },

    generateMayContainText: function () {
        if (this.probs.minAmount === 1 && this.probs.maxAmount === 1) {
            return cleverapps.UI.generateOnlyText("ChestContainOnlyOne", cleverapps.styles.FONTS.DARK_TEXT);
        }

        return cleverapps.UI.generateOnlyText("ChestContainAmount", cleverapps.styles.FONTS.DARK_TEXT, {
            min: this.probs.minAmount,
            max: this.probs.maxAmount
        });
    },

    generateMayContain: function () {
        var styles = cleverapps.styles.ChestPrizeProbWindow;

        var container = new cleverapps.Layout(this.generateContains(), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.containScroll.margin,
            padding: styles.containScroll.padding
        });

        var mayContain = new cleverapps.UI.ScrollView(container, {
            scrollBarEnabled: false,
            direction: cleverapps.UI.HORIZONTAL
        });
        mayContain.setContentSize2(styles.containScroll.width, container.height + 2);
        mayContain.scrollToDefault();

        return mayContain;
    },

    createContent: function () {
        var styles = cleverapps.styles.ChestPrizeProbWindow;

        var unit = new UnitOnCell(this.unit, {
            shining: true
        });

        unit.setScale(styles.chest.scale);

        return new cleverapps.Layout([
            unit,
            this.generateMayContainText(),
            this.generateMayContain(),
            this.generateSameItemsText()
        ], {
            margin: styles.margin,
            padding: styles.padding
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SAME_ITEMS_TEXT: {
        size: 23,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.ChestPrizeProbWindow = {
    margin: 30,

    prize: {
        background: {
            width: 200,
            height: 200
        },
        icon: {
            width: 160,
            height: 160,
            maxScale: 1.6
        },
        probText: {
            x: { align: "right", dx: -10 },
            y: { align: "bottom" }
        }
    },

    padding: {
        left: -60,
        right: -60,
        top: 0,
        bottom: 0
    },

    chest: {
        scale: 1.6
    },

    containScroll: {
        width: 1400,
        margin: 14,
        padding: {
            x: 40,
            y: 0
        }
    }
};
