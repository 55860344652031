/**
 * Created by Denis Kuzin on 17 february 2022
 */

var ClansJoinRow = ClansRow.extend({
    ctor: function (params) {
        var buttonText = "Join";
        if (params.clan.isFull()) {
            buttonText = "ClansJoinRow.full";
            this.buttonTooltipText = "ClanErrors.clanIsFull";
            this.dataTooltipText = "ClanErrors.clanIsFull";
        } else if (!params.clan.isAppropriateByLevel()) {
            buttonText = Messages.get("ClansJoinRow.level") + " " + params.clan.requiredLevel;
            this.buttonTooltipText = Messages.get("AddClanWindow.requiredLevel") + " " + params.clan.requiredLevel;
        }
        this._super(params, {
            buttonData: {
                text: buttonText,
                disabled: !params.clan.isAppropriate(),
                action: function () {
                    if (this.button) {
                        this.button.disable();
                    }

                    cleverapps.clans.join(params.clan.id);
                }.bind(this)
            },
            onClick: function () {
                new ClanInformationWindow(params.clan);
            }
        });
    },

    createButton: function (data) {
        var button = this.button = this._super(data);

        if (this.buttonTooltipText) {
            cleverapps.tooltipManager.create(button, {
                text: this.buttonTooltipText,
                location: cleverapps.UI.Tooltip.LOCATION_BELOW
            });
        }

        return button;
    },

    createPlace: function () {
        return new cc.Node();
    },

    createData: function (params) {
        var styles = cleverapps.styles.ClansJoinRow;

        var bg = cleverapps.UI.createScale9Sprite(bundles.clan_table.frames.clan_amount_bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(styles.bg.width, styles.bg.height);

        if (this.dataTooltipText) {
            cleverapps.tooltipManager.create(bg, {
                text: this.dataTooltipText,
                location: cleverapps.UI.Tooltip.LOCATION_BELOW
            });
        }

        var icon = new cc.Sprite(bundles.clan_table.frames.players_icon);
        icon.setPositionRound(styles.icon);
        bg.addChild(icon);

        var text = cleverapps.UI.generateImageText(params.clan.membersCount + "/" + ClansConfig.MAX_SIZE, cleverapps.styles.FONTS.CLANS_ROW_DATA_TEXT);
        text.fitTo(styles.text.width);
        text.setPositionRound(styles.text);
        bg.addChild(text);

        return bg;
    }
});

cleverapps.styles.ClansJoinRow = {
    icon: {
        x: { align: "left", dx: 20 },
        y: { align: "center", dy: 0 }
    },

    text: {
        width: 140,
        x: { align: "right", dx: -20 },
        y: { align: "center", dy: 0 }
    },

    bg: {
        width: 235,
        height: 74
    }
};