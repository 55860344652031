/**
 * Created by r4zi4l on 22.01.2021
 */

var ComponentTimer = cc.Node.extend({
    ctor: function (countdown, fullValue, options) {
        this._super();
        this.createTimer(countdown, fullValue, options);
    },

    clear: function () {
        this.countdown.countDown.stop();
    },

    createTimer: function (model, fullValue, options) {
        options = options || {};

        if (this.countdown) {
            this.countdown.removeFromParent();
        }

        var styles = cleverapps.styles.ComponentTimer.timer;

        this.countdown = new cleverapps.CountDownView(model, {
            font: cleverapps.styles.FONTS.COMPONENT_TIMER_TEXT,
            bar: {
                type: options.barType || ScaledProgressBar.Types.blue_with_frames,
                width: styles.width,
                fullValue: fullValue,
                reversed: options.reversed
            },
            icon: {
                frame: bundles.timer_merge.frames.timer_png,
                json: bundles.timer_merge.jsons.timer_icon_json,
                animation: "animation"
            }
        });
        this.countdown.setPositionRound(styles);
        this.addChild(this.countdown);
    },

    createAnimation: function () {
        if (cleverapps.config.name !== "mergecraft") {
            return;
        }

        var styles = cleverapps.styles.ComponentTimer.animation;

        var animation = new cleverapps.Spine(bundles.timer_merge.jsons.timer_json);
        animation.setPositionRound(styles);
        animation.setAnimation(0, "animation", true);
        this.addChild(animation);
    },

    createFastCountdown: function (params) {
        var time = params.time;
        var count = params.duration / 50;
        var step = params.limit / count;
        var id;

        var countdown = {
            onSecond: function () {},
            onFinish: params.callback || function () {},
            getTimeLeft: function () {
                return time; 
            },
            stop: function () {
                cleverapps.timeouts.clearInterval(id);
            },
            remove: function () {
                cleverapps.timeouts.clearInterval(id);
            }
        };

        id = cleverapps.timeouts.setInterval(function () {
            if (count <= 0) {
                clearInterval(id);
                countdown.onFinish();
                return;
            }
            time = Math.max(params.minimalValue || 0, time - step);
            count -= 1;
            countdown.onSecond();
        }, 50);

        return countdown;
    },

    runFastCountdownAnimation: function (duration) {
        var seconds = duration / 1000;
        var pulses = Math.ceil(seconds);
        var pulseTime = seconds / pulses;

        this.countdown.runAction(new cc.Repeat(new cc.Sequence(
            new cc.ScaleTo(pulseTime / 2, this.countdown.scale * 1.15),
            new cc.ScaleTo(pulseTime / 2, this.countdown.scale)
        ), pulses));
    }
});

var BaseWorkerTimer = ComponentTimer.extend({
    ctor: function (worker, countdown) {
        this.worker = worker;
        this._super(countdown, this.worker.getFullJobTime());
        this.createAnimation();

        this.worker.onDestructor = this.createListener(this.clear.bind(this));
    }
});

var InstantComponentTimer = BaseWorkerTimer.extend({
    ctor: function (worker, options) {
        var countdown = this.createFastCountdown(options);
        this._super(worker, countdown);
        this.runFastCountdownAnimation(options.duration);
    }
});

var WorkerComponentTimer = BaseWorkerTimer.extend({
    ctor: function (worker) {
        this.state = undefined;

        var countdown = new cleverapps.CountDown(worker.getTimeLeft(), {
            onTick: this.updateState.bind(this)
        });

        this._super(worker, countdown);

        this.updateState();
    },

    calcState: function () {
        var SoftFeastMission = cleverapps.missionManager.findByType(Mission.TYPE_BOOSTTIME);

        var state = WorkerComponentTimer.STATE_DEFAULT;
        if (SpeedUpWindow.CalcPrice(this.worker.getTimeLeft()) === 0) {
            state = WorkerComponentTimer.STATE_FREE;
        } else if (SoftFeastMission && SoftFeastMission.isRunning() && MissionManager.hasProperParent(SoftFeastMission)) {
            state = WorkerComponentTimer.STATE_BONUS_COINS;
        } else if (this.worker.isSpeedUpBonusActive()) {
            if (this.worker.getTimeLeft() <= cleverapps.parseInterval(Workers.SPEEDUP_BONUS_TIME)) {
                state = WorkerComponentTimer.STATE_BONUS_WATCH_AD_COMPLETE;
            } else {
                state = WorkerComponentTimer.STATE_BONUS_WATCH_AD;
            }
        }

        return state;
    },

    updateState: function (timeLeft) {
        var state = this.calcState();

        if (this.state === state) {
            if (this.state === WorkerComponentTimer.STATE_DEFAULT && this.button) {
                this.updateBtnPrice(timeLeft);
            }
            return;
        }

        this.state = state;

        this.hideButton();
        this.showButton();
    },

    updateBtnPrice: function (timeLeft) {
        timeLeft = timeLeft !== undefined ? timeLeft : this.worker.getTimeLeft();
        var price = SpeedUpWindow.CalcPrice(timeLeft);
        if (this.button.speedPrice !== price) {
            this.button.speedPrice = price;
            this.button.setString("$$" + price + ">>");
        }
    },

    onButtonClick: function () {
        switch (this.state) {
            case WorkerComponentTimer.STATE_FREE:
                this.worker.speedUp();
                break;
            case WorkerComponentTimer.STATE_BONUS_COINS:
                cleverapps.focusManager.display({
                    focus: "FromTimerSpeedUp",
                    action: function (f) {
                        new SpeedUpWindow(this.worker);
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }.bind(this)
                });
                break;
            case WorkerComponentTimer.STATE_BONUS_WATCH_AD:
            case WorkerComponentTimer.STATE_BONUS_WATCH_AD_COMPLETE:
                this.worker.speedUpForVideo();
                break;
            case WorkerComponentTimer.STATE_DEFAULT:
                if (cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.SPEED_UP, SpeedUpWindow.CalcPrice(this.worker.getTimeLeft()))) {
                    this.worker.speedUp();
                }
                break;
        }
    },

    showButton: function () {
        var styles = cleverapps.styles.WorkerComponentTimer.button;

        var text;
        switch (this.state) {
            case WorkerComponentTimer.STATE_FREE:
                text = "FREE" + TextWithIcon.ICONS_BY_NAME.speedup;
                break;
            case WorkerComponentTimer.STATE_BONUS_COINS:
                text = TextWithIcon.ICONS_BY_NAME.speedup;
                styles = Object.assign({}, styles, styles.coinsButton);
                break;
            case WorkerComponentTimer.STATE_BONUS_WATCH_AD:
                text = TextWithIcon.ICONS_BY_NAME.ads + "ComponentTimer.boost";
                break;
            case WorkerComponentTimer.STATE_BONUS_WATCH_AD_COMPLETE:
                text = TextWithIcon.ICONS_BY_NAME.ads + "ComponentTimer.complete";
                break;
            case WorkerComponentTimer.STATE_DEFAULT:
                text = TextWithIcon.ICONS_BY_NAME.hard + SpeedUpWindow.CalcPrice(this.worker.getTimeLeft()) + TextWithIcon.ICONS_BY_NAME.speedup;
        }

        var button = this.button = new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            text: text,
            onClicked: this.onButtonClick.bind(this),
            type: cleverapps.styles.UI.Button.Images.field_button_blue
        });

        button.setPositionRound(styles);
        button.adviceTarget = Map2d.SPEED_UP;
        this.addChild(button);

        button.setScale(0.3);
        button.runAction(new cc.ScaleTo(0.2, 1));
    },

    hideButton: function () {
        if (this.button) {
            this.button.runAction(new cc.Sequence(
                new cc.ScaleTo(0.2, 0.3),
                new cc.RemoveSelf()
            ));
            this.button = undefined;
        }
    },

    showBoost: function (params) {
        this.hideButton();
        params = Object.assign({ time: this.worker.getTimeLeft() }, params);
        this.createTimer(this.createFastCountdown(params));
        this.runFastCountdownAnimation(params.duration);
    }
});

var LockedComponentTimer = ComponentTimer.extend({
    ctor: function (locked) {
        this.locked = locked;

        var countdown = new cleverapps.CountDown(locked.getTimeLeft(), {
            onTick: this.updateState.bind(this)
        });

        this._super(countdown, locked.harvestDuration);
    },

    updateState: function () {
        if (SpeedUpWindow.CalcPrice(this.locked.getTimeLeft()) === 0 && !this.button) {
            this.showButton();
        }
    },

    showButton: function () {
        var styles = cleverapps.styles.WorkerComponentTimer.button;

        var button = this.button = new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            text: "FREE" + TextWithIcon.ICONS_BY_NAME.speedup,
            onClicked: function () {
                this.locked.unlock();
            }.bind(this),
            type: cleverapps.styles.UI.Button.Images.field_button_blue
        });

        button.setPositionRound(styles);
        this.addChild(button);

        button.setScale(0.3);
        button.runAction(new cc.ScaleTo(0.2, 1));
    }
});

WorkerComponentTimer.STATE_DEFAULT = 0;
WorkerComponentTimer.STATE_FREE = 1;
WorkerComponentTimer.STATE_BONUS_COINS = 2;
WorkerComponentTimer.STATE_BONUS_WATCH_AD = 3;
WorkerComponentTimer.STATE_BONUS_WATCH_AD_COMPLETE = 4;

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    COMPONENT_TIMER_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.ComponentTimer = {
    timer: {
        width: 136,
        x: 0,
        y: 0
    },

    animation: {
        x: 0,
        y: 45
    }
};

cleverapps.styles.WorkerComponentTimer = {
    button: {
        x: 0,
        y: -45,
        width: 140,
        height: 50,

        coinsButton: {
            width: 110
        },

        padding: {
            left: 12,
            right: 12,
            top: 5,
            bottom: 4
        }
    },

    bonus: {
        x: -43,
        y: 58
    }
};
