/**
 * Created by andrey on 04.02.2021.
 */

var RegionUnit = function (options) {
    this.code = options.code;
    this.type = options.type;
    this.region = true;

    this.currentOption = false;

    this.onUpdate = function () {};
};

RegionUnit.prototype.isSelected = function () {
    return Editor.currentEditor.selected === this;
};

RegionUnit.prototype.isCreateUnit = function () {
    return this.code === RegionUnit.CODE_CREATE;
};

RegionUnit.prototype.onClick = function () {
    if (this.isCreateUnit()) {
        Editor.currentEditor.regions.createNewRegion();
    } else if (this.isSelected()) {
        Editor.currentEditor.unselect();
    } else {
        Editor.currentEditor.select(this);
    }
};

RegionUnit.prototype.isFog = function () {
    return this.type === RegionUnit.TYPE_FOG;
};

RegionUnit.prototype.isMoneyFog = function () {
    return this.type === RegionUnit.TYPE_FOG && Map2d.currentMap.fogs.config[this.code].money;
};

RegionUnit.prototype.setCurrentOption = function (option) {
    if (this.currentOption === option) {
        this.currentOption = undefined;
    } else {
        this.currentOption = option;
    }

    this.onUpdate();
};

RegionUnit.CODE_CREATE = "zzzzzzz___create";

RegionUnit.TYPE_FOG = "fog";
RegionUnit.TYPE_REGION = "region";
