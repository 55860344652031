/**
 * Created by Denis Kuzin on 10 november 2022
 */

var CustomerRecipe = function (recipeTemplate, seed) {
    this.ingredients = this.chooseIngredients(recipeTemplate.ingredients, seed).map(function (ingredient) {
        return new CustomerIngredient(ingredient);
    }, this);

    this.order = this.chooseIngredients(recipeTemplate.order, seed);
    this._wantsWindow = false;

    if (recipeTemplate.cookingDuration) {
        this.cookingDuration = cleverapps.parseInterval(recipeTemplate.cookingDuration);
    }
};

CustomerRecipe.prototype.replaceWithCoins = function () {
    this.order.forEach(function (order) {
        if (order.unit && Game.currentGame.needToReplaceWithCoins(order.unit)) {
            order.unit = Game.currentGame.getUnitReplacer(order.unit);
        }
    });
};

CustomerRecipe.prototype.chooseIngredients = function (template, seed) {
    var availableCodes = {};
    var items = {};

    if (!template) {
        return [];
    }

    template.forEach(function (unitItem) {
        if (unitItem.ingredient) {
            if (!items[unitItem.ingredient]) {
                items[unitItem.ingredient] = {
                    ingredient: unitItem.ingredient,
                    amount: 0
                };
            }

            items[unitItem.ingredient].amount += unitItem.amount;
            return;
        }

        var chosenCode;

        if (unitItem.code) {
            chosenCode = unitItem.code;
        } else if (unitItem.type) {
            if (!availableCodes[unitItem.type]) {
                availableCodes[unitItem.type] = {};
            }

            if (!availableCodes[unitItem.type][unitItem.stage] || availableCodes[unitItem.type][unitItem.stage].length === 0) {
                availableCodes[unitItem.type][unitItem.stage] = cleverapps.unitsLibrary.listOpenedOfType(unitItem.type);

                if (availableCodes[unitItem.type][unitItem.stage].length === 0) {
                    availableCodes[unitItem.type][unitItem.stage] = cleverapps.unitsLibrary.listCodesByType(unitItem.type).slice(0, 1);
                }
            }

            var codes = availableCodes[unitItem.type][unitItem.stage];

            if (seed === undefined) {
                chosenCode = codes[0];
            } else {
                chosenCode = cleverapps.Random.choose(codes);
            }
            codes.splice(codes.indexOf(chosenCode), 1);
        }

        var chosenStage = unitItem.stage;
        if (unitItem.stages) {
            chosenStage = Math.min(Families[chosenCode].units.length - 1, cleverapps.Random.choose(unitItem.stages));
        }
        if (chosenStage === undefined) {
            chosenStage = Families[chosenCode].units.length - 1;
        }

        if (!items[chosenCode + "_" + chosenCode]) {
            items[chosenCode + "_" + chosenCode] = {
                unit: {
                    code: chosenCode,
                    stage: chosenStage
                },
                amount: 0
            };
        }

        items[chosenCode + "_" + chosenCode].amount += unitItem.amount;
    });

    return Object.values(items).map(function (item) {
        if (item.unit && Game.currentGame.needToReplaceWithCoins(item.unit)) {
            item.unit = Game.currentGame.getUnitReplacer(item.unit);
        }

        return item;
    });
};

CustomerRecipe.prototype.removeUnitFromIngredients = function (unit) {
    this.ingredients.forEach(function (ingredient) {
        ingredient.removeUnit(unit);
    });
};

CustomerRecipe.prototype.needsUnit = function (unit) {
    return this.ingredients.some(function (ingredient) {
        return ingredient.needsUnit(unit);
    });
};

CustomerRecipe.prototype.needsIngredients = function () {
    return this.ingredients.some(function (ingredient) {
        return ingredient.needsIngredients();
    });
};

CustomerRecipe.prototype.addUnits = function (units) {
    cleverapps.toArray(units).forEach(function (unit) {
        this.ingredients.forEach(function (ingredient) {
            if (ingredient.needsUnit(unit)) {
                ingredient.addUnit(unit);
            }
        });
    }.bind(this));
    return this._updateWantWindow();
};

CustomerRecipe.prototype.updateIngredients = function () {
    this.ingredients.forEach(function (ingredient) {
        if (ingredient.needsIngredients()) {
            ingredient.updateIngredients();
        }
    });
    return this._updateWantWindow();
};

CustomerRecipe.prototype._updateWantWindow = function () {
    var _wantsWindow = this._wantsWindow;
    this._wantsWindow = this.isReady();
    return !_wantsWindow && this._wantsWindow;
};

CustomerRecipe.prototype.cook = function (customerUnit, f) {
    var unitsToRemove = [];
    var ingredientsToRemove = [];

    this.ingredients.forEach(function (ingredient) {
        unitsToRemove = unitsToRemove.concat(ingredient.listUnitsForExchange());
        ingredientsToRemove = ingredientsToRemove.concat(ingredient.listIngredientsForExchange());
    });

    var unitsToSpawn = [];

    this.order.forEach(function (order) {
        if (order.ingredient) {
            // do nothing
        } else {
            for (var i = 0; i < order.amount; ++i) {
                unitsToSpawn.push(order.unit);
            }
        }
    });

    ingredientsToRemove.forEach(function (ingredient) {
        Game.currentGame.harvested.take(ingredient.ingredient, ingredient.amount);
    });

    var cellsForPrizes = Map2d.currentMap.countEmptySlots() + unitsToRemove.length - unitsToSpawn.length;
    unitsToRemove.forEach(function (unit) {
        unit.setCustomerMark(false);

        if (unit.prizesCount) {
            if (cellsForPrizes >= unit.prizesCount) {
                cellsForPrizes -= unit.prizesCount;
                unit.takePrizes();
            }
            unit.setPrizes();
        }
        if (unit.points) {
            unit.claimPoints();
        }

        var pulsing = unit.findComponent(Pulsing);
        if (pulsing) {
            pulsing.setRemoved(true);
        }

        unit.remove();
    });

    cleverapps.timeouts.setTimeout(f, 300);
};

CustomerRecipe.prototype.listPrize = function () {
    var prizes = [];

    this.order.forEach(function (item) {
        if (item.unit) {
            for (var i = 0; i < item.amount; i++) {
                prizes.push(item.unit);
            }
        } else if (item.ingredient) {
            prizes.push(item);
        }
    });

    return prizes;
};

CustomerRecipe.prototype.isReady = function () {
    return this.ingredients.every(function (ingredient) {
        return ingredient.isReady();
    });
};

CustomerRecipe.prototype.getIngredients = function () {
    return this.ingredients;
};

CustomerRecipe.prototype.getOrder = function () {
    return this.order;
};

CustomerRecipe.prototype.getCookingDuration = function () {
    return this.cookingDuration || 0;
};

CustomerRecipe.prototype.getShoppingList = function () {
    var shoppingList = {
        hardItems: [], softItems: [], totalUnits: 0, totalHard: 0, totalSoft: 0
    };
    this.getIngredients().forEach(function (ingredient) {
        shoppingList.totalUnits += ingredient.getLackingAmount();
        if (ingredient.useCoins()) {
            shoppingList.softItems.push(ingredient);
            shoppingList.totalSoft += ingredient.calcPrice();
        } else {
            shoppingList.hardItems.push(ingredient);
            shoppingList.totalHard += ingredient.calcPrice();
        }
    });
    return shoppingList;
};

CustomerRecipe.prototype.needsMore = function (item) {
    return this.ingredients.some(function (ingredient) {
        return ingredient.equals(item) && ingredient.getLackingAmount() > 0;
    });
};

CustomerRecipe.selectTemplate = function (unit, seed, ignoreFilter) {
    if (seed !== undefined) {
        cleverapps.Random.seed(seed);
    }
    var recipes = CustomerRecipes[cleverapps.travelBook.getCurrentPage().id];
    recipes = recipes && recipes[Unit.GetKey(unit)];
    if (!recipes) {
        return;
    }

    recipes = recipes.filter(function (recipe) {
        if (ignoreFilter) {
            return true;
        }

        if (recipe.fog && !Map2d.currentMap.fogs.isOpened(recipe.fog)) {
            return false;
        }

        if (recipe.requiredUnits && !recipe.requiredUnits.every(function (unit) {
            return cleverapps.unitsLibrary.isOpened(unit);
        })) {
            return false;
        }

        if (recipe.opened && !recipe.ingredients.every(function (ingredient) {
            return !ingredient.code || cleverapps.unitsLibrary.isOpened(ingredient);
        })) {
            return false;
        }

        if (recipe.onlyIfNeeded && !Map2d.currentMap.customers.listActive().some(function (customer) {
            return (recipe.order || []).some(function (item) {
                return customer.getCurrentRecipe().needsMore(item);
            });
        })) {
            return false;
        }

        return true;
    });

    return seed === undefined ? recipes[0] : cleverapps.Random.choose(recipes);
};

var CustomerRecipes = {
    main: {}
};
