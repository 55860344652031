/**
 * Created by Aleksandr on 11.11.2022
 */

var ClanGift = function (data) {
    this.giftId = data.giftId;
    this.messageId = data.messageId;
    this.messageType = data.messageType;
    this.reward = data.data;
    this.status = data.status || ClansConfig.GIFT_READY;

    if (this.reward) {
        Object.keys(this.reward).forEach(function (type) {
            switch (type) {
                case "coins":
                    this.reward.soft = this.reward.coins;
                    delete this.reward.coins;
                    break;
                case "gold":
                    this.reward.hard = this.reward.gold;
                    delete this.reward.gold;
                    break;
            }
        }.bind(this));
    }
};

ClanGift.prototype.getInfo = function () {
    return {
        giftId: this.giftId,
        messageId: this.messageId,
        messageType: this.messageType,
        reward: this.reward,
        status: this.status
    };
};

ClanGift.prototype.isReceived = function () {
    return this.status === ClansConfig.GIFT_RECEIVED;
};

ClanGift.prototype.getGift = function () {
    return this.reward;
};

ClanGift.prototype.getGiftAmount = function () {
    if (this.reward && this.reward.soft) {
        return this.reward.soft;
    }

    return 0;
};

ClanGift.prototype.collect = function () {
    this.status = ClansConfig.GIFT_RECEIVED;

    var gift = this.getGift();
    if (gift) {
        new RewardWindow(gift, { event: cleverapps.EVENTS.EARN.CLAN });
    }
};
