/**
 * Created by r4zi4l on 29.01.2021
 */

var InstantWorkerView = cc.Node.extend({
    ctor: function (worker, unitView) {
        this._super();

        this.worker = worker;
        this.unitView = unitView;

        this.restoreState();
    },

    startMagic: function () {
        this.restoreState(true);
    },

    finishMagic: function () {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(cleverapps.styles.InstantWorkerView.jump.duration),
            new cc.CallFunc(function () {
                this.restoreState(true);
            }.bind(this))
        ));
    },

    restoreState: function (animated) {
        if (this.worker.isWorking()) {
            this.unitView.hideSprite();
        } else {
            this.unitView.showSprite();
        }

        if (this.worker.isFresh() || this.worker.isWorking() || InfoView.IsDisplayedFor(this.worker.unit)) {
            this.removeProgress(animated);
        } else {
            this.createProgress(animated);
        }
    },

    createProgress: function (animated) {
        if (this.progress) {
            return;
        }

        var styles = cleverapps.styles.InstantWorkerView.progress;

        var progress = this.progress = new ScaledProgressBar({
            type: styles.type
        });
        progress.setLength(styles.width);
        progress.setPositionRound(styles);
        progress.setPercentage(this.worker.getPercentage());
        this.addChild(this.progress);

        progress.baseScale = progress.scale;

        if (animated) {
            progress.runAction(new cc.Sequence(
                new cc.MoveBy(0, 0, -styles.offsetY),
                new cc.ScaleTo(0, this.progress.baseScale / 2),
                new cc.Show(),
                new cc.Spawn(
                    new cc.MoveBy(0.1, 0, styles.offsetY),
                    new cc.ScaleTo(0.1, this.progress.baseScale)
                )
            ));
        }
    },

    removeProgress: function () {
        if (!this.progress) {
            return;
        }

        this.progress.removeFromParent();
        this.progress = undefined;
    }
});

InstantWorkerView.createInstantAnimation = function (unitView, worker, time) {
    if (unitView.aboveInstantAnimation || unitView.belowInstantAnimation) {
        return;
    }

    var aboveAnimation = unitView.aboveInstantAnimation = InstantWorkerView.createAboveAnimation(unitView, worker);

    if (cleverapps.Spine.hasAnimation("building_back", UnitView.UnitIconJson(worker.unit))) {
        var belowAnimation = UnitView.getUnitImage(worker.unit, { defaultAnimation: "building_back" });
        belowAnimation.setAnchorPoint(aboveAnimation.anchorX, aboveAnimation.anchorY);
        belowAnimation.setPosition(aboveAnimation.x, aboveAnimation.y);
        belowAnimation.setLocalZOrder(-1);
        unitView.addChild(belowAnimation);
        unitView.belowInstantAnimation = belowAnimation;
    }

    var completedFraction = (Date.now() - worker.startBuildTime) / InstantWorker.BUILDING_ANIMATION_TIME;
    var uncompleted = 1 - completedFraction;

    var delta = Math.min(time, worker.limit);
    delta = Math.round(uncompleted * delta);

    var targetTime = Math.max(0, time - worker.limit);
    var timeLeft = targetTime + delta;

    var timer = new InstantComponentTimer(worker, {
        time: timeLeft,
        limit: delta,
        duration: Math.round(uncompleted * InstantWorker.BUILDING_ANIMATION_TIME),
        minimalValue: targetTime
    });
    timer.setPositionRound(cleverapps.styles.WorkerView.timer);
    unitView.animateAppearAdditionalView("timer", timer);
};

InstantWorkerView.createAboveAnimation = function (unitView, worker) {
    var unitStyles = UnitView.getUnitAnchor(unitView.unit);
    var workerStyles = UnitView.getUnitAnchor(worker.unit);

    var aboveAnimation = UnitView.getUnitImage(worker.unit);
    aboveAnimation.setCompleteListener();
    aboveAnimation.setAnchorPoint(workerStyles.x, workerStyles.y);
    aboveAnimation.setPositionRound(unitView.width / 2, unitStyles.y * unitView.height);
    aboveAnimation.setAnimation(0, "building", true);
    unitView.addChild(aboveAnimation);
    return aboveAnimation;
};

InstantWorkerView.removeInstantAnimation = function (unitView) {
    if (unitView.aboveInstantAnimation) {
        unitView.aboveInstantAnimation.removeFromParent();
        delete unitView.aboveInstantAnimation;
    }

    if (unitView.belowInstantAnimation) {
        unitView.belowInstantAnimation.removeFromParent();
        delete unitView.belowInstantAnimation;
    }

    unitView.removeAdditionalView("timer");
};

InstantWorkerView.startMagic = function (unitView, worker, callback) {
    var flyingAnimation = InstantWorkerView.createAboveAnimation(unitView, worker);
    flyingAnimation.setAnimation(0, "jump", cleverapps.styles.InstantWorkerView.jump.repeat);

    InstantWorkerView.jumpAnimation(flyingAnimation, worker.unit, unitView.unit, unitView, function () {
        cleverapps.audio.playSound(bundles.merge.urls.instantworker_build_effect);
        callback();
    });
};

InstantWorkerView.finishMagic = function (unitView, worker, callback) {
    var movingNode = cleverapps.scenes.getMovingNode(unitView);

    var flyingAnimation = InstantWorkerView.createAboveAnimation(unitView, worker);
    flyingAnimation.replaceParentSamePlace(movingNode);
    flyingAnimation.setAnimation(0, "jump_back", cleverapps.styles.InstantWorkerView.jump.repeat);

    InstantWorkerView.jumpAnimation(flyingAnimation, unitView.unit, worker.unit, movingNode, function () {
        callback();
    });
};

InstantWorkerView.jumpAnimation = function (animation, from, to, parent, callback) {
    var mapView = Map2d.currentMap.getMapView();

    var fromPos = parent.convertToNodeSpace(mapView.convertToWorldSpace(from.onGetView() || mapView.alignInIsometricGrid(from.x, from.y)));
    var toPos = parent.convertToNodeSpace(mapView.convertToWorldSpace(mapView.alignInIsometricGrid(to.x, to.y)));

    var distance = cc.pDistance(fromPos, toPos);

    animation.setPositionRound(fromPos);

    animation.runAction(new cc.Sequence(
        new cc.JumpTo(cleverapps.styles.InstantWorkerView.jump.duration, toPos, distance * 0.3, 1),
        new cc.CallFunc(callback),
        new cc.RemoveSelf()
    ));
};

cleverapps.styles.InstantWorkerView = {
    jump: {
        duration: 0.45,
        repeat: true
    },

    progress: {
        type: ScaledProgressBar.Types.red_small,
        width: 80,
        x: 5,
        y: -55,
        offsetY: 15
    },

    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -29 }
    }
};