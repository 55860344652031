/**
 * Created by andrey on 04.02.2021.
 */

var RegionsTab = cc.Node.extend({
    ctor: function (key, container, options) {
        this._super();
        this.setContentSize2(options);
        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });

        this.options = options;
        this.key = key;
        this.container = container;

        this.container.on("refresh", this.refresh.bind(this), this);
        this.refresh();
    },

    refresh: function () {
        if (this.content) {
            this.content.removeFromParent();
            delete this.content;
        }

        var styles = cleverapps.styles.RegionsTab;

        var icons = this.container.getUnitIcons(this.options);

        var grid = this.grid = new cleverapps.GridLayout(icons, {
            columns: 8,
            align: {
                y: cleverapps.GridLayout.ALIGN_END
            },
            padding: styles.grid.padding,
            margin: styles.grid.margin
        });
        grid.setPositionRound({
            x: { align: "center" },
            y: { align: "top" }
        });

        if (grid.height <= this.height) {
            this.addChild(grid);
            this.content = grid;
            return;
        }

        var scroll = this.content = new cleverapps.UI.ScrollView(grid, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL,
            scrollBarEnabled: false
        });
        scroll.setContentSize(this.width, this.height);
        scroll.scrollToDefault();
        scroll.setAnchorPoint(0.5, 0.5);
        scroll.setPositionRound(this.width / 2, this.height / 2);
        scroll.onUpdatePositionListener = function () {
            Editor.currentEditor["scrollPosition_" + this.key] = scroll.getContainerPosition();
        }.bind(this);

        if (Editor.currentEditor["scrollPosition_" + this.key]) {
            scroll.scrollToPoint(Editor.currentEditor["scrollPosition_" + this.key]);
        }

        this.addChild(scroll);
    }
});

cleverapps.styles.RegionsTab = {
    grid: {
        padding: {
            x: 0,
            y: 60
        },

        margin: {
            x: 30,
            y: 70
        }
    }
};