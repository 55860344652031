/**
 * Created by mac on 12/27/20
 */

var FruitMine = {
    prize: function (level, unit) {
        return [
            {
                amount: 2,
                units: [{ code: unit.code, stage: 0 }]
            },

            {
                probabilities: [0.2, 1],
                units: [
                    { code: unit.code, stage: 1 },
                    { code: unit.code, stage: 0 }
                ]
            },

            {
                probabilities: [0.3],
                units: [{ code: "coins", stage: 0 }]
            },

            {
                probabilities: [0.4],
                units: [{ code: "magicplant", stage: 0 }]
            }
        ];
    }
};