/**
 * Created by Aleksandr on 02.08.2024
 */

var CompleteLocationComponentView = cc.Node.extend({
    ctor: function (component, unitView) {
        this._super();

        this.unitView = unitView;

        if (!cleverapps.config.debugMode) {
            return;
        }

        var label = cleverapps.UI.generateOnlyText("open location", cleverapps.styles.FONTS.WHITE_TEXT);
        label.setPosition(this.width, this.height);
        this.addChild(label);
    }
});