/**
 * Created by vladislav on 25/10/2022
 */

QuestsConfig.xmas = {
    xmproducer1a_0: {
        trigger: { fog: "fog2" },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "xmproducer1a", stage: 0 }
        }
    },

    xmproducer0a_0: {
        trigger: { unit: { code: "xmproducer1a", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "xmproducer0a", stage: 0 }
        }
    },

    xmproduct0a_0: {
        trigger: { unit: { code: "xmproducer1a", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "xmproduct0a", stage: 0 }
        }
    },

    xmproduct1a_0: {
        trigger: { unit: { code: "xmproducer0a", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "xmproduct1a", stage: 0 }
        }
    },

    xmproduct2a_0: {
        trigger: { unit: { code: "xmcustomermain0", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "xmproduct2a", stage: 0 } 
        }
    },

    xmproducer2a_0: {
        trigger: { unit: { code: "xmproduct1a", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "xmproducer2a", stage: 0 }
        },
        dynamic: {
            getIcon: function () {
                return new cc.Sprite(bundles.merge_xmas.frames.quest_xmproducer2a);
            }
        }
    },

    xmcustomermain5_00: {
        trigger: { unit: { code: "xmcustomermain0", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "xmcustomermain5", stage: 0 }
        }
    },

    xmproducer1b_0: {
        trigger: { fog: "fog12" },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "xmproducer1b", stage: 0 }
        }
    },

    xmproduct0b_0: {
        trigger: { unit: { code: "xmproducer1b", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "xmproduct0b", stage: 0 }
        }
    },

    xmproduct1b_0: {
        trigger: { unit: { code: "xmproducer1b", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "xmproduct1b", stage: 0 }
        }
    },

    xmproducer2b_0: {
        trigger: { unit: { code: "xmproduct1b", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "xmproducer2b", stage: 0 }
        }
    }

};