/**
 * Created by Andrey Popov on 1/24/24.
 */

var PrefabPlayer = function (options) {
    this.options = options;
    if (options.clipConfig.properties.frameCapture && !options.clipConfig.properties.frameCapture.video) {
        this.screenshotParameters = options.clipConfig.properties.frameCapture;
    }
    this.caption = options.clipConfig.properties.caption;

    this.clipPreviewBar = new ClipPreviewBar(this);

    window.addEventListener("message", this.handleMessage.bind(this));
};

PrefabPlayer.prototype.run = function () {
    cleverapps.debugStats.setEnabled(false);
    cleverapps.flags.noTransitionAnimation = true;

    var player = new ActionPlayer();
    var options = this.options;

    if (!options.download && !options.preview && !this.barView) {
        this.barView = new ClipPreviewBarView(this.clipPreviewBar);
        cleverapps.scenes.getRunningScene().addChild(this.barView);
    }

    if (this.prefabNodes) {
        this.prefabNodes.forEach(function (prefab) {
            prefab.destroy();
        });
    }
    this.prefabNodes = [];

    cleverapps.wysiwyg.wysiwygPreview.selectedResolution = options.resolutionName;
    var resolution = Wysiwyg.VIDEO_SIZES[options.resolutionName];
    var width = resolution.width / window.devicePixelRatio;
    var height = resolution.height / window.devicePixelRatio;

    if (!options.download) {
        var windowSize = cleverapps.resolution.getFrameSize();
        var scale = Math.min(windowSize.width / width, windowSize.height / height);
        width *= scale;
        height *= scale;
    }

    cleverapps.resolution.setupDesignResolution(cc.size(width, height));

    player.add(function (f) {
        if (bundles["language_" + options.language]) {
            cleverapps.bundleLoader.loadBundle("language_" + options.language);
        }

        cleverapps.settings.setLanguage(options.language);
        f = cleverapps.wait(2, f);

        cleverapps.wysiwyg.loadExtraBundles(f);
        Messages.preload(options.language, f);
    });

    player.add(function (f) {
        cleverapps.audio.unmute();
        cc.audioEngine.stopAllEffects();

        f();
    });

    options.scenes.forEach(function (sceneConfig, prefabIndex) {
        player.add(this.runPrefab.bind(this, prefabIndex, sceneConfig));
    }.bind(this));

    player.add(this.stopRecording.bind(this));

    player.play();

    if (options.clipConfig.properties.cutOff) {
        var endCardSceneIndex = options.scenes.length - 1;
        var endCardScene = options.scenes[endCardSceneIndex];

        this.cutOffTimeout = setTimeout(function () {
            if (this.prefabNodes.length === options.scenes.length || endCardSceneIndex < 1) {
                return;
            }

            player.stop();
            this.runPrefab(endCardSceneIndex, endCardScene, function () {
                this.recorder.stopCapture(this.caption, this.stopRecording.bind(this));
            }.bind(this));
        }.bind(this), 30000 - endCardScene.properties.duration);
    }
};

PrefabPlayer.prototype.stopRecording = function (f) {
    var onFinish = function (f) {
        this.prefabNodes.forEach(function (prefab) {
            prefab.destroy();
            prefab.removeFromParent();
        });

        var currentWindow = cleverapps.windows.currentWindow();
        if (currentWindow) {
            currentWindow.close();
        }

        f && f();

        if (this.options.onFinish) {
            setTimeout(this.options.onFinish, 500);
        }
    }.bind(this);

    if (this.cutOffTimeout) {
        clearTimeout(this.cutOffTimeout);
        delete this.cutOffTimeout;
    }

    this.clipPreviewBar.stopTimer();
    cleverapps.audio.mute();
    cleverapps.audio.eject();

    if (this.options.download) {
        if (this.screenshotParameters && this.screenshotParameters.frequency > 0 && this.screenshotParameters.amount > 0) {
            setTimeout(onFinish.bind(this, f), this.screenshotParameters.frequency * 1000 * this.screenshotParameters.amount + 200);
        } else {
            this.recorder.stopCapture(this.caption, onFinish.bind(this, f));
        }
    }
};

PrefabPlayer.prototype.runPrefab = function (prefabIndex, sceneConfig, f) {
    var prefabNode = new Prefab(sceneConfig, {
        size: Wysiwyg.VIDEO_SIZES[this.options.resolutionName],
        preview: this.options.preview,
        onInit: function () {
            this.clipPreviewBar.resetTimer();
            cleverapps.debugStats.setEnabled();

            if (prefabIndex === 0) {
                if (this.options.download) {
                    var params = {
                        folder: this.options.resolutionName + "/" + this.options.language
                    };
                    if (this.screenshotParameters && this.screenshotParameters.frequency > 0) {
                        params.screenshotParameters = Object.assign({}, this.screenshotParameters, {
                            frequency: (this.screenshotParameters.frequency || 0) * 1000,
                            amount: this.screenshotParameters.amount,
                            name: this.caption
                        });
                    }
                    if (!this.recorder) {
                        this.recorder = new Recorder(!this.screenshotParameters);
                    }
                    this.recorder.startCapture(params);
                }

                var music = this.options.clipConfig.properties.music;
                if (music && music.name) {
                    cleverapps.audio.playSound(cleverapps.wysiwyg.googleDriveProvider.getSoundLink(music));
                } else if (!this.screenshotParameters) {
                    var scene = cleverapps.scenes.getRunningScene();
                    if (scene.getAudioStyles()) {
                        scene.startOrRestartMusic(scene.getAudioStyles());
                    }
                }
            }

            window.parent.postMessage(JSON.stringify({ type: "sceneLoaded" }));
        }.bind(this),
        onComplete: f
    });

    if (prefabIndex > 0 && this.prefabNodes[prefabIndex - 1] && this.prefabNodes[prefabIndex - 1].snapshotComponent && prefabNode.snapshotComponent) {
        this.prefabNodes[prefabIndex - 1].destroy();
        this.prefabNodes[prefabIndex - 1].removeFromParent();

        Game.currentGame = undefined;
    }

    prefabNode.attachToScene();

    this.prefabNodes.push(prefabNode);
};

PrefabPlayer.prototype.reloadSnapshot = function () {
    new ActionPlayer([
        function (f) {
            if (cleverapps.loadedSnapshot) {
                cleverapps.snapshots.importData(cleverapps.loadedSnapshot, f);
            } else {
                f();
            }
        },

        function (f) {
            Messages.preload(this.options.language, f);
        }.bind(this),

        function (f) {
            if (!cleverapps.focusManager.isFocused()) {
                cleverapps.focusManager.display({
                    focus: "SceneRefreshForClip",
                    action: f
                });
            } else if (cleverapps.focusManager.focus !== "SceneRefreshForClip") {
                cleverapps.focusManager.distract({
                    focus: "SceneRefreshForClip",
                    action: f
                });
            } else {
                f();
            }
        },

        function (f) {
            var scene = cleverapps.loadedSnapshot && cleverapps.loadedSnapshot.scene;
            if (scene) {
                cleverapps.reloadSnapshotScene(scene, f);
            } else {
                cleverapps.scenes.replaceScene(new cleverapps.FixedWidthScene(), function () {
                    cleverapps.travelBook.gotoMainScene(f);
                });
            }
        },

        function (f) {
            f();
            cleverapps.focusManager.freeFocus("SceneRefreshForClip");
            this.run();
        }.bind(this)
    ]).play();
};

PrefabPlayer.prototype.completeDownload = function (f) {
    this.recorder.saveZip(cleverapps.config.name + "_" + this.caption + ".zip", f);
};

PrefabPlayer.prototype.handleMessage = function (event) {
    var data;
    try {
        data = JSON.parse(event.data);
    } catch (e) {
        return;
    }

    var hierarchy = cleverapps.wysiwyg.hierarchy;

    switch (data.type) {
        case "redrawComponent":
            this.prefabNodes.forEach(function (sceneNode) {
                sceneNode.redrawComponent(data.item);
            });
            break;
        case "changeLanguage":
            cleverapps.settings.setLanguage(data.language);
            break;
        case "debugClick":
            cleverapps.keyboardController.keyPressed[cc.KEY.ctrl] = true;
            cleverapps.config.wysiwygMode = false;

            var screenRect = cc.game.canvas.getBoundingClientRect();
            var point = cc.p(data.point.x * screenRect.width * window.devicePixelRatio, data.point.y * screenRect.height * window.devicePixelRatio);

            var touchId = Math.ceil(Math.random() * 100);
            var touch = {
                _prevPoint: point,
                _point: point,
                getID: function () {
                    return touchId;
                }
            };
            cc.inputManager.handleTouchesBegin([touch]);
            cc.director.getScheduler().schedule(function () {
                touch._prevPoint = touch._point;
                touch._point = point;
                cc.inputManager.handleTouchesMove([touch]);
            }, this, 0, cc.REPEAT_FOREVER, 0, false, this.__instanceId + "_" + touchId);

            cc.director.getScheduler().unschedule(this.__instanceId + "_" + touchId, this);
            cc.inputManager.handleTouchesEnd([touch]);

            cleverapps.keyboardController.keyPressed[cc.KEY.ctrl] = false;
            cleverapps.config.wysiwygMode = true;

            if (!selectedNode) {
                return;
            }

            var selection = cleverapps.config.type === "merge" && selectedNode.constructor === UnitView ? selectedNode.sprite : selectedNode;

            if (selection.constructor === cc.Sprite || selection.constructor === cc.Scale9Sprite) {
                var frame = selection.spriteFrameName;

                Object.keys(bundles).forEach(function (bundleName) {
                    Object.keys(bundles[bundleName].frames).forEach(function (frameName) {
                        if (bundles[bundleName].frames[frameName].link === frame) {
                            window.parent.postMessage(JSON.stringify({
                                type: "newAdHocItem",
                                bundle: bundleName,
                                image: frameName
                            }));
                        }
                    });
                });
            } else if (selection.jsonName && processVirtualJson(selection.jsonName)) {
                for (var i = 0; i < Object.keys(bundles).length; i++) {
                    var bundleName = Object.keys(bundles)[i];

                    for (var j = 0; j < Object.keys(bundles[bundleName].jsons).length; j++) {
                        var jsonName = Object.keys(bundles[bundleName].jsons)[j];

                        if (processVirtualJson(bundles[bundleName].jsons[jsonName], true) === processVirtualJson(selection.jsonName)) {
                            window.parent.postMessage(JSON.stringify({
                                type: "newAdHocItem",
                                bundle: bundleName,
                                spine: jsonName
                            }));
                            return;
                        }
                    }
                }
            }

            break;
        case "reload":
            var snapshotId = cleverapps.loadedSnapshot ? cleverapps.loadedSnapshot.snapshotId : "wysiwygdummyclip";
            if (data.hardReload || snapshotId !== data.snapshot) {
                cleverapps.setUrlHash({ snapshot: data.snapshot, clipId: data.clipId, scene: data.scene });
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
                return;
            }

            hierarchy.processClipData(data.hierarchyItems);

            cleverapps.wysiwyg.loadPrefabResources(
                hierarchy.getSelectedClip(),
                function (scenes) {
                    this.options.scenes = scenes;
                    cleverapps.setUrlHash({ res: data.resolution });
                    this.options.resolutionName = data.resolution;
                    this.run();
                }.bind(this),
                data.scene
            );

            break;
    }
};