/**
 * Created by razial on 28.10.2021
 */

var ExpeditionWandsGuideOptions = {
    name: "ExpeditionWandsGuideWindow",

    getGuideBundle: function () {
        if (cleverapps.travelBook.getCurrentPage().id === "undersea") {
            return bundles.undersea_expeditionguidewindow_old;
        }
        return bundles[cleverapps.skins.getSlot("skinName") + "_expeditionguidewindow"];
    },

    stagesContent: function () {
        var styles = cleverapps.styles.ExpeditionWandsGuideWindow;
        var unitCode = cleverapps.unitsLibrary.listCodesByType(cleverapps.unitsLibrary.getExpeditionUnitType("hero"))[0];

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            var ground1 = this.addSprite(container, this.bundle.frames.expedition_ground, styles.ground);
            [this.bundle.frames.highlight_dark, this.bundle.frames.highlight_light, this.bundle.frames.highlight_light].map(function (image, index) {
                return this.addSprite(ground1, image, styles.highlight[index]);
            }, this);
            [{ code: unitCode, stage: 0 }, { code: unitCode, stage: 0 }, { code: unitCode, stage: 0 }].forEach(function (unit, index) {
                this.addUnitIcon(ground1, unit.code, unit.stage, styles.units[index]);
            }, this);
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            var ground2 = this.addSprite(container, this.bundle.frames.expedition_ground, styles.ground);
            var unit = this.addUnitIcon(ground2, unitCode, 1, styles.hero);
            this.addSprite(unit, this.bundle.frames.expedition_prizeview, styles.prizeview);
            this.addSprite(ground2, bundles.finger.frames.tutorial_hand_png, styles.finger);
        }.bind(this));

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            var ground3 = this.addSprite(container, this.bundle.frames.expedition_ground, styles.ground);
            this.addSprite(ground3, this.bundle.frames.guide_cloud, styles.fog);
            var banner = this.addSprite(container, this.bundle.frames.expedition_banner, styles.banner);
            this.addSprite(banner, this.bundle.frames.expedition_button, styles.button);
        }.bind(this));

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.ExpeditionWandsGuideWindow = {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        },

        ground: {
            x: 0,
            y: 0
        },

        highlight: [{
            x: 192,
            y: 182
        }, {
            x: 104,
            y: 133
        }, {
            x: 278,
            y: 133
        }],

        units: [{
            x: 192,
            y: 182,
            alignAnchorY: true
        }, {
            x: 104,
            y: 133,
            alignAnchorY: true
        }, {
            x: 278,
            y: 133,
            alignAnchorY: true
        }]
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -80 }
        },

        ground: {
            x: 0,
            y: 0
        },

        prizeview: {
            x: { align: "center" },
            y: { align: "top", anchor: 0, dy: 80 }
        },

        hero: {
            x: 210,
            y: 205,
            scale: 1.2
        },

        finger: {
            x: 305,
            y: 33
        }
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        },

        ground: {
            x: 0,
            y: -30
        },

        fog: {
            x: 186,
            y: 132
        },

        banner: {
            x: -5,
            y: 45
        },

        button: {
            x: 111,
            y: -50
        }
    }
};
