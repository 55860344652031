/**
 * Created by Denis Kuzin on 30 september 2021
 */

var ConfirmSpeedUpWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.timeLeft = options.timeLeft;
        this.totalDuration = options.totalDuration;
        this.eventName = options.eventName;
        this.callback = options.callback;
        this.price = options.price !== undefined ? options.price : SpeedUpWindow.CalcPrice(this.timeLeft);
        this.source = options.source;
        this.canCoins = options.canCoins === undefined ? true : options.canCoins;

        this._super({
            title: "ConfirmSpeedUpWindow.title",
            name: "ConfirmSpeedUpWindow",
            content: this.createContent(),
            closeButton: true
        });

        cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");
    },

    getPerson: function () {
        return "worker";
    },

    createContent: function () {
        var styles = cleverapps.styles.ConfirmSpeedUpWindow;
        var items = [];

        items.push(this.createTitle());
        items.push(this.createTimer());
        items.push(this.createRubyButton());

        return new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createTitle: function () {
        var styles = cleverapps.styles.ConfirmSpeedUpWindow;

        var text = cleverapps.UI.generateOnlyText("ConfirmSpeedUpWindow.text", cleverapps.styles.FONTS.SPEEDUP_WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.textWidth, 0);
        text.fitTo(undefined, styles.textHeight);
        return text;
    },

    createRubyButton: function () {
        var styles = cleverapps.styles.ConfirmSpeedUpWindow.buttons;

        return new UseGoldButton({
            price: this.price,
            onClicked: function () {
                this.close();
                this.callback();
            }.bind(this),
            freeIcon: TextWithIcon.ICONS_BY_NAME.speedup,
            canCoins: this.canCoins,
            source: this.source,
            eventName: this.eventName,
            type: cleverapps.styles.UI.Button.Images.button_blue,
            width: styles.button.width,
            height: styles.button.height
        });
    },

    createTimer: function () {
        var styles = cleverapps.styles.ConfirmSpeedUpWindow.timer;

        var timer = new cleverapps.CountDownView(new cleverapps.CountDown(this.timeLeft, {
            onFinish: this.close.bind(this)
        }), {
            font: cleverapps.styles.FONTS.SPEEDUP_WINDOW_TIMER_TEXT,
            bar: {
                type: ScaledProgressBar.Types.blue_large_with_frames,
                width: styles.width,
                height: styles.height,
                fullValue: this.totalDuration
            },
            icon: {
                frame: bundles.timer.frames.timer_icon_large,
                json: bundles.timer.jsons.timer_icon_large_json,
                animation: "animation"
            }
        });
        timer.bar.progress.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.TintTo(0.6, 160, 160, 160),
                new cc.DelayTime(0.1),
                new cc.TintTo(0.6, 255, 255, 255),
                new cc.DelayTime(0.1)
            )
        ));
        return timer;
    }
});

ConfirmSpeedUpWindow.TRIGGER_PRICE = 10;

ConfirmSpeedUpWindow.isAvailable = function (price) {
    if (price > ConfirmSpeedUpWindow.TRIGGER_PRICE) {
        var boostTimeMission = cleverapps.missionManager.findByType(Mission.TYPE_BOOSTTIME);
        return !boostTimeMission || !MissionManager.hasProperParent(boostTimeMission);
    }
};

cleverapps.styles.ConfirmSpeedUpWindow = {
    margin: 40,
    textWidth: 1100,
    textHeight: 135,

    timer: {
        width: 460,
        height: 90
    },

    buttons: {
        margin: 60,
        coinsMargin: 120,

        reward: {
            scale: 1.4
        },

        button: {
            width: 400,
            height: 136,
            margin: 8
        },

        note: {
            margin: 10
        }
    }
};
