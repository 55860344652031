/**
 * Created by vladislav on 26/10/2022
 */

var CustomerIngredient = function (ingredient) {
    cleverapps.EventEmitter.call(this);

    this.requiredAmount = ingredient.amount;
    this.ingredient = ingredient.ingredient;
    this.unit = ingredient.unit;

    this.units = [];
};

CustomerIngredient.prototype = Object.create(cleverapps.EventEmitter.prototype);
CustomerIngredient.prototype.constructor = CustomerIngredient;

CustomerIngredient.prototype.isReady = function () {
    return this.getCurrentAmount() >= this.requiredAmount;
};

CustomerIngredient.prototype.needsUnit = function (unit) {
    return this.unit && Unit.IsApplicable(this.unit, unit) && unit.isBuilt();
};

CustomerIngredient.prototype.needsIngredients = function () {
    return this.ingredient !== undefined;
};

CustomerIngredient.prototype.listUnitsForExchange = function () {
    return this.units.slice(0, this.requiredAmount);
};

CustomerIngredient.prototype.listIngredientsForExchange = function () {
    if (this.ingredient) {
        return [{
            ingredient: this.ingredient,
            amount: this.requiredAmount
        }];
    }

    return [];
};

CustomerIngredient.prototype.getCurrentAmount = function () {
    if (this.ingredient) {
        return Game.currentGame.harvested.get(this.ingredient);
    }

    return this.units.length;
};

CustomerIngredient.prototype.removeUnit = function (unit) {
    var index = this.units.indexOf(unit);
    if (index !== -1) {
        this.units.splice(index, 1);
        this.trigger("amountChanged");
    }
};

CustomerIngredient.prototype.addUnit = function (unit) {
    if (!this.units.includes(unit)) {
        this.units.push(unit);
        unit.setCustomerMark(true);
        this.trigger("amountChanged");
    }
};

CustomerIngredient.prototype.updateIngredients = function () {
    this.trigger("amountChanged");
};

CustomerIngredient.prototype.buyLacking = function () {
    var amount = this.getLackingAmount();
    for (var i = 0; i < amount; i++) {
        var cell = Map2d.currentMap.getScreenCenterCell();
        var slot = Map2d.currentMap.findEmptySlot(cell.x, cell.y);

        var unit = new Unit(this.unit);
        unit.setPosition(slot.x, slot.y);
        Map2d.currentMap.add(Map2d.LAYER_UNITS, unit.x, unit.y, unit);
        Map2d.currentMap.onAddUnit(unit.x, unit.y, unit);
        Map2d.currentMap.onUnitAvailable(unit);
    }
};

CustomerIngredient.prototype.getLackingAmount = function () {
    return Math.max(this.requiredAmount - this.getCurrentAmount(), 0);
};

CustomerIngredient.prototype.calcPrice = function () {
    return this.calcBasePrice() * this.getLackingAmount();
};

CustomerIngredient.prototype.calcBasePrice = function () {
    if (this.unit && !cleverapps.travelBook.getCurrentPage().noOrdersBuyout && cleverapps.unitsLibrary.isOpened(this.unit)) {
        var type = Families[this.unit.code].type;
        if (type.indexOf("order") !== -1 || type.indexOf("resource") !== -1) {
            return this.unit.stage === 3 ? 199 : 0;
        }

        if (type.indexOf("fruit") !== -1) {
            return 10;
        }

        if (type.indexOf("product0") !== -1) {
            return 20;
        }
        if (type.indexOf("product1") !== -1) {
            return 50;
        }
        if (type.indexOf("product2") !== -1) {
            return 125;
        }
        if (type.indexOf("product3") !== -1) {
            return 310;
        }
    }

    return 0;
};

CustomerIngredient.prototype.useCoins = function () {
    if (this.unit) {
        switch (Families[this.unit.code].type) {
            case "searesource":
            case "rpresource":
                return true;
        }
    }
    return false;
};

CustomerIngredient.prototype.getHelp = function () {
    var helpParams;

    if (this.unit) {
        if (Families[this.unit.code].type === "campaignorder") {
            helpParams = {
                unit: this.unit,
                useStageForCustomerSearch: true
            };
        }
        if (Families[this.unit.code].locators) {
            helpParams = {
                unit: this.unit,
                getFindTargets: function () {
                    return Families[this.unit.code].locators;
                }.bind(this)
            };
        }

        if (Families[this.unit.code].type.indexOf("resource") !== -1) {
            helpParams = {
                unit: this.unit,
                resource: true
            };
        }
    }

    if (this.ingredient) {
        helpParams = {
            ingredient: this.ingredient
        };
    }

    return helpParams;
};

CustomerIngredient.prototype.equals = function (item) {
    return item && (this.unit ? Unit.Equals(this.unit, item) : this.ingredient === item.ingredient);
};