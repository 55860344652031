/**
 * Created by r4zi4l on 22.04.2022
 */

var ClansConfig = {};

ClansConfig.MIN_REQUIRED_LEVEL = 8;
ClansConfig.MAX_REQUIRED_LEVEL = 50;
ClansConfig.MAX_SIZE = 50;
ClansConfig.CREATION_PRICE = 3490;
ClansConfig.EDITING_PRICE = 2000;

ClansConfig.GIFT_MESSAGES_VERSION = "1.304.0";
ClansConfig.NEW_REWARD_FORMAT_VERSION = "1.318.0";

ClansConfig.ROLE_MEMBER = 0;
ClansConfig.ROLE_OWNER = 1;

ClansConfig.HELP_READY = 0;
ClansConfig.HELP_RECEIVED = 1;

ClansConfig.GIFT_READY = 0;
ClansConfig.GIFT_RECEIVED = 1;

ClansConfig.MESSAGE_FLOOD_TIMEOUT = cleverapps.parseInterval("1 minute");
ClansConfig.MESSAGE_HELP_TIMEOUT = cleverapps.parseInterval(cleverapps.config.debugMode ? "5 minutes" : "24 hours");
ClansConfig.MESSAGE_HELP_LIFETIME = cleverapps.parseInterval("24 hours");
ClansConfig.MESSAGE_GIFT_LIFETIME = cleverapps.parseInterval("48 hours");

ClansConfig.MESSAGE_TYPE_ENERGY = 0;
ClansConfig.MESSAGE_TYPE_GIFT = 1;
ClansConfig.MESSAGE_TYPE_PHRASE_1 = 10;
ClansConfig.MESSAGE_TYPE_PHRASE_2 = 11;
ClansConfig.MESSAGE_TYPE_PHRASE_3 = 12;
ClansConfig.MESSAGE_TYPE_PHRASE_4 = 13;
ClansConfig.MESSAGE_TYPE_PHRASE_5 = 14;
ClansConfig.MESSAGE_TYPE_PHRASE_6 = 15;

ClansConfig.MESSAGE_HELP_REQUEST_TYPES = [
    ClansConfig.MESSAGE_TYPE_ENERGY
];

ClansConfig.MESSAGE_GIFT_TYPES = [
    ClansConfig.MESSAGE_TYPE_GIFT
];

ClansConfig.MESSAGES = {};

ClansConfig.MESSAGES[ClansConfig.MESSAGE_TYPE_ENERGY] = {
    help: {
        energy: 5,
        times: 6
    }
};

ClansConfig.MESSAGES[ClansConfig.MESSAGE_TYPE_GIFT] = {
    sender: "ClanMessage.youEarnGift",
    receiver: "ClanMessage.earnedGift"
};

ClansConfig.MESSAGES[ClansConfig.MESSAGE_TYPE_PHRASE_1] = {
    phrase: "ClanMessage.phrase_1"
};

ClansConfig.MESSAGES[ClansConfig.MESSAGE_TYPE_PHRASE_2] = {
    phrase: "ClanMessage.phrase_2"
};

ClansConfig.MESSAGES[ClansConfig.MESSAGE_TYPE_PHRASE_3] = {
    phrase: "ClanMessage.phrase_3"
};

ClansConfig.MESSAGES[ClansConfig.MESSAGE_TYPE_PHRASE_4] = {
    phrase: "ClanMessage.phrase_4"
};

ClansConfig.MESSAGES[ClansConfig.MESSAGE_TYPE_PHRASE_5] = {
    phrase: "ClanMessage.phrase_5"
};

ClansConfig.MESSAGES[ClansConfig.MESSAGE_TYPE_PHRASE_6] = {
    phrase: "ClanMessage.phrase_6"
};

if (typeof cc === "undefined") {
    module.exports = ClansConfig;
}
