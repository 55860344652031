/**
 * Created by olga on 11.01.2023
 */

var MagicplantMine = {
    prize: function (level, unit) {
        return [{
            amount: 3,
            probabilities: [0.5, 0.5],
            units: [
                { code: unit.code, stage: 1 },
                { code: unit.code, stage: 0 }
            ]
        }];
    }
};