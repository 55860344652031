/**
 * Created by olga on 26.10.2022
 */

var ProducerHintContent = function (options) {
    this.unit = options.unit;
    this.producer = options.unit.getData().producer;
    this.producerSource = undefined;

    this.doNotBlock = {
        x: this.unit.dragX,
        y: this.unit.dragY
    };
};

ProducerHintContent.prototype.hasSameContent = function (options) {
    return Unit.Equals(this.unit, options.unit);
};

ProducerHintContent.isApplicable = function (options) {
    return options.wrongMerged && options.unit && options.unit.getMergeUnit() && options.unit.getData().producer && !Game.currentGame.tutorial.isActive();
};

ProducerHintContent.prototype.getMessages = function () {
    var nextUnit = this.unit.getMergeUnit();
    var messages = [
        { text: Messages.get("CenterHint.merge.beginsProduced") },
        { icon: UnitView.getUnitImage(this.producer, { preferStatic: true }) },
        { text: Messages.get("CenterHint.merge.endsProduced") },
        { icon: UnitView.getUnitImage(nextUnit, { preferStatic: true }) }
    ];
    return messages;
};

ProducerHintContent.prototype.onComplete = function () {
    if (this.pointToProducerIsPending()) {
        return;
    }

    var source;
    Map2d.currentMap.listAvailableUnits().forEach(function (unit) {
        if (unit.findComponent(Improver) !== undefined) {
            source = unit;
        } else if (!source && unit.getData().unitsshop) {
            source = unit;
        }
    });

    if (!source) {
        return;
    }
    this.producerSource = source;
    ProducerHintContent.lastPointToProducer = {
        unit: this.unit,
        time: Date.now()

    };

    cleverapps.focusManager.display({
        focus: "PointToProducer",
        action: this.pointToProducer.bind(this)
    });
};

ProducerHintContent.prototype.pointToProducer = function (f) {
    Map2d.currentMap.getScrollView().runAction(
        new cc.Sequence(
            new cc.CellScrollAction(this.producerSource, {
                allowScrollWithFocus: true
            }).easing(cc.easeInOut(2)),
            new cc.CallFunc(
                function () {
                    if (this.producerSource.findComponent(Improver)) {
                        var draggable = this.unit.findComponent(Draggable);
                        draggable.onPointToTarget(this.producerSource);
                    } else {
                        FingerView.hintClick(this.producerSource, { runOnce: true });
                    }
                }.bind(this)
            ),
            new cc.DelayTime(1),
            new cc.CallFunc(f)
        )
    );
};

ProducerHintContent.prototype.pointToProducerIsPending = function () {
    var cooldown = cleverapps.parseInterval(ProducerHintContent.POINT_TO_PRODUCER_COOLDOWN);
    if (ProducerHintContent.lastPointToProducer && Date.now() < cooldown + ProducerHintContent.lastPointToProducer.time) {
        return true;
    }
    return false;
};

ProducerHintContent.POINT_TO_PRODUCER_COOLDOWN = "30 seconds";
