/**
 * Created by andrey on 09.02.2021.
 */

Map2dDecorators.prototype.getSaveInfo = function () {
    var decorators = [];

    for (var y in this.decorators) {
        for (var x in this.decorators[y]) {
            decorators.push.apply(decorators, this.decorators[y][x]);
        }
    }

    return decorators.sort(function (d1, d2) {
        return (d1.x - d2.x) || (d1.y - d2.y);
    }).map(function (decorator) {
        return decorator.getSaveInfo();
    });
};

Map2dDecorators.prototype.add = function (options) {
    var decorator = new Map2dDecorator(options);
    this.addDecorator(decorator.x, decorator.y, decorator);

    var view = decorator.createView(decorator);
    view.select();

    Map2d.currentMap.saveLevel();
};

Map2dDecorators.prototype.updateCell = function (decorator, x, y) {
    this.removeDecorator(decorator.x, decorator.y, decorator);

    decorator.x = x;
    decorator.y = y;

    this.addDecorator(decorator.x, decorator.y, decorator);
};

Map2dDecorators.prototype.remove = function (decorator) {
    this.removeDecorator(decorator.x, decorator.y, decorator);

    Map2d.currentMap.saveLevel();
};
