/**
 * Created by Denis "dalorveen" Kuzin on 02 november 2020
 */

var AdminQuickNavigationView = cc.Node.extend({
    ctor: function () {
        this._super();

        var styles = cleverapps.styles.AdminQuickNavigationView;
        this.setContentSize2(styles);
        this.setAnchorPoint(0.5, 0.5);

        var bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.regular_button);
        bg.setContentSize2(this.getContentSize());
        this.addChild(bg);
        bg.setPositionRound(this.width / 2, this.height / 2);

        this.createContent();
    },

    createContent: function () {
        var box = this.createEditBox();
        this.addChild(box);
        box.setPositionRound(this.width / 2, this.height / 2);
    },

    createEditBox: function () {
        var box = cleverapps.UI.createScale9Sprite(bundles.admin.frames.navigation_level_episode, cleverapps.UI.Scale9Rect.TwoPixelXY);
        box.setContentSize2(cleverapps.styles.AdminQuickNavigationView.editBox);

        var label = cleverapps.UI.generateOnlyText("Go to:", cleverapps.styles.FONTS.ADMIN_QUICK_NAVIGATION_TEXT);
        box.addChild(label);
        label.setPosition(box.width / 2, box.height / 2);
        box.label = label;

        cleverapps.UI.onClick(box, function () {
            var title = "Level no";
            if (cleverapps.config.subtype === "stacks") {
                title += " or hint";
            }
            var value = window.prompt(title, AdminQuickNavigation.levelNo);
            if (value) {
                cleverapps.focusManager.display({
                    focus: "QuickNavigation",
                    action: function (f) {
                        AdminQuickNavigation.handle(value, function (hasResults) {
                            if (!hasResults) {
                                f();
                                return;
                            }

                            if (AdminQuickNavigation.suggestions.length > 0) {
                                this.showSuggestions();
                                f();
                            } else {
                                AdminQuickNavigation.go(f);
                            }
                        }.bind(this));
                    }.bind(this)
                });
            }
        }.bind(this));
        cleverapps.UI.applyHover(box);

        return box;
    },

    showSuggestions: function () {
        if (!this.suggest) {
            this.createSuggestions();
        }

        var styles = cleverapps.styles.AdminQuickNavigationView.suggest;

        var suggestions = AdminQuickNavigation.suggestions.map(function (data) {
            return this.createEntry(data);
        }, this);

        var content = new cleverapps.Layout(suggestions, {
            direction: cleverapps.UI.VERTICAL,
            reversed: true,
            margin: styles.margin
        });

        this.suggest.scroll.setInnerContent(content);
        var height = content.height < styles.height ? content.height : styles.height;
        this.suggest.scroll.setContentSize(styles.width, height);

        this.suggest.setContentSize2(this.suggest.scroll.width, this.suggest.scroll.height + styles.paddingY);
        this.suggest.bg.setContentSize2(this.suggest.getContentSize());
        this.suggest.scroll.setPositionRound(this.suggest.width / 2, this.suggest.height / 2);

        this.suggest.setVisible(true);
    },

    createSuggestions: function () {
        this.suggest = new cc.Node();
        this.suggest.setAnchorPoint(0.5, 0.5);
        this.suggest.setAnchorPoint(0.5, 1);

        var scene = cleverapps.scenes.getRunningScene();
        scene.addChild(this.suggest);
        var pos = {
            x: this.x,
            y: this.y - this.height / 2
        };
        pos = scene.convertToNodeSpace(this.parent.convertToWorldSpace(pos));
        this.suggest.setPositionRound(pos);

        var bg = this.suggest.bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_bg);
        bg.setAnchorPoint(0, 0);
        this.suggest.addChild(bg, -1);

        var scroll = this.suggest.scroll = new cleverapps.UI.ScrollView();
        scroll.setBarPadding(cleverapps.styles.AdminQuickNavigationView.suggest.scroll);
        this.suggest.addChild(scroll, -1);

        this.suggest.setVisible(false);
        cc.eventManager.addListener({
            event: cc.EventListener.TOUCH_ONE_BY_ONE,
            swallowTouches: false,
            onTouchBegan: function (touch) {
                if (!this.suggest.isDisplayed()) {
                    return false;
                }

                if (!cc.rectContainsPoint(this.suggest.getBoundingBox(), scene.convertTouchToNodeSpace(touch))) {
                    this.suggest.setVisible(false);
                }
            }.bind(this)
        }, this.suggest);
    },

    createEntry: function (data) {
        var styles = cleverapps.styles.AdminQuickNavigationView.suggest.entry;

        var entry = new cc.Node();
        entry.setAnchorPoint(0.5, 0.5);

        var text = cleverapps.UI.generateTTFText(data.keywords, cleverapps.styles.FONTS.ADMIN_LOG_FONT);
        text.setAnchorPoint(0, 0.5);
        entry.addChild(text);

        var lvlText = cleverapps.UI.generateTTFText("Ep " + data.episodeNo + " lvl " + data.levelNo, cleverapps.styles.FONTS.ADMIN_LOG_FONT);
        lvlText.setOpacity(150);
        lvlText.setAnchorPoint(1, 0.5);
        entry.addChild(lvlText);

        text.setDimensions(styles.width - lvlText.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);

        entry.setContentSize2(styles.width, Math.max(text.height, styles.height));
        text.setPositionRound(0, entry.height / 2);
        lvlText.setPositionRound(entry.width, entry.height / 2);

        var line = cleverapps.UI.createScale9Sprite(bundles.admin.frames.log_entry_separator, cleverapps.UI.Scale9Rect.TwoPixelXY);
        line.setContentSize(line.width, entry.width);
        line.setRotation(90);
        entry.addChild(line);
        line.setPositionRound(entry.width / 2, 0);

        cleverapps.UI.applyHover(entry);
        cleverapps.UI.onClick(entry, function () {
            var move = function () {
                cleverapps.focusManager.display({
                    focus: "moveToFoundLvl",
                    action: function (f) {
                        AdminQuickNavigation.set(data.episodeNo, data.levelNo);
                        AdminQuickNavigation.go(f);
                    }
                });
            };

            if (data.lang && cleverapps.settings.language !== data.lang) {
                cleverapps.administrator.setLanguage(data.lang, move);
            } else {
                move();
            }
        });

        return entry;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_QUICK_NAVIGATION_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.ADMIN_TEXT_COLOR,
        name: "formal"
    }
});

cleverapps.styles.AdminQuickNavigationView = {
    width: 215,
    height: 70,

    suggest: {
        margin: 8,
        paddingY: 20,
        width: 220,
        height: 300,

        entry: {
            width: 185,
            height: 25
        },

        scroll: {
            cornerPadding: 10,
            sidePadding: 12
        }
    },

    editBox: {
        width: 180,
        height: 50
    }
};