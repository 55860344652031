/**
 * Created by spepa on 11.01.2024
 */

cleverapps.SkinManager.SKINS.china_expedition = {
    bundle: "expedition_china",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {
        workerCutTime: function () {
            return 500;
        },

        workerMiningAnimationStyles: function () {
            return cleverapps.styles.china.miningAnimation;
        },

        workerSound: function () {
            return bundles.merge_china.urls.worker_sound;
        },

        gameAudio: function () {
            return bundles.expedition_china.urls.china_music;
        },

        gameSceneBg: function () {
            return {
                horizontalPattern: bundles.expedition_china.urls.china_bg,
                parallax: true
            };
        },

        outOfBoundaryScale: function () {
            return 0;
        },

        unitIcon: function (unit) {
            return bundles.unit_icons_china.frames["small_icon_family_" + unit.code];
        },

        worker_json: function () {
            return bundles.merge_china.jsons.worker_json;
        },

        expedition_buildpass_icon_json: bundles.sidebar.jsons.china_expedition_buildpass_icon_json
    })
};