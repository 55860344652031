/**
 * Created by spepa on 09.09.2024
 */

var GameSnackLogic = function () {
    PlatformLogic.call(this);
};

GameSnackLogic.prototype = Object.create(PlatformLogic.prototype);
GameSnackLogic.prototype.constructor = GameSnackLogic;

GameSnackLogic.prototype.reportScore = function (score, callback) {
    if (cleverapps.config.type === "merge") {
        return;
    }
    GameSnacks.score.update(score);
    callback(cleverapps.CODE_SUCCEED);
};

GameSnackLogic.prototype.reportLevelUp = function (callback) {
    var value = levels.user.getVirtualLevel() - 1;
    GameSnacks.game.levelComplete(value);
    callback();
};

GameSnackLogic.prototype.reportGameOver = function (callback) {
    GameSnacks.game.gameOver();
    callback();
};