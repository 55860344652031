/**
 * Created by Denis Kuzin on 28 february 2022
 */

var Clan = function (data) {
    this.update(data);

    this.onLoaded = function () {};
};

Clan.prototype.getInfo = function () {
    return {
        id: this.id,
        name: this.name,
        emblem: this.emblem,
        membersCount: this.membersCount,
        rating: this.rating,
        requiredLevel: this.requiredLevel
    };
};

Clan.prototype.update = function (data) {
    data = data || {};

    this.id = data.clanId || data.id;
    this.name = data.name || "";
    this.emblem = data.emblem || 1;
    this.membersCount = data.membersCount || 0;
    this.rating = data.rating || 0;
    this.requiredLevel = data.requiredLevel || 0;

    this.players = (data.players || []).map(function (playerData) {
        return new ClanPlayer(playerData);
    });
};

Clan.prototype.load = function (onSuccess, onFailure, options) {
    onSuccess = onSuccess || function () {};

    this.loadRequest(function (data) {
        this.update(data);

        this.onLoaded();
        onSuccess();
    }.bind(this), onFailure, options);
};

Clan.prototype.loadRequest = function (onSuccess, onFailure, options) {
    cleverapps.RestClient.get("/clans/claninfo/" + this.id, {}, onSuccess, onFailure, options);
};

Clan.prototype.isUserClan = function () {
    return cleverapps.userClan.id !== undefined && cleverapps.userClan.id === this.id;
};

Clan.prototype.isFull = function () {
    return !(this.membersCount < ClansConfig.MAX_SIZE);
};

Clan.prototype.isAppropriateByLevel = function () {
    return this.requiredLevel <= cleverapps.gameLevel.getLevel();
};

Clan.prototype.isAppropriate = function () {
    return !this.isFull() && this.isAppropriateByLevel();
};

Clan.prototype.getOwner = function () {
    for (var i = 0; i < this.players.length; ++i) {
        var player = this.players[i];
        if (player.role === ClansConfig.ROLE_OWNER) {
            return player.id;
        }
    }
};
