/**
 * Created by r4zi4l on 14.12.2021
 */

var RudolfView = cc.Node.extend({
    ctor: function (rudolf, unitView) {
        this._super();

        this.rudolf = rudolf;
        this.unitView = unitView;
        this.unitAnimation = unitView.getAnimation();

        var styles = cleverapps.styles.RudolfView;

        this.progress = new ScaledProgressBar({
            type: ScaledProgressBar.Types.blue_small_with_frames
        });
        this.progress.hidden = false;
        this.progress.setAnchorPoint(0.5, 0.5);
        this.progress.setPositionRound(styles.progress);
        this.progress.setLength(styles.progress.width);
        this.progress.setPercentage(this.rudolf.getProgressPercentage());
        this.progress.setVisible(false);
        this.unitView.addChild(this.progress);

        cleverapps.bundleLoader.ensureSoundsLoaded([bundles.merge.urls.rudolf_start_effect, bundles.merge.urls.rudolf_complete_effect]);
        this.restoreState();
    },

    restoreState: function (animated) {
        if (!this.rudolf.isAnimating()) {
            if (this.rudolf.getTimeLeft()) {
                this.unitAnimation.setAnimation(0, "run2", true);
            } else {
                this.unitAnimation.setAnimation(0, "idle", true);
            }
        }

        var prizeView = this.unitView.getAdditionalView("prize");
        if (prizeView) {
            if (this.rudolf.isAnimating()) {
                prizeView.hide();
            } else {
                prizeView.animateAppear();
            }
        }

        if (!this.rudolf.getTimeLeft() || InfoView.IsDisplayedFor(this.rudolf.unit)) {
            this.removeTimer(animated);
        } else {
            this.createTimer(animated);
        }

        if (this.rudolf.getTimeLeft() || this.rudolf.unit.prizes || this.rudolf.progress === 0 || InfoView.IsDisplayedFor(this.rudolf.unit)) {
            this.hideProgress(animated);
        } else {
            this.showProgress(animated);

            this.progress.setPercentage(this.rudolf.getProgressPercentage(), {
                animated: animated
            });
        }
    },

    createTimer: function (animated) {
        if (this.timer) {
            return;
        }

        var styles = cleverapps.styles.RudolfView.timer;

        var timer = new cleverapps.CountDownView(new cleverapps.CountDown(this.rudolf.getTimeLeft()), {
            icon: bundles.timer_merge.frames.timer_png,
            font: cleverapps.styles.FONTS.EXCHANGER_TIMER_TEXT,
            bar: {
                type: ScaledProgressBar.Types.blue,
                width: styles.width,
                fullValue: Rudolf.EXCNAGE_DURATION
            }
        });
        timer.setPositionRound(styles);

        if (animated) {
            this.unitView.animateAppearAdditionalView("timer", timer);
        } else {
            this.unitView.createAdditionalView("timer", timer);
        }
    },

    removeTimer: function (animated) {
        this.unitView.removeAdditionalView("timer", !animated);
    },

    showProgress: function (animated) {
        if (!this.progress.hidden) {
            return;
        }
        this.progress.hidden = false;
        this.progress.stopAllActions();

        if (!animated) {
            this.progress.setVisible(true);
            return;
        }

        this.progress.runAction(
            new cc.Sequence(
                new cc.MoveBy(0, 0, 15),
                new cc.ScaleTo(0, 0.5),
                new cc.Show(),
                new cc.Spawn(
                    new cc.MoveBy(0.1, 0, -15),
                    new cc.ScaleTo(0.1, 1)
                )
            )
        );
    },

    hideProgress: function (animated) {
        if (this.progress.hidden) {
            return;
        }
        this.progress.hidden = true;
        this.progress.stopAllActions();

        if (!animated) {
            this.progress.setVisible(false);
            return;
        }

        this.progress.runAction(new cc.Sequence(
            new cc.ScaleTo(0.1, 0.3),
            new cc.Hide()
        ));
    },

    acceptDraggable: function (unit) {
        unit.getDnDAccepted(this.rudolf.unit);
    },

    startExchange: function () {
        this.rudolf.setAnimating(true);
        this.restoreState(true);

        this.unitAnimation.setAnimation(0, "swap", false);
        this.unitAnimation.setCompleteListenerOnce(function () {
            this.rudolf.setAnimating(false);
            this.restoreState();
        }.bind(this));
        this.unitAnimation.setSafeToRemove();

        cleverapps.audio.playSound(bundles.merge.urls.rudolf_start_effect);
    },

    completeExchange: function () {
        this.rudolf.setAnimating(true);
        this.restoreState(true);

        this.unitAnimation.setAnimation(0, "swap2", false);
        this.unitAnimation.setCompleteListenerOnce(function () {
            this.rudolf.setAnimating(false);
            this.restoreState(true);
        }.bind(this));
        this.unitAnimation.setSafeToRemove();

        cleverapps.audio.playSound(bundles.merge.urls.rudolf_complete_effect);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    EXCHANGER_TIMER_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.RudolfView = {
    timer: {
        x: { align: "center" },
        y: { align: "bottom" },
        width: 140
    },

    progress: {
        width: 84,
        x: { align: "center" },
        y: { align: "bottom", dy: -5 }
    }
};
