/**
 * Created by olga on 27.09.2024
 */

var FixableView = cc.Node.extend({
    ctor: function (component, unitView) {
        this._super();

        this.unitView = unitView;
    },

    afterUpgradeStage: function (f, oldUnitImage) {
        if (this.unitView.unit.findComponent(Switchable)) {
            oldUnitImage.removeFromParent();
            f();
            return;
        }

        if (this.unitView.sprite && this.unitView.sprite.hasAnimation("switch0") && this.unitView.sprite.hasAnimation("choice0")) {
            oldUnitImage.removeFromParent();
            this.unitView.sprite.setAnimationAndIdleAfter("switch0", "choice0");
            this.unitView.sprite.setCompleteListenerOnce(f);
        } else {
            this.unitView.setVisible(false);
            var duration = 1;
            AnimationsLibrary.upgrade(oldUnitImage, this.unitView, {
                duration: duration,
                cloud: true,
                parent: Map2d.currentMap.getMapView().animations,
                callback: f
            });
        }
    }
});