/**
 * Created by evgenijsenkevic on 11.10.2024
 */

var HomeTask = function (unit, name) {
    UnitComponent.call(this, unit);
    this.name = name;
};

HomeTask.prototype = Object.create(UnitComponent.prototype);
HomeTask.prototype.constructor = HomeTask;

HomeTask.prototype.handleClick = function () {
    var currentFurniture = cleverapps.meta.getMainObject().tasks.actives.find(function (furniture) {
        return furniture.name === this.name;
    }.bind(this));

    if (currentFurniture) {
        cleverapps.focusManager.display({
            focus: "homeTasksWindows",
            action: function (f) {
                new HomeTasksWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
    }
};