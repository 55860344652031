/**
 * Created by Andrey Popov on 27.02.2024
 */

var SuppliesSaleLogic = function (offer) {
    this.offer = offer;
    this.reward = Offers[offer.type].reward || RewardsConfig.Supplies.rewards;

    this.onProductBought = function () {};
    this.onWindowShowUpFinished = function () {};
};

SuppliesSaleLogic.prototype.nextStage = function () {
    this.offer.lastRewardTime = Date.now();
    this.offer.incStage();

    var icon = cleverapps.sideBar.findOfferIcon(this.offer.type);
    if (icon) {
        icon.resetState();
    }

    this.checkIfComplete();
};

SuppliesSaleLogic.prototype.checkIfComplete = function () {
    if (this.getStage() >= this.reward.length && (this.getLastRewardTime() + cleverapps.parseInterval(SuppliesSaleLogic.NEXT_STAGE_INTERVAL)) < Date.now()) {
        this.offer.complete();
    }
};

SuppliesSaleLogic.prototype.getStage = function () {
    return this.offer.stage || 0;
};

SuppliesSaleLogic.prototype.getLastRewardTime = function () {
    return this.offer.lastRewardTime || 0;
};

SuppliesSaleLogic.prototype.isReady = function () {
    return this.getStage() > 0 && this.getStage() < this.reward.length && (this.getLastRewardTime() + cleverapps.parseInterval(SuppliesSaleLogic.NEXT_STAGE_INTERVAL)) < Date.now();
};

SuppliesSaleLogic.prototype.getTimeLeft = function () {
    var leftTime = this.getLastRewardTime() + cleverapps.parseInterval(SuppliesSaleLogic.NEXT_STAGE_INTERVAL) - Date.now();

    if (this.getStage() === 0 || this.getStage() >= this.reward.length || leftTime < 0) {
        return 0;
    }
    return leftTime;
};

SuppliesSaleLogic.prototype.getRewards = function () {
    return this.reward[this.getStage()];
};

SuppliesSaleLogic.prototype.canBuy = function () {
    return this.getStage() === 0;
};

SuppliesSaleLogic.prototype.getProduct = function () {
    return Product.CreateByHardPrice(RewardsConfig.Supplies.hardPrice, {
        type: "supplies_sale",
        offer: this.offer.type
    });
};

SuppliesSaleLogic.prototype.isIconAvailable = function () {
    return true;
};

SuppliesSaleLogic.prototype.hasIconAttention = function () {
    return this.isReady();
};

SuppliesSaleLogic.prototype.calcOldPrice = function (product) {
    if (product.currency === "hard") {
        return "" + 3.6 * product.price;
    }

    var oldPrice = undefined;
    if (product.textPrice) {
        var priceParts = product.textPrice.split(" ");
        if (priceParts.length === 2) {
            priceParts.forEach(function (part, index) {
                var float = Number.parseFloat(part);
                if (!isNaN(float)) {
                    var delta = product.textPrice.indexOf(".") === -1 ? 0 : 0.01;
                    oldPrice = "" + (Math.round(float * 3.6) - delta);
                    if (index === 0) {
                        oldPrice += (" " + priceParts[1]);
                    } else {
                        oldPrice = priceParts[0] + " " + oldPrice;
                    }
                }
            });
        }
    }

    if (!oldPrice) {
        oldPrice = "$$ " + product.oldPrice;
    }

    return oldPrice;
};

SuppliesSaleLogic.prototype.buyProduct = function () {
    var product = this.getProduct();
    product.buy(this.processProductBought.bind(this), { noRewardWindow: true });
};

SuppliesSaleLogic.prototype.processProductBought = function (success) {
    if (success) {
        this.onProductBought();
    }
};

SuppliesSaleLogic.RestoreExpeditionId = function (context) {
    return cleverapps.offerManager.getOfferExpedition(context.offer);
};

SuppliesSaleLogic.RestoreActions = function (context, consume) {
    var offer = cleverapps.offerManager.findOffer({ type: context.offer });
    if (!offer || offer.logic.getStage() > 0) {
        return;
    }

    return [
        function (f) {
            offer.logic.onWindowShowUpFinished = cleverapps.once(f);
            new SuppliesWindow(offer);
        },

        function (f) {
            consume();
            offer.logic.processProductBought(true);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    ];
};

SuppliesSaleLogic.NEXT_STAGE_INTERVAL = "1 day";

if (cleverapps.config.debugMode) {
    SuppliesSaleLogic.NEXT_STAGE_INTERVAL = "5 seconds";
}

Product.RESTORE_LOGIC["supplies_sale"] = SuppliesSaleLogic;