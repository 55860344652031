/**
 * Created by andrey on 02.07.2021.
 */

var UnitAdditionalView = cc.Node.extend({
    ctor: function (unitView, view, type) {
        this._super();

        this.unitView = unitView;
        this.view = view;
        this.type = type;

        this.setBasePosition();

        this.view.runAnimation && this.view.runAnimation();
    },

    setBasePosition: function () {
        this.setPositionRound(this.view.alignment || this.view.getPosition());

        var styles = cleverapps.styles.UnitAdditionalView;
        if (cleverapps.config.name === "wondermerge" && this.unitView.unit.findComponent(MakesOrder) && this.view instanceof PrizeView) {
            this.x += styles.makesorder.x;
            this.y += styles.makesorder.y;
        }

        delete this.view.alignment;
        this.view.setPositionRound(0, 0);
        this.addChild(this.view);

        this.unitView.addChild(this);
        delete this.alignment;

        this.basePosition = this.getPosition();
    },

    animateAppear: function () {
        if (cleverapps.silentIntro) {
            return;
        }

        this.stopAllActions();

        var actions = [
            new cc.Show(),
            new cc.ScaleTo(0.2, 1, 1).easing(cc.easeIn(1))
        ];

        if (this.view.runAnimation) {
            actions.push(new cc.CallFunc(function () {
                this.view.setPositionRound(0, 0);
                this.view.runAnimation();
            }.bind(this)));
        }

        this.setVisible(false);
        this.setScale(0.5);
        this.runAction(new cc.Sequence(actions));
    },

    hide: function () {
        this.stopAllActions();
        this.setVisible(false);
    },

    animateHide: function (callback) {
        this.stopAllActions();

        var actions = [
            new cc.ScaleTo(0.1, 0.5, 0.5).easing(cc.easeIn(1)),
            new cc.Hide()
        ];
        if (callback) {
            actions.push(new cc.CallFunc(callback));
        }
        this.runAction(new cc.Sequence(actions));
    },

    remove: function (silent) {
        if (!silent) {
            this.animateHide(function () {
                this.removeFromParent();
            }.bind(this));
        } else {
            this.removeFromParent();
        }
        if (this.view.beforeRemove) {
            this.view.beforeRemove();
        }
    }
});

cleverapps.styles.UnitAdditionalView = {
    makesorder: {
        x: -10,
        y: 8
    }
};