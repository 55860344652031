/**
 * Created by vtbelo on 17/03/21
 */

var PushingComponent = function () {
    this.collections = {};
};

PushingComponent.prototype.destructor = function () {
    this.stop();
    for (var i in this.collections) {
        this.collections[i].destructor();
    }
    this.collections = {};
};

PushingComponent.prototype.stop = function () {
    if (this.pushingInterval) {
        cleverapps.timeouts.clearInterval(this.pushingInterval);
        this.pushingInterval = undefined;
    }
};

PushingComponent.prototype.updatePushing = function () {
    var ct = Date.now();
    var dt = ct - this.lastUpdateTime;
    this.lastUpdateTime = ct;
    for (var i in this.collections) {
        this.collections[i].pushing(dt / 1000);
    }
};

PushingComponent.prototype.run = function () {
    this.stop();
    this.lastUpdateTime = Date.now();
    this.pushingInterval = cleverapps.timeouts.setInterval(this.updatePushing.bind(this), 15);
};

PushingComponent.prototype.remove = function (node, collectionName) {
    if (this.collections[collectionName]) {
        this.collections[collectionName].remove(node);
        node.pausePushing = undefined;
        node.resumePushing = undefined;
        node.setPushingDirty = undefined;

        if (this.collections[collectionName].isEmpty()) {
            delete this.collections[collectionName];

            if (Object.keys(this.collections).length === 0) {
                this.stop();
            }
        }
    }
};

PushingComponent.prototype.add = function (node, collectionName) {
    if (!this.collections[collectionName]) {
        this.collections[collectionName] = new PushingCollection(collectionName);
    }

    var collection = this.collections[collectionName];
    collection.add(node);
    node.pausePushing = function () {
        collection.pausePushing(node);
    };
    node.resumePushing = function () {
        collection.resumePushing(node);
    };
    node.setPushingDirty = function () {
        collection.setPushingDirty(node);
    };
    node.addCleaner(function () {
        this.remove(node, collectionName);
    }.bind(this));

    if (!this.pushingInterval) {
        this.run();
    }
};
