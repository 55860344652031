/**
 * Created by Ivan on 15.09.2023
 */

var ReplaceAllBooster = function (id) {
    Booster.call(this, id);
    this.disableForceOnInactive = true;
};

ReplaceAllBooster.prototype = Object.create(Booster.prototype);
ReplaceAllBooster.prototype.constructor = ReplaceAllBooster;

ReplaceAllBooster.prototype.isAdsAvailable = function () {
    if (cleverapps.flags.lowMonetization) {
        return cleverapps.adsLimits.state(AdsLimits.TYPES.BOOSTER_ADS) === AdsLimits.STATE_READY;
    }

    return BaseBooster.prototype.isAdsAvailable.call(this);
};

ReplaceAllBooster.prototype.onExecute = function () {
    var game = Game.currentGame;
    var pieces = game.pieces;
    game.counter.setTimeout(function () {}, 1000);
    game.applySelectionAlgorithm({
        findSolvable: true,
        findMaxExplode: true,
        proposedIndexes: false,
        callback: function (forms) {
            forms = forms.items.map(function (solvable) {
                return Forms.ALL_POSSIBLE_VARIANTS[solvable];
            });

            forms.forEach(function (form, index) {
                pieces.clearHint(index);
                pieces.replacePiece({
                    index: index,
                    form: form,
                    color: pieces.calcPieceColor()
                });
            });
        }
    });
};