/**
 * Created by mac on 9/30/20
 */

var AdminEpisodeView = cc.Node.extend({
    ctor: function (episode) {
        this.episode = episode;
        this.createButton();

        this._super();
        this.setContentSize2(this.button.getContentSize());
        this.setAnchorPoint(0.5, 0.5);

        this.button.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.button);

        this.createIndicator();

        this.updateCurrentIndicators();

        episode.on("select", this.select.bind(this), this);
        episode.on("unselect", this.unselect.bind(this), this);
        if (episode.selected) {
            this.select();
        }

        episode.on("update", this.onUpdateEpisode.bind(this), this);
        episode.on("updateCurrent", this.updateCurrentIndicators.bind(this), this);

        cleverapps.administrator.adminEpisodes.on("updateApproved", this.onUpdateEpisode.bind(this), this);

        this.onUpdateEpisode();
    },

    getTextVariant: function () {
        if (!this.episode.isDebug() && !this.episode.isApproved()) {
            return "strict_red";
        }

        if (this.episode.selected) {
            return "strict_black";
        }

        return "strict_blue";
    },

    createButton: function () {
        var styles = cleverapps.styles.AdminEpisodeView;
        this.button = new cleverapps.UI.Button({
            type: this.getButtonType(),
            text: this.getTitle(),
            textVariant: this.getTextVariant(),
            width: this.episode.isAddMoreEpisode() ? undefined : styles.width,
            height: this.episode.isAddMoreEpisode() ? undefined : styles.height,
            onClicked: function () {
                cleverapps.focusManager.display({
                    focus: "adminEpisodeClick",
                    action: this.episode.onClick.bind(this.episode)
                });
            }.bind(this),

            onMouseOver: function () {
                this.button.setScale((this.button.baseScale * 1.05) || 1.05);
                this.episode.hover();
            }.bind(this),

            onMouseOut: function () {
                this.button.setScale(this.button.baseScale === undefined ? 1 : this.button.baseScale);
                this.episode.unhover();
            }.bind(this)
        });
    },

    getTitle: function () {
        if (this.episode.isAddMoreEpisode()) {
            return "";
        }
        if (!this.episode.isNumeric()) {
            return this.episode.episodeNo + "";
        }
        return this.episode.episodeNo + ". " + (this.episode.start + 1) + "-" + (this.episode.end + 1);
    },

    createIndicator: function () {
        var indicator = this.indicator = new cc.Sprite(bundles.admin.frames.in_process);
        indicator.setVisible(this.episode.inProgress());
        indicator.setPositionRound(cleverapps.styles.AdminEpisodeView.indicator);
        this.button.addChild(indicator);
    },

    updateCurrentIndicators: function () {
        if (this.episode.isCurrent()) {
            if (!this.currentFlag) {
                this.addCurrentFlag();
            }
        } else if (this.currentFlag) {
            this.currentFlag.removeFromParent(true);
            delete this.currentFlag;
        }
    },

    addCurrentFlag: function () {
        this.currentFlag = new cc.Sprite(bundles.admin.frames.current_arrow);
        this.button.addChild(this.currentFlag);
        this.currentFlag.setPositionRound(cleverapps.styles.AdminEpisodeView.currentFlag);
        this.currentFlag.setVisible(this.episode.isCurrent());
    },

    updateAverageDifficulty: function () {
        if (this.avgDifficulty) {
            this.avgDifficulty.removeFromParent(true);
        }
        if (!this.episode.selected || this.episode.isDebug()) {
            return;
        }

        var styles = cleverapps.styles.AdminEpisodeView.avgDifficulty;

        var value = this.avgDifficulty = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.ADMIN_TEXT_SELECTED);
        this.button.addChild(value);

        var updateStr = function () {
            var data = Object.values(cleverapps.administrator.adminLevels.episodeStatistics.data);
            var sum = 0;
            var avg = 0;
            if (data.some(function (lvl) {
                return lvl.difficulty >= 95;
            })) {
                avg = 100;
            } else if (data.length) {
                data.forEach(function (lvl) {
                    sum += 1 / (1 - lvl.difficulty / 100);
                });
                avg = Math.round((1 - 1 / (sum / data.length)) * 100);
            }
            value.setString(avg);
            value.setFontSize(cleverapps.styles.FONTS.ADMIN_TEXT_SELECTED.size);
            value.fitTo(styles.width);
            value.setPositionRound(styles);
        };

        cleverapps.administrator.adminLevels.on("statisticsLoaded", updateStr, value);
        if (cleverapps.administrator.adminLevels.episodeStatistics.ready) {
            updateStr();
        }

        cleverapps.administrator.adminLevels.on("statisticsReset", function () {
            value.setString("");
        }, value);
    },

    onUpdateEpisode: function () {
        if (!this.episode.isAddMoreEpisode()) {
            this.button.setString(this.getTitle());
            this.button.setTextVariant(this.getTextVariant());

            this.indicator.setVisible(this.episode.inProgress());
            this.updateCurrentIndicators();
            this.updateBackgroundImage();
        }
    },

    select: function () {
        this.button.setTextVariant(!this.episode.isDebug() && !this.episode.isApproved() ? "strict_red" : "strict_black");
        this.updateBackgroundImage();

        this.showMenu();
    },

    unselect: function () {
        this.button.setTextVariant(this.getTextVariant());
        this.updateBackgroundImage();

        this.hideMenu();
    },

    getButtonType: function () {
        if (this.episode.isAddMoreEpisode()) {
            return cleverapps.styles.UI.Button.Images.editor_plus;
        }

        var button = bundles.admin.frames.regular_button;
        if (this.episode.selected) {
            button = bundles.admin.frames.selected_button;
        } else if (this.episode.isDebug()) {
            button = bundles.admin.frames.disabled_button;
        }
        return {
            button_png: button,
            button_on_png: bundles.admin.frames.regular_button_on
        };
    },

    updateBackgroundImage: function () {
        this.button.setType(this.getButtonType());

        this.updateAverageDifficulty();
    },

    showMenu: function () {
        if (this.menu) {
            return;
        }

        var styles = cleverapps.styles.AdminEpisodeView.menu;

        var buttons = AdminEpisodeView.BUTTONS.map(function (data) {
            return new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.editor,
                content: new cc.Sprite(data.icon),
                width: styles.width,
                height: styles.height,
                onClicked: data.action.bind(this),
                hint: data.hint
            });
        }.bind(this));

        this.menu = new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        this.addChild(this.menu);

        this.menu.setPositionRound(styles);
        cleverapps.UI.wrap(this);
    },

    hideMenu: function () {
        if (this.menu) {
            this.menu.removeFromParent();
            delete this.menu;
        }

        this.button.setPositionRound(this.button.x, this.button.height / 2);
        cleverapps.UI.wrap(this);
    }
});

AdminEpisodeView.BUTTONS = [{
    icon: bundles.admin.frames.clone_icon,
    hint: "Copy episode",
    action: function () {
        cleverapps.focusManager.display({
            focus: "CopyEpisode",
            action: function (f) {
                cleverapps.administrator.adminEpisodes.copy(this.episode.episodeNo, f);
            }.bind(this)
        });
    }
}, {
    icon: bundles.admin.frames.edit_icon,
    hint: "Edit episode",
    action: function () {
        console.log(this.episode);
        var episodeNo = this.episode.episodeNo;
        var callback = function (newEpisodeNo) {
            newEpisodeNo = cleverapps.castType(newEpisodeNo);
            cleverapps.focusManager.distract({
                focus: "EditEpisodeNameDistract",
                action: function (f) {
                    if (episodeNo === newEpisodeNo) {
                        f();
                        return;
                    }

                    cleverapps.administrator.adminEpisodes.rename(episodeNo, newEpisodeNo, f);
                }
            });
        };

        cleverapps.focusManager.display({
            focus: "EditEpisodeName",
            action: function (f) {
                if (connector.info.isNative && cc.sys.OS_ANDROID === connector.info.os) {
                    new PromptWindow({ callback: callback, text: episodeNo });
                    cleverapps.focusManager.onceNoWindowsListener = f;
                } else {
                    var newEpisodeNo = window.prompt(episodeNo + " new episode name", episodeNo);
                    if (newEpisodeNo !== null) {
                        callback(newEpisodeNo);
                    } else {
                        f();
                    }
                }
            }
        });
    }
}, {
    icon: bundles.admin.frames.delete_icon,
    hint: "Delete episode",
    action: function () {
        cleverapps.focusManager.display({
            focus: "deleteEpisode",
            action: function (f) {
                if (confirm("Delete episode " + this.episode.episodeNo + "?")) {
                    cleverapps.administrator.adminEpisodes.remove(this.episode.episodeNo, f);
                } else {
                    f();
                }
            }.bind(this)
        });
    }
}];

cleverapps.styles.AdminEpisodeView = {
    width: 215,
    height: 44,

    indicator: {
        x: { align: "right", dx: -5 },
        y: { align: "top", dy: -5 }
    },

    menu: {
        margin: 10,
        height: 45,
        width: 45,
        x: { align: "center" },
        y: { align: "bottom", dy: -56 }
    },

    currentFlag: {
        x: { align: "left", dx: 4 },
        y: { align: "center", dy: 0 }
    },

    avgDifficulty: {
        x: { align: "right", dx: -4 },
        y: { align: "top", dy: -2 },
        width: 16
    }
};
