/**
 * Created by Andrey Popov on 26.10.21
 */

var XmasSourceMine = {
    prize: function (level, unit) {
        var amounts = [[1], [1, 1], [1, 1, 1]];

        switch (unit.code) {
            case "xmsledsource":
                amounts = [[1], [1, 2], [1, 2, 2, cleverapps.Random.random(2, 3)]];
                break;

            case "xmcastlesource":
                amounts = [[2], [2, 3], [1, 2, 3, cleverapps.Random.random(3, 4)]];
                break;
        }

        if (level === unit.getData().mineable.length - 1) {
            return [{
                units: [{ code: unit.getResource() + "chest", stage: 0 }]
            }];
        }

        return [{
            amount: amounts[unit.stage][level],
            units: [{ code: unit.getResource(), stage: 0 }]
        }];
    }
};
