/**
 * Created by evgeny on 01.08.2024
 */

var HighLightView = function (highLight, unitView) {
    this.unitView = unitView;
    this.highLight = highLight;

    this.restoreState();
};

HighLightView.prototype.restoreState = function () {
    if (this.highLight.isHighlighted) {
        this.addHighlight();
    } else {
        this.removeHighlight();
    }
};

HighLightView.prototype.addHighlight = function () {
    if (this.highlight) {
        return;
    }

    var styles = cleverapps.styles.HighLightView;

    var animation = this.highlight = new cleverapps.Spine(bundles.highlight.jsons.highlight_particles_json);

    animation.setAnimation(0, "light", true);
    animation.setPosition(this.unitView.width / 2, this.unitView.height / 2);

    var scale = Math.max(Math.min(this.unitView.width / animation.width, this.unitView.height / animation.height, styles.scale.max), styles.scale.min);
    animation.setScale(scale);
    this.unitView.addChild(animation, styles.zOrder);
};

HighLightView.prototype.removeHighlight = function () {
    if (this.highlight) {
        this.highlight.removeFromParent();
        delete this.highlight;
    }
};

cleverapps.styles.HighLightView = {
    zOrder: 1,
    scale: {
        max: 2,
        min: 1
    }
};