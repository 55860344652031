/**
 * Created by spepa on 07.06.2023
 */
var EditorHintView = cc.Scale9Sprite.extend({
    ctor: function () {
        this._super(bundles.tool.frames.tool_bg);
        this.setAnchorPoint(0.5, 0.5);
    },

    refresh: function (commands) {
        this.removeAllChildren();

        var styles = cleverapps.styles.EditorHintView;
        var textNode = this.createText(commands);
        this.setContentSize2(textNode.width + styles.bgPadding.x, textNode.height + styles.bgPadding.y);

        this.addChild(textNode);
        textNode.setPositionRound(this.width / 2, this.height / 2);
        this.setPositionRound(cleverapps.styles.EditorHintView.pos);

        this.setCascadeOpacityEnabledRecursively(true);
        this.showUp();
    },

    createText: function (commands) {
        var styles = cleverapps.styles.EditorHintView;

        var cmdNodes = commands.map(function (cmd) {
            var icon = new cc.Sprite(bundles.editor.frames["hint_" + cmd.description.replace(" ", "").toLowerCase()]);
            var key = cleverapps.UI.generateOnlyText(cmd.key, cleverapps.styles.FONTS.EDITOR_HINT);
            var msg = cleverapps.UI.generateOnlyText(cmd.description, cleverapps.styles.FONTS.EDITOR_HINT);
            return new cleverapps.Layout([icon, key, msg], {
                direction: cleverapps.UI.VERTICAL
            });
        });

        return new cleverapps.Layout(cmdNodes, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.itemsMargin
        });
    },

    showUp: function () {
        this.stopAllActions();
        this.runAction(new cc.FadeIn(0.3));
    },

    hide: function () {
        this.stopAllActions();
        this.runAction(new cc.FadeOut(0.3));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    EDITOR_HINT: {
        name: "nostroke",
        size: 25,
        color: cc.color(255, 255, 255)
    }
});

cleverapps.styles.EditorHintView = {
    itemsMargin: 40,

    bgPadding: {
        x: 30,
        y: 30
    },

    pos: {
        x: { align: "left", dx: 15 },
        y: { align: "top", dy: -5 }
    }
};