/**
 * Created by mac on 12/23/20
 */

var Grounded = function (unit) {
    UnitComponent.call(this, unit);
};

Grounded.prototype = Object.create(UnitComponent.prototype);
Grounded.prototype.constructor = Grounded;

Grounded.prototype.remove = function (silent) {
    if (this.view) {
        this.view.onRemove(silent);
    }
};

Grounded.prototype.save = function (data) {
    data.grounded = 1;
};

Grounded.prototype.onHighlight = function () {
    if (this.view) {
        this.view.highlight();
    }
};

Grounded.prototype.onUnhighlight = function () {
    if (this.view) {
        this.view.unhighlight();
    }
};
