/**
 * Created by slava on 4/3/20
 */

var MergeScene = NoneMainGameScene.extend({
    ctor: function (options) {
        options = options || {};

        this._super(options);
    },

    onSceneLoaded: function () {
        this._super();

        this.map = new Map2dView(this.game.map, this.mapPositionChanged.bind(this));
        this.addChild(this.map);

        this.game.onShowMergeBonus = this.createListener(this.showMergeBonus.bind(this));

        this.addTutorial();

        if (Game.currentGame.isMainGame()) {
            var travelBookHintView = new TravelBookHintView();
            this.addChild(travelBookHintView);
        }

        this.advice = new MergeAdviceView(this.game.advice, this);

        this.game.onSubstractEnergy = this.onSubEnergy.bind(this);
        cleverapps.gameLevel.on("levelUp", this.onLevelUp.bind(this), this);

        if (this.game.pixelsPlanner) {
            var pixelsPlannerView = new PixelsPlannerView(this.game.pixelsPlanner);
            this.map.getAnimationsLayer().addChild(pixelsPlannerView);
        }

        if (this.game.pocket) {
            this.game.pocket.onAddBubble = this.createPocketBubbleView.bind(this);
            this.game.pocket.bubbles.forEach(function (bubble) {
                this.createPocketBubbleView(bubble);
            }, this);
        }

        var orangery = Map2d.currentMap.orangery;
        if (orangery && !cleverapps.gameModes.noControls && !cleverapps.config.wysiwygMode) {
            var orangeryView = new OrangeryView(orangery);
            this.addChild(orangeryView);

            cleverapps.focusManager.registerControl(orangery.getControlName(), new HidingNode(orangeryView, cleverapps.UI.VERTICAL));
        }

        if (cleverapps.gameModes.axemerge) {
            // as after education stage
            var fogBlocks = this.game.map.fogs.blocks;
            if (fogBlocks.fog0) {
                fogBlocks.fog0.open();
            }
            if (fogBlocks.fog1) {
                fogBlocks.fog1.open();
            }
        }

        if (cleverapps.git) {
            cleverapps.git.changeFolders([GitManager.FOLDERS.UNIT_ANCHORS]);
        }

        var roomToneEffect = cleverapps.skins.getSlot("roomToneEffect");
        if (roomToneEffect) {
            this.runAction(new cc.RepeatForever(new cc.Sequence(
                new cc.DelayTime(5),
                new cc.PlaySound(roomToneEffect),
                new cc.DelayTime(5)
            )));
        }
    },

    getBackgroundStyles: function () {
        return cleverapps.skins.getSlot("gameSceneBg") || this.styling;
    },

    createPocketBubbleView: function (bubble) {
        var pocketBubbleView = new PocketBubbleView(bubble, this.map.getVisibleRect());
        this.map.getAnimationsLayer().addChild(pocketBubbleView);
    },

    showMergeBonus: function (units) {
        var styles = cleverapps.styles.MergeScene.mergeBonus;

        var position = cc.getCenter(units.map(function (unit) {
            return this.game.map.getMapView().alignInIsometricGrid(unit.x, unit.y);
        }, this));

        var animation = new cleverapps.Spine(bundles.merge_animations.jsons.bonus_shining_json);
        animation.setAnchorPoint(0.5, 0.5);
        animation.setPositionRound(position.x + styles.x, position.y + styles.y);
        animation.setAnimation(0, "animation", false);
        animation.setCompleteListenerRemove();
        this.map.getAnimationsLayer().addChild(animation);
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }
        this._super();
    },

    onSubEnergy: function (value, source) {
        var parent = this.map.getAnimationsLayer();
        var page = cleverapps.travelBook.getCurrentPage();

        if (page.isMain() && cleverapps.missionManager.findRunningMission(Mission.TYPE_LIVESFEAST)) {
            var reward = new Reward("mission", { amount: value, missionType: Mission.TYPE_LIVESFEAST });
            reward.collectRewardsAnimation(source, { delay: 300, withoutDelta: true });
            return;
        }

        var position = undefined;

        if (source instanceof Unit || source instanceof FogTile) {
            position = this.game.map.getMapView().getSourceCenterPosition(source);
        } else {
            return;
        }

        if ((source instanceof Unit) && source.getData().simpleInfo) {
            return;
        }

        this.runAction(AnimationsLibrary.animateDelta(-value + " **", {
            target: parent,
            x: position.x,
            y: position.y,
            icons: {
                "**": cleverapps.skins.getSlot("energy_icon") || bundles.reward_icons_merge.frames.energy_icon
            },
            iconScale: cleverapps.styles.Reward.jumpCollect.delta.scale,
            font: cleverapps.styles.FONTS.SCENE_ANIMATE_DELTA_TEXT
        }));
    },

    onLevelUp: function () {
        cleverapps.focusManager.display({
            focus: "onLevelUp",
            control: ["MenuBarGameLevelItem"],
            actions: [
                function (f) {
                    new LevelUpWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                },

                function (f) {
                    f();

                    Map2d.currentMap.fogs.calcFogStates();
                },

                function (f) {
                    var fakeUnit = Map2d.currentMap.fogs.findFakeUnit({ code: "warcraft" });

                    if (!cleverapps.forces.isShown(Forces.FOG_EXPLORE.id) && fakeUnit && cleverapps.user.level >= 17) {
                        Map2d.currentMap.getScrollView().runAction(new cc.Sequence(
                            new cc.ZoomAction(0.7, { zoom: 0.6 }),
                            new cc.CellScrollAction(fakeUnit, {
                                allowScrollWithFocus: true
                            }).easing(cc.easeInOut(2)),
                            new cc.DelayTime(0.4),
                            new cc.ZoomAction(0.8, {
                                zoom: 0.9,
                                zoomFocus: Map2d.currentMap.getMapView().getCell(fakeUnit.x, fakeUnit.y)
                            }),
                            new cc.CallFunc(function () {
                                setTimeout(function () {
                                    cleverapps.centerHint.createTextHint("Fog.explore");
                                }, 2000);
                            }),
                            new cc.CellScrollAction({
                                x: fakeUnit.x + 20,
                                y: fakeUnit.y + 20
                            }, {
                                duration: 6,
                                allowScrollWithFocus: true
                            }).easing(cc.easeInOut(1)),
                            new cc.CallFunc(f)
                        ));

                        cleverapps.forces.saveForce(Forces.FOG_EXPLORE.id);
                    } else {
                        f();
                    }
                },

                function (f) {
                    if (cleverapps.platformLogic.reportLevelUp) {
                        cleverapps.platformLogic.reportLevelUp(f);
                    } else {
                        f();
                    }
                }
            ]
        });
    },

    makeNodeVisible: function (target, options) {
        options = options || {};

        var currentZoom = Map2d.currentMap.getScrollZoom();

        // SCROLL SPEED options.speed
        this.map.runAction(new cc.Sequence(
            new cc.ScrollAction(target, {
                skipFocusReport: true,
                duration: 1,
                visibleBox: {
                    left: 0.74,
                    right: 0.74,
                    top: 1 / (currentZoom * 1.87),
                    bottom: 0.8
                }
            }),
            new cc.CallFunc(options.callback || function () {})
        ));
    },

    addTutorial: function () {
        var mergeTutorialView = new MergeTutorialView(this.game.tutorial);
        mergeTutorialView.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(mergeTutorialView);
    },

    completeAnimationOnResize: function () {
        this._super.apply(this);
        this.mapPositionChanged();
    },

    mapPositionChanged: function () {
        if (!this.map) {
            return;
        }

        var styles = this.getBackgroundStyles();

        if (styles.surfaceAnimation && !this.map.getCloudsAboveLayer().surfaceAnimation) {
            var surfaceAnimation = new cleverapps.Spine(styles.surfaceAnimation);
            surfaceAnimation.setPositionRound({ align: "center" }, { align: "center" });
            surfaceAnimation.setAnimation(0, "animation", true);
            this.map.getCloudsAboveLayer().surfaceAnimation = surfaceAnimation;
            this.map.getCloudsAboveLayer().addChild(surfaceAnimation);
        }

        var questsGroupIcon = cleverapps.sideBar.findIconByClassName(QuestsGroupIcon);
        if (questsGroupIcon) {
            questsGroupIcon.hideInfo();
        }

        if (!styles.parallax || !this.background) {
            return;
        }

        var safePadding = cleverapps.resolution.getSafePadding();
        var bgSize = cleverapps.resolution.getBgSize();

        var scale = this.map.zoom - this.map.zoomHandler.minZoom;
        var normalizedScale = Math.max(1.1 * bgSize.height / this.background.height, 1.25 + MergeScene.PARALLAX_FACTOR * scale)
            + 0.001 * (safePadding.bottom + safePadding.top);
        this.background.setScale(normalizedScale);

        var scroll = this.map.getCurrentScroll();
        var normalizedScroll = {
            x: 2 * (scroll.x - 0.5),
            y: 2 * (scroll.y - 0.5)
        };

        var x = this.width / 2 + MergeScene.PARALLAX_FACTOR * this.width * normalizedScroll.x * (1 + scale);
        var y = this.height / 2 + MergeScene.PARALLAX_FACTOR * this.height * normalizedScroll.y * (1 + scale);

        if (styles.parallax.hideHorizon) {
            x = this.width * (0.5 + 0.1 * normalizedScroll.x);
            y = this.height * (1.2 + 0.9 * normalizedScroll.y);
        }

        this.background.setPositionRound(x - safePadding.left, y - safePadding.bottom / 2);

        if (styles.topAnimation) {
            if (!this.background.topAnimation) {
                this.background.topAnimation = new cleverapps.Spine(styles.topAnimation);
                this.background.topAnimation.setAnimation(0, "animation", true);
                this.map.getAnimationsLayer().addChild(this.background.topAnimation);
            }

            this.background.topAnimation.setPositionRound(this.map.getCurrentPosition().x + safePadding.left, this.map.getCurrentPosition().y + safePadding.bottom);
            this.background.topAnimation.setScale(Math.max(bgSize.height / this.background.topAnimation.height, 1.05 / this.map.zoom));
        }
    },

    listBundles: function () {
        var result = NoneMainGameScene.prototype.listBundles.apply(this, arguments);

        result.push(Map2d.getTilesTexture(this.level.meta.tiles));
        result.push(Map2d.getUnitsTexture(this.level.meta.units));
        result.push("location_" + this.level.meta.location);

        if (cleverapps.gameModes.axemerge) {
            result.push("axemerge");
        }

        return result;
    },

    cachedBundles: function () {
        return ["levelupwindow", "startquest", "finishquest"];
    },

    lazyBundles: function () {
        var result = [
            "pixeloffer"
        ];

        if (bundles["pixels_" + this.level.meta.units]) {
            result.push("pixels_" + this.level.meta.units);
        } else {
            result.push("pixels_main");
        }

        result = result.concat(Families.listLazyBundles(Object.keys(this.level.families)));
        return result;
    },

    animateZoom: function (zoom, duration, f) {
        zoom = this.map.zoomHandler.normalizeZoom(zoom);

        this.map.runAction(new cc.Sequence(
            new cc.ZoomAction(duration, {
                zoom: zoom
            }),
            new cc.CallFunc(f)
        ));

        this.mapPositionChanged();
    }
});

var GameScene = MergeScene;
MergeScene.PARALLAX_FACTOR = 0.08;
SideBarView.ZORDER = -1;

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SCENE_ANIMATE_DELTA_TEXT: {
        size: 52,
        color: cleverapps.styles.FONTS.LIGHT_TEXT_COLOR
    }
});

cleverapps.styles.MergeScene = {
    mergeBonus: {
        x: 0,
        y: 30
    }
};
