/**
 * Created by Denis Kuzin on 29 july 2021
 */

var UnitHelper = {
    produceUnit: function (x, y, code, stage, silent, callback) {
        var unit = new Unit({
            code: code,
            stage: stage
        });

        unit.setPosition(x, y);
        Map2d.currentMap.add(Map2d.LAYER_UNITS, x, y, unit);
        Map2d.currentMap.onAddUnit(x, y, unit);
        callback && callback(unit);

        if (!silent) {
            var unitView = unit.onGetView();
            var baseScaleX = unitView.getScaleX();
            var baseScaleY = unitView.getScaleY();
            unitView.setScale(0);
            unitView.runAction(new cc.ScaleTo(0.5, baseScaleX, baseScaleY).easing(cc.easeBackOut()));
        }
    }
};