/**
 * Created by iamso on 13.10.2021
 */

var SeasonGuideOptions = {
    name: "SeasonGuideWindow",

    getGuideBundle: function () {
        return bundles.seasonguidewindow;
    },

    stagesContent: function () {
        var styles = cleverapps.styles.SeasonGuideWindow;

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.white_arrow_up, styles.arrow);
            this.addUnitIcon(container, "discountshop", 0, styles.shop);
            styles.chests.forEach(function (chestStyles, index) {
                this.addSprite(container, bundles.merge.frames.stand_ground, {
                    x: chestStyles.x,
                    y: chestStyles.y - styles.shop.dy
                });
                this.addUnitIcon(container, "seasonchest", index, chestStyles);
            }.bind(this));
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            var itemCode = Season.getCurrentItemCode();
            this.addSprite(container, this.bundle.frames.white_arrows, styles.arrows);
            styles.hats.units.forEach(function (hatStyles) {
                this.addSprite(container, bundles.merge.frames.stand_ground, {
                    x: hatStyles.x - styles.hats.dx,
                    y: hatStyles.y - styles.hats.dy
                });
                this.addUnitIcon(container, itemCode, 0, hatStyles);
            }.bind(this));
            this.addSprite(container, bundles.merge.frames.stand_ground, {
                x: styles.bigHat.x + styles.bigHat.dx,
                y: styles.bigHat.y - styles.bigHat.scale * styles.bigHat.dy,
                scale: styles.bigHat.scale
            });
            this.addUnitIcon(container, itemCode, 4, styles.bigHat);
        }.bind(this));

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.prizes_heap, styles.progress);
        }.bind(this));

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.SeasonGuideWindow = {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        shop: {
            x: 0,
            y: 100,
            scale: 1.4,
            dy: 20
        },

        arrow: {
            x: -20,
            y: -35,
            rotation: 90,
            scale: 1.3
        },

        chests: [
            {
                x: -87,
                y: -87
            },
            {
                x: 0,
                y: -140
            },
            {
                x: 87,
                y: -87
            }
        ]
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        arrows: {
            x: 0,
            y: 0
        },

        bigHat: {
            x: 0,
            y: -140,
            scale: 1.5,
            dx: 0,
            dy: 0,
            alignAnchorY: true
        },

        hats: {
            dx: -5,
            dy: 5,
            units: [
                {
                    x: 0,
                    y: 140,
                    scale: 0.9,
                    alignAnchorY: true
                },
                {
                    x: -87,
                    y: 87,
                    scale: 0.9,
                    alignAnchorY: true
                },
                {
                    x: 87,
                    y: 87,
                    scale: 0.9,
                    alignAnchorY: true
                }
            ]
        }
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center" }
        },

        progress: {
            x: 0,
            y: 0
        },

        finger: {
            x: 70,
            y: -155,
            scale: 0.65,
            rotation: -13
        }
    }
};
