/**
 * Created by mac on 1/20/21
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    COLOR_BONUS: new cc.Color(215, 169, 255, 255),
    SHOP_STROKE_COLOR: new cc.Color(0, 0, 0, 100),
    SHOP_SHADOW_COLOR: new cc.Color(0, 0, 0, 80),
    TILE_REWARDS_COLOR: new cc.Color(9, 89, 151, 255),
    LIGHT_TEXT_COLOR: new cc.Color(255, 255, 255, 255),
    YELLOW_TEXT_COLOR: new cc.Color(247, 230, 43, 255),
    LIGHT_TEXT_DISABLED_COLOR: new cc.Color(200, 200, 200, 255),

    GREEN: new cc.Color(123, 194, 63, 255),
    COLOR_RED: new cc.Color(255, 74, 74, 255),

    DARK_TEXT_COLOR: new cc.Color(121, 75, 57, 255),
    DARK_BLUE_TEXT_COLOR: new cc.Color(50, 25, 119, 255),

    "RARITY_COLOR_0": new cc.Color(255, 255, 255, 255),
    "RARITY_COLOR_1": new cc.Color(23, 184, 243, 255),
    "RARITY_COLOR_2": new cc.Color(164, 17, 218, 255),
    "RARITY_COLOR_3": new cc.Color(247, 231, 80, 255)
});

cleverapps.overrideStyles(cleverapps.styles.UI.Button, {
    keepRadius: true
});

(function () {
    [
        cleverapps.styles.UI.Button.Images.button_green,
        cleverapps.styles.UI.Button.Images.button_blue,
        cleverapps.styles.UI.Button.Images.button_red,
        cleverapps.styles.UI.Button.Images.button_purple,
        cleverapps.styles.UI.Button.Images.small_button_blue,
        cleverapps.styles.UI.Button.Images.small_button_green,
        cleverapps.styles.UI.Button.Images.field_button_green,
        cleverapps.styles.UI.Button.Images.field_button_blue
    ].forEach(function (obj) {
        cleverapps.overrideStyles(obj, {
            SKIP_RESOLUTION: {
                padding: {
                    left: 0.3,
                    right: 0.3,
                    top: 0.05,
                    bottom: 0.12
                }
            }
        });
    });
}());

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    LIGHT_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_STROKE_COLOR,
        size: 1
    },

    LIGHT_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_SHADOW_COLOR,
        direction: cc.size(0, -3),
        blur: 3
    },

    SHOP_STROKE: {
        color: cleverapps.styles.COLORS.SHOP_STROKE_COLOR,
        size: 1
    },

    SHOP_SHADOW: {
        color: cleverapps.styles.COLORS.SHOP_SHADOW_COLOR,
        direction: cc.size(0, -2),
        blur: 3
    },

    TITLE_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.TITLE_SHADOW,
        direction: cc.size(0, -2),
        blur: 1
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TIMER_TEXT: {
        name: "default",
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    DAILY_TASKS_TIMER_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    BOOSTER_BEFORE_AMOUNT_TEXT: {
        name: "default",
        size: 35
    },

    ROW_PLAYER_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    ROW_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    ROW_DATA_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    DAILY_TASK_REWARD_FONT: {
        size: 37,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    DAILY_TASK_FONT: {
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    DAILY_TASK_PROGRESS_FONT: {
        size: 32,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    DAILY_TASK_CTA: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    MENUBAR_TEXT: {
        name: "default",
        size: 52
    },

    TILE_SHOP_OFFER_TEXT: {
        name: "default",
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.SHOP_STROKE,
        size: 45
    },

    TILE_SHOP_COINS_OFFER: {
        name: "default",
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.SHOP_STROKE,
        size: 60
    },

    SHOP_WINDOW_SMALL_TILE_TITLE: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.SHOP_STROKE,
        shadow: cleverapps.styles.DECORATORS.SHOP_SHADOW
    },

    SHOP_LIVES_PRODUCT_TILE_DESCRIPTION_TEXT: {
        name: "nostroke",
        size: 26,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    TILE_REWARDS: {
        size: 40,
        name: "nostroke",
        color: cleverapps.styles.COLORS.TILE_REWARDS_COLOR
    },

    TILE_REWARDS_HEADER: {
        size: 30,
        name: "nostroke",
        color: cleverapps.styles.COLORS.TILE_REWARDS_COLOR
    },

    SHOP_WINDOW_TILE_TITLE: {
        name: "default",
        size: 55,
        color: cleverapps.styles.COLORS.WHITE
    },

    PRODUCT_TILE_LIMIT_TEXT: {
        name: "nostroke",
        size: 26,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    PACKWINDOW_TEXT: {
        name: "default",
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SHOP_WINDOW_LIVES_TEXT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        size: 40
    },

    TILE_SHOP_BADGE_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TILE_SHOP_BADGE_STROKE
    },

    PACK_TILE_REWARDS_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    WINDOW_TITLE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    NOBG_WINDOW_TITLE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    WINDOW_SMALL_TEXT: {
        name: "nostroke",
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    TOURNAMENT_PLAYER_TEXT: {
        name: "nostroke",
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    WINDOW_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    EXTRA_WINDOW_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    LOADING_ANIMATION_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    SMALL_TEXT_ERROR: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_RED
    },
    FBCONNECT_WINDOW_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    SETTINGS_WINDOW_ID_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    TERMS_WINDOW_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    LIGHT_TITLE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    LIGHT_TITLE_TEXT_DISABLED: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_DISABLED_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    UNIT_PROB: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    UNIT_AMOUNT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BUTTON_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    FORCE_MESSAGE_TEXT: {
        size: 39,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    LIGHT_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    DARK_TEXT: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    LOADING_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    REWARDS_LIST_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR
    },

    REWARD_WINDOW_REWARDS_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    SIDEBAR_ICON_TEXT: {
        name: "default",
        size: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    EXPEDITION_WINDOW_TEXT: {
        size: 32,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    CHAT_REWARD_COUNT_TEXT: {
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    RATE_WINDOW_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    GROWTH_FUND_LEVEL_TITLE: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_LEVEL_TITLE_REACHED: {
        size: 35,
        color: new cc.Color(145, 230, 54),
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_LEVEL_REWARD: {
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    GROWTH_FUND_LEVEL_REWARD_REACHED: {
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    GROWTH_FUND_LEVEL_VALUE: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_LEVEL_VALUE_REACHED: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_CTA2: {
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_CTA1: {
        color: cleverapps.styles.COLORS.YELLOW,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    GROWTH_FUND_RECEIVE_AMOUNT: {
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    FRIEND_REQUEST_TEXT: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    DIALOGUE_PERSON_TITLE_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    KNOCKOUT_WINDOW_COUNTDOWN_TEXT: {
        name: "nostroke",
        size: 70,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    COOLDOWNNODE_TIMER_TEXT: {
        name: "nostroke",
        size: 65,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    CLANS_ROW_LEADER_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SUBSCRIPTION_BUTTON_TITLE: {
        size: 22,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_DISABLED_COLOR,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    SUBSCRIPTION_MAIN_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    TERMS_LINK_TEXT: {
        size: 26,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    TERMS_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_DISABLED_COLOR,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    VIPREWARDEDWINDOW_DESCRIPTION_TEXT: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    VIPREWARDEDWINDOW_TIMER_TEXT: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    VIPREWARDEDWINDOW_NOTE_TEXT: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    LEVEL_TITLE_TEXT: {
        name: "nostroke",
        size: 65,
        color: new cc.Color(48, 83, 120, 255)
    },

    LEVEL_CURRENT_TITLE_TEXT: {
        size: 40
    },

    LEVEL_NOT_PASSED_TITLE_TEXT: {
        name: "nostroke",
        size: 40,
        color: new cc.Color(58, 58, 58, 255)
    },

    PROLONG_BUTTON_AMOUNT_TEXT: {
        size: 32,
        color: cleverapps.styles.COLORS.WHITE
    },

    SHOP_USER_GOLD_TEXT: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    TREASURE_STAGE_TEXT: {
        size: 25,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PRIZE_CALENDAR_TITLE: {
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    NOADS_BONUS_TEXT: {
        name: "nostroke"
    },

    PRIZE_BAR_TEXT: {
        size: 32
    },

    STAR_CHEST_PROGRESS_TEXT: {
        size: 35
    }
});

cleverapps.overrideStyles(cleverapps.styles.SideBarIconView, {
    lock: {
        x: { align: "right", dx: 4 },
        y: { align: "bottom", dy: 39 }
    },

    text: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -15 },
        width: 180,
        height: 74,
        offsetY: 2,
        factorX: 0.78,
        factorY: 0.775
    },

    mark: {
        x: { align: "right", dx: 7 },
        y: { align: "top", dy: -23 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTaskTimer, {
    offsetY: 3
});

cleverapps.overrideStyles(cleverapps.styles.Timer, {
    width: 270,
    height: 55,
    timer: {
        y: { align: "center", dy: 2 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTasksWindow, {
    person: {
        x: { align: "center", dx: -960 },
        y: { align: "bottom", dy: -20 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    title: {
        margin: {
            y: 0
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTasksTab, {
    scroll: {
        height: 680
    },

    padding: {
        x: 40,
        bottom: 40,
        top: 65
    },

    timer: {
        padding: { x: 0, y: 15 }
    },

    foreground: {
        width: 868,
        wide: {
            width: 1178
        },
        height: 45,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 15 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.NotificationView, {
    progress: {
        progressFrames: {
            0: bundles.progress_bar.frames.bar_red_dailytask,
            30: bundles.progress_bar.frames.bar_orange_dailytask,
            70: bundles.progress_bar.frames.bar_dailytask
        },
        height: 37
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTasksCompleteAllView, {
    prize: {
        y: { align: "bottom", dy: -19 }
    },
    prizePosition: {
        x: { align: "right", dx: 50 },
        y: { align: "center", dy: 13 }
    },
    bar: {
        height: 45,
        progressFrames: {
            0: bundles.progress_bar.frames.bar_red_dailytask,
            30: bundles.progress_bar.frames.bar_orange_dailytask,
            70: bundles.progress_bar.frames.bar_dailytask
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PrizeBarComponent, {
    prize: {
        flyY: 10,
        x: { align: "right", dx: 50 },
        y: { align: "center", dy: 8 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MenuBarItem, {
    bg: {
        width: 299,
        height: 91,
        minWidth: 247
    },

    icon: {
        x: 26,
        y: 0
    },

    timer: {
        icon: {
            y: { align: "center", dy: -2 }
        },

        text: {
            y: { align: "center", dy: -1 }
        }
    },

    plusButton: {
        offset: {
            x: -23,
            y: 8
        }
    }
});

if (cleverapps.styles.MenuBarGameLevelView) {
    cleverapps.overrideStyles(cleverapps.styles.MenuBarGameLevelView, {
        margin: -33
    });
}

cleverapps.overrideStyles(cleverapps.styles.SideBarSlot, {
    wideModePadding: [
        {
            top: 120,
            horizontal: 20
        },
        {
            top: 170,
            horizontal: 40
        },
        {
            top: 190,
            horizontal: 50
        }
    ],
    margin: 25
});

cleverapps.overrideStyles(cleverapps.styles.ToolbarView, {
    bg: {
        height: 170
    }
});

cleverapps.overrideStyles(cleverapps.styles.BandButton, {
    size: [{
        width: 85,
        height: 190
    }, {
        width: 85,
        height: 190
    }, {
        width: 120,
        height: 266
    }],

    position: [{
        x: { align: "right", dx: -40 },
        y: { align: "top", dy: 40 }
    }, {
        x: { align: "right", dx: -80 },
        y: { align: "top", dy: 40 }
    }, {
        x: { align: "right", dx: -80 },
        y: { align: "top", dy: 40 }
    }],

    icon: {
        x: { align: "center" },
        y: { align: "bottom", dy: 35 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.Row, {
    content: {
        y: { align: "center", dy: 3 }
    },

    data: {
        text: {
            width: 70
        },
        paddingX: 15
    }
});

if (cleverapps.config.type === "merge") {
    cleverapps.overrideStyles(cleverapps.styles.Row, {
        width: 760
    });
}

cleverapps.overrideStyles(cleverapps.styles.FriendRequestView, {
    width: 580,
    height: 230,

    processButton: {
        height: 70,
        x: 50
    },

    Avatar: {
        Margin: {
            x: 50
        }
    },

    Request: {
        width: 300
    },

    close: {
        x: 28,
        y: 12
    }
});

cleverapps.overrideStyles(cleverapps.styles.FriendRequestsWindow, {
    y: 150
});

cleverapps.overrideStyles(cleverapps.styles.LevelStartWindow, {
    padding: {
        top: 20,
        bottom: 5
    },

    lanternStart: {
        margin: 20,
        padding: {
            left: -30,
            right: -30,
            bottom: 5
        },

        coinBadge: {
            label: {
                anchor: { x: 0.5, y: 0.5 },
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 0 }
            },
            x: { align: "left", dx: 97 },
            y: { align: "top", dy: -88 }
        }
    }
});
if (cleverapps.config.type === "match3") {
    cleverapps.overrideStyles(cleverapps.styles.LevelStartWindow, {
        hardLevel: {
            x: { align: "left", dx: 30 },
            y: { align: "top" }
        }
    });
}

if (cleverapps.styles.HardLevelWindowIcon) {
    cleverapps.overrideStyles(cleverapps.styles.HardLevelWindowIcon, {
        rotation: 0
    });
}

if (cleverapps.styles.BoosterBeforeView) {
    cleverapps.overrideStyles(cleverapps.styles.BoosterBeforeView, {
        textAvailable: {
            y: { align: "bottom", dy: 9 },

            bg: {
                y: { align: "bottom", dy: -15 },
                height: 70
            }
        }
    });
}

if (cleverapps.styles.BoosterBeforeAmountView) {
    cleverapps.overrideStyles(cleverapps.styles.BoosterBeforeAmountView, {
        amount: {
            y: { align: "center", dy: 2 }
        }
    });
}

if (cleverapps.styles.BoostersBeforeComponent) {
    cleverapps.overrideStyles(cleverapps.styles.BoostersBeforeComponent, {
        contentMargin: 30
    });
}

cleverapps.overrideStyles(cleverapps.styles.SelectFriendWindow, {
    withFriends: {
        height: 730
    }
});

cleverapps.overrideStyles(cleverapps.styles.TacticalExtendWindow, {
    animation: {
        height: 400
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoseWindow, {
    livesLeftMargin: 0,
    animation: {
        width: 400,
        height: 400
    },
    content: {
        padding: {
            top: 20,
            bottom: 10,
            x: 30
        }
    },

    life: {
        width: 80,
        height: 60
    },
    livesLeftText: {
        width: 500
    }
});

cleverapps.overrideStyles(cleverapps.styles.RestartWindow, {
    button: {
        width: 220,
        height: 90
    }
});

cleverapps.overrideStyles(cleverapps.styles.ReloadWindow, {
    button: {
        height: 90
    }
});

if (cleverapps.styles.HeroWindow) {
    cleverapps.overrideStyles(cleverapps.styles.HeroWindow, {
        padding: {
            bottom: 70
        },

        helpBlock: {
            text: {
                width: 400
            }
        }
    });
}

if (cleverapps.styles.ExtraWindow) {
    cleverapps.overrideStyles(cleverapps.styles.ExtraWindow, {
        margin: 40,
        window: {
            padding: {
                left: 30,
                top: 80,
                right: 30,
                bottom: 80
            },
            CloseButton: {
                x: { align: "right", dx: 30 },
                y: { align: "top", dy: 30 }
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.DialogueView, {
    widthPadding: 50,
    offsetY: 10,

    positions: {
        bottom: {
            y: 30
        }
    },

    arrow: {
        y: { align: "top", dy: 36 }
    },

    text: {
        padding: {
            fromWallX: 50,
            x: 50,
            y: 50
        },
        verticalPadding: {
            fromWallX: 50,
            x: 50,
            y: 45
        }
    },

    title: {
        mobileY: { align: "top", anchor: false, dy: -10 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MinimalDialogue, {
    text: {
        arrow: {
            left: {
                x: { align: "left", dx: -21 },
                y: { align: "center", dy: 0 }
            },
            right: {
                x: { align: "right", dx: 23 },
                y: { align: "center", dy: 0 }
            }
        },

        verticalPadding: {
            top: 20,
            bottom: 18,
            left: 25,
            right: 25
        }
    }
});

if (cleverapps.styles.LanternWindow) {
    cleverapps.overrideStyles(cleverapps.styles.LanternWindow, {
        flashingArrows: true,
        timer: {
            y: { align: "top", dy: -15 }
        },
        bonuses: {
            margin: 10,
            images: {
                moves: {
                    scale: 1.0
                }
            },
            y: { align: "bottom", dy: 30 }
        },
        animation: {
            y: 80,
            scale: 0.8
        },
        text: {
            width: 750,
            height: 100
        }
    });
}

if (cleverapps.styles.ProlongationOfferView) {
    cleverapps.overrideStyles(cleverapps.styles.ProlongationOfferView, {
        label: {
            amount: {
                x: { align: "center", dx: -3 },
                y: { align: "center", dy: -15 }
            }
        },

        bonusLabel: {
            x: { align: "center", dx: 145 },
            y: { align: "center", dy: 73 }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.LeadersWindow, {
    arrows: {
        dec: {
            x: { align: "left", dx: -145 }
        },

        inc: {
            x: { align: "right", dx: 145 }
        }
    }
});

if (cleverapps.styles.BackgroundsWindow) {
    cleverapps.overrideStyles(cleverapps.styles.BackgroundsWindow, {
        padding: {
            right: -58,
            left: -60
        },
        present: {
            button: {
                x: { align: "center", dx: 10 }

            }
        },

        tape: {
            x: { align: "right", dx: 31 },
            withName: {
                x: { align: "right", dx: 31 }
            },
            text: {
                y: 65
            }
        },

        item: {
            bg: {
                padding: {
                    x: 10,
                    y: 12
                }
            }
        },

        lockWithName: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        },

        name: {
            x: { align: "center" },
            y: { align: "top", dy: -30 }
        }
    });
}

if (cleverapps.styles.OrdersWindow) {
    cleverapps.overrideStyles(cleverapps.styles.OrdersWindow, {
        Background: {
            image: bundles.orderswindow.frames.ow_window_background
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.DailyTaskView, {
    icon: {
        bg: {
            wide: {
                width: 150
            },

            width: 150,
            height: 150
        }
    },

    titleAndRewards: {
        width: 300
    },

    progressBar: {
        progressFrames: {
            0: bundles.progress_bar.frames.bar_red_dailytask,
            30: bundles.progress_bar.frames.bar_orange_dailytask,
            70: bundles.progress_bar.frames.bar_dailytask
        }
    },

    button: {
        width: 230
    },

    reward: {
        textMargin: 5
    }
});

cleverapps.overrideStyles(cleverapps.styles.BaseProductTile, {
    width: 460,
    badge: {
        x: { align: "right", dx: 39 },
        y: { align: "top", dy: 39 }
    },

    title: {
        x: 7,
        text: {
            x: { align: "center", dx: -10 },
            y: { align: "center", dy: 12 }
        }
    },

    description: {
        width: 480,
        height: 138,
        bg: {
            width: 480,
            height: 138,
            y: { align: "center", dy: -5 },
            x: { align: "center", dx: 7 }
        }
    },

    background: {
        adjustBackgroundCapInsets: true
    }
});

cleverapps.overrideStyles(cleverapps.styles.TileBadge, {
    x: { align: "center", dx: -3 },
    y: { align: "center", dy: 3 },
    height: 112
});

cleverapps.overrideStyles(cleverapps.styles.TileButton, {
    width: 230,
    height: 90
});

cleverapps.overrideStyles(cleverapps.styles.TileIcon, {
    height: undefined,
    wrap: {
        dx: 15,
        dy: 20
    },

    "subsMonth": {
        wrap: { dy: -15 }
    },

    "growthFund": {
        wrap: { dy: -40 }
    }
});

if (cleverapps.styles.StarsProgressBar) {
    cleverapps.overrideStyles(cleverapps.styles.StarsProgressBar, {
        bar: {
            offsetX: 6,
            height: 82,
            dy: 5
        },

        barAnimation: {
            offsetX: -15
        }
    });
}

if (cleverapps.styles.LivesProductTile) {
    cleverapps.overrideStyles(cleverapps.styles.LivesProductTile, {
        width: 460,
        height: 747,

        content: {
            y: { align: "bottom", dy: 45 },
            margin: 10
        },

        limit: {
            y: -10
        },

        description: {
            y: 10
        },

        badge: {
            x: { align: "right", dx: 40 },
            y: { align: "top", dy: 40 },
            scale: 1
        }
    });
}

if (cleverapps.styles.StarChestWindow) {
    cleverapps.overrideStyles(cleverapps.styles.StarChestWindow, {
        bar: {
            type: ScaledProgressBar.Types.green
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.SubscriptionButtons, {
    buttonWidth: 410,
    buttonHeight: 120
});

cleverapps.overrideStyles(cleverapps.styles.SubscriptionTile, {
    width: 460,
    height: 747,

    content: {
        margin: 0,
        y: { align: "bottom", dy: 75 }
    },

    description: {
        height: 154,
        width: 380,

        y: 8,

        rewardsMargin: {
            y: -2
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GrowthFundTile, {
    description: {
        height: 154,
        y: 8
    },
    content: {
        margin: 0,
        y: { align: "bottom", dy: 75 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackProductTile, {
    width: 460,
    height: 747,

    limit: {
        y: -20
    },

    content: {
        y: { align: "bottom", dy: 75 },
        margin: 0
    },

    description: {
        width: 380,
        height: 185,
        y: 20,
        paddingY: -30,
        bg: {
            width: 380,
            height: 170
        }
    },

    title: {
        y: 20,

        text: {
            y: { align: "center", dy: 6 }
        },

        size: {
            width: 480
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CoinsProductTile, {
    width: 460,

    title: {
        height: 138,
        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 8 }
        }
    },

    description: undefined,

    content: {
        x: { align: "center", dx: 2 },
        y: { align: "bottom", dy: 75 },
        margin: 18
    }
});

cleverapps.overrideStyles(cleverapps.styles.OfferProductTile, {
    icon: {
        offsetY: 10
    }
});

cleverapps.overrideStyles(cleverapps.styles.TileRewardsBlock, {
    rowMargin: 10,
    colMargin: 10
});

cleverapps.overrideStyles(cleverapps.styles.ShopWindow, {
    padding: { x: 0, y: 30 }
});

cleverapps.overrideStyles(cleverapps.styles.CloseButton, {
    x: { align: "right", dx: 30 },
    y: { align: "top", dy: 30 }
});

cleverapps.overrideStyles(cleverapps.styles.Window, {
    padding: {
        top: 70,
        bottom: 70
    },
    Background: {
        padding: {
            x: 30,
            y: 20
        }
    },
    HelpButton: {
        lovesTitle: true
    }
});

cleverapps.overrideStyles(cleverapps.styles.Tabs, {
    buttons: {
        width: 135,
        height: 240
    },

    attention: {
        x: { align: "left", dx: -20 },
        y: { align: "top", dy: -20 }
    },

    margin: 6
});

cleverapps.overrideStyles(cleverapps.styles.RateWindow, {
    width: 800,
    height: 400,

    text: {
        height: 400
    }
});

cleverapps.overrideStyles(cleverapps.styles.WindowTitle.Types, {
    bg_window: {
        background: {
            height: 94
        },
        textPosition: {
            y: { align: "center", dy: 6 }
        },
        position: {
            y: { align: "top", anchor: false, dy: 4 }
        }
    },
    nobg_window: {
        padding: {
            x: 145
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoaderScene, {
    progressBar: {
        y: 120,
        scale9: {
            wideWidth: 800,
            width: 300
        }
    },
    message: {
        y: 180
    }
});

if (cleverapps.styles.ExtendUnlimitedLivesWindow) {
    cleverapps.overrideStyles(cleverapps.styles.ExtendUnlimitedLivesWindow, {
        animation: {
            height: 200
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.SoftCurrencyTile, {
    title: {
        height: 138,
        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 8 }
        }
    },

    content: {
        y: { align: "bottom", dy: 70 },
        margin: 25
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyCupView, {
    title: {
        text: {
            width: 430,

            y: { align: "center", dy: -18 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.FingerView, {
    anchorX: 0.1,
    anchorY: 0.9
});

if (cleverapps.styles.LocationView) {
    cleverapps.overrideStyles(cleverapps.styles.LocationView, {
        coming_soon: {
            activities: {
                button: {
                    height: 80
                }
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.PassLastChanceWindow, {
    rewards: {
        text: {
            x: { align: "center", dx: -37 },
            y: { align: "center", dy: 36 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MissionWindow, {
    padding: {
        x: -30,
        y: -15
    }
});

cleverapps.overrideStyles(cleverapps.styles.ToolbarItemView, {
    mark: {
        baseScale: 0.72
    }
});

cleverapps.overrideStyles(cleverapps.styles.SettingsWindow, {
    window: {
        padding: {
            left: 70,
            right: 70,
            top: 70,
            bottom: 60
        }
    },

    button: {
        height: 110
    },

    loginButton: {
        width: 563
    }
});

cleverapps.overrideStyles(cleverapps.styles.DeleteAccountWindow, {
    window: {
        padding: {
            left: 50,
            right: 50,
            top: 60,
            bottom: 70
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelView, {
    clickSize: {
        width: 120,
        height: 120
    },

    LevelId: {
        x: { dx: 0 },
        y: { dy: -10 },

        showTypes: [Level.TYPE_CURRENT, Level.TYPE_NOTPASSED]
    }
});

cleverapps.overrideStyles(cleverapps.styles.DialoguePersonTitleView, {
    text: {
        y: { align: "center", dy: 3 },
        padding: {
            x: 38,
            y: -20
        }
    }
});

if (cleverapps.styles.ChainSaleLot) {
    cleverapps.overrideStyles(cleverapps.styles.ChainSaleLot, {
        button: {
            SKIP_RESOLUTION: {
                padding: {
                    left: 0.3,
                    right: 0.3,
                    top: 0.05,
                    bottom: 0.12
                }
            }
        },

        arrow: {
            x: { align: "right", dx: 36 }
        }
    });
}

if (cleverapps.styles.TreasureSearchView) {
    cleverapps.overrideStyles(cleverapps.styles.TreasureSearchView, {
        size: [{
            width: 876, height: 1005
        }, {
            width: 876, height: 1005
        }, {
            width: 876, height: 1005
        }],

        goalBlock: {
            "g": {
                positions: [{
                    x: { align: "center", dx: 228 }, y: { align: "top", dy: -52 }
                }, {
                    x: { align: "center", dx: 228 }, y: { align: "top", dy: -52 }
                }, {
                    x: { align: "center", dx: 228 }, y: { align: "top", dy: -52 }
                }],
                scale: 1.0,
                delay: 3
            },
            "h": {
                positions: [{
                    x: { align: "center", dx: -178 }, y: { align: "top", dy: -73 }
                }, {
                    x: { align: "center", dx: -178 }, y: { align: "top", dy: -73 }
                }, {
                    x: { align: "center", dx: -178 }, y: { align: "top", dy: -73 }
                }],
                rotation: -90,
                scale: 0.83,
                delay: 3
            },
            "j": {
                positions: [{
                    x: { align: "center", dx: 93 }, y: { align: "top", dy: -71 }
                }, {
                    x: { align: "center", dx: 93 }, y: { align: "top", dy: -71 }
                }, {
                    x: { align: "center", dx: 93 }, y: { align: "top", dy: -71 }
                }],
                scale: 0.58,
                delay: 3
            },
            "k": {
                positions: [{
                    x: { align: "center", dx: -17 }, y: { align: "top", dy: -121 }
                }, {
                    x: { align: "center", dx: -17 }, y: { align: "top", dy: -121 }
                }, {
                    x: { align: "center", dx: -17 }, y: { align: "top", dy: -121 }
                }],
                scale: 0.65,
                zOrder: 2,
                delay: 3
            }
        },

        progress: {
            bg: {
                size: [{
                    width: 1026, height: 185
                }, {
                    width: 1026, height: 185
                }, {
                    width: 1026, height: 185
                }],

                positions: [{
                    x: { align: "center", dx: 0 }, y: { align: "top", dy: -255 }
                }, {
                    x: { align: "center", dx: 0 }, y: { align: "top", dy: -255 }
                }, {
                    x: { align: "center", dx: 0 }, y: { align: "top", dy: -255 }
                }]
            },

            currentStage: {
                width: 152,
                height: 195,
                dy: -18
            },

            chestBlock: {
                positions: [{
                    x: { align: "center", dx: 0 }, y: { align: "center", dy: 21 }
                }, {
                    x: { align: "center", dx: 0 }, y: { align: "center", dy: 21 }
                }, {
                    x: { align: "center", dx: 0 }, y: { align: "center", dy: 21 }
                }],

                text: {
                    width: 118,
                    y: { align: "center", dy: -75 }
                }
            }
        },

        miningTool: {
            bg: {
                width: 135,
                height: 220,
                positions: [{
                    x: { align: "right", dx: 135 }, y: { align: "center", dy: 0 }
                }, {
                    x: { align: "right", dx: 135 }, y: { align: "center", dy: 0 }
                }, {
                    x: { align: "right", dx: 135 }, y: { align: "center", dy: 0 }
                }]
            },

            icon: {
                x: { align: "center", dx: 0 },
                y: { align: "top", dy: -17 }
            },

            amountBg: {
                y: { align: "bottom", dy: 25 }
            }
        },

        ads: {
            positions: [{
                x: { align: "left", dx: -21 }, y: { align: "bottom", dy: -48 }
            }, {
                x: { align: "left", dx: -21 }, y: { align: "bottom", dy: -48 }
            }, {
                x: { align: "left", dx: -21 }, y: { align: "bottom", dy: -48 }
            }]
        },

        field: {
            border: {
                padding: {
                    x: 35,
                    y: 35
                }
            }
        },

        scroll: {
            positions: [{
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -430 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -430 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -430 }
            }]
        },

        shadow: {
            size: [{
                width: 998, height: 775
            }, {
                width: 998, height: 775
            }, {
                width: 998, height: 775
            }],

            positions: [{
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -313 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -313 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -313 }
            }]
        }
    });
}

if (cleverapps.styles.TreasureSearchWindow) {
    cleverapps.overrideStyles(cleverapps.styles.TreasureSearchWindow, {
        content: {
            y: { align: "top", dy: 47 }
        },

        timer: {
            positions: [{
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -160 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -160 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -160 }
            }]
        },

        winBg: {
            width: 998,
            height: 775,
            y: { align: "bottom", dy: -35 }
        }
    });
}

if (cleverapps.styles.StickersBookWindow) {
    cleverapps.overrideStyles(cleverapps.styles.StickersBookWindow, {
        size: [
            { width: 830, height: 1370 },
            { width: 1520, height: 950 },
            { width: 1520, height: 950 }
        ],

        fg: undefined,

        scroll: {
            size: [
                { width: 860, height: 1200 },
                { width: 1483, height: 900 },
                { width: 1483, height: 900 }
            ],
            position: [
                {
                    x: { align: "center" },
                    y: { align: "center", dy: -70 }
                },
                {
                    x: { align: "center", dx: 2 },
                    y: { align: "center", dy: -60 }
                },
                {
                    x: { align: "center", dx: 2 },
                    y: { align: "center", dy: -60 }
                }
            ],

            barPadding: {
                sidePadding: 10
            }
        },

        progressView: {
            width: 360,
            verticalWidth: 340,
            position: [
                { x: { align: "center", dx: 36 }, y: { align: "top", dy: -48 } },
                { x: { align: "center", dx: 50 }, y: { align: "top", dy: -54 } },
                { x: { align: "center", dx: 50 }, y: { align: "top", dy: -54 } }
            ],
            joker: {
                x: { align: "left", dx: -40 },
                y: { align: "center" }
            },
            text: {
                dy: 0
            }
        },

        collectionsAmount: {
            position: [
                { x: { align: "left", dx: 20 }, y: { align: "top", dy: -30 } },
                { x: { align: "left", dx: 35 }, y: { align: "top", dy: -40 } },
                { x: { align: "left", dx: 35 }, y: { align: "top", dy: -40 } }
            ],
            bg: {
                height: 67
            }
        },

        stickersAmount: {
            position: [
                { x: { align: "left", dx: 40 }, y: { align: "top", dy: -40 } },
                { x: { align: "left", dx: 280 }, y: { align: "top", dy: -40 } },
                { x: { align: "left", dx: 280 }, y: { align: "top", dy: -40 } }
            ],
            bg: {
                height: 67
            }
        },

        jokers: {
            position: [
                { x: { align: "right", dx: -20 }, y: { align: "top", dy: -28 } },
                { x: { align: "right", dx: -30 }, y: { align: "top", dy: -40 } },
                { x: { align: "right", dx: -30 }, y: { align: "top", dy: -40 } }
            ]
        },

        jokerAnimation: {
            duration: 1.2
        }
    });
}

if (cleverapps.styles.StickersCollectionIcon) {
    cleverapps.overrideStyles(cleverapps.styles.StickersCollectionIcon, {
        icon: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 80 }
        },

        textBg: undefined,

        title: {
            maxWidth: 220,
            maxHeight: 60,
            x: { align: "center" },
            y: { align: "bottom", dy: 120 }
        },

        margin: -110,

        badge: {
            x: { align: "left", dx: -10 },
            y: { align: "top", dy: 10 },
            text: {
                x: { align: "center", dx: -15 },
                y: { align: "center", dy: 15 },
                maxWidth: 95,
                rotation: -45
            }
        },

        underline: {
            x: { align: "center" },
            y: { align: "top", dy: 2 }
        },

        prizebar: {
            x: { align: "center", dx: 3 },
            y: { align: "center" },
            width: 170,
            prize: {
                x: { align: "right", dx: 55 },
                y: { align: "center" }
            },
            text: {
                dy: 1
            }
        },

        collectAnimation: {
            duration: 2.95
        }
    });

    cleverapps.overrideFonts(cleverapps.styles.FONTS, {
        STICKERS_COLLECTION_TEXT: {
            size: 30,
            color: cleverapps.styles.COLORS.STICKERS_COLLECTION_COLOR
        },

        STICKERS_AVAILABLE_TEXT: {
            size: 30,
            color: cleverapps.styles.COLORS.STICKERS_AVAILABLE_COLOR
        },

        STICKERS_PROGRESS_BAR_TEXT: {
            size: 32
        }
    });
}

if (cleverapps.styles.StickersCollectionWindow) {
    cleverapps.overrideStyles(cleverapps.styles.StickersCollectionWindow, {
        size: [
            { width: 900, height: 1200 },
            { width: 900, height: 1200 },
            { width: 900, height: 1200 }
        ],
        puzzlesComponent: {
            x: { align: "center" },
            y: { align: "center", dy: -50 }
        },
        prize: {
            position: [
                { x: { align: "center", dx: -109 }, y: { align: "top", dy: -37 } },
                { x: { align: "center", dx: -109 }, y: { align: "top", dy: -37 } },
                { x: { align: "center", dx: -109 }, y: { align: "top", dy: -37 } }
            ],
            width: 465
        },
        jokers: {
            position: [
                { x: { align: "right", dx: -85 }, y: { align: "top", dy: -30 } },
                { x: { align: "right", dx: -85 }, y: { align: "top", dy: -30 } },
                { x: { align: "right", dx: -85 }, y: { align: "top", dy: -30 } }
            ]
        },
        tooltip: {
            y: { align: "bottom", dy: -40 }
        },
        highlight: {
            x: { align: "center", dx: -5 },
            y: { align: "center", dy: -13 }
        },
        text: {
            dy: 0
        }
    });
    cleverapps.overrideFonts(cleverapps.styles.FONTS, {
        STICKER_JOKER_BUTTON_TEXT: {
            size: 80,
            color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
            stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
            shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
        }
    });
}

if (cleverapps.styles.StickerView) {
    cleverapps.overrideStyles(cleverapps.styles.StickerView, {
        text: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 135 }
        },
        points: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 50 }
        },
        badge: {
            x: { align: "right", dx: 25 },
            y: { align: "top", dy: 35 },
            text: {
                x: { align: "center" },
                y: { align: "center" },
                maxWidth: 100
            }
        }
    });
}

if (cleverapps.styles.StickersCompletedWindow) {
    cleverapps.overrideStyles(cleverapps.styles.StickersCompletedWindow, {
        layout: {
            x: { align: "center" },
            y: { align: "center", dy: -300 },
            margin: 20
        },
        puzzles: {
            x: { align: "center" },
            y: { align: "center", dy: 160 }
        }
    });
}

if (cleverapps.styles.StickersBookJokerView) {
    cleverapps.overrideStyles(cleverapps.styles.StickersBookJokerView, {
        withBillet: true
    });
}

if (cleverapps.styles.PackOfferComponent && cleverapps.styles.PackOfferComponent[Offers.TYPES.STICKERS_PACK]) {
    cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent[Offers.TYPES.STICKERS_PACK], {
        pack: {
            badge: {
                x: { align: "right", dx: -50 },
                y: { align: "top", dy: 65 },
                rotation: 30
            },
            packOverlap: 1,
            title: undefined
        },
        tap: {
            x: { align: "center", dx: -20 },
            y: { align: "center", dy: -110 },
            rotation: 0
        }
    });
}

if (cleverapps.styles.PrizeCalendarWindow) {
    cleverapps.overrideStyles(cleverapps.styles.PrizeCalendarWindow, {
        margin: 30,
        windowPadding: {
            bottom: 15,
            x: -10
        },
        tiles: {
            margin: {
                x: 55, y: 83
            },
            padding: {
                y: 15
            }
        },
        timerAtBottom: true
    });

    cleverapps.overrideStyles(cleverapps.styles.PrizeCalendarTileView, {
        width: 216,
        height: 144,
        collectDelay: 1.5,
        showUpAnimationDelay: 3,
        titleBg: {
            invisible: true,
            y: { align: "center", dy: 3 }
        },
        rewards: {
            x: { align: "center" },
            y: { align: "center", dy: -30 },

            fitToBox: {
                width: 120,
                height: 120
            }
        },
        outerBg: {
            sprite: bundles.prizecalendar.frames.tile_bg,
            x: { align: "center" },
            y: { align: "center" }
        },
        innerBg: {
            spine: {
                json: bundles.prizecalendar.jsons.tile_json,
                animations: {
                    last: "idle_g_box",
                    lastAvailable: "idle_g_box_paper_close",
                    locked: "idle_w_box",
                    adsUnavailable: "idle_w_box_paper_close",
                    received: "idle_w_box_paper_open",
                    available: "idle_w_box_paper_close"
                }
            },
            receiveAnimation: {
                default: "w_box_paper_claim",
                last: "g_box_paper_claim"
            },
            changeAnimation: {
                lockedToAvailable: { animation: "w_box_paper", idle: "idle_w_box_paper_close" },
                availableToReceived: { animation: "w_box_paper_claim", idle: "idle_w_box_paper_open" },
                lastToAvailable: { animation: "g_box_paper", idle: "idle_g_box_paper_close" },
                lastToReceived: { animation: "g_box_paper_claim", idle: "idle_g_box_paper_open" },
                dontWaitForAds: true
            },
            x: { align: "center" },
            y: { align: "center" }
        },
        mark: {
            checkOnly: true,
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -37 }
        },
        arrow: {
            right: {
                x: { align: "right", dx: 47 },
                y: { align: "center" }
            },
            down: {
                x: { align: "center" },
                y: { align: "bottom", dy: -60 }
            },
            left: {
                x: { align: "left", dx: -46 },
                y: { align: "center" }
            }
        }
    });
}

if (cleverapps.styles.SuppliesWindow) {
    cleverapps.overrideStyles(cleverapps.styles.SuppliesWindow, {
        scales: [0.8, 1, 1]
    });
}

if (cleverapps.styles.LanternStartComponent) {
    cleverapps.overrideStyles(cleverapps.styles.LanternStartComponent, {
        bg: {
            scale9: {
                width: 590,
                height: 290
            }
        },
        lanternIcon: {
            x: { align: "center", dx: -140 },
            y: { align: "center" }
        },

        helpButton: {
            x: { align: "right", dx: -6 },
            y: { align: "top", dy: -6 },
            scale: 1
        },

        label: {
            x: { align: "center", dx: 100 },
            y: { align: "center", dy: 65 }
        },

        progressBar: {
            x: { align: "center", dx: 100 }
        }
    });
}

if (cleverapps.styles.QuestIcon) {
    cleverapps.overrideStyles(cleverapps.styles.QuestIcon, {
        progress: {
            y: { align: "bottom", dy: -5 },
            width: 160,
            height: 55,
            text: {
                y: { align: "center", dy: 2 }
            }
        }
    });
}

if (cleverapps.styles.StartQuestWindow) {
    cleverapps.overrideStyles(cleverapps.styles.StartQuestWindow, {
        tile: {
            icon: {
                scale: 1.4
            }
        }
    });
}

if (cleverapps.styles.QuestInfoView) {
    cleverapps.overrideStyles(cleverapps.styles.QuestInfoView, {
        content: {
            title: {
                width: 614
            }
        },

        arrow: {
            x: { align: "left", dx: -29 }
        },

        compact: {
            arrow: {
                x: { align: "left", dx: -29 }
            }
        }
    });
}

if (cleverapps.styles.OpenLetterButtonBooster) {
    cleverapps.overrideStyles(cleverapps.styles.OpenLetterButtonBooster, {
        width: 228,
        height: 120,
        position: [
            {
                x: { align: "right", dx: -10 },
                y: { align: "center", dy: -350 }
            },
            {
                x: { align: "right", dx: -10 },
                y: { align: "top", dy: -890 }
            },
            {
                x: { align: "right", dx: -10 },
                y: { align: "top", dy: -890 }
            }
        ],
        content: {
            margin: 0,
            direction: cleverapps.UI.HORIZONTAL,
            layout: {
                x: { align: "center", dx: 3 },
                y: { align: "center", dy: 5 }
            }
        }
    });
}