/**
 * Created by andrey on 26.12.2020.
 */

var EditorUnitView = UnitView.extend({
    createAdditionalView: function () {
        if (this.unit.chooser) {
            return;
        }
        this._super.apply(this, arguments);
    }
});