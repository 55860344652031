/**
 * Created by slava on 7/17/17.
 */

var LogTimeStart = function () {
    this.started = Date.now();

    this.timeout = setTimeout(this.stop.bind(this), 60000);
};

LogTimeStart.prototype.save = function () {
    if (!cleverapps.user || !cleverapps.eventLogger) {
        console.log("Skip logging time start, no user or eventLogger found after timeout");
        return;
    }

    var passedTime = Math.round((Date.now() - this.started) / 1000);
    if (passedTime > 60) {
        passedTime = 60;
    }

    var newbie = cleverapps.user.registered + cleverapps.parseInterval("1 day") > Date.now();
    var event = cleverapps.EVENTS.STATS.TIMESTART + (newbie ? "_new" : "_old");

    cleverapps.eventLogger.logEvent(event + "_count");
    cleverapps.eventLogger.logEvent(event, {
        value: passedTime
    });
};

LogTimeStart.prototype.stop = function () {
    if (this.timeout === undefined) {
        return;
    }

    clearTimeout(this.timeout);
    this.timeout = undefined;
    this.save();
};

LogTimeStart.active = new LogTimeStart();
