/**
 * Created by Aleksandr on 06.04.2023
 */

var InterstitialAction = function (f) {
    if (this.game.outcome === GameBase.OUTCOME_GAVEUP && !cleverapps.config.lives) {
        f();
        return;
    }
    cleverapps.rewardedAdsManager.showInterstitial(function (shown) {
        if (shown && cleverapps.noAds.needToNudge(true)) {
            new NoAdsWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        } else {
            f();
        }
    });
};

InterstitialAction.checkLastFocus = function () {
    var focus = cleverapps.meta.lastFocus;
    if (!focus) {
        return false;
    }

    return focus.startsWith("missionCompleted")
        || ["onLevelUp", "FinishQuest", "ExitExpeditionIconClicked",
            "openChestWindow", "updateBuildPassUnit", "GiveRuinReward",
            "ConfirmSpeedUpWindowLock", "StartUnitDialogue", "StartUnitGreeting"].indexOf(focus) !== -1;
};

InterstitialAction.showOnLowMonetization = function () {
    if (!cleverapps.flags.lowMonetization) {
        return;
    }

    if (Game.currentGame && Game.currentGame.counter.isActive()) {
        return false;
    }

    if (Game.currentGame && Game.currentGame.goalCounter && Game.currentGame.goalCounter.isActive()) {
        return false;
    }

    if (cleverapps.focusManager.isFocused()) {
        return false;
    }

    cleverapps.rewardedAdsManager.showInterstitial();
};