/**
 * Created by r4zi4l on 20.08.2021
 */

Families.initialize = function () {
    Families.initializeBase();

    Families.wood = FamiliesHelper.ResourceFamily(9, "wood", { deletedStage: 7, basicPrice: 7 });
    Families.stone = FamiliesHelper.ResourceFamily(10, "stone", { deletedStage: 7, basicPrice: 8 });

    Families.bear = FamiliesHelper.HeroFamily(7, { deletedStage: 1 });
    Families.elf = FamiliesHelper.HeroFamily(8, { deletedStage: 4 });
    Families.warlock = FamiliesHelper.HeroFamily(9, { deletedStage: 3 });
    Families.troll = FamiliesHelper.HeroFamily(10, { deletedStage: 6 });
    Families.knight = FamiliesHelper.HeroFamily(11, { deletedStage: 4 });

    Families.dragonmine = FamiliesHelper.SpecialUnitFamily({
        pointOfInterest: true,
        minigame: "minesweeper",
        unmergeable: true,
        multicell: FamiliesHelper.ShapeSquare,
        important: true,
        movable: false
    });

    if (cleverapps.config.debugMode) {
        Families.kingfood = FamiliesHelper.HeroFoodFamily();
        Families.dwarffood = FamiliesHelper.HeroFoodFamily();
    }

    Families.afterInitialize();
};

if (typeof cc === "undefined") {
    module.exports = Families;
}
