/**
 * Created by Denis Kuzin on 23 june 2022
 */

var UnitsShopComponentView = function (unitsShopComponent, unitView) {
    this.unitsShop = unitsShopComponent;
    this.unitView = unitView;

    if (unitsShopComponent.hasCrystals()) {
        this.showCrystals();
    }
};

UnitsShopComponentView.prototype.showCrystals = function () {
    if (this.unitsShop.unitWithSalePass()) {
        this.unitView.sprite.setAnimation(0, "salepass", true);
    }
};

UnitsShopComponentView.prototype.hideCrystals = function () {
    if (this.unitsShop.unitWithSalePass()) {
        this.unitView.sprite.setAnimation(0, "animation", true);
    }
};