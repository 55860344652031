/**
 * Created by Ivan on 17.10.2024
 */

var OpenLetterBooster = function (id) {
    Booster.call(this, id);
    this.state = OpenLetterBooster.INACTIVE;
};

OpenLetterBooster.prototype = Object.create(Booster.prototype);
OpenLetterBooster.prototype.constructor = OpenLetterBooster;

OpenLetterBooster.prototype.onExecute = function () {
    this.state = OpenLetterBooster.ACTIVE;

    Game.currentGame.keypad.chooseBoosterLetter();
};

OpenLetterBooster.prototype.isActive = function () {
    return this.state === OpenLetterBooster.ACTIVE;
};

OpenLetterBooster.prototype.isAnimating = function () {
    return this.state === OpenLetterBooster.ANIMATION;
};

OpenLetterBooster.prototype.open = function (letter) {
    var hintAnimationDuration = 0.3;
    var hintAnimationTimeout = hintAnimationDuration + 0.5;

    var letters = Game.currentGame.board.selectLettersPositions(letter.symbol);
    letters.forEach(function (pos) {
        Game.currentGame.board.addHint(pos[0], pos[1], cleverapps.Board.SPAWN_LETTER_ANIMATION);
    });

    this.state = OpenLetterBooster.ANIMATION;
    Game.currentGame.counter.setTimeout(function () {
        this.state = OpenLetterBooster.INACTIVE;
    }.bind(this), hintAnimationTimeout * 1000);
};

OpenLetterBooster.INACTIVE = 0;
OpenLetterBooster.ACTIVE = 1;
OpenLetterBooster.ANIMATION = 2;