/**
 * Created by mac on 10/5/20
 */

var AdminLevelsView = cc.Node.extend({
    ctor: function (adminLevels, mode) {
        this._super();

        this.adminLevels = adminLevels;

        this.mode = mode;

        var size = cleverapps.resolution.getSceneSize();

        this.setContentSize2(cleverapps.styles.AdminLevelsView.width, size.height);
        this.setAnchorPoint(0.5, 0.5);

        this.adminLevels.on("reset", this.reset.bind(this), this);
        this.adminLevels.on("addLevel", this.addLevel.bind(this), this);
        this.adminLevels.on("downloadLevels", this.downloadLevels.bind(this), this);
        this.adminLevels.on("changeActive", this.onSetActive.bind(this), this);

        var nodes = [];

        var log = this.log = new AdminLogView();
        nodes.unshift(this.wrapWithBg(log));

        this.scroll = this.createScroll();
        nodes.unshift(this.wrapWithBg(this.scroll));

        var chartWrapper = this.getChartWrapper();
        nodes.unshift(this.wrapWithBg(chartWrapper, cleverapps.styles.AdminLevelsView.bg.chart));

        var layout = new cleverapps.Layout(nodes, {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.AdminLevelsView.margin
        });
        layout.setAnchorPoint(0, 1);
        layout.setPositionRound(0, this.height - 15);
        this.addChild(layout);

        this.createLanguageButtons();
        this.createControlButtons();
        this.transferFileButtons();

        this.reset();
    },

    wrapWithBg: function (node, options) {
        options = options || {};

        var styles = cleverapps.styles.AdminLevelsView.bg;
        var paddingY = options.paddingY || styles.paddingY;
        var dx = options.dx || 0;
        var dy = options.dy || 0;

        var bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.common_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.addChild(node);
        bg.setContentSize2(this.width, node.height + paddingY);
        node.setPositionRound(bg.width / 2 + dx, bg.height / 2 + dy);
        return bg;
    },

    getChartWrapper: function () {
        var wrapper = new cc.Node();
        wrapper.setAnchorPoint(0.5, 0.5);
        wrapper.setContentSize2(this.width - cleverapps.styles.AdminLevelsView.chart.paddingX, cleverapps.styles.AdminLevelsView.chart.height);

        var chart = undefined;
        var onStatisticsLoaded = function (data) {
            chart = new AdminLevelsChart(data, wrapper);
            cleverapps.UI.fitToBox(chart, wrapper.getContentSize());
            chart.setPositionRound(wrapper.width / 2, wrapper.height / 2);
            wrapper.addChild(chart);
        };
        this.adminLevels.on("statisticsLoaded", onStatisticsLoaded, this);
        if (this.adminLevels.episodeStatistics.ready) {
            onStatisticsLoaded(this.adminLevels.episodeStatistics.getData());
        }

        this.adminLevels.on("statisticsReset", function () {
            if (chart) {
                chart.removeFromParent(true);
                chart = undefined;
            }
        }, this);

        return wrapper;
    },

    reset: function () {
        if (this.levelsList) {
            this.levelsList.removeFromParent(true);
            delete this.levelsList;
        }
        this.levelsList = this.getLevelsList();
        cleverapps.UI.wrapWithPadding(this.levelsList, cleverapps.styles.AdminLevelsView.levels.padding, true);
        this.scroll.setInnerContent(this.levelsList);
        // this.scroll.scrollTo(cleverapps.UI.ScrollView.SCROLLS.UPPER_LEFT);
        this.onSetActive(true);
    },

    getLevelsList: function () {
        var levelsList = new cc.Node();
        levelsList.setAnchorPoint(0.5, 0.5);

        this.slots = [];

        this.adminLevels.levels.forEach(function (adminLevel, levelNo) {
            var slot = new AdminLevelSlotView(adminLevel);
            slot.setPositionRound(this.alignInTheGrid(levelNo));
            levelsList.addChild(slot);
            this.slots.push(slot);

            var view = AdminLevelView.create(adminLevel, this);
            view.setPositionRound(slot.width / 2, slot.height / 2);
            slot.addChild(view);

            if (!adminLevel.isAddMoreLevel()) {
                adminLevel.on("update", function () {
                    this.slots[adminLevel.levelNo].updateAdminLevel(adminLevel);
                }.bind(this), levelsList);
            }
        }.bind(this));

        cleverapps.UI.wrap(levelsList);

        return levelsList;
    },

    createScroll: function () {
        var scroll = new cleverapps.UI.ScrollView();
        scroll.setContentSize2(this.width, cleverapps.styles.AdminLevelsView.levels.height);
        return scroll;
    },

    addLevel: function () {
        this.reset();
    },

    getNearestSlotIndex: function (worldPosition) {
        var position = this.levelsList.convertToNodeSpace(worldPosition);
        var minDist = Number.MAX_VALUE;
        var index = 0;
        for (var i = 0; i < this.slots.length - 1; i++) {
            var slot = this.slots[i];
            var dist = cc.pDistance(position, slot.getPosition());
            if (dist < minDist) {
                minDist = dist;
                index = i;
            }
        }

        return index;
    },

    createLanguageButtons: function () {
        if (!cleverapps.administrator.adminLanguages) {
            return;
        }

        var styles = cleverapps.styles.AdminLevelsView.languageBlock;

        var width = (styles.width + styles.margin - styles.padding.x * 2) / cleverapps.config.languages.length - styles.margin;
        var views = Object.values(cleverapps.administrator.adminLanguages).map(function (adminLanguage) {
            return new AdminLanguageView(adminLanguage, { width: width });
        });

        var layout = new cleverapps.Layout(views, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });

        layout.setPositionRound(styles);
        this.addChild(layout);
    },

    createControlButtons: function () {
        var styles = cleverapps.styles.AdminLevelsView.controlButtons;
        var buttonOptions = [];

        if (cleverapps.config.name === "scramble") {
            buttonOptions.push({
                icon: bundles.admin.frames.update_icon,
                hint: "Update googlesheets",
                action: function () {
                    cleverapps.focusManager.display({
                        focus: "AdminUpdateGooglesheetsWindow",
                        action: function (f) {
                            new AdminUpdateGooglesheetsWindow();
                            cleverapps.focusManager.onceNoWindowsListener = f;
                        }
                    });
                }
            });
        }

        buttonOptions.push({
            icon: bundles.admin.frames.update_diff_icon,
            hint: "Update difficulty docs",
            action: function () {
                cleverapps.focusManager.display({
                    focus: "updateDifficultyDocs",
                    action: function (f) {
                        var waitWindow = new WaitWindow("Updating...");
                        cleverapps.focusManager.onceNoWindowsListener = f;

                        var stopWait = cleverapps.waitNoMore(20000, cleverapps.once(function () {
                            waitWindow.close();
                        }));

                        cleverapps.administrator.adminEpisodes.gatherDifficultyData(function (data) {
                            cleverapps.RestClient.post("/admin/epdifficulty/upload/", data, function (response) {
                                if (response.error) {
                                    console.log("Failed uploading difficulty statistics", response.error);
                                } else {
                                    console.log("Updated difficulty docs https://docs.google.com/spreadsheets/d/1CSamhReQazPJb7p8hEwuf2ntUTTrxjSz5Dkb_JRI54Q");
                                }
                                stopWait();
                            }, function (err) {
                                console.log("Error uploading difficulty statistics", err);
                                stopWait();
                            });
                        }, {
                            timeout: 20000
                        });
                    }
                });
            }
        });

        var buttons = [new AdminDebugButton()];

        if (["merge", "tile3"].indexOf(cleverapps.config.type) !== -1) {
            buttons.unshift(new AdminPasteClipboardButton());
        }

        var views = buttons.concat(buttonOptions.map(function (data) {
            return new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.editor,
                content: new cc.Sprite(data.icon),
                width: styles.width,
                height: styles.height,
                onClicked: data.action,
                hint: data.hint,
                hintLocation: cleverapps.UI.Tooltip.LOCATION_BELOW
            });
        }));

        var layout = new cleverapps.Layout(views, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            reversed: true
        });

        layout.setPositionRound(styles);
        this.addChild(layout);
    },

    transferFileButtons: function () {
        var styles = cleverapps.styles.AdminLevelsView.transferFileButtons;

        var uploadButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.editor,
            content: new cc.Sprite(bundles.admin.frames.upload_icon),
            width: styles.width,
            height: styles.height,
            onClicked: function () {
                cleverapps.focusManager.display({
                    focus: "UploadLevels",
                    action: function (f) {
                        var element = document.createElement("input");
                        element.setAttribute("type", "file");
                        element.setAttribute("id", "uploadLevels");
                        element.style.display = "none";
                        document.body.appendChild(element);
                        element.click();

                        element.addEventListener("change", function (event) {
                            this.adminLevels.upload(event, f);
                        }.bind(this));
                    }.bind(this)
                });
            }.bind(this),
            hint: "Upload levels"
        });

        var downloadButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.editor,
            content: new cc.Sprite(bundles.admin.frames.download_icon),
            width: styles.width,
            height: styles.height,
            onClicked: function () {
                cleverapps.focusManager.display({
                    focus: "DownloadLevels",
                    action: function (f) {
                        this.adminLevels.download(f);
                    }.bind(this)
                });
            }.bind(this),
            hint: "Download levels"
        });

        var layout = new cleverapps.Layout([uploadButton, downloadButton], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        layout.setPositionRound(styles);
        this.addChild(layout);
    },

    downloadLevels: function (options) {
        var data = options.data;
        var fileName = options.fileName;

        var element = document.createElement("a");
        element.setAttribute("href", "data:text/json;charset=utf-8," + encodeURIComponent(data));
        element.setAttribute("download", fileName);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    },

    switchMode: function (mode) {
        this.reset(mode);
    },

    alignInTheGrid: function (index) {
        var styles = cleverapps.styles.AdminLevelsView;

        var marginCol = styles.marginCol;
        var marginRow = styles.marginRow;
        var availableWidth = styles.width;
        var size = styles.cellSize;

        var perRow = Math.floor(availableWidth / (size.width + marginCol));

        var col = index % perRow;
        var row = Math.floor(index / perRow);

        return cc.p(
            size.width / 2 + col * (size.width + marginCol),
            (this.levelsList ? this.levelsList.height : 0) - size.height / 2 - row * (size.height + marginRow) - styles.paddingTop
        );
    },

    onSetActive: function (silent) {
        this.slots.forEach(function (slot) {
            if (slot.adminLevel.selected) {
                if (silent) {
                    this.scroll.scrollTo(slot, 0);
                } else {
                    this.scroll.stopAutoScroll();
                    this.scroll.runAction(new cc.ScrollAction(slot, {
                        duration: 0.2,
                        visibleBox: {
                            top: 0.85,
                            bottom: 0.85
                        }
                    }));
                }
            }
        }.bind(this));
    }
});

cleverapps.styles.AdminLevelsView = {
    paddingTop: 50,
    margin: 3,
    marginCol: 10,
    marginRow: 70,

    levels: {
        height: 783,
        padding: {
            top: 40,
            right: 40
        }
    },

    chart: {
        paddingX: 100,
        height: 300
    },

    width: 880,

    cellSize: {
        width: 140,
        height: 220
    },

    languageBlock: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -310 },
        width: 530,
        margin: 2,
        padding: {
            x: 0,
            y: 10
        }
    },

    controlButtons: {
        x: { align: "right", dx: -25 },
        y: { align: "top", dy: -54 },
        margin: 2,
        width: 45,
        height: 45
    },

    transferFileButtons: {
        x: { align: "right", dx: -25 },
        y: { align: "top", dy: -200 },
        margin: 2,
        width: 45,
        height: 45
    },

    bg: {
        paddingY: 10,

        chart: {
            paddingY: 43,
            dx: -30,
            dy: 15
        }
    }
};