/**
 * Created by r4zi4l on 06.04.2021
 */

var ThirdElementWindow = CleverappsWindow.extend({
    onWindowLoaded: function (thirdElement) {
        this.thirdElement = thirdElement;

        this._super({
            title: "ThirdElementWindow.title",
            name: "ThirdElementWindow",
            content: this.createContent(),
            closeButton: true,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true,
            openSound: bundles.thirdelement.urls.thirdelement_window_effect
        });
    },

    getPerson: function () {
        return cleverapps.persons.choose("seller", "worker");
    },

    createContent: function () {
        var styles = cleverapps.styles.ThirdElementWindow;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize(styles);

        var infoBlock = this.createInfoBlock();
        infoBlock.setAnchorPoint(0.5, 0.5);
        infoBlock.setPositionRound(styles.infoBlock);
        content.addChild(infoBlock);

        var buttons = this.createButtons();
        buttons.setAnchorPoint(0.5, 0.5);
        buttons.setPositionRound(styles.buttons);
        content.addChild(buttons);

        var title = cleverapps.UI.generateOnlyText("ThirdElementWindow.text", cleverapps.styles.FONTS.THIRD_ELEMENT_WINDOW_TEXT);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        title.setDimensions(styles.title.width, 0);
        title.fitTo(undefined, styles.title.height);
        title.setAnchorPoint(0.5, 0.5);
        title.setPositionRound(styles.title);
        content.addChild(title);

        return content;
    },

    createInfoBlock: function () {
        var styles = cleverapps.styles.ThirdElementWindow.infoBlock;

        var background = cleverapps.UI.createScale9Sprite(bundles.merge.frames.icon_background);
        background.setContentSize2(styles);

        if (this.thirdElement.getTimeLeft()) {
            var timer = this.createTimer();
            timer.setAnchorPoint(0.5, 0.5);
            timer.setPositionRound(styles.timer);
            background.addChild(timer);
        }

        var icon = new UnitOnCell(this.thirdElement.prize);
        icon.setAnchorPoint(0.5, 0.5);
        icon.setScale(styles.icon.scale);
        icon.setPositionRound(styles.icon);
        background.addChild(icon);

        return background;
    },

    createTimer: function () {
        var styles = cleverapps.styles.ThirdElementWindow.infoBlock.timer;

        var countdown = new Timer(this.thirdElement.getTimeLeft());
        countdown.setAnchorPoint(0.5, 0.5);
        countdown.setPositionRound(styles);

        var text = cleverapps.UI.generateOnlyText("ThirdElementWindow.timer", cleverapps.styles.FONTS.THIRD_ELEMENT_WINDOW_TITLE_TEXT);

        return new cleverapps.Layout([text, countdown], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createButtons: function () {
        var styles = cleverapps.styles.ThirdElementWindow.buttons;

        var product = this.thirdElement.getProduct();

        var buyButton = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: product ? product.getCurrentPrice() : "$$" + this.thirdElement.price,
            textOff: "ThirdElementWindow.timeIsOver",
            onClicked: this.tryBuy.bind(this),
            type: cleverapps.styles.UI.Button.Images.button_blue
        });

        var passButton = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "ThirdElementWindow.pass",
            onClicked: this.tryPass.bind(this),
            type: cleverapps.styles.UI.Button.Images.button_red
        });

        return new cleverapps.Layout([buyButton, passButton], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    },

    tryBuy: function () {
        this.thirdElement.buy(function (success) {
            if (success) {
                this.close();
            }
        }.bind(this));
    },

    tryPass: function () {
        this.close();
        this.thirdElement.reject();
    },

    getPreferredBundles: function () {
        return ["game"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    THIRD_ELEMENT_WINDOW_TITLE_TEXT: {
        size: 55,
        color: cleverapps.styles.COLORS.YELLOW_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    THIRD_ELEMENT_WINDOW_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.ThirdElementWindow = {
    width: 830,
    height: 670,

    infoBlock: {
        width: 830,
        height: 340,
        x: { align: "center" },
        y: { align: "top" },

        icon: {
            x: { align: "right", dx: -100 },
            y: { align: "center", dy: 5 },
            scale: 1.2
        },

        timer: {
            width: 280,
            height: 54,
            x: { align: "left", dx: 110 },
            y: { align: "center", dy: 10 },
            margin: 20
        }
    },

    title: {
        width: 600,
        height: 120,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 185 }
    },

    buttons: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 15 },
        margin: 52,
        button: {
            width: 360,
            height: 110
        }
    }
};
