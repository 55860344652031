/**
 * Created by andrey on 09.02.2021.
 */

var DecoratorUnitIcon = cc.Scale9Sprite.extend({
    ctor: function (unit) {
        this.unit = unit;

        this._super(bundles.merge.frames.icon_background);

        this.setContentSize2(cleverapps.styles.DecoratorUnitIcon);

        var icon = Map2dDecoratorView.getDecoratorImage(unit);
        icon.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(icon);

        if (icon instanceof cleverapps.Spine && icon.hasAnimation("smile")) {
            icon.setAnimation(0, "smile", true);
        }

        cleverapps.UI.fitToBox(icon, this.getContentSize());

        var title = cleverapps.UI.generateOnlyText(unit.code.replace("decorator_", ""), cleverapps.styles.FONTS.LIGHT_TITLE_TEXT);
        title.setPositionRound(cleverapps.styles.DecoratorUnitIcon.title);
        title.fitTo(this.width, 0);
        this.addChild(title);

        cleverapps.UI.applyHover(this);
        cleverapps.UI.onClick(this, unit.onClick.bind(unit));

        this.update();

        unit.onUpdate = this.createListener(this.update.bind(this));
    },

    update: function () {
        var image = this.unit.isSelected() ? bundles.merge.frames.icon_background_light : bundles.merge.frames.icon_background;
        this.setSpriteFrame(new cc.Sprite(image).getSpriteFrame());
    }
});

cleverapps.styles.DecoratorUnitIcon = {
    width: 160,
    height: 160,

    title: {
        x: { align: "center" },
        y: { align: "bottom", dy: -40 }
    }
};