/**
 * Created by spepa on 21.06.2023
 */

var Map2dFenceView = cc.Node.extend({
    ctor: function (fenceTile) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this._localZOrder = fenceTile.getLocalZOrder();

        var image = new cleverapps.Spine(fenceTile.getJson());
        image.setIdle("animation");

        image.setPositionRound({ align: "center" }, { align: "center" });

        var styles = cleverapps.styles.Map2dFenceView[fenceTile.code];
        if (styles) {
            this.setPositionRound({ align: "center", dx: styles.x }, { align: "center", dy: styles.y });
        }

        this.setContentSize2(image);
        this.addChild(image);

        fenceTile.getView = this.createListener(function () {
            return this;
        }.bind(this));
    }
});

cleverapps.styles.Map2dFenceView = {};

if (cleverapps.config.name === "wordsoup") {
    cleverapps.overrideStyles(cleverapps.styles.Map2dFenceView, {
        inner: { x: 0, y: 0 },
        up: { x: -44.28, y: 128.77 },
        right: { x: 44.28, y: 128.77 },
        down: { x: 43.24, y: 78.52 },
        left: { x: -43.42, y: 78.89 },
        leftup: { x: -52, y: 78.7 },
        rightdown: { x: 34.89, y: 129.12 },
        downright: { x: 52, y: 78.7 },
        upleft: { x: -34.89, y: 129.12 }
    });
}