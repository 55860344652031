/**
 * Created by r4zi4l on 09.03.2021
 */

var CastleGuideOptions = {
    name: "CastleGuideWindow",

    getGuideBundle: function () {
        return bundles.castleguidewindow;
    },

    stagesContent: function (unit) {
        var styles = cleverapps.styles.CastleGuideWindow;

        var stageCodes = {
            stageMinus1: unit.stage - 1,
            stageMinus2: unit.stage - 2,
            stageMinus3: unit.stage - 3
        };

        while (stageCodes.stageMinus1 >= 0 && Families[unit.code].units[stageCodes.stageMinus1].deleted) {
            stageCodes.stageMinus1--;
            stageCodes.stageMinus2--;
            stageCodes.stageMinus3--;
        }

        while (stageCodes.stageMinus2 >= 0 && Families[unit.code].units[stageCodes.stageMinus2].deleted) {
            stageCodes.stageMinus2--;
            stageCodes.stageMinus3--;
        }

        while (stageCodes.stageMinus3 >= 0 && Families[unit.code].units[stageCodes.stageMinus3].deleted) {
            stageCodes.stageMinus3--;
        }

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.blue_light, styles.light);
            this.addSprite(container, this.bundle.frames.blue_light_tears, styles.tears);
            this.addSprite(container, this.bundle.frames.blue_light_foreground, styles.foreground);
            this.addUnitIcon(container, unit.code, stageCodes.stageMinus2, styles.result);
            styles.components.forEach(function (stageStyles) {
                this.addUnitIcon(container, unit.code, stageCodes.stageMinus3, stageStyles);
            }, this);
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            this.addUnitIcon(container, unit.code, stageCodes.stageMinus1, styles.castle);
            this.addSprite(container, this.bundle.frames.white_arrows, styles.arrows);
            var progress = this.addSprite(container, this.bundle.frames.progress, styles.progress);
            this.addUnitIcon(progress, unit.code, unit.stage, styles.icon);
            styles.components.forEach(function (stageStyles) {
                this.addUnitIcon(container, unit.code, stageCodes.stageMinus2, stageStyles);
            }, this);
        }.bind(this));

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.yellow_light, styles.light);
            this.addUnitIcon(container, unit.code, unit.stage, styles.castle);
            this.addSprite(container, this.bundle.frames.crystals, styles.crystals);
        }.bind(this));

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.CastleGuideWindow = {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: 70 }
        },

        result: {
            x: 0,
            y: 0
        },

        light: {
            x: 0,
            y: 0
        },

        foreground: {
            x: 0,
            y: 0
        },

        tears: {
            x: 0,
            y: -279
        },

        components: [
            {
                x: -107,
                y: -166,
                scale: 0.9
            },
            {
                x: -4,
                y: -236,
                scale: 0.9
            },
            {
                x: 105,
                y: -166,
                scale: 0.9
            }
        ]
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -80 }
        },

        castle: {
            x: 0,
            y: 0,
            scale: 0.9
        },

        progress: {
            x: 0,
            y: -125
        },

        icon: {
            x: { align: "left", dx: 10 },
            y: { align: "center", dy: -2 },
            scale: 0.30
        },

        arrows: {
            x: -10,
            y: 125
        },

        components: [
            {
                x: -94,
                y: 218,
                scale: 0.7
            },
            {
                x: 96,
                y: 200,
                scale: 0.8
            }
        ]
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -50 }
        },

        castle: {
            x: 0,
            y: 0,
            scale: 1.1,
            padding: {
                x: 10,
                y: 10
            }
        },

        light: {
            x: 0,
            y: 50
        },

        crystals: {
            x: 0,
            y: 50
        }
    }
};
