/**
 * Created by iamso on 27.12.2021
 */

var ImproverGuideOptions = {
    name: "ImproverGuideWindow",

    getGuideBundle: function () {
        return bundles.improverguidewindow;
    },

    stagesContent: function () {
        var styles = cleverapps.styles.ImproverGuideWindow;
        var code = cleverapps.unitsLibrary.listCodesByType(cleverapps.unitsLibrary.getExpeditionUnitType("gift"))[0];

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            var unit = new UnitOnCell(this.unit, { unitImgOptions: { defaultAnimation: "idle" } });
            container.addChild(unit);
            unit.setPositionRound(styles.generator.x, styles.generator.y);
            unit.setScale(styles.generator.scale);
            this.addUnitIcon(container, code, 2, styles.ball);
            this.addSprite(container, this.bundle.frames.white_arrow_up, styles.arrow);
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            var unit = new UnitOnCell(this.unit, { unitImgOptions: { defaultAnimation: "animation" } });
            container.addChild(unit);
            unit.setPositionRound(styles.generator);
            unit.setScale(styles.generator.scale);
            var timer = new ComponentTimer(new cleverapps.CountDown(Improver.GENERATE_DURATION), Improver.GENERATE_DURATION);
            timer.clear();
            unit.addChild(timer);
            timer.setPositionRound(styles.generator.timer);
            timer.setScale(styles.generator.timer.scale);
        }.bind(this));

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            var unit = new UnitOnCell({
                code: code,
                stage: 3
            });
            container.addChild(unit);
            unit.setPositionRound(styles.ball);
            unit.setScale(styles.ball.scale);
        });

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.ImproverGuideWindow = {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -30 }
        },

        ball: {
            x: { align: "center", dx: -100 },
            y: { align: "center", dy: 255 },
            scale: 1.3
        },

        generator: {
            x: { align: "center" },
            y: { align: "center", dy: 10 },
            scale: 1.8
        },

        arrow: {
            x: { align: "center", dx: -30 },
            y: { align: "center", dy: 180 },
            scale: 1.2,
            rotation: 80
        }
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -30 }
        },

        generator: {
            x: { align: "center" },
            y: { align: "center", dy: 10 },
            scale: 1.8,

            timer: {
                x: { align: "center", dx: 10 },
                y: { align: "bottom", dy: 20 },
                scale: 0.9
            }
        }
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -30 }
        },

        ball: {
            x: { align: "center", dx: -5 },
            y: { align: "center", dy: -20 },
            scale: 1.8
        }
    }
};
