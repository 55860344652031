/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["showBoughtWorker"] = {
    type: Placements.FREE_FOCUS,
    priority: 8,

    filter: function () {
        return Map2d.currentMap && Map2d.currentMap.workers.wantsToShowBoughtWorker;
    },

    action: function () {
        Map2d.currentMap.workers.showBoughtWorker();
    }
};

Placements.ENTRIES["updateMissionTreeUnit"] = {
    type: Placements.FREE_FOCUS,

    filter: function () {
        return MissionTree.wantsToUpdateUnitStage;
    },

    action: function () {
        MissionTree.processStateChange();
    }
};