/**
 * Created by Denis Kuzin on 04 april 2023
 */

cleverapps.SkinManager.SKINS.collections_expedition = {
    bundle: "expedition_collections",

    slots: {
        unitIcon: function (unit) {
            return bundles.unit_icons_collections.frames["small_icon_family_" + unit.code];
        },

        gameAudio: function () {
            return bundles.expedition_collections.urls.collections_music;
        },

        workerCutTime: function () {
            return 500;
        },

        workerMiningAnimationStyles: function () {
            return cleverapps.styles.collections.miningAnimation;
        },

        gameSceneBg: function () {
            return {
                horizontalPattern: bundles.expedition_collections.urls.collections_bg,
                parallax: true
            };
        },

        fogBlocker: function () {
            return cleverapps.styles.collections.fogBlockerLock;
        },

        levelUpWindowValueFont: function () {
            return cleverapps.styles.FONTS.LEVELUPWINDOW_COLLECTIONS_VALUE_TEXT;
        },

        worker_json: function () {
            return bundles.merge_collections.jsons.worker_json;
        },

        toolbar_units_library_icon_png: bundles.toolbar.frames.collections_toolbar_units_library_icon_png,

        menubar_round_bg_png: bundles.menubar.frames.collections_menubar_round_bg_png,
        crown_json: bundles.menubar.jsons.collections_crown_json,
        merge_wand_png: bundles.menubar.frames.collections_merge_wand_png,

        feed_icon_png: bundles.buttons_inlined_icons.frames.collections_feed_icon_png,

        levelup_json: function () {
            return bundles.levelupwindow.jsons.collections_levelup_json;
        },

        expedition_buildpass_icon_json: bundles.sidebar.jsons.collections_expedition_buildpass_icon_json,

        reward_exp_png: bundles.reward_icons.frames.collections_reward_exp_png,
        reward_exp_small_png: bundles.reward_icons.frames.collections_reward_exp_small_png,
        reward_wand_png: bundles.reward_icons.frames.collections_reward_wand_png,
        reward_wand_small_png: bundles.reward_icons.frames.collections_reward_wand_small_png,
        paw_png: bundles.reward_icons.frames.collections_paw_png,

        fog_disappear_effect: function () {
            return bundles.fog_collections.urls.open_effect;
        }
    }
};