/**
 * Created by razial on 30.07.2024.
 */

var DynamiteView = function (dynamite, unitView) {
    this.dynamite = dynamite;
    this.unitView = unitView;
};

DynamiteView.prototype.explode = function () {
    this.unitView.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this.unitView), {
        keepScale: true
    });
    this.unitView.sprite.setAnimation(0, "explode", false);
    this.unitView.sprite.setCompleteListener(function () {
        this.unitView.removeFromParent();
    }.bind(this));
};