/**
 * Created by iamso on 22.11.2021
 */

var UnitStories = function () {
    UnitStoriesInitialize();

    this.fresh = [];
    cleverapps.eventBus.on("unitAvailable", this.onUnitAvailable.bind(this), this);
};

UnitStories.prototype.destructor = function () {
    runCleaners(this);
};

UnitStories.prototype.findStory = function (key) {
    var pageId = cleverapps.travelBook.getCurrentPage().id;

    var skinStoriesData = cleverapps.skins.getSlot("skinName") && UnitStoriesData[cleverapps.skins.getSlot("skinName")];
    if (skinStoriesData && skinStoriesData[key]) {
        var skinStory = skinStoriesData[key][pageId] || skinStoriesData[key].default;
        if (skinStory) {
            return skinStory;
        }
    }

    var story = UnitStoriesData[key];
    if (story) {
        return story[pageId] || story.default;
    }
};

UnitStories.prototype.onUnitAvailable = function (unit) {
    var unitStory = this.findStory(Unit.GetKey(unit));
    if (!unitStory || !(unit instanceof Unit)) {
        return;
    }

    if (["caravanship", "kraken"].indexOf(unit.code) !== -1) {
        return;
    }

    this.fresh.push({
        story: unitStory,
        unit: unit
    });
    Game.currentGame.counter.trigger();
};

UnitStories.prototype.onFogOpened = function (fogId) {
    var unitStory = this.findStory("fog_" + fogId);
    if (!unitStory) {
        return;
    }

    if (unitStory.target) {
        var units = Map2d.currentMap.listAvailableUnitsInRegion(fogId, unitStory.target);
        var targets = Map2d.currentMap.findLargestGroup(units);
    }

    this.fresh.push({
        story: unitStory,
        unit: targets
    });
};

UnitStories.prototype.processFresh = function () {
    if (!this.fresh.length || cleverapps.focusManager.isFocused() || Map2d.currentMap.dragging) {
        return;
    }

    var unitStory = this.fresh.shift();

    cleverapps.focusManager.display({
        focus: "StartUnitDialogue",
        actions: [
            function (f) {
                this.showUnitStory(f, unitStory.story.dialogue, {
                    unit: unitStory.unit,
                    tutorial: this.getTutorial(unitStory.story.id)
                });
            }.bind(this)
        ]
    });
};

UnitStories.prototype.getTutorial = function (storyId) {
    return MergeTutorials["story_" + storyId];
};

UnitStories.prototype.showUnitStory = function (f, dialogueData, options) {
    options = options || {};

    if (!dialogueData || cleverapps.gameModes.skipUnitStories) {
        f();
        return;
    }

    var units = cleverapps.toArray(options.unit);
    var unit = cc.getCenterPoint(units);
    var unitView = unit && unit.onGetView();

    cleverapps.focusManager.compound(f, [
        function (f) {
            if (unitView) {
                unit.hideAdditionalViews();
            }
            f();
        },

        function (f) {
            if (unitView) {
                Map2d.currentMap.zoomIn(unit, {
                    zoom: 2,
                    callback: f
                });
            } else {
                f();
            }
        },

        function (f) {
            var delay = 600;

            if (options.delay !== undefined) {
                delay = options.delay;
            } else if (unitView) {
                delay = 0;
            }

            Game.currentGame.counter.setTimeout(f, delay);
        },

        function (f) {
            this.showDialogue(dialogueData, units, f);
        }.bind(this),

        function (f) {
            if (unit) {
                Map2d.currentMap.zoomOut(f);
            } else {
                f();
            }
        },

        function (f) {
            if (unitView) {
                unit.showAdditionalViews();
            }
            f();
        },

        function (f) {
            if (options.tutorial) {
                if (["fruitshop", "resourceshop", "discountshop"].indexOf(options.unit.code) !== -1
                    && cleverapps.unitsShop.getAvailableShops().length === 2 && !cleverapps.forces.isShown(Forces.UNITS_SHOP_ICON.id)) {
                    f();
                } else {
                    Game.currentGame.tutorial.showTutorial(options.tutorial, f);
                }
            } else {
                f();
            }
        }
    ]);
};

UnitStories.prototype.showDialogue = function (dialogueData, units, f) {
    var steps = cleverapps.clone(dialogueData, true);

    var dialogue = new Dialogue(steps, {
        showUp: true,
        autoClose: false,
        autoScroll: false
    });

    var closeDialogue = function () {
        if (!dialogue.typing && dialogue.stage >= dialogue.data.length - 1) {
            dialogue.close();
        }
    };

    dialogue.on("buttonClicked", closeDialogue);
    dialogue.on("screenClicked", closeDialogue);
    dialogue.on("afterClose", f);

    if (units) {
        units = units.filter(function (unit) {
            return unit.onGetView();
        });
        units.sort(function (a, b) {
            return (b.x - a.x) || (a.y - b.y);
        });
        dialogue.on("showUp", function () {
            var unique = units.reduce(function (arr, unit) {
                if (!arr.find(function (item) {
                    return Unit.IsApplicable(item, unit);
                })) {
                    arr.push(unit);
                }
                return arr;
            }, []);

            units.forEach(function (unit) {
                var spotlight = units.length === 1 || (unique.length > 1 && unique.includes(unit));
                var highlighter = new UnitHighlighter(Map2d.currentMap.getMapView(), unit, { spotlight: spotlight });
                dialogue.on("beforeClose", highlighter.unhighlight.bind(highlighter));
            });
        });
    }

    var dialogueView = new DialogueView(dialogue);
    dialogueView._setGlobalZOrder(true);
};
