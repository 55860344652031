/**
 * Created by evgenijsenkevic on 26.09.2024
 */

var InvisibleView = function (invisible, unitView) {
    if (cleverapps.config.editorMode) {
        return;
    }

    unitView.hideSprite();
};