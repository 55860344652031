/**
 * Created by olga on 27.09.2024
 */

var Fixable = function (unit) {
    UnitComponent.call(this, unit);
};

Fixable.prototype = Object.create(UnitComponent.prototype);
Fixable.prototype.constructor = Fixable;

Fixable.prototype.updateStage = function (f) {
    if (this.unit.isLast()) {
        f();
        return;
    }

    var x = this.unit.x;
    var y = this.unit.y;
    var newUnit = new Unit({
        code: this.unit.code,
        stage: this.unit.stage + 1
    });

    var oldUnitImage = this.unit.onGetView().sprite;
    oldUnitImage.replaceParentSamePlace(Map2d.currentMap.getMapView().animations);
    newUnit.setPosition(x, y);
    Map2d.currentMap.onAddUnit(x, y, newUnit);
    Map2d.currentMap.add(Map2d.LAYER_UNITS, x, y, newUnit);
    Map2d.currentMap.onUnitAvailable(newUnit);

    newUnit.findComponent(Fixable).afterUpgradeStage(f, oldUnitImage);
};

Fixable.prototype.afterUpgradeStage = function (f, oldUnitImage) {
    this.view.afterUpgradeStage(f, oldUnitImage);
};
