/**
 * Created by Denis Kuzin on 15 february 2022
 */

var ClansTopTab = cc.Node.extend({
    ctor: function (options) {
        this._super();

        this.setContentSize2(options.width, options.height);

        this.createTableBg();
        this.createTable();
    },

    createTableBg: function () {
        var styles = cleverapps.styles.ClansTopTab;

        var rect = cc.rectSubPadding(cc.rect(0, 0, this.width, this.height), cc.padding(styles.padding));

        var tableBg = this.tableBg = cleverapps.UI.createScale9Sprite(bundles.clan.frames.table_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        tableBg.setContentSize2(rect.width, rect.height);
        tableBg.setAnchorPoint(0, 0);
        tableBg.setPositionRound(rect.x, rect.y);
        this.addChild(tableBg);

        var foreground = cleverapps.UI.createScale9Sprite(bundles.clan.frames.table_fg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        foreground.setContentSize2(tableBg.width, tableBg.height);
        foreground.setAnchorPoint(0, 0);
        foreground.setPositionRound(0, 0);
        tableBg.addChild(foreground, 1);
    },

    createTable: function () {
        var styles = cleverapps.styles.ClansTopTab.table;

        var rect = cc.rectSubPadding(cc.rect(0, 0, this.tableBg.width, this.tableBg.height), cc.padding(styles.padding));

        var results = cleverapps.clans.listTop().map(function (clan, index) {
            return {
                id: clan.id,
                clan: clan,
                place: index + 1,
                player: clan.isUserClan(),
                emblem: clan.emblem,
                text: clan.name
            };
        });

        var table = new cleverapps.Table();
        var tableView = table.createTableView({
            id: "topClans",
            data: results,
            height: rect.height,
            bg: false,
            rowConstructor: ClansTopRow,
            innerPadding: styles.innerPadding
        });
        tableView.setPositionRound(rect.x + rect.width / 2, rect.y + rect.height / 2);
        this.tableBg.addChild(tableView);
    }
});

cleverapps.styles.ClansTopTab = {
    padding: {
        x: -30,
        top: -10,
        bottom: 0
    },

    table: {
        padding: {
            x: 0,
            top: 20,
            bottom: 20
        }
    }
};