/**
 * Created by razial on 28.12.2023
 */

GitManager.EDITORS = {
    location: {
        apply: function (data) {
            var content = data.content;

            var info = this.prepare(data);
            var file = info.file;

            cleverapps.git.urls[file] = content;

            var changes = {};
            changes[file] = {
                file: file,
                content: content
            };

            return changes;
        },

        load: function (file, data) {
            this.apply(data);
        },

        cache: function (data) {
            var info = this.prepare(data);
            var bundleName = info.bundleName;
            var file = info.file;
            var bundle = bundles[bundleName];
            var content = bundle.jsons.map_json;

            var cache = {
                bundleName: bundleName,
                map_json: JSON.parse(JSON.stringify(content))
            };

            var cached = {};
            cached[file] = cache;

            return cached;
        },

        restore: function (file, data) {
            if (data.map_json) {
                bundles[data.bundleName].jsons.map_json = data.map_json;
            }
        },

        prepare: function (data) {
            var bundleName = data.content.name || "location_" + data.content.expedition;
            var bundle = bundles[bundleName];
            var mapJson = bundle.jsons.map_json;
            var file = bundle.originalPaths.map_json;

            return {
                bundleName: bundleName,
                map_json: mapJson,
                file: file
            };
        }
    },

    episode: {
        apply: function (data) {
            var episodeNo = data.episodeNo;
            var language = data.language;
            var content = data.content;

            var info = this.prepare(data);
            var bundleName = info.bundleName;
            var levels = info.levels;
            var file = info.file;

            var bundle = bundles[bundleName];

            var processNew = !bundle;
            var processDelete = content === null;

            if (processNew) {
                bundle = bundles[bundleName] = {
                    frames: {},
                    jsons: { levels: levels },
                    urls: { },
                    originalPaths: {
                        levels: file
                    }
                };
            }

            if (processDelete) {
                delete bundles[bundleName];
                delete cleverapps.bundleLoader.handles[bundleName];
                delete cleverapps.git.urls[file];
            } else {
                content = content.levels ? content : { levels: content };

                var episodeLevels = content.levels.map(function (level) {
                    return Array.isArray(level) ? level[level.length - 1] : level;
                });

                bundle.meta = {
                    episode: {
                        episodeNo: episodeNo,
                        language: language || undefined,
                        levelsAmount: episodeLevels.length,
                        levels: episodeLevels.map(function (level) {
                            var enemies;

                            if (cleverapps.config.rpg && level.goals && level.goals.length) {
                                enemies = level.goals.map(function (goal) {
                                    return goal.type;
                                });
                            }

                            return {
                                tag: level.tag || undefined,
                                bundles: level.bundles && level.bundles.length && level.bundles || undefined,
                                units: level.units || undefined,
                                tiles: level.tiles || undefined,
                                expedition: level.expedition || undefined,
                                mapSize: level.map && {
                                    width: level.map[0].length,
                                    height: level.map.length
                                } || undefined,
                                enemies: enemies || undefined
                            };
                        })
                    } 
                };

                bundle.meta.stored = true;
                bundle.meta.debug = content.debug || undefined;

                var compact = Object.keys(content).filter(function (key) {
                    return content[key] !== undefined;
                }).length < 2;

                if (compact) {
                    content = content.levels;
                }

                cleverapps.git.urls[file] = content;

                if (processNew) {
                    bundles[bundleName] = new Bundle(bundleName, bundle);
                }
            }

            var changes = {};
            changes[file] = {
                episodeNo: episodeNo,
                language: language,
                content: content
            };

            return changes;
        },

        load: function (file, data) {
            this.apply(data);
        },

        cache: function (data) {
            var info = this.prepare(data);
            var bundleName = info.bundleName;
            var levels = info.levels;
            var file = info.file;

            var bundle = bundles[bundleName];
            var content = cc.loader.cache[levels];

            var cache = {
                bundleName: bundleName,
                bundleContent: bundle ? bundle.save() : null,
                levelsUrl: levels,
                levelsContent: content ? JSON.parse(JSON.stringify(content)) : undefined
            };

            if (bundle && !cleverapps.bundleLoader.isLoaded(bundleName)) {
                cleverapps.bundleLoader.loadBundle(bundleName, {
                    onSuccess: function () {
                        var newBundle = bundles[bundleName];
                        var newContent = newBundle && cc.loader.cache[newBundle.jsons.levels];
                        if (newContent) {
                            cache.levelsContent = JSON.parse(JSON.stringify(newContent));
                        }
                    }
                });
            }

            var cached = {};
            cached[file] = cache;

            return cached;
        },

        restore: function (file, data) {
            if (data.bundleContent === null) {
                delete bundles[data.bundleName];
            } else {
                bundles[data.bundleName].parse(data.bundleContent);
            }

            cc.loader.cache[data.levelsUrl] = data.levelsContent;
            delete cleverapps.git.urls[data.levelsUrl];
        },

        prepare: function (data) {
            var episodeNo = data.episodeNo;
            var language = data.language;

            var bundleName = AdminEpisode.BundleId(episodeNo, language);
            var bundle = bundles[bundleName];

            var levels, file;

            if (!bundle) {
                levels = bundleName + "#levels";

                file = bundles.episode_0.jsons.levels.originalPath()
                    .replace("/levels/en/", "/levels/" + language + "/")
                    .replace("/0.json", "/" + episodeNo + ".json");
            } else {
                levels = bundle.jsons.levels;
                file = levels.originalPath();
            }

            if (!file) {
                throw "Can't find file path for episode " + episodeNo + " json";
            }

            return {
                bundleName: bundleName,
                levels: levels,
                file: file
            };
        },

        getValidateError: function (data) {
            var errors = [];

            for (var i = 0; i < data.length; i++) {
                var level = new AdminLevel(data[i]);

                if (!(/\d+/.test(level.episodeNo))) {
                    return;
                }

                var err = level.getValidateError();

                if (err) {
                    errors.push("Episode " + level.episodeNo + ", Level " + level.getHumanNumber() + ":\n" + err);
                }
            }

            return errors.length > 0 ? errors : undefined;
        }
    },

    anchors: {
        apply: function (data) {
            var stage = data.stage;
            var urls = data.urls;

            var bundle = bundles[urls[stage].bundle];
            var res = bundle.jsons[urls[stage].key] || bundle.frames[urls[stage].key];

            bundle.meta = bundle.meta || {};
            bundle.meta.anchors = bundle.meta.anchors || {};
            bundle.meta.anchors[res.key] = bundle.meta.anchors[res.key] || {};
            bundle.meta.anchors[res.key].x = data.anchorX;
            bundle.meta.anchors[res.key].y = data.anchorY;

            var info = this.prepare(data);

            var changes = {};
            changes[info.file] = {
                stage: data.stage,
                urls: data.urls,
                content: info.content
            };

            return changes;
        },

        load: function (file, data) {
            this.restore(file, data);
        },

        cache: function (data) {
            var info = this.prepare(data);

            var changes = {};
            changes[info.file] = {
                stage: data.stage,
                urls: data.urls,
                content: info.content
            };

            return changes;
        },

        restore: function (file, data) {
            var urls = data.urls;
            var content = data.content;

            urls.forEach(function (url, stage) {
                var bundle = bundles[url.bundle];
                var res = bundle.jsons[url.key] || bundle.frames[url.key];

                var anchors = content["stage" + (stage + 1)];

                if (anchors) {
                    bundle.meta = bundle.meta || {};
                    bundle.meta.anchors = bundle.meta.anchors || {};
                    bundle.meta.anchors[res.key] = bundle.meta.anchors[res.key] || {};
                    bundle.meta.anchors[res.key].x = anchors.x;
                    bundle.meta.anchors[res.key].y = anchors.y;
                }
            });
        },

        prepare: function (data) {
            var stage = data.stage;
            var urls = data.urls;

            var bundle = bundles[urls[stage].bundle];
            var res = bundle.jsons[urls[stage].key] || bundle.frames[urls[stage].key];

            var file = res.originalPath().replace(/\/stage.*/, "/anchors.json");
            var content = {};

            urls.forEach(function (url, stage) {
                var bundle = bundles[url.bundle];
                var res = bundle.jsons[url.key] || bundle.frames[url.key];
                var stageFile = res.originalPath().replace(/\/stage.*/, "/anchors.json");

                if (stageFile === file) {
                    bundle.meta = bundle.meta || {};
                    bundle.meta.anchors = bundle.meta.anchors || {};
                    bundle.meta.anchors[res.key] = bundle.meta.anchors[res.key] || {};

                    if (bundle.meta.anchors[res.key].x === undefined) {
                        bundle.meta.anchors[res.key].x = 0.5;
                    }

                    if (bundle.meta.anchors[res.key].y === undefined) {
                        bundle.meta.anchors[res.key].y = 0.5;
                    }

                    content["stage" + (stage + 1)] = cleverapps.clone(bundle.meta.anchors[res.key], true);
                }
            });

            return {
                file: file,
                content: content
            };
        }
    },

    scenario: {
        apply: function (data) {
            var file = data.file;
            var content = data.content;

            cleverapps.git.urls[file] = content;

            var changes = {};
            changes[file] = {
                file: file,
                content: content
            };

            return changes;
        },

        load: function (file, data) {
            if (data.content === null) {
                delete cleverapps.git.urls[file];
            } else {
                cleverapps.git.urls[file] = data.content;
            }
        },

        cache: function (data) {
            var cached = {};
            cached[data.file] = {};

            return cached;
        },

        restore: function (file) {
            delete cleverapps.git.urls[file];
        }
    }
};
