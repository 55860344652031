/**
 * Created by Denis Kuzin on 01 march 2023
 */

QuestsConfig.dragonia2 = {
    dr2blacksmith_open: {
        trigger: { fog: "fog1" },
        dynamic: {
            getTitle: function () {
                return Messages.get("Quest.find.title", {
                    name: cleverapps.unitsLibrary.getUnitName({ code: "dr2blacksmith", stage: 0 })
                });
            },
            getIcon: function () {
                return UnitView.getUnitImage({ code: "dr2blacksmith", stage: 0 }, { alignAnchorX: true, preferStatic: true });
            }
        },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: {
                code: "dr2blacksmith",
                stage: 0
            }
        }
    },
    dr2treasuremap_0: {
        trigger: { unit: { code: "dr2blacksmith", stage: 0 } },
        dynamic: {
            getTitle: function () {
                return Messages.get("Quest.buyTreasureMap.title");
            },
            getIcon: function () {
                return UnitView.getUnitImage({ code: "dr2treasuremap", stage: 0 }, { alignAnchorX: true });
            }
        },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "dr2treasuremap", stage: 0 }
        }
    },
    treasureisland: {
        trigger: { unit: { code: "dr2treasuremap", stage: 0 } },
        quest: {
            type: Map2d.OPENFOG,
            target: "dr2FogIsland"
        }
    },
    dr2airship_open: {
        trigger: { unit: { code: "dr2treasuremap", stage: 0 } },
        tutorial: "treasureisland_0",
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: {
                code: "dr2airship",
                stage: 0
            }
        }
    }
};