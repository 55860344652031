/**
 * Created by iamso on 18.05.21
 */

var BarrelPlanner = function () {
    if (!Map2d.currentMap.regions.barrel) {
        return;
    }

    if (cleverapps.config.editorMode || cleverapps.config.adminMode || cleverapps.config.wysiwygMode) {
        return;
    }

    this.load();

    cleverapps.rewardedAdsManager.onRewardedRefresh(this.changeRewardState.bind(this), this);

    if (cleverapps.silentIntro) {
        this.changeRewardState();
    } else {
        this.timeout = setTimeout(this.send.bind(this), BarrelPlanner.FIRST_CHECK_INTERVAL);
    }
};

BarrelPlanner.prototype.changeRewardState = function () {
    if (cleverapps.rewardedAdsManager.isRewardedAvailable()) {
        this.send();
        return;
    }

    if (this.barrel && !this.barrel.playingVideo && !this.barrel.prizes) {
        this.barrel.remove();
    }
};

BarrelPlanner.prototype.destructor = function () {
    runCleaners(this);
    this.clearTimeout();
};

BarrelPlanner.prototype.clearTimeout = function () {
    if (this.timeout !== undefined) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    }
};

BarrelPlanner.prototype.save = function () {
    var data = [];

    if (this.barrel && this.barrel.prizes) {
        data.push(this.barrel.getSaveData());
    }

    cleverapps.dataLoader.save(DataLoader.TYPES.BARREL_PLANNER, data);
};

BarrelPlanner.prototype.load = function () {
    var stored = cleverapps.dataLoader.load(DataLoader.TYPES.BARREL_PLANNER);

    if (!Array.isArray(stored)) {
        return;
    }

    stored.forEach(function (data) {
        if (!data) {
            return;
        }

        this.spawn({
            stage: data.stage,
            prizes: data.prizes
        });
    }, this);
};

BarrelPlanner.prototype.send = function () {
    this.clearTimeout();
    this.timeout = setTimeout(this.send.bind(this), BarrelPlanner.CHECK_INTERVAL);

    if (cleverapps.adsLimits.state(AdsLimits.TYPES.BARREL) === AdsLimits.STATE_READY) {
        this.spawn();
    }
};

BarrelPlanner.prototype.spawn = function (stored) {
    if (this.barrel) {
        return;
    }

    var stage = Math.floor(Math.random() * Families.barrel.units.length);
    if (stored && stored.stage < Families.barrel.units.length) {
        stage = stored.stage;
    }

    var unit = new Unit({ code: "barrel", stage: stage });
    var barrel = unit.findComponent(Barrel);
    if (stored) {
        barrel.restorePrizes(stored.prizes);
    }
    barrel.spawn();

    this.barrel = barrel;

    barrel.onRemove = function () {
        this.barrel = undefined;
        this.save();
    }.bind(this);

    barrel.onReward = function () {
        this.save();
    }.bind(this);

    barrel.onPrizeChanged = function () {
        this.save();
    }.bind(this);
};

BarrelPlanner.FIRST_CHECK_INTERVAL = cleverapps.parseInterval("15 seconds");
BarrelPlanner.CHECK_INTERVAL = cleverapps.parseInterval("1 minute");
