/**
 * Created by vtbelo on 31/03/21
 */

var TileModel = function (product) {
    this.product = product;
    this.onUpdateTileModel = function () {};
    this.onGetView = function () {};
    this.onBuyVideoProduct = function () {};
    this.onUpdateAvailability = function () {};

    cleverapps.focusManager.showControlsWhileFocused(this.getCurrencyControl());
    if (this.product.videoProduct) {
        cleverapps.rewardedAdsManager.onRewardedRefresh(this.updateAvailability.bind(this), this);
    } else {
        cleverapps.paymentsLogic.onChangeStatus(this.updateAvailability.bind(this), this);
    }
};

TileModel.prototype.onExit = function () {
    runCleaners(this);
};

TileModel.prototype.getCurrencyControl = function () {
    if (this.product.currency === "hard") {
        return "MenuBarGoldItem";
    }
    if (this.product.currency === "soft") {
        return "MenuBarCoinsItem";
    }
    return [];
};

TileModel.prototype.currentVideoProgress = function () {
    return cleverapps.dataLoader.load(DataLoader.TYPES.PRODUCT_VIDEO_PROGRESS + this.product.itemId) || 0;
};

TileModel.clearVideoProgress = function (product) {
    return cleverapps.dataLoader.remove(DataLoader.TYPES.PRODUCT_VIDEO_PROGRESS + product.itemId);
};

TileModel.prototype.updateModelState = function () {
    this.onUpdateTileModel();
};

TileModel.prototype.updateAvailability = function () {
    this.onUpdateAvailability();
};

TileModel.prototype.getCurrentPrice = function () {
    if (this.product.videoProduct) {
        return "##" + this.currentVideoProgress() + "/" + this.product.videoPrice;
    }
    return this.product.getCurrentPrice();
};

TileModel.prototype.isAttention = function () {
    return this.isAvailable() && this.product.attention;
};

TileModel.prototype.getTitle = function () {
    return this.product.title;
};

TileModel.prototype.getDescription = function () {
    return this.product.description;
};

TileModel.prototype.getPrice = function () {
    return this.product.price;
};

TileModel.prototype.getItemId = function () {
    return this.product.itemId;
};

TileModel.prototype.getIcon = function () {
    return this.product.getIcon();
};

TileModel.prototype.getViewClassName = function () {
    return this.product.ViewClassName || (this.product.getViewClassName && this.product.getViewClassName()) || CoinsProductTile;
};

TileModel.prototype.getProductDescription = function () {
};

TileModel.prototype.getProductLimit = function () {
};

TileModel.prototype.getRewardsIcon = function () {
    return cleverapps.skins.getSlot(this.product.rewardsIcon) || bundles.reward_icons.frames[this.product.rewardsIcon];
};

TileModel.prototype.getReward = function () {
    return this.product.reward;
};

TileModel.prototype.getButtonText = function () {
    if (this.getPrice() === 0) {
        return "FREE";
    }

    return this.getCurrentPrice();
};

TileModel.prototype.isEnabled = function () {
    if (this.product.itemId === "unlimitedLives") {
        return cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_REGULAR;
    }
    if (this.product.itemId === "energyLottery") {
        return Game.currentGame.energyLottery !== undefined;
    }

    return true;
};

TileModel.prototype.checkDisabledProducts = function () {
    if (this.product.disableProductIds) {
        for (var i = 0; i < this.product.disableProductIds.length; ++i) {
            var disableTile = TileModel.Create(Product.CreateById(this.product.disableProductIds[i]));
            if (disableTile.isEnabled() && disableTile.isAvailable()) {
                return false;
            }
        }
    }
    return true;
};

TileModel.prototype.buyVideoProduct = function (closeShopCallback) {
    var adLimit = AdsLimits.TYPES.PRODUCT;
    if (typeof UnitsShopTileModel !== "undefined" && this instanceof UnitsShopTileModel) {
        adLimit = AdsLimits.TYPES.CINEMA;
    }
    cleverapps.rewardedAdsManager.playRewarded(adLimit, function () {
        this.incVideoProduct(closeShopCallback);
    }.bind(this));
};

TileModel.prototype.incVideoProduct = function (closeShopCallback) {
    var amount = this.currentVideoProgress();
    amount++;
    if (amount >= this.product.videoPrice) {
        amount = 0;

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.VIDEO_PRODUCT_BUY);
        if (this.product.onBuyed) {
            this.product.onBuyed();
        }
        closeShopCallback();
    }
    cleverapps.dataLoader.save(DataLoader.TYPES.PRODUCT_VIDEO_PROGRESS + this.product.itemId, amount);

    this.onBuyVideoProduct();
};

TileModel.prototype.buy = function (shopCallback) {
    if (this.product.videoProduct) {
        this.buyVideoProduct(shopCallback);

        return;
    }
    return this.product.buy(function (success) {
        if (success) {
            this.product.logBuyItem();

            shopCallback();
        }
    }.bind(this));
};

TileModel.prototype.isAvailable = function () {
    if (this.product.videoProduct) {
        return cleverapps.rewardedAdsManager.isRewardedAvailable();
    }

    if (this.product instanceof VirtualProduct) {
        return true;
    }

    return connector.payments.isConnected();
};

TileModel.prototype.wantsScroll = function () {
    return this.badge;
};

TileModel.prototype.getBadge = function () {
    if (cleverapps.clans && Clans.IsAvailable() && this.product.clanGift && !this.product.videoProduct) {
        if (!(this instanceof SubscriptionTileModel && cleverapps.subscription.isActive())
            && !(this instanceof GrowthFundTileModel && cleverapps.growthFund && cleverapps.growthFund.isBought())) {
            return {
                icon: new cc.Sprite(bundles.reward_icons.frames.clan_gift_png),
                tooltipText: "ClanGift.Text"
            };
        }
    }

    if (this.badge) {
        return this.badge;
    }

    if (this.product.discount) {
        return {
            type: "discount",
            text: this.product.discount
        };
    }

    if (this.product.value) {
        return {
            type: "value",
            value: this.product.value
        };
    }

    if (this.product.badge) {
        return {
            text: this.product.badge
        };
    }
};

TileModel.prototype.getView = function () {
    return this.onGetView();
};

TileModel.Create = function (product) {
    var TileModelClass = TileModel;
    if (product.TileModelClass || product.getTileModelClass) {
        TileModelClass = product.TileModelClass || product.getTileModelClass();
    }
    return new TileModelClass(product);
};
