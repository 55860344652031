/**
 * Created by Denis Kuzin on 01 march 2023
 */

QuestsConfig.dragonia3 = {
    drproducer1a_0: {
        trigger: { fog: "fog2" },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: {
                code: "drproducer1a",
                stage: 0
            }
        }
    },

    drproduct0a_0: {
        trigger: { unit: { code: "drproducer1a", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: {
                code: "drproduct0a",
                stage: 0
            }
        }
    },

    drproduct1a_0: {
        trigger: { unit: { code: "drproducer1a", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: {
                code: "drproduct1a",
                stage: 0
            }
        }
    },

    drcustomermain3_1: {
        trigger: { unit: { code: "drcustomermain3", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "drcustomermain3", stage: 1 }
        }
    },

    drproducer2a_0: {
        trigger: { unit: { code: "drproduct1a", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: {
                code: "drproducer2a",
                stage: 0
            }
        }
    },

    drproducer1b_0: {
        trigger: { fog: "fog14" },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: {
                code: "drproducer1b",
                stage: 0
            }
        }
    },

    drproduct0b_0: {
        trigger: { unit: { code: "drproducer1b", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: {
                code: "drproduct0b",
                stage: 0
            }
        }
    },

    drproduct1b_0: {
        trigger: { unit: { code: "drproducer1b", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: {
                code: "drproduct1b",
                stage: 0
            }
        }
    },

    drproducer2b_0: {
        trigger: { unit: { code: "drproduct1b", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: {
                code: "drproducer2b",
                stage: 0
            }
        }
    }
};
