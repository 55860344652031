/**
 * Created by Denis Kuzin on 12 october 2022
 */

var PixelsPlannerView = cc.Node.extend({
    ctor: function (pixelsPlanner) {
        this._super();

        this.pixelsPlanner = pixelsPlanner;

        this.pixelsPlanner.onCreatePixel = this.createListener(this.createPixelView.bind(this));
        this.pixelsPlanner.onCreatePixelOffer = this.createListener(this.createPixelOfferView.bind(this));

        this.setLocalZOrder(100000);
    },

    createPixelView: function (pixel) {
        var pixelView = new PixelView(pixel);
        this.addChild(pixelView);
    },

    createPixelOfferView: function (pixelOffer) {
        var pixelOfferView = new PixelOfferView(pixelOffer);
        this.addChild(pixelOfferView);
    }
});