/**
 * Created by andrey on 27.11.2020.
 */

var AdminDebugButton = cleverapps.UI.Button.extend({
    ctor: function () {
        var styles = cleverapps.styles.AdminDebugButton;

        this.icon = new cc.Sprite(bundles.admin.frames.release_icon);

        this._super({
            type: cleverapps.styles.UI.Button.Images.editor,
            content: this.icon,
            width: styles.width,
            height: styles.height,
            onClicked: this.onPressed.bind(this),
            hint: "Toggle debug"
        });

        this.adminLevels = cleverapps.administrator.adminLevels;
        this.adminEpisodes = cleverapps.administrator.adminEpisodes;

        this.adminEpisodes.on("updateApproved", this.update.bind(this), this);
        this.adminEpisodes.on("setActive", this.update.bind(this), this);
        this.adminLevels.on("reset", this.update.bind(this), this);
        this.update();
    },

    onPressed: function () {
        cleverapps.focusManager.display({
            focus: "ToggleDebug",
            action: function (f) {
                var bundle = this.adminEpisodes.getActive().getBundle();

                cleverapps.administrator.adminEpisodes.setDebug(!bundle.meta.debug, function () {
                    this.update();

                    f();
                }.bind(this));
            }.bind(this)
        });
    },

    update: function () {
        var adminEpisode = this.adminEpisodes.getActive();
        if (!adminEpisode || !adminEpisode.getBundle()) {
            this.setVisible(false);
            return;
        }

        this.setVisible(true);

        this.icon.setSpriteFrame(adminEpisode.isDebug() ? bundles.admin.frames.debug_icon : bundles.admin.frames.release_icon);

        this.setRecCascadeColorEnabled();
        this.setColor(!adminEpisode.isDebug() && !adminEpisode.isApproved() ? cc.color(255, 0, 0, 255) : cc.color(255, 255, 255, 255));

        if (adminEpisode.isDebug() && !adminEpisode.isApproved()) {
            this.disable();
        } else {
            this.enable();
        }
    }
});

cleverapps.styles.AdminDebugButton = {
    width: 45,
    height: 45
};
