/**
 * Created by r4zi4l on 21.06.2021
 */

var ChestTimeMissionWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        this.mission = mission;
        this.pack = ChestTimeMissionWindow.PACK;

        this._super({
            name: "ChestTimeMissionWindow",
            content: this.createContent(),
            title: "ChestTimeMissionWindow.title",
            noBackground: true,
            contentPadding: cleverapps.styles.ChestTimeMissionWindow.contentPadding,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true,
            openSound: bundles.chesttime.urls.gin_energy_time
        });

        if (!cleverapps.UI.isNodeOnScene(this.packView, 0.7)) {
            this.packView.removeFromParent();
        }
    },

    getPerson: function () {
        return cleverapps.config.name === "mergecraft" ? "jihn" : "worker";
    },

    createContent: function () {
        var styles = cleverapps.styles.ChestTimeMissionWindow;

        var container = new cc.Node();
        container.setContentSize2(styles);
        container.setAnchorPoint(0.5, 0.5);

        var animation = new cleverapps.Spine(bundles.chesttime.jsons.energy_animation);
        animation.setAnchorPoint(0.5, 0.5);
        animation.setPositionRound(styles.animation);
        animation.setAnimation(0, "animation", true);
        container.addChild(animation);

        var description = cleverapps.UI.generateOnlyText("ChestTimeMissionWindow.description", cleverapps.styles.FONTS.CHESTTIMEMISSIONWINDOW_DESCRIPTION_TEXT);
        description.setAnchorPoint(0.5, 0.5);
        description.setPositionRound(styles.description);
        description.setDimensions(styles.description.width, 0);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        container.addChild(description);

        var pack = this.packView = this.createPack();
        cleverapps.UI.wrap(pack);
        pack.setPositionRound(styles.pack);
        container.addChild(pack);

        return container;
    },

    createPack: function () {
        var styles = cleverapps.styles.ChestTimeMissionWindow.pack;

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);

        var title = cleverapps.UI.generateOnlyText("ChestTimeMissionWindow.packTitle", cleverapps.styles.FONTS.CHESTTIMEMISSIONWINDOW_TITLE_TEXT);
        title.fitTo(styles.title.text.width);
        title.setPositionRound(styles.title.text);
        title.setLocalZOrder(1);

        if (styles.title.noBg) {
            container.addChild(title);
        } else {
            var ribbon = new cc.Sprite(bundles.chesttime.frames.purple_ribbon);
            container.addChild(ribbon);
            ribbon.setPositionRound(styles.title);
            ribbon.addChild(title);
        }

        if (bundles.chesttime.jsons.energy_pack) {
            var energyPack = new cleverapps.Spine(bundles.chesttime.jsons.energy_pack);
            energyPack.setAnimation(0, "animation", true);
            container.addChild(energyPack);
        } else if (bundles.chesttime.frames.energy_pack) {
            energyPack = new cc.Sprite(bundles.chesttime.frames.energy_pack);
            container.addChild(energyPack);
        }

        var icon = new cc.Sprite(bundles.reward_icons.frames.treasure_chest_png);
        icon.setScale(styles.energyReward.icon.scale);

        var amount = cleverapps.UI.generateImageText("x" + this.pack.prizes.unit[0].amount, cleverapps.styles.FONTS.CHESTTIMEMISSIONWINDOW_REWARD_TEXT);

        var energyReward = new cleverapps.Layout([icon, amount], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        energyReward.setPositionRound(styles.energyReward);
        container.addChild(energyReward);

        var buyPackButton = new UseGoldButton({
            price: this.pack.price,
            eventName: cleverapps.EVENTS.SPENT.OFFER + "_" + this.mission.getName(),
            onClicked: this.buyPackProduct.bind(this),
            filter: this.canBuyProduct.bind(this),
            width: styles.buyPackButton.width,
            height: styles.buyPackButton.height
        });
        buyPackButton.setAnchorPoint(0.5, 0.5);
        buyPackButton.setPositionRound(styles.buyPackButton);
        container.addChild(buyPackButton);

        return container;
    },

    canBuyProduct: function () {
        if (this.pack.prizes.unit) {
            var items = this.pack.prizes.unit.reduce(function (sum, reward) {
                return sum + (reward.amount || 0);
            }, 0);

            var notEnough = items - Map2d.currentMap.countEmptySlots();
            if (notEnough > 0) {
                cleverapps.centerHint.createTextHint("Spawn.nospace", { left: notEnough });
                return false;
            }
        }
        return true;
    },

    buyPackProduct: function () {
        new RewardWindow(this.pack.prizes);
        this.close();
    },

    listBundles: function () {
        return ["chesttime"];
    }
});

ChestTimeMissionWindow.PACK = {
    price: 225,
    prizes: {
        unit: [
            { code: "treasure", stage: 2, amount: 3 }
        ]
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CHESTTIMEMISSIONWINDOW_TITLE_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    CHESTTIMEMISSIONWINDOW_DESCRIPTION_TEXT: {
        size: 42,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    CHESTTIMEMISSIONWINDOW_REWARD_TEXT: {
        size: 50,
        name: "default"
    }
});

cleverapps.styles.ChestTimeMissionWindow = {
    width: 1200,
    height: 550,

    contentPadding: {
        left: 200,
        right: 200
    },

    animation: {
        x: { align: "center", dx: 20 },
        y: { align: "center", dy: 0 }
    },

    description: {
        x: { align: "center" },
        y: { align: "center", dy: -450 },
        width: 1200
    },

    pack: {
        margin: 10,
        x: { align: "center", dx: 580 },
        y: { align: "center" },

        title: {
            x: { align: "center" },
            y: { align: "center", dy: 175 },
            text: {
                x: { align: "center" },
                y: { align: "center", dy: 17 },
                width: 280
            }
        },

        energyReward: {
            x: { align: "center" },
            y: { align: "center", dy: -142 },

            icon: {
                scale: 1.0
            }
        },

        buyPackButton: {
            x: { align: "center" },
            y: { align: "center", dy: -250 },
            width: 230,
            height: 100
        }
    }
};
