/**
 * Created by r4zi4l on 21.12.2021
 */

var RudolfGuideOptions = {
    name: "RudolfGuideWindow",
    title: "RudolfGuideWindow.title",
    description: "RudolfGuideWindow.description",

    getGuideBundle: function () {
        return cleverapps.skins.getSlot("rudolfGuideBundle");
    },

    stagesContent: function () {
        var styles = cleverapps.styles.RudolfGuideWindow;

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.gifts, styles.gifts);
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.rudolf, styles.rudolf);
        }.bind(this));

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            this.addSprite(container, this.bundle.frames.prizes_heap, styles.heap);
        }.bind(this));

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.RudolfGuideWindow = {
    description: {
        width: 800,
        height: 0
    },

    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        },

        gifts: {
            x: 0,
            y: -30
        }
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        },

        rudolf: {
            x: 0,
            y: 0
        }
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        },

        heap: {
            x: 0,
            y: 0
        }
    }
};
