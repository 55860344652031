/**
 * Created by Denis Kuzin on 25 february 2022
 */

var Clans = function () {
    this.top = [];
    this.list = [];

    this.onClansListRefreshed = function () {};
};

Clans.prototype.updateTop = function (onSuccess, onFailure) {
    if (cleverapps.flags.norest) {
        onSuccess();
        return;
    }

    cleverapps.RestClient.get("/clans/listtop", {}, function (data) {
        this.top = data.map(function (info) {
            return new Clan(info);
        });

        onSuccess();
    }.bind(this), onFailure);
};

Clans.prototype.listTop = function () {
    var founded = false;

    for (var index = 0; index < this.top.length; index++) {
        if (this.top[index].isUserClan()) {
            this.top[index] = cleverapps.userClan;
            founded = true;
        }
    }

    if (!founded && cleverapps.userClan.id !== undefined) {
        this.top.push(cleverapps.userClan);
    }

    this.top = this.top.sort(function (a, b) {
        return b.rating - a.rating;
    });

    return this.top;
};

Clans.prototype.updateList = function (name, onSuccess, onFailure) {
    if (cleverapps.flags.norest) {
        onSuccess();
        return;
    }
    cleverapps.RestClient.post("/clans/list", { name: name }, function (data) {
        this.list = data.map(function (info) {
            return new Clan(info);
        });
        onSuccess();
        this.onClansListRefreshed();
    }.bind(this), onFailure);
};

Clans.prototype.listClans = function () {
    return this.list;
};

Clans.prototype.search = function (name, onSuccess) {
    WaitWindow.show();

    var waiter = cleverapps.wait(2, function () {
        WaitWindow.hide();
        onSuccess && onSuccess();
    });

    setTimeout(waiter, 1000);

    this.updateList(name, waiter, waiter);
};

Clans.prototype.create = function (options) {
    var action = "create";
    var price = ClansConfig.CREATION_PRICE;

    if (options.clanId) {
        action = "edit";
        price = ClansConfig.EDITING_PRICE;
    }

    if (!cleverapps.user.canTakeHard(price)) {
        cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.CLAN, price);
        return;
    }

    WaitWindow.show();

    var onFailure = function (error) {
        WaitWindow.hide();
        new NoConnectionWindow();
        console.log(error);
    };

    cleverapps.RestClient.post("/clans/" + action, {
        userId: connector.platform.getUserID(),
        name: options.name,
        emblem: options.emblem,
        rating: cleverapps.gameLevel.getLevel(),
        requiredLevel: options.requiredLevel
    }, function (data) {
        if (data.error) {
            if (data.error.code === "ER_DUP_ENTRY") {
                cleverapps.notification.create("ClanErrors.duplicateNames");
                WaitWindow.hide();
                return;
            }
            onFailure(data.error);
            return;
        }

        WaitWindow.hide();

        cleverapps.userClan.update(data);

        new MyClanWindow();
        cleverapps.windows.closeBottomWindows();

        cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.CLAN, price);
    }, onFailure);
};

Clans.prototype.addUserToClan = function (userId, clanId, onSuccess, onError) {
    WaitWindow.show();

    cleverapps.RestClient.post("/clans/join", {
        userId: userId,
        clanId: clanId,
        userRating: cleverapps.gameLevel.getLevel()
    }, onSuccess, onError);
};

Clans.prototype.join = function (clanId) {
    this.addUserToClan(connector.platform.getUserID(), clanId, function (data) {
        WaitWindow.hide();

        if (data.error) {
            cleverapps.notification.create(data.error);
        } else {
            cleverapps.userClan.update(data);

            new MyClanWindow();
            cleverapps.windows.closeBottomWindows();
        }
    }, function (err) {
        WaitWindow.hide();
        new NoConnectionWindow();
        console.log(err);
    });
};

Clans.prototype.leave = function () {
    WaitWindow.show();

    cleverapps.RestClient.post("/clans/leave", {
        userId: connector.platform.getUserID(),
        clanId: cleverapps.userClan.id
    }, function () {
        WaitWindow.hide();

        cleverapps.userClan.update({});

        cleverapps.windows.closeBottomWindows();
        cleverapps.windows.currentWindow().close();
    }, function (err) {
        WaitWindow.hide();
        new NoConnectionWindow();
        console.log(err);
    });
};

Clans.prototype.autofit = function () {
    WaitWindow.show();

    cleverapps.RestClient.post("/clans/autofit/", {
        userRating: cleverapps.gameLevel.getLevel()
    }, function (data) {
        WaitWindow.hide();

        if (data.error) {
            cleverapps.notification.create(data.error);
        } else {
            this.join(data.clanId);
        }
    }.bind(this), function () {
        WaitWindow.hide();
    });
};

Clans.prototype.getUserRole = function () {
    cleverapps.RestClient.get("/userclan/" + encodeURIComponent(connector.platform.getUserID()), {
        userId: connector.platform.getUserID()
    }, function (data) {
        return data;
    });
};

Clans.prototype.sendGift = function (gift) {
    cleverapps.RestClient.post("/clans/gift", {
        userId: connector.platform.getUserID(),
        clanId: cleverapps.userClan.id,
        data: gift
    }, function (data) {
        if (data.error) {
            cleverapps.notification.create(data.error);
        } else {
            cleverapps.clanMessages.isGiftSent = true;
            cleverapps.toolbar.resetByType(ToolbarItem.CLANS);
        }
    }, function () {
        cleverapps.notification.create("ClanErrors.connectionError");
    });
};

Clans.IsAvailable = function () {
    if (cleverapps.gameModes.clans) {
        return true;
    }

    var isCheater = cleverapps.user.level >= 100 && !cleverapps.isLocalhost();
    return !isCheater && cleverapps.userClan && cleverapps.userClan.id !== undefined && levels.user.checkAvailable(cleverapps.Availables.CLANS);
};
