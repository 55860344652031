/**
 * Created by Denis Kuzin on 05 april 2022
 */

var ClanMessageRow = cc.Node.extend({
    ctor: function (message) {
        this._super();
        this.message = message;

        var styles = cleverapps.styles.ClanMessageRow;
        var phrase = this.message.getText();

        var messageNode = this.messageNode = new cc.Node();
        messageNode.setAnchorPoint(0.5, 0.5);

        var messageTitle = this.createTitle(this.message.getPlayerAvatar(), this.message.getPlayerName(), this.message.type);

        var content;

        if (this.message.isHelpMessage()) {
            if (this.message.isUserMessage()) {
                content = this.createContentToReceiveHelp();
            } else {
                content = this.createContentToSendHelp();
            }
        } else if (this.message.isGiftMessage()) {
            if (this.message.isUserMessage()) {
                content = this.createContentAboutSentGift();
            } else {
                content = this.createContentToGetGift();
            }
        } else {
            content = cleverapps.UI.generateOnlyText(phrase, this.message.isUserMessage() ? cleverapps.styles.FONTS.CLAN_CHAT_PHRASE : cleverapps.styles.FONTS.CLAN_CHAT_TEAMMATE_PHRASE);
        }

        this.updateHelp(true);
        this.updateGift();

        var width = styles.helpRowWidth;
        if (!this.message.isHelpMessage()) {
            width = styles.maxRowWidth;
            var maxWidth = Math.max(content.width, messageTitle.width) + styles.deltaWidth;
            if (maxWidth < width) {
                width = maxWidth;
            }
        }

        var messageTitleBg = cleverapps.UI.createScale9Sprite(
            this.message.isHelpMessage() || this.message.isGiftMessage()
                ? bundles.my_clan_window.frames.massage_green_title_bg
                : bundles.my_clan_window.frames.massage_blue_title_bg,
            cleverapps.UI.Scale9Rect.TwoPixelXY
        );
        messageTitleBg.setContentSize2(width, styles.title.bg.height);
        messageTitleBg.setPositionRound(styles.title.bg);
        messageNode.addChild(messageTitleBg, 1);

        messageTitle.setPositionRound(styles.title);
        messageTitleBg.addChild(messageTitle);

        if (this.message.isHelpMessage() || this.message.isGiftMessage()) {
            var timeLeft = this.message.getTimeLeft();
            if (timeLeft > 0) {
                timeLeft = new cleverapps.CountDown(timeLeft, {
                    onFinish: function () {
                        cleverapps.clanMessages.onMessagesUpdated();
                    }
                });
            }

            var timer = new cleverapps.CountDownView(timeLeft, {
                font: cleverapps.styles.FONTS.CLANS_CHAT_TIMER_TEXT
            });
            messageTitleBg.addChild(timer);
            timer.setPositionRound(styles.title.timer);
        }

        var messageContentBg = cleverapps.UI.createScale9Sprite(bundles.my_clan_window.frames[this.message.isUserMessage() ? "massage_content_orange_bg" : "massage_content_bg"], cleverapps.UI.Scale9Rect.TwoPixelXY);
        messageContentBg.setContentSize2(width, content.height + styles.content.bg.paddingY);
        messageContentBg.setPositionRound(styles.content.bg);
        messageNode.addChild(messageContentBg);

        cleverapps.UI.fitToBox(content, {
            width: messageContentBg.width - styles.deltaWidth
        });
        messageContentBg.addChild(content);
        content.setPositionRound(phrase ? styles.phrase : styles.content);

        cleverapps.UI.wrap(messageNode);

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles.width, messageNode.height);
        this.addChild(messageNode);
        if (this.message.isUserMessage()) {
            messageNode.setAnchorPoint(1, 0);
            messageNode.setPositionRound(styles.right);
        } else {
            messageNode.setAnchorPoint(0, 0);
            messageNode.setPositionRound(styles.left);
        }

        this.addArrow(messageNode);

        this.message.onHelpUpdated = this.createListener(this.updateHelp.bind(this));
        this.message.onGiftUpdated = this.createListener(this.updateGift.bind(this));
        this.message.onMessagePosted = this.createListener(this.received.bind(this));

        if (this.message.isFresh()) {
            this.messageNode.setCascadeOpacityEnabledRecursively(true);
            this.messageNode.setOpacity(160);
            this.messageNode.setScale(0);
            this.messageNode.runAction(new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut()));
        }
    },

    received: function () {
        this.messageNode.runAction(new cc.FadeTo(0.15, 255));
    },

    updateHelp: function (silent) {
        if (this.message.isHelpMessage()) {
            this.updateProgressBar(silent);

            if (!this.message.isUserMessage()) {
                if (this.message.canHelp()) {
                    this.sendHelpButton.enable();
                } else {
                    this.sendHelpButton.disable();
                }
            } else {
                this.helpButtons.forEach(function (button) {
                    if (button.help.status === ClansConfig.HELP_READY) {
                        button.enable();
                    } else {
                        button.disable();
                    }
                });
            }
            cleverapps.toolbar.resetByType(ToolbarItem.CLANS);
        }
    },

    updateGift: function () {
        if (this.message.isGiftMessage() && !this.message.isUserMessage() && this.collectGiftButton) {
            if (this.message.gift && !this.message.gift.isReceived()) {
                this.collectGiftButton.enable();
            } else {
                this.collectGiftButton.disable();
            }
            cleverapps.toolbar.resetByType(ToolbarItem.CLANS);
        }
    },

    createContentToReceiveHelp: function () {
        var styles = cleverapps.styles.ClanMessageRow.teammateHelp;

        var bar = this.createProgressBar();

        this.helpButtons = [];

        var helpList = this.message.listHelp().filter(function (help) {
            return help.status !== ClansConfig.HELP_RECEIVED;
        }).map(function (help) {
            var bg = cleverapps.UI.createScale9Sprite(bundles.my_clan_window.frames.massage_help_from_teammates_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
            bg.setContentSize2(styles);

            var title = this.createTitle(help.senderAvatar, help.getSenderName());

            var button = this.createHelpButton("Claim", function () {
                cleverapps.clanMessages.collectHelp(help, button);
            });
            button.help = help;
            this.helpButtons.push(button);

            var content = new cleverapps.Layout([title, button], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.margin
            });

            bg.addChild(content);
            content.setPositionRound(styles.content);

            var node = new cc.Node();
            node.setAnchorPoint(0.5, 0.5);
            node.setContentSize2(bg.getContentSize());
            node.addChild(bg);
            bg.setPositionRound(node.width / 2 + styles.offsetX, node.height / 2);

            return node;
        }, this);

        return new cleverapps.Layout([bar].concat(helpList), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.verticalMargin
        });
    },

    createContentToSendHelp: function () {
        var styles = cleverapps.styles.ClanMessageRow;

        var bar = this.createProgressBar();

        var button = this.sendHelpButton = this.createHelpButton("ClanMessage.helpButton", function () {
            cleverapps.clanMessages.sendHelp(this.message.messageId, connector.platform.getUserID());
        }.bind(this));

        return new cleverapps.Layout([bar, button], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.content.margin
        });
    },

    createGiftIcon: function () {
        return new cc.Sprite(bundles.tablerow_icons.frames.clan_gift);
    },

    createContentAboutSentGift: function () {
        var styles = cleverapps.styles.ClanMessageRow;

        var message = ClansConfig.MESSAGES[this.message.type].sender;
        var text = cleverapps.UI.generateOnlyText(message, cleverapps.styles.FONTS.CLAN_CHAT_PHRASE);
        var gift = this.createGiftIcon();

        var layout = new cleverapps.Layout([text, gift], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.giftMessage.margin
        });

        cleverapps.UI.fitToBox(layout, {
            width: styles.width
        });

        return layout;
    },

    createContentToGetGift: function () {
        var styles = cleverapps.styles.ClanMessageRow;

        var message = ClansConfig.MESSAGES[this.message.type].receiver;
        var text = cleverapps.UI.generateOnlyText(message, cleverapps.styles.FONTS.CLAN_CHAT_TEAMMATE_PHRASE);

        var gift = this.createGiftIcon();

        if (this.message.gift) {
            var button = this.collectGiftButton = this.createGiftButton("ClanMessage.claimGiftButton", function () {
                cleverapps.clanMessages.collectGift(this.message.gift, button);
            }.bind(this));
        }

        return new cleverapps.Layout([text, gift, button].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.giftMessage.margin
        });
    },

    createTitle: function (avatar, name, type) {
        var styles = cleverapps.styles.ClanMessageRow;

        var userAvatar = new cleverapps.UI.Avatar(avatar);
        cleverapps.UI.fitToBox(userAvatar, {
            height: styles.title.avatar.height
        });
        var userName = cleverapps.UI.generateOnlyText(cleverapps.UI.cutPlayerName(name, 12) + ":", cleverapps.styles.FONTS.CLAN_CHAT_USERNAME_TEXT);
        userName.fitTo(type === undefined ? styles.teammateHelp.name.width : styles.title.name.width);

        var text = "";
        if (type === undefined) {
            text = "ClanMessage.helpYou";
        } else if (type === ClansConfig.MESSAGE_TYPE_ENERGY) {
            text = "ClanMessage.sendEnergy";
        }
        var msg = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.BUTTON_TEXT);
        msg.fitTo(type === undefined ? styles.teammateHelp.helpText.width : styles.title.helpText.width);

        return new cleverapps.Layout([userAvatar, userName, msg], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.title.margin
        });
    },

    createProgressBar: function () {
        var styles = cleverapps.styles.ClanMessageRow;

        var totalHelp = this.message.getTotalHelp();
        var currentHelp = this.message.getCurrentHelp();

        var bar = this.bar = new ScaledProgressBar({
            progress: bundles.progress_bar.frames.bar_clanwindow,
            background: bundles.progress_bar.frames.bg_clanwindow
        });
        bar.setLength(styles.content.bar.width);
        bar.setGoal(totalHelp);
        bar.setPercentage(currentHelp);

        if (cleverapps.config.debugMode) {
            cleverapps.UI.onClick(bar, function () {
                var restricted = [this.message.playerId].concat(this.message.helps.map(function (help) {
                    return help.senderId;
                }));

                var sender = cleverapps.userClan.players.find(function (player) {
                    return restricted.indexOf(player.id) === -1;
                });

                if (sender) {
                    cleverapps.clanMessages.sendHelp(this.message.messageId, sender.id);

                    if (this.message.isUserMessage()) {
                        cleverapps.windows.closeBottomWindows();
                        if (cleverapps.windows.currentWindow()) {
                            cleverapps.windows.currentWindow().close();
                        }

                        new MyClanWindow();
                    }
                }
            }.bind(this));
        }

        var icon = new cc.Sprite(cleverapps.skins.getSlot("energy_icon_png") || bundles.buttons_inlined_icons.frames.energy_icon_png);
        icon.setPositionRound(styles.content.bar.icon);
        bar.addChild(icon);

        var barText = this.barText = cleverapps.UI.generateImageText(currentHelp + "/" + totalHelp, cleverapps.styles.FONTS.SMALL_WHITE_TEXT);
        barText.setAnchorPoint(0.5, 0.5);
        barText.setPosition(bar.width / 2, bar.height / 2);
        bar.addChild(barText);

        return bar;
    },

    updateProgressBar: function (silent) {
        var totalHelp = this.message.getTotalHelp();
        var currentHelp = this.message.getCurrentHelp();

        this.barText.setString(currentHelp + "/" + totalHelp);

        this.bar.setGoal(totalHelp);
        this.bar.setPercentage(currentHelp, {
            animated: !silent
        });

        if (!silent) {
            this.barText.runAction(new cc.Sequence(
                new cc.ScaleTo(0.2, 1.1),
                new cc.ScaleTo(0.2, 1)
            ));
        }
    },

    createHelpButton: function (actionText, onClick) {
        var styles = cleverapps.styles.ClanMessageRow;

        var action = cleverapps.UI.generateOnlyText(actionText, cleverapps.styles.FONTS.BUTTON_TEXT);
        var icon = new cc.Sprite(cleverapps.skins.getSlot("energy_icon_png") || bundles.buttons_inlined_icons.frames.energy_icon_png);
        var amount = cleverapps.UI.generateOnlyText("x" + this.message.getHelpAmount(), cleverapps.styles.FONTS.BUTTON_TEXT);
        var content = new cleverapps.Layout([action, icon, amount], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.content.button.margin
        });

        return new cleverapps.UI.Button({
            type: styles.content.button.type,
            content: content,
            width: styles.content.button.width,
            height: styles.content.button.height,
            onClicked: function () {
                onClick();
            }
        });
    },

    createGiftButton: function (actionText, onClick) {
        var styles = cleverapps.styles.ClanMessageRow;
        actionText = this.message.gift && !this.message.gift.isReceived() ? actionText : "ClanMessage.giftClaimed";
        var action = cleverapps.UI.generateOnlyText(actionText, cleverapps.styles.FONTS.BUTTON_TEXT);
        if (this.message.gift && !this.message.gift.isReceived()) {
            var icon = new cc.Sprite(bundles.buttons_inlined_icons.frames.coin_icon_png);
            var amount = cleverapps.UI.generateOnlyText("x" + this.message.gift.getGiftAmount(), cleverapps.styles.FONTS.BUTTON_TEXT);
            var content = new cleverapps.Layout([action, icon, amount], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.content.button.margin
            });

            return new cleverapps.UI.Button({
                type: styles.content.button.type,
                content: content,
                width: styles.content.button.width,
                height: styles.content.button.height,
                onClicked: function () {
                    onClick();
                }
            });
        }

        return new cleverapps.UI.Button({
            type: styles.content.button.type,
            content: action,
            width: styles.content.button.width,
            height: styles.content.button.height,
            onClicked: function () {
                onClick();
            }
        });
    },

    addArrow: function (container) {
        var styles = cleverapps.styles.ClanMessageRow.arrow;
        var image;

        if (this.message.isUserMessage()) {
            styles = styles.right;
            image = bundles.my_clan_window.frames.massage_orange_arrow;
        } else {
            styles = styles.left;
            image = bundles.my_clan_window.frames.massage_arrow;
        }

        var icon = new cc.Sprite(image);
        container.addChild(icon);

        icon.setPositionRound(styles);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CLAN_CHAT_TEAMMATE_PHRASE: {
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    CLAN_CHAT_PHRASE: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    },

    CLAN_CHAT_USERNAME_TEXT: {
        size: 36,
        color: cleverapps.styles.COLORS.YELLOW_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    CLANS_CHAT_TIMER_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.YELLOW_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.ClanMessageRow = {
    width: 1190,
    deltaWidth: 100,
    maxRowWidth: 1000,
    helpRowWidth: 1000,

    left: {
        x: { align: "left" },
        y: { align: "center" }
    },

    right: {
        x: { align: "right" },
        y: { align: "center" }
    },

    arrow: {
        left: {
            x: { align: "left", dx: -26 },
            y: { align: "top", dy: -80 }
        },
        right: {
            x: { align: "right", dx: 29 },
            y: { align: "top", dy: -80 }
        }
    },

    title: {
        x: { align: "left", dx: 30 },
        y: { align: "center" },
        margin: 20,

        bg: {
            height: 80,
            x: { align: "left" },
            y: { align: "top" }
        },

        avatar: {
            height: 60
        },

        name: {
            width: 250
        },

        helpText: {
            width: 380
        },

        timer: {
            x: { align: "right", dx: -30 },
            y: { align: "center" }
        }
    },

    content: {
        margin: 50,
        x: { align: "center" },
        y: { align: "center", dy: -30 },

        bg: {
            paddingY: 140,
            x: { align: "left" },
            y: { align: "top" }
        },

        bar: {
            width: 350,

            icon: {
                x: { align: "left", dx: -5 },
                y: { align: "center", dy: 5 }
            }
        },

        button: {
            width: 240,
            height: 80,
            margin: 5,
            type: cleverapps.styles.UI.Button.Images.button_green
        }
    },

    phrase: {
        x: { align: "left", dx: 50 },
        y: { align: "center", dy: -30 }
    },

    teammateHelp: {
        width: 870,
        height: 100,
        verticalMargin: 10,
        margin: 50,
        offsetX: 58,

        content: {
            x: { align: "left", dx: 20 },
            y: { align: "center" }
        },

        name: {
            width: 220
        },

        helpText: {
            width: 190
        }
    },

    giftMessage: {
        margin: 15
    }
};
