/**
 * Created by iamso on 05.10.2021
 */

Season.REWARDS = {
    default: [
        {},
        {unit: {code: 'worker', stage: 1, amount: 1}},
        {unit: {code: 'crystal', stage: 0, amount: 1}},
        {unit: {code: 'sacks', stage: 1, amount: 2}},
        {unit: {code: 'worker', stage: 2, amount: 1}},
        {unit: {code: 'crystal', stage: 1, amount: 1}},
        {unit: {code: 'sacks', stage: 2, amount: 2}},
        {unit: {code: 'treasure', stage: 0, amount: 1}},
        {unit: {code: 'crystal', stage: 2, amount: 1}},
        {unit: {code: 'crystal', stage: 3, amount: 1}}
    ]
};