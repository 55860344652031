/**
 * Created by r4zi4l on 05.04.2024
 */

RewardTypes.lives = RewardTypes.energy = {
    controls: "MenuBarLivesItem",
    flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,

    getIcon: function () {
        return cleverapps.skins.getSlot("reward_energy_png") || bundles.reward_icons.frames.reward_energy_png;
    },

    getSmallIcon: function () {
        return cleverapps.skins.getSlot("energy_small_png") || bundles.reward_icons.frames.energy_small_png;
    },

    handler: function (value, options) {
        cleverapps.lives.give(value, options.event, true);
        return function () {
            cleverapps.lives.processRegenerateState(true);
            cleverapps.lives.runListeners();
        };
    }
};
