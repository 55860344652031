/**
 * Created by vladislav on 25/10/2022
 */

QuestsConfig.undersea3 = cleverapps.clone(QuestsConfig.universal, true);

QuestsConfig.undersea3.rpcustomerbridge1_1.trigger.unit = { code: "rpcustomermain0a", stage: 2 };
QuestsConfig.undersea3.rpcustomerbridge2_1.trigger.unit.code = "rpcustomermain1a";
QuestsConfig.undersea3.rpcustomerbridge3_1.trigger.unit.code = "rpcustomermain2a";
QuestsConfig.undersea3.rpproducer0.trigger.unit.stage = 1;
QuestsConfig.undersea3.rpproducer0.trigger.unit.code = "rpcustomermain0a";
QuestsConfig.undersea3.rpproducer1.trigger.unit.code = "rpcustomermain1a";
QuestsConfig.undersea3.rpproducer2.trigger.unit.code = "rpcustomermain2a";
QuestsConfig.undersea3.rpproducer3.trigger = { unit: { code: "rpcustomermain3a", stage: 0 } };

delete QuestsConfig.undersea3.rpcustomermain0_0;
delete QuestsConfig.undersea3.rpcustomermain0_1;
delete QuestsConfig.undersea3.rpcustomermain1_0;
delete QuestsConfig.undersea3.rpcustomermain1_1;
delete QuestsConfig.undersea3.rpcustomermain2_0;
delete QuestsConfig.undersea3.rpcustomermain2_1;
delete QuestsConfig.undersea3.rpcustomermain3_0;
delete QuestsConfig.undersea3.rpcustomermain3_1;
delete QuestsConfig.halloween.rpcustomerbridge4_1;
delete QuestsConfig.undersea3.rpcustomermain4_0;
delete QuestsConfig.undersea3.rpcustomermain4_1;
delete QuestsConfig.undersea3.rpproducer4;
delete QuestsConfig.undersea3.rpproduct4;

QuestsConfig.undersea3.rpcustomermain0a_1 = {
    trigger: { unit: { code: "rpcustomerbridge0", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain0a", stage: 1 }
    }
};
QuestsConfig.undersea3.rpcustomermain1a_0 = { 
    trigger: { unit: { code: "rpcustomermain0a", stage: 2 } },
    quest: {
        type: Map2d.OPEN_UNIT,
        unit: { code: "rpcustomermain1a", stage: 0 }
    }   
};

QuestsConfig.undersea3.rpcustomermain2a_0 = { 
    trigger: { unit: { code: "rpcustomermain1a", stage: 1 } },
    quest: {
        type: Map2d.OPEN_UNIT,
        unit: { code: "rpcustomermain2a", stage: 0 }
    }   
};

QuestsConfig.undersea3.rpcustomermain3a_0 = { 
    trigger: { unit: { code: "rpcustomermain2a", stage: 1 } },
    quest: {
        type: Map2d.OPEN_UNIT,
        unit: { code: "rpcustomermain3a", stage: 0 }
    }   
};

QuestsConfig.undersea3.rpcustomermain0a_1 = {
    trigger: { unit: { code: "rpcustomerbridge0", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain0a", stage: 1 }
    }
};
QuestsConfig.undersea3.rpcustomermain1a_1 = {
    trigger: { unit: { code: "rpcustomerbridge1", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain1a", stage: 1 }
    }
};
QuestsConfig.undersea3.rpcustomermain2a_1 = {
    trigger: { unit: { code: "rpcustomerbridge2", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain2a", stage: 1 }
    }
};
QuestsConfig.undersea3.rpcustomermain3a_1 = {
    trigger: { unit: { code: "rpcustomerbridge3", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain3a", stage: 1 }
    }
};