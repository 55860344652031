/**
 * Created by vladislav on 21.06.2022
 */

var ConfirmMergeWindow = CleverappsWindow.extend({
    onWindowLoaded: function (unit, mergeInfoUnit, amount, callback) {
        this.unit = unit;
        this.mergeInfoUnit = mergeInfoUnit;
        this.amount = amount;
        this.callback = callback;

        this.isConfirmed = false;

        this._super({
            name: "ConfirmMergeWindow",
            title: {
                text: "ConfirmMergeWindow.title",
                toReplace: {
                    unit: cleverapps.unitsLibrary.getUnitName(unit)
                }
            },
            content: this.createContent()
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.ConfirmMergeWindow;

        var msg = Messages.get("ConfirmMergeWindow.text", {
            amount: this.amount,
            used: "[[",
            received: "]]"
        });

        var text = new TextWithIcon(msg, {
            font: cleverapps.styles.FONTS.WINDOW_TEXT,
            icons: {
                "[[": UnitView.getUnitImage(this.unit, { preferStatic: true }),
                "]]": UnitView.getUnitImage(this.mergeInfoUnit.getMergeUnit(), { preferStatic: true })
            },
            iconMinHeight: 1,
            iconMaxHeight: 3
        });

        var buttons = this.createButtons();

        return new cleverapps.Layout([text, buttons], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL,
            padding: styles.padding
        });
    },

    createButtons: function () {
        var styles = cleverapps.styles.ConfirmMergeWindow;

        var buttons = [
            new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.button_red,
                text: "No",
                width: styles.buttons.width,
                height: styles.buttons.height,
                onClicked: function () {
                    this.close();
                }.bind(this)
            }),
            new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.button_green,
                text: "Yes",
                width: styles.buttons.width,
                height: styles.buttons.height,
                onClicked: function () {
                    this.isConfirmed = true;

                    this.close();
                }.bind(this)
            })
        ];

        return new cleverapps.Layout(buttons, {
            margin: styles.buttons.margin,
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    onClose: function () {
        this.callback(this.isConfirmed);
    }
});

cleverapps.styles.ConfirmMergeWindow = {
    margin: 60,

    padding: {
        x: 80,
        top: 30,
        bottom: 10
    },

    buttons: {
        width: 250,
        height: 100,

        margin: 80
    }
};
