/**
 * Created by Anastasiya on 17.05.2024
 */

var TradeWindow = CleverappsWindow.extend({
    onWindowLoaded: function (forceActive) {
        this.trade = new UnitTrade();

        this._super({
            title: "TradeWindow.Title",
            name: "tradewindow",
            content: this.getContent(),
            help: function () {
                new GuideWindow({
                    name: "TradeGuideWindow",
                    bundle: bundles.tradeguidewindow
                });
            },
            styles: cleverapps.styles.TradeWindow.window
        });

        this.trade.on("pickChange", this.updateButton.bind(this), this);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.TRADE_WINDOW_OPEN);
        if (forceActive) {
            this.startTutorial();
        }
    },

    getContent: function () {
        var styles = cleverapps.styles.TradeWindow;
        this.sourcePicker = new TradePicker(UnitTrade.SOURCE, this.trade);
        this.targetPicker = new TradePicker(UnitTrade.TARGET, this.trade);

        var layout = new cleverapps.Layout([this.sourcePicker, this.targetPicker], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        var arrow = new cc.Sprite(bundles.tradewindow.frames.arrow);
        arrow.setPositionRound(styles.arrow);
        layout.addChild(arrow);

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.addChild(layout);
        cleverapps.UI.wrap(content);

        this.button = this.createButton();
        this.button.setPositionRound(styles.button);
        content.addChild(this.button);
        this.updateButton();

        return content;
    },

    createButton: function () {
        var styles = cleverapps.styles.TradeWindow.button;
        return new cleverapps.UI.Button({
            text: "TradeWindow.ButtonTitle",
            width: styles.width,
            height: styles.height,
            onClicked: function () {
                if (cleverapps.forces.getRunningForce()) {
                    cleverapps.forces.closeRunningForce();
                }
                this.exchange = true;
                this.close();
            }.bind(this)
        });
    },

    updateButton: function () {
        if (this.trade.getSelected(UnitTrade.SOURCE) && this.trade.getSelected(UnitTrade.TARGET)) {
            this.button.enable();
        } else {
            this.button.disable();
        }
    },

    startTutorial: function () {
        new ActionPlayer([
            this.sourcePicker.playTutorial.bind(this.sourcePicker),
            this.targetPicker.playTutorial.bind(this.targetPicker),
            function (f) {
                var rects = [this.sourcePicker.description.getSceneBoundingBox(), this.targetPicker.description.getSceneBoundingBox(), this.button.getSceneBoundingBox()];

                var dialogue = new MinimalDialogue({
                    text: "Forces.TradeExchange",
                    forcePosition: Dialogue.POSITIONS.CENTER,
                    rects: rects
                });
                this.addChild(dialogue);
                dialogue.display();

                this.runAction(new cc.Sequence(
                    new cc.DelayTime(5),
                    new cc.CallFunc(function () {
                        dialogue.remove();
                    })
                ));
                f();
            }.bind(this)
        ]).play();
    },

    beforeCloseAnimation: function (callback) {
        if (!this.exchange) {
            callback();
            return;
        }
        this.hideSelf();
        this.trade.exchange(callback);
    },

    listBundles: function () {
        return ["tradewindow"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TRADE_WINDOW_SELECT_HINT: {
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.TradeWindow = {
    margin: 25,

    window: {
        padding: {
            x: 60,
            top: 55,
            bottom: 75
        }
    },

    column: {
        width: 700,
        height: 700
    },

    arrow: {
        x: { align: "center", dx: 22 },
        y: { align: "top", dy: -60 }
    },

    button: {
        width: 350,
        height: 100,
        x: { align: "center" },
        y: { align: "bottom", dy: -105 }
    }
};