/**
 * Created by Andrey Popov on 30.10.2024
 */

cc.HighlightUnit = cc.CallFunc.extend({
    ctor: function (unit, options) {
        this._super(function (target) {
            target.highlighter = new UnitHighlighter(target, unit, options);
        });
    }
});