/**
 * Created by andrey on 28.07.2023
 */

var Map2dWaterView = cc.Sprite.extend({
    reuse: function (frame) {
        this.setSpriteFrame(frame);

        this._super();
    }
});

Map2dWaterView.PoolLimit = 500;

Map2dWaterView.GetFromPool = function (frame) {
    return cc.pool.getFromPool(Map2dWaterView, frame) || new Map2dWaterView(frame);
};