/**
 * Created by Andrey Popov on 01.11.2024
 */

var CustomerWindowTitle = cc.Node.extend({
    ctor: function (customer, forUpgrade) {
        this._super();

        var styles = cleverapps.styles.CustomerWindowTitle;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);

        var mainTitleBg = this.mainTitleBg = new cleverapps.Spine(bundles.customer_window.jsons.customer_title_json);
        this.addChild(mainTitleBg);
        mainTitleBg.setPositionRound(styles.mainTitle);

        var titleText = cleverapps.unitsLibrary.getUnitName({
            code: customer.unit.code,
            stage: Families[customer.unit.code].oneViewForStages ? 0 : customer.unit.stage
        });

        var mainTitle = this.mainTitle = cleverapps.UI.generateOnlyText(titleText, cleverapps.styles.FONTS.CUSTOMER_TITLE_TEXT);
        mainTitle.setPositionRound(styles.mainTitle.text);
        mainTitle.fitTo(styles.mainTitle.text.width);
        mainTitleBg.addChild(mainTitle);

        var subtitleBg = this.subtitleBg = cleverapps.UI.createScale9Sprite(
            bundles.customer_window.frames.customer_light_bg,
            cleverapps.UI.Scale9Rect.TwoPixelXY
        );
        subtitleBg.setContentSize2(forUpgrade ? styles.subtitle.upgrade : styles.subtitle);
        subtitleBg.setPositionRound(forUpgrade ? styles.subtitle.upgrade : styles.subtitle);
        subtitleBg.setCascadeOpacityEnabled(true);

        var subtitle = forUpgrade ? cleverapps.UI.generateTTFText("Customer.UpgradeToStage", cleverapps.styles.FONTS.CUSTOMER_SUBTITLE_TEXT, { stage: customer.unit.stage + 2 })
            : cleverapps.UI.generateTTFText("Customer.FindIngredients", cleverapps.styles.FONTS.CUSTOMER_SUBTITLE_TEXT);
        subtitle.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        if (!forUpgrade) {
            subtitle.setDimensions(styles.subtitle.text.width, 0);
            subtitle.fitTo(undefined, styles.subtitle.text.height);
        }

        subtitleBg.addChild(subtitle);
        subtitle.setPositionRound(subtitleBg.width / 2, subtitleBg.height / 2);

        this.addChild(subtitleBg);
    },

    showUp: function () {
        this.mainTitle.setOpacity(0);
        this.subtitleBg.setOpacity(0);

        this.subtitleBg.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.FadeIn(0.5)
        ));

        this.mainTitleBg.setAnimation(0, "open", false);
        this.mainTitleBg.setCompleteListener(function () {
            this.mainTitleBg.setCompleteListener();

            this.mainTitleBg.setAnimation(0, "idle", true);
            this.mainTitle.runAction(new cc.FadeIn(0.5));
        }.bind(this));
    },

    close: function () {
        this.mainTitle.runAction(new cc.FadeOut(0.2));
        this.subtitleBg.runAction(new cc.FadeOut(0.2));

        this.mainTitleBg.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.CallFunc(function () {
                this.mainTitleBg.setAnimation(0, "close", false);
                this.mainTitleBg.setCompleteListenerRemove();
            }.bind(this))
        ));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CUSTOMER_TITLE_TEXT: {
        name: "default",
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    CUSTOMER_SUBTITLE_TEXT: {
        name: "default",
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.CustomerWindowTitle = {
    width: 700,
    height: 300,

    mainTitle: {
        x: { align: "center" },
        y: { align: "top", dy: 360 },

        text: {
            width: 400,
            x: { align: "center" },
            y: { align: "center", dy: -234 }
        }
    },

    subtitle: {
        height: 200,
        width: 700,

        text: {
            width: 520,
            height: 140
        },

        x: { align: "center" },
        y: { align: "bottom", dy: -80 },

        upgrade: {
            height: 162,
            width: 700,

            x: { align: "center" },
            y: { align: "bottom", dy: -110 }
        }
    }
};