/**
 * Created by iamso on 21.11.2020.
 */

var AdminDetailControls = cleverapps.Layout.extend({
    ctor: function () {
        this.createActionButtons();
        this.createNavigationButtons();
        this.createTagButtons();

        this._super([this.navigationButtons, this.actionButtons, this.tagButtons], {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.AdminDetailControls.rowMargin
        });
    },

    createButton: function (data) {
        var styles = cleverapps.styles.AdminDetailControls;

        var icon = data.icon ? new cc.Sprite(data.icon) : undefined;
        var button = new cleverapps.UI.Button({
            type: data.type || cleverapps.styles.UI.Button.Images.editor,
            content: icon || data.content,
            width: data.width || styles.width,
            height: styles.height,
            onClicked: data.action,
            hint: data.hint
        });
        button.icon = icon;

        return button;
    },

    createActionButtons: function () {
        var adminLevel = cleverapps.administrator.adminLevels.getActive();

        var buttons = this.buttons = AdminDetailControls.BUTTONS.filter(function (data) {
            return (data.usage & AdminDetailControls.DETAIL_CONTROLS) && adminLevel && data.available(adminLevel);
        }).map(function (data) {
            var button = this.createButton({
                icon: data.icon,
                hint: data.hint,
                update: data.update,
                action: function () {
                    data.action(adminLevel);
                }
            });

            if (data.update) {
                button.updateButton = data.update.bind(button, adminLevel);
                button.updateButton();
            }

            return button;
        }.bind(this));

        this.actionButtons = new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.AdminDetailControls.colMargin
        });
    },

    createNavigationButtons: function () {
        var buttons = ["left_double", "left", "right", "right_double"].map(function (key, index) {
            return this.createButton({
                icon: bundles.admin.frames[key],
                hint: ["Previous episode", "Previous level", "Next level", "Next episode"][index],
                action: function () {
                    cleverapps.focusManager.display({
                        focus: "AdminNavigation",
                        action: AdminNavigation[key]
                    });
                }
            });
        }.bind(this));

        this.navigationButtons = new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.AdminDetailControls.colMargin
        });
    },

    createTagButtons: function () {
        var styles = cleverapps.styles.AdminDetailControls;
        var availableTags = Level.AvailableTags();
        var type = {
            button_png: bundles.admin.frames.button_tag_off,
            button_on_png: bundles.admin.frames.button_tag_off,
            SKIP_RESOLUTION: styles.tagButton
        };

        this.tagButtonList = [];

        availableTags.reverse().forEach(function (tag) {
            var button = this.createButton({
                type: type,
                content: cleverapps.UI.generateOnlyText(tag, cleverapps.styles.FONTS.ADMIN_DETAIL_CONTROLS_TAG_BUTTON_TEXT),
                hint: "Toggle " + tag,
                action: function () {
                    cleverapps.administrator.adminLevels.getActive().toggleTag(tag);
                    cleverapps.administrator.adminDetails.reset();
                },
                width: styles.tagButton.width
            });

            button.updateIcon = function () {
                var level = cleverapps.administrator.adminLevels.getActive();
                if (level.getTag() === tag) {
                    button.setType({
                        button_png: bundles.admin.frames["button_tag_" + tag],
                        button_on_png: bundles.admin.frames["button_tag_" + tag],
                        SKIP_RESOLUTION: styles.tagButton
                    });
                } else {
                    button.setType(type);
                }
            };
            this.tagButtonList.push(button);
        }, this);

        var text = cleverapps.UI.generateOnlyText("TAGS:", cleverapps.styles.FONTS.ADMIN_DETAIL_CONTROLS_TEXT_TAGS);

        this.tagButtons = new cleverapps.Layout([text].concat(this.tagButtonList), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.colMargin
        });
    },

    reset: function (adminLevel) {
        if (adminLevel === undefined) {
            return;
        }

        this.buttons.forEach(function (button) {
            if (button.updateButton) {
                button.updateButton();
            }
        });

        this.tagButtonList.forEach(function (button) {
            button.updateIcon();
        });
        adminLevel.on("change:tag", function () {
            this.tagButtonList.forEach(function (button) {
                button.updateIcon();
            });
        }.bind(this), this);
    }
});

AdminDetailControls.DETAIL_CONTROLS = 1 << 0;
AdminDetailControls.LEVEL_VIEW = 1 << 1;

AdminDetailControls.BUTTONS = [{
    usage: AdminDetailControls.DETAIL_CONTROLS,
    icon: bundles.admin.frames.current_icon,
    hint: "Make current",
    available: function (adminLevel) {
        return cleverapps.isNumber(adminLevel.episodeNo);
    },
    action: function (adminLevel) {
        cleverapps.focusManager.display({
            focus: "MakeCurrent",
            action: function (f) {
                cleverapps.administrator.adminLevels.makeCurrent(adminLevel);
                cleverapps.administrator.adminLevels.update();
                cleverapps.administrator.adminEpisodes.updateCurrent();
                cleverapps.administrator.adminDetails.reset();
                f();
            }
        });
    },
    update: function (adminLevel) {
        if (adminLevel.isCurrent()) {
            this.icon.setColor(new cc.Color(36, 255, 46, 255));
        } else {
            this.icon.setColor(new cc.Color(255, 255, 255, 255));
        }
    }
}, {
    usage: AdminDetailControls.DETAIL_CONTROLS | AdminDetailControls.LEVEL_VIEW,
    icon: bundles.admin.frames.play_icon,
    hint: "Play level",
    available: function () {
        return true;
    },
    action: function () {
        cleverapps.focusManager.display({
            focus: "AdminPlayLevel",
            action: function (f) {
                LevelManager.getInstance().playLevel(f);
            }
        });
    }
}, {
    usage: AdminDetailControls.DETAIL_CONTROLS | AdminDetailControls.LEVEL_VIEW,
    icon: bundles.admin.frames.edit_icon,
    hint: "Edit level",
    available: function (adminLevel) {
        if (cleverapps.config.name === "wordsoup") {
            return true;
        }
        
        var editorByType = {
            match3: "riddles",
            board: "scramble",
            solitaire: "tripeaks",
            battlefield: "epicart",
            tile3: "zenmatch"
        }[cleverapps.config.type];

        if (editorByType) {
            return editorByType === cleverapps.config.name;
        }

        var paths = [
            "res/levels/" + adminLevel.episodeNo + ".json",
            "cleverapps/res/" + cleverapps.config.name + "/levels/" + adminLevel.episodeNo + ".json"
        ];

        var json = bundles[AdminEpisode.BundleId(adminLevel.episodeNo)].jsons.levels;
        return paths.some(function (path) {
            return json.originalPath().includes(path);
        });
    },
    action: function (adminLevel) {
        adminLevel.editLevel();
    }
}, {
    usage: AdminDetailControls.LEVEL_VIEW,
    icon: bundles.admin.frames.clone_icon,
    hint: "Clone level",
    available: function () {
        return !cleverapps.config.changeLevelsDisabled;
    },
    action: function (adminLevel) {
        cleverapps.administrator.adminLevels.copyLevel(adminLevel);
    }
}, {
    usage: AdminDetailControls.LEVEL_VIEW,
    icon: bundles.admin.frames.copy_icon,
    hint: "Copy level to clipboard",
    available: function () {
        return ["merge", "tile3"].indexOf(cleverapps.config.type) !== -1;
    },
    action: function (adminLevel) {
        cleverapps.administrator.adminLevels.copyLevelToClipboard(adminLevel);
    }
}, {
    usage: AdminDetailControls.DETAIL_CONTROLS,
    icon: bundles.admin.frames.delete_icon,
    hint: "Delete level",
    available: function () {
        return ["differences"].indexOf(cleverapps.config.name) === -1;
    },
    action: function (adminLevel) {
        var active = cleverapps.administrator.adminLevels.getActive();
        if (adminLevel.isAddMoreLevel()) {
            return;
        }

        cleverapps.focusManager.display({
            focus: "deleteLevel",
            action: function (f) {
                if (confirm("Delete level " + active.getHumanNumber() + "?")) {
                    cleverapps.administrator.adminLevels.removeLevel(adminLevel, f);
                } else {
                    f();
                }
            }
        });
    }
}, {
    usage: AdminDetailControls.DETAIL_CONTROLS,
    icon: bundles.admin.frames.clear_icon,
    hint: "Reset saved game",
    available: function () {
        return true;
    },
    action: function (adminLevel) {
        cleverapps.GameSaver.removeSave(cleverapps.GameSaver.getStoreSlot(adminLevel.episodeNo, adminLevel.levelNo));
    }
}];

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_DETAIL_CONTROLS_TEXT_TAGS: {
        size: 34,
        color: cleverapps.styles.COLORS.ADMIN_TEXT_COLOR
    },

    ADMIN_DETAIL_CONTROLS_TAG_BUTTON_TEXT: {
        size: 42,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: {
            color: cleverapps.styles.COLORS.BLACK_STROKE,
            size: 2
        }
    }
});

cleverapps.styles.AdminDetailControls = {
    height: 60,
    width: 60,
    colMargin: 5,
    rowMargin: 10,

    tagButton: {
        width: 170,
        padding: {
            left: 0.01,
            right: 0.01,
            top: 0.01,
            bottom: 0.01
        }
    }
};
