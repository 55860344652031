/**
 * Created by andrey on 25.08.2021.
 */

var PropertiesPacker = {
    compactProperties: function (data, splitter) {
        var list = splitter(data);

        var context = {};

        list.forEach(function (item) {
            var index = item.indexOf("{");
            if (index !== -1) {
                item.substr(index + 1, item.length - 1 - index - 1).split(",").forEach(function (property) {
                    context[property] = (context[property] || 0) + 1;
                });
            }
        });

        var contextProperties = Object.keys(context)
            .filter(function (code) {
                return context[code] > 1;
            })
            .sort(function (a, b) {
                return context[b] - context[a];
            })
            .slice(0, 80);

        var contextCodes = {};
        contextProperties.forEach(function (property, code) {
            contextCodes[property] = Base80.compactNumber(code);
        });

        list = list.map(function (item) {
            var index = item.indexOf("{");

            if (index === -1) {
                return item;
            }

            var properties = item.substr(index + 1, item.length - 1 - index - 1).split(",").map(function (property) {
                return contextCodes[property] || property;
            }).join(",");

            return item.substr(0, index) + "{" + properties + "}";
        });

        var contextCodesStr = "";

        if (contextProperties.length > 0) {
            contextCodesStr = "[" + contextProperties.join(",") + "]";
        }

        return contextCodesStr + list.join("");
    },

    expandProperties: function (data, splitter) {
        if (data.charAt(0) !== "[") {
            return data;
        }

        var context = {};

        var endIndex = data.indexOf("]");
        data.substr(1, endIndex - 1).split(",").forEach(function (property, index) {
            context[Base80.compactNumber(index)] = property;
        });

        data = data.substr(endIndex + 1);

        var items = splitter(data);

        items = items.map(function (item) {
            var index = item.indexOf("{");

            if (index === -1) {
                return item;
            }

            var properties = item.substr(index + 1, item.length - 1 - index - 1).split(",").map(function (property) {
                return context[property] || property;
            }).join(",");

            return item.substr(0, index) + "{" + properties + "}";
        });

        return items.join("");
    }
};

if (typeof cc === "undefined") {
    module.exports = PropertiesPacker;
}