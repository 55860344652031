/**
 * Created by iamso on 27.12.2021
 */

var PortalGuideOptions = {
    name: "PortalGuideWindow",

    getGuideBundle: function () {
        return bundles.portalguidewindow;
    },

    stagesContent: function () {
        var styles = cleverapps.styles.PortalGuideWindow;

        var stage1 = this.initStage(styles.firstStage, function (styles, container) {
            var unitFirst = new UnitOnCell(this.unit);
            container.addChild(unitFirst);
            unitFirst.setPositionRound(styles.portal.x, styles.portal.y);
            unitFirst.setScale(styles.portal.scale);
            this.addUnitIcon(container, "crystal", 3, styles.ball);
            this.addSprite(container, this.bundle.frames.white_arrow_up, styles.arrow);
        }.bind(this));

        var stage2 = this.initStage(styles.secondStage, function (styles, container) {
            var unit1 = new PocketBubbleView({
                code: "crystal",
                stage: 1
            });
            unit1.setScale(styles.unit1.scale);
            container.addChild(unit1);
            unit1.setPositionRound(styles.unit1.x, styles.unit1.y);
            var unit2 = new PocketBubbleView({
                code: "worker",
                stage: 4
            });
            unit2.setScale(styles.unit2.scale);
            container.addChild(unit2);
            unit2.setPositionRound(styles.unit2.x, styles.unit2.y);
            var unit3 = new PocketBubbleView({
                code: "crystal",
                stage: 3
            });
            unit3.setScale(styles.unit3.scale);
            container.addChild(unit3);
            unit3.setPositionRound(styles.unit3.x, styles.unit3.y);
        });

        var stage3 = this.initStage(styles.thirdStage, function (styles, container) {
            var unit = new UnitOnCell(this.unit, { unitImgOptions: { defaultAnimation: "animation" } });
            container.addChild(unit);
            unit.setPositionRound(styles.portal.x, styles.portal.y);
            unit.setScale(styles.portal.scale);

            var timer = new ComponentTimer(new cleverapps.CountDown(Portal.REFRESH_DURATION), Portal.REFRESH_DURATION);
            timer.clear();
            unit.addChild(timer);
            timer.setPositionRound(styles.portal.timer);
            timer.setScale(styles.portal.timer.scale);
        }.bind(this));

        return [stage1, stage2, stage3];
    }
};

cleverapps.styles.PortalGuideWindow = {
    firstStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -70 }
        },

        ball: {
            x: { align: "center", dx: -100 },
            y: { align: "center", dy: 205 },
            scale: 1.3
        },

        portal: {
            x: { align: "center" },
            y: { align: "center", dy: -40 },
            scale: 1.8
        },

        arrow: {
            x: { align: "center", dx: -30 },
            y: { align: "center", dy: 130 },
            scale: 1.2,
            rotation: 80
        }
    },

    secondStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -60 }
        },

        unit1: {
            x: { align: "center", dx: -60 },
            y: { align: "center", dy: 55 },
            scale: 1.3
        },

        unit2: {
            x: { align: "center", dx: 60 },
            y: { align: "center", dy: 55 },
            scale: 1.3
        },

        unit3: {
            x: { align: "center" },
            y: { align: "center", dy: -15 },
            scale: 1.6
        }
    },

    thirdStage: {
        container: {
            x: { align: "center" },
            y: { align: "center", dy: -20 }
        },

        ball: {
            x: { align: "center", dx: 100 },
            y: { align: "center", dy: 255 },
            scale: 1.35
        },

        portal: {
            x: { align: "center" },
            y: { align: "center", dy: 10 },
            scale: 1.8,

            timer: {
                x: { align: "center", dx: 0 },
                y: { align: "bottom", dy: 0 },
                scale: 0.9
            }
        },

        arrow: {
            x: { align: "center", dx: 30 },
            y: { align: "center", dy: 180 },
            scale: 1.2,
            rotation: -25
        }
    }
};
