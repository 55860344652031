/**
 * Created by r4zi4l on 02.04.2021
 */

var ThirdElement = function (unit, options) {
    UnitComponent.call(this, unit);

    this.type = options.type || ThirdElement.TYPE_RUBY;
    this.prize = options.prize;

    var config = this.config = ThirdElement.GetConfig(this.type, this.prize);

    if (config.price === undefined) {
        this.price = Math.max(300, 300 + (this.prize.stage - 6) * 200);
    } else {
        this.price = config.price;
    }

    this.timeStart = Date.now();

    this.duration = cleverapps.parseInterval(config.duration) + cleverapps.parseInterval(ThirdElement.APPEARANCE_TIME);
    this.timeout = new cleverapps.LongTimeout(this.reject.bind(this), this.duration);

    if (config.limit) {
        cleverapps.adsLimits.watch(config.limit);
        cleverapps.rewardedAdsManager.onRewardedRefresh(this.changeRewardedState.bind(this), this);
    }

    if (config.timeout) {
        cleverapps.dataLoader.save(DataLoader.TYPES.THIRD_ELEMENT_LAST_TIME, this.timeStart);
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.THIRD_ELEMENT_COUNT + this.type);
};

ThirdElement.prototype = Object.create(UnitComponent.prototype);
ThirdElement.prototype.constructor = ThirdElement;

ThirdElement.prototype.onAppearance = function () {
    if (this.view) {
        if (["mergecraft", "hustlemerge"].indexOf(cleverapps.config.name) !== -1) {
            this.view.helicopterAppearance();
        } else {
            this.view.planeAppearance();
        }
    }
};

ThirdElement.prototype.onDestruction = function () {
    if (this.view) {
        this.view.playDestruction();
    }
};

ThirdElement.prototype.onBuying = function (unit) {
    if (this.view) {
        this.view.playBuying(unit);
    }
};

ThirdElement.GetConfig = function (type, unit) {
    var config = ThirdElement.CONFIG[type];

    var familyType = Families[unit.code].type;
    if (type === ThirdElement.TYPE_ANIMALS) {
        config = config[familyType] && config[familyType][unit.stage];
    } else if (config.customPrices && config.customPrices[familyType]) {
        config = Object.assign({}, config, {
            price: config.customPrices[familyType][unit.stage]
        });
    }

    return config;
};

ThirdElement.IsAvailable = function (type, unit) {
    if (cleverapps.gameModes.skipThirdElement) {
        return false;
    }

    var family = Families[unit.code];
    var unitType = family.type;
    var config = ThirdElement.GetConfig(type, unit);
    if (!config) {
        return false;
    }

    if (type === ThirdElement.TYPE_ANIMALS) {
        if (cleverapps.unitsLibrary.isOpened({ code: unit.code, stage: family.units.length - 1 })) {
            return false;
        }

        if (!cleverapps.user.checkAvailable(ThirdElement.CONFIG[type].available)) {
            return false;
        }
    }

    if (config.limit !== undefined && cleverapps.adsLimits.state(config.limit) !== AdsLimits.STATE_READY) {
        return false;
    }
    if (config.timeout && cleverapps.dataLoader.load(DataLoader.TYPES.THIRD_ELEMENT_LAST_TIME) + cleverapps.parseInterval(config.timeout) > Date.now()) {
        return false;
    }
    if (!config.types) {
        return true;
    }

    return config.types.some(function (configType) {
        if (unitType !== cleverapps.unitsLibrary.getExpeditionUnitType(configType)) {
            return false;
        }
        var isAds = config.limit !== undefined;

        if (configType === "hero") {
            if (isAds) {
                return unit.stage >= 1 && unit.stage < Math.min(4, family.units.length - 1);
            }
            if (unitType === "hero") {
                return unit.stage >= 6 && unit.stage >= family.units.length - 3 && unit.stage < family.units.length - 1;
            }
            return unit.stage >= 5 && unit.stage >= family.units.length - 3 && unit.stage < family.units.length - 1;
        }

        if (cleverapps.unitsLibrary.isOpened(unit) || !MergeTutorials[Unit.GetKey(unit)]) {
            if (isAds) {
                return unit.stage >= 2 && unit.stage < Math.min(5, family.units.length - 1);
            }
            if (unitType === "resource") {
                return unit.stage >= 6 && unit.stage >= family.units.length - 4 && unit.stage < family.units.length - 2;
            }
            if (unitType === "rpresource") {
                return unit.stage === family.units.length - 1;
            }
            return unit.stage >= 5 && unit.stage >= family.units.length - 3 && unit.stage < family.units.length - 1;
        }

        return false;
    });
};

ThirdElement.prototype.changeRewardedState = function () {
    if (!cleverapps.rewardedAdsManager.isRewardedAvailable()) {
        this.reject();
    }
};

ThirdElement.prototype.destructor = function () {
    clearTimeout(this.timeout);
    runCleaners(this);
};

ThirdElement.prototype.getTimeLeft = function () {
    return Math.max(0, this.timeStart + this.duration - Date.now());
};

ThirdElement.prototype.accept = function () {
    if (this.collected) {
        return;
    }

    var x = this.unit.x;
    var y = this.unit.y;
    var mapUnit = Map2d.currentMap.getUnit(x, y);

    if (mapUnit === this.unit) {
        this.unit.liftUnitFromMap();
    } else if (mapUnit) {
        var cell = Map2d.currentMap.findEmptySlot(x, y);
        if (!cell) {
            return;
        }
        x = cell.x;
        y = cell.y;
    }

    var unit = new Unit(this.prize);
    unit.setPosition(x, y);
    Map2d.currentMap.add(Map2d.LAYER_UNITS, x, y, unit);
    Map2d.currentMap.onAddUnit(x, y, unit);
    Map2d.currentMap.onUnitAvailable(unit);
    this.onBuying(unit);

    this.collected = true;

    if (mapUnit === this.unit) {
        this.unit.remove(true);
    }

    Map2d.mapEvent(Map2d.SPAWN, {
        unit: unit,
        affected: this.unit
    });
};

ThirdElement.prototype.reject = function () {
    this.onDestruction();
    this.unit.onDestruction();
    this.unit.remove(true);
};

ThirdElement.prototype.getProduct = function () {
    return Product.CreateByHardPrice(this.price, {
        type: "third_element",
        expedition: cleverapps.travelBook.getCurrentPage().id
    }, this.type === ThirdElement.TYPE_PASS_FINISH ? ["kit3", "kit10", "kit50"] : []);
};

ThirdElement.prototype.buy = function (callback) {
    var product = this.getProduct();

    if (product) {
        product.buy(function (success) {
            if (success) {
                this.accept();
            }
            callback(success);
        }.bind(this));
    } else {
        callback(false);
    }
};

ThirdElement.prototype.watch = function () {
    cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.THIRD_ELEMENT + this.type, function () {
        this.accept();
    }.bind(this));
};

ThirdElement.prototype.handleClick = function () {
    if (this.config.limit !== undefined) {
        this.watch();
        return true;
    }

    cleverapps.focusManager.display({
        focus: "thirdElementWindow",
        control: "MenuBarGoldItem",
        actions: [
            function (f) {
                setTimeout(f, 300);
            },

            function (f) {
                new ThirdElementWindow(this);
                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this)
        ]
    });

    return true;
};

ThirdElement.TYPE_RUBY = 1;
ThirdElement.TYPE_ADS = 2;
ThirdElement.TYPE_PASS_FINISH_LEGACY = 3;
ThirdElement.TYPE_PASS_FINISH = 4;
ThirdElement.TYPE_PASS_POINTS = 5;
ThirdElement.TYPE_ANIMALS = 6;

ThirdElement.CONFIG = {};

ThirdElement.CONFIG[ThirdElement.TYPE_RUBY] = {
    duration: "5 minutes",
    timeout: cleverapps.config.debugMode ? "10 seconds" : "24 hours",
    types: ["hero", "resource"],
    customPrices: {
        rpresource: { 3: 29 }
    }
};

ThirdElement.CONFIG[ThirdElement.TYPE_ADS] = {
    duration: "1 minutes",
    limit: AdsLimits.TYPES.THIRD_ELEMENT,
    types: ["hero", "resource"]
};

ThirdElement.CONFIG[ThirdElement.TYPE_PASS_FINISH] = ThirdElement.CONFIG[ThirdElement.TYPE_PASS_FINISH_LEGACY] = {
    duration: "5 minutes",
    price: 299
};

ThirdElement.CONFIG[ThirdElement.TYPE_PASS_POINTS] = {
    duration: "1 minutes",
    limit: AdsLimits.TYPES.POINTS_THIRD_ELEMENT
};

ThirdElement.ANIMALS_TIMEOUT = cleverapps.config.debugMode ? "10 seconds" : "24 hours";

ThirdElement.CONFIG[ThirdElement.TYPE_ANIMALS] = {
    available: {
        gameLevel: 3
    },
    clpet: {
        1: {
            duration: "1 minutes",
            limit: AdsLimits.TYPES.ANIMALS_THIRD_ELEMENT
        },
        2: {
            duration: "1 minutes",
            limit: AdsLimits.TYPES.ANIMALS_THIRD_ELEMENT
        },
        3: {
            duration: "5 minutes",
            timeout: ThirdElement.ANIMALS_TIMEOUT,
            price: 165
        }
    },
    clpetrare: {
        1: {
            duration: "1 minutes",
            limit: AdsLimits.TYPES.ANIMALS_THIRD_ELEMENT
        },
        4: {
            duration: "5 minutes",
            timeout: ThirdElement.ANIMALS_TIMEOUT,
            price: 350
        }
    },
    clpetlegend: {
        4: {
            duration: "5 minutes",
            timeout: ThirdElement.ANIMALS_TIMEOUT,
            price: 890
        },
        5: {
            duration: "5 minutes",
            timeout: ThirdElement.ANIMALS_TIMEOUT,
            price: 1490
        }
    }
};

ThirdElement.APPEARANCE_TIME = cleverapps.config.name === "mergecraft" ? "4 seconds" : "2 seconds";
