/**
 * Created by Denis Kuzin on 20 october 2021
 */

var AdminPasteClipboardButton = cleverapps.UI.Button.extend({
    ctor: function () {
        var styles = cleverapps.styles.AdminPasteClipboardButton;

        this._super({
            type: cleverapps.styles.UI.Button.Images.editor,
            content: new cc.Sprite(bundles.admin.frames.paste_icon),
            width: styles.width,
            height: styles.height,
            onClicked: this.onPressed.bind(this)
        });

        this.adminLevels = cleverapps.administrator.adminLevels;

        this.adminLevels.on("copyLevelToClipboard", this.update.bind(this), this);
        this.update();
    },

    onPressed: function () {
        cleverapps.focusManager.display({
            focus: "PasteLevelFromClipboard",
            action: function (f) {
                this.adminLevels.levelFromClipboard(function (levelContent) {
                    if (levelContent) {
                        this.adminLevels.pasteLevelFromClipboard(levelContent, f);
                    } else {
                        f();
                    }
                }.bind(this));
            }.bind(this)
        });
    },

    update: function () {
        this.adminLevels.levelFromClipboard(function (levelContent) {
            if (levelContent) {
                this.enable();
            } else {
                this.disable();
            }
        }.bind(this));
    }
});

cleverapps.styles.AdminPasteClipboardButton = {
    width: 45,
    height: 45
};