/**
 * Created by spepa on 11.01.2024
 */

QuestsConfig.china = {
    chsapphire_3: {
        trigger: { unit: { code: "chnarrator", stage: 0 } },
        tutorial: "china_sapphiresource",
        finishTutorial: "china_customer",
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "chsapphire", stage: 3 }
        }
    },
    chdragongreen_3: {
        trigger: { unit: { code: "chdragongreen", stage: 0 } },
        tutorial: "chdragongreen_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragongreen", stage: 3 }
        }
    },
    chdragonyellow_3: {
        trigger: { unit: { code: "chdragonyellow", stage: 0 } },
        tutorial: "chdragonyellow_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragonyellow", stage: 3 }
        }
    },
    chfeathers_3: {
        trigger: { unit: { code: "chfeathers", stage: 0 } },
        tutorial: "chfeathers_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chfeathers", stage: 3 }
        }
    },
    chdragonpurple_3: {
        trigger: { unit: { code: "chdragonpurple", stage: 0 } },
        tutorial: "chdragonpurple_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragonpurple", stage: 3 }
        }
    },
    chcastle_3: {
        trigger: { unit: { code: "chcastle", stage: 0 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chcastle", stage: 3 }
        }
    },
    chbamboo_3: {
        trigger: { unit: { code: "chbamboo", stage: 0 } },
        tutorial: "chbamboo_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chbamboo", stage: 3 }
        }
    },
    chdragonred_3: {
        trigger: { unit: { code: "chdragonred", stage: 0 } },
        tutorial: "chdragonred_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragonred", stage: 3 }
        }
    },
    chdragonrainbow_10: {
        trigger: { unit: { code: "chdragonrainbow", stage: 0 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragonrainbow", stage: 9 }
        }
    },
    chdragonrainbow_1: {
        trigger: { unit: { code: "chdragonrainbow", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "chdragonrainbow", stage: 1 }
        }
    },
    chdragonrainbow_2: {
        trigger: { unit: { code: "chdragonrainbow", stage: 1 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragonrainbow", stage: 2 }
        }
    },
    chdragonrainbow_3: {
        trigger: { unit: { code: "chdragonrainbow", stage: 2 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragonrainbow", stage: 3 }
        }
    },
    chdragonrainbow_4: {
        trigger: { unit: { code: "chdragonrainbow", stage: 3 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragonrainbow", stage: 4 }
        }
    },
    chdragonrainbow_5: {
        trigger: { unit: { code: "chdragonrainbow", stage: 4 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragonrainbow", stage: 5 }
        }
    },
    chdragonrainbow_6: {
        trigger: { unit: { code: "chdragonrainbow", stage: 5 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragonrainbow", stage: 6 }
        }
    },
    chdragonrainbow_7: {
        trigger: { unit: { code: "chdragonrainbow", stage: 6 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragonrainbow", stage: 7 }
        }
    },
    chdragonrainbow_8: {
        trigger: { unit: { code: "chdragonrainbow", stage: 7 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragonrainbow", stage: 8 }
        }
    },
    chdragonrainbow_9: {
        trigger: { unit: { code: "chdragonrainbow", stage: 8 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "chdragonrainbow", stage: 9 }
        }
    }
};