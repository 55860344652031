/**
 * Created by evgeny on 18.06.2024
 */

var Switchable = function (unit, options, choices) {
    UnitComponent.call(this, unit);

    this.unit = unit;
    this.choices = choices;
    this.choice = options.choice || 0;
};

Switchable.prototype = Object.create(UnitComponent.prototype);
Switchable.prototype.constructor = Switchable;

Switchable.prototype.save = function (data) {
    data.choice = this.choice;
};

Switchable.prototype.getChoice = function () {
    return this.choice;
};

Switchable.prototype.setChoice = function (choice, f) {
    f = f || function () {};

    this.choice = choice;
    this.unit.save();
    if (this.view) {
        this.view.animateChoice(choice, f);
    } else {
        f();
    }
};

Switchable.prototype.getLastStage = function () {
    return Families[this.unit.code].units.length - 1;
};

Switchable.prototype.showChoice = function (choice) {
    choice = choice === undefined ? this.choice : choice;
    if (this.view) {
        this.view.showChoice(choice);
    }
};

Switchable.prototype.animateHover = function () {
    if (this.view) {
        this.view.animateHover();
    }
};

Switchable.prototype.stopAnimateHover = function () {
    if (this.view) {
        this.view.stopAnimateHover();
    }
};

Switchable.prototype.handleClick = function () {
    cleverapps.meta.getMainObject().handleMakeChoiceWindow(this);
};