/**
 * Created by iamso on 25.12.2019.
 */

cleverapps.GameSaver = {
    gamesData: [],

    getStoreSlot: function (episodeNo, levelNo) {
        episodeNo = episodeNo || 0;
        levelNo = levelNo || 0;

        if (["match3", "board", "merge"].indexOf(cleverapps.config.type) === -1 || cleverapps.isRumble()) {
            var snapshot = cleverapps.loadedSnapshot && cleverapps.loadedSnapshot.games;
            if (!snapshot || snapshot.episode !== episodeNo || snapshot.level !== levelNo) {
                return undefined;
            }
        }

        if (cleverapps.config.type === "merge") {
            var level = new Level(episodeNo, levelNo);
            var page = TravelBook.findPageById(level.meta.expedition);

            if (page && String(page.location) === String(level.episodeNo) && String(page.level || 0) === String(level.levelNo)) {
                return page.slot || Meta.SLOT_MAIN;
            }
        }

        return levelNo + "x" + episodeNo;
    },

    convertToStoreKey: function (slot) {
        if (cleverapps.config.type === "merge") {
            slot = "0x" + (slot || 0);
        }

        return cleverapps.config.name + connector.platform.getLocalStoragePrefix() + "_level_" + slot;
    },

    findSave: function (key) {
        return this.gamesData.filter(function (save) {
            return Object.keys(save)[0] === key;
        })[0];
    },

    load: function (slot) {
        var key = this.convertToStoreKey(slot);
        var saveData = this.findSave(key);
        // console.log('saveData', saveData)
        saveData = saveData && JSON.parse(saveData[key]);

        if (!saveData) {
            saveData = cleverapps.dataLoader.load(key);
            if (saveData) {
                this.saveInfo(slot, saveData);
            }
        }
        return saveData;
    },

    loadProperty: function (slot, propertyCode) {
        var stored = cleverapps.GameSaver.load(slot);
        return stored && stored[propertyCode];
    },

    reset: function () {
        cleverapps.GameSaver.gamesData = [];

        this.listAllLevels().forEach(function (data) {
            var slot = cleverapps.GameSaver.getStoreSlot(data.episodeNo, data.levelNo);
            cleverapps.GameSaver.removeSave(slot, {});
            cleverapps.GameSyncer.addUpdateTask(slot);
        });
    },

    saveInfo: function (slot, gameData, fromServer) {
        var key = this.convertToStoreKey(slot);
        var existingSave = this.findSave(key);
        if (existingSave) {
            this.gamesData[this.gamesData.indexOf(existingSave)][key] = JSON.stringify(gameData);
        } else {
            while (this.gamesData.length >= DataLoader.FIFO_LIMIT) {
                this.gamesData.shift();
            }

            var saveData = {};
            saveData[key] = JSON.stringify(gameData);
            this.gamesData.push(saveData);
        }
        cleverapps.dataLoader.save(key, gameData, {
            fifoKey: DataLoader.TYPES.GAME_SAVE_FIFO
        });

        if (!fromServer) {
            cleverapps.GameSyncer.addUpdateTask(slot);
        }
    },

    removeSave: function (slot) {
        var key = this.convertToStoreKey(slot);
        var save = this.findSave(key);
        if (save) {
            this.gamesData.splice(this.gamesData.indexOf(save), 1);
        }
        cleverapps.dataLoader.remove(key, DataLoader.TYPES.GAME_SAVE_FIFO);
    },

    listAllLevels: function () {
        var levels = [];

        Object.keys(bundles).forEach(function (bundleName) {
            var bundle = bundles[bundleName];
            if (!bundle) {
                return;
            }

            if (bundle.meta.episode && bundle.meta.episode.levels && bundles[Level.BundleId(bundle.meta.episode.episodeNo)]) {
                bundle.meta.episode.levels.forEach(function (data, levelNo) {
                    levels.push({
                        episodeNo: bundle.meta.episode.episodeNo,
                        levelNo: levelNo
                    });
                });
            } else if (bundle.meta.location) {
                levels.push({
                    episodeNo: bundle.meta.location,
                    levelNo: 0
                });
            }
        });

        return levels;
    }
};
