/**
 * Created by andrey on 31.07.2023
 */

var FogBalloon = function (tile, block) {
    this.tile = tile;
    this.block = block;

    this.shown = true;
    this.state = this.tile.state;

    this.onHide = function () {};
    this.onShow = function () {};

    this.hide();
};

FogBalloon.prototype.updateState = function (state, silent) {
    if (this.state === state) {
        return;
    }
    this.state = state;

    if (this.state === FogBlock.CANOPEN) {
        this.show(silent);
    }
};

FogBalloon.prototype.show = function (silent) {
    if (!this.shown && this.state === FogBlock.CANOPEN) {
        this.shown = true;
        this.onShow(silent);
    }
};

FogBalloon.prototype.hide = function (silent) {
    if (this.shown) {
        this.shown = false;
        this.onHide(silent);
    }
};

FogBalloon.ChoosePosition = function (positions, head) {
    var map = Map2d.currentMap;

    var extendedDirs = Object.values(Iso);
    var dirs = ISO_NEIGHBORS;
    var balloonCell;

    positions.forEach(function (position) {
        dirs.forEach(function (direction) {
            var cell = cc.p(position.x + direction.x, position.y + direction.y);

            if (map.getValue(Map2d.LAYER_WATER, cell.x, cell.y) !== Map2d.TILE_WATER) {
                return;
            }

            var countBadDirections = 0;

            extendedDirs.forEach(function (currDir) {
                var neighborWaterCells = 0;
                for (var step = 1; step <= 2; step++) {
                    if (map.getValue(Map2d.LAYER_WATER, cell.x + currDir.x * step, cell.y + currDir.y * step) !== Map2d.TILE_WATER) {
                        neighborWaterCells++;
                    }
                }

                if (neighborWaterCells < 2) {
                    countBadDirections++;
                }
            });

            if (countBadDirections > 3 || head && cc.getDistanceChebyshev(cell, head) <= 1) {
                return;
            }
            if (!balloonCell || cell.y < balloonCell.y) {
                balloonCell = cell;
                return;
            }
            if (Math.abs(cell.y - balloonCell.y) <= 1) {
                if (cell.x < Map2d.currentMap.width / 2 && cell.x < balloonCell.x) {
                    balloonCell = cell;
                }
                if (cell.x >= Map2d.currentMap.width / 2 && cell.x > balloonCell.x) {
                    balloonCell = cell;
                }
            }
        });
    });

    return balloonCell || positions[0];
};