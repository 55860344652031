/**
 * Created by razial on 09.01.2021
 */

var PrizeView = cc.Scale9Sprite.extend({
    ctor: function (prizes, options) {
        var skin = this.getSkin();
        var styles = cleverapps.styles.PrizeView[skin];

        var icon;

        prizes = prizes || {};

        var prizeToString = function (prize) {
            if (prize instanceof Unit) {
                return prize.getKey();
            }
            return JSON.stringify(prize);
        };

        if (prizes.waiting) {
            icon = new cleverapps.Spine(bundles.timer_merge.jsons.timer_json);
            icon.setScale(styles.animation.scale || 1.0);
            icon.setAnchorPoint(0.5, 0.5);
            icon.setPositionRound(styles.animation);
            icon.setAnimation(0, "animation", true);
        } else if (prizes.minigame) {
            icon = new cc.Sprite(bundles.reward_icons_merge.frames.pickaxe);
        } else if (prizes.sprite) {
            icon = new cc.Sprite(prizes.sprite);
        } else if (PrizeView.SKINS[skin].prize_icon) {
            icon = new cc.Sprite(PrizeView.SKINS[skin].prize_icon);
        } else {
            var level = Game.currentGame && Game.currentGame.level;

            var otherUnits = prizes.filter(function (unit) {
                return !unit.resource && !unit.ingredient && level && !level.families[unit.code];
            });

            if (otherUnits.length > 0) {
                cleverapps.throwAsync("Other world units in prizes - " + otherUnits.map(prizeToString).join(",")
                    + " - for unit " + (options.unit && options.unit.getKey()));
            }

            var prize = prizes[0];
            if (prize && prize.resource) {
                icon = new Reward(prize.resource, prize.amount).getSmallIcon();
            } else if (prize && prize.ingredient) {
                icon = Game.currentGame.harvested.getIngredientSmallIcon(prize.ingredient);
            } else if (prize && prize.code !== undefined && prize.stage !== undefined) {
                icon = UnitView.getUnitImage(prize, { alignAnchorX: true });
                icon.clearTrack && icon.clearTrack(0);
            } else {
                cleverapps.throwAsync("Empty sprite prize - " + prizeToString(prizes)
                    + " - for unit " + (options.unit && options.unit.getKey()));
                icon = new cc.Node();
            }
        }

        this._super(options.warning ? PrizeView.SKINS[skin].background_warning : PrizeView.SKINS[skin].background);

        this.debugId = "PrizeView";

        this.setAnchorPoint(0.5, 0);
        this.setLocalZOrder(1);

        if (styles.width && styles.height) {
            this.setContentSize2(styles.width, styles.height);
        }

        if (cleverapps.gameModes.silentInfoView) {
            this.setVisible(false);
        }

        var pointer = new cc.Sprite(options.warning === true ? PrizeView.SKINS[skin].pointer_warning : PrizeView.SKINS[skin].pointer);
        pointer.setAnchorPoint(0.5, 0.5);
        pointer.setPositionRound(this.width / 2, styles.pointer.dy);
        this.addChild(pointer);

        icon.setPositionRound(this.width / 2, this.height / 2);
        cleverapps.UI.fitToBox(icon, {
            width: this.width - styles.padding.x * 2,
            height: this.height - styles.padding.y * 2
        });
        this.addChild(icon);

        if (options.onClick && !cleverapps.environment.isSceneWithPreview()) {
            cleverapps.UI.onClick(this, this.createListener(options.onClick));
        }
    },

    getSkin: function () {
        return cleverapps.skins.getSlot("prizeView") || "default";
    },

    runAnimation: function () {
        var skin = this.getSkin();
        var styles = cleverapps.styles.PrizeView[skin];

        this.stopAllActions();
        this.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.MoveBy(styles.duration, 0, styles.delta).easing(cc.easeInOut(1.7)),
                new cc.MoveBy(styles.duration, 0, -styles.delta).easing(cc.easeInOut(1.7))
            )
        ));
    }
});

PrizeView.SKINS = {
    "default": {
        background: bundles.merge.frames.background,
        pointer: bundles.merge.frames.pointer2,

        background_warning: bundles.merge.frames.background_warning,
        pointer_warning: bundles.merge.frames.pointer2_warning
    },

    "adventure": {
        background: bundles.merge.frames.simple_info_bg,
        pointer: bundles.merge.frames.simple_info_pointer,

        background_warning: bundles.merge.frames.simple_info_bg_warning,
        pointer_warning: bundles.merge.frames.simple_info_pointer_warning,

        prize_icon: bundles.merge.frames.simple_info_prize
    }
};

cleverapps.styles.PrizeView = {
    "default": {
        width: 90,
        height: 90,

        padding: {
            x: 12,
            y: 10
        },

        duration: 1,
        delta: 10,

        pointer: {
            dy: 4
        },

        animation: {
            x: { align: "center" },
            y: { align: "center", dy: 3 },
            scale: 0.8
        }
    },

    "adventure": {
        duration: 1,
        delta: 10,

        padding: {
            x: 28,
            y: 28
        },

        pointer: {
            dy: 0
        },

        animation: {
            x: { align: "center" },
            y: { align: "center", dy: 3 },
            scale: 0.8
        }
    }
};
