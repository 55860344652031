/**
 * Created by mac on 12/28/20
 */

var WandsGrowing = {
    prize: function (level, unit) {
        var amount;

        switch (unit.stage) {
            case 1: amount = 1; break;
            case 2: amount = 3; break;
            default: amount = level + 3;
        }

        return [{
            units: [{ resource: "wands", amount: amount }]
        }];
    }
};