/**
 * Created by andrey on 01.10.2024
 */

Map2dFences.prototype.updateNeighbours = function (x, y) {
    var map2d = Map2d.currentMap;

    for (var i in Map2dFences.DIRS) {
        var dir = Map2dFences.DIRS[i];
        var nx = x + dir.x;
        var ny = y + dir.y;

        map2d.removeTiles(nx, ny);

        this.removeFence(nx, ny);
        if (this.getRegion(nx, ny)) {
            this.addFence(nx, ny);
        }

        map2d.showTiles(nx, ny);
    }
};

Map2dFences.prototype.add = function (x, y, regionName) {
    this.addRegion(x, y, regionName);
    this.addFence(x, y, regionName);

    Map2d.currentMap.showTiles(x, y);
    this.updateNeighbours(x, y);
};

Map2dFences.prototype.remove = function (x, y) {
    Map2d.currentMap.removeTiles(x, y);

    this.removeRegion(x, y);
    this.removeFence(x, y);

    this.updateNeighbours(x, y);

    Map2d.currentMap.showTiles(x, y);
};