/**
 * Created by spepa on 27.10.2022
 */

var UnitGreeters = function () {
    this.fresh = [];
    this.greeters = [];
    cleverapps.eventBus.on("unitAvailable", this.onUnitAvailable.bind(this), this);
};

UnitGreeters.prototype.addGreeter = function (greeter) {
    if (greeter.isLifted()) {
        return;
    }
    this.greeters.push(greeter);
};

UnitGreeters.prototype.removeGreeter = function (greeter) {
    this.greeters = this.greeters.filter(function (unit) {
        return greeter !== unit;
    });
};

UnitGreeters.prototype.destructor = function () {
    runCleaners(this);
};

UnitGreeters.prototype.onUnitAvailable = function (unit) {
    if (unit instanceof Unit) {
        this.greetWithTutor(unit);
        if (unit.wantsToGreet()) {
            this.fresh.push(unit);
            Game.currentGame.counter.trigger();
        }
    }

    for (var i = 0; i < this.greeters.length; i++) {
        if (this.greeters[i].wantsToGreet()) {
            var greeter = this.greeters.splice(i, 1)[0];
            this.fresh.push(greeter);
            Game.currentGame.counter.trigger();
            break;
        }
    }
};

UnitGreeters.prototype.greetWithTutor = function (unit) {
    var tutorial = MergeTutorials["greeting_" + Unit.GetKey(unit)];
    if (!tutorial) {
        return false;
    }

    var greeter = {};
    greeter.makeGreetings = function (f) {
        Game.currentGame.tutorial.showTutorial(tutorial, f);
    };
    this.fresh.push(greeter);
    Game.currentGame.counter.trigger();
    return true;
};

UnitGreeters.prototype.processFresh = function () {
    if (!this.fresh.length || cleverapps.focusManager.isFocused() || Map2d.currentMap.dragging) {
        return;
    }

    var greeter = this.fresh.shift();

    cleverapps.focusManager.display({
        focus: "StartUnitGreeting",
        action: function (f) {
            greeter.makeGreetings(f);
        }
    });
};