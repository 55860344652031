/**
 * Created by andrey on 20.05.2022.
 */

var Bank = function (unit, options) {
    UnitComponent.call(this, unit);

    this.mined = options.mined || 0;
    this.lastHarvested = options.lh && cleverapps.expandTime(options.lh) || 0;
    this.clearTime = options.ct && cleverapps.expandTime(options.ct) || 0;

    if (cleverapps.config.editorMode || cleverapps.config.adminMode || cleverapps.config.wysiwygMode) {
        return;
    }

    cleverapps.rewardedAdsManager.onRewardedRefresh(this.update.bind(this), this);

    this.setState(options.state || Bank.STATES.WAIT);
    this.update();
};

Bank.prototype = Object.create(UnitComponent.prototype);
Bank.prototype.constructor = Bank;

Bank.prototype.onPrizeHarvested = function () {
    this.nextPosition();
};

Bank.prototype.onChangeState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }

    var ready = this.state === Bank.STATES.READY && !this.unit.additionalViewsHidden;
    if (ready) {
        cleverapps.audio.playSound(bundles.bank.urls.bank_ready_effect);
    }
    var pulsing = this.unit.findComponent(Pulsing);
    pulsing.setActive(ready);
};

Bank.prototype.destructor = function () {
    runCleaners(this);
    this.clearTimeout();
};

Bank.prototype.getTimeLeft = function () {
    return Math.max(0, this.lastHarvested + Bank.TIMEOUT - Date.now());
};

Bank.prototype.getNoAdsTimeLeft = function () {
    return Math.max(0, Bank.AD_TIMEOUT - cleverapps.rewardedAdsManager.getNoRewardedTime());
};

Bank.prototype.isAdsAvailable = function () {
    return !cleverapps.paymentsHistory.isVIP() && cleverapps.rewardedAdsManager.isEnabled() && this.getNoAdsTimeLeft();
};

Bank.prototype.isAdsReady = function () {
    return cleverapps.adsLimits.state(AdsLimits.TYPES.BANK) === AdsLimits.STATE_READY;
};

Bank.prototype.update = function () {
    var state = this.state;
    var isReady = (this.isAdsReady() || !this.isAdsAvailable()) && !this.getTimeLeft();

    if (this.state === Bank.STATES.WAIT) {
        if (isReady) {
            state = Bank.STATES.READY;
        }
    } else if (this.state === Bank.STATES.READY) {
        if (!isReady) {
            state = Bank.STATES.WAIT;
        }
    }

    this.setState(state);

    this.clearTimeout();
    if (!isReady) {
        this.timeout = new cleverapps.LongTimeout(this.update.bind(this), this.getTimeLeft() || this.getNoAdsTimeLeft());
    }
};

Bank.prototype.setState = function (state) {
    if (this.state === state) {
        return;
    }

    this.state = state;

    if (this.state === Bank.STATES.WAIT) {
        this.unit.setPrizes(this.getTimeLeft() > 0 ? undefined : { waiting: true });
    } else if (this.state === Bank.STATES.READY) {
        if (this.clearTime + Bank.CLEAR_TIMEOUT < Date.now()) {
            this.clearTime = Date.now();
            this.mined = 0;
        }

        this.unit.setPrizes(this.listPrize());
    } else if (this.state === Bank.STATES.WATCHED) {
        this.unit.setPrizes(this.listPrize());
    }

    this.onChangeState();
};

Bank.prototype.handlePrizes = function () {
    if (this.state === Bank.STATES.WATCHED) {
        return;
    }

    if (!Map2d.currentMap.countEmptySlots()) {
        cleverapps.centerHint.createTextHint("Spawn.nospace", { left: 1 });
        return true;
    }

    var callback = function () {
        this.setState(Bank.STATES.WATCHED);
        this.unit.takePrizes();

        cleverapps.adsLimits.watch(AdsLimits.TYPES.BANK);
    }.bind(this);

    cleverapps.eventLogger.logEvent("bank-start-" + (this.isAdsAvailable() && this.isAdsReady() ? "ads" : "noads"));

    if (!this.isAdsAvailable()) {
        callback();
    } else if (this.isAdsReady()) {
        cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.BANK, callback);
    } else {
        cleverapps.centerHint.createTextHint("Bank.waitAd");
    }

    return true;
};

Bank.prototype.listPrize = function () {
    var index = Math.min(this.mined, Bank.PRIZES.length - 1);
    return [Bank.PRIZES[index]];
};

Bank.prototype.nextPosition = function () {
    var prize = this.listPrize()[0];
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BANK_COINS, { value: Families[prize.code].units[prize.stage].value || 0.33 });

    this.lastHarvested = Date.now();
    this.mined++;
    this.setState(Bank.STATES.WAIT);
    this.unit.save();
    this.update();
};

Bank.prototype.save = function (data) {
    if (this.lastHarvested && this.lastHarvested + Bank.TIMEOUT > Date.now()) {
        data.lh = cleverapps.compactTime(this.lastHarvested);
    }
    if (this.clearTime && this.clearTime + Bank.CLEAR_TIMEOUT > Date.now()) {
        data.ct = cleverapps.compactTime(this.clearTime);
    }
    if (this.mined) {
        data.mined = this.mined;
    }
    if (this.state) {
        data.state = this.state;
    }
};

Bank.prototype.clearTimeout = function () {
    if (this.timeout !== undefined) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    }
};

Bank.prototype.isAdsIconVisible = function () {
    return this.state === Bank.STATES.READY && this.isAdsAvailable();
};

Bank.prototype.isTimerVisible = function () {
    return this.state === Bank.STATES.WAIT && this.getTimeLeft();
};

Bank.STATES = {
    WAIT: 0,
    READY: 1,
    WATCHED: 2
};

Bank.CLEAR_TIMEOUT = cleverapps.parseInterval(cleverapps.config.debugMode ? "5 minutes" : "12 hour");
Bank.TIMEOUT = cleverapps.parseInterval(cleverapps.config.debugMode ? "1 minutes" : "3 hours");
Bank.AD_TIMEOUT = cleverapps.parseInterval(cleverapps.config.debugMode ? "30 seconds" : "10 minutes");

Bank.PRIZES = [
    {
        code: "coins",
        stage: 2
    },
    {
        code: "coins",
        stage: 1
    },
    {
        code: "coins",
        stage: 0
    }
];
