/**
 * Created by iamso on 18.05.21
 */

var BarrelAdsWindow = CleverappsWindow.extend({
    onWindowLoaded: function (barrel) {
        this.barrel = barrel;

        this._super({
            name: "barreladswindow",
            title: "BarrelAdsWindow.title",
            content: this.createContent(),
            styles: cleverapps.styles.BarrelAdsWindow.styles
        });
    },

    createContent: function () {
        var items = this.barrel.listItems().map(function (reward) {
            return this.createItem(Prizes.Generate([reward]));
        }, this);

        var itemsNode = new cleverapps.Layout(items, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.BarrelAdsWindow.itemsMargin
        });

        var text = cleverapps.UI.generateOnlyText("BarrelAdsWindow.subTitle", cleverapps.styles.FONTS.BARREL_ADS_TITLE);
        text.setDimensions(itemsNode.width * 0.8, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        var content = new cleverapps.Layout([text, itemsNode], {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.BarrelAdsWindow.contentMargin
        });

        items.forEach(function (item) {
            item.icon.replaceParentSamePlace(content);
        });

        return content;
    },

    createItem: function (rewards) {
        var styles = cleverapps.styles.BarrelAdsWindow.item;

        var item = new cc.Node();
        item.setAnchorPoint(0.5, 0.5);
        item.setContentSize2(styles);

        var bg = cleverapps.UI.createScale9Sprite(bundles.windows.frames.tile_bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(item.getContentSize());
        item.addChild(bg, -1);
        bg.setPositionRound(item.width / 2, item.height / 2);

        var icon = this.createIcon(rewards[0]);
        item.addChild(icon);
        icon.setPositionRound(styles.iconNode);

        var strip = this.createItemStrip(rewards);
        item.addChild(strip);
        strip.setPositionRound(styles.strip);

        var title = cleverapps.UI.generateOnlyText(cleverapps.unitsLibrary.getUnitName(rewards[0]), cleverapps.styles.FONTS.BARREL_ADS_ITEM_TITLE || cleverapps.styles.FONTS.BARREL_ADS_TITLE);
        item.addChild(title);
        title.setPositionRound(styles.title);
        title.setDimensions(styles.title.maxWidth, 0);
        title.fitTo(undefined, styles.title.textHeight);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var layout = new cleverapps.Layout([item, this.createItemButton(rewards)], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.buttonMargin
        });
        layout.icon = icon;
        return layout;
    },

    createItemStrip: function (rewards) {
        var styles = cleverapps.styles.BarrelAdsWindow.item.strip;

        var strip = cleverapps.UI.createScale9Sprite(bundles.windows.frames.tile_amount_png);
        strip.setContentSize2(styles.width, strip.height);

        var amount = cleverapps.UI.generateImageText("x" + rewards.length, cleverapps.styles.FONTS.BARREL_ADS_AMOUNT);
        strip.addChild(amount);
        amount.setPositionRound(styles.amount);

        return strip;
    },

    createIcon: function (reward) {
        var styles = cleverapps.styles.BarrelAdsWindow.item.iconNode;

        var unit = new UnitOnCell(reward, {
            shining: styles.shining,
            noWrap: styles.noWrap
        });
        unit.setScale(styles.scale);
        return unit;
    },

    createItemButton: function (rewards) {
        var styles = cleverapps.styles.BarrelAdsWindow.item.button;

        return new cleverapps.UI.Button({
            text: "##FREE",
            type: cleverapps.styles.UI.Button.Images.button_blue,
            width: styles.width,
            height: styles.height,
            onClicked: this.barrel.showItemAds.bind(this.barrel, rewards, this.createListener(function () {
                this.close();
            }.bind(this)))
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BARREL_ADS_TITLE: {
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    BARREL_ADS_AMOUNT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.BarrelAdsWindow = {
    itemsMargin: 65,
    contentMargin: 20,

    item: {
        width: 350,
        height: 540,
        buttonMargin: 20,

        iconNode: {
            x: { align: "center" },
            y: { align: "center", dy: 120 },
            scale: 1.5,
            shining: true
        },

        strip: {
            width: 390,
            x: { align: "center", dx: 8 },
            y: { align: "bottom", dy: 120 },

            amount: {
                x: { align: "center" },
                y: { align: "center", dy: 7 }
            }
        },

        title: {
            maxWidth: 320,
            x: { align: "center" },
            y: { align: "bottom", dy: 50 },
            textHeight: 80
        },

        button: {
            width: 350,
            height: 120
        }
    }
};
