/**
 * Created by mac on 9/25/20
 */

var GitManager = function () {
    cleverapps.EventEmitter.call(this);

    this.folders = [];
    this.processing = 0;
    this.error = undefined;

    this.state = GitManager.STATE_READY;
    this.remote = {};
    this.local = {};
    this.cached = {};

    this.urls = {};

    cc.loader.getRes = cleverapps.extendFunc(cc.loader.getRes, function (url) {
        return cleverapps.git.urls[url && url.originalPath && url.originalPath()] || this._super(url);
    });

    addEventListener("beforeunload", this.handlePageUnload.bind(this));
};

GitManager.prototype = Object.create(cleverapps.EventEmitter.prototype);
GitManager.prototype.constructor = GitManager;

GitManager.prototype.updateStatus = function () {
    var status = GitManager.STATUS_EMPTY;
    var error = this.getError();

    if (this.checkAvailableErrors()) {
        status = GitManager.STATUS_UNAVAILABLE;
    } else if (this.isProcessing()) {
        status = GitManager.STATUS_PROCESSING;
    } else if (error === GitManager.PUSH || error === GitManager.REVERT) {
        status = GitManager.STATUS_ERROR;
    } else if (this.state === GitManager.STATE_DETACHED) {
        status = GitManager.STATUS_DETACHED;
    } else if (this.hasLocalChanges() || this.hasRemoteChanges()) {
        status = GitManager.STATUS_REDY;
    }

    if (status !== this.status) {
        this.status = status;

        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(this.trigger.bind(this, "changed"), 0);
    }
};

GitManager.prototype.getStatus = function () {
    return this.status;
};

GitManager.prototype.setState = function (state, changes) {
    this.state = state || GitManager.STATE_NONE;
    this.remote = changes || {};
};

GitManager.prototype.checkAvailableErrors = function () {
    if (cleverapps.isLocalhost()) {
        return undefined;
    }

    if (!this.getUserName()) {
        return "Login before you can process changes.";
    }

    return undefined;
};

GitManager.prototype.getUserName = function () {
    return cleverapps.dataLoader.localStorage.getItem("adminAccess_username");
};

GitManager.prototype.getError = function () {
    return this.error;
};

GitManager.prototype.isProcessing = function () {
    return this.processing > 0;
};

GitManager.prototype.changeFolders = function (folders) {
    this.folders = cleverapps.toArray(folders) || [];
    this.update();
};

GitManager.prototype.update = function () {
    var error = this.checkAvailableErrors();
    if (error) {
        return;
    }

    this.request(GitManager.STATUS, {});
};

GitManager.prototype.validate = function (files) {
    files = cleverapps.toArray(files).map(function (file) {
        file = file.split(":");
        return file[1] || file[0];
    });

    var errors = [];

    for (var i = 0; i < files.length; i++) {
        var changes = this.local[files[i]];
        var editor = GitManager.EDITORS[changes.type];
        var content = this.getContent(files[i]);

        var fileErrors = editor.getValidateError && editor.getValidateError(content);
        if (fileErrors) {
            errors = errors.concat(fileErrors);
        }
    }

    if (errors.length > 0) {
        cleverapps.git.displayErrorWindow(errors.join("\n"));
    }

    return errors.length === 0;
};

GitManager.prototype.push = function (comment, files, callback) {
    if (!this.validate(files)) {
        return;
    }

    callback = cleverapps.once(callback);
    files = cleverapps.toArray(files).map(function (file) {
        file = file.split(":");
        return file[1] || file[0];
    });

    var options = {
        timeout: 30000
    };

    var pending = {};

    files.forEach(function (file) {
        if (this.local[file]) {
            pending[file] = this.local[file].content;
        }

        if (this.remote[file] && !cleverapps.isLocalhost()) {
            pending[file] = true;
        }
    }.bind(this));

    var username = this.getUserName();
    var data = {
        comment: "user: " + username + "\ncomment: \"" + comment + "\"",
        files: pending
    };

    var onComplete = function (error) {
        if (error) {
            callback(error);
            return;
        }

        for (var file in pending) {
            if (this.local[file] && this.local[file].content === pending[file]) {
                delete this.local[file];
            }
        }

        this.save();
        this.updateStatus();

        if (!cleverapps.config.wysiwygMode) {
            cleverapps.administrator && cleverapps.administrator.log.createEntry({
                type: AdminLog.GIT_PUSH,
                data: Object.keys(pending).join(" ")
            });
        }

        callback();
    }.bind(this);

    this.request(GitManager.PUSH, data, onComplete, options);
};

GitManager.prototype.revert = function (files, callback) {
    files = cleverapps.toArray(files);

    var local = [];
    var remote = [];

    files.forEach(function (path) {
        path = path.split(":");

        var file = path[1] || path[0];

        if (path[0] === "local") {
            local.push(file);
        } else {
            remote.push(file);
        }
    });

    this.revertLocalFiles(local);
    this.revertRemoteFiles(remote, callback);
};

GitManager.prototype.revertLocalFiles = function (files) {
    this.restore(files);
    this.save();
    this.updateStatus();
};

GitManager.prototype.revertRemoteFiles = function (files, callback) {
    callback = cleverapps.once(callback);

    if (!files.length) {
        callback();
        return;
    }

    var data = {
        files: files
    };

    var onComplete = function (error) {
        if (error) {
            callback(error);
            return;
        }

        if (!cleverapps.config.wysiwygMode) {
            cleverapps.administrator && !cleverapps.config.wysiwygMode && cleverapps.administrator.log.createEntry({
                type: AdminLog.GIT_REVERT,
                data: data.files.join(" ")
            });
        }

        callback();
    };

    this.request(GitManager.REVERT, data, onComplete);
};

GitManager.prototype.request = function (command, data, callback, options) {
    options = Object.assign({ ignoreNoRest: true }, options || {});
    callback = cleverapps.once(callback);

    var onError = function (error) {
        error = "git error: " + error;

        console.error(error);
        cleverapps.notification.create(error);

        callback(error);
    };

    var error = this.checkAvailableErrors();
    if (error) {
        onError(error);
        return;
    }

    if (this.isProcessing() && GitManager.STATUS !== command) {
        onError("Previous request still in process. Try again later.");
        return;
    }

    this.processing += 1;
    this.updateStatus();

    var onSuccess = function (response) {
        this.processing -= 1;

        var error = response && response.error;
        if (error) {
            this.error = command;
            this.updateStatus();
            onError(error);
        } else {
            this.error = undefined;
            this.setState(response.state, response.changes);
            this.updateStatus();
            callback();
        }
    }.bind(this);

    var onFailure = function (request) {
        this.processing -= 1;
        this.error = command;
        this.updateStatus();
        onError("Request failed for '" + command + "' git command with error " + request.status + " " + request.statusText);
    }.bind(this);

    cleverapps.RestClient.post("/gitcommands/" + command, data, onSuccess, onFailure, options);
};

GitManager.prototype.hasRemoteChanges = function () {
    return this.listRemoteChanges().length > 0;
};

GitManager.prototype.listRemoteChanges = function (folders) {
    folders = cleverapps.toArray(folders);

    return Object.keys(this.remote).filter(function (file) {
        return !folders || GitManager.match(file, folders);
    });
};

GitManager.prototype.hasLocalChanges = function () {
    return this.listLocalChanges().length > 0;
};

GitManager.prototype.listLocalChanges = function (folders) {
    folders = cleverapps.toArray(folders);

    return Object.keys(this.local).filter(function (file) {
        return !folders || GitManager.match(file, folders);
    });
};

GitManager.prototype.listAllChanges = function () {
    var files = [];

    this.listLocalChanges().forEach(function (file) {
        files.push("local:" + file);
    });

    this.listRemoteChanges().forEach(function (file) {
        files.push("remote:" + file);
    });

    return files;
};

GitManager.prototype.handleSceneClose = function () {
    if (this.hasLocalChanges()) {
        if (!window.confirm("There are some unsaved changes, are you sure you want to exit? All unsaved changes will be lost.")) {
            this.displayWindow();
            return false;
        }
    }

    if (this.hasRemoteChanges()) {
        if (!window.confirm("Some changes on server wasn't pushed properly, are you sure you want to exit?")) {
            this.displayWindow();
            return false;
        }
    }

    if (this.isProcessing()) {
        if (!window.confirm("Some requests still in process, are you sure you want to exit? All unsaved changes will be lost.")) {
            return false;
        }
    }

    cleverapps.git.changeFolders(undefined);

    return true;
};

GitManager.prototype.handlePageUnload = function (event) {
    this.deprecateLocalChanges();

    if (this.hasLocalChanges() || this.hasRemoteChanges() || this.isProcessing()) {
        this.displayWindow();
        event.preventDefault();
        event.returnValue = "Please save changes. All unsaved changes will be lost.";
        return false;
    }

    return true;
};

GitManager.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.GIT) || {};
    var local = data.local || {};

    this.local = {};

    for (var file in local) {
        var changes = local[file];
        if (!changes.deprecated && (changes.date > Date.now() - GitManager.CHANGES_LIFETIME)) {
            this.local[file] = changes;
        }
    }

    for (file in this.local) {
        changes = this.local[file];
        var editor = GitManager.EDITORS[changes.type];

        this.cache(changes.type, changes);

        editor.load(file, changes);
    }

    this.updateStatus();
};

GitManager.prototype.save = function () {
    var data = {
        local: this.local
    };

    cleverapps.dataLoader.save(DataLoader.TYPES.GIT, data);
};

GitManager.prototype.edit = function (type, data, callback) {
    var editor = GitManager.EDITORS[type];

    this.cache(type, data);

    var changes = editor.apply(data);

    for (var file in changes) {
        changes[file].type = type;
        changes[file].date = Date.now();
        this.local[file] = changes[file];
    }

    this.save();
    this.updateStatus();

    if (callback) {
        cleverapps.dataLoader.onSaved(callback);
    }
};

GitManager.prototype.remove = function (type, data) {
    data.content = null;
    this.edit(type, data);
};

GitManager.prototype.cache = function (type, data) {
    var editor = GitManager.EDITORS[type];
    var cached = editor.cache(data);

    for (var file in cached) {
        if (!this.cached[file]) {
            cached[file].type = type;
            this.cached[file] = cached[file];
        }
    }
};

GitManager.prototype.restore = function (files) {
    for (var i = 0; i < files.length; ++i) {
        var file = files[i];

        var cached = this.cached[file];
        var editor = GitManager.EDITORS[cached.type];

        editor.restore(file, cached);

        delete this.cached[file];
        delete this.local[file];
    }
};

GitManager.prototype.displayWindow = function (action) {
    cleverapps.focusManager.display({
        stack: true,
        focus: "GitInfoWindow",
        action: function (f) {
            new GitInfoWindow(action);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};

GitManager.prototype.displayErrorWindow = function (debugInfo) {
    if (cleverapps.focusManager.isFocused()) {
        new ErrorWindow({
            debugInfo: debugInfo
        });
    } else {
        cleverapps.focusManager.display({
            focus: "ErrorWindow",
            action: function (f) {
                new ErrorWindow({
                    debugInfo: debugInfo
                });
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
    }
};

GitManager.prototype.getContent = function (file) {
    file = file.split(":");
    file = file[1] || file[0];

    var changes = this.local[file];
    return changes && changes.content;
};

GitManager.prototype.deprecateLocalChanges = function () {
    if (cleverapps.isLocalhost()) {
        return;
    }

    var needSave = false;

    for (var file in this.local) {
        this.local[file].deprecated = true;
        needSave = true;
    }

    if (needSave) {
        this.save();
        cleverapps.dataLoader.processSaveQueue();
    }
};

GitManager.CHANGES_LIFETIME = cleverapps.parseInterval("1 day");
