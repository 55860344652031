/**
 * Created by iamso on 11.03.2022
 */

var RestoreProgressButton = cleverapps.UI.Button.extend({
    alwaysOn: true,

    ctor: function () {
        var styles = cleverapps.styles.RestoreProgressButton;
        var size = styles.size ? styles.size[cleverapps.resolution.mode] : styles;

        var content = this.content = cleverapps.UI.generateOnlyText("RestoreProgress", cleverapps.styles.FONTS.BUTTON_TEXT);
        content.setDimensions(size.width, 0);
        content.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        content.fitTo(undefined, size.height);

        this._super({
            width: size.width,
            height: size.height,
            content: content,
            type: cleverapps.styles.UI.Button.Images.button_blue,
            onClicked: function () {
                cleverapps.restoreProgress.restore();
            }
        });

        this.content.setPositionRound(styles.content);

        this.setCascadeOpacityEnabledRecursively(true);
        this.setLocalZOrder(BaseWindow.WINDOWS_ZORDER + 9); // higher than scene film effect and shadow
        this._setGlobalZOrder(true);

        cleverapps.restoreProgress.onUpdate = this.createListener(this.updateDynamicPosition.bind(this));
        cleverapps.restoreProgress.onRemove = this.createListener(this.onRemove.bind(this));

        this.updateSize();
        this.hide(true);
    },

    updateSize: function () {
        if (!cleverapps.styles.RestoreProgressButton.size) {
            return;
        }

        var styles = cleverapps.styles.RestoreProgressButton;
        var size = styles.size[cleverapps.resolution.mode];
        this.resize(size.width, size.height);
        this.content.setFontSize(cleverapps.styles.FONTS.BUTTON_TEXT.size);
        this.content.setDimensions(size.width, 0);
        this.content.fitTo(undefined, size.height);

        this.content.setPositionRound(styles.content);
    },

    onRemove: function () {
        if (this.removing) {
            return;
        }
        this.removing = true;

        this.updateDynamicPosition = function () {};

        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.FadeOut(0.3),
            new cc.RemoveSelf()
        ));
    },

    updateDynamicPosition: function (silent) {
        if (!cleverapps.restoreProgress.isVisible()) {
            this.hide(silent);
            return;
        }

        var scene = cleverapps.scenes.getRunningScene();
        var avoidNodes = scene.getAvoidNodes(RestoreProgressButton.OverlappingNodes)
            .filter(function (node) {
                return node.isShown !== false;
            });

        this.showOverlappingNodes(avoidNodes);

        var styles = cleverapps.styles.RestoreProgressButton;
        var preferredPositions = styles.position;
        if (this.preferredPosition) {
            preferredPositions = [this.preferredPosition].concat(preferredPositions);
        }

        var position = cleverapps.UI.findPosition(this, {
            preferredPositions: preferredPositions,
            avoidNodes: avoidNodes,
            offset: styles.offset
        });

        this.preferredPosition = position && position.preferred || this.preferredPosition;

        if (!position) {
            this.hide(silent);
        } else if (!this.isShown) {
            this.move(position, true);
            this.show(silent);
        } else {
            this.move(position, silent);
        }
    },

    move: function (position, silent) {
        if (cc.pointEqualToPoint(position, this.movePosition, cleverapps.styles.RestoreProgressButton.eps)) {
            return;
        }
        this.stopAllActions();

        this.movePosition = position;

        if (silent) {
            this.setPosition(position.x, position.y);
            return;
        }

        this.runAction(new cc.Sequence(
            new cc.FadeOut(0.3),
            new cc.MoveTo(0, position.x, position.y),
            new cc.FadeIn(0.3)
        )).setFinalize(function () {
            this.setOpacity(255);
            this.setPosition(position.x, position.y);
        }.bind(this));
    },

    show: function (silent) {
        if (this.isShown === true) {
            return;
        }
        this.isShown = true;

        this.stopAllActions();
        this.setVisible(true);

        if (silent) {
            this.setOpacity(255);
            return;
        }

        this.runAction(new cc.FadeIn(0.3)).setFinalize(function () {
            this.setOpacity(255);
        }.bind(this));
    },

    hide: function (silent) {
        if (this.isShown === false) {
            return;
        }
        this.isShown = false;

        this.stopAllActions();

        if (silent) {
            this.setVisible(false);
            this.setOpacity(0);
            return;
        }
        
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.FadeOut(0.3)
        )).setFinalize(function () {
            this.setOpacity(0);
            this.setVisible(false);
        }.bind(this));
    },

    showOverlappingNodes: function (nodes) {
        if (!cleverapps.gameModes.showAvoidRects) {
            return;
        }

        cleverapps.scenes.getRunningScene().drawRects(nodes.map(function (node) {
            var rect = node.getBoundingBoxToWorld();
            rect.name = node.avoidNode;
            return rect;
        }));
    }
});

RestoreProgressButton.OverlappingNodes = ["Window", "FieldView", "MenuBarItemView", "MinimalDialogue", "Panel", "PlayButtonView", "BandButton", "DialogueView", "ButtonBooster"];

cleverapps.styles.RestoreProgressButton = {
    width: 300,
    height: 110,
    eps: 20,
    offset: { x: 20, y: 20 },

    position: [
        {
            x: { align: "left", dx: 20 },
            y: { align: "bottom", dy: 20 }
        },
        {
            x: { align: "right", dx: -20 },
            y: { align: "bottom", dy: 20 }
        },
        {
            x: { align: "right", dx: -20 },
            y: { align: "top", dy: -160 }
        }
    ],
    content: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    }
};
