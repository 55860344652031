/**
 * Created by spepa on 26.12.2022
 */

Landmarks.WORLDS.rapunzel3 = {
    target: { code: "rpcustomermain0a", stage: 0 },
    force: Forces.LANDMARK_DONOR_SLOT2,
    landmarks: [{
        code: "landmark",
        stage: 15,
        targetExpedition: "main",
        donors: ["rpcustomermain0b", "rpcustomermain1a", "rpcustomermain1b", "rpcustomermain1c",
            "rpcustomermain2a", "rpcustomermain2b", "rpcustomermain2c", "rpcustomermain2d", "rpcustomermain3a",
            "rpcustomermain3b", "rpcustomermain3c", "rpcustomermain3d"]
    }]
};

CustomerRecipes.rapunzel3 = cleverapps.clone(CustomerRecipes.universal, true);
CustomerRecipes.rapunzel3.rpproducer0_0[0].ingredients[0] = {
    type: "rpresource",
    stage: 3,
    amount: 1
};
delete CustomerRecipes.rapunzel3.rpproducer0_0[0].ingredients[0].code;

CustomerRecipes.rapunzel3.rpproducer1_0[0].requiredUnits[0].code = "rpcustomermain0b";
CustomerRecipes.rapunzel3.rpproducer2_0[0].requiredUnits[0].code = "rpcustomermain1c";
CustomerRecipes.rapunzel3.rpproducer3_0[0].requiredUnits[0].code = "rpcustomermain2d";
CustomerRecipes.rapunzel3.rpcustomerbridge0_0[0].ingredients[0].type = "rpresource";
CustomerRecipes.rapunzel3.rpcustomerbridge0_0[0].ingredients[0].amount = 3;
CustomerRecipes.rapunzel3.rpcustomerbridge1_0[0].requiredUnits[0].code = "rpcustomermain0b";
CustomerRecipes.rapunzel3.rpcustomerbridge2_0[0].requiredUnits[0].code = "rpcustomermain1c";
CustomerRecipes.rapunzel3.rpcustomerbridge3_0[0].requiredUnits[0].code = "rpcustomermain2d";

delete CustomerRecipes.rapunzel3.rpcustomermain0_0;
delete CustomerRecipes.rapunzel3.rpcustomermain1_0;
delete CustomerRecipes.rapunzel3.rpcustomermain2_0;
delete CustomerRecipes.rapunzel3.rpcustomermain3_0;

PassLevelsConfig[Mission.CompoundType(Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_RAPUNZEL3_EXPEDITION)] = [
    {
        energy: 20,
        task: {
            goal: 120
        },
        reward: {
            unit: { code: "worker", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 2, amount: 1 }
        }
    }, // 1
    {
        energy: 20,
        task: {
            goal: 330
        },
        reward: {
            unit: { code: "sacks", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 1, amount: 1 }
        }
    }, // 2
    {
        energy: 20,
        task: {
            goal: 550
        },
        reward: {
            unit: { code: "wands", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "wands", stage: 2, amount: 1 }
        }
    }, // 3
    {
        energy: 20,
        task: {
            goal: 1100
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 3, amount: 1 }
        }
    }, // 4
    {
        energy: 20,
        task: {
            goal: 1700
        },
        reward: {
            unit: { code: "crystal", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 1, amount: 3 }
        }
    }, // 5
    {
        energy: 20,
        task: {
            goal: 2250
        },
        reward: {
            unit: { code: "treasure", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 1 }
        }
    }, // 6
    {
        energy: 20,
        task: {
            goal: 2800
        },
        reward: {
            unit: { code: "sacks", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 2, amount: 2 }
        }
    }, // 7
    {
        energy: 20,
        task: {
            goal: 3350
        },
        reward: {
            unit: { code: "herochest", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 1, amount: 3 }
        }
    }, // 8
    {
        energy: 20,
        task: {
            goal: 3350
        },
        reward: {
            unit: { code: "crystal", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 3, amount: 2 }
        }
    }, // 9
    {
        energy: 20,
        task: {
            goal: 3800
        },
        reward: {
            unit: { code: "herochest", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 2, amount: 2 }
        }
    }, // 10
    {
        energy: 20,
        task: {
            goal: 3800
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 2 }
        }
    }, // 11
    {
        energy: 20,
        task: {
            goal: 4295
        },
        reward: {
            unit: { code: "worker", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 3, amount: 3 }
        }
    }, // 12
    {
        energy: 20,
        task: {
            goal: 4295
        },
        reward: {
            unit: { code: "magicplant", stage: 3, amount: 1 }
        },
        premiumReward: {
            unit: { code: "magicplant", stage: 5, amount: 1 }
        }
    }, // 13
    {
        energy: 20,
        task: {
            goal: 4600
        },
        reward: {
            unit: { code: "treasure", stage: 1, amount: 2 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 3 }
        }
    }, // 14
    {
        energy: 20,
        task: {
            goal: 4600
        },
        reward: {
            unit: { code: "wands", stage: 1, amount: 2 }
        },
        premiumReward: {
            unit: { code: "wands", stage: 3, amount: 3 }
        }
    }, // 15
    {
        energy: 20,
        task: {
            goal: 5050
        },
        reward: {
            unit: { code: "crystal", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 3, amount: 2 }
        }
    }, // 16
    {
        energy: 20,
        task: {
            goal: 5050
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 3 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 2, amount: 2 }
        }
    }, // 17
    {
        energy: 20,
        task: {
            goal: 5500
        },
        reward: {
            unit: { code: "worker", stage: 3, amount: 2 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 4, amount: 2 }
        }
    }, // 18
    {
        energy: 20,
        task: {
            goal: 5500
        },
        reward: {
            unit: { code: "worker", stage: 4, amount: 1 }
        },
        premiumReward: {
            unit: { code: "magicplant", stage: 6, amount: 1 }
        }
    }, // 19
    {
        energy: 20,
        task: {
            goal: 5650
        },
        reward: {
            unit: { code: "coinstree", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "energytree", stage: 3, amount: 1 }
        }
    }, // 20
    {
        energy: 20,
        task: {
            goal: 5650
        },
        reward: {
            unit: { code: "coins", stage: 4, amount: 3 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 4, amount: 3 }
        }
    }, // 21
    {
        energy: 20,
        task: {
            goal: 5800
        },
        reward: {
            unit: { code: "herochest", stage: 2, amount: 2 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 2, amount: 3 }
        }
    }, // 22
    {
        energy: 20,
        task: {
            goal: 6100
        },
        reward: {
            unit: { code: "worker", stage: 4, amount: 2 }
        },
        premiumReward: {
            unit: { code: "magicplant", stage: 7, amount: 1 }
        }
    }, // 23
    {
        energy: 20,
        task: {
            goal: 6400
        },
        reward: {
            unit: { code: "magicplant", stage: 4, amount: 1 }
        },
        premiumReward: {
            unit: { code: "coinstree", stage: 3, amount: 1 }
        }
    }, // 24
    {
        energy: 20,
        task: {
            goal: 7000
        },
        reward: {
            unit: { code: "magicplant", stage: 5, amount: 1 }
        },
        premiumReward: {
            unit: { code: "energytree", stage: 3, amount: 2 }
        }
    } // 25
];

CustomerRecipes.rapunzel3.rpcustomermain0b_0 = [
    {
        ingredients: [{
            code: "rpproduct0",
            stage: 0,
            amount: 3
        }],
        requiredUnits: [{ code: "rpcustomermain0a", stage: 1 }]
    }
];
CustomerRecipes.rapunzel3.rpcustomermain1b_0 = [
    {
        requiredUnits: [{ code: "rpcustomermain1a", stage: 1 }],
        ingredients: [{
            code: "rpproduct1",
            stage: 0,
            amount: 3
        }]
    }
];
CustomerRecipes.rapunzel3.rpcustomermain1c_0 = [
    {
        requiredUnits: [{ code: "rpcustomermain1b", stage: 1 }],
        ingredients: [{
            code: "rpproduct1",
            stage: 0,
            amount: 3
        }]
    }
];
CustomerRecipes.rapunzel3.rpcustomermain2b_0 = [
    {
        requiredUnits: [{ code: "rpcustomermain2a", stage: 1 }],
        ingredients: [{
            code: "rpproduct2",
            stage: 0,
            amount: 2
        }]
    }
];
CustomerRecipes.rapunzel3.rpcustomermain2c_0 = [
    {
        requiredUnits: [{ code: "rpcustomermain2b", stage: 1 }],
        ingredients: [{
            code: "rpproduct2",
            stage: 0,
            amount: 3
        }]
    }
];
CustomerRecipes.rapunzel3.rpcustomermain2d_0 = [
    {
        requiredUnits: [{ code: "rpcustomermain2c", stage: 1 }],
        ingredients: [{
            code: "rpproduct2",
            stage: 0,
            amount: 3
        }]
    }
];
CustomerRecipes.rapunzel3.rpcustomermain3b_0 = [
    {
        requiredUnits: [{ code: "rpcustomermain3a", stage: 1 }],
        ingredients: [{
            code: "rpproduct3",
            stage: 0,
            amount: 2
        }]
    }
];
CustomerRecipes.rapunzel3.rpcustomermain3c_0 = [
    {
        requiredUnits: [{ code: "rpcustomermain3b", stage: 1 }],
        ingredients: [{
            code: "rpproduct3",
            stage: 0,
            amount: 3
        }]
    }
];
CustomerRecipes.rapunzel3.rpcustomermain3d_0 = [
    {
        requiredUnits: [{ code: "rpcustomermain3c", stage: 1 }],
        ingredients: [{
            code: "rpproduct3",
            stage: 0,
            amount: 3
        }]
    }
];

CustomerRecipes.rapunzel3.rpcustomermain0a_0 = [
    {
        ingredients: [{
            code: "rpproduct0",
            stage: 0,
            amount: 3
        }]
    }
];
CustomerRecipes.rapunzel3.rpcustomermain1a_0 = [
    {
        requiredUnits: [{ code: "rpcustomermain0a", stage: 1 }],
        ingredients: [{
            code: "rpproduct1",
            stage: 0,
            amount: 3
        }]
    }
];
CustomerRecipes.rapunzel3.rpcustomermain2a_0 = [
    {
        ingredients: [{
            code: "rpproduct2",
            stage: 0,
            amount: 1
        }]
    }
];
CustomerRecipes.rapunzel3.rpcustomermain3a_0 = [
    {
        ingredients: [{
            code: "rpproduct3",
            stage: 0,
            amount: 1
        }]
    }
];