/**
 * Created by r4zi4l on 01.09.2022
 */

var ClansCupWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            name: "ClansCupWindow",
            title: "Cups.clan.title",
            content: this.createContent(),
            help: function () {
                new GuideWindow({
                    name: "ClanCupGuideWindow",
                    bundle: bundles.clancupguidewindow
                });
            }
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.ClansCupWindow;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles.width, styles.height);

        var table = this.table = this.createTable();
        table.setPositionRound(styles.table);
        content.addChild(table);

        var tabs = this.createTabs();
        tabs.setPositionRound(styles.tabs);
        tabs.setLocalZOrder(2);
        table.addChild(tabs);

        var timer = this.timer = this.createTimerBlock();
        timer.setPositionRound(styles.timer);
        content.addChild(timer);

        var finishText = this.finishText = cleverapps.UI.generateOnlyText("Finished", cleverapps.styles.FONTS.DARK_TEXT);
        finishText.setPositionRound(styles.finishText);
        content.addChild(finishText);

        this.updateState();

        cleverapps.clanCup.reload();
        cleverapps.clanCupInner.reload(true);

        cleverapps.clanCup.onChangedView = this.createListener(this.updateState.bind(this));

        return content;
    },

    updateState: function () {
        if (cleverapps.clanCup.isRunning()) {
            this.timer.setVisible(true);
            this.timer.countdown.countDown.resetTimeLeft(cleverapps.clanCup.getTimeLeft());
            this.finishText.setVisible(false);
        } else if (!cleverapps.clanCup.isFinished()) {
            this.timer.setVisible(false);
            this.finishText.setVisible(true);
            this.finishText.setString("ClansCupWindow.summingUp");
        } else {
            this.timer.setVisible(false);
            this.finishText.setVisible(true);
            this.finishText.setString("Finished");
        }
    },

    createTable: function () {
        var styles = cleverapps.styles.ClansCupWindow.table;

        var background = cleverapps.UI.createScale9Sprite(bundles.clan.frames.table_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(styles.width, styles.height);

        var foreground = cleverapps.UI.createScale9Sprite(bundles.clan.frames.table_fg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        foreground.setContentSize2(background.width, background.height);
        foreground.setPositionRound(background.width / 2, background.height / 2);
        background.addChild(foreground, 1);

        return background;
    },

    createTabs: function () {
        var styles = cleverapps.styles.ClansCupWindow;

        var info = {
            0: {
                text: "ClansCupWindow.top",
                generator: this.createTab.bind(this, cleverapps.clanCup)
            },
            1: {
                text: "ClansCupWindow.myclan",
                generator: this.createTab.bind(this, cleverapps.clanCupInner)
            }
        };

        var tabs = new Tabs(info, styles.tabs);
        tabs.activateTab(0);
        return tabs;
    },

    createTab: function (cup) {
        var styles = cleverapps.styles.ClansCupWindow.table;

        var tableRect = cc.rectSubPadding(cc.rect(0, 0, styles.width, styles.height), cc.padding(styles.padding));

        var tableView = new CupTableView(cup, function () {
            return cleverapps.cupsTopTable.createTableView({
                id: cup.type,
                data: cup.listParticipants(),
                bg: false,
                height: tableRect.height,
                rowConstructor: ClansRow,
                rowOptions: {
                    prizes: true,
                    badges: true
                },
                innerPadding: styles.innerPadding
            });
        }, {
            scaleDuration: 0.3,
            scaleEasing: cc.easeInOut(1)
        });
        tableView.setPositionRound(tableRect.x + tableRect.width / 2, tableRect.y + tableRect.height / 2);
        this.table.addChild(tableView);

        cleverapps.playSession.set(cleverapps.EVENTS.CUP_DAU + cup.type, true);

        return tableView;
    },

    createTimerBlock: function () {
        var styles = cleverapps.styles.ClansCupWindow.timer;

        var leftTime = cleverapps.clanCup.getTimeLeft();
        var countdown = new cleverapps.CountDownView(new cleverapps.CountDown(leftTime), {
            font: cleverapps.styles.FONTS.TIMER_TEXT,
            background: {
                frame: bundles.big_timer.frames.big_timer_bg_png,
                width: styles.width,
                height: styles.height
            },
            icon: {
                frame: bundles.big_timer.frames.big_timer_png
            }
        });

        var text = cleverapps.UI.generateOnlyText("TimeLeft", cleverapps.styles.FONTS.DARK_TEXT);

        var timer = new cleverapps.Layout([text, countdown], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        timer.countdown = countdown;

        return timer;
    },

    onClose: function () {
        cleverapps.clanCup.afterWindowShown();
    }
});

cleverapps.styles.ClansCupWindow = {
    width: 1280,
    height: 940,

    table: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -60 },
        width: 1320,
        height: 800,
        innerPadding: 30,

        padding: {
            x: 0,
            y: 40
        }
    },

    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 },
        width: 270,
        height: 60,
        margin: 10
    },

    finishText: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 }
    },

    tabs: {
        x: { align: "left", dx: 70 },
        y: { align: "top", dy: 70 },
        direction: cleverapps.UI.HORIZONTAL,
        type: Tabs.Types.tabs_custom,
        width: 340,
        height: 77,
        margin: 20
    }
};