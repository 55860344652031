/**
 * Created by Andrey Popov on 29.02.2024
 */

var MissionOfferLogic = function (offer) {
    this.offer = offer;

    var data = Offers[offer.type];

    this.mission = data.mission;
    this.hero = data.hero;
    this.product = data.product;
    this.price = data.price;
    this.reward = data.reward;

    this.onBought = function () {};
};

MissionOfferLogic.prototype.getProduct = function () {
    if (this.product) {
        return Product.CreateById(this.product, {
            type: "mission_offer",
            offer: this.offer.type
        });
    }

    return Product.CreateByHardPrice(this.price, {
        type: "mission_offer",
        offer: this.offer.type 
    });
};

MissionOfferLogic.prototype.isIconAvailable = function () {
    var type = Mission.GetParentType(this.mission) || Mission.GetChildType(this.mission);
    var mission = Missions[type];

    if (!mission) {
        return false;
    }

    var expedition = cleverapps.travelBook.getCurrentExpedition();

    return expedition
        ? (expedition.missionType === type || expedition.id === mission.expedition)
        : !cleverapps.travelBook.getPageById(mission.id);
};

MissionOfferLogic.prototype.hasIconAttention = function () {
    return false;
};

MissionOfferLogic.prototype.getPriceText = function () {
    return this.getProduct().getCurrentPrice();
};

MissionOfferLogic.prototype.getTimeLeft = function () {
    var mission = cleverapps.missionManager.findByType(this.mission);
    return mission && mission.getTimeLeft() || 0;
};

MissionOfferLogic.prototype.isAvailable = function () {

};

MissionOfferLogic.prototype.buy = function () {
    if (this.reward.unit) {
        var unitsAmount = this.reward.unit.reduce(function (sum, unit) {
            return sum + (unit.amount || 0);
        }, 0);

        var notEnough = unitsAmount - Map2d.currentMap.countEmptySlots();
        if (notEnough > 0) {
            cleverapps.centerHint.createTextHint("Spawn.nospace", { left: notEnough });
            return;
        }
    }

    this.getProduct().buy(this.processBought.bind(this), { noRewardWindow: true });
};

MissionOfferLogic.prototype.processBought = function (success) {
    if (!success) {
        return;
    }

    new RewardWindow(this.reward, { event: cleverapps.EVENTS.EARN.PURCHASE });

    cleverapps.eventLogger.logEvent(this.offer.name + "_" + cleverapps.EVENTS.OFFER_LOT);

    this.offer.complete();

    this.onBought();
};

MissionOfferLogic.RestoreExpeditionId = function (context) {
    return cleverapps.offerManager.getOfferExpedition(context.offer);
};

MissionOfferLogic.RestoreActions = function (context, consume) {
    var offer = cleverapps.offerManager.findOffer({ type: context.offer });
    if (!offer) {
        return;
    }

    return [
        function (f) {
            consume();
            offer.logic.processBought(true);
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    ];
};

Product.RESTORE_LOGIC["mission_offer"] = MissionOfferLogic;