/**
 * Created by r4zi4l on 03.08.2021
 */

var WatchTellyWindow = CleverappsWindow.extend({
    onWindowLoaded: function (telly) {
        this.telly = telly;
        this._super({
            title: cleverapps.unitsLibrary.getUnitName(telly.unit),
            name: "WatchTellyWindow",
            content: this.createContent(),
            closeButton: true,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true,
            help: function () {
                new ChestPrizeProbWindow(telly.unit, telly.listPrizeProb());
            }
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.WatchTellyWindow;

        var telly = new UnitOnCell(this.telly.unit, {
            shining: true
        });

        telly.setScale(styles.telly.scale);
        telly.pulsate();

        var question = cleverapps.UI.generateOnlyText("WatchTellyWindow.question", cleverapps.styles.FONTS.WATCHTELLYWINDOW_TITLE_TEXT);
        question.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        question.setDimensions(styles.question.width, 0);

        if (!cleverapps.rewardedAdsManager.isEnabled()) {
            var noads = cleverapps.UI.generateOnlyText("*" + Messages.get("NoAdsForSource"), cleverapps.styles.FONTS.WATCHTELLYWINDOW_NOADS_TEXT);
        }

        return new cleverapps.Layout([telly, question, this.createButtons(), noads], {
            margin: styles.margin
        });
    },

    createButtons: function () {
        var styles = cleverapps.styles.WatchTellyWindow.buttons;

        var watchButton = this.watchButton = new cleverapps.UI.Button({
            text: "##Watch",
            width: styles.width,
            height: styles.height,
            onClicked: this.watchTelly.bind(this),
            type: cleverapps.styles.UI.Button.Images.button_blue
        });

        cleverapps.rewardedAdsManager.onRewardedRefresh(this.changeRewardState.bind(this), this);
        this.changeRewardState();

        var removeButton = new cleverapps.UI.Button({
            text: "WatchTellyWindow.skip",
            width: styles.width,
            height: styles.height,
            onClicked: this.removeTelly.bind(this),
            type: cleverapps.styles.UI.Button.Images.button_red
        });

        return new cleverapps.Layout([watchButton, removeButton], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    },

    removeTelly: function () {
        this.close();
        this.telly.remove();
    },

    changeRewardState: function () {
        if (cleverapps.rewardedAdsManager.isRewardedAvailable()) {
            this.watchButton.enable();
        } else {
            this.watchButton.disable();
        }
    },

    watchTelly: function () {
        cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.PIXEL, this.createListener(function () {
            this.close();
            this.telly.prize();
        }.bind(this)));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    WATCHTELLYWINDOW_TITLE_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    WATCHTELLYWINDOW_NOADS_TEXT: {
        name: "nostroke",
        size: 36,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.WatchTellyWindow = {
    margin: 40,

    question: {
        width: 1040
    },

    buttons: {
        width: 300,
        height: 110,
        margin: 120
    },

    telly: {
        scale: 1.6
    }
};
