/**
 * Created by Denis Kuzin on 30 march 2022
 */

var ClansWindow = BaseClanWindow.extend({
    ctor: function () {
        this.updateTop = true;

        this._super.apply(this, arguments);
    },

    onWindowLoaded: function () {
        this._super({
            name: "ClansWindow",
            title: "ClansWindow.joiningTeam.title"
        });
    },

    createTabs: function () {
        return {
            0: {
                icon: bundles.windows.frames.tab_icon_join_team,
                text: "ClansWindow.joiningTeam.title",
                generator: this.createTab.bind(this, ClansJoinTab),
                updateTitle: this.setTitle.bind(this, "ClansWindow.joiningTeam.title")
            },
            1: {
                icon: bundles.windows.frames.tab_icon_top_team,
                text: "ClansWindow.top.title",
                generator: this.createTab.bind(this, ClansTopTab),
                updateTitle: this.setTitle.bind(this, "ClansWindow.top.title")
            }
        };
    },

    onHide: function () {
        this._super();

        if (this.tabs.getActive() === "0") {
            this.tabs.onUnSelectTab("0");
        }
    },

    onShow: function () {
        this._super();

        if (this.tabs.getActive() === "0") {
            this.tabs.onSelectTab("0");
        }
    }
});