/**
 * Created by vladislav on 13.07.20.
 */

var UndoBooster = function (id) {
    Booster.call(this, id);
};

UndoBooster.prototype = Object.create(Booster.prototype);
UndoBooster.prototype.constructor = UndoBooster;

UndoBooster.prototype.onExecute = function () {
    Game.currentGame.log.executeUndo();
};

UndoBooster.prototype.onUsed = function () {

};

UndoBooster.prototype.getControls = function () {
    return ["opencards", "hint_18", "hint_4"];
};

UndoBooster.prototype.onGameStarted = function () {
    Game.currentGame.log.on("change", this.updateState.bind(this));
};

UndoBooster.prototype.updateState = function () {
    if (Game.currentGame.log.isEmpty()) {
        this.onDisableListener();
    } else {
        this.onEnableListener();
    }
};