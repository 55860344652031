/**
 * Created by spepa on 24.10.2022
 */

var LandMarkDonorWindow = CleverappsWindow.extend({
    onWindowLoaded: function (landmark) {
        this.tabsInfo = {};
        this.rewards = {};

        this.landmarks = Game.currentGame.landmarks.listActiveLandmarks();
        this.landmarks.forEach(function (landmark, index) {
            if (Game.currentGame.landmarks.canGiveLandmark(landmark)) {
                this.rewards[index] = new Reward("units", landmark, { toMainWorld: true, withoutDelta: true });
                this.rewards[index].receiveReward();

                Game.currentGame.landmarks.landmarkReceived(landmark);
            }

            this.tabsInfo[index] = {
                node: UnitView.getUnitImage(landmark, { preferStatic: true }),
                generator: this.createTab.bind(this, landmark, this.rewards[index])
            };
        }.bind(this));

        landmark = landmark || this.findMostProgressed();

        this.selected = this.landmarks.indexOf(landmark);

        var titleKey = "LandMarkDonorWindow.title.landmark_" + landmark.stage;
        var title = Messages.has(titleKey) ? Messages.get(titleKey) : Messages.get("LandMarkDonorWindow.title", {
            family: cleverapps.unitsLibrary.getUnitName(UnitsLibrary.LastStage(landmark.donors[0]))
        });

        this._super({
            styles: cleverapps.styles.LandMarkDonorWindow,
            title: title,
            name: "LandMarkDonorWindow",
            content: this.createContent(),
            foreground: bundles.windows.frames.window_foreground_png
        });
    },

    findMostProgressed: function () {
        var bestLandmark = this.landmarks[0];
        var bestProgress = Game.currentGame.landmarks.getPendingProgress(bestLandmark);

        for (var i = 1; i < this.landmarks.length; ++i) {
            var landmark = this.landmarks[i];
            var progress = Game.currentGame.landmarks.getPendingProgress(landmark);
            if (progress > bestProgress) {
                bestLandmark = landmark;
                bestProgress = progress;
            }
        }
        return bestLandmark;
    },

    createTab: function (landmark, reward) {
        var styles = cleverapps.styles.LandMarkDonorWindow;
        var tab = new LandmarkDonorTab(landmark, reward, this.createListener(this.updateTabsState.bind(this)), this.createListener(this.close.bind(this)));
        tab.setAnchorPoint(0.5, 1);
        tab.setPositionRound(styles.content);
        this.content.addChild(tab);
        return tab;
    },

    createContent: function () {
        var styles = cleverapps.styles.LandMarkDonorWindow;

        var content = this.content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles);

        var tabs = this.tabs = new Tabs(this.tabsInfo, styles.tabs);
        tabs.setPositionRound(styles.tabs);
        content.addChild(tabs);

        tabs.activateTab(this.selected);
        this.updateTabsState();

        return content;
    },

    updateTabsState: function () {
        if (this.landmarks.length <= 1) {
            this.tabs.setVisible(false);
            return;
        }

        this.landmarks.forEach(function (landmark, index) {
            this.tabs.setAttention(index, Game.currentGame.landmarks.getPendingProgress(landmark));
        }.bind(this));
    },

    beforeCloseAnimation: function (callback) {
        var actions = [];

        Object.keys(this.rewards).forEach(function (index) {
            actions.push(function (f) {
                var activeTab = this.tabs.activateTab(index);
                if (activeTab) {
                    this.rewards[index].collectRewardsAnimation(activeTab.getLandmarkIcon(), {
                        callback: f
                    });
                } else {
                    f();
                }
            }.bind(this));
        }.bind(this));

        cleverapps.focusManager.compound(callback, actions);
    },

    listBundles: function () {
        var bundles = ["landmarkdonor_window"];
        var lazyBundles = Families.listLazyBundles(Game.currentGame.landmarks.listActiveLandmarks());
        return bundles.concat(lazyBundles);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LANDMARK_PROGRESS_FONT: {
        name: "default",
        size: 45
    }
});

cleverapps.styles.LandMarkDonorWindow = {
    width: 920,
    height: 960,

    tabs: {
        height: 150,
        direction: cleverapps.UI.VERTICAL,
        x: { align: "left", dx: -200 },
        y: { align: "top", dy: -45 }
    },

    content: {
        x: { align: "center" },
        y: { align: "center" }
    }
};
