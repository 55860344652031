/**
 * Created by razial on 10.06.2024.
 */

cleverapps.SkinManager.SKINS.rose_energy = {
    slots: {
        energy_for_coins_png: bundles.tile_shop.frames.rose_energy_for_coins_png,
        energy_for_video_png: bundles.tile_shop.frames.rose_energy_for_video_png,

        energy_icon_0: bundles.specialenergyofferwindow.frames.rose_energy_icon_0,

        menubar_life_png: bundles.menubar.frames.rose_menubar_life_png,

        energy_icon_png: bundles.buttons_inlined_icons.frames.rose_energy_icon_png,

        energy_icon: bundles.reward_icons_merge.frames.rose_energy_icon,

        reward_energy_png: bundles.reward_icons.frames.rose_reward_energy_png,
        energy_small_png: bundles.reward_icons.frames.rose_energy_small_png,
        shop_energy_png: bundles.reward_icons.frames.rose_shop_energy_png
    }
};