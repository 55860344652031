/**
 * Created by mac on 10/8/20
 */

var AdminDetailsView = cc.Node.extend({
    ctor: function (adminDetails, options) {
        this._super();

        this.adminDetails = adminDetails;

        var size = cleverapps.resolution.getSceneSize();

        this.setContentSize2(options.width, size.height);
        this.setAnchorPoint(0.5, 0.5);

        this.addKeyboardNavigation();

        this.adminDetails.on("reset", this.reset.bind(this), this);

        this.reset();
    },

    wrapWithBg: function (node, options) {
        if (node.innerContent) {
            var styles = cleverapps.styles.AdminDetailsView;
            var paddingTop = styles.bgPadding.y;
            var paddingBottom = styles.bgPadding.y;
            var maxiaturePaddingTop = options && options.maxiaturePaddingTop ? options.maxiaturePaddingTop : 0;

            node.setContentSize2(this.width - 2 * styles.padding.x, Math.max(node.innerContent.height * node.innerContent.scaleY + maxiaturePaddingTop, styles.minHeight) + paddingTop + paddingBottom);
            node.innerContent.setPositionRound(node.width / 2, node.height / 2 - maxiaturePaddingTop / 2);
            return node;
        }

        var bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.common_bg);
        node.setAnchorPoint(0.5, 0.5);
        bg.addChild(node);
        bg.innerContent = node;
        return this.wrapWithBg(bg);
    },

    reset: function () {
        if (this.layout) {
            this.layout.removeFromParent();
            this.layout = undefined;
        }

        if (this.status) {
            this.status.removeFromParent();
            this.status = undefined;
        }

        this.createControls();

        var styles = cleverapps.styles.AdminDetailsView;
        var availableWidth = this.calcAvailableWidth();

        var adminLevel = cleverapps.administrator.adminLevels.getActive();

        var maxiatureView = AdminLevelMaxiature.generate(adminLevel);
        this.maxiatureView = this.wrapWithBg(maxiatureView);

        if (adminLevel) {
            var dataView = new AdminDataView(availableWidth);
            this.dataView = this.wrapWithBg(dataView);
            dataView.onReady = this.createListener(this.reshapeContent.bind(this));

            this.eventsView = new AdminLevelFlagsPanel(adminLevel, true);

            var level = adminLevel.getHumanNumber();
            var text = cleverapps.UI.generateOnlyText("Level: " + level, cleverapps.styles.FONTS.ADMIN_DETAILS_VIEW_STATUS_TEXT);

            var currentFlag;
            if (adminLevel.isCurrent()) {
                currentFlag = new cc.Sprite(bundles.admin.frames.current_arrow);
            }

            var tagView;
            if (adminLevel.getTag()) {
                tagView = new AdminLevelTag(adminLevel, true);
            }

            var status = this.status = new cleverapps.Layout([this.eventsView, text, currentFlag, tagView].filter(Boolean), {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.status.margin
            });
            this.maxiatureView.addChild(status);

            if (cleverapps.config.name === "differences") {
                this.enlargeView = this.createEnlargeButton(adminLevel);
                this.maxiatureView.addChild(this.enlargeView);
            }
        } else {
            this.dataView = undefined;
            this.eventsView = undefined;
            this.enlargeView = undefined;
        }

        if (Game.currentGame) {
            var game = Game.currentGame;
            addCleaner(this.maxiatureView, function () {
                game.stop();
            });
        }

        var layout = this.layout = new cleverapps.Layout([this.maxiatureView, this.dataView].filter(Boolean), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.layout.margin
        });
        this.addChild(layout);

        this.buttons.reset(adminLevel);

        this.reshapeContent();
    },

    calcAvailableWidth: function () {
        var styles = cleverapps.styles.AdminDetailsView;
        return this.width - 2 * styles.padding.x - 2 * styles.bgPadding.x;
    },

    calcMaxiatureHeight: function (siblings) {
        var styles = cleverapps.styles.AdminDetailsView;

        var buttonsTop = this.buttons.getBoundingBox().y + this.buttons.getBoundingBox().height;
        var maxiatureHeight = this.height - styles.paddingTop - 2 * styles.bgPadding.y - buttonsTop - styles.layout.margin;
        maxiatureHeight -= siblings.reduce(function (height, node) {
            return height + node.height * node.scale + styles.layout.margin + 2 * styles.bgPadding.y;
        }, 0);

        return maxiatureHeight;
    },

    reshapeContent: function () {
        var styles = cleverapps.styles.AdminDetailsView;

        if (this.dataView) {
            this.wrapWithBg(this.dataView);
        }

        cleverapps.UI.fitToBox(this.maxiatureView.innerContent, {
            width: this.calcAvailableWidth(),
            height: this.calcMaxiatureHeight([this.dataView].filter(Boolean)) - styles.bgPadding.maxiaturePaddingTop
        });

        this.wrapWithBg(this.maxiatureView, { maxiaturePaddingTop: styles.bgPadding.maxiaturePaddingTop });

        if (this.status) {
            this.status.setPositionRound(this.maxiatureView.innerContent.x - this.maxiatureView.innerContent.width * this.maxiatureView.innerContent.scaleX / 2 + this.status.width / 2, styles.status.y);
        }

        if (this.enlargeView) {
            this.enlargeView.setPositionRound(styles.enlarge);
        }

        this.layout.reshape();
        this.layout.setAnchorPoint(0.5, 1);
        this.layout.setPositionRound(this.width / 2, this.height - styles.paddingTop);
    },

    switchMode: function (mode) {
        this.reset(mode);
    },

    createEnlargeButton: function (adminLevel) {
        var deleteEnlarged = function () {
            if (!this.elarged) {
                return;
            }
            cc.eventManager.removeListener(this.elarged.onClick);
            this.elarged.runAction(new cc.RemoveSelf());
            delete this.elarged;
        }.bind(this);

        var resetEnlarged = function () {
            deleteEnlarged();

            var enlarged = this.elarged = AdminLevelMaxiature.generate(adminLevel, { enlarge: true });
            var scene = cleverapps.scenes.getRunningScene();
            scene.addChild(enlarged);
            enlarged.setAnchorPoint(0.5, 0.5);
            enlarged.setPositionRound(scene.width / 2, scene.height / 2);
            cleverapps.UI.wrap(enlarged);

            var bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_bg);
            var padding = cleverapps.styles.AdminDetailsView.bgPadding.paddingBottom;
            bg.setContentSize(enlarged.width + padding, enlarged.height + padding);
            enlarged.addChild(bg, -1);
            bg.setPositionRound(enlarged.width / 2, enlarged.height / 2);

            enlarged.onClick = cc.eventManager.addListener({
                event: cc.EventListener.TOUCH_ONE_BY_ONE,
                swallowTouches: true,
                onTouchBegan: function (touch) {
                    var point = enlarged.convertTouchToNodeSpace(touch);
                    if (!cc.rectContainsPoint(cc.rect(0, 0, enlarged.width, enlarged.height), point)) {
                        deleteEnlarged();
                    }
                    return true;
                }
            }, enlarged);
        }.bind(this);

        if (this.elarged) {
            resetEnlarged();
        }

        return new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.editor,
            onClicked: resetEnlarged
        });
    },

    createControls: function () {
        if (this.buttonsWithBg) {
            this.buttonsWithBg.removeFromParent();
            this.buttonsWithBg = undefined;
            this.buttons = undefined;
        }

        this.buttons = new AdminDetailControls();
        this.buttonsWithBg = this.wrapWithBg(this.buttons);
        this.addChild(this.buttonsWithBg);
        this.buttonsWithBg.setPositionRound(cleverapps.styles.AdminDetailsView.buttons);
    },

    addKeyboardNavigation: function () {
        var execAction = function (action) {
            cleverapps.focusManager.display({
                focus: "hotKeyPress",
                action: action
            });
        };

        cc.eventManager.addListener({
            event: cc.EventListener.KEYBOARD,

            onKeyPressed: function (key) {
                if (key === cc.KEY.left && cleverapps.keyboardController.isPressed(cc.KEY.ctrl)) {
                    execAction(function (f) {
                        AdminNavigation.nextEpisode(AdminNavigation.LEFT, f);
                    });
                } else if (key === cc.KEY.right && cleverapps.keyboardController.isPressed(cc.KEY.ctrl)) {
                    execAction(function (f) {
                        AdminNavigation.nextEpisode(AdminNavigation.RIGHT, f);
                    });
                } else if (key === cc.KEY.enter && cleverapps.keyboardController.isPressed(cc.KEY.ctrl)) {
                    cleverapps.administrator.adminLevels.getActive().editLevel();
                } else if (key === cc.KEY.h) {
                    var activeLevel = cleverapps.administrator.adminLevels.getActive();
                    if (activeLevel) {
                        activeLevel.toggleHard();
                    }
                } else if (key === cc.KEY.left) {
                    execAction(function (f) {
                        AdminNavigation.nextLevel(AdminNavigation.LEFT, f);
                    });
                } else if (key === cc.KEY.right) {
                    execAction(function (f) {
                        AdminNavigation.nextLevel(AdminNavigation.RIGHT, f);
                    });
                } else if (key === cc.KEY.enter) {
                    execAction(function (f) {
                        LevelManager.getInstance().playLevel(f);
                    });
                }
            }
        }, this);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ADMIN_DETAILS_VIEW_STATUS_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.ADMIN_TEXT_COLOR
    }
});

cleverapps.styles.AdminDetailsView = {
    minHeight: 50,

    padding: {
        x: 10,
        y: 0
    },

    bgPadding: {
        x: 10,
        y: 20,

        paddingBottom: 40,

        maxiaturePaddingTop: 40
    },

    events: {
        x: { align: "right", dx: 0 },
        y: { align: "bottom", dy: 0 }
    },

    enlarge: {
        x: { align: "right", dx: 5 },
        y: { align: "top", dy: 25 }
    },

    status: {
        y: { align: "top", dy: -10 },
        margin: 10
    },

    buttons: {
        x: { align: "center" },
        y: { align: "bottom", dy: 15 }
    },

    paddingTop: 15,

    layout: {
        margin: 10
    }
};
