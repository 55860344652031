/**
 * Created by Ivan on 18.01.2023
 */
var EditorFogHead = function (tile, block) {
    this.tile = tile;
    this.block = block;

    this.moveTo = function () {};
    this.onDragMove = function () {};
    this.onUpdateTitle = function () {};
};

EditorFogHead.prototype.handleDragEnd = function (cell) {
    if (!this.canMoveTo(cell.x, cell.y)) {
        cell = this.tile;
    }

    this.setPosition(cell);
};

EditorFogHead.prototype.canMoveTo = function (x, y) {
    var tile = this.block.findTile(x, y);
    return tile && tile !== this.block.balloonTile;
};

EditorFogHead.prototype.setPosition = function (x, y) {
    this.moveTo(x, y);
    this.block.moveHead(x, y);
};

EditorFogHead.prototype.getShape = function () {
    return FamiliesHelper.ShapeCell;
};