/**
 * Created by andrey on 28.03.2024.
 */

var AdjustLogger = function () {
    var json = bundles.adjust.jsons.adjust_json.getJson();

    this.remoteJson = new RemoteJson(json);
};

AdjustLogger.prototype.getConfig = function () {
    var json = this.remoteJson.getJson();
    return json[connector.info.source] || json;
};

AdjustLogger.prototype.logEvent = function (name) {
    var config = this.getConfig();

    var eventToken = config.events && config.events[name];
    if (!eventToken) {
        return;
    }

    connector.platform.callNative("AdjustPlugin.logEvent", { eventToken: eventToken });
};

AdjustLogger.prototype.logPurchase = function (product, purchase, params) {
    var config = this.getConfig();

    connector.platform.callNative("AdjustPlugin.logPurchase", {
        eventToken: config.purchaseToken,
        transactionID: params.transactionId,
        amount: params.price,
        currency: params.currency
    });
};

AdjustLogger.prototype.logAdRevenue = function (name, impressionCost, params) {
    connector.platform.callNative("AdjustPlugin.logAdRevenue", {
        revenue: impressionCost,
        source: params.source
    });
};

AdjustLogger.isApplicable = function () {
    var result = connector.platform.oneOf(connector.ANDROID, connector.AMAZON, connector.IOS, connector.MICROSOFT);

    var json;

    if (bundles.adjust.jsons.adjust_json) {
        json = bundles.adjust.jsons.adjust_json.getJson();
        json = json[connector.info.source] && json[connector.info.source] || json;
    }

    if (result && (!json || !json.purchaseToken)) {
        cleverapps.throwAsync("empty adjust config");
        return false;
    }

    return result;
};
