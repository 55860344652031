/**
 * Created by Anastasiya on 11.06.2024
 */

var TradeUnitDescription = cc.Node.extend({
    ctor: function (tradeType, trade) {
        this._super();
        this.tradeType = tradeType;
        this.trade = trade;

        var styles = cleverapps.styles.TradeUnitDescription;
        this.setContentSize(styles);
        this.createDescription();
        this.createUnit();

        trade.on("pickChange", this.updateSelected.bind(this), this);
    },

    createDescription: function () {
        var styles = cleverapps.styles.TradeUnitDescription.desc;

        var bg = this.descBg = cleverapps.UI.createScale9Sprite(bundles.tradewindow.frames.light_bg);
        bg.setContentSize(styles);
        bg.setAnchorPoint(this.tradeType === UnitTrade.SOURCE ? 0 : 1, 0.5);
        var posX = this.tradeType === UnitTrade.SOURCE ? 0 : this.width;
        bg.setPositionRound(posX, this.height / 2);
        this.addChild(bg);

        var arrow = new cc.Sprite(bundles.tradewindow.frames.dialogue_arrow);
        arrow.setScaleX(this.tradeType === UnitTrade.SOURCE ? 1 : -1);
        var arrowX = this.tradeType === UnitTrade.SOURCE ? bg.width + styles.arrowOffsetX : -styles.arrowOffsetX;
        arrow.setPositionRound(arrowX, bg.height / 2);
        bg.addChild(arrow);

        this.createDescriptionText();
    },

    createDescriptionText: function (unit) {
        var styles = cleverapps.styles.TradeUnitDescription.desc.text;
        if (unit) {
            this.text = this.getUnitText(unit);
        } else {
            var msg = this.tradeType === UnitTrade.SOURCE ? "TradeWindow.HintSelectTradeable" : "TradeWindow.HintSelectAvailable";
            this.text = cleverapps.UI.generateOnlyText(msg, cleverapps.styles.FONTS.TRADE_WINDOW_DESCRIPTION);
            this.text.setDimensions(this.descBg.width - styles.paddingX, 0);
            this.text.fitTo(undefined, this.descBg.height - styles.paddingY);
        }
        this.text.setCascadeOpacityEnabledRecursively(true);
        this.text.setPositionRound(styles);
        this.descBg.addChild(this.text);
    },

    getUnitText: function (unit) {
        var styles = cleverapps.styles.TradeUnitDescription.desc.text;
        var rarityText = {
            "clpet": "TradeWindow.Rarity.Regular",
            "clpetrare": "TradeWindow.Rarity.Rare",
            "clpetlegend": "TradeWindow.Rarity.Legendary"
        };

        var name = cleverapps.UI.generateOnlyText(cleverapps.unitsLibrary.getUnitName(unit), cleverapps.styles.FONTS.TRADE_WINDOW_DESCRIPTION);
        name.fitTo(this.descBg.width - styles.paddingX);

        var rarity = new TextWithIcon("%% " + rarityText[unit.type], {
            font: cleverapps.styles.FONTS.TRADE_WINDOW_DESCRIPTION,
            icons: {
                "%%": this.trade.getIconByType(unit.type)
            }
        });

        return new cleverapps.Layout([rarity, name], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createUnit: function () {
        var styles = cleverapps.styles.TradeUnitDescription.unit;
        var unitCell = this.unitCell = new cc.Sprite(bundles.tradewindow.frames.ground);
        unitCell.setAnchorPoint(this.tradeType === UnitTrade.SOURCE ? 1 : 0, 0.5);
        unitCell.setPositionRound(this.tradeType === UnitTrade.SOURCE ? this.width : 0, this.height / 2 + styles.offsetY);
        this.addChild(unitCell);

        this.createUnitIcon();
    },

    createUnitIcon: function (unit) {
        var styles = cleverapps.styles.TradeUnitDescription.unit.icon;
        if (unit) {
            this.unitIcon = UnitView.getUnitImage(unit, { preferStatic: true, alignAnchorY: true });
            cleverapps.UI.fitToBox(this.unitIcon, styles);
        } else {
            this.unitIcon = new cc.Node();
        }
        this.unitIcon.setPositionRound(styles);
        this.unitCell.addChild(this.unitIcon);
    },

    updateSelected: function (type, newUnit) {
        if (this.tradeType !== type) {
            return;
        }

        var duration = 0.4;
        var oldText = this.text;
        this.createDescriptionText(newUnit);

        oldText.stopAllActions();
        oldText.runAction(new cc.Sequence(
            new cc.FadeOut(duration),
            new cc.RemoveSelf()
        ));
        this.text.setOpacity(0);
        this.text.runAction(new cc.Sequence(
            new cc.DelayTime(duration),
            new cc.FadeIn(duration)
        ));

        var oldUnit = this.unitIcon;
        this.createUnitIcon(newUnit);
        this.unitIcon.setVisible(false);
        AnimationsLibrary.upgrade(oldUnit, this.unitIcon, {
            duration: duration,
            parent: this
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TRADE_WINDOW_DESCRIPTION: {
        size: 35,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.TradeUnitDescription = {
    width: 630,
    height: 150,

    unit: {
        offsetY: -7,
        icon: {
            width: 150,
            height: 150,
            x: { align: "center" },
            y: { align: "bottom", dy: 25 }
        }
    },

    desc: {
        width: 435,
        height: 150,
        offsetX: 10,
        arrowOffsetX: 6,

        text: {
            paddingX: 60,
            paddingY: 50,
            margin: 2,
            x: { align: "center" },
            y: { align: "center", dy: 5 }
        }
    }
};