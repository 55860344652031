/**
 * Created by andrey on 01.07.2024
 */

var VirtualUrl = function (bundleName, key, data) {
    VirtualResource.call(this, bundleName, key);

    this.parse(data);
};

VirtualUrl.prototype = Object.create(VirtualResource.prototype);
VirtualUrl.prototype.constructor = VirtualUrl;

cc.VirtualUrl = VirtualUrl;

VirtualUrl.prototype.parse = function (data) {
    if (data.type) {
        this.type = data.type;
        this.name = data.name;
        this.srcs = data.srcs;
    } else {
        this.url = data;
    }
};

VirtualUrl.prototype.isSound = function () {
    return typeof this.url === "string" && (this.url.includes("/sfx/") || this.url.endsWith(".mp3"));
};

VirtualUrl.prototype.isImportant = function () {
    var bundle = bundles[this.bundleName];

    return bundle.important && bundle.important[this.key]
        || cleverapps.config.debugMode && cleverapps.dataLoader.load(DataLoader.TYPES.TEST_SOUNDS) === "wait_all";
};

VirtualUrl.prototype.getUrl = function () {
    return this.type ? this : this.url;
};

VirtualUrl.prototype.save = function () {
    return this.type ? {
        type: this.type,
        name: this.name,
        srcs: this.srcs
    } : {
        url: this.url
    };
};