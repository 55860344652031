/**
 * Created by slava on 24.03.17.
 */

var VictoryWindow = CleverappsWindow.extend({
    ctor: function (game) {
        this.game = game;
        this.boatswain = game.boatswain;
        this.next = undefined;
        var rewards = this.rewards = new RewardsList(game.rewards, {
            event: game.level.isBonus() ? cleverapps.EVENTS.EARN.BONUS_LEVEL_REWARD : cleverapps.EVENTS.EARN.LEVEL_REWARD
        });
        rewards.receiveRewards();

        this._super();
    },

    onWindowLoaded: function () {
        var styles = cleverapps.styles.VictoryWindow;

        this.rumble = cleverapps.isRumble() && cleverapps.meta.getRumble();
        if (this.rumble) {
            this.rumbleRound = this.rumble.getPrevRound();

            if (!this.rumbleRound) {
                cleverapps.throwAsync("can't find round for rumble [" + this.rumble.rounds.map(function (round) {
                    return round.players && round.players.join(",");
                }).join("],[") + "]");
            }
        }

        this.content = new cleverapps.Layout(this.listComponents(), {
            direction: cleverapps.UI.VERTICAL,
            padding: styles.padding,
            margin: styles.margin
        });

        this._super({
            name: "victorywindow",
            title: this.getTitleText(),
            shareId: !this.rumble && !cleverapps.instantTournament.isInTournament() && "victory",
            styles: styles,
            content: this.content,
            homeButton: !this.rumble,
            fireworks: !cleverapps.isKnockoutGame() && this.game.outcome === GameBase.OUTCOME_VICTORY,
            noBackground: true,
            closeButtonManual: true
        });

        if (this.boatswain) {
            this.boatswain.setIntent(this.boatswain.canNext() ? Boatswain.NEXT_INTENT : Boatswain.RETURN_INTENT);
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(styles.soundDelay || 0),
            new cc.PlaySound(bundles.victory_window.urls.win_effect)
        ));

        this.rewardsList.showUp(this.delayedShowUp.bind(this));
        this.content.children.forEach(function (component) {
            if (component.showUp && component !== this.rewardsList) {
                component.showUp();
            }
        }.bind(this));
    },

    getPerson: function () {
        return {
            role: "hero",
            emotion: "happy"
        };
    },

    getTitleText: function () {
        if (this.rumble) {
            return this.rumbleRound && this.rumbleRound.isLast()
                ? "KnockoutWindow.Title.Final"
                : Messages.get("KnockoutWindow.Title.Round", { roundId: (this.rumbleRound && this.rumbleRound.id || 0) + 1 });
        }

        if (this.game.outcome === GameBase.OUTCOME_LOST) {
            return "LoseWindow.title";
        }

        return "VictoryWindow.Victory";
    },

    listComponents: function () {
        var data = [];

        if (cleverapps.config.type === "battlefield") {
            data.push(new BattleStatsComponent());
        }

        if (Game.currentGame.getMode() === GameBase.MODE_HIGHSCORE) {
            data.push(new VictoryScoreComponent());
            data.push(new ScoreStatsComponent());
        }

        data.push(this.createRewards());

        if ((this.game.level.isBonus() || this.game.level.isHard()) && bundles.victory_window.jsons.bonus_animation_json) {
            data.push(new PictureComponent(this.game.level));
        }

        if (this.rumbleRound) {
            data.push(new RoundResultsComponent(this.rumbleRound, { victory: true }));
            data.push(new RoundCountdownComponent(function () {
                if (!this.closed) {
                    this.close();
                }
            }.bind(this)));
        }

        return data;
    },

    createRewards: function () {
        var styles = cleverapps.styles.VictoryWindow.rewards;

        var columns = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? 3 : 5;
        var rewardsList = this.rewardsList = new RewardsListComponent(this.rewards, {
            columns: columns,
            iconWrap: styles.iconWrap,
            textMargin: styles.textMargin,
            font: cleverapps.styles.FONTS.REWARDWINDOW_VALUE_TEXT,
            noShowControls: false,
            event: cleverapps.EVENTS.EARN.LEVEL_REWARD,
            shine: styles.shine,
            filterCustomAnimation: true
        });
        rewardsList.setLocalZOrder(2);
        return rewardsList;
    },

    beforeCloseAnimation: function (callback) {
        this.content.children.forEach(function (item) {
            item.beforeWinClose && item.beforeWinClose();
        });

        this.runAction(new cc.Sequence(
            new cc.TargetedAction(this.rewardsList, this.rewardsList.receiveRewardsAction()),
            new cc.CallFunc(function () {
                if (this.persons) {
                    this.persons.hide();
                    delete this.persons;
                }

                if (this.rewardText) {
                    this.rewardText.runAction(new cc.FadeOut(0.3));
                }

                if (this.windowTitle) {
                    this.windowTitle.hide();
                }
            }.bind(this)),
            new cc.CallFunc(function () {
                cleverapps.aims.whenAllHidden(callback);
            })
        ));
    },

    getPreferredBundles: function () {
        return ["game"];
    },

    listBundles: function () {
        return ["victory_window"].concat(this.rewards.listBundles());
    }
});

cleverapps.styles.VictoryWindow = {
    margin: 40,
    padding: {
        top: 60,
        bottom: 100
    },

    rewards: {
        textMargin: 0,

        iconWrap: {
            width: 130,
            height: 130
        },

        margin: 30,

        padding: {
            x: 0,
            y: 0
        }
    }
};
