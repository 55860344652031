/**
 * Created by Denis Kuzin on 29 march 2022
 */

var AddClanWindow = CleverappsWindow.extend({
    onWindowLoaded: function (userClan) {
        this.userClan = userClan;

        this.clanEmblem = userClan && userClan.emblem || 1;
        this.clanName = userClan && userClan.name || "";
        this.clanLevel = userClan && userClan.requiredLevel || ClansConfig.MIN_REQUIRED_LEVEL;

        this.emblemsAmount = 0;
        while (bundles.clan_banner.frames["emblem_" + (this.emblemsAmount + 1)]) {
            this.emblemsAmount++;
        }

        this._super({
            name: "AddClanWindow",
            content: this.createContent(),
            title: this.userClan ? "AddClanWindow.editingTeam.title" : "AddClanWindow.creatingTeam.title"
        });
        this.updateState();
    },

    createContent: function () {
        var styles = cleverapps.styles.AddClanWindow;

        var content = new cc.Node();
        content.setContentSize2(styles);

        var background = cleverapps.UI.createScale9Sprite(bundles.clan.frames.table_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(styles.background.width, styles.background.height);
        background.setPositionRound(styles.background);
        content.addChild(background);

        var emblem = this.createEmblem();
        emblem.setPositionRound(styles.emblem);
        background.addChild(emblem);

        var name = this.createName();
        name.setPositionRound(styles.name);
        background.addChild(name);

        var settings = this.createSettings();
        settings.setPositionRound(styles.settings);
        background.addChild(settings);

        var footerButton = this.createFooterButtons();
        footerButton.setPositionRound(styles.footerButton);
        content.addChild(footerButton);

        return content;
    },

    createEmblem: function () {
        var styles = cleverapps.styles.AddClanWindow.emblem;

        var emblemSelection = cleverapps.UI.createScale9Sprite(bundles.clans_window.frames.selector_background, cleverapps.UI.Scale9Rect.TwoPixelXY);
        emblemSelection.setContentSize2(styles.width, styles.height);

        var changeLink = new cc.Node();

        var changeText = cleverapps.UI.generateOnlyText("AddClanWindow.edit", cleverapps.styles.FONTS.CLAN_CHANGE_EMBLEM_TEXT);
        changeLink.addChild(changeText, 1);

        if (styles.underline) {
            changeLink.addChild(cleverapps.UI.drawUnderline(changeText, styles.underline));
        }

        cleverapps.UI.wrap(changeLink);

        var emblem = new cc.Sprite(bundles.clan_banner.frames["emblem_" + this.clanEmblem]);

        var layout = new cleverapps.Layout([changeLink, emblem], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
        layout.setPositionRound(styles.icon);
        emblemSelection.addChild(layout);

        var onClick = function (step) {
            this.clanEmblem += step;
            if (this.clanEmblem > this.emblemsAmount) {
                this.clanEmblem = 1;
            } else if (this.clanEmblem < 1) {
                this.clanEmblem = this.emblemsAmount;
            }
            emblem.setSpriteFrame(new cc.Sprite(bundles.clan_banner.frames["emblem_" + this.clanEmblem]).getSpriteFrame());
            this.updateState();
        }.bind(this);

        cleverapps.UI.onClick(changeLink, onClick.bind(this, 1));

        return new cleverapps.Layout([this.getArrow(true, onClick.bind(this, -1)), emblemSelection, this.getArrow(false, onClick.bind(this, 1))], {
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    createName: function () {
        var styles = cleverapps.styles.AddClanWindow.name;

        var bg = cleverapps.UI.createScale9Sprite(bundles.clans_window.frames.selector_background, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(styles.width, styles.height);

        var placeholder = cleverapps.UI.generateOnlyText("AddClanWindow.teamNamePlaceholder", cleverapps.styles.FONTS.CLAN_EDIT_TEXT);
        placeholder.setDimensions(styles.text.width, 0);
        placeholder.fitTo(undefined, styles.height);
        placeholder.setPositionRound(styles.text.placeholder);
        placeholder.setOpacity(140);
        bg.addChild(placeholder);

        var text = new cc.EditBox(cc.size(styles.text.width, styles.text.height), undefined, true);
        text.setMaxLength(styles.text.maxLength);
        text.setString(" ");
        text.setDelegate(this);
        text.stayOnTop(true);
        text.setTouchEnabled();
        text.setFontSize(cleverapps.styles.FONTS.CLAN_EDIT_TEXT.size);
        text.setFontColor(cleverapps.styles.FONTS.CLAN_EDIT_TEXT.color);
        text.setFontName(cleverapps.UI.getFontName());
        text.setString(this.clanName);
        text.setPositionRound(bg.width / 2, bg.height / 2 + styles.text.offsetY);
        text.customPlaceholder = placeholder;
        bg.addChild(text);

        if (this.userClan) {
            placeholder.setVisible(false);
        }

        this.clanNameEditbox = text;

        var label = cleverapps.UI.generateOnlyText("AddClanWindow.teamNameLabel", cleverapps.styles.FONTS.CLAN_PROPERTY_TEXT);
        label.fitTo(styles.label.width);

        return new cleverapps.Layout([label, bg], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    },

    createSettings: function () {
        var styles = cleverapps.styles.AddClanWindow.settings;

        var getItem = function (label, values, idx, updater) {
            var settingName = cleverapps.UI.generateOnlyText(label, cleverapps.styles.FONTS.CLAN_PROPERTY_TEXT);
            settingName.setDimensions(styles.label.width, 0);
            settingName.fitTo(undefined, styles.label.height);

            var bg = cleverapps.UI.createScale9Sprite(bundles.clans_window.frames.selector_background, cleverapps.UI.Scale9Rect.TwoPixelXY);
            bg.setContentSize2(styles.bg.width, styles.bg.height);

            var text = cleverapps.UI.generateOnlyText(values[idx].text, cleverapps.styles.FONTS.CLAN_SETTING_TEXT);
            text.fitTo(styles.text.width);
            text.setPositionRound(bg.width / 2, bg.height / 2);
            bg.addChild(text);

            updater(values[idx].value);

            var onClick = function (step) {
                idx += step;
                idx = Math.max(0, Math.min(values.length - 1, idx));

                text.setString(values[idx].text);
                updater(values[idx].value);

                this.updateState();
            }.bind(this);

            var leftArrow = this.getArrow(true, onClick);
            var rightArrow = this.getArrow(false, onClick);

            var settingValue = new cleverapps.Layout([leftArrow, bg, rightArrow], {
                direction: cleverapps.UI.HORIZONTAL
            });

            return new cleverapps.Layout([settingName, settingValue], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.row.margin
            });
        }.bind(this);

        var availableLevels = [];
        var currentLevelIdx = 0;
        var level = ClansConfig.MIN_REQUIRED_LEVEL;
        do {
            availableLevels.push({ text: level, value: level });
            if (level === this.clanLevel) {
                currentLevelIdx = availableLevels.length - 1;
            }
            level++;
        } while (level <= cleverapps.gameLevel.getLevel() && level <= ClansConfig.MAX_REQUIRED_LEVEL);

        var requiredLevel = getItem("AddClanWindow.requiredLevel", availableLevels, currentLevelIdx, function (value) {
            this.clanLevel = value;
        }.bind(this));

        return new cleverapps.Layout([requiredLevel], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    getArrow: function (reverse, onClick) {
        var button = new cleverapps.UI.Button({
            type: {
                button_png: bundles.clans_window.frames.selector_arrow,
                button_on_png: bundles.clans_window.frames.selector_arrow,
                button_off_png: bundles.clans_window.frames.selector_arrow
            },
            onClicked: function () {
                onClick(reverse ? -1 : 1);
            }
        });

        if (reverse) {
            button.baseScaleX = -1;
            button.baseScaley = 1;
            button.setScaleX(-1);
        }

        return button;
    },

    createFooterButtons: function () {
        var styles = cleverapps.styles.AddClanWindow.footerButton;
        var text = this.userClan ? "Edit$$ " + ClansConfig.EDITING_PRICE
            : "Create$$ " + ClansConfig.CREATION_PRICE;

        this.createButton = new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            type: cleverapps.styles.UI.Button.Images.button_blue,
            text: text,
            disabled: true,
            onClicked: function () {
                cleverapps.clans.create({
                    clanId: this.userClan && this.userClan.id,
                    name: this.clanNameEditbox.getString(),
                    emblem: this.clanEmblem,
                    requiredLevel: this.clanLevel
                });
            }.bind(this)
        });

        return this.createButton;
    },

    updateState: function () {
        if (!this.createButton) {
            return;
        }

        var tooltip;

        var str = this.clanNameEditbox.getString();
        if (!str) {
            tooltip = "AddClanWindow.emptyNameTooltip";
        } else if (/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g.test(str)) {
            tooltip = "AddClanWindow.invalidNameTooltip";
        } else if (this.userClan && str === this.userClan.name && this.clanEmblem === this.userClan.emblem && this.userClan.requiredLevel === this.clanLevel) {
            tooltip = "AddClanWindow.noChangesToEdit";
        }

        cleverapps.tooltipManager.remove(this.createButton);

        if (!tooltip) {
            this.createButton.enable();
            return;
        }

        this.createButton.disable();
        cleverapps.tooltipManager.create(this.createButton, {
            text: tooltip
        });
    },

    onHide: function () {
        this._super();
        this.clanNameEditbox.stayOnTop(false);
    },

    onShow: function () {
        this._super();
        this.clanNameEditbox.stayOnTop(true);
    },

    editBoxEditingDidBegin: function (sender) {
        sender.customPlaceholder.setVisible(false);
    },

    editBoxEditingDidEnd: function (sender) {
        if (sender.getString() === "") {
            sender.customPlaceholder.setVisible(true);
        }
    },

    editBoxTextChanged: function (sender, text) {
        if (text === "\n") {
            sender.setString(this._tmpText || "");
        }

        this.updateState();
    },

    editBoxReturn: function (sender) {
        this._tmpText = sender.getString();

        sender.setString("");
    }
});

cleverapps.styles.AddClanWindow = {
    width: 1280,
    height: 730,

    background: {
        width: 1340,
        height: 570,
        x: { align: "center" },
        y: { align: "top" }
    },

    footerButton: {
        width: 400,
        height: 100,
        x: { align: "center" },
        y: { align: "bottom", dy: 20 }
    },

    emblem: {
        width: 300,
        height: 300,
        x: { align: "left", dx: 75 },
        y: { align: "bottom", dy: 50 },
        icon: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        },
        underline: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -18 },
            height: 2
        },
        margin: 8
    },

    name: {
        width: 750,
        height: 100,
        margin: 85,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -50 },

        label: {
            width: 430
        },

        text: {
            maxLength: 25,
            width: 690,
            height: 72,
            offsetY: 4,

            placeholder: {
                x: { align: "left", dx: 35 },
                y: { align: "center", dy: 2 }
            },

            regular: {
                x: { align: "left", dx: 35 },
                y: { align: "center", dy: 4 }
            }
        }
    },

    settings: {
        margin: 20,
        x: { align: "right", dx: -90 },
        y: { align: "bottom", dy: 50 },

        bg: {
            width: 300,
            height: 100
        },

        row: {
            margin: 50
        },

        label: {
            width: 350,
            height: 100
        },

        text: {
            width: 280
        }
    }
};