/**
 * Created by Anastasiya on 08.06.2024
 */

var PixelOffer = function (offer) {
    this.offer = offer;

    this.flying = false;

    this.onDie = function () {};
    this.onFlyingChanged = function () {};

    offer.logic.on("onProductBought", this.die.bind(this), this);

    var centerCell = Map2d.currentMap.getScreenCenterCell() || Map2d.currentMap.scrollCell;
    var startingY = 0;

    for (var y = startingY; y < centerCell.y; y++) {
        if (Map2d.currentMap.isScreenCellPosition(centerCell.x, y)) {
            break;
        }

        startingY = y;
    }

    var x = centerCell.x + 2;
    this.setPosition(x, startingY - 5);
};

PixelOffer.prototype.destructor = function () {
    runCleaners(this);
};

PixelOffer.prototype.collect = function () {
    cleverapps.offerManager.showOfferWindow(this.offer);
};

PixelOffer.prototype.setPosition = function (x, y) {
    this.x = x;
    this.y = y;
};

PixelOffer.prototype.setFlying = function (flying) {
    if (flying !== this.flying) {
        this.flying = flying;
        this.onFlyingChanged(this.flying);
    }
};

PixelOffer.prototype.die = function (silent) {
    this.dead = true;

    this.setFlying(false);

    this.onDie(silent);
};