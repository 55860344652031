/**
 * Created by vladislav on 25/10/2022
 */

QuestsConfig.rapunzel = {
    rpdoll_3: {
        trigger: { unit: { code: "rpprince", stage: 0 } },
        tutorial: "rapunzel_dollsource",
        finishTutorial: "rapunzel_customer",
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpdoll", stage: 3 }
        }
    },
    rpperfume_3: {
        trigger: { unit: { code: "rpperfume", stage: 0 } },
        tutorial: "rpperfume_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpperfume", stage: 3 }
        }
    },
    rpcandle_3: {
        trigger: { unit: { code: "rpcandle", stage: 0 } },
        tutorial: "rpcandle_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpcandle", stage: 3 }
        }
    },
    rpjewelry_3: {
        trigger: { unit: { code: "rpjewelry", stage: 0 } },
        tutorial: "rpjewelry_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpjewelry", stage: 3 }
        }
    },
    rpcomb_3: {
        trigger: { unit: { code: "rpcomb", stage: 0 } },
        tutorial: "rpcomb_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpcomb", stage: 3 }
        }
    },
    rpchameleon_3: {
        trigger: { unit: { code: "rpchameleon", stage: 0 } },
        tutorial: "rpchameleon_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpchameleon", stage: 3 }
        }
    },
    rpdress_3: {
        trigger: { unit: { code: "rpdress", stage: 0 } },
        tutorial: "rpdress_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpdress", stage: 3 }
        }
    },
    rpanimals_3: {
        trigger: { unit: { code: "rpanimals", stage: 0 } },
        tutorial: "rpanimals_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rpanimals", stage: 3 }
        }
    },
    rprapunzel_1: {
        trigger: { unit: { code: "rprapunzel", stage: 0 } },
        tutorial: "rprapunzel_1",
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rprapunzel", stage: 1 }
        }
    },
    rprapunzel_2: {
        trigger: { unit: { code: "rprapunzel", stage: 1 } },
        tutorial: "rprapunzel_2",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rprapunzel", stage: 2 }
        }
    },
    rprapunzel_3: {
        trigger: { unit: { code: "rprapunzel", stage: 2 } },
        tutorial: "rprapunzel_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rprapunzel", stage: 3 }
        }
    },
    rprapunzel_4: {
        trigger: { unit: { code: "rprapunzel", stage: 3 } },
        tutorial: "rprapunzel_4",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rprapunzel", stage: 4 }
        }
    },
    rprapunzel_5: {
        trigger: { unit: { code: "rprapunzel", stage: 4 } },
        tutorial: "rprapunzel_5",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rprapunzel", stage: 5 }
        }
    },
    rprapunzel_6: {
        trigger: { unit: { code: "rprapunzel", stage: 5 } },
        tutorial: "rprapunzel_6",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rprapunzel", stage: 6 }
        }
    },
    rprapunzel_7: {
        trigger: { unit: { code: "rpdoll", stage: 0 } },
        tutorial: "rprapunzel_7",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "rprapunzel", stage: 7 }
        }
    }
};