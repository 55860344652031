/**
 * Created by r4zi4l on 25.02.2021
 */

var MergeAdviceBuildLogic = function (options) {
    MergeAdviceBaseLogic.call(this, options);
};

MergeAdviceBuildLogic.prototype = Object.create(MergeAdviceBaseLogic.prototype);
MergeAdviceBuildLogic.prototype.constructor = MergeAdviceBuildLogic;

MergeAdviceBuildLogic.prototype.findMove = function () {
    var unit = this.listAvailableUnits().filter(function (unit) {
        return !Buildable.IsBuilt(unit) && !Map2d.currentMap.workers.findAssigned(unit) && Map2d.currentMap.workers.countRegularFree() > 0;
    })[0];

    if (unit) {
        var infoOpen = InfoView.IsDisplayedFor(unit);
        var cell = cc.p(unit.x, unit.y);
        return {
            cells: [cell],
            highlight: !infoOpen && [cell],
            arrow: !infoOpen && cell,
            unitView: infoOpen && ["build"]
        };
    }
};