/**
 * Created by vladislav on 19.05.20.
 */

var HomeTool = {
    setInt: function (furnitureId, stage, skipLast, callback) {
        var home = cleverapps.meta.getMainObject();

        while (!cleverapps.meta.isCompleted() && (home.getCurrentFurnitureId() !== furnitureId || home.getMaxFurniture().stage !== stage)) {
            var currentFurniture = home.getMaxFurniture();
            while (!currentFurniture.canBeUpgraded() && !levels.user.isPassedAll()) {
                var level = new Level(levels.user.episode, levels.user.level);
                cleverapps.meta.changeStars(home.amountLevelStars(level));
                levels.user.incLevel();
            }

            var nextProgress = this.calcNextProgress(currentFurniture);
            if (skipLast && nextProgress.furnitureId === furnitureId && nextProgress.stage === stage) {
                break;
            } else {
                this.moveNext();
            }
        }

        callback();
    },

    set: function (furnitureId, stage, skipLast, callback) {
        var home = cleverapps.meta.getMainObject();
        this.reset();

        this.setInt(furnitureId, stage, skipLast, function () {
            home.save();
            home.tasks.setActives();
            cleverapps.user.save();

            callback();
        });
    },

    calcNextProgress: function (furniture) {
        if (furniture.stage + 1 === furniture.stages.length) {
            return {
                furnitureId: furniture.id + 1,
                stage: 0
            };
        }

        return {
            furnitureId: furniture.id,
            stage: furniture.stage + 1
        };
    },

    moveNext: function () {
        var home = cleverapps.meta.getMainObject();
        var furniture = home.getMaxFurniture();
        if (!furniture.nextStage()) {
            furniture.choices && (furniture.choice = 0);
            home.setCurrentFurnitureId(home.getNextId());
        }
    },

    reset: function () {
        cleverapps.meta.reset();
        cleverapps.user.level = 0;
        cleverapps.user.episode = 0;
    }
};