/**
 * Created by Vladislav on 25.09.2024.
 */

var WorkersMenuBarItem = {
    name: "WorkersItem",
    plusButton: true,
    targets: "worker",
    timerTTF: true,
    viewClass: MenuBarWithTimeoutView,
    modelClass: WorkersMenuBarItemModel,
    sparks: true,
    attention: function () {
        return typeof Map2d !== "undefined" && Map2d.currentMap && Map2d.currentMap.workers.isAdsWorkerAvailable()
            && cleverapps.adsLimits.state(AdsLimits.TYPES.WORKER) === AdsLimits.STATE_READY && cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_ADS;
    },
    onClickWindow: BonusWorkerWindow,
    icon: function () {
        return cleverapps.skins.getSlot("merge_worker_png") || bundles.menubar.frames.merge_worker_png;
    },
    availableOnScenes: function () {
        var page = cleverapps.travelBook.getCurrentPage();
        if (page.withWorkers()) {
            return [cleverapps.Environment.SCENE_MAIN];
        }
    },
    deltaSound: bundles.menubar.urls.worker_fly_finish_effect,
    sticker: function () {
        var page = cleverapps.travelBook.getCurrentPage();
        if (page.isMain() && Subscription.IsAvailable() && cleverapps.subscription.isActive()) {
            return "premium";
        }
    }
};