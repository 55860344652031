/**
 * Created by mac on 12/27/20
 */

var ValuableMine = {
    prize: function (level, unit) {
        return [{
            units: [{ code: unit.code, stage: 0 }]
        }, {
            probabilities: [0.5],
            units: [{ code: unit.code, stage: 0 }]
        }];
    }
};
