/**
 * Created by razial on 23.05.2024.
 */

QuestsConfig.adventure = {
    advcustomermain2_0: {
        trigger: { fog: "fog0" },
        tutorial: "advshrub_1",
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "advcustomermain2", stage: 0 }
        }
    },

    advshrub_1: {
        trigger: { fog: "fog0" },
        quest: {
            type: Map2d.MINE,
            unit: { code: "advshrub", stage: 1 }
        }
    },

    advcustomerbridge0_1: {
        trigger: { unit: { code: "advcustomerbridge0", stage: 0 } },
        tutorial: "advcustomerbridge0_1",
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "advcustomerbridge0", stage: 1 }
        }
    },

    advtree_1: {
        trigger: { unit: { code: "advcustomerbridge0", stage: 0 } },
        quest: {
            type: Map2d.MINE,
            unit: { code: "advtree", stage: 1 }
        }
    },

    advbush_1: {
        trigger: { quest: "advtree_1" },
        quest: {
            type: Map2d.MINE,
            unit: { code: "advbush", stage: 1 }
        }
    },

    advcustomerbridge1_0: {
        trigger: { quest: "advbush_1" },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "advcustomerbridge1", stage: 0 }
        }
    },

    advpile_0_1: {
        trigger: { quest: "advcustomerbridge1_0" },
        quest: {
            type: Map2d.PRIZE_HARVESTED,
            unit: { code: "advpile", stage: 0 }
        }
    },

    advcustomerbridge1_1: {
        trigger: { unit: { code: "advcustomerbridge1", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "advcustomerbridge1", stage: 1 }
        }
    },

    advcustomermain1_0: {
        trigger: { quest: "advcustomerbridge1_1" },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "advcustomermain1", stage: 0 }
        }
    },

    advtree_4: {
        trigger: { quest: "advcustomerbridge1_1" },
        quest: {
            type: Map2d.MINE,
            unit: { code: "advtree", stage: 4 }
        }
    },

    advcustomermain1_1: {
        trigger: { unit: { code: "advcustomermain1", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "advcustomermain1", stage: 1 }
        }
    },

    advbush_5: {
        trigger: { quest: "advcustomermain1_1" },
        quest: {
            type: Map2d.MINE,
            unit: { code: "advbush", stage: 5 }
        }
    },

    advcustomerbridge2_0: {
        trigger: { quest: "advbush_5" },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "advcustomerbridge2", stage: 0 }
        }
    },

    advpile_0_2: {
        trigger: { quest: "advcustomerbridge2_0" },
        quest: {
            type: Map2d.PRIZE_HARVESTED,
            unit: { code: "advpile", stage: 0 }
        }
    },

    advcustomerbridge2_1: {
        trigger: { unit: { code: "advcustomerbridge2", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "advcustomerbridge2", stage: 1 }
        }
    },

    advcustomermain2_1: {
        trigger: { unit: { code: "advcustomermain2", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "advcustomermain2", stage: 1 }
        }
    },

    advfinalquest: {
        trigger: { unit: { code: "advcustomermain2", stage: 1 } },
        dynamic: {
            getIcon: function () {
                return new cc.Sprite(bundles.quests.frames.mine_sources);
            },
            getGoal: function () {
                var units = Map2d.currentMap.listAvailableUnits(function (unit) {
                    return Families[unit.code].type === "advsource";
                });

                var fogUnits = Map2d.currentMap.fogs.listFakeUnits(function (unit) {
                    return Families[unit.code].type === "advsource" && Unit.GetShape(unit).every(function (shape) {
                        return Map2d.currentMap.getFog(unit.x + shape.x, unit.y + shape.y);
                    });
                });

                return Math.min(Math.ceil((units.length + fogUnits.length) * 0.7), 15);
            }
        },
        quest: {
            type: Map2d.MINE,
            unit: { type: "advsource" },
            toMainWorld: true
        }
    }
};
