/**
 * Created by evgenijsenkevic on 28.08.2024
 * */

var AdminLocation = function (bundleName) {
    cleverapps.EventEmitter.call(this);

    this.bundleName = bundleName;
};

AdminLocation.prototype = Object.create(cleverapps.EventEmitter.prototype);
AdminLocation.prototype.constructor = AdminLocation;

AdminLocation.prototype.getBundleName = function () {
    return this.bundleName;
};

AdminLocation.prototype.getLocationName = function () {
    return this.bundleName.replace("location_", "");
};

AdminLocation.prototype.onClick = function (f) {
    cleverapps.administrator.adminLocations.setActive(this);
    f();
};

AdminLocation.prototype.select = function () {
    this.trigger("select", this);
};

AdminLocation.prototype.unselect = function () {
    this.trigger("unselect");
};

AdminLocation.prototype.getLocationBundles = function () {
    var locationName = this.getLocationName();
    return [
        this.bundleName,
        Map2d.getTilesTexture(bundles[this.bundleName].meta.tiles || locationName),
        Map2d.getUnitsTexture(bundles[this.bundleName].meta.units || locationName)
    ].filter(function (bundleName) {
        return bundles[bundleName];
    });
};
