/**
 * Created by slava on 4/4/17.
 */

cleverapps.ABTest = function () {
    this.groups = {};

    cleverapps.country.on("ready", this.updateGroups.bind(this));

    var json = bundles.abtest.jsons.abtest_json.getJson();

    this.remoteJson = new RemoteJson(json);
    this.remoteJson.on("update", this.updateGroups.bind(this));
};

cleverapps.ABTest.A = 0;
cleverapps.ABTest.B = 1;
cleverapps.ABTest.C = 2;
cleverapps.ABTest.D = 3;

cleverapps.ABTest.groupEventNames = {};

Object.keys(cleverapps.ABTest).forEach(function (name) {
    var group = cleverapps.ABTest[name];
    if (typeof group === "number") {
        cleverapps.ABTest.groupEventNames[group] = name.toLowerCase();
    }
});

cleverapps.ABTest.prototype.initialize = function (callback) {
    this.remoteJson.initialize(callback);
};

cleverapps.ABTest.prototype.updateTests = function () {
    var tests = this.remoteJson.getJson();
    cleverapps.ABTest.Tests = JSON.parse(JSON.stringify(tests));

    for (var id in cleverapps.ABTest.Tests) {
        if (typeof cleverapps.ABTest.Tests[id] !== "object") {
            delete cleverapps.ABTest.Tests[id];
        }
    }

    cleverapps.ABTest.initializeTests();
};

cleverapps.ABTest.prototype.updateGroups = function () {
    this.updateTests();

    this.groups = {};

    var data = cleverapps.dataLoader.load(DataLoader.TYPES.ABTEST) || {};

    for (var id in cleverapps.ABTest.Tests) {
        var test = cleverapps.ABTest.Tests[id];

        if (cleverapps.user.checkAvailable(test.available)) {
            if (data[id] !== undefined) {
                this.setGroup(test, data[id]);
                continue;
            }

            this.setGroup(test, cleverapps.ABTest.calcGroup(test));
        }
    }
};

cleverapps.ABTest.prototype.forceGroup = function (test, group) {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.ABTEST) || {};
    data[test.id] = group;
    cleverapps.dataLoader.save(DataLoader.TYPES.ABTEST, data);

    this.updateGroups();
};

cleverapps.ABTest.prototype.getGroup = function (test) {
    if (!test) {
        return undefined;
    }

    return this.groups[test.id];
};

cleverapps.ABTest.prototype.setGroup = function (test, group) {
    this.groups[test.id] = group;
};

cleverapps.ABTest.prototype.logEvent = function (test, event, params) {
    var group = this.getGroup(test);
    if (group !== undefined) {
        group = cleverapps.ABTest.groupEventNames[group];
        cleverapps.eventLogger.logEvent("ab_" + test.id + "_" + group + "_" + event, params);
    }
};

cleverapps.ABTest.prototype.allLogEvent = function (event, params) {
    params = params || {};

    var page = cleverapps.travelBook.getCurrentExpedition();
    var expedition = params.expedition || page && page.id;

    for (var id in this.groups) {
        var test = cleverapps.ABTest.Tests[id];

        if (test.expeditions && test.expeditions.indexOf(expedition) === -1) {
            continue;
        }

        var group = cleverapps.ABTest.groupEventNames[this.getGroup(test)];
        cleverapps.eventLogger.logEvent("ab_" + test.id + "_" + group + "_" + event, params);
    }
};

cleverapps.ABTest.TSEARCH = function () {
    var group = cleverapps.abTest.getGroup(cleverapps.ABTest.Tests.tsearch);

    if (group === undefined) {
        return false;
    }

    return cleverapps.config.type !== "board" || group === cleverapps.ABTest.A;
};

cleverapps.ABTest.STICKERS_BOOK = function () {
    return cleverapps.abTest.getGroup(cleverapps.ABTest.Tests.stickers) === cleverapps.ABTest.A;
};

cleverapps.ABTest.PRIZECAL = function () {
    return cleverapps.abTest.getGroup(cleverapps.ABTest.Tests.prizecal) === cleverapps.ABTest.A;
};

cleverapps.ABTest.PINBALL = function () {
    return cleverapps.abTest.getGroup(cleverapps.ABTest.Tests.pinball) === cleverapps.ABTest.A;
};

cleverapps.ABTest.MONEY_FOG1 = function () {
    return cleverapps.abTest.getGroup(cleverapps.ABTest.Tests.moneyFog1) === cleverapps.ABTest.A;
};

cleverapps.ABTest.WELCOME = function () {
    return cleverapps.abTest.getGroup(cleverapps.ABTest.Tests.welcome) === cleverapps.ABTest.A;
};

cleverapps.ABTest.DISCORD_NOTIFICATIONS_ALL = function () {
    return cleverapps.abTest.getGroup(cleverapps.ABTest.Tests.dcnotifications) === cleverapps.ABTest.A;
};

cleverapps.ABTest.DISCORD_NOTIFICATIONS_WELCOME = function () {
    return cleverapps.abTest.getGroup(cleverapps.ABTest.Tests.dcnotifications) === cleverapps.ABTest.B;
};

cleverapps.ABTest.STAR_CHEST = function () {
    return cleverapps.abTest.getGroup(cleverapps.ABTest.Tests.starchest) === cleverapps.ABTest.B;
};

cleverapps.ABTest.ChangeConfigsForAB = function () {
    // console.log("Change configs for AB, ", expedition);
    // FamiliesHelper.unoverrideFamilies();
};

cleverapps.ABTest.calcGroup = function (test, userId) {
    if (cleverapps.isLocalhost()) {
        return cleverapps.ABTest.A;
    }

    var group;
    var rnd = cleverapps.Random.seededDouble((userId || connector.platform.getUserID()) + test.id);

    if (test.groups.length > 2) {
        var groupWeight = 1 / test.groups.length;
        group = test.groups[Math.floor(rnd / groupWeight)];
    } else {
        group = rnd > test.AWeight ? cleverapps.ABTest.B : cleverapps.ABTest.A;
    }

    return group;
};

cleverapps.ABTest.initializeTests = function () {
    for (var name in cleverapps.ABTest.Tests) {
        var test = cleverapps.ABTest.Tests[name];
        test.id = name;
        test.groups = test.groups || [cleverapps.ABTest.A, cleverapps.ABTest.B];
        test.AWeight = test.AWeight || 0.5;
    }
};

if (typeof cc === "undefined") {
    cleverapps.ABTest.Tests = require("./abtest.json");
    cleverapps.ABTest.initializeTests();

    module.exports = cleverapps.ABTest;
}
