/**
 * Created by vladislav on 20/10/2022
 */

Mines.rpsource = function (level, unit) {
    if (unit.stage === 1) {
        return [
            {
                amount: 5,
                units: [
                    { code: unit.getResource(), stage: 1 }
                ]
            },
            {
                amount: 1,
                probabilities: [0.1],
                units: [
                    { code: "coins", stage: 1 }
                ]
            }
        ];
    }

    return [
        {
            amount: 4,
            units: [
                { code: unit.getResource(), stage: 0 },
                { code: unit.getResource(), stage: 1 }
            ]
        }
    ];
};

Mines.rpsourcegenerator = function () {
    return { openedType: "rpsource" };
};

PassLevelsConfig[Mission.CompoundType(Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_RAPUNZEL_EXPEDITION)] = [
    {
        energy: 20,
        task: {
            goal: 15
        },
        reward: {
            unit: { code: "worker", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 1, amount: 1 }
        }
    }, // 1
    {
        energy: 20,
        task: {
            goal: 45
        },
        reward: {
            unit: { code: "sacks", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 1, amount: 1 }
        }
    }, // 2
    {
        energy: 20,
        task: {
            goal: 100
        },
        reward: {
            unit: { code: "wands", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "wands", stage: 2, amount: 1 }
        }
    }, // 3
    {
        energy: 20,
        task: {
            goal: 210
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 4, amount: 1 }
        }
    }, // 4
    {
        energy: 20,
        task: {
            goal: 430
        },
        reward: {
            unit: { code: "crystal", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 1, amount: 3 }
        }
    }, // 5
    {
        energy: 20,
        task: {
            goal: 900
        },
        reward: {
            unit: { code: "treasure", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 1 }
        }
    }, // 6
    {
        energy: 20,
        task: {
            goal: 1100
        },
        reward: {
            unit: { code: "sacks", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 2, amount: 1 }
        }
    }, // 7
    {
        energy: 20,
        task: {
            goal: 1300
        },
        reward: {
            unit: { code: "herochest", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 1, amount: 2 }
        }
    }, // 8
    {
        energy: 20,
        task: {
            goal: 1500
        },
        reward: {
            unit: { code: "crystal", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 3, amount: 2 }
        }
    }, // 9
    {
        energy: 20,
        task: {
            goal: 2100
        },
        reward: {
            unit: { code: "herochest", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "energytree", stage: 2, amount: 1 }
        }
    } // 10
];

Landmarks.WORLDS.rapunzel = {
    fog: "fog1",
    force: Forces.LANDMARK_DONOR_SLOT2,
    landmarks: [{
        code: "landmark",
        stage: 1,
        targetExpedition: "main",
        donors: "rprapunzel"
    }]
};

cleverapps.styles.rapunzel = {
    miningAnimation: {
        x: { align: "center", dx: 0, leftDx: 0 },
        y: { align: "center", dy: 0 }
    },

    fogBlockerLock: {
        width: 100,
        height: 100,
        margin: 25,

        content: {
            width: 68,
            height: 68
        },
        position: {
            x: { align: "center" },
            y: { align: "center", dy: 50 }
        },
        text: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        icon: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        cloud: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -39 }
        },
        lock: {
            x: { align: "center" },
            y: { align: "center" }
        }
    }
};

CustomerRecipes.rapunzel = {
    rpprince_0: [
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rporder",
                    stage: 0,
                    amount: 5
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rporder",
                    stage: 0,
                    amount: 10
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rporder",
                    stage: 0,
                    amount: 5
                },
                {
                    type: "rporder",
                    stage: 1,
                    amount: 6
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rporder",
                    stage: 0,
                    amount: 5
                },
                {
                    type: "rporder",
                    stage: 0,
                    amount: 5
                },
                {
                    type: "rporder",
                    stage: 1,
                    amount: 8
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rporder",
                    stage: 0,
                    amount: 10
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 2
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rporder",
                    stage: 0,
                    amount: 5
                },
                {
                    type: "rporder",
                    stage: 1,
                    amount: 6
                }
            ]
        }

    ],
    rpthrone_0: [
        {
            ingredients: [
                {
                    type: "rporder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rphero",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "rphero",
                    stage: 1,
                    amount: 2
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rporder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rphero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "rphero",
                    stage: 1,
                    amount: 3
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rporder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "rporder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rphero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "rphero",
                    stage: 1,
                    amount: 6
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rporder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "rporder",
                    stage: 3,
                    amount: 2
                }
            ],
            order: [
                {
                    type: "rphero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "rphero",
                    stage: 1,
                    amount: 6
                }
            ]
        }
    ]
};
