/**
 * Created by vtbelo on 01.04.21
 */

var UnitsShopTileModel = function (data) {
    TileModel.call(this, data);

    this.onBuy = function () {};
};

UnitsShopTileModel.prototype = Object.create(TileModel.prototype);
UnitsShopTileModel.prototype.constructor = UnitsShopTileModel;

UnitsShopTileModel.prototype.getViewClassName = function () {
    return UnitsShopTile;
};

UnitsShopTileModel.prototype.getAvailable = function () {
    return this.product.left > 0;
};

UnitsShopTileModel.prototype.getCurrentPrice = function () {
    if (this.isFree()) {
        return "FREE";
    }

    if (this.product.priceType === UnitsShop.COIN_PRICE) {
        return "@@" + this.product.price;
    }

    if (this.product.priceType === UnitsShop.RUBY_PRICE) {
        return "$$" + this.product.price;
    }

    if (this.product.priceType === UnitsShop.ADS_PRICE) {
        return "##" + this.currentVideoProgress() + "/" + this.product.videoPrice;
    }
};

UnitsShopTileModel.prototype.getHelpButtonAction = function () {
    if (this.isChest()) {
        return function () {
            new ChestPrizeProbWindow(this.product.unit, Chest.ListPrizeProb(this.product.unit));
        }.bind(this);
    }

    if (this.product.guideWindowOptions) {
        return function () {
            new GuideWindow(this.product.guideWindowOptions, this.product.unit);
        }.bind(this);
    }
};

UnitsShopTileModel.prototype.isChest = function () {
    return Families[this.product.unit.code].type === cleverapps.unitsLibrary.getExpeditionUnitType("chest");
};

UnitsShopTileModel.prototype.isPaidOnlyUnit = function () {
    if (!Families[this.product.unit.code]) {
        cleverapps.throwAsync("isPaidOnlyUnit:  No family for code " + this.product.unit.code);
        return false;
    }

    return ["drrubyhero", "searubyhero", "searubyhero"].indexOf(Families[this.product.unit.code].type) !== -1;
};

UnitsShopTileModel.prototype.isAttention = function () {
    return this.isFree() && this.isAvailable();
};

UnitsShopTileModel.prototype.isFree = function () {
    return this.product.price <= 0 || this.product.price === undefined;
};

UnitsShopTileModel.prototype.wantsScroll = function () {
    return this.isFree() && this.product.left > 0;
};

UnitsShopTileModel.prototype.isAvailable = function () {
    if (this.product.videoProduct && !cleverapps.rewardedAdsManager.isRewardedAvailable()) {
        return false;
    }

    return this.product.left > 0;
};

UnitsShopTileModel.prototype.isLocked = function () {
    return this.product.left <= 0;
};

UnitsShopTileModel.prototype.canSpawnUnits = function (buyParams) {
    var amount = this.product.amount;
    if (buyParams.videoBonus) {
        amount *= 2;
    }

    var cells = Families[this.product.unit.code].units[this.product.unit.stage].multicell;
    cells = cells && cells.length || 1;

    var left = amount * cells - Map2d.currentMap.countEmptySlots();
    if (left > 0) {
        cleverapps.centerHint.createTextHint("Spawn.nospace", { left: left });

        return false;
    }

    if (cells > 1) {
        var centerCell = Map2d.currentMap.getScreenCenterCell();
        var slot = Map2d.currentMap.findEmptySlot(centerCell.x, centerCell.y, this.product.unit);
        if (!slot) {
            cleverapps.centerHint.createTextHint("Spawn.nospace", { left: "2x2" });
            return false;
        }
    }

    return true;
};

UnitsShopTileModel.prototype.buy = function (closeShop, buyParams) {
    buyParams = buyParams || {};

    if (this.product.left > 0) {
        if (!this.canSpawnUnits(buyParams)) {
            return;
        }

        if (this.product.priceType === UnitsShop.ADS_PRICE) {
            this.buyVideoProduct(function () {
                this.receiveItem(buyParams);
            }.bind(this));

            return;
        }

        var result = false;
        if (this.isFree()) {
            result = true;
        } else {
            result = (this.product.priceType === UnitsShop.COIN_PRICE) ? cleverapps.user.spendSoft(
                this.isPaidOnlyUnit() ? cleverapps.EVENTS.SPENT.PAID_UNIT : cleverapps.EVENTS.SPENT.UNIT,
                this.product.price,
                { source: this.onGetView() && this.onGetView().button }
            ) : cleverapps.user.spendHard(
                this.isPaidOnlyUnit() ? cleverapps.EVENTS.SPENT.PAID_UNIT : cleverapps.EVENTS.SPENT.UNIT,
                this.product.price
            );
        }
        if (result) {
            this.receiveItem(buyParams);

            var window = cleverapps.windows.findWindow(ShopWindow);

            if ([UnitsShop.COIN_PRICE, UnitsShop.RUBY_PRICE].includes(this.product.priceType) && this.product.price > 0) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.BUY_ITEM, {
                    store_type: window && window.getTitle(),
                    item_id: this.product.itemId,
                    item_num: this.product.amount,
                    item_price: this.product.price,
                    cost_type: this.product.priceType === UnitsShop.COIN_PRICE ? "soft" : "hard"
                });
            }
        }
    }
};

UnitsShopTileModel.prototype.receiveItem = function (buyParams) {
    cleverapps.unitsShop.tabs[this.product.tab].buy(this.product, buyParams.videoBonus);
    this.product.left--;
    this.onBuy(buyParams.videoBonus);

    if (!this.isFree() && !this.product.videoProduct) {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.BUY_UNITS_SHOP_ITEMS);
    }

    if (buyParams.afterBuy) {
        buyParams.afterBuy();
    }
};
