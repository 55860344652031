/**
 * Created by r4zi4l on 12.02.2021
 */

var FinishQuestWindow = CleverappsWindow.extend({
    onWindowLoaded: function (quest, callback) {
        this.callback = callback || function () {};

        this._super({
            name: "FinishQuestWindow",
            content: this.createContent(quest),
            noBackground: true,
            closeButtonDelay: true,
            shareId: this.needsShare(quest) && "achievement",
            fireworks: cleverapps.config.name === "fairy" && {
                type: "fireworks",
                zOrder: 3
            },
            styles: {
                decors: cleverapps.styles.FinishQuestWindow.decors
            },
            openSound: bundles.finishquest.urls.quest_completed_effect
        });

        this.setLocalZOrder(UpMenuContainer.ZORDER - 1);
    },

    getPerson: function () {
        return {
            left: {
                role: "dwarf",
                emotion: "happy"
            },
            right: {
                role: "worker",
                emotion: "happy"
            }
        };
    },

    needsShare: function (quest) {
        if (!quest.unit || !quest.unit.code) {
            return false;
        }

        var families = ["hero", "resource"];
        var family = Families[quest.unit.code];
        return families.indexOf(family.type) !== -1 && family.units.length - 1 === quest.unit.stage;
    },

    createContent: function (quest) {
        var styles = cleverapps.styles.FinishQuestWindow;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize(styles);

        var title = this.title = cleverapps.UI.generateOnlyText("FinishQuestWindow.title", cleverapps.styles.FONTS.FINISHQUESTWINDOW_TITLE_TEXT);
        title.setDimensions(styles.title.width, 0);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        title.setPositionRound(styles.title);
        content.addChild(title);

        var icon = this.icon = new QuestIcon(quest, {
            images: QuestIcon.Images.sidebar
        });
        icon.setAnchorPoint(0.5, 0.5);
        icon.setPositionRound(styles.icon);
        icon.setScale(styles.icon.scale);
        content.addChild(icon);

        var checkmark = this.checkmark = new cleverapps.Spine(bundles.finishquest.jsons.quest_checkmark);
        checkmark.setAnchorPoint(0.5, 0.5);
        checkmark.setPositionRound(styles.checkmark);
        content.addChild(checkmark);

        var rewardsTitle = this.createRewardTitle();
        var rewardsList = this.rewardsList = new RewardsListComponent(quest.config.prize, {
            toMainWorld: quest.config.toMainWorld,
            font: cleverapps.styles.FONTS.FINISHQUESTWINDOW_REWARDS_TEXT,
            columns: 6,
            noPrefix: true,
            textDirection: cleverapps.UI.HORIZONTAL,
            iconWrap: styles.rewards.iconWrap,
            event: cleverapps.EVENTS.EARN.OTHER
        });

        var rewards = this.rewards = new cleverapps.Layout([rewardsTitle, rewardsList], {
            direction: styles.rewards.direction,
            margin: styles.rewards.margin,
            padding: styles.rewards.padding
        });
        rewards.setAnchorPoint(0.5, 0.5);
        rewards.setPositionRound(styles.rewards);
        content.addChild(rewards);

        if (styles.rewardsBackground) {
            var rewardsBackground = this.rewardsBackground = cleverapps.UI.createScale9Sprite(bundles.finishquest.frames.rewards_background, cleverapps.UI.Scale9Rect.TwoPixelXY);
            rewardsBackground.setContentSize(rewards.width, rewardsBackground.height);
            rewardsBackground.setPositionRound(styles.rewardsBackground);
            rewardsBackground.setLocalZOrder(-1);
            content.addChild(rewardsBackground);
        }

        return content;
    },

    createRewardTitle: function () {
        var text = cleverapps.UI.generateOnlyText("RewardsListComponent.rewardTitle", cleverapps.styles.FONTS.FINISHQUESTWINDOW_PRIZE_TEXT);

        if (cleverapps.config.ui === "riddles") {
            return text;
        }

        var leftLine = new cc.Sprite(bundles.finishquest.frames.quest_sideline);
        var rightLine = new cc.Sprite(bundles.finishquest.frames.quest_sideline);
        rightLine.setScaleX(-1);
        return new cleverapps.Layout([leftLine, text, rightLine], {
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    onShow: function () {
        var styles = cleverapps.styles.FinishQuestWindow;

        if (this.fireworks) {
            this.fireworks.start(2.6);
        }

        this._super();

        this.title.setScale(0);
        this.title.runAction(new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut()));

        var baseIconScale = this.icon.scale;
        this.icon.setScale(0);
        this.icon.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.ScaleTo(0.5, baseIconScale).easing(cc.easeBackOut()),
            new cc.CallFunc(function () {
                this.checkmark.setAnimationAndIdleAfter("open", "idle");
            }.bind(this))
        ));

        this.rewards.setCascadeOpacityEnabledRecursively(true);
        this.rewards.setOpacity(0);
        this.rewards.runAction(new cc.Sequence(
            new cc.DelayTime(0.45),
            new cc.FadeIn(0.2)
        ));

        if (this.rewardsBackground) {
            var baseSize = this.rewardsBackground.getContentSize();

            this.rewardsBackground.setVisible(false);
            this.rewardsBackground.setOpacity(0);
            this.rewardsBackground.setContentSize(styles.rewardsBackground.minWidth, this.rewardsBackground.height);
            this.rewardsBackground.runAction(new cc.Sequence(
                new cc.DelayTime(styles.rewardsBackground.delay || 0),
                new cc.Show(),
                new cc.Spawn(
                    new Scale9SpriteResizeTo(0.5, baseSize),
                    new cc.FadeIn(0.5)
                )
            ));
        }
    },

    beforeCloseAnimation: function (callback) {
        this.callback();
        this.rewardsList.receiveRewards();
        this.rewardsList.receiveRewardsAnimation({
            callback: callback
        });
    },

    listBundles: function (quest) {
        return ["finishquest"].concat(StartQuestWindow.ListLazyBundles(quest));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FINISHQUESTWINDOW_TITLE_TEXT: {
        size: 110,
        color: new cc.Color(255, 222, 0, 255),
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    FINISHQUESTWINDOW_PRIZE_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    FINISHQUESTWINDOW_REWARDS_TEXT: {
        name: "big_digits",
        size: 70,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.FinishQuestWindow = {
    width: 1000,
    height: 1000,

    title: {
        x: { align: "center" },
        y: { align: "center", dy: 350 },
        width: 1200
    },

    icon: {
        x: { align: "center" },
        y: { align: "center", dy: 35 },
        scale: 1.2
    },

    checkmark: {
        x: { align: "center", dx: 125 },
        y: { align: "center", dy: 160 }
    },

    rewardTitle: {
        x: { align: "center" },
        y: { align: "center", dy: -280 }
    },

    rewards: {
        direction: cleverapps.UI.VERTICAL,
        margin: 30,
        x: { align: "center" },
        y: { align: "center", dy: -330 },
        iconWrap: {
            height: 100
        }
    }
};
