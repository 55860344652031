/**
 * Created by spepa on 03.03.2023
 */

cleverapps.SkinManager.SKINS.easter_expedition = {
    bundle: "expedition_easter",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {
        unitIcon: function (unit) {
            return bundles.unit_icons_easter.frames["small_icon_family_" + unit.code];
        },

        workerCutTime: function () {
            return 500;
        },

        workerMiningAnimationStyles: function () {
            return cleverapps.styles.easter.miningAnimation;
        },

        workerSound: function () {
            return bundles.merge_easter.urls.worker_sound;
        },

        gameAudio: function () {
            return bundles.expedition_easter.urls.easter_music;
        },

        gameSceneBg: function () {
            return {
                movableAnimation: bundles.expedition_easter.jsons.easter_bg,
                parallax: {
                    hideHorizon: true
                }
            };
        },

        outOfBoundaryScale: function () {
            return 0;
        },

        fogBlocker: function () {
            return cleverapps.styles.easter.fogBlockerLock;
        },

        worker_json: function () {
            return bundles.merge_easter.jsons.worker_json;
        },

        whirlwind: bundles.fog_animations.jsons.easter_whirlwind,

        expedition_buildpass_icon_json: bundles.sidebar.jsons.easter_expedition_buildpass_icon_json,

        fog_disappear_effect: function () {
            return bundles.fog_easter.urls.open_effect;
        }
    })
};
