/**
 * Created by Denis Kuzin on 16 february 2022
 */

var ClansTopRow = ClansRow.extend({
    ctor: function (params) {
        this._super(params, {
            badges: true
        });

        cleverapps.UI.onClick(this, function () {
            new ClanInformationWindow(params.clan);
        }, {
            interactiveScale: false
        });
    },

    generateAmount: function (frame, amount, width) {
        var styles = cleverapps.styles.ClansTopRow;

        var icon = new cc.Sprite(bundles.tablerow_icons.frames[frame]);
        icon.setScale(0.9);

        var amountText = cleverapps.UI.generateImageText(amount, cleverapps.styles.FONTS.CLANS_ROW_DATA_TEXT);
        amountText.setAnchorPoint(0, 0.5);
        amountText.fitTo(width);

        var amountWrap = new cc.Node();
        amountWrap.setAnchorPoint(0.5, 0.5);
        amountWrap.setContentSize2(width, amountText.height);
        amountWrap.addChild(amountText);
        amountText.setPositionRound(0, amountText.height / 2);

        return new cleverapps.Layout([icon, amountWrap], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.iconMargin
        });
    },

    createData: function (params) {
        var styles = cleverapps.styles.ClansTopRow.data;

        var items = [];
        items.push(this.generateAmount("alliances_people", params.clan.membersCount, styles.text.membersWidth));
        items.push(this.generateAmount("alliances_crown_gold", params.clan.rating, styles.text.ratingWidth));

        var content = new cleverapps.Layout(items, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });
        content.setPositionRound(content.width / 2, content.height / 2);

        var dataBg = new cc.Scale9Sprite(this.player ? bundles.clan_table.frames.clan_amount_bg_you_png : bundles.clan_table.frames.clan_amount_bg_png);
        dataBg.setContentSize2(content.width, content.height);
        dataBg.addChild(content);

        var wrap = new cc.Node();
        wrap.setAnchorPoint(0.5, 0.5);
        wrap.setContentSize(dataBg.width + styles.offsetRight, dataBg.height);
        wrap.addChild(dataBg);
        dataBg.setPositionRound(wrap.width / 2 - styles.offsetRight / 2, wrap.height / 2);

        return wrap;
    }
});

cleverapps.styles.ClansTopRow = {
    data: {
        iconMargin: 4,
        margin: 10,
        offsetRight: 0,

        padding: {
            y: 5,
            x: 15
        },

        text: {
            membersWidth: 65,
            ratingWidth: 120
        }
    }
};