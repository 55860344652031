/**
 * Created by spepa on 07.11.2022
 */

var LandmarkSpot = function (unit) {
    UnitComponent.call(this, unit);
};

LandmarkSpot.prototype = Object.create(UnitComponent.prototype);
LandmarkSpot.prototype.constructor = LandmarkSpot;

LandmarkSpot.prototype.onRemove = function () {
    if (this.view) {
        this.view.onRemove();
    }
};

LandmarkSpot.prototype.onCreate = function () {
    if (this.view) {
        this.view.onCreate();
    }
};

LandmarkSpot.prototype.handleClick = function () {
    if (Game.currentGame.landmarks.listWindowLandmarks().length === 0) {
        cleverapps.centerHint.createTextHint("MissionUnavailable");
        return false;
    }

    cleverapps.focusManager.display({
        focus: "landMarkWindow",
        actions: Game.currentGame.landmarks.replaceActions(this.unit)
    });
    return true;
};