/**
 * Created by andrey on 09.02.2021.
 */

var Map2dDecoratorView = cc.Node.extend({
    ctor: function (decorator) {
        this._super();

        this.decorator = decorator;

        var image = this.image = Map2dDecoratorView.getDecoratorImage(decorator, this.decorator.animation);
        this.addChild(image);

        if (cleverapps.config.adminMode) {
            if (image instanceof cleverapps.Spine && image.hasAnimation("smile")) {
                image.setAnimation(0, "smile", true);
            }
        }

        this.setContentSize2(image);
        image.setPositionRound({ align: "center" }, { align: "center" });

        var dx = (decorator.dx || 0) * resolutionScale;
        var dy = (decorator.dy || 0) * resolutionScale;

        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound({ align: "center", dx: dx }, { align: "center", dy: dy });

        this.setRotation(decorator.rotation || 0);
        this.setScale(decorator.scaleX || 1, decorator.scaleY || 1);

        this.decorator.isOnScreen = this.createListener(this.isOnScreen.bind(this));
        this.decorator.onSmile = this.createListener(this.smile.bind(this));
        this.decorator.getView = this.createListener(function () {
            return this;
        }.bind(this));

        this.decorator.onAnimateHover = this.createListener(this.animateHover.bind(this));
        this.decorator.onStopAnimateHover = this.createListener(this.stopAnimateHover.bind(this));
        this.decorator.onSetChoice = this.createListener(this.setChoice.bind(this));

        var choice = this.decorator.getChoice();

        if (choice !== undefined) {
            this.setChoice(choice, true);
        }
    },

    isOnScreen: function () {
        return this.image.isVisible() && cleverapps.UI.isNodeOnScene(this.image, 2, 2);
    },

    smile: function () {
        this.image.setCompleteListener(function () {
            this.image.setCompleteListener();
            this.image.setAnimationAndIdleAfter("smile", "animation");

            if (!Map2dDecoratorView.smileSoundTimeouts[this.decorator.code] || Date.now() > Map2dDecoratorView.smileSoundTimeouts[this.decorator.code] + Map2dDecoratorView.SMILE_SOUND_THROTTLE) {
                Map2dDecoratorView.smileSoundTimeouts[this.decorator.code] = Date.now();
                cleverapps.audio.playSound(Map2dDecoratorView.getEffect(this.decorator), {
                    throttle: Map2dDecoratorView.SMILE_SOUND_THROTTLE
                });
            }
        }.bind(this));
    },

    animateHover: function () {
        if (this.image instanceof cleverapps.Spine) {
            this.hoverAnimation = this.image.spine.runAction(
                AnimationsLibrary.blinkShader(this.image.spine, {
                    brightness: 0.3
                })
            );
        }
    },

    stopAnimateHover: function () {
        if (this.hoverAnimation) {
            this.image.spine.stopAction(this.hoverAnimation);
            delete this.hoverAnimation;
        }
    },

    setChoice: function (choice, silent) {
        if (this.image instanceof cleverapps.Spine) {
            if (choice === undefined) {
                this.image.setAnimation(0, "animation");
                return;
            }

            if (silent) {
                this.image.setAnimation(0, "choice" + choice);
            } else {
                this.image.setAnimationAndIdleAfter("switch" + choice, "choice" + choice);
            }
        }
    }
});

Map2dDecoratorView.processDecoratorUrl = function (decorator) {
    var index = decorator.code && decorator.code.indexOf("#");
    if (index > -1) {
        return {
            bundle: decorator.code.slice(0, index),
            url: decorator.code.slice(index + 1)
        };
    }

    return {
        bundle: "decorator_" + decorator.code,
        url: "decorator_" + decorator.code
    };
};

Map2dDecoratorView.getFrame = function (decorator) {
    var url = Map2dDecoratorView.processDecoratorUrl(decorator);
    return bundles[url.bundle] && bundles[url.bundle].frames[url.url];
};

Map2dDecoratorView.getJson = function (decorator) {
    var url = Map2dDecoratorView.processDecoratorUrl(decorator);
    return bundles[url.bundle] && bundles[url.bundle].jsons[url.url];
};

Map2dDecoratorView.getEffect = function (decorator) {
    var url = Map2dDecoratorView.processDecoratorUrl(decorator);
    return bundles[url.bundle] && bundles[url.bundle].urls[url.url + "_effect"];
};

Map2dDecoratorView.DecoratorImageExists = function (decorator) {
    return Map2dDecoratorView.getJson(decorator) || Map2dDecoratorView.getFrame(decorator);
};

Map2dDecoratorView.getDecoratorImage = function (decorator, choice) {
    var image = Map2dDecoratorView.getJson(decorator);

    if (image) {
        var animation = new cleverapps.Spine(image);
        animation.setAnimation(0, choice || "animation", true);
        return animation;
    }

    image = Map2dDecoratorView.getFrame(decorator);

    if (image) {
        return new cc.Sprite(image);
    }

    throw "Not found resources for decorator " + decorator.code;
};

Map2dDecoratorView.smileSoundTimeouts = {};
Map2dDecoratorView.SMILE_SOUND_THROTTLE = cleverapps.parseInterval("5 seconds");
