/**
 * Created by andrey on 24.12.2020.
 */

var EditorMap2dBase = function (layer, options) {
    Map2d.call(this, layer, options);
    this.fogs = new EditorFogs();
};

EditorMap2dBase.prototype = Object.create(Map2d.prototype);
EditorMap2dBase.prototype.constructor = EditorMap2dBase;

EditorMap2dBase.prototype.containsCell = function (x, y) {
    if (typeof x === "object") {
        y = x.y;
        x = x.x;
    }
    return x >= 0 && x < this.getWidth() && y >= 0 && y < this.getHeight();
};