/**
 * Created by andrey on 04.02.2021.
 */

var RegionUnitIcon = cc.Scale9Sprite.extend({
    ctor: function (unit) {
        this.unit = unit;

        this._super(bundles.merge.frames.icon_background);

        this.setContentSize2(cleverapps.styles.RegionUnitIcon);

        this.createTitle();

        cleverapps.UI.applyHover(this);
        cleverapps.UI.onClick(this, unit.onClick.bind(unit));

        this.update();

        unit.onUpdate = this.createListener(this.update.bind(this));
    },

    update: function () {
        var image = this.unit.isSelected() ? bundles.merge.frames.icon_background_light : bundles.merge.frames.icon_background;
        this.setSpriteFrame(new cc.Sprite(image).getSpriteFrame());

        if (this.unit.isSelected()) {
            this.showMenu();
        } else {
            this.hideMenu();
        }

        this.updateOption();
    },

    showMenu: function () {
        var styles = cleverapps.styles.RegionUnitIcon.menu;

        var buttons = Object.values(RegionUnit.MENU).filter(function (data) {
            if (data.moneyFog && !this.unit.isMoneyFog()) {
                return false;
            }

            return !data.type || data.type === this.unit.type;
        }, this).map(function (data) {
            return new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.editor,
                text: data.icon ? "%%" : data.text,
                icons: {
                    "%%": data.icon
                },
                width: styles.width,
                height: styles.height,
                onClicked: data.action.bind(this)
            });
        }.bind(this));

        this.menu = new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        this.addChild(this.menu);

        this.menu.setPositionRound(styles);
    },

    hideMenu: function () {
        if (this.menu) {
            this.menu.removeFromParent();
            delete this.menu;
        }
    },

    createTitle: function () {
        if (this.unit.isCreateUnit()) {
            var icon = new cc.Sprite(bundles.admin.frames.plus_button);
            icon.setPositionRound(this.width / 2, this.height / 2);
            this.addChild(icon);
            return;
        }

        var title = this.title = cleverapps.UI.generateTTFText("fog\n" + this.unit.code.replace("fog", ""), cleverapps.styles.FONTS.LIGHT_TITLE_TEXT);
        title.setPositionRound(this.width / 2, this.height / 2);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        title.setDimensions(this.width * 0.9, 0);
        title.fitTo(undefined, this.height * 0.9);
        this.addChild(title);
    },

    updateOption: function () {
        if (this.unit.type === RegionUnit.TYPE_REGION) {
            return;
        }

        if (this.optionsTitle) {
            this.optionsTitle.removeFromParent();
            delete this.optionsTitle;
        }

        if (!this.unit.currentOption) {
            return;
        }

        this.optionsTitle = cleverapps.UI.generateTTFText(this.unit.currentOption.text, cleverapps.styles.FONTS.LIGHT_TITLE_TEXT);
        this.optionsTitle.setPositionRound(cleverapps.styles.RegionUnitIcon.headTitle);
        this.addChild(this.optionsTitle);
    }
});

cleverapps.styles.RegionUnitIcon = {
    width: 160,
    height: 160,

    menu: {
        margin: 10,
        height: 45,
        width: 45,
        x: { align: "center" },
        y: { align: "bottom", dy: -56 }
    },

    headTitle: {
        x: { align: "right", dx: -10 },
        y: { align: "top", dy: -10 }
    }
};

RegionUnit.MENU = {
    renameRegion: {
        type: RegionUnit.TYPE_REGION,
        icon: bundles.admin.frames.edit_icon,
        action: function () {
            Editor.currentEditor.regions.renameRegion(this.unit);
        }
    },

    deleteRegion: {
        type: RegionUnit.TYPE_REGION,
        icon: bundles.admin.frames.trash_icon,
        action: function () {
            Editor.currentEditor.regions.deleteRegion(this.unit);
        }
    }
};
