/**
 * Created by vladislav on 12/19/2023
 */

var FeedableView = function (feedable, unitView) {
    this.feedable = feedable;
    this.unitView = unitView;
};

FeedableView.prototype.clearAnimations = function () {
    if (this.unitView.sprite instanceof cleverapps.Spine) {
        this.unitView.sprite.setCompleteListener();
    }
};

FeedableView.prototype.feed = function () {
    if (this.unitView.sprite instanceof cleverapps.Spine && this.unitView.sprite.hasAnimation("feed")) {
        this.unitView.sprite.setAnimation(0, "feed", false);
        this.unitView.sprite.setCompleteListener(function () {
            UnitView.applyDefaultAnimation(this.unitView.sprite, this.feedable.unit);
        }.bind(this));
    } else {
        this.unitView.squeeze();
    }
};