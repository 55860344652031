/**
 * Created by andrey on 04.03.2021.
 */

cc.isoRectGetCenter = function (rect) {
    return cc.p( 
        Math.round(rect.x + rect.width / 2 + rect.height / 2),
        Math.round(rect.y + rect.width / 2 - rect.height / 2)
    );
};

cc.isoRectContainsPoint = function (rect, x, y) {
    var dx = x - rect.x;
    var dy = y - rect.y;

    var tx = (dx + dy) / 2;
    var ty = (dx - dy) / 2;

    return tx >= 0 && tx <= rect.width && ty >= 0 && ty <= rect.height;
};

cc.iterateIsoRect = function (rect, iterator) {
    for (var dy = rect.height; dy >= 0; dy -= 0.5) {
        var startX = rect.x + Math.floor(dy);
        var startY = rect.y - Math.ceil(dy);

        for (var dx = rect.width; dx >= 0; dx--) {
            iterator(startX + dx, startY + dx);
        }
    }
};