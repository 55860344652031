/**
 * Created by evgenijsenkevic on 28.08.2024
 * */

var AdminLocationView = cc.Node.extend({
    ctor: function (adminLocation) {
        this._super();
        this.adminLocation = adminLocation;

        this.setAnchorPoint(0.5, 0.5);
        var styles = this.styles = cleverapps.styles.AdminLocationView;
        this.setContentSize(styles.width, styles.height);

        this.createBackground();
        this.createContent();

        adminLocation.on("select", this.select.bind(this));
        adminLocation.on("unselect", this.unselect.bind(this));

        cleverapps.UI.onClick(this, this.onClick.bind(this));
    },

    onClick: function () {
        cleverapps.focusManager.display({
            focus: "AdminLocationClick",
            action: function (f) {
                this.adminLocation.onClick(f);
            }.bind(this)
        });
    },

    select: function () {
        this.name.setFont(cleverapps.styles.FONTS.ADMIN_LEVEL_TEXT_SELECTED);
        this.bg.setSpriteFrame(new cc.Sprite(bundles.admin.frames.lvl_selected_bg).getSpriteFrame());
    },

    unselect: function () {
        this.name.setFont(cleverapps.styles.FONTS.ADMIN_LEVEL_TEXT);
        this.bg.setSpriteFrame(new cc.Sprite(bundles.admin.frames.lvl_bg).getSpriteFrame());
    },

    createBackground: function () {
        var bg = this.bg = cleverapps.UI.createScale9Sprite(bundles.admin.frames.lvl_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(this.getContentSize());
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(bg);
    },

    createContent: function () {
        var name = this.name = cleverapps.UI.generateOnlyText(this.adminLocation.getLocationName(), cleverapps.styles.FONTS.ADMIN_LEVEL_TEXT);

        name.setAnchorPoint(0.5, 0.5);
        name.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(name);
    }
});

cleverapps.styles.AdminLocationView = {
    height: 50,
    width: 200,

    preview: {
        x: { align: "center" },
        y: { align: "top", dy: -5 }
    },

    text: {
        x: { align: "center" },
        y: { align: "bottom" }
    }
};