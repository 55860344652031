/**
 * Created by vladislav on 1/18/2024
 */

var PlatformLogic = function () {
    if (connector.info.isNative && !connector.platform.oneOf(connector.WECHAT)) {
        var params = new URLSearchParams(window.location.search);
        if (params.has("source")) {
            cc.sys.isNativeFlight = true;
        }
    }

    connector.payments.on("stream:logs", this.logEvent.bind(this));
    connector.social.on("stream:logs", this.logEvent.bind(this));
    connector.ads.on("stream:logs", this.logEvent.bind(this));
    connector.platform.on("stream:logs", this.logEvent.bind(this));

    connector.payments.on("stream:error", this.throwError.bind(this));
    connector.social.on("stream:error", this.throwError.bind(this));
    connector.ads.on("stream:error", this.throwError.bind(this));
    connector.platform.on("stream:error", this.throwError.bind(this));

    connector.payments.on("stream:debug", this.showDebugNotification.bind(this));
    connector.social.on("stream:debug", this.showDebugNotification.bind(this));
    connector.ads.on("stream:debug", this.showDebugNotification.bind(this));
    connector.platform.on("stream:debug", this.showDebugNotification.bind(this));

    connector.payments.on("stream:console", this.consoleStream.bind(this));
    connector.social.on("stream:console", this.consoleStream.bind(this));
    connector.ads.on("stream:console", this.consoleStream.bind(this));
    connector.platform.on("stream:console", this.consoleStream.bind(this));
};

PlatformLogic.prototype.logEvent = function (name, params) {
    cleverapps.eventLogger.logEvent(name, params);
};

PlatformLogic.prototype.throwError = function (messages, error) {
    cleverapps.throwAsync(messages, error);
};

PlatformLogic.prototype.showDebugNotification = function (message) {
    if (cleverapps.config.debugMode) {
        cleverapps.notification.create(message);
    }
};

PlatformLogic.prototype.consoleStream = function (group) {
    var messages = [];
    for (var i = 0; i < arguments.length; i++) {
        messages.push(arguments[i]);
    }
    messages.shift();

    cleverapps.ConsoleStream.sendMessages(group, cleverapps.ConsoleStream.serialize(messages));
};

PlatformLogic.prototype.onInitialize = function () {

};

PlatformLogic.prototype.onStart = function () {

};

PlatformLogic.prototype.initGameEvents = function () {
    var inactive = false;

    cc.eventManager.addCustomListener(cc.game.EVENT_HIDE, function () {
        inactive = true;
    });
    cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, function () {
        inactive = false;
    });

    cc.game.canvas.addEventListener("webglcontextlost", function () {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.WEBGL.CONTEXT_LOST);
        cleverapps.playSession.inc(cleverapps.EVENTS.WEBGL.CONTEXT_LOST);
        if (cleverapps.paymentsHistory.isPayer()) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.WEBGL.CONTEXT_LOST_PAYER);
            cleverapps.playSession.inc(cleverapps.EVENTS.WEBGL.CONTEXT_LOST_PAYER);
        }

        if (inactive) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.WEBGL.CONTEXT_LOST_INACTIVE);
            cleverapps.playSession.inc(cleverapps.EVENTS.WEBGL.CONTEXT_LOST_INACTIVE);
            if (cleverapps.paymentsHistory.isPayer()) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.WEBGL.CONTEXT_LOST_PAYER_INACTIVE);
                cleverapps.playSession.inc(cleverapps.EVENTS.WEBGL.CONTEXT_LOST_PAYER_INACTIVE);
            }
        }

        setTimeout(function () {
            cleverapps.dataLoader.onSaved(function () {
                window.location.reload();
            });
        }, 30);
    });

    // resume context after clicks (after autoplay policy pause; after alerts); click event fires before context can be resumed thus we need to delay it
    cc.eventManager.addListener(cc.EventListener.create({
        event: cc.EventListener.TOUCH_ONE_BY_ONE,
        onTouchBegan: cleverapps.accumulate(100, function () {
            cleverapps.Audio.resumeAudioContext();
        })
    }), -1);
};

PlatformLogic.isTmpId = function (id) {
    return id && id.startsWith("__");
};

PlatformLogic.isDeviceId = function (id) {
    var deviceId = connector.info.deviceID;

    return deviceId && id === deviceId;
};

PlatformLogic.create = function () {
    var platforms = {
        wechat: WechatLogic,
        ios: AppleLogic,
        macos: AppleLogic,
        instant: InstantLogic,
        msstart: MSStartLogic,
        ok: OKLogic,
        vk: VKLogic,
        yandex: YandexLogic,
        microsoft: MicrosoftLogic,
        kongregate: KongregateLogic,
        cleverapps: CleverAppsLogic,
        crazy: CrazyLogic,
        gdcom: GDComLogic,
        coolmath: CoolMathGamesLogic,
        mbga: MobageLogic,
        sp_mbga: SPMobageLogic,
        youtube: YoutubeLogic,
        gamesnack: GameSnackLogic,
        fotostrana: FotostranaLogic,
        samsung: SamsungLogic,
        ton: TonLogic,
        wortal: WortalLogic
    };

    var Class = platforms[connector.info.source] || PlatformLogic;

    return new Class();
};

cc.sys.LANGUAGE_LATVIAN = "lv";
cc.sys.LANGUAGE_TURKISH = "tr";
cc.sys.LANGUAGE_DUTCH = "nl";
