/**
 * Created by r4zi4l on 25.02.2021
 */

var MergeAdviceSpeedupLogic = function (options) {
    MergeAdviceBaseLogic.call(this, options);
};

MergeAdviceSpeedupLogic.prototype = Object.create(MergeAdviceBaseLogic.prototype);
MergeAdviceSpeedupLogic.prototype.constructor = MergeAdviceSpeedupLogic;

MergeAdviceSpeedupLogic.prototype.findMove = function () {
    var workers = Map2d.currentMap.workers.listBusy();

    if (this.targets) {
        workers = workers.filter(function (worker) {
            return this.targets.some(Unit.Equals.bind(Unit, worker.unit));
        }, this);
    }

    workers.sort(function (a, b) {
        return a.getTimeLeft() - b.getTimeLeft();
    });

    for (var i = 0; i < workers.length; ++i) {
        var worker = workers[i];

        if (!Buildable.IsBuilt(worker.unit)) {
            return {
                cells: [cc.p(worker.unit.x, worker.unit.y)],
                unitView: ["speedup"]
            };
        }

        var mineable = worker.unit.findComponent(Mineable);
        if (mineable && cleverapps.parseInterval(mineable.getTask().time) !== 0) {
            return {
                cells: [cc.p(worker.unit.x, worker.unit.y)],
                unitView: ["speedup"]
            };
        }
    }
};