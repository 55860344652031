/**
 * Created by Vladislav on 24.09.2024.
 */

var GameLevelMenuBarItem = {
    name: "GameLevelItem",
    targets: ["default", "exp"],
    viewClass: MenuBarGameLevelView,
    modelClass: GameLevelMenuBarItemModel,
    availableOnScenes: [cleverapps.Environment.SCENE_MAIN]
};