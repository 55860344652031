/**
 * Created by andrey on 01.03.2021.
 */

var FamilySyncer = function (code, slot) {
    this.code = code;
    this.slot = slot;
    this.data = {};
    this.syncerCode = "units_" + code + (slot ? "_" + slot : "");

    CustomSyncers.extractors[this.syncerCode] = this.getInfo.bind(this);
};

FamilySyncer.prototype.addUpdateTask = function () {
    cleverapps.synchronizer.addUpdateTask(this.syncerCode);
};

FamilySyncer.prototype.remove = function (x, y) {
    var key = this.getKey(x, y);
    if (this.data[key]) {
        delete this.data[key];
        this.addUpdateTask();
    }
};

FamilySyncer.prototype.save = function (x, y, data, fromLoad) {
    var key = this.getKey(x, y);
    this.data[key] = this.encode(data, x, y);

    if (fromLoad !== true) {
        this.addUpdateTask();
    }
};

FamilySyncer.prototype.reset = function (fromLoad) {
    this.data = {};

    if (fromLoad !== true) {
        this.addUpdateTask();
    }
};

FamilySyncer.prototype.getKey = function (x, y) {
    return x + "_" + y;
};

FamilySyncer.prototype.parseKey = function (key) {
    var parts = key.split("_");
    return {
        x: parseInt(parts[0]),
        y: parseInt(parts[1])
    };
};

FamilySyncer.prototype.encode = function (data, x, y) {
    var info = cleverapps.clone(data, true);
    delete info.code;

    info.x = x;
    info.y = y;
    info._compact = info._compact || UnitPacker.compactUnit(info, this.code);

    return info;
};

FamilySyncer.prototype.decode = function (data) {
    var info = cleverapps.clone(data, true);
    info.code = this.code;
    if (!info._unknown) {
        delete info._compact;
    }
    return info;
};

FamilySyncer.prototype.getInfo = function () {
    return UnitPacker.compactUnits(Object.values(this.data), this.code);
};

FamilySyncer.prototype.updateInfo = function (data) {
    this.data = {};
    data = data || {};

    if (typeof data === "object") {
        this.data = data;
    } else if (typeof data === "string" && data.charAt(0) === "{") {
        this.data = JSON.parse(data);
    } else {
        UnitPacker.expandUnits(data, this.code).forEach(function (unit) {
            this.save(unit.x, unit.y, unit, true);
        }, this);
    }
};

FamilySyncer.prototype.listUnits = function () {
    return Object.keys(this.data).map(function (key) {
        var info = this.parseKey(key);
        info.data = this.decode(this.data[key]);
        return info;
    }, this);
};

if (typeof cc === "undefined") {
    module.exports = FamilySyncer;
}
