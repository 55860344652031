/**
 * Created by Andrey Popov on 30.10.2024
 */

cc.UnhighlightUnit = cc.CallFunc.extend({
    ctor: function () {
        this._super(function (target) {
            if (target.highlighter) {
                target.highlighter.unhighlight();
                target.highlighter = undefined;
            }
        });
    }
});