/**
 * Created by andrey on 22.08.17.
 */

var LevelStartWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.options = options;
        this.level = options.level;

        this.isHardLevel = this.level.isHard() && !this.level.isDailyLevel();

        if (options.boostersBefore) {
            this.boostersBefore = new WindowBoostersBefore(this.level);
            cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");
        }

        var styles = cleverapps.styles.LevelStartWindow;
        var layoutStyles = this.isLanternStartWindow() ? styles.lanternStart : styles;

        var content = new cleverapps.Layout(this.listContent(), {
            direction: cleverapps.UI.VERTICAL,
            reversed: true,
            margin: layoutStyles.margin,
            padding: layoutStyles.padding
        });

        this._super({
            title: this.getTitle(),
            name: "levelstartwindow",
            content: content,
            button: this.getButton(),
            level: this.level,
            closeButton: !cleverapps.environment.isGameScene(),
            showSound: this.getShowSound()
        });

        if (styles.hardLevel && this.isHardLevel) {
            var hardLevelIcon = new HardLevelWindowIcon();
            hardLevelIcon.setPositionRound(styles.hardLevel);

            if (cleverapps.config.ui === "heroes") {
                hardLevelIcon.pulseAnimation();
            }

            this.window.addChild(hardLevelIcon);
        }

        var badge = this.createCoinBadge({
            danger: this.level.isDanger(),
            lantern: this.level.isBonus() && this.isLanternStartWindow()
        });

        if (badge) {
            this.window.addChild(badge);
        }

        this.setCoinsRain();
    },

    getPerson: function () {
        return "hero";
    },

    getShowSound: function () {
        if (this.level.isBonus()) {
            return bundles.main.urls.levelstartwindow_open_bonus_effect;
        }
        if (this.level.isHard()) {
            return bundles.main.urls.levelstartwindow_open_hard_effect;
        }
    },

    onPopUpAnimationFinished: function () {
        if (this.boostersBefore) {
            this.boostersBefore.showTutorialSteps();
        }
    },

    onClose: function () {
        cleverapps.focusManager.hideControlsWhileFocused("MenuBarGoldItem");

        if (this.coinsRain) {
            this.coinsRain.removeFromParent();
            this.coinsRain = undefined;
        }
    },

    getTitle: function () {
        var levelNo = "";
        if (this.level.isRegular()) {
            if (cleverapps.meta.getType() === Meta.HOSE && !this.level.isCurrentLevel()) {
                levelNo = this.level.getHumanReadableNumber();
            } else {
                levelNo = cleverapps.user.getVirtualDisplayLevel();
            }
        }

        return {
            text: this.getTitleText(),
            font: cleverapps.styles.FONTS.WINDOW_TITLE_TEXT,
            toReplace: {
                levelNo: levelNo
            }
        };
    },

    getTitleText: function () {
        if (this.level.isDailyCup()) {
            return "Cups.daily.title";
        }
        if (this.isHardLevel) {
            return "message.hardLevel";
        }
        if (this.level.isTricky()) {
            return "message.trickyLevel";
        }
        if (this.level.isBonus()) {
            return "message.bonusLevel";
        }
        if (this.level.isDailyLevel()) {
            return "DailyLevelWindow.title";
        }
        if (cleverapps.user.isPassedAll() && cleverapps.meta.getType() === Meta.HOSE && this.level.isCurrentLevel()) {
            return "LevelStartWindow.ComingSoonTitle";
        }

        return "LevelStartWindow.title";
    },

    getButton: function () {
        var playButton = {
            button_png: bundles.buttons_main.frames.play_button_png,
            button_on_png: bundles.buttons_main.frames.play_button_on_png
        };

        if (this.isHardLevel) {
            playButton = {
                button_png: bundles.buttons.frames.red_button,
                button_on_png: bundles.buttons.frames.red_button_on
            };
        }

        if (this.level.isTricky() || this.level.isBonus()) {
            playButton = {
                button_png: bundles.buttons.frames.blue_button_png,
                button_on_png: bundles.buttons.frames.blue_button_png
            };
        }

        var startGame = cleverapps.once(function () {
            cleverapps.focusManager.distract({
                focus: "LevelStartFromLevelStartWindow",
                action: function (f) {
                    this.close();

                    var actionsBefore = [];
                    if (this.boostersBefore) {
                        actionsBefore = actionsBefore.concat(this.boostersBefore.listActionsBefore());
                    }
                    if (this.isLanternStartWindow()) {
                        actionsBefore = actionsBefore.concat(Lantern.ListActionsBefore(this.level));
                    }

                    this.level.play(f, {
                        actionsBefore: actionsBefore
                    });
                }.bind(this)
            });
        }.bind(this));

        var params = {
            width: cleverapps.styles.LevelStartWindow.Button.width,
            height: cleverapps.styles.LevelStartWindow.Button.height,
            type: playButton,
            onPressed: function () {
                if (cleverapps.lives && cleverapps.lives.isEmpty()) {
                    new LivesShopWindow();
                } else {
                    startGame();
                }
            }
        };

        params.text = Messages.get("Play").toUpperCase();
        return params;
    },

    listContent: function () {
        var styles = cleverapps.styles.LevelStartWindow;
        var data = [];

        if (this.boostersBefore) {
            var boostersBefore = new BoostersBeforeComponent(this.boostersBefore, this.isLanternStartWindow());
            data.push(boostersBefore);

            if (this.isLanternStartWindow()) {
                var lanternStart = new LanternStartComponent(this.level);
                data.push(lanternStart);
            }
        }

        if (this.options.danger) {
            data.push(new cc.Sprite(bundles.level_start_window.frames.start_bg));
        }

        if (cleverapps.config.name === "crocword" && this.level.isHard()) {
            data = data.concat(this.setCoinsView(bundles.level_start_window.jsons.hard_animation, styles.coinsBg));
        } else if (this.level.isBonus() && !this.isLanternStartWindow()) {
            data = data.concat(this.setCoinsView(bundles.level_start_window.jsons.bonus_animation, styles.coinsBg));
        }

        return data;
    },

    createCoinBadge: function (options) {
        if (!options.danger && !options.lantern) {
            return;
        }

        var styles = cleverapps.styles.LevelStartWindow.lanternStart.coinBadge;

        var coinBadge = new cc.Node();
        coinBadge.setPositionRound(styles);

        var labelBundle = bundles.level_start_window.frames.label || bundles.lantern_start.frames.lantern_bonus_label;

        var label = this.coinBadgeLabel = new cc.Sprite(labelBundle);
        label.setAnchorPoint(styles.label.anchor.x, styles.label.anchor.y);
        label.setPositionRound(styles.label);

        if (cleverapps.config.ui === "riddles") {
            var labelStyles = options.danger ? styles.label.danger : styles.label;

            var iconBundle = options.danger ? bundles.level_start_window.frames.gold_small_png : bundles.level_start_window.frames.reward_gold_png;

            var rewardIcon = new cc.Sprite(iconBundle);

            if (labelStyles.icon && labelStyles.icon.scale) {
                rewardIcon.setScale(labelStyles.icon.scale);
            }

            var offset = labelStyles.icon.offset;

            rewardIcon.setPosition(label.width / 2 + offset.x, label.height / 2 + offset.y);
            label.addChild(rewardIcon);

            if (labelStyles.text) {
                var rewardText = cleverapps.UI.generateImageText("+" + GameBase.DANGER_REWARD, cleverapps.styles.FONTS.LABEL_TEXT);
                rewardText.setRotation(13);

                offset = labelStyles.text.offset;
                rewardText.setPosition(label.width / 2 + offset.x, label.height / 2 + offset.y);

                label.addChild(rewardText);
            }

            var line = new cc.Sprite(bundles.level_start_window.frames.label_line);
            coinBadge.addChild(line, 1);
        }

        if (["riddles", "heroes"].includes(cleverapps.config.ui)) {
            label.setRotation(20);
            label.runAction(new cc.Sequence(
                new cc.RotateBy(0.8, -35),
                new cc.RotateBy(0.8, 25),
                new cc.RotateBy(0.8, -15),
                new cc.RotateBy(0.8, 5)
            )).setFinalize(function () {
                label.setRotation(0);
            });
        }

        coinBadge.addChild(label);

        return coinBadge;
    },

    setCoinsView: function (animationBundle, coinsBg) {
        var data = [];
        var coins;

        if (animationBundle) {
            coins = new cleverapps.Spine(animationBundle);
            coins.setAnimationAndIdleAfter("animation", "idle");
        } else {
            coins = new cc.Sprite(bundles.level_start_window.frames.pot_coins_png);
        }

        if (coinsBg) {
            var bg = new cc.Scale9Sprite(bundles.level_start_window.frames.coins_bg_png);
            bg.setContentSize2(coinsBg);
            bg.addChild(coins);
            coins.setPositionRound({ align: "center" }, { align: "center" });
            data.push(bg);
        } else {
            data.push(coins);
        }

        return data;
    },

    setCoinsRain: function () {
        if (!cleverapps.styles.LevelStartWindow.coinsRain || !this.level.isBonus()) {
            return;
        }

        var coins = this.coinsRain = new cleverapps.Spine(bundles.level_start_window.jsons.coinsRain);
        var styles = cleverapps.styles.LevelStartWindow;
        coins.setAnimationAndIdleAfter("animation", "idle");
        var scene = cleverapps.scenes.getRunningScene();
        scene.addChild(coins);
        coins.setPositionRound(styles.coinsRain);
        coins.setLocalZOrder(BaseWindow.WINDOWS_ZORDER - 1);
    },

    isLanternStartWindow: function () {
        return this.options.lantern;
    },

    completeAnimationOnResize: function () {
        if (this.coinBadgeLabel) {
            this.coinBadgeLabel.stopAllActions();
        }
    }
});

LevelStartWindow.isAvailable = function (level) {
    if (cleverapps.config.name === "crocword" && (level.isHard() || level.isBonus())) {
        return true;
    }

    var boostersBefore = cleverapps.boosters.listBoostersBefore();

    if (boostersBefore.length === 0) {
        return false;
    }

    if (level.episodeNo === 0 && level.levelNo === 0) {
        return false;
    }

    if (cleverapps.config.subtype === "stacks") {
        return !cleverapps.GameSaver.load(cleverapps.GameSaver.getStoreSlot(level.episodeNo, level.levelNo)) && WindowBoostersBefore.isAvailable();
    }

    if (cleverapps.config.type === "solitaire") {
        return WindowBoostersBefore.isAvailable();
    }

    return true;
};

LevelStartWindow.prototype.listBundles = function (options) {
    var bundles = ["level_start_window"];

    if (cleverapps.config.features.includes("boosters_before") && WindowBoostersBefore.isAvailable() && Lantern.IsActive(options.level)) {
        if (cleverapps.config.features.includes("lantern_start")) {
            bundles.push("lantern_start");
        } else {
            bundles.push("lantern_element");
        }
    }

    return bundles;
};

cleverapps.override(cleverapps.styles.FONTS, {
    LABEL_TEXT: {
        size: 35
    }
});

cleverapps.styles.LevelStartWindow = {
    Button: {
        width: 300,
        height: 100
    },

    margin: 40,

    lanternStart: {
        margin: 30,

        coinBadge: {
            label: {
                anchor: { x: 0.56, y: 0.83 },
                x: { align: "center", dx: -40 },
                y: { align: "center", dy: -122 },

                icon: {
                    offset: {
                        x: -5,
                        y: -10
                    },

                    scale: 0.7
                },

                danger: {
                    icon: {
                        offset: {
                            x: 2,
                            y: 11
                        }
                    },

                    text: {
                        offset: {
                            x: -20,
                            y: -40
                        }
                    }
                }
            },
            x: { align: "left", dx: 85 },
            y: { align: "top", dy: -53 }
        }
    }
};
