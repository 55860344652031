/**
 * Created by spepa on 13.06.2024
 */

var TradePicker = cc.Node.extend({
    ctor: function (tradeType, trade) {
        this._super();
        this.tradeType = tradeType;
        this.trade = trade;
        this.unitViews = [];

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.styles.TradeSelector);

        this.createBg();
        this.createDescription();
        this.createPicker();

        trade.on("tabChange", this.onTabChange.bind(this), this);
        trade.on("pickChange", this.updateSelected.bind(this), this);
    },

    createDescription: function () {
        var styles = cleverapps.styles.TradeSelector.description;
        var desc = this.description = new TradeUnitDescription(this.tradeType, this.trade);
        desc.setAnchorPoint(this.tradeType === UnitTrade.SOURCE ? 0 : 1, 0.5);
        var posX = this.tradeType === UnitTrade.SOURCE ? styles.offsetX : this.width - styles.offsetX;
        desc.setPositionRound(posX, this.height + styles.offsetY);
        this.addChild(desc);
    },

    createBg: function () {
        var bgImg = this.tradeType === UnitTrade.SOURCE ? bundles.tradewindow.frames.left_bg : bundles.tradewindow.frames.right_bg;
        var bg = cleverapps.UI.createScale9Sprite(bgImg);
        bg.setContentSize2(this.getContentSize());
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(bg);
    },

    createPicker: function () {
        var styles = cleverapps.styles.TradeSelector.picker;

        this.picker = cleverapps.UI.createScale9Sprite(bundles.tradewindow.frames.light_bg);
        this.picker.setContentSize(styles);
        this.picker.setPositionRound(styles);
        this.addChild(this.picker);

        var sourceTypes = this.trade.getSourceTypes();
        var targetTypes = this.trade.getTargetTypes(sourceTypes[0]);
        var tabsInfo = this.getTabsInfo(this.tradeType === UnitTrade.SOURCE ? sourceTypes : targetTypes);
        this.createTabs(tabsInfo);

        cleverapps.UI.onClick(this.picker, this.onGridClick.bind(this), { interactiveScale: false });
    },

    onGridClick: function (touch) {
        var target = this.unitViews.find(function (view) {
            return view.isDisplayed() && cc.rectContainsPoint(cc.rect(0, 0, view.width, view.height), view.convertTouchToNodeSpace(touch));
        });
        if (target) {
            this.trade.setSelected(this.tradeType, target.unitData);
        }
    },

    createTabs: function (tabsInfo) {
        var styles = cleverapps.styles.TradeSelector.tabs;
        this.tabs = new Tabs(tabsInfo, {
            width: styles.width,
            height: styles.height,
            direction: cleverapps.UI.HORIZONTAL,
            type: {
                off_png: bundles.tradewindow.frames.tab_off,
                on_png: bundles.tradewindow.frames.tab_on
            }
        });
        this.tabs.setAnchorPoint(this.tradeType === UnitTrade.SOURCE ? 0 : 1, 0.5);
        var posX = this.tradeType === UnitTrade.SOURCE ? styles.offsetX : this.picker.width - styles.offsetX;
        this.tabs.setPositionRound(posX, this.picker.height + styles.offsetY);
        this.picker.addChild(this.tabs);

        this.tabs.activateTab(0);
        this.tabs.onTabChanged = this.createListener(function (selectedTab) {
            this.trade.trigger("tabChange", selectedTab);
        }.bind(this));
    },

    getTabsInfo: function (unitTypes) {
        var tabs = {};
        unitTypes.forEach(function (unitType, index) {
            var units = this.trade.listTradeUnits(this.tradeType, unitType);
            tabs[index] = {
                icon: this.trade.getIconByType(unitType),
                generator: this.createTab.bind(this, units)
            };
        }.bind(this));
        return tabs;
    },

    createTab: function (units) {
        var content;
        if (units.length) {
            content = this.createGrid(units);
        } else {
            content = cleverapps.UI.generateOnlyText("TradeWindow.HintSelectNotAvailable", cleverapps.styles.FONTS.TRADE_WINDOW_SELECT_HINT);
            content.setDimensions(this.picker.width * 0.7, 0);
            content.setPositionRound(this.picker.width / 2, this.picker.height / 2);
        }

        this.picker.addChild(content);
        return content;
    },

    createGrid: function (units) {
        var styles = cleverapps.styles.TradeSelector.picker;
        var unitViews = units.map(function (unitData) {
            return this.createPickUnit(unitData);
        }.bind(this));

        var grid = new cleverapps.GridLayout(unitViews, {
            columns: 4,
            margin: styles.grid.margin
        });
        unitViews.forEach(function (unitView) {
            unitView.icon.replaceParentSamePlace(grid);
        });
        unitViews.forEach(function (unitView) {
            if (unitView.amount) {
                unitView.amount.replaceParentSamePlace(grid);
            }
        });

        var availableSize = cc.size(styles.width, styles.height - styles.scroll.paddingY);
        if (grid.height > availableSize.height) {
            grid = this.createScroll(grid, availableSize);
        }

        grid.setPositionRound(grid instanceof cleverapps.UI.ScrollView ? styles.scroll : styles.grid);
        grid.setAnchorPoint(0, 1);
        return grid;
    },

    createScroll: function (grid, size) {
        var scroll = this.scroll = new cleverapps.UI.ScrollView(grid, {
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL,
            scrollBarEnabled: true
        });
        scroll.setContentSize(size);
        scroll.scrollTo(cleverapps.UI.ScrollView.SCROLLS.UPPER_LEFT);
        return scroll;
    },

    updateSelected: function (type, selected) {
        if (this.tradeType === type) {
            var target;
            this.unitViews.forEach(function (view) {
                view.selectBg.setVisible(false);
                if (view.unitData === selected) {
                    view.selectBg.setVisible(true);
                    var sfx = this.tradeType === UnitTrade.SOURCE ? bundles.tradewindow.urls.select_source_effect : bundles.tradewindow.urls.select_target_effect;
                    cleverapps.audio.playSound(sfx);
                    target = view;
                }
            }.bind(this));

            if (this.scroll) {
                this.scroll.runAction(new cc.ScrollAction(target, {
                    duration: 0.4,
                    visibleBox: { top: 0.9, bottom: 0.9 }
                }).easing(cc.easeBackOut()));
            }
        }
    },

    createPickUnit: function (unit) {
        var styles = cleverapps.styles.TradeSelector.picker.unit;
        var bg = cleverapps.UI.createScale9Sprite(bundles.tradewindow.frames.paper);
        bg.setContentSize(styles);
        bg.unitData = unit;
        this.unitViews.push(bg);

        var selectBg = bg.selectBg = cleverapps.UI.createScale9Sprite(bundles.tradewindow.frames.paper_selected);
        selectBg.setContentSize(styles.selectBg);
        selectBg.setPositionRound(bg.width / 2, bg.height / 2);
        selectBg.setVisible(false);
        bg.addChild(selectBg, -1);

        var unitIcon = bg.icon = UnitView.getUnitImage(unit, { preferStatic: true, useLibraryAnchors: true });
        unitIcon.setPositionRound(bg.width / 2, bg.height / 2);
        cleverapps.UI.fitToBox(unitIcon, styles.icon);
        bg.addChild(unitIcon);

        if (this.tradeType === UnitTrade.SOURCE) {
            var amountBg = bg.amount = new cc.Sprite(bundles.tradewindow.frames.amount_bg);
            amountBg.setPositionRound(styles.amount);
            bg.addChild(amountBg);

            var amount = cleverapps.UI.generateImageText(unit.amount, cleverapps.styles.FONTS.TRADE_WINDOW_AMOUNT);
            amount.fitTo(styles.amount.text.width);
            amount.setPositionRound(styles.amount.text);
            amountBg.addChild(amount);
        }
        return bg;
    },

    onTabChange: function (selectedTab) {
        this.trade.setSelected(UnitTrade.TARGET);
        this.trade.setSelected(UnitTrade.SOURCE);

        if (this.tradeType === UnitTrade.TARGET) {
            delete this.scroll;
            this.unitViews = [];
            this.picker.removeAllChildren();
            var sourceTypes = this.trade.getSourceTypes();
            var targetTypes = this.trade.getTargetTypes(sourceTypes[selectedTab]);
            this.createTabs(this.getTabsInfo(targetTypes));
        }
    },

    playTutorial: function (f) {
        var target = this.unitViews[0];
        if (!target) {
            return;
        }

        var handler = cleverapps.UI.onClick(target, function () {
            handler.remove();
            this.trade.setSelected(this.tradeType, target.unitData);
            if (cleverapps.forces.getRunningForce()) {
                cleverapps.forces.closeRunningForce();
            }
        }.bind(this));

        var force = this.tradeType === UnitTrade.SOURCE ? Forces.TRADE_SELECT_SOURCE : Forces.TRADE_SELECT_TARGET;
        cleverapps.forces.create(target, force, {
            pointer: true,
            highlights: [this.description, target.icon, target.amount].filter(Boolean)
        });
        cleverapps.forces.onceForceClosed = f;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TRADE_WINDOW_AMOUNT: {
        size: 32,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.TradeSelector = {
    width: 700,
    height: 700,

    description: {
        offsetX: 25,
        offsetY: -115
    },

    tabs: {
        width: 120,
        height: 75,
        offsetX: 30,
        offsetY: 31
    },

    picker: {
        width: 650,
        height: 400,
        x: { align: "center" },
        y: { align: "bottom", dy: 20 },

        grid: {
            margin: { x: 3, y: 3 },
            x: { align: "left", dx: 50 },
            y: { align: "top", dy: -5 }
        },

        scroll: {
            paddingY: 15,
            x: { align: "left" },
            y: { align: "top", dy: -5 }
        },

        unit: {
            width: 135,
            height: 135,

            icon: {
                width: 105,
                height: 105
            },
            selectBg: {
                width: 137,
                height: 137
            },
            amount: {
                x: { align: "right", dx: 7 },
                y: { align: "bottom", dy: -7 },

                text: {
                    width: 50,
                    x: { align: "center" },
                    y: { align: "center", dy: 5 }
                }
            }
        }
    }
};