/**
 * Created by mac on 12/23/20
 */

var MiniGameView = cc.Node.extend({
    ctor: function (minigame) {
        this._super();

        this.minigame = minigame;

        this.createAnimation();
        this.restoreState();
    },

    restoreState: function (animated) {
        if (this.minigame.isWaiting()) {
            this.animation.setAnimation(0, "growing", true);
            return;
        }

        if (animated) {
            this.animation.setAnimation(0, "blink", false);
            this.animation.addAnimation(0, "ready", true);
        } else {
            this.animation.setAnimation(0, "ready", true);
        }
    },

    createAnimation: function () {
        this.animation = new cleverapps.Spine(bundles.fruit.jsons.castle_animation_json);
        this.animation.setPositionRound(cleverapps.styles.MiniGameView);
        this.addChild(this.animation);
    }
});

cleverapps.styles.MiniGameView = {
    x: { align: "center" },
    y: { align: "center", dy: -20 }
};