/**
 * Created by vtbelo on 17/03/21
 */

var MonstersPlanner = function () {
    this.fresh = 0;

    if (!Map2d.currentMap.regions.monsters) {
        return;
    }

    if (cleverapps.config.editorMode || cleverapps.config.adminMode || cleverapps.config.wysiwygMode) {
        return;
    }

    if (this.getCurrentMonster()) {
        this.load();

        var timeout = cleverapps.parseInterval(MonstersPlanner.RESET_TIMEOUT);
        if (this.spawnTime + timeout < Date.now()) {
            this.spawnToAllSlots();
        } else {
            this.planNext();
        }
    }
};

MonstersPlanner.prototype.destructor = function () {
    this.clearPlanTimeout();
};

MonstersPlanner.prototype.clearPlanTimeout = function () {
    if (this.planTimeout !== undefined) {
        clearTimeout(this.planTimeout);
        this.planTimeout = undefined;
    }
};

MonstersPlanner.prototype.spawnToAllSlots = function (needStory) {
    this.clearPlanTimeout();

    var slots = this.getFreeSlots();
    if (!slots.length) {
        return;
    }

    this.needStory = needStory;
    this.spawnOrder = 0;
    this.spawnTime = Date.now();

    this.save();

    for (var i = 0; i < slots.length; ++i) {
        this.spawnMonster();
    }
};

MonstersPlanner.prototype.removeWrongMonsters = function () {
    var currentMonster = this.getCurrentMonster();
    var units = Map2d.currentMap.listAvailableUnits(function (unit) {
        return unit.getData().monster;
    });

    var region = Map2d.currentMap.regions.monsters;
    region = region && region.positions || [];

    units.forEach(function (unit) {
        var inRegion = region.some(function (cell) {
            return cell.x === unit.x && cell.y === unit.y;
        });

        if (!inRegion || unit.getData().monster !== currentMonster) {
            unit.findComponent(Monster).die(true);
        }
    });
};

MonstersPlanner.prototype.getFreeSlots = function () {
    return Map2d.currentMap.regions.monsters.positions.filter(function (position) {
        var monster = Map2d.currentMap.getUnit(position.x, position.y);
        return !monster;
    });
};

MonstersPlanner.prototype.spawnMonster = function () {
    this.fresh += 1;
    this.save();
    Game.currentGame.counter.trigger(100);
};

MonstersPlanner.prototype.processFresh = function () {
    if (!this.fresh || cleverapps.focusManager.isFocused() || Map2d.currentMap.dragging) {
        return;
    }

    var monster = this.getCurrentMonster();
    var slots = this.getFreeSlots();
    if (!monster || !slots.length) {
        this.fresh = 0;
        return;
    }

    slots = cleverapps.Random.shuffle(slots);

    var storyUnit = MonstersPlanner.monsters[monster].units[0] || {};
    var story = this.needStory && Game.currentGame.unitStories.findStory(Unit.GetKey(storyUnit));

    var actions = []; 

    for (var i = 0, last = Math.min(this.fresh, slots.length) - 1; i <= last; ++i) {
        var slot = slots[i];
        var unit = cleverapps.Random.choose(MonstersPlanner.monsters[monster].units, Math.floor(Math.random() * 10000));

        if (story && i === last) {
            actions.push(function (unitData, cell, f) {
                var monsterUnit = new Unit(unitData);
                monsterUnit.findComponent(Monster).spawn(cell, 2);

                Game.currentGame.unitStories.showUnitStory(f, story.dialogue, {
                    unit: monsterUnit,
                    tutorial: Game.currentGame.unitStories.getTutorial(story.id),
                    delay: 2000
                });
            }.bind(this, storyUnit, slot));
            break;
        }

        actions.push(function (cell, f) {
            Map2d.currentMap.getScrollView().runAction(new cc.Sequence(
                new cc.CellScrollAction(cell, {
                    allowScrollWithFocus: true
                }).easing(cc.easeInOut(2)),
                new cc.CallFunc(f)
            ));
        }.bind(this, slot));

        actions.push(function (unitData, cell, f) {
            var monsterUnit = new Unit(unitData);
            monsterUnit.findComponent(Monster).spawn(cell);

            setTimeout(f, 2000);
        }.bind(this, unit, slot));

        if (i === last) {
            actions.push(function (cell, f) {
                Game.currentGame.advice.setLogic(Map2d.CLICK_UNIT, {
                    cells: cell
                });
                Game.currentGame.advice.startHintOnce();
                setTimeout(f, MergeAdvice.ONE_ITERATION_DURATION);
            }.bind(this, slot));
        }
    }

    this.fresh = 0;
    this.needStory = false;

    this.spawnTime = Date.now();
    this.spawnOrder++;
    this.save();

    this.planNext();

    cleverapps.focusManager.display({
        focus: "SpawnMonsters",
        actions: actions
    });
};

MonstersPlanner.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.MONSTER_PLANNER, {
        fresh: this.fresh,
        time: this.spawnTime,
        order: this.spawnOrder
    });
};

MonstersPlanner.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.MONSTER_PLANNER);
    if (data) {
        this.fresh = data.fresh;
        this.spawnTime = data.time;
        this.spawnOrder = data.order;
    }

    this.fresh = this.fresh || 0;
    this.spawnOrder = this.spawnOrder || 0;
    this.spawnTime = Math.min(this.spawnTime || Date.now(), Date.now());

    this.save();
};

MonstersPlanner.prototype.onDieMonster = function () {
    if (this.planTimeout === undefined) {
        this.spawnTime = Date.now();
        this.spawnOrder = 0;
        this.save();
        this.planNext();
    }
};

MonstersPlanner.prototype.stop = function () {
    this.clearPlanTimeout();
    this.removeWrongMonsters();
};

MonstersPlanner.prototype.planNext = function () {
    this.clearPlanTimeout();

    var slots = this.getFreeSlots();
    if (!slots.length) {
        return;
    }

    var timeout = cleverapps.parseInterval(MonstersPlanner.BETWEEN_TIMEOUTS[this.spawnOrder] || MonstersPlanner.BETWEEN_TIMEOUTS[MonstersPlanner.BETWEEN_TIMEOUTS.length - 1]);
    var waitTime = cleverapps.parseInterval(MonstersPlanner.SMALL_TIMEOUT);
    if (this.spawnTime + timeout > Date.now()) {
        waitTime = this.spawnTime + timeout - Date.now();
    }

    this.planTimeout = setTimeout(function () {
        this.clearPlanTimeout();
        this.spawnMonster();
    }.bind(this), waitTime);
};

MonstersPlanner.prototype.getCurrentMonster = function () {
    var mission = cleverapps.missionManager.findByType(Mission.TYPE_KRAKENFEAST);
    if (mission && mission.isRunning()) {
        return "kraken";
    }

    return undefined;
};

MonstersPlanner.SMALL_TIMEOUT = "1 seconds";
MonstersPlanner.RESET_TIMEOUT = "1 hour";
MonstersPlanner.BETWEEN_TIMEOUTS = ["20 minutes", "20 minutes", "20 minutes", "30 minutes", "30 minutes", "30 minutes", "30 minutes", "1 hour"];

MonstersPlanner.monsters = {
    kraken: {
        units: [{
            code: "kraken",
            stage: 0
        }, {
            code: "kraken",
            stage: 1
        }, {
            code: "kraken",
            stage: 2
        }]
    }
};