/**
 * Created by spepa on 23.01.2023
 */

FogsConfig.ADS_FOGS_AMOUNT = 40;
FogsConfig.ads = {
    default: {}
};

(function () {
    FogsConfig.ads.default.fog0 = {
        id: 0,
        price: 0,
        exp: 0,
        headless: true
    };

    for (var i = 1; i < FogsConfig.ADS_FOGS_AMOUNT; i++) {
        FogsConfig.ads.default["fog" + i] = {
            id: i,
            price: 1,
            exp: 0,
            parentFogId: "fog" + (i - 1)
        };
    }

    FogsConfig.ads.default.fog99 = {
        id: 99,
        price: 1,
        exp: 0,
        available: false
    };
}());

FogsConfig.GenerateAdsDefault = function () {
    var config = cleverapps.clone(FogsConfig.ads.default, true);
    var baseName = "moneyFog";

    if (cleverapps.travelBook.isExpedition()) {
        var expedition = cleverapps.travelBook.getCurrentPage().id;
        baseName += expedition[0].toUpperCase() + expedition.slice(1).toLowerCase();
    }

    for (var i = 0; i < 20; i++) {
        config[baseName + i] = {
            id: FogsConfig.ADS_FOGS_AMOUNT + i,
            price: 1,
            exp: 0,
            money: true,
            bubbles: [{
                code: "coins",
                stage: 4,
                amount: 1
            }]
        };
        if (i > 0) {
            config[baseName + i].parentFogId = baseName + (i - 1);
        }
    }
    return config;
};

FogsConfig.ads.ads_dragonia_4 = {
    fog0: {
        id: 0,
        price: 0,
        exp: 0,
        headless: true
    },
    fog1: {
        id: 1,
        price: 4,
        exp: 10
    },
    fog2: {
        id: 2,
        price: 5,
        exp: 10,
        parentFogId: "fog1"
    },
    fog3: {
        id: 3,
        price: 5,
        exp: 10,
        parentFogId: "fog2"
    },
    fog4: {
        id: 4,
        price: 10,
        exp: 10,
        parentFogId: "fog3"
    }
};

FogsConfig.ads.ads_rapunzel_0 = {
    fog0: {
        id: 0,
        price: 0,
        exp: 0,
        headless: true
    },
    fog1: {
        id: 1,
        price: 0,
        exp: 10,
        parentFogId: "fog0",
        conditions: {
            family: "rpplate"
        }
    },
    fog2: {
        id: 2,
        price: 0,
        exp: 10,
        parentFogId: "fog1",
        conditions: {
            family: "rpcustomerbridge0"
        }
    },
    fog3: {
        id: 3,
        price: 0,
        exp: 10,
        parentFogId: "room0",
        conditions: {
            family: "rpcustomermain0b"
        }
    },
    fog4: {
        id: 4,
        price: 0,
        exp: 10,
        parentFogId: "fog3",
        conditions: {
            family: "rpcustomerbridge1"
        }
    },
    fog5: {
        id: 5,
        price: 0,
        exp: 10,
        parentFogId: "fog4",
        conditions: {
            family: "rpcustomermain1b"
        }
    },
    fog6: {
        id: 6,
        price: 0,
        exp: 10,
        parentFogId: "fog5",
        conditions: {
            family: "rpcustomerbridge2"
        }
    },
    fog7: {
        id: 7,
        price: 0,
        exp: 10,
        parentFogId: "fog6",
        conditions: {
            family: "rpcustomermain2a"
        }
    },
    fog8: {
        id: 8,
        price: 0,
        exp: 10,
        parentFogId: "fog7",
        conditions: {
            family: "rppans"
        }
    },
    fog9: {
        id: 9,
        price: 0,
        exp: 10,
        parentFogId: "fog7",
        conditions: {
            family: "rpcustomermain2d"
        }
    },
    fog10: {
        id: 16,
        price: 0,
        exp: 10,
        parentFogId: "fog9",
        conditions: {
            family: "rpcustomerbridge3"
        }
    },
    fog11: {
        id: 17,
        price: 0,
        exp: 10,
        parentFogId: "rpmugs",
        conditions: {
            family: "rpmugs"
        }
    },
    fog12: {
        id: 18,
        price: 0,
        exp: 10,
        parentFogId: "fog11",
        conditions: {
            family: "rpcustomermain3a"
        }
    },
    fog13: {
        id: 19,
        price: 0,
        exp: 10,
        parentFogId: "fog12",
        conditions: {
            family: "rpcustomermain3c"
        }
    },
    fog14: {
        id: 20,
        price: 20,
        exp: 10,
        parentFogId: "fog7"
    },
    fog15: {
        id: 21,
        price: 20,
        exp: 10,
        parentFogId: "fog7"
    },

    room0: {
        id: 22,
        price: 0,
        exp: 10,
        parentFogId: "fog1",
        conditions: {
            family: "rpcustomerbridge0"
        }
    },
    room1: {
        id: 23,
        price: 0,
        exp: 10,
        parentFogId: "fog3",
        conditions: {
            family: "rpcustomerbridge1"
        }
    },
    room2: {
        id: 24,
        price: 0,
        exp: 10,
        parentFogId: "fog5",
        conditions: {
            family: "rpcustomerbridge2"
        }
    },
    room3: {
        id: 25,
        price: 0,
        exp: 10,
        parentFogId: "fog9",
        conditions: {
            family: "rpcustomerbridge3"
        }
    },

    moneyFogRapunzel1: {
        id: 11,
        price: 99,
        money: true,
        parentFogId: "fog2",
        conditions: {
            family: "rpshop"
        },
        bubbles: [{
            code: "treasure",
            stage: 0,
            amount: 2
        }]
    },
    moneyFogRapunzel2: {
        id: 12,
        price: 299,
        money: true,
        parentFogId: "fog4",
        bubbles: [{
            code: "treasure",
            stage: 0,
            amount: 1
        },
        {
            code: "crystal",
            stage: 2,
            amount: 1
        }]
    },
    moneyFogRapunzel3: {
        id: 13,
        price: 599,
        money: true,
        parentFogId: "fog6",
        bubbles: [{
            code: "treasure",
            stage: 1,
            amount: 1
        },
        {
            code: "crystal",
            stage: 3,
            amount: 1
        }]
    },
    moneyFogRapunzel4: {
        id: 14,
        price: 899,
        money: true,
        parentFogId: "fog8",
        bubbles: [{
            code: "treasure",
            stage: 1,
            amount: 1
        },
        {
            code: "crystal",
            stage: 3,
            amount: 1
        }]
    },
    moneyFogRapunzel5: {
        id: 15,
        price: 1299,
        money: true,
        parentFogId: "fog10",
        bubbles: [{
            code: "treasure",
            stage: 1,
            amount: 1
        },
        {
            code: "crystal",
            stage: 3,
            amount: 1
        }]
    }
};